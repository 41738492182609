import { Component, OnInit } from '@angular/core';
import { FooterService } from './footer.service';
import { CommonService } from '../services/common.service';
@Component({
	selector: 'app-footer',
	templateUrl: './footer.component.html',
	styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

	year = new Date().getFullYear();
	isChargeLink = false;

	googlePlayAppLink: string = this.commonService.mobileAppLinks.google_play.iotah;
	appStoreAppLink: string = this.commonService.mobileAppLinks.app_store.iotah;

	constructor(
		public footer: FooterService,
		private commonService: CommonService,
	) {
		this.isChargeLink = this.commonService.isChargeLink();
	}

	ngOnInit() {
		if (this.isChargeLink) {
			this.googlePlayAppLink = this.commonService.mobileAppLinks.google_play.chargLink;
			this.appStoreAppLink = this.commonService.mobileAppLinks.app_store.chargLink;
		}
	}
}