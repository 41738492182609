import { CommonDataService } from './shared/services/common-data.service';
import { NotificationMessageService } from './shared/notification-message/notification-message.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LeftPanelService } from './shared/left-panel/left-panel.service';
import { CustomHttpParams } from './shared/loader/custom-httpParam';
import { Router, NavigationStart } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { filter } from 'rxjs/operators';
import {CookieService} from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { Title } from '@angular/platform-browser';
import { CommonService } from './shared/services/common.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit, OnDestroy {

	versionKey: string = 'current-version';
	showBanner: boolean = true;
	selectedLang = 'en';

	sub1: Subscription = new Subscription();
	sub2: Subscription = new Subscription();
	sub3: Subscription = new Subscription();

	constructor(
		public translate: TranslateService,
		public leftPanelService: LeftPanelService,
		private router: Router,
		private httpClient: HttpClient,
		private cookieService: CookieService,
		public commonDataService: CommonDataService,
		private notificationMessageService: NotificationMessageService,
		private titleService: Title,
		private commonService: CommonService
	) {
		localStorage.removeItem(this.versionKey)
		
		let is_cookie_policy_confirmed = this.cookieService.get("cookiePolicy");
		this.showBanner = !is_cookie_policy_confirmed;

		translate.addLangs(['en']);
		// this language will be used as a fallback when a translation isn't found in the current language
		translate.setDefaultLang('en');

		this.sub1 = router.events.pipe(
			filter(event => event instanceof NavigationStart)
		).subscribe(() => {
			let ver = localStorage.getItem(this.versionKey);

			const notificationMessage = this.notificationMessageService.messageSubject.value;
			if (notificationMessage) {
				if (notificationMessage.numberOfNavigate === notificationMessage.clearOnXTimeNavigate - 1) 
					this.notificationMessageService.closeNotification();
				else
					notificationMessage.numberOfNavigate++;
			}

			this.sub2 = this.httpClient.post('/user/updateCheck', {ver}, {
				observe: "body",
				params: new CustomHttpParams({noUIBlock: true})
			}).subscribe((response: any) => {

				if(response.newVersion) {

					localStorage.setItem(this.versionKey, response.newVersion);
					
					if(ver) 
						this.commonDataService.showRefreshMessage = true;
				}
			});
		});
		
	}

	ngOnInit() {
		this.handleTitleAndFavicon();
		this.sub3 = this.httpClient.get('/user/getGitData', {
			observe: "body"
		}).subscribe((response: any) => {
			console.log(response);
			if(Object.keys(response).length > 0)
				console.log("%c"+JSON.stringify(response), "color: red; font-size: x-large");
		});
	}

	closeBanner() {
		let exprireDate = new Date();
		exprireDate.setFullYear(exprireDate.getFullYear() + 10);
		this.cookieService.set('cookiePolicy', '1', exprireDate);
		this.showBanner = false;
	}

	ngOnDestroy() {
		this.sub1.unsubscribe();
		this.sub2.unsubscribe();
		this.sub3.unsubscribe();
	}

	handleTitleAndFavicon() {
		const favIcon: HTMLLinkElement = document.querySelector('#favIcon');
		favIcon.href = '/sctportal/images/favicon_t.ico';
		this.titleService.setTitle('Iotah');
		if(this.commonService.isChargeLink()) {
			this.titleService.setTitle('ChargLink');
			favIcon.href = '/sctportal/images/favicon_c.ico';
		}
	}
}