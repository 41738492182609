<div *ngIf="reportsHistory.length > 0; else noData">
	<div class="d-flex justify-content-between mb-2 px-2">
		<h2>{{(source == deviceManagementService.shipmentSource.quality ? 'nav.shipment_quality_history' : 'nav.receiving_shipment_history') | translate}}</h2>
	</div>

	<div class="col-md-12 mt-4">
		<div class="my-2 col-4">
			<input [ngClass]="{'invalid-input': invalidDevice}" name="searchString" [(ngModel)]="deviceId" trim-text placeholder="{{'g.serial_number' | translate}}/{{'g.mac_address' | translate}}" class="form-control"/>
			<p *ngIf="invalidDevice" class="text-danger"> {{'g.invalid_input' | translate}}</p>
		</div>
		<div class="col-12 my-3 d-flex justify-content-between">
			<div>
				<button type="submit" class="btn btn-primary pointer" [disabled]="!deviceId" (click)="filterReceivingHistoryDevices()">{{'g.search' | translate}}</button>
				<button *ngIf="searchApplied" type="submit" class="btn btn-primary pointer ml-2" (click)="resetSearchFilter()">
					{{'g.clear_search' | translate}}
				</button>
			</div>
			<button *ngIf="source == deviceManagementService.shipmentSource.receive" class="btn btn-primary pointer mr-4" (click)="exportReceivedShipmentData.show()" >{{'g.export' | translate}}</button>
		</div>
	</div>
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.date' | translate}}</th>
				<th>{{'shipment.user_name' | translate}}</th>
				<th *ngIf="source == deviceManagementService.shipmentSource.receive">{{'shipment.cm_po_number' | translate}}</th>
				<th>{{(source == deviceManagementService.shipmentSource.quality ? 'shipment.success_checked_no_devices' : 'shipment.success_no_devices') | translate}}</th>
				<th>{{'shipment.total_no_devices' | translate}}</th>
				<th *ngIf="source != deviceManagementService.shipmentSource.quality">{{'shipment.checked_quality' | translate}}</th>
				<th>{{'g.results' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let report of reportsHistory">
				<td>{{report.action_time}}</td>
				<td>{{report.user_name}}</td>
				<td *ngIf="source == deviceManagementService.shipmentSource.receive" class="editable-cell d-flex justify-content-between">
					<span>{{report.cm_po_number || '---'}}</span>
					<span (click)="toggleEditCmPoNumberPopup(true, report)" class="edit-icon"><fa-icon icon="pen" size="1x" class="pointer text-primary ms-3 ml-2" [title]="'g.edit' | translate"></fa-icon></span>
				</td>
				<td>{{report.success_no_devices}} {{searchApplied ? '('+(getDeviceHistoryStatus(report.id) | translate)+')': '' }}</td>
				<td>{{report.total_no_devices}}</td>
				<td *ngIf="source != deviceManagementService.shipmentSource.quality">{{(report.checked_quality ? 'g.yes' : 'g.no') | translate}}</td>
				<td>
					<a class="btn btn-primary btn-sm"
							[routerLink]="['/management', source == deviceManagementService.shipmentSource.quality ? 'shipment-quality-history' : 'receiving-shipment-history', report.id]"
							target="_blank">
						{{'g.results' | translate}}
					</a>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal #exportReceivedShipmentData [hasConfirmButton]="false" [hasCloseButton]="false" [title]="'g.export' | translate">
	<div class="row">
		<div class="col-md-12 mb-3">
			<label class="form-label mr-2"><span>{{'g.from'|translate}}</span></label>
			<input type="date" name="searchString" [(ngModel)]="fromDate" placeholder="{{'g.search' | translate}}" class="form-control mr-2" required />
		</div>

		<div class="col-md-12">
			<label class="form-label mr-2"><span>{{'g.to'|translate}}</span></label>
			<input type="date" name="searchString" [(ngModel)]="toDate" placeholder="{{'g.search' | translate}}" class="form-control" required />
		</div>
		<p *ngIf="notValidPopupDate" class="pl-3 pt-3 text-danger">{{'g.start_date_before_end_date' | translate}}</p>
	</div>
	<div class="mt-3 d-flex justify-content-end">
		<button class="btn btn-md btn-primary" (click)="generateReceivedShipmentHistoryReport()">
			{{'devices.export_csv' | translate}}
		</button>
	</div>
</app-modal>

<app-modal #editCmPoNumberPopup [hasConfirmButton]="true" [hasCloseButton]="true" [title]="'shipment.edit_cm_po_numnber' | translate">
	<div class="row">
		<div class="col-md-12 mb-3">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'shipment.cm_po_number' | translate}}</span>
				</div>
				<input type="text" class="form-control" [(ngModel)]="newCmPoNumberValue">
			</div>
		</div>
	</div>
</app-modal>
