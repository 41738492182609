<form class="form-auth">
	<div class="container-fluid d-flex w-100 justify-content-center" id="main">
		<div class="bg-white rounded p-3 col-md-8 col-md-offset-1" id="left">
            <div>
                <h2 class="text-center">{{ "nav.search_device_events" | translate }}</h2>
                    <div>
                        <div class="col-12 mt-3">
                            <p class="font-weight"> {{(processOnGoing? 'events.ah_events_search_report_status' : 'events.ah_events_search_note') | translate}} <a class="link" *ngIf="processOnGoing" (click)="confitmCancleSearchModal.show()"> {{'g.cancel' | translate}} </a> </p>
                        </div>
                        <!-- threshold value -->
                        <div class="row col-md-12 p-3">
                            <div class="col-md-2">
                                <span class="mr-1">*</span>
                                <strong>{{'events.threshold_ah' | translate}}</strong>
                            </div>
                            <div class="col-md-5">
                                <input type="number" [ngClass]="{'invalid-input': thresholdValueRequired}" class="form-control" name="thresholdValue" id="thresholdValue" [(ngModel)]="model.thresholdValue" [disabled]="processOnGoing" required>
                            </div>
                        </div>

                        <!-- date range  (max one month)-->
                        <div class="row col-md-12 p-3">
                            <div class="col-md-2">
                                <span class="mr-1">*</span>
                                <strong>{{'g.date_range' | translate}}</strong>
                            </div>
                            <div class="col-md-5">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{'g.start_date' | translate}}</span>
                                    </div>
                                    <input [ngClass]="{'invalid-input': invalidStartDate}" type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp1" [minDate]="minDate" [maxDate]="maxDate" [(ngModel)]="model.fromDate" bsDatepicker #d1="bsDatepicker" [disabled]="processOnGoing" [bsConfig]="{containerClass: 'theme-dark-blue'}">
                                    <div class="input-group-append pointer" (click)="d1.toggle()">
                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                
                            <div class="col-md-5">
                                <div class="input-group">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text">{{'g.end_date' | translate}}</span>
                                    </div>
                                    <input [ngClass]="{'invalid-input': invalidEndDate}" type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp2" (ngModelChange)="updateMinDate($event)" [maxDate]="today" [(ngModel)]="model.toDate" bsDatepicker #d2="bsDatepicker" [disabled]="processOnGoing" [bsConfig]="{containerClass: 'theme-dark-blue'}">
                                    <div class="input-group-append pointer" (click)="d2.toggle()">
                                        <span class="input-group-text"><i class="fa fa-calendar"></i></span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- device type dropdown (Iotah or ChargLink) default both -->
                        <div class="row col-md-12 p-3">
                            <div class="col-md-2">
                                <strong>{{'devices.device_type' |translate}}</strong>
                            </div>
                            <div class="col-md-5">
                                <ng-select 
                                    [items]="devicesType"
                                    bindLabel="label"
                                    bindValue="value"
                                    placeholder="{{'devices.device_type' | translate}}"
                                    name="device_type"
                                    multiple="false"
                                    [(ngModel)]="model.selectedDeviceType"
                                    [disabled]="processOnGoing"
                                >
                                </ng-select>
                            </div>
                        </div>
                        <!-- Select Customers -->
						<div class="row col-md-12 p-3">
							<div class="col-md-2">
								<strong>{{ "g.customer" | translate }}</strong>
							</div>
							<div class="col-md-5">
								<ng-select
									[items]="customers"
									bindLabel="customer_name"
									bindValue="id"
									placeholder="{{ 'g.all_customers' | translate }}"
									name="customer_id"
									[(ngModel)]="model.selectedCustomers"
									multiple="false"
                                    [disabled]="processOnGoing"
									(change)="updateSitesAvailable()"
								>
								</ng-select>
							</div>
						</div>
                        <!-- select sites -->
						<div class="row col-md-12 p-3" *ngIf="model.selectedCustomers.length > 0">
							<div class="col-md-2">
								<strong>{{ "site.sites" | translate }}</strong>
							</div>
							<div class="col-md-5">
								<ng-select
									[items]="sites"
									bindLabel="name"
									bindValue="id"
									name="site_id"
									[(ngModel)]="model.selectedSites"
                                    [disabled]="processOnGoing"
									multiple="false"
								>
								</ng-select>
							</div>
						</div>
					</div>
			</div>
			<div class="form-buttons d-flex justify-content-end" *ngIf="!processOnGoing">
				<button class="btn btn-primary mb20" type="button" (click)="showActionModal()">
					<span class="glyphicon glyphicon-search"></span>
					{{ "g.search" | translate }}
				</button>
			</div>
		</div>
	</div>
</form>

<app-modal #confirmActionModal [confirmBtnText]="'g.yes' | translate" [closeBtnText]="'g.no' | translate"  title="{{'g.confirm_action' | translate}}">
    <div>
        <p>{{ 'events.starting_search_ah_events_msg' | translate}}</p>
    </div>
</app-modal>

<app-modal #confitmCancleSearchModal [confirmBtnText]="'g.yes' | translate" [closeBtnText]="'g.no' | translate"  title="{{'g.confirm_action' | translate}}">
    <div>
        <p>{{ 'events.cancle_search_ah_events_msg' | translate}}</p>
    </div>
</app-modal>

