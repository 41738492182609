import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class PermissionsService {

	route = '/permissions/';

	constructor(
		private httpClient: HttpClient
	) { }

	getPermissionsList(type) {
		return this.httpClient.post(this.route + 'getPermissionsList', { type }, {
			observe: "body"
		});
	}

	getPermissionInfo(permissionId, type) {
		return this.httpClient.post(this.route + 'getPermissionInfo', { permissionId, type }, {
			observe: "body"
		});
	}

	addPermission(options) {
		return this.httpClient.post(this.route + 'addPermission', options, {
			observe: "body"
		});
	}

	updatePermission(options) {
		return this.httpClient.post(this.route + 'updatePermission', options, {
			observe: "body"
		});
	}

	deletePermission(options) {
		return this.httpClient.post(this.route + 'deletePermission', options, {
			observe: "body"
		});
	}
}
