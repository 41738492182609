import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class CustomersService {

	route = '/customer/';

	constructor(
		private httpClient: HttpClient
	) { }
	
	getCustomers(filters?) {
		return this.httpClient.post(this.route+'getCustomers', {filters}, {
			observe: "body"
		});
	}

	getCustomer(customerId, options?) {
		return this.httpClient.post(this.route+'getCustomer', {customerId, options}, {
			observe: "body"
		});
	}

	deleteCustomer(customerId) {
		return this.httpClient.post(this.route+'deleteCustomer', {customerId}, {
			observe: "body"
		});
	}

	saveCustomer(customer) {
		return this.httpClient.post(this.route+'saveCustomer', customer, {
			observe: "body"
		});
	}

	getCustomerZombieSites(customerId) {
		return this.httpClient.post(this.route + 'getCustomerZombieSites', {customerId}, {
			observe: "body"
		});
	}
}