import { Component, OnInit, Input, OnChanges, Output, EventEmitter, ViewChild } from '@angular/core';
import { DeviceService } from '../../device.service';
import { CommonService } from 'src/app/shared/services/common.service';
import * as moment from 'moment';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

@Component({
	selector: 'app-rt',
	templateUrl: './rt.component.html',
	styleUrls: ['./rt.component.css']
})
export class RtComponent implements OnInit, OnChanges {
	@ViewChild("fetchRtModal") fetchRtModal: ModalComponent;

	@Input() device: any = {};
	@Output() onDeviceDataChange = new EventEmitter<Object>();
	@Input() currentSite: any;
	@Input() dateRange: any = {};

	fromDateFormatted;
	data: any[] = [];
	hasGrid: boolean = null;
	byId = true;

	columnDefs: any[] = [];
	rowData: any[] = [];

	allColumnsKeys: string[] = [
		"sequence_id",
		"timestamp",
		"voltage",
		"current",
		"temperature",
		"version",
		"rt_flag_current_error"
	];

	visibilityCheckboxes: any = {
		voltage: true,
		current: true,
	};
	chartObject: any = {};
	showChart: boolean = false;
	chartData: any = [];

	maxRTHours: number = 48;
	force_read_rt_time: number = null;
	forceReadRTUntil: string = '';

	fetchRtByIdLimit = 12000;
	fetchRtByTimeLimitSec = 36 * 60 *60;
	fetchRtBy: any = {
		id: {
			fromId: null,
			toId: null,
			error: ""
		},
		timeRange: {
			fromTime: '',
			toTime: '',
			fromTimeLocal: '',
			toTimeLocal: '',
			error: ""
		}
	}

	fetchRtRecordsByRangeErrorsCode = {
		1: "start_id_less_end_id",
		2: "start_id_not_exist",
		3: "end_id_not_exist",
		4: "fetch_rt_in_progress",
		5: 'range_already_exist',
		6: 'exceed_max_range_allowed',
		7: 'before_installation_date',
	}

	constructor(
		private deviceService: DeviceService,
		public commonService: CommonService,
		private deviceManagementService: DeviceManagementService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService,
		public usersService: UsersService,
		private router: Router,
	) { }

	public ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'read_rts'))
			return this.router.navigate(['/unauthorized']);

		this.chartObject.formatterType = 'DateFormat';
		this.chartObject.formatter = {pattern: "MM/dd/yyyy HH:mm:ss"};
		this.getRTDate();
	}

	ngAfterViewInit(): void {
		this.fetchRtModal.onClose.subscribe((result: any) => {
			let by: 'id' | 'timeRange' = 'timeRange';

			if (this.byId)
				by = 'id';

			this.fetchRtBy[by].error= "";

			if(result) {
				if(!this.validateRange(by))
					return;

				this.fetchRtRecordsByRange(by);
			}

			this.fetchRtModal.hide();
			this.fetchRtBy[by] = by == 'id' ? {fromId: null, toId: null, error: ''} : {fromTime: '', fromTimeLocal: '', toTimeLocal: '', toTime: '', error: ''};
			return;
		});
	}

	validateRange(by) {
		let isValid = true;
		let errors = [];
		const messagesMapper = {
			fill_all_fields: 'force_read_rt',
			start_date_before_end_date: "g",
			exceed_max_time_range_allowed: "force_read_rt"
		}

		switch (by) {
			case 'id':
				if (this.fetchRtBy.id.fromId == null || this.fetchRtBy.id.toId == null)
					errors.push('fill_all_fields');

				if (this.fetchRtBy.id.fromId < 0 || this.fetchRtBy?.id.toId < 0)
					errors.push('ids_should_be_positive');

				if (this.fetchRtBy.id.fromId > this.fetchRtBy.id.toId)
					errors.push('start_id_should_be_less_than_end_id');

				if (this.fetchRtBy.id.toId - this.fetchRtBy.id.fromId > this.fetchRtByIdLimit)
					errors.push('exceed_max_range_allowed');

				if (errors.length) {
					isValid = false;
					this.fetchRtBy.id.error = errors.map(error => this.translateService.instant(`force_read_rt.${error}`)).join(', ');
				}
				break;

			case 'timeRange':
				const fromTime = this.fetchRtBy.timeRange.fromTime ? moment(this.fetchRtBy.timeRange.fromTime).unix() : null;
				const toTime = this.fetchRtBy.timeRange.toTime ? moment(this.fetchRtBy.timeRange.toTime).unix() : null;

				if (!fromTime || !toTime)
					errors.push('fill_all_fields');

				if (fromTime > toTime)
					errors.push('start_date_before_end_date');

				if (toTime - fromTime > this.fetchRtByTimeLimitSec)
					errors.push('exceed_max_time_range_allowed');

				if (errors.length) {
					isValid = false;
					this.fetchRtBy.timeRange.error = errors.map(error => this.translateService.instant(`${messagesMapper[error]}.${error}`)).join(', ');
				}
				break;

			default:
				break;
		}

		return isValid;
	}

	openFetchRTPopup(byId) {
		this.byId = byId;
		this.fetchRtModal.show();
	}

	formatTime(field, value: string) {
		this.fetchRtBy.timeRange[field] = moment(value).format('YYYY/MM/DD HH:mm:ss');
	}

	fetchRtRecordsByRange(type: 'timeRange' | 'id') {
		const range: any = {};

		switch (type) {
			case 'timeRange':
				range.fromTime = this.fetchRtBy.timeRange.fromTime;
				range.toTime = this.fetchRtBy.timeRange.toTime;
				break;

			case 'id':
				range.fromId = this.fetchRtBy.id.fromId;
				range.toId = this.fetchRtBy.id.toId;
				break;

			default:
				break;
		}

		this.deviceService.fetchRtRecordsByRange(this.device.mac_address, range).subscribe((data: any) => {
			if (data.err) {
				const errorText = this.fetchRtRecordsByRangeErrorsCode[data.err];

				if (errorText)
					return this.notificationMessage.setMessage(this.translateService.instant(`force_read_rt.${errorText}`));

				return this.notificationMessage.setMessage(this.translateService.instant(`g.global_err`));
			}

			this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1})
		});
	}

	getData() {
		let fromDateRange = this.dateRange.fromDate;
		this.fromDateFormatted = moment(this.dateRange.fromDate).format('MM/DD/YYYY');

		if(!this.device.mac_address || !fromDateRange)
			return;

		let zoneDiff		= new Date().getTimezoneOffset() * -1;
		let fromDate:any	= new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000));
		fromDate			= moment(fromDate).utc().startOf('day').unix();
		fromDate			= this.commonService.getUTCTimestampFromZone((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), fromDate);

		this.deviceService.getRTs(this.device.mac_address, fromDate).subscribe((data: any) => {
			this.formatData(data);
			this.buildGrid();
			this.drawRTrecordsChart();
		});
	}

	buildGrid() {
		let gridColumns		= [],
			existsColumns	= Object.keys(this.data[0] || {});

		let hiddenFields = [
			'temperature',
			'version'
		];

		for(let field of this.allColumnsKeys) {
			let colDef:any = {};
			if(existsColumns.includes(field)) {
				switch (field) {
					case 'voltage':
					case 'current':
						colDef = {type: 'number', filterParams: {format: '1.0-2'}};
						break;
					case 'version':
					case 'sequence_id':
					case 'temperature':
						colDef = {type: 'number', filterParams: {format: 'noFormat'}};
						break;
					case 'timestamp':
						colDef = {type: 'dateTime'};
						break;
					case 'rt_flag_current_error':
						colDef = {type: 'boolean'};
						break;
					default:
						break;
				}
				if(hiddenFields.includes(field))
					colDef.hide = true;

				Object.assign(colDef, {headerName: 'rt.'+field, field: field, colId: field});
				gridColumns.push(colDef);
			}
		}

		this.columnDefs = gridColumns;
		this.hasGrid = existsColumns.length > 0;
	}

	formatData(data) {
		this.chartData = [];
		let timeFields = [
			"timestamp"
		];
		let booleanFields = [
			'rt_flag_current_error'
		]
		let events = [];
		data.forEach((element) => {
			let record = this.commonService.decompress(element, 'rt');
			if(!record.rt_flag_current_error)
				record.rt_flag_current_error = 0;
			this.chartData.push({...record});
			Object.keys(record).forEach((field) => {
				if(timeFields.includes(field)) {
					record[field] = this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), record[field]);
					record[field] = moment(record[field]*1000).utc().format('MM/DD/YYYY HH:mm:ss');
				}
				if (booleanFields.includes(field))
					record[field] = record[field] == 1 ? true : false;
			});
			events.push(record);
		});

		this.data = events;
	}

	ngOnChanges() {
		this.getData();
	}

	drawRTrecordsChart() {
		this.showChart = false;
		if (this.chartData.length === 0)
			return;

		if(!this.chartData[0].hasOwnProperty('voltage') && !this.chartData[0].hasOwnProperty('current'))
			return;

		let vAxes = {
			"0" : {
				"textPosition": "out",
					textStyle: {color: '#328332'},
					title: "Voltage (V)",
					titleTextStyle: {color: '#328332'},
				},
			"1" : {
				"textPosition": "in",
				textStyle:{color: '#2828ab'},
				title: "Current (A)",
				titleTextStyle: {color: '#2828ab'}
			}
		};
		let series: any = {
			"0" : {
				"targetAxisIndex":0,
				"color": "#328332" ,
				type: 'line'
			},
			"1" : {
				"targetAxisIndex":1,
				"color" : "#2828ab",
				type: 'line'
			},
			"2": {
				"targetAxisIndex":2,
				"color" : "#FFA500",
				type: 'line'
			}
		};

		let config = {
			LegendPosition: 'top',
			hAxis: {
				title: "Time of Day"
			},
			chartArea: {
				width: '80%',
				height: '70%',
				backgroundColor: {
					stroke: '#000',
					strokeWidth: 2
				}
			},
			vAxes: vAxes,
			"series": series,
			explorer: {
				"actions": [
					"dragToZoom",
					"rightClickToReset"
				],
				maxZoomIn: 50.0,
				keepInBounds: true
			},
			widgetHeight: 400,
			dataIsDataTable: true,
		};

		if (!this.visibilityCheckboxes.voltage) {
			config.series["0"].lineWidth = 0;
			config.series["0"].enableInteractivity= false;
			config.series["0"].tooltip ="none";
			config.vAxes["0"].textPosition = "none";
			config.vAxes["0"].title = "";
		}
		if (!this.visibilityCheckboxes.current) {
			config.series["1"].lineWidth = 0;
			config.series["1"].enableInteractivity= false;
			config.series["1"].tooltip ="none";
			config.vAxes["1"].textPosition = "none";
			config.vAxes["1"].title = "";
		}

		this.chartObject.columnNames = ['Date', 'Voltage', 'Current', 'Current Reset'];

		let data		= [];
		let zoneDiff	= new Date().getTimezoneOffset() * -1;

		this.chartData.forEach((item) => {
			let timestamp = this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), item.timestamp);
			timestamp = new Date(new Date(timestamp*1000).getTime() - (zoneDiff * 60 * 1000));
			data.push([timestamp, item.voltage, item.current, item.rt_flag_current_error]);
		});

		let rtSorter =  function(a, b) {
			if (a[0] < b[0]) return -1;
			if (a[0] > b[0]) return 1;
			return 0;
		}

		this.chartObject.data = data.sort(rtSorter);
		this.chartObject.config = config;

		if (data.length > 1)
			this.showChart = true;
	}

	getRTDate(force_read_rt_time?) {
		force_read_rt_time = force_read_rt_time || this.device.config_info.force_read_rt_time;
		if(force_read_rt_time && typeof(force_read_rt_time) == 'number')
			this.forceReadRTUntil = moment.unix(force_read_rt_time || 0).utc().format('MM/DD/YYYY H:mm');
	}

	saveRTDate() {
		if(this.force_read_rt_time > this.maxRTHours || this.force_read_rt_time < 0)
			return this.notificationMessage.setMessage(this.translateService.instant('force_read_debug.select_time_more_max_time'));

		this.deviceManagementService.setForceReadRTDate(this.device.mac_address, this.force_read_rt_time).subscribe((data: any) => {
			if(data.api_status == 3)
				return this.notificationMessage.setMessage('translate|rt_requests.force_read_rt_validation_msg', {clearOnXTimeNavigate: 1});
			this.onDeviceDataChange.emit(data);
			this.getRTDate(data.force_read_rt_time);
			this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		})
	}
}
