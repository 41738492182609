import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { StudiesService } from '../studies.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import * as lo from 'lodash-es';
import { UsersService } from 'src/app/users/users.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';


@Component({
	selector: 'app-studies-list',
	templateUrl: './studies-list.component.html',
	styleUrls: ['./studies-list.component.css']
})
export class StudiesListComponent implements OnInit, AfterViewInit {

	@Input() parent: 'device'|'admin'|'customer'|'site' = 'admin';
	@Input() customerId = 0;
	@Input() customerName = "";
	@Input() siteId = 0;
	@Input() device;

	studies: any = [];
	filteredStudies: any = [];
	styles: string = '';

	statusFilterList = [
		{ id: 0, value: this.translateService.instant('studies.status_any') },
		{ id: 1, value: this.translateService.instant('studies.status_not_done') },
		{ id: 2, value: this.translateService.instant('studies.status_done') }
	];

	showNewStudyForm = false;
	canDeleteStudy = false;
	listingColumns: any = [];

	invalidDateRange: boolean = false;
	dateRange: any;
	originalFromDate: number = 0;
	originalToDate: number = 0;
	status: number = this.statusFilterList[0].id;
	recordsPerPage: number = 100;
	selectedStudies: any = [];
	currentUser: any = {};

	requestStatus = {
		0: 'queued',
		1: 'accepted',
		queued: 0,
		accepted: 1
	};

	@ViewChild("confirmDeleteStudy") confirmDeleteStudy: ModalComponent;

	constructor(
		private studiesService: StudiesService,
		private translateService: TranslateService,
		private sctToastService: NotificationMessageService,
		public usersService: UsersService,
		private router: Router,
		private commonService: CommonService
	) {}

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();

		if(!this.currentUser.has_access_to_any_study || this.commonService.isChargeLink())
			return this.router.navigate(['/unauthorized']);

		this.listingColumns = this.getListingColumns();
		this.prepareStyle();
		this.getData();
		this.canDeleteStudy = this.usersService.hasAccessPermission(null, 'delete_power_studies', 'write');
	}

	getListingColumns() {
		const columns: any[] = [
			{
				headerName: "studies.name", field: "name",
				cellRendererSelector:function (params) {
					params['label'] = params.data.name;
					let component = 'textCellRenderer';
					if(params.data.request_status != 0) {
						params['link'] = ["/#", params.data.customer_id, params.data.site_id, params.data.mac_address, 'performance', params.data.id].join('/');
						component = 'linkCellRenderer';
					}
					return {
						component,
						params: params
					};
				},
				cellRendererParams: {},
			},
			{
				headerName: "studies.is_done",
				field: "is_done_fmt",
				floatingFilterComponent: "DropDownFilter",
				floatingFilterComponentParams: {
					dropDownOptions: [
						{ label: '', value: null },
						{ value: this.translateService.instant('studies.queued'), label: this.translateService.instant('studies.queued') },
						{ value: this.translateService.instant('studies.status_not_done'), label: this.translateService.instant('studies.status_not_done') },
						{ value: this.translateService.instant('studies.status_done'), label: this.translateService.instant('studies.status_done') },
					]
				},
			}
		];

		if (['admin', 'device'].includes(this.parent))
			columns.push({
				headerName: "studies.customer_name", field: "customer_name",
				cellRendererSelector:function (params) {
					params['label'] = params.data.customer_name;
					params['link'] = ["/#", params.data.customer_id].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: {},
			});

		if (['admin', 'customer', 'device'].includes(this.parent))
			columns.push({
				headerName: "studies.site_name",
				field: "site_name",
				cellRendererSelector:function (params) {
					params['label'] = params.data.site_name;
					params['link'] = ["/#", params.data.customer_id, params.data.site_id].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: {},
			});

		if (['customer', 'admin', 'site'].includes(this.parent))
			columns.push({
				headerName: "studies.serial_number",
				field: "serial_number",
				cellRendererSelector: function (params) {
					params['label'] = params.data.serial_number;
					params['link'] = params.data.has_site_device_acces? ["/#", params.data.device_customer_id, params.data.device_site_id, params.data.mac_address, 'performance'].join('/') : null
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: {},
			});

		if (this.usersService.hasAccessPermission(null, 'noc')) {
			columns.push(
				{headerName: "studies.start_event_id", field: "start_event_id", type: 'number', filterParams: {format: '1.0-0'}},
				{headerName: "studies.end_event_id", field: "end_event_id", type: 'number', filterParams: {format: '1.0-0'}},
			);
		}

		columns.push(
			{headerName: "studies.start_study_time", field: "start_study_time_fmt", type: 'date'},
			{headerName: "studies.end_study_time", field: "end_study_time_fmt", type: 'date'},
			{headerName: "studies.total_kwhs", field: "total_kwhs", type: 'number', filterParams: {format: '1.0-2'}},
			{headerName: "studies.total_ahs", field: "total_ahs", type: 'number', filterParams: {format: '1.0-2'}}
		);

		return columns;
	}

	getData() {
		const params: {parent: 'admin'|'customer'|'site'|'device', customer_id?: number, site_id?: number, from?: number, to?: number, status?: number, mac_address?: string} = { parent: this.parent };

		if (this.customerId)
			params.customer_id = this.customerId;

		if (this.siteId)
			params.site_id = this.siteId;

		if (this.device)
			params.mac_address = this.device.mac_address;

		if(this.dateRange) {
			params.from = moment(this.dateRange.fromDate).utc().unix();
			params.to = moment(this.dateRange.toDate).utc().unix();
		}

		if (this.status)
			params.status = this.status-1;

		this.studiesService.getStudies(params).subscribe((res: any) => {
			this.studies = res;
			this.originalFromDate = moment(this.dateRange.fromDate).utc().unix();
			this.originalToDate = moment(this.dateRange.toDate).utc().unix();
			this.filterResults();
		});
	}

	filterResults() {
		const params: {from?: number, to?: number} = { };
		if(this.dateRange) {
			params.from = moment(this.dateRange.fromDate).utc().unix();
			params.to = moment(this.dateRange.toDate).utc().unix();
			if(params.from < this.originalFromDate || params.to > this.originalToDate) {
				this.getData();
				return;
			}
		}

		// filter this.studies based on the params
		const filteredStudies = this.studies.filter((study: any) => {
			// Apply filters here
			if (params.from && study.start_study_time < params.from) return false; // Filter by start date
			if (params.to && study.end_study_time > params.to) return false; // Filter by end date

			return true; // Return true if the study should be included in the filtered array
		});

		// format the filteredStudies array
		this.filteredStudies = filteredStudies.map((study: any) => {
			study.is_done_fmt = this.translateService.instant('studies.'+(study.request_status == this.requestStatus.queued ? 'queued' : study.is_done ? 'status_done' : 'status_not_done'));
			study.request_status_fmt = this.translateService.instant('studies.'+this.requestStatus[study.request_status]);
			study.start_event_id = study.start_event_id || '-';
			study.end_event_id = study.end_event_id || '-';
			study.start_study_time_fmt = study.start_study_time ? moment(study.start_study_time * 1000).utc().format('YYYY-MM-DD') : '';
			study.end_study_time_fmt = study.end_study_time ? moment(study.end_study_time * 1000).utc().format('YYYY-MM-DD') : '';
			study.total_kwhs = study.total_kwhs || '-';
			study.total_ahs = study.total_ahs || '-';
			return study;
		});
	}

	ngAfterViewInit(): void {
		this.confirmDeleteStudy.onClose.subscribe((ok) => {
			if (ok) {
				this.deletePowerStudies(true);
			} else {
				this.confirmDeleteStudy.hide();
			}
		});
	}

	onDatesChanged(event: any) {
		this.dateRange = event.dates;
		this.invalidDateRange = event.invalidRange;
		if (!this.invalidDateRange)
			this.filterResults();
	}

	prepareStyle() {
		switch(this.parent) {
			case 'admin':
				this.styles = 'white-box m-2 p-4 border-radius-bottom';
				break;
			case 'site':
				this.styles = 'white-box p-4';
				break;
			default:
				break;
		}
	}

	deletePowerStudies(confirmed=false) {
		if (!confirmed) {
			const hasSelectedWrongStudy = this.selectedStudies.filter(study => !study.is_done && study.request_status != 0).length > 0;
			if (hasSelectedWrongStudy)
				return this.sctToastService.setMessage('translate|studies.selected_wrong_study', {clearOnXTimeNavigate: 1, type: 'warning'});

			return this.confirmDeleteStudy.show();
		}

		const selectedStudiesIds = this.selectedStudies.map(study => +study.id);
		this.studiesService.deletePowerStudies(selectedStudiesIds).subscribe((res: any) => {
			switch(res) {
				case 2:
					this.sctToastService.setMessage('translate|studies.selected_wrong_study', {clearOnXTimeNavigate: 1, type: 'warning'});
					break;
				default:
					this.studies = lo.filter(this.studies, study => !selectedStudiesIds.includes(study.id));
					this.getData();
					this.selectedStudies = [];
					this.sctToastService.setMessage('translate|g.deleted_successfully', {clearOnXTimeNavigate: 1, type: 'success'});
					break;
			}
		});
	}

	anyStudySelected(): boolean {
		return this.selectedStudies.length > 0;
	}
	onRowSelected(event: any) {
		this.selectedStudies = event.selection ?? [];
	}

	onNewStudyFormClose(id: number) {
		this.showNewStudyForm = false;
		if(id > 0)
			this.getData();
	}
}
