import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { UsersService } from 'src/app/users/users.service';
import * as _ from 'lodash-es'
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-rma',
	templateUrl: './rma.component.html',
	styleUrls: ['./rma.component.css']
})
export class RmaComponent implements OnInit, AfterViewInit {

	searchString: string = '';
	searchBy: 'mac' | 'sn' = 'mac'; // sn: serial number, mac: mac address
	searchResult: any = null;
	sites: any = [];
	rmaNo: string = '';
	nocEmail: string = 'noc@smartchargetech.com';
	errorText: string = '';

	@ViewChild("rmaNoModal") rmaNoModal: ModalComponent;
	@ViewChild("rmaConfirmation") rmaConfirmation: ModalComponent;
	@ViewChild("nocEmailRmaModal") nocEmailRmaModal;

	constructor(
		private router: Router,
		public userService: UsersService,
		private devicesService: DeviceService,
		private sideMenuService: SideMenuService,
		private deviceManagementService: DeviceManagementService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService
	) { }

	ngOnInit(): void {
		this.sideMenuService.hide();
		if (!this.userService.hasAccessFunction('shipment_management'))
			this.router.navigate(['unauthorized']);
	}

	ngAfterViewInit(): void {
		this.rmaNoModal.onClose.subscribe((result: any) => {
			this.rmaNo = '';
			this.errorText = '';
		});
	}

	getDevices() {
		this.deviceManagementService.searchDevices(this.searchString, this.searchBy, false, false, 'rma').subscribe((data: any) => {
			this.searchResult = data.devices || [];
			this.sites = _.keyBy(data.sites || [], 'id');
			this.rmaNo = (this.searchResult[0] && this.searchResult[0].rmaNo);
		});
	}

	moveDeviceToRMA() {
		this.errorText = '';
		this.rmaNo = this.rmaNo.trim();

		if (!this.rmaNo)
			return this.errorText = this.translateService.instant('rma.rma_no_required');

		const isValidRmaNo = this.devicesService.validateRmaNo(this.rmaNo);
		if (!isValidRmaNo)
			return this.errorText =  this.translateService.instant('rma.rma_no_invalid');

		this.deviceManagementService.moveDeviceToRMA(this.searchResult[0].mac_address, this.rmaNo).subscribe((data: any) => {
			if (data.api_status == 2)
				return this.errorText = this.translateService.instant('rma.rma_no_invalid');
			else if(data.api_status == 3)
				return this.errorText = this.translateService.instant('rma.rma_used');

			this.searchResult = null;
			this.searchString = '';
			this.rmaNo = '';
			this.rmaConfirmation.hide();
			this.rmaNoModal.hide();
			this.notificationMessage.setMessage('globalSuccessMsg');
			return this.nocEmailRmaModal.show();
		});
	}

}
