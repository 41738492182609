import { Component, OnInit, TemplateRef, Input, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-modal',
	templateUrl: './modal.component.html',
	styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

	@Input('closeOnConfirm') closeOnConfirm = true;
	@Input('hasConfirmButton') hasConfirmButton = true;
	@Input('hasCloseButton') hasCloseButton = true;
	@Input('title') title;
	@Input('closeBtnText') closeBtnText = '';
	@Input('confirmBtnText') confirmBtnText = '';
	@Input('confirmBtnClass') confirmBtnClass = 'btn-primary';
	@Input('size') size = 'md';
	@Input('modalComponent') modalComponent;
	@Input('passData') passData: any;
	@Input('message') message: any;
	@Input('disableConfirm') disableConfirm = false;
	@ViewChild("modalTemplate") modalTemplate: TemplateRef<any>;
	modalRef: BsModalRef;
	onClose: Subject<boolean>;
	render: boolean = false;
	settings = {
		animated: true,
		preventEscape: false,
		staticModal: false,
		backdrop: true
	};

	constructor(
		private modalService: BsModalService,
		public translate: TranslateService
	) {
	}

	ngOnInit() {
		this.closeBtnText = this.translate.instant(this.closeBtnText || 'g.close');
		this.confirmBtnText = this.translate.instant(this.confirmBtnText || 'g.confirm');
		this.onClose = new Subject();
		this.modalService.onHide.subscribe(
			event => {
				if((event == 'esc' || event == 'backdrop-click') && this.render)
					this.onHide();
			}
		);
	}

	show() {
		this.render = true;
		this.modalRef = this.modalService.show(this.modalTemplate, this.getConfig());
	}

	hide() {
		if(this.modalRef) this.modalRef.hide();
		this.render = false;
	}

	onHide(): void {
		let returnData: any = false;
		if(this.passData)
			returnData = [returnData, this.passData];
		this.onClose.next(returnData);
		this.hide();
	}

	onConfirm(): void {
		let returnData: any = true;
		if(this.passData)
			returnData = [returnData, this.passData];
		this.onClose.next(returnData);
		if(this.closeOnConfirm)
			this.hide();
	}

	getConfig() {
		const configObject: any = {};
		if (this.settings.staticModal) {
			configObject.backdrop = "static";
		}
		if (this.settings.preventEscape) {
			configObject.keyboard = false;
		}
		configObject.animated = this.settings.animated;
		configObject.backdrop = this.settings.backdrop;
		configObject.class = 'modal-' + this.size;
		return configObject;
	}

}