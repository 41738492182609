import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';

@Component({
  	selector: 'app-upload-fw-files',
  	templateUrl: './upload-fw-files.component.html',
})

export class UploadFwFilesComponent implements OnInit {
	file = null;
	maxAllowedFileSize: number = 15000000;
	fileName: string = '';
	fwVersion: any = 0;
	successFile: boolean;
	successSize: boolean;
	fwList: String[] = [];

	constructor (
		private router: Router,
		private userService: UsersService,
		private adminService: AdminService,
		private notificationMessage: NotificationMessageService,
		private deviceManagementService: DeviceManagementService
	) { }

	ngOnInit() {
		if(!this.userService.hasAccessFunction('upload_fw_files'))
			this.router.navigate(['unauthorized']);

		this.getTestingFWFilesList();
	}

	checkFile(file: any) {
		const uploadedFile = file.target.files[0];
		this.fileName = uploadedFile.name;

		this.successFile = uploadedFile.type.startsWith('application');
		this.successSize = uploadedFile.size <= this.maxAllowedFileSize && uploadedFile.size > 0;

		if(!this.successSize || !this.successFile)
	  		return;

		this.file = uploadedFile;
	}

	getTestingFWFilesList() {
		this.deviceManagementService.getTestingFWFilesList().subscribe((res: []) => {
			this.fwList = res;
		});
	}

	upload() {
		if(!this.file || !this.fwVersion)
			return;

		const formData = new FormData();

		formData.append('file', this.file);
		formData.append('version', this.fwVersion);

		this.adminService.uploadFWFile(formData).subscribe(res => {
			if (res == 1) {
				this.fwList.push((+this.fwVersion).toFixed(4));
				return this.notificationMessage.setMessage('globalSuccessMsg');
			}

			return this.notificationMessage.setMessage(`translate|g.${res}`);
		})
	}

	deleteFWFile(file: string) {
		this.deviceManagementService.deleteFWFile(file).subscribe((res: any) => {
			this.getTestingFWFilesList();
			return this.notificationMessage.setMessage('globalSuccessMsg');
		});
	}
}
