import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import * as _ from 'underscore';

@Component({
	selector: 'app-warnings',
	templateUrl: './warnings.component.html',
	styleUrls: ['./warnings.component.css']
})
export class WarningsComponent implements OnInit {
	constructor(private usersService: UsersService, private router: Router) { }

	ngOnInit() {
			if(!this.usersService.hasAccessPermission(null,'noc')) {
				return this.router.navigate(['/unauthorized']);
			}
	}
}
