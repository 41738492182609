<div>
	<h4 class="ml-2 mt-2">{{'site.zombie_sites' | translate}}</h4>
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.name' | translate}}</th>
				<th>{{'site_edit.country' | translate}}</th>
				<th>{{'site_edit.state' | translate}}</th>
				<th>{{'site_edit.address' | translate}}</th>
				<th>{{'site.modification_date' | translate}}</th>
				<th>{{'g.action' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr 
				*ngFor="let site of sites">
				<td>{{site.name}}</td>
				<td>{{site.country_id}}</td>
				<td>{{site.state_id}}</td>
				<td>{{site.address}}</td>
				<td>{{site.modification_date | date:'MM/dd/yyyy'}}</td>
				<td>
					<div class="btn-group mx-3" dropdown placement="top left" [dropup]="true">
						<fa-icon icon="cog" size="lg" dropdownToggle></fa-icon>
						<ul *dropdownMenu class="dropdown-menu dropdown-menu-right" role="menu">
							<li role="menuitem" *ngIf="usersService.hasAccessFunction('site_management')">
								<a class="dropdown-item pointer" (click)="goToEditSite(site.id)">{{'g.edit' | translate}}</a>
							</li>
							<li *ngIf="!site.is_special && usersService.hasAccessFunction('site_management')" role="menuitem">
								<a class="dropdown-item pointer" (click)="confirmDeleteSite(site.id)">{{'g.delete' | translate}}</a>
							</li>
							<li role="menuitem">
								<a class="dropdown-item pointer" [routerLink]="['/devices', customerId, site.id]">{{'g.devices' | translate}}</a>
							</li>
							<li role="menuitem" *ngFor="let permission of site['site_enterprise']">
								<a class="dropdown-item pointer" [routerLink]="['/admin-panel/permission-edit', permission.permission_id]" [queryParams]="{customerId: customerId}">{{'site.permission' | translate}} ({{permission.enterprise?.name}})</a>
							</li>
						</ul>
					</div>
				</td>
			</tr>	
		</tbody>
	</table>
</div>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>