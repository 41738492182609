<div>
	<div *ngIf="usersService.hasAccessFunction('site_management')">
		<button class="btn btn-primary mb20" type="button" [routerLink]="['/sites', customerId, 'edit', 0]">
			{{'g.add_new' | translate}}<fa-icon icon="plus"></fa-icon>
		</button>
	</div>
	<div class="boxs">
		<table class="table table-striped">
			<thead>
				<tr>
					<th>{{'g.name' | translate}}</th>
					<th>{{'g.enterprise_name' | translate}}</th>
					<th>{{'site_edit.country' | translate}}</th>
					<th>{{'site_edit.state' | translate}}</th>
					<th>{{'site_edit.address' | translate}}</th>
					<th>{{'site.modification_date' | translate}}</th>
					<th>{{'g.action' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let site of sites">
					<td>{{site.name}}</td>
					<td>{{getEnterpriseNameList(site.site_enterprise)}}</td>
					<td>{{site.country_id}}</td>
					<td>{{site.state_id}}</td>
					<td>{{site.address}}</td>
					<td>{{site.modification_date | date:'MM/dd/yyyy'}}</td>
					<td>
						<div class="btn-group mx-3" dropdown placement="left">
							<fa-icon icon="cog" size="lg" dropdownToggle class="dropdown-toggle"></fa-icon>
							<ul *dropdownMenu class="dropdown-menu" role="menu">
								<li role="menuitem" *ngIf="usersService.hasAccessFunction('site_management')">
									<a class="dropdown-item pointer" (click)="goToEditSite(site.id)">{{'g.edit' | translate}}</a>
								</li>
								<li *ngIf="canDeleteSite(site)" role="menuitem">
									<a class="dropdown-item pointer" (click)="deleteModal.passData = site.id; deleteModal.show();">{{'g.delete' | translate}}</a>
								</li>
								<li role="menuitem" *ngIf="usersService.hasAccessFunction('device_management')">
									<a class="dropdown-item pointer" [routerLink]="['/devices', customerId, site.id]">{{'g.devices' | translate}}</a>
								</li>
								<li role="menuitem" *ngFor="let permission of site['site_enterprise']">
									<a class="dropdown-item pointer" [routerLink]="['/admin-panel/permission-edit', permission.permission_id]" [queryParams]="{customerId: customerId}">{{'site.permission' | translate}} ({{permission.enterprise?.name}})</a>
								</li>
							</ul>
						</div>
					</td>
				</tr>	
			</tbody>
		</table>
	</div>
</div>

<app-modal #deleteModal title="{{'site.delete_site' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'g.del_site_prompt' | translate}}
</app-modal>