import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';
import _ from 'lodash';
import { saveAs } from 'file-saver';
import { Subscription } from 'rxjs';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

@Component({
	selector: 'app-smart-rebates-sites',
	templateUrl: './smart-rebates-sites.component.html',
	styleUrls: ['./smart-rebates-sites.component.css']
})
export class SmartRebatesSitesComponent implements OnInit {
	@ViewChild("consumptionsReportDialog") consumptionsReportDialog: any;
	@ViewChild("bulkExportConsumptionsReportDialog") bulkExportConsumptionsReportDialog: any;
	@ViewChild("defectiveDevicesModal") defectiveDevicesModal: ModalComponent;

	downloadConsumptionsReportSubscription!: Subscription;
	selectedYear: number = new Date().getFullYear();
	selectedSiteIds: number[] = [];
	isValidDate = true;

	quarters = [
		{id: 1, value:'Q1'},
		{id: 2, value:'Q2'},
		{id: 3, value:'Q3'},
		{id: 4, value:'Q4'}
	];

	selectedQuarter = this.quarters[0].id;
	years = [];
	selectedStartDate: any = 0;

	data: any[] = [];

	MONTHS_LIST = this.commonDataService.MONTHS_LIST;
	selectedSitesToExport = [];
	periodTypes = {
		yearly: 0,
		quarterly: 1,
		monthly: 2,
	};
	periodTypesItems = [
		{ id: this.periodTypes.yearly, value: this.translate.instant('g.yearly') },
		{ id: this.periodTypes.quarterly, value: this.translate.instant('g.quarterly') },
		{ id: this.periodTypes.monthly, value: this.translate.instant('g.monthly') },
	];
	selectedPeriod = this.periodTypesItems[0].id;
	monthlyPeriod: { from: { year: number, month: number }, to: { year: number, month: number } } = {
		from: { year: null, month: null },
		to: { year: null, month: null },
	};

	selectedReport = null;

	constructor(
		private router: Router,
		private sitesService: SitesService,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		private notificationMessage: NotificationMessageService,
		public translate: TranslateService,
		public commonDataService: CommonDataService,
		private commonService: CommonService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		if (!(this.usersService.hasAccessFunction('official_reporting') || this.usersService.hasAccessFunction('site_management')))
			return this.router.navigate(['/unauthorized']);
		return this.getSmartRebatesSitesInfo();
	}

	ngAfterViewInit() {

		this.bulkExportConsumptionsReportDialog.onClose.subscribe((result: any) => {
			if (result) {
				this.downloadConsumptionsReport();
			}
		});
	}

	getSmartRebatesSitesInfo() {
		this.sitesService.getSmartRebatesSitesInfo().subscribe((data: any) => {
			this.data = data;
			this.data = this.commonService.sortDataAlphabetically(this.data, 'customer_name');
		})
	}

	bulkExportConsumptionsReport(selectedSite = null) {
		let installationDates = [];
		this.selectedSiteIds = [];

    if(selectedSite) {
      this.selectedSiteIds = [+selectedSite.id];
      installationDates = [moment(selectedSite.installation_date).unix()];
    } else {
      for (const site of this.selectedSitesToExport) {
        this.selectedSiteIds.push(site.id);
        installationDates.push(moment(site.installation_date).unix());
      }
    }

		const bulkInstallationDate = _.min(installationDates);
		const startDate = new Date(bulkInstallationDate * 1000);
		this.getAllYearsFromDate(startDate);
		this.bulkExportConsumptionsReportDialog.show();
	}

	getAllYearsFromDate(startDate) {
		if (!startDate)
			return

		const currentDate = new Date();
		const startYear = startDate.getFullYear();
		const currentYear = currentDate.getFullYear();
		const years = [];

		for (let year = startYear; year <= currentYear; year++) {
			years.push(year);
		}

		if (!years.length)
			years.push(currentYear);

		this.selectedYear = years[years.length -1];
		this.years = years;
	}

	getReportConsumptionsPeriod() {
		let fromTime: number, toTime: number = 0;
		switch (this.selectedPeriod) {
			case this.periodTypes.yearly:
				fromTime = moment.utc(this.selectedYear, 'YYYY').startOf('year').utc().unix();
				toTime = moment.utc(this.selectedYear, 'YYYY').endOf('year').utc().unix();
				break;
			case this.periodTypes.quarterly:
				fromTime = moment.utc(this.selectedYear, 'YYYY').quarter(this.selectedQuarter).startOf('quarter').utc().unix();
				toTime = moment.utc(this.selectedYear, 'YYYY').quarter(this.selectedQuarter).endOf('quarter').utc().unix();
				break;
			case this.periodTypes.monthly:
				const zoneDiff = (new Date().getTimezoneOffset() * -1) * 60;
				fromTime = moment().year(this.monthlyPeriod.from.year).month(this.monthlyPeriod.from.month - 1).startOf('month').utc().unix() + zoneDiff;
				toTime = moment().year(this.monthlyPeriod.to.year).month(this.monthlyPeriod.to.month - 1).endOf('month').utc().unix() + zoneDiff;
				break;
		}

		return { fromTime, toTime };
	}

	checkIsValidDate() {
		this.isValidDate = true;
		const from = this.monthlyPeriod.from;
		const to = this.monthlyPeriod.to;

		if (this.selectedPeriod == this.periodTypes.monthly &&  (
				(!from.year || !from.month || !to.year || !to.month) || // not all field has value
				(from.year > to.year || (from.year == to.year && from.month > to.month)) // from > to
			)
		)
			this.isValidDate = false;
	}

	downloadConsumptionsReport() {
		const selectedStartDate = moment(this.selectedStartDate || 0).utc().startOf('day').unix() || 0;

		const { fromTime, toTime } = this.getReportConsumptionsPeriod();

		this.downloadConsumptionsReportSubscription = this.sitesService.downloadConsumptionsReport(fromTime, toTime, this.selectedSiteIds, selectedStartDate).subscribe((res: any) => {
			switch (res.api_status) {
				case 2:
					this.notificationMessage.setMessage(this.translate.instant('orders.not_sr_site'));
					break;
				case 3:
					this.notificationMessage.setMessage(this.translate.instant('orders.no_devices_founded'));
					break;
				case 4:
					this.notificationMessage.setMessage(this.translate.instant('smart_rebates_reports.no_data'));
					break;
				default:
					this.downloadReport(res);
					break;
			}
		});
	}

	downloadReport(data: any) {
		if (!data) return;
		const arr = new Uint8Array(data.content.data);
		const blob = new Blob([arr]);
		if (blob)
			saveAs(blob, data.name);
	}

	selectSite(checked: boolean, site: { id: number, installation_date: number }) {
		if (checked) {
			this.selectedSitesToExport.push({ id: +site.id, installation_date: site.installation_date });
		} else {
			this.selectedSitesToExport = this.selectedSitesToExport.filter((row: { id: number, installation_date: number }) => {
				return row.id !== +site.id;
			});
		}
	}

	openDefectiveDevicesModal(report: any) {
		this.selectedReport = report;
		this.defectiveDevicesModal.show();
	}

	ngOnDestroy() {
		if (this.downloadConsumptionsReportSubscription)
			this.downloadConsumptionsReportSubscription.unsubscribe();
	}
}
