import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from '../../device-management.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import * as _ from 'underscore';
import { SitesService } from 'src/app/sites/sites.service';

@Component({
	selector: 'app-device-requests',
	templateUrl: './device-requests.component.html',
	styleUrls: ['./device-requests.component.css']
})
export class DeviceRequestsComponent implements OnInit, AfterViewInit {

	requestsList: any;
	selectedRequest: number = 0;
	selectedRequests: any[] = [];
	deviceOwners: any;
	selectedAll: boolean = false;
	selectedCount: number = 0;
	usersInfo: any = {};
	enterprisesInfo: any = {};
	devicesInfo: any[] = [];
	locationsArr: any[] = [];

	@ViewChild("updateSetupDone") updateSetupDone;
	@ViewChild("updateSetupDoneMulti") updateSetupDoneMulti;
	@ViewChild("confirmModal") confirmModal;
	@ViewChild("locationModal") locationModal;

	constructor(
		private notificationMessageService: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		public usersService: UsersService,
		public enterpriseService: EnterpriseService,
		public sitesService: SitesService,
	) { }

	ngOnInit() {
		this.getMoveRequests();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			let requests;
			switch(result[1][0]) {
				case 'approveRequest':
					this.approveRequest(result[1][1]);
				break;
				case 'rejectRequest':
					this.rejectRequest(result[1][1]);
				break;
				case 'approveRequestMulti':
					requests = this.getSelectedRequests();
					this.approveRequest(requests);
				break;
				case 'rejectRequestMulti':
					requests = this.getSelectedRequests();
					this.rejectRequest(requests);
				break;
			}
		});
	}

	confirmAction(action, data?) {
		this.confirmModal.passData = [action, data];
		switch(action) {
			case 'approveRequest':
				this.confirmModal.message = 'device_management.approve_move_prompt';
			break;
			case 'rejectRequest':
				this.confirmModal.message = 'device_management.reject_move_prompt';
			break;
			case 'approveRequestMulti':
				this.confirmModal.message = 'device_management.approve_multi_move_prompt';
			break;
			case 'rejectRequestMulti':
				this.confirmModal.message = 'device_management.reject_multi_move_prompt';
			break;
		}
		this.confirmModal.show();
	}
	
	getMoveRequests() {
		this.selectedAll = false;
		this.selectedCount = 0;
		this.devicesInfo = [];
		this.deviceManagementService.getMoveRequests({get_sn: true}).subscribe((res) => {
			this.requestsList = res;
			let deviceIds		= _.chain(res).pluck('mac_address').uniq().value();
			let userIds			= _.chain(res).pluck('user_id').uniq().value();
			let enterpriseIds	= _.chain(res).pluck('enterprise_id').uniq().value();
			this.usersService.getUsersInfo(userIds).subscribe((res) => {
				this.usersInfo = _.indexBy(res, 'id');
			});
			this.enterpriseService.getEnterprise(enterpriseIds, {source: 'manage_device', multi: true}).subscribe((res) => {
				this.enterprisesInfo = _.indexBy(res, 'id');
			});
			
			if(deviceIds.length) {
				this.deviceManagementService.getDeviceOwners(deviceIds).subscribe((res: any) => {
					this.devicesInfo = _.indexBy(res.devices, 'mac_address');
					this.deviceOwners = _.chain(res.customersAndSites).pluck('sites').flatten(true).indexBy('id').value();
				});
			}
		});
	}

	onBeforeApproveRequest(request) {
		if(!request.setup_done && !request.is_special) {
			// show modal to update setup done flag
			this.selectedRequest = request.id;
			return this.updateSetupDone.show();
		}

		this.confirmAction('approveRequest', request.id);
	}

	onBeforeApproveRequestMulti() {
		let requests = this.getSelectedRequests(true);

		this.selectedRequests = [];
		let showSetupDoneModal = false;
		requests.forEach((request) => {
			if(!request.setup_done && !request.is_special) {
				// show modal to update setup done flag
				this.selectedRequests.push({id: request.id, mac_address: request.mac_address});
				showSetupDoneModal = true;
			}
		});
		if(showSetupDoneModal)
			return this.updateSetupDoneMulti.show();
		this.confirmAction('approveRequestMulti', requests);
	}

	approveRequest(id, changeSetupDone = false) {
		this.approveMoveRequest(id, {changeSetupDone});
	}

	approveRequestMulti(changeSetupDone = false) {
		let reqIds = this.getSelectedRequests();
		let setupDoneDevices = [];
		if(changeSetupDone)
			setupDoneDevices = _.pluck(this.selectedRequests, 'mac_address');
		this.approveMoveRequest(reqIds, {setupDoneDevices});
	}
	
	approveMoveRequest(reqIds, options) {
		this.deviceManagementService.approveMoveRequest(reqIds, options).subscribe((res: any) => {
			if (res.api_status > 1) {
				switch (res.api_status) {
					case 2:
						return this.notificationMessageService.setMessage('translate|site.site_not_found', { clearOnXTimeNavigate: 1, type: 'danger' });
				}
			}
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getMoveRequests();
		});
	}
	
	rejectRequest(id) {
		this.deviceManagementService.rejectMoveRequest(id).subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getMoveRequests();
		});
	}

	selectAll(checked) {
		for (let itemIdx in this.requestsList) {
			let item = this.requestsList[itemIdx];
			item.selected = checked;
			this.selectRow(checked);
		}
	}

	selectRow(checked) {
		if (checked) {
			this.selectedCount++;
		} else {
			this.selectedCount--;
			this.selectedAll = false;
		}
		
		if (this.selectedCount <= 0) {
			this.selectedCount = 0;
		}

		if(this.selectedCount >= Object.keys(this.requestsList).length) {
			this.selectedCount = Object.keys(this.requestsList).length;
			this.selectedAll = true;
		}
	}

	getSelectedRequests(getInfo?) {
		let selectedDevices = [];
		this.requestsList.forEach((item) => {
			if(item.selected) {
				let data = getInfo ? item : item.id;
				selectedDevices.push(data);
			}
		});
		return selectedDevices;
	}

	showDeviceLocation(request) {
		this.locationsArr = [];
		this.locationModal.show();
		this.locationsArr = [{
			latitude: this.devicesInfo[request.mac_address].latitude,
			longitude: this.devicesInfo[request.mac_address].longitude
		}, {
			latitude: this.deviceOwners[request._new_site].latitude,
			longitude: this.deviceOwners[request._new_site].longitude
		}];
	}
}
