<div class="mt-3 mb-2 ml-2 mr-2">
	<div class="row mt-1">
		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'update_firmware_log.mac_address' | translate}}:</span>
				</div>
				<input pInputText type="text" class="form-control inline-form-control" [(ngModel)]="filter.macAddress" />
			</div>
		</div>
		<div class="col-md-6">
			<button class="form-control btn btn-success"
				(click)="getFirmwareUpdateLog(false, true)">{{'g.get_logs' | translate}}</button>
		</div>
	</div>
	<table  class="table table-striped">
		<thead>
			<tr>
				<th>{{'update_firmware_log.mac_address' | translate}}</th>
				<th>{{'update_firmware_log.old_version' | translate}}</th>
				<th>{{'update_firmware_log.new_version' | translate}}</th>
				<th>{{'g.status' | translate}}</th>
				<th>{{'g.step' | translate}}</th>
				<th>{{'update_firmware_log.time' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let log of logs">
				<td>
					{{log.mac_address}}
				</td>
				<td>
					{{log.old_version}}
				</td>
				<td>
					{{log.new_version}}
				</td>
				<td>
					{{((log.status ? 'update_firmware_log.'+firmwareUpdateStatuses[log.status] : 'g.n/a') | translate)}}
				</td>
				<td>
					{{log.step || ('g.n/a' | translate)}}
				</td>
				<td>
					{{log.created_at | date:'long' }}
				</td>
			</tr>
		</tbody>
	</table>
	<server-pagination idField="id" [limit]="limit" (paginate)="getFirmwareUpdateLog($event)" #serverPagination>
	</server-pagination>
</div>