import { Component, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../../admin.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { ModalComponent } from '../../../shared/modal/modal.component';
@Component({
	selector: 'app-unused-logs',
	templateUrl: './unused-logs.component.html',
	styleUrls: []
})
export class UnusedLogsComponent implements OnInit {
	logs: any[] = [];
	columnNames: any[];
	title: string = '';

	@ViewChild("confirmModal") confirmModal: ModalComponent;

	constructor(
		private adminService: AdminService,
		private translateService: TranslateService,
		private router: Router,
		private usersService: UsersService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessFunction('access_error_logs')) {
			this.router.navigate(['/unauthorized']);
			return;
		}

		this.title = this.translateService.instant('unused_logs.unused_logs_title');
		// fieldName,command,struct,startPosition,fieldLength,fwVersion,isBit,count
		this.columnNames = [
			{name: this.translateService.instant('unused_logs.field_name'), key: 'fieldName'},
			{name: this.translateService.instant('unused_logs.command'), key: 'command'},
			{name: this.translateService.instant('unused_logs.struct'), key: 'struct'},
			{name: this.translateService.instant('unused_logs.start_position'), key: 'startPosition'},
			{name: this.translateService.instant('unused_logs.field_length'), key: 'fieldLength'},
			{name: this.translateService.instant('unused_logs.fw_version'), key: 'fwVersion'},
			{name: this.translateService.instant('unused_logs.is_bit'), key: 'isBit'},
			{name: this.translateService.instant('unused_logs.count'), key: 'count'},
			{name: this.translateService.instant('g.options'), key: 'options', type: 'options', optionsList: [{'action': 'view', 'text':'View'}]},
		];
		this.adminService.getUnusedLogs().subscribe(
			(response: any) => {
				this.logs = response;
			}
		);
	}

	doAction(event) {
		if(event.action == 'view') {
			this.confirmModal.passData = this.logs.find((item: any) => item.id == event.element.id).macList;
			this.confirmModal.show();
		}
	}
}