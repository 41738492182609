import { Component, OnInit, Input } from '@angular/core';
import { DeviceService } from '../../device.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import * as moment from 'moment';

@Component({
	selector: 'app-daily-details',
	templateUrl: './daily-details.component.html',
	styleUrls: ['./daily-details.component.css']
})
export class DailyDetailsComponent implements OnInit {

	@Input() device: any = {};
	@Input() currentSite: any;
	@Input() dateRange: any = {};
	@Input() invalidDateRange: boolean = false;
	@Input() studyId: number = 0;

	data: any[] = [];
	hasGrid: boolean = false;

	columnDefs: any[] = [];
	rowData: any[] = [];

	currentUser: any = {};

	allColumnsKeys: string[] = [
		"date",
		"accurate_inuse_seconds",
		"accurate_charge_seconds",
		"total_off_time",
		"is_complete",
		"is_working_day",
		"day_type",
		"charge_kwhr",
		'charge_kwhr_ld',
		'charge_kwhr_hd',
		"inuse_kwhr",
		"charge_ahr",
		"inuse_ahr",
		"charge_events_ahr",
		"charge_events_kwhr",
		"inuse_events_ahr",
		"inuse_events_kwhr",
		"billed_kwh",
		"total_charge_events",
		"total_inuse_events",
		"charge_duration",
		"inuse_duration",
		"total_idle_events",
		"max_temperature_value",
		"min_temperature_value",
		"max_temperature_exceeded",
		"min_temperature_exceeded",
		"deep_discharge_value",
		"deep_discharge_exceeded",
		"potential_week_cells_exceeded",
		"inuse_max_current",
		"charge_max_current",
		"charge_oppurtinity",
		"sensor_issue",
		"temperature_sensor_enabled",
		"idle_times_distribution",
		"non_plugged_in_idle_times",
		"events_ids",
		"idle_duration",
		"charge_oppurtinity_duration",
		"water_level_low",
		"lift_time",
		"travel_time"
	];

	fieldsToHideInChargLinkDevices: string[] = [
		'billed_kwh',
		'accurate_inuse_seconds',
		"lift_time",
		"travel_time"
	];

	constructor(
		private deviceService: DeviceService,
		private commonService: CommonService,
		private translateService: TranslateService,
		private usersService: UsersService
	) { }

	ngOnInit() {

		this.usersService.currentUser.subscribe((user: any) => {
			if(user && user.id) {
				this.currentUser = user;
			}
		});
	}

	getData() {
		let fromDateRange = this.dateRange.fromDate;
		let toDateRange = this.dateRange.toDate;

		if (!this.device || !this.device.mac_address || !fromDateRange || !toDateRange) return;

		let zoneDiff		= new Date().getTimezoneOffset() * -1;
		let fromDate:any	= new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000));
		let toDate:any		= new Date(new Date(toDateRange).getTime() + (zoneDiff * 60 * 1000));
		fromDate			= moment(fromDate).utc().startOf('day').unix();
		toDate				= moment(toDate).utc().endOf('day').unix();

		this.deviceService.getDailyDetails(this.device.mac_address, fromDate, toDate, {}, this.studyId).subscribe((data: any) => {
			this.formatData(data);
			this.buildGrid();
		});
	}

	buildGrid() {
		if(this.data.length === 0)
			return;

		let gridColumns		= [],
			existsColumns	= Object.keys(this.data[0]);

		let booleanFields = [
			'is_complete',
			'is_working_day',
			'temperature_sensor_enabled',
			'water_level_low',
			'sensor_issue',
		];
		let floatFields = [
			'charge_events_ahr',
			'charge_ahr',
			'charge_events_kwhr',
			'charge_kwhr',
			'charge_kwhr_ld',
			'charge_kwhr_hd',
			'inuse_events_ahr',
			'inuse_ahr',
			'inuse_events_kwhr',
			'inuse_kwhr',
			'max_temperature_value',
			'min_temperature_value',
			'deep_discharge_value',
			'inuse_max_current',
			'charge_max_current',
			'billed_kwh'
		];
		let integerFields = [
			'total_charge_events',
			'total_inuse_events',
			'total_idle_events',
			'max_temperature_exceeded',
			'min_temperature_exceeded',
			'deep_discharge_exceeded',
			'potential_week_cells_exceeded',
			'charge_oppurtinity',
		];

		let hiddenFields = [
			'is_complete',
			'is_working_day',
			'day_type',
			'charge_kwhr',
			'charge_kwhr_ld',
			'charge_kwhr_hd',
			'charge_ahr',
			'max_temperature_value',
			'min_temperature_value',
			'max_temperature_exceeded',
			'min_temperature_exceeded',
			'deep_discharge_value',
			'deep_discharge_exceeded',
			'potential_week_cells_exceeded',
			'inuse_max_current',
			'charge_max_current',
			'charge_oppurtinity',
			'sensor_issue',
			'temperature_sensor_enabled',
			'idle_times_distribution',
			'non_plugged_in_idle_times',
			'events_ids',
			'charge_oppurtinity_duration',
			'water_level_low',
		];

		let longFieldNames = [
			'potential_week_cells_exceeded',
			'charge_oppurtinity_duration',
		];

		let durationFields = [
			"idle_duration",
			"charge_oppurtinity_duration",
			"charge_duration",
			"inuse_duration",
			"accurate_inuse_seconds",
			"accurate_charge_seconds",
			"total_off_time",
			"lift_time",
			"travel_time"
		];

		let dateFields = [
			"date"
		];

		let NocAccessFields = [
			'billed_kwh'
		]

		let helpFields = [
			'date',
			'accurate_inuse_seconds',
			'total_off_time',
			'charge_kwhr',
			'inuse_kwhr',
			'charge_ahr',
			'inuse_ahr',
			'total_charge_events',
			'inuse_events_kwhr',
			'total_inuse_events',
			'total_idle_events',
			'charge_duration',
			'inuse_duration',
			'inuse_max_current',
			'idle_duration',
			'charge_events_ahr',
			'charge_events_kwhr',
		];

		for(let field of this.allColumnsKeys) {
			if(this.fieldsToHideInChargLinkDevices.includes(field) && this.device.config_info.is_charglink)
				continue;

			let colDef: any = {};
			if(!existsColumns.includes(field))
				continue;
			if(booleanFields.includes(field)) {
				colDef = {type: 'boolean'};
			} else if(integerFields.includes(field)) {
				colDef = {type: 'number', filterParams: {format: 'noFormat'}};
			} else if(floatFields.includes(field)) {
				colDef = {type: 'number', filterParams: {format: '1.0-1'}};
			} else if(durationFields.includes(field)) {
				colDef = {
					filter: 'agTextColumnFilter',
					filterValueGetter: 'data.'+field,
					filterParams: {
						filterOptions: ['equals', 'greaterThan', 'lessThan'],
						defaultOption: 'equals',
						thisObject: this,
						textMatcher: ({ filterOption, value, filterText }: { filterOption: any, value: any, filterText: string }) => {
							return this.commonService.durationFormatter({filter: filterOption, value, filterText});
						}
					}
				};
			} else if(dateFields.includes(field)) {
				colDef = {type: 'date'};
			} else if(field == 'day_type') {
				colDef = {
					floatingFilterComponent: "DropDownFilter",
					floatingFilterComponentParams: {dropDownOptions: [
						{label: '', value: null},
						{value: 'c', label: this.translateService.instant('daily_details.charging_day')},
						{value: 'w', label: this.translateService.instant('daily_details.working_day')}
					]},
				};
			}
			if(hiddenFields.includes(field))
				colDef.hide = true;
			if(longFieldNames.includes(field))
				colDef.width = 400;

			if(NocAccessFields.includes(field) && !this.usersService.userHasNOCAccess())
				continue;

			if(helpFields.includes(field))
				colDef.headerTooltip = this.translateService.instant('grid_help.daily_details.'+field);

			if(durationFields.includes(field))
				colDef = Object.assign({headerName: 'daily_details.'+field, field: field+"_formatted", colId: field+"_formatted"}, colDef);
			else
				Object.assign(colDef, {headerName: 'daily_details.'+field, field: field, colId: field});
			gridColumns.push(colDef);
		}

		this.columnDefs = gridColumns;
		this.hasGrid = existsColumns.length > 0;
	}

	formatData(data) {
		let wsToKwhrRatio = 3600000;
		let dateFields = [
			"date"
		];
		let durationFields = [
			"idle_duration",
			"charge_oppurtinity_duration",
			"charge_duration",
			"inuse_duration",
			"accurate_inuse_seconds",
			"accurate_charge_seconds",
			"total_off_time",
			"lift_time",
			"travel_time"
		];
		let temperatureFields = [
			"min_temperature_value",
			"max_temperature_value"
		];
		let floatFields = [
			'deep_discharge_value',
			'inuse_max_current',
			'charge_max_current',
		];
		let dailyDetails = [];
		for(let elementIdx in data) {

			let dayData = data[elementIdx];
			if(!dayData || !dayData.date)
				continue;

			if(this.device.config_info.is_charglink && this.currentSite.state_id != 'Oregon')
				dayData.charge_kwhr = dayData.billed_kwh;

			Object.keys(dayData).forEach((field) => {
				if(dateFields.includes(field)) {
					dayData[field] = moment(dayData[field]*1000).utc().format('MM/DD/YYYY');
				} else if(temperatureFields.includes(field)) {
					dayData[field] = this.commonService.fahToCel(dayData[field], true);
				} else if(durationFields.includes(field)) {
					dayData[field+"_formatted"] = this.commonService.timeFormat(dayData[field] || 0, {limitToOneDay: true});
				} else if(floatFields.includes(field)) {
					dayData[field] = +(dayData[field]/1.0).toFixed(1);
				}
			});

			if(this.usersService.hasAccessFunction('dev_team')) {
				let dayType = [];
				if(dayData.is_working_day)
					dayType.push("W");
				if(dayData.total_charge_events !== undefined && dayData.total_charge_events > 0)
					dayType.push("C");
				dayData.day_type = dayType.join(' ');
			}

			if(dayData)
				dailyDetails.push(dayData);
		}

		this.data = dailyDetails;
	}

	ngOnChanges() {
		this.getData();
	}
}
