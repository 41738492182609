<div class="date-range white-box main-black p-4 border-radius-bottom">
	<div class="border-bottom p-3">
		<h2>{{'action_log.official_reporting' | translate}}</h2>
	</div>
	<div class="px-5 py-4">
		<form  validate-form="deviceAdmin" (ngSubmit)="saveData()">
			<div class="row mt-2 align-items-center">
				<div class="col-md-4">
					<strong>{{'devices.included_in_report' | translate}}</strong>
				</div>
				<div class="col-md-8">
					<div class="form-check form-check-inline slide-toggle-div">
						<mat-slide-toggle [(ngModel)]="included_in_reports" name="included_in_report"></mat-slide-toggle>
					</div>
				</div>
			</div>

			<!-- Defective Device -->
			<div class="row mt-2 align-items-center">
				<strong class="col-md-4">{{'devices.defective_periods' | translate}}</strong>
				<div class="col-md-8">
					<button
						class="btn text-white main-blue-bg btn-md mt-2 mr-4"
						(click)="showDefectivePeriodPopup($event)"
						[disabled]="tableData.length > periodLimit - 1"
					>
						{{'devices.add_defective_period' | translate}}
					</button>
				</div>
			</div>

			<div *ngIf="tableData.length">
				<sct-custom-table
					*ngIf="tableData.length"
					[columnsConfig]="columnConfig"
					[data]="tableData"
					[config]="tableConfig"
				></sct-custom-table>
			</div>

			<div class="row my-4 align-items-center">
				<div class="col-md-4">
				</div>
				<div class="col-md-8">
					<div class="row align-items-center">
						<div class="col-12">
							<button type="submit" class="btn text-white main-blue-bg btn-lg col-md-5 mr-4" >{{'g.save' | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>

<app-modal
	[hasCloseButton]="false"
	[hasConfirmButton]="false"
	[closeOnConfirm]="false"
	#addDefectivePeriod title="{{(this.popupAction == 'add'? 'devices.add_defective_period' : 'devices.edit_defective_period') | translate}}"
	size="lg"
>
	<p class="popup-warning" *ngIf="popupWarning.viable">{{popupWarning.text}}</p>
	<div class="row mt-2 align-items-center">
		<div class="col-md-2">
			<strong>{{'devices.ongoing_period' | translate}}</strong>
		</div>
		<div class="col-md-4">
			<div class="form-check slide-toggle-div">
				<ng-select
					[items]="periodStatusOptions"
					bindLabel="name"
					bindValue="id"
					placeholder="{{'devices.ongoing_period' | translate }}"
					name="period_status"
					[(ngModel)]="selectedPeriodStatus"
					[multiple]="false"
					(change)="updatePeriodStatus()"
					[clearable]="false"
					class="options"
				>
				</ng-select>
			</div>
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-2">
			<strong>{{'g.start_date' | translate}}</strong>
		</div>
		<div class="col-md-4">
			<div class="form-check slide-toggle-div">
				<input
					type="date"
					name="start_date"
					[(ngModel)]="defective.startDate"
					[min]="deviceInstallationDate"
					[max]="ongoingPeriod ? today : defective.endDate"
					placeholder="{{'g.start_date' | translate}}"
					class="form-control mr-2"
					[ngClass]="{'border border-danger': fieldsError.start_date}"
					required
					/>
			</div>
		</div>
		<div class="col-md-2" *ngIf="!ongoingPeriod">
			<strong>{{'g.end_date' | translate}}</strong>
		</div>
		<div class="col-md-4" *ngIf="!ongoingPeriod">
			<div class="form-check slide-toggle-div">
				<input
					type="date"
					name="end_date"
					[(ngModel)]="defective.endDate"
					[min]="defective.startDate"
					[max]="today"
					placeholder="{{'g.start_date' | translate}}"
					class="form-control mr-2"
					[ngClass]="{'border border-danger': fieldsError.end_date}"
					required
					/>
			</div>
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-12">
			<strong>{{'g.reason' | translate}}</strong>
		</div>
		<div class="col-md-12">
			<div class="slide-toggle-div">
				<textarea
					class="form-control mt-3"
					name="reason"
					id="reason"
					rows="5"
					[(ngModel)]="defective.reason"
					placeholder="{{'g.reason' | translate}}"
					[ngClass]="{'border border-danger': fieldsError.reason}"
				>
				</textarea>
			</div>
		</div>
	</div>
	<div dialog-content>
		<div class="mt-5 d-flex flex-row-reverse">
			<button class="btn btn-light ml-3" (click)="addDefectivePeriod.onHide()">{{'g.cancel' | translate}}</button>
			<button
				[disabled]="popupAction == 'add' && tableData.length > periodLimit - 1"
				class="btn btn-primary me-2"
				(click)="addDefectivePeriod.onConfirm()"
			>
				{{(popupAction == 'add' ? 'g.add' : 'g.ok') | translate}}
			</button>
		</div>
	</div>
</app-modal>

<app-modal
	[hasCloseButton]="false"
	[hasConfirmButton]="false"
	[closeOnConfirm]="false"
	#deleteDefectivePeriod [title]="'devices.delete_defective_period' | translate"
	size="lg"
>
	<div class="row mt-2 align-items-center">
		<div class="col-md-2">
			<strong>{{'g.start_date' | translate}}</strong>
		</div>
		<div class="col-md-4">
			<div class="form-check slide-toggle-div">
				<input
					type="date"
					name="start_date"
					[(ngModel)]="toDeletePeriod.from_date.value"
					[min]="deviceInstallationDate"
					[max]="today"
					placeholder="{{'g.start_date' | translate}}"
					class="form-control mr-2"
					[disabled]="true"
					/>
			</div>
		</div>
		<div class="col-md-2" *ngIf="!ongoingPeriod">
			<strong>{{'g.end_date' | translate}}</strong>
		</div>
		<div class="col-md-4" *ngIf="!ongoingPeriod">
			<div class="form-check slide-toggle-div">
				<input
					type="date"
					name="end_date"
					[(ngModel)]="toDeletePeriod.to_date.value"
					[min]="deviceInstallationDate"
					[max]="today"
					placeholder="{{'g.end_date' | translate}}"
					class="form-control mr-2"
					[disabled]="true"
					/>
			</div>
		</div>
	</div>
	<div dialog-content>
		<div class="mt-5 d-flex flex-row-reverse">
			<button class="btn btn-light ml-3" (click)="deleteDefectivePeriod.hide()">{{'g.cancel' | translate}}</button>
			<button
				class="btn btn-primary me-2"
				(click)="deleteDefectivePeriod.onConfirm()"
			>
				{{'g.delete' | translate}}
			</button>
		</div>
	</div>
</app-modal>
