import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { Subscription } from 'rxjs';
import { OrdersService } from '../orders.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import validationRulesModel from 'iotah-common/validations/validationRules.json';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-edit-order',
	templateUrl: './edit-order.component.html',
})
export class EditOrderComponent implements OnInit {

	@ViewChild("editOrderForm") editOrderForm: NgForm;
	routeSubscription!: Subscription;
	action!: string;

	allowedActions: string[] = ['add', 'edit', 'view'];
	formFields: any = [];

	order: any = {};
	orderId: number = 0;

	validationRules: any = {};
	invalidFields: any = {};

	pricesOptions: any = [];
	salesChannelOptions: any = [];
	subscriptionTermsOptions: any = [];
	warrantyPeriodOptions = [
		{value: 1, label: `1 ${this.translateService.instant("year")}`},
		{value: 2, label: `2 ${this.translateService.instant("years")}`},
		{value: 3, label: `3 ${this.translateService.instant("years")}`},
		{value: 4, label: `4 ${this.translateService.instant("years")}`},
		{value: 5, label: `5 ${this.translateService.instant("years")}`},
	];
	defaultWarrantyPeriod = 1;

	notificationEmails: {email: string}[] = [];
	poFieldClasses: string = 'form-control';
	fieldsToTrim: string[] = [
		'receiver_company_email',
		'receiver_email',
		'notifications_email_list',
		'po_number',
		'shipment_tracking_number',
		'receiver_name',
		'receiver_company_name',
		'receiver_first_address_line',
		'receiver_second_address_line',
		'receiver_postal_code',
		'receiver_city',
		'receiver_country',
		'reseller',
	];

	constructor(
		private userService: UsersService,
		private router: Router,
		private route: ActivatedRoute,
		public notificationMessage: NotificationMessageService,
		public ordersService: OrdersService,
		private sideMenuService: SideMenuService,
		private navigation: NavigationService,
		private translateService: TranslateService,
	) { }

	ngOnInit(): void {
		this.sideMenuService.hide();

		this.ordersService.shouldBackToSelectedTab = false;

		this.routeSubscription = this.route.params.subscribe((params: any) => {
			this.action = params.action || null;

			if (!params || !this.allowedActions.includes(this.action) || (['edit', 'view'].includes(this.action) && !params.id) || (this.action == 'add' && params.id))
				return this.router.navigateByUrl('/404');

			const access = this.userService.hasAccessFunction('create_orders') || this.userService.hasAccessFunction('view_orders');
			if (!access)
				this.router.navigate(['/unauthorized']);

			this.orderId = +params.id || 0;

			if (this.action != 'add')
				this.getOrder();

			this.validationRules = validationRulesModel.orders;
			this.pricesOptions = this.ordersService.getPricesOptions();
			this.salesChannelOptions = this.ordersService.getSalesChannelOptions();
			this.subscriptionTermsOptions = this.ordersService.getSubscriptionTermsOptions();
			this.order.subscription_terms = this.subscriptionTermsOptions[0].value;
			this.order.warranty_years = this.order.warranty_years || this.defaultWarrantyPeriod;
		});
	}

	isEditForm() {
		return this.action == 'edit';
	}

	isFormDisabled() {
		return this.action == 'view';
	}

	getOrder() {
		this.ordersService.getOrder(this.orderId).subscribe((order: any) => {
			if(this.isEditForm() && order.status === +(this.ordersService.getStatusesByKey()).SHIPPED)
				return this.router.navigateByUrl('/404');

			this.notificationEmails = order.notifications_email_list.trim().split(' ').map((email: string) => { return { email }});
			this.order = order;
		});
	}

	customValidation(formFields: any) {
		// Cannot decrease the order count less than quantity of shipped devices
		if(formFields.devices_count < this.order.devices_shipped_count) {
			this.notificationMessage.setMessage('translate|orders.cannot_decrease_the_order_count', {clearOnXTimeNavigate: 1});
			return false;
		}
		return true;
	}

	saveOrder() {
		let formFields = this.editOrderForm.value;
		formFields.id = this.orderId;

		for (const key in formFields) {
			if (this.fieldsToTrim.includes(key) && formFields[key]) {
				if (key == 'notifications_email_list')
					formFields.notifications_email_list = formFields.notifications_email_list.map(item => ({ email: item.email.trim() }));
				else
					formFields[key] = formFields[key].trim();
			}
		}

		if(!this.customValidation(formFields))
			return;

		this.ordersService.saveOrder(formFields).subscribe((res: any) => {
			switch (res.api_status) {
				case 2:
					this.notificationMessage.setMessage('translate|g.invalid_fields', {clearOnXTimeNavigate: 1});
					break;
				case 3:
					this.poFieldClasses = this.poFieldClasses.concat(' invalid-input');
					this.notificationMessage.setMessage('translate|orders.duplicate_po_number', {timeout: 20000, clearOnXTimeNavigate: 1});
					break;
				case 4:
					this.notificationMessage.setMessage('translate|orders.cannot_decrease_the_order_count', {clearOnXTimeNavigate: 1});
					break;
				default:
					this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
					this.goBack();
					break;
			}
		});
	}

	shipmentTrackingFieldsVisibility(status: number) {
		return status === +(this.ordersService.getStatusesByKey()).SHIPPED && this.isFormDisabled;
	}

	isPoNumberDisabled(status: number) {
		return this.isFormDisabled() ||
			(this.isEditForm() && (status != +(this.ordersService.getStatusesByKey()).NEW || !this.userService.hasAccessFunction('create_orders')));
	}

	@HostListener('window:popstate', ['$event'])
	onPopState() {
		this.ordersService.shouldBackToSelectedTab = true;
	}

	goBack() {
		this.ordersService.shouldBackToSelectedTab = true;
		this.navigation.goBack('/orders/manage');
	}
}
