<app-header></app-header>
    <i class="fa fa-check col-md-12 mt-5 d-flex justify-content-around " style="color: #004999;font-size: 8em;" aria-hidden="true"></i>
<div class="d-flex align-items-center justify-content-center">
	<div class="jumbotron text-center">
		<h1 class="display-4">{{'g.thank_you' | translate}}</h1>
		<strong class="text-left">{{'g.user_registered' | translate}}</strong>
	
		<hr>
		<p class="lead">
			<button type="button" class="col-12 col-md-5 ms-2 btn main-blue-bg" routerLink="/login" >{{'login_register.go_to_login' | translate}}</button>
	
		</p>
	</div>
