<div>
    <div class="d-flex justify-content-between mb-2 px-2">
		<h2>{{'nav.qv_logs' | translate}}</h2>
	</div>

	<div class="row col-md-12 p-3">
		<div class="col-md-3">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input [ngClass]="{'invalid-input': invalidStartDate}" type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp1" [(ngModel)]="fromDate" bsDatepicker #d1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
				<div class="input-group-append pointer" (click)="d1.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>

		<div class="col-md-3">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input [ngClass]="{'invalid-input': invalidEndDate}" type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp2" [(ngModel)]="toDate" bsDatepicker #d2="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
				<div class="input-group-append pointer" (click)="d2.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
		<button class="btn btn-primary run ml-4"  type="button" (click)="getQuickViewHistory()">{{"g.run" | translate}}</button>
	</div>

	<div *ngIf="quickViewHistoryLogs.length; else noData">
		<sct-custom-table
			[columnsConfig]="columnConfig"
			[data]="quickViewHistoryLogs"
			[config]="tableConfig"
		>
		</sct-custom-table>
	</div>

	<ng-template #noData>
		<div class="alert alert-info" *ngIf="quickViewHistoryLogs.length == 0">{{'g.no_data_available' | translate}}</div>
	</ng-template>

</div>

<app-modal #liveRtDetailsModal [title]="'qv.live_rt' | translate" [hasCloseButton]="false" [hasConfirmButton]="false" [size]="'l'">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{ 'g.field_name' | translate }}</th>
				<th>{{ 'g.value' | translate }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of liveRtModalData | keyvalue">
				<td>{{ 'rt.' + item.key | translate }}</td>
				<td>{{ item.value }}</td>
			</tr>
		</tbody>
	</table>
</app-modal>

<app-modal #liveEventModal [title]="'qv.live_event' | translate"  [hasCloseButton]="false" [hasConfirmButton]="false" [size]="'xl'">
	<div class="my-2">
		<div class="row">
			<div class="col-md-4 mt-2">
				<strong>{{'event.sequence_id' | translate}}: </strong><span>{{liveEvent.sequence_id}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.event_type' | translate}}: </strong><span>{{formatQv('event_type', 'event')}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'g.start_time' | translate}}: </strong><span>{{formatQv('timestamp_utc', 'event')}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'g.end_time' | translate}}: </strong><span>{{formatQv('end_timestamp_utc', 'event')}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'g.time_zone' | translate}}: </strong><span>{{formatQv('my_zone_id', 'event')}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'g.is_valid' | translate}}: </strong><span>{{formatQv('isValidCRC16', 'event')}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'events.charge_kwhr' | translate}}: </strong><span>{{liveEvent.charge_ws / 3600000}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'events.inuse_kwhr' | translate}}: </strong><span>{{liveEvent.inuse_ws / 3600000}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'events.charge_ahr' | translate}}: </strong><span>{{liveEvent.charge_as / 3600}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'events.inuse_ahr' | translate}}: </strong><span>{{liveEvent.inuse_as / 3600}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.number_of_cells' | translate}}: </strong><span>{{liveEvent.number_of_cells}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.vpc' | translate}}: </strong><span>{{liveEvent.vpc}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.min_voltage' | translate}}: </strong><span>{{liveEvent.min_voltage | number: '1.1-3'}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.start_voltage' | translate}}: </strong><span>{{liveEvent.start_voltage | number: '1.1-3'}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.end_voltage' | translate}}: </strong><span>{{liveEvent.end_voltage | number: '1.1-3'}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.event_max_current' | translate}}: </strong><span>{{liveEvent.event_max_current | number: '1.1-3'}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.event_min_current' | translate}}: </strong><span>{{liveEvent.event_min_current | number: '1.1-3'}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.event_average_current' | translate}}: </strong><span>{{liveEvent.event_average_current | number: '1.1-3'}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.hall_effect_count' | translate}}: </strong><span>{{liveEvent.hall_effect_count}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.accurate_inuse_seconds' | translate}}: </strong><span>{{liveEvent.accurate_inuse_seconds}}</span>
			</div>
			<div class="col-md-4 mt-2" >
				<strong>{{'event.accurate_charge_seconds' | translate}}: </strong><span>{{liveEvent.accurate_charge_seconds}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.latitude' | translate}}: </strong><span>{{liveEvent.latitude}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.longitude' | translate}}: </strong><span>{{liveEvent.longitude}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'events.event_duration' | translate}}: </strong><span>{{common.secondsToDaysHoursMinutesAndSeconds(liveEvent._duration)}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'event.water_level' | translate}}: </strong><span>{{formatQv('water_level_high', 'event')}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'events.lift_time' | translate}}: </strong> <span>{{liveEvent.lift_time}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'events.travel_time' | translate}}: </strong> <span>{{liveEvent.travel_time}}</span>
			</div>
			<div class="col-md-4 mt-2">
				<strong>{{'g.notes' | translate}}: </strong><span>{{formatQv('notes', 'event')}}</span>
			</div>
		</div>
	</div>
</app-modal>