import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { DeviceService } from '../device.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';
import { ActivatedRoute, Router } from "@angular/router";
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { Subscription } from 'rxjs';

import * as _ from 'underscore';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { SiteDashboardService } from '../../site-dashboard.service';
import { TagsService } from '../../tags.service';
import { LoaderInterceptorService } from 'src/app/shared/loader/loader-interceptor.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';

@Component({
	selector: 'app-devices-listing',
	templateUrl: './devices-listing.component.html',
	styleUrls: ['./devices-listing.component.css']
})
export class DevicesListingComponent implements OnInit, AfterViewInit {

	hideSetMaintenanceDateButton: boolean = true;
	currentSite: any = {};
	siteId: number;
	customerId: number;
	currentUser: any = {};
	selectedDevices: any = [];
	devicesListing: any = [];
	allDevices: any = [];
	timeoutId;
	hasDevices: boolean = true;
	hasWarningAccess: boolean = false;
	warningOptionsList;
	filters: any = {
		lastConnectTime: null,
		tags: []
	};
	truckTypes = [];
	chargerManufacturers = [];
	connectTimeOptions = [
		{id: 'all', label: this.translateService.instant('g.all')},
		{id: '1', label: this.translateService.instant('connectivity.connected')},
		{id: '2', label: this.translateService.instant('connectivity.not_connected_day')},
		{id: '3', label: this.translateService.instant('connectivity.not_connected_week')},
		{id: '4', label: this.translateService.instant('connectivity.not_connected')},
	];
	subListing: Subscription = new Subscription();
	permissionsSub: Subscription = new Subscription();
	visibility: any = {};

	isDestroyed: boolean = false;
	siteTags: any[] = [];
	siteTagsObj: any = {}
	selectedAll: boolean = false;
	countOfDevicesChecked: number = 0;

	commonTags: any[] = [];
	tagsNamesToDelete: any[] = [];
	selectedTagsToDelete: any[] = [];

	tooltips = {
		site_tags: ''
	};

	lastPMModalVars: any = {};

	enterprisePermissions: any = {};
	isAdminUser: Boolean;
	fromDate: Date;
	toDate: Date;
	minDate: Date;
	isChargLinkDomain: boolean;

	exportEventsColumns: string[] = [];
	defaultColumns: string[] = [
		'sequence_id',
		'timestamp',
		'event_type',
		'end_timestamp'
	]
	selectedExportEventsColumns: string[] = [
		"sequence_id",
		"timestamp",
		"event_type",
		"event_duration",
		"inuse_ahr",
		"inuse_kwhr",
		"charge_ahr",
		"charge_kwhr",
		"accurate_inuse_seconds",
		"start_voltage",
		"min_voltage",
		"end_voltage",
		"event_max_current",
		"event_average_current",
		"insertion_time",
		"accurate_charge_seconds",
		'latitude',
		'longitude'
	];
	durationFields: string[] = [
		"duration",
		"accurate_inuse_seconds",
		"accurate_charge_seconds",
		"event_duration",
	];
	columns: any[];

	@ViewChild("addTagModal") addTagModal;
	@ViewChild("removeTagModal") removeTagModal;
	@ViewChild("factoryResetModal") factoryResetModal;
	@ViewChild("resetRTCLostDetectionModal") resetRTCLostDetectionModal;
	@ViewChild("lastPMModal") lastPMModal;
	@ViewChild("exportEventsModal") exportEventsModal;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		public deviceService: DeviceService,
		private sideMenuService: SideMenuService,
		public usersService: UsersService,
		private notificationMessageService: NotificationMessageService,
		private translateService: TranslateService,
		private siteDashboardService: SiteDashboardService,
		public tagsService: TagsService,
		private loader: LoaderInterceptorService,
		public commonService: CommonService
	) { }

	ngOnInit() {
		this.exportEventsColumns = this.deviceService.getEventsFields();
		this.sideMenuService.currentSite.subscribe((data:any) => {

			if (!data || !data.id || _.isEqual(data, this.currentSite))
				return;

			this.siteId = Number(this.route.parent.snapshot.params.siteId);
			this.customerId = Number(this.route.parent.snapshot.params.customerId);

			this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data=> {
				this.visibility.restart_device	= this.usersService.hasAccessPermission(data, 'restart_device');
				this.visibility.factory_reset	= this.usersService.hasAccessPermission(data, 'factory_reset', 'write');
				this.visibility.set_maintenance_date = this.usersService.hasAccessPermission(data, 'basic_setup', 'write');
				this.visibility.bulk_fill_device_info = this.usersService.hasAccessPermission(data, 'noc', 'write');
				this.visibility.export_events = this.usersService.hasAccessPermission(null, 'noc');
				this.visibility.export_last_connect_time_devices = this.usersService.hasAccessPermission(null, 'noc');

				for(let key in this.tooltips) {
					this.tooltips[key] = this.commonService.getTooltipKey(key, this.usersService.userHasNOCAccess(data));
				}
				this.enterprisePermissions = data;
			});

			if(data.id == this.siteId && data.customer_id == this.customerId) {
				this.currentSite = data;
				this.getSiteDevicesListing();
			}
		});

		this.visibility.reset_rtc_lost_detection = this.usersService.hasAccessFunction('reset_rtc_lost_detection');

		this.warningOptionsList = this.deviceService.getWarningOptionsList();
		this.currentUser = this.usersService.getCurrentUser();
		this.isAdminUser = this.currentUser.is_admin;
		this.hasWarningAccess = this.usersService.hasAccessFunction('show_warnings');
		this.isChargLinkDomain = this.commonService.isChargeLink();
	}

	ngAfterViewInit() {
		this.removeTagModal.onClose.subscribe((ok) => {
			if(!ok)
				return;

			let selectedDevices = this.getSelectedDevices();
			this.selectedDevices = selectedDevices;
			let tags = [];
			for(let i in this.selectedTagsToDelete) {
				if(this.selectedTagsToDelete[i]) {
					tags.push(this.tagsNamesToDelete[i]);
				}
			}
			this.tagsService.removeDeviceTag(this.siteId, selectedDevices, tags).subscribe(() => {
				this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.removeTagFromUI(selectedDevices, _.pluck(tags, 'tag_id'));
				this.selectedTagsToDelete = [];
			});
		});

		this.factoryResetModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			this.sendCommand(result[1][0], result[1][1]);
		});

		this.resetRTCLostDetectionModal.onClose.subscribe((result) => {
			if(!result[0])
				return;
			this.resetRTCLostDetection(result[1][0]);
		});
	}

	getSiteDevicesListing(isRefresh = false) {
		if(Object.keys(this.devicesListing).length == 0 || isRefresh) {
			if(this.siteId && this.customerId && !this.isDestroyed) {

				this.subListing = this.deviceService.getSiteDevicesListing(this.siteId).subscribe( (response: any) => {

					let devicesListing = response.devices;
					this.hasDevices = (Object.keys(devicesListing).length > 0);

					this.deviceService.addConnectivityClasses(response.nowTime, devicesListing);
					devicesListing = _.chain(devicesListing).sortBy('serial_number').sortBy('truck_sn').value();

					let oldSiteTags = JSON.parse(JSON.stringify(this.siteTags));
					let devicesTags = response.tags.deviceTags || {};
					this.siteTagsObj = response.tags.siteTags;
					for(let tag of oldSiteTags) {
						if(tag.isActive) {
							this.siteTagsObj[tag.id].isActive = true;
						}
					}
					this.siteTags = Object.values(this.siteTagsObj) || [];

					let selectedDevices = this.getSelectedDevices();

					for (let item of devicesListing) {
						if (!item.is_charglink)
							this.hideSetMaintenanceDateButton = false;

						item.tooltipMessages = item.tooltipMessages || [];
						this.filterByTruckType(item);
						item.warnings = this.hasWarningAccess ? this.deviceService.formatDeviceWarnings(item, {getAll: true, permission: this.enterprisePermissions.permission, canIgnorePermission: true}) : '';
						if(item.warnings.length) {
							let deviceWarnings = [];
							for(let warning of item.warnings)
								deviceWarnings.push(this.translateService.instant('warnings.'+warning));
							item.tooltipMessages.push(deviceWarnings);
						}

						if(selectedDevices.includes(item.mac_address))
							item.selected = true;

						item.bgClass = this.hasWarningAccess && item.device_warnings ? 'red-bg': '';
						let tags = [],
							detailedTags = {};

						if(devicesTags[item.mac_address]) {
							for(let tag of devicesTags[item.mac_address]) {
								tags.push(+tag.tag_id);
								detailedTags[+tag.tag_id] = tag;
							}
						}
						item.detailedTags = detailedTags;

						item.tags = tags;
					}
					this.checkIsChargLink(devicesListing)
					this.refreshDevicesList(2 * 60 * 1000); //every 2 mins
					this.devicesListing = devicesListing;
					this.allDevices = devicesListing.slice();
					this.filterListing();
				});
			}
		}
	}

	deviceChecked(isChecked) {
		// this.hideAddDeviceTagBox = true;
		if (isChecked) {
			this.countOfDevicesChecked++;
		} else {
			this.countOfDevicesChecked--;
			this.selectedAll = false;
		}

		if (this.countOfDevicesChecked <= 0) {
			this.countOfDevicesChecked = 0;
		}

		if(this.countOfDevicesChecked >= Object.keys(this.devicesListing).length) {
			this.countOfDevicesChecked = Object.keys(this.devicesListing).length;
			this.selectedAll = true;
		}

		this.updateCommonTags();
		this.updateMaintenanceDateRange();
	}

	filterByTruckType(item) {
		if (this.isChargLinkDomain) {
			if (item.is_charglink && item.truck_manufacturer && !this.chargerManufacturers.includes(item.truck_manufacturer))
				this.chargerManufacturers.push(item.truck_manufacturer);
		} else if(item.truck_type && !this.truckTypes.includes(item.truck_type)) {
			this.truckTypes.push(item.truck_type);
		}
	}

	selectAll(selectedAll) {
		for (let itemIdx in this.devicesListing) {
			let item = this.devicesListing[itemIdx];
			item.selected = selectedAll;
			this.deviceChecked(selectedAll);
		}
	}

	updateCommonTags() {
		let selectedDevices = this.getSelectedDevices();
		if(!selectedDevices.length)
			return;

		let commonTags = this.getDevicesCommonTags(selectedDevices);

		if(!commonTags.length)
			this.commonTags = commonTags;
		else
			this.commonTags = this.getTagsObject(commonTags);
	}

	updateMaintenanceDateRange() {
		let devices = this.getSelectedDevices(true);
		let maxDate = _.chain(devices).pluck('installation_date').max().value();
		this.lastPMModalVars.min = new Date(maxDate * 1000);
		this.lastPMModalVars.installationDate = maxDate;
	}

	filterTags(tag) {
		// if the tag filter was enabled and clicked to disabled
		if (tag !== 'REFRESH') {
			if (tag.isActive) {
				let index = this.filters.tags.indexOf(+tag.id);
				if (index > -1) {
					this.filters.tags.splice(index, 1);
				}
			} else {
				this.filters.tags.push(+tag.id);
			}
			tag.isActive = !tag.isActive;
		}

		this.filterListing();

		if (tag !== 'REFRESH') {
			this.selectedAll = false;
		}
	}

	siteTagAdded(info) {
		let selectedDevices = this.getSelectedDevices();
		let tag = info.tag;
		if(info.type == 'remove')
			return this.removeTagFromUI(selectedDevices, [+tag.id]);

		for(let device of this.devicesListing) {
			if(!selectedDevices.includes(device.mac_address))
				continue;

			if(!device.tags.includes(+tag.id))
				device.tags.push(+tag.id);

			if(!device.detailedTags[+tag.id])
				device.detailedTags[+tag.id] = tag;
		}

		if(!Object.keys(this.siteTagsObj).includes(''+tag.id))
			this.siteTagsObj[tag.id] = tag;

		this.updateCommonTags();
	}

	getTags(device) {
		let result = [];
		for(let id of device.tags) {
			let obj = _.extend({}, this.siteTagsObj[id]);
			obj.keep_in_replacement = false;
			if(device.detailedTags[id])
				obj.keep_in_replacement = device.detailedTags[id].keep_in_replacement;

			result.push(obj);
		}
		return result;
	}

	deleteTags() {
		let selectedDevices = this.getSelectedDevices();
		if(!selectedDevices.length)
			return alert(this.translateService.instant('g.no_device_selected'));

		let commonTags = this.getDevicesCommonTags(selectedDevices);

		if(!commonTags.length)
			return alert(this.translateService.instant('tags.no_common_msg'));

		let tagsToDelete = this.getTagsObject(commonTags);

		this.tagsNamesToDelete = tagsToDelete;
		this.removeTagModal.show();
	}

	saveSettings() {
		let selectedDevices = this.getSelectedDevices();
		if(!selectedDevices.length)
			return alert(this.translateService.instant('g.no_device_selected'));
		let url: any[] = [selectedDevices.join(','), 'settings'];
		this.router.navigate(url, {relativeTo: this.route.parent, queryParams: {tab: 'info'}});
	}

	private removeTagFromUI(selectedDevices, commonTags) {
		// Remove tags from UI
		for(let device of this.devicesListing) {
			if(!selectedDevices.includes(device.mac_address))
				continue;

			device.tags = _.reject(device.tags, (tagId) => {return commonTags.includes(+tagId) });
			for(let tag of commonTags) {
				delete device.detailedTags[tag];
			}
		}

		this.filterTags('REFRESH');
		this.updateCommonTags();
	}

	refreshDevicesList(checkFreq) {
		this.timeoutId = setTimeout(() => {
			if(!this.isDestroyed && Object.keys(this.devicesListing).length > 0)
				this.getSiteDevicesListing(true);
		}, checkFreq);
	}

	sendCommand(action, macAddress) {
		// TODO : check permission and show/hide option from actions list accordingly
		this.deviceService.sendCommand(macAddress, action, {}).subscribe((response: any) => {
			// if the api timed-out OR if wait sent and the device is disconnected.
			if (response.response_status == this.loader.resStatus.api_timeout || response.api_status == this.deviceService.deviceCommandsResponse)
				this.notificationMessageService.setMessage('translate|settings.queue_and_wait_timeout_msg', {clearOnXTimeNavigate: 1, type: 'warning'});
			else
				this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}

	resetRTCLostDetection(macAddress) {
		this.deviceService.resetRTCLostDetection(macAddress).subscribe((response: any) => {
			this.refreshDevicesList(0);
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}

	filterListing() {
		let filteredDevices	= [];
		for (let device of this.allDevices) {
			let deviceIsFilteredOut = false;
			for (let filter in this.filters) {
				let filterValues = this.filters[filter];
				if(!filterValues)
					filterValues = [];

				if(!Array.isArray(filterValues))
					filterValues = [filterValues];

				for (let item of filterValues) {
					if (!this.checkFilterType(device, item, filter)) {
						deviceIsFilteredOut = true;
						if(device.selected) {
							device.selected = false;
							this.deviceChecked(false);
						}
						continue;
					}

					deviceIsFilteredOut = false;
					break;
				}
			}
			if(!deviceIsFilteredOut)
				filteredDevices.push(device);
		}

		this.devicesListing = this.filterListingTags(filteredDevices);
	}

	getSelectedDevices(getInfo=false) {
		let selectedDevices = [];
		this.devicesListing.forEach((item) => {
			if(item.selected) {
				let data = getInfo ? item : item.mac_address;
				selectedDevices.push(data);
			}
		});
		return selectedDevices;
	}

	showAddTagBox() {
		let selectedDevices = this.getSelectedDevices();
		if (selectedDevices.length === 0)
			return alert(this.translateService.instant('g.no_device_selected'));

		this.addTagModal.show();
	}

	isDeleteBtnDisabled() {
		let disabled = true;
		for(let item of this.selectedTagsToDelete) {
			if(item){
				disabled = false;
				break;
			}
		}
		return disabled;
	}

	manageDevices() {
		let selectedDevices = this.getSelectedDevices();
		if(!selectedDevices.length)
			return alert(this.translateService.instant('g.no_device_selected'));

		let path = 'management';
		if(!this.deviceService.showManageDeviceOption({}, this.currentSite))
			path += '-request';
		this.router.navigate(['/device', path, selectedDevices.join(',')]);
	}

	openLastPMModal() {
		Object.assign(this.lastPMModalVars, {
			lastPMDate: new Date(),
			isValid: true,
			max: new Date()
		});
		this.lastPMModal.show();
	}

	closeLastPMModal() {
		this.lastPMModal.hide();
	}

	setLastPMDate() {
		let selectedDevices = this.getSelectedDevices();
		if(!selectedDevices.length)
			return alert(this.translateService.instant('g.no_device_selected'));

		let lastPMDate = moment(this.lastPMModalVars.lastPMDate).startOf('day').unix();
		if(!this.deviceService.validateLastPMDate(lastPMDate, this.lastPMModalVars.installationDate)) {
			this.lastPMModalVars.isValid = false;
			return;
		}

		//  local time -> utc
		let zoneDiff = new Date().getTimezoneOffset();
		lastPMDate -= (zoneDiff * 60);

		this.lastPMModalVars.isValid = true;

		this.deviceService.setMultiLastMaintenanceDate(selectedDevices, lastPMDate).subscribe((res: any) => {
			this.notificationMessageService.setMessage('translate|settings.queue_and_wait_timeout_msg', {clearOnXTimeNavigate: 1, type: 'warning'});
			this.closeLastPMModal();
		});
	}

	checkIsChargLink(devices) {
		devices.forEach(item => {
			const connectivity = item.connectivityFlag === 4 ? "not_connected" : "connected";
			item.imageLink = this.commonService.imagePacker(connectivity, item.is_charglink, !!(+item.is_study_device));
		});
	}

	private getTagsObject(tagsIds) {
		if (!Array.isArray(tagsIds))
			tagsIds = [tagsIds];

		let tagsArr = [];
		for (let id of tagsIds) {
			if (this.siteTagsObj[id]) {
				let tempObj = Object.assign({}, this.siteTagsObj[id]);
				tempObj.tag_id = tempObj.id;
				tagsArr.push(tempObj);
			}
		}
		return tagsArr;
	}

	private getDevicesCommonTags(selectedDevices) {
		let devicesTags = this.getDevicesTagsIds(selectedDevices);
		let commonTags	= _.intersection(...devicesTags);
		return commonTags.map((e) => { return +e; });
	}

	private getDevicesTagsIds(deviceIds) {
		if (!Array.isArray(deviceIds))
			deviceIds = [deviceIds];

		let devicesByMac = _.indexBy(this.devicesListing, 'mac_address');
		let devicesTags = [];
		for (let deviceId of deviceIds) {
			if(devicesByMac[deviceId].tags)
				devicesTags.push(devicesByMac[deviceId].tags);
		}
		return devicesTags;
	}

	private filterListingTags(devices) {
		let filteredDevices	= [];
		for (let device of devices) {
			let deviceIsFilteredOut = true;

			for (let tagId of this.filters.tags) {
				if(device.tags && device.tags.includes(tagId)) {
					deviceIsFilteredOut = false;
					break;
				}
			}

			if(!deviceIsFilteredOut || !this.filters.tags.length)
				filteredDevices.push(device);
			else {
				if(device.selected) {
					device.selected = false;
					this.deviceChecked(false);
				}
			}
		}

		return filteredDevices;
	}

	private checkFilterType(device, item, filter) {
		let filtered = true;
		switch (filter) {
			case "warningFilter":
				filtered = device.warnings && device.warnings.indexOf(item) > -1;
				break;
			case "truckTypeFilter":
				filtered = device.truck_type == item;
				break;
			case "lastConnectTime":
				filtered = device.connectivityFlag == item || !item || item == 'all';
				break;
			case "chargerManufacturersFilter":
				filtered = device.truck_manufacturer == item;
				break;
		}
		return filtered;
	}

	showExportEventsPopup() {
		this.exportEventsModal.show();
		this.updateExportEventsMinDate();
		this.prepareColumns();
	}

	updateExportEventsMinDate() {
		const selectedDevices = this.getSelectedDevices();
		const minDate = this.devicesListing
			.filter(device => selectedDevices.includes(device.mac_address))
			.reduce((minDate, device) => {
				const installationDate = new Date(device.installation_date * 1000);
				return installationDate < minDate ? installationDate : minDate;
			}, new Date());
		this.minDate = minDate;
	}

	queueExportEventsRequest() {
		let zoneDiff = new Date().getTimezoneOffset() * -1;
		let fromDate: any = new Date(new Date(this.fromDate).getTime() + (zoneDiff * 60 * 1000));
		let toDate: any = new Date(new Date(this.toDate).getTime() + (zoneDiff * 60 * 1000));
		const dateRange = {from: moment(fromDate).utc().startOf('day').unix(), to: moment(toDate).utc().endOf('day').unix()};

		const macAddressesList = this.getSelectedDevices();
		const selectedColumns = this.columns.filter((col) => !col.hide);
		const selectedColumnsFields = selectedColumns.map(col => col.field);

		this.deviceService.queueExportEventsRequest(selectedColumnsFields, macAddressesList, dateRange, this.siteId).subscribe((response: any) => {
			if(!response.err) {
				this.notificationMessageService.setMessage('translate|events.export_events_success', {clearOnXTimeNavigate: 1, type: 'success'});
			} else {
				this.notificationMessageService.setMessage('translate|events.'+ response.err, {clearOnXTimeNavigate: 1, type: 'warning'});
			}
		});
		this.exportEventsModal.hide();
	}

	toggleColumn(col) {
		col['hide'] = col['hide'] == true ? false : true;
	}

	prepareColumns() {
		let gridColumns		= [];

		let shownFields = this.deviceService.eventsDefaultFields;
		let NocAccessFields = this.deviceService.eventsNocAccessFields;
		let adminFields = this.deviceService.eventsAdminFields;
		let justIotahDevicesEventFields = this.deviceService.justIotahDevicesEventFields;

		for(let field of this.exportEventsColumns) {
			let colDef: any = {};
			colDef.hide = !shownFields.includes(field);

			let selectedDevices = this.getSelectedDevices();
			let selectedDevicesData = this.allDevices.filter(device => selectedDevices.includes(device.mac_address));
			let iotahSelectedDevice = selectedDevicesData.filter(device => !device.is_charglink);

			if (justIotahDevicesEventFields.includes(field) && !iotahSelectedDevice.length)
				continue;

			if(adminFields.includes(field) && !this.isAdminUser)
				continue;

			if (NocAccessFields.includes(field) && !this.usersService.userHasNOCAccess())
				continue;

			if(this.durationFields.includes(field)) {
				colDef = Object.assign({headerName: 'events.'+field, field: field, colId: field+"_formatted"}, colDef);
			}
			else {
				Object.assign(colDef, {headerName: 'events.'+field, field: field, colId: field});
			}

			gridColumns.push(colDef);
		}
		this.columns = gridColumns;
	}

	exportDevicesLastConnectTime() {
		const lastConnectTimeReportsHeaders = [
			this.translateService.instant('devices.power_view_sn'),
			`${this.translateService.instant('devices.last_connect_time')} ${this.commonService.getZoneName(this.currentSite.zone_id).match(/\((UTC.*?)\)/)[0]}`
		];

		const selectedDevices = this.getSelectedDevices();
		const selectedDevicesInfo = this.devicesListing.filter(obj => selectedDevices.includes(obj.mac_address));
		const generationReportTime = `${moment().format('YYYY-MM-DD hh:mm:ss a')}  (${this.commonService.showUserTimeZoneReference()})`;
		const report = [];
		for (const device of selectedDevicesInfo) {
			device.last_connect_time = this.commonService.getZoneTimestampFromUTC(device.zone_id, device.last_connect_time);
			report.push([
				device.serial_number,
				this.commonService.getDateFormattedFromUnixTimeStamp(device.last_connect_time, '12h')
			]);
		};

		const csvData = [
			[this.translateService.instant('g.customer'), this.currentSite.customer_name, '', ''],
			[this.translateService.instant('g.site_name'), this.currentSite.name, '', ''],
			[this.translateService.instant('official_report.report_generation_time'), generationReportTime, '', ''],
			['', '', '', ''],
			['', '', '', ''],
			lastConnectTimeReportsHeaders,
			...report,
		];
		const fileName = `${ this.currentSite.name}-Export Devices last connect time.csv`;

		new AngularCsv(csvData, fileName);
	}

	ngOnDestroy() {
		this.isDestroyed = true;
		this.subListing.unsubscribe();
		this.permissionsSub.unsubscribe();
		clearTimeout(this.timeoutId);
	}
}
