import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { ApisLogsService } from './apis-logs.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ServerPaginationComponent } from 'src/app/shared/server-pagination/server-pagination.component';

@Component({
	selector: 'app-apis-logs',
	templateUrl: './apis-logs.component.html',
})
export class ApisLogsComponent implements OnInit {

	logs: any[] = [];
	currentPage: number = 1;
    pageNum!: number;
    logsLimit: number = 100;

	@ViewChild("serverPagination", { static: true }) serverPagination!: ServerPaginationComponent;
	constructor(
		public userService: UsersService,
		private router: Router,
		private apisLogsService: ApisLogsService,
		private sideMenuService: SideMenuService,
	) { }

	ngOnInit() {
		if (!this.userService.hasAccessFunction('apis_logs'))
			return this.router.navigate(['/unauthorized']);

		this.sideMenuService.hide();
		this.resetPageVars();
		return this.getLogs(false, true)
	}

	resetPageVars() {
		this.pageNum = 0;
	}

	getLogs(isBack = false, firstTime=false) {
		if (firstTime)
			this.serverPagination.init();

		if (isBack)
			this.pageNum--;
		else
			this.pageNum++;

		this.apisLogsService.getLogs({page: this.pageNum}).subscribe((logs: any) => {
			this.serverPagination.updatePagination(logs, isBack, firstTime);
			if(logs.length >= (this.logsLimit + 1))
				logs.pop();
			this.logs = [...logs];
		});
	}

	viewLog(log: any) {
		if (log.response?.err)
			log.response.error = this.apisLogsService.getLogError(log.response.err);

		const data = { request: log.request, response: log.response };

		const document = window.open('', '')?.document;
		if (!document)
			return;

		document.open();
		document.write([
			'<!DOCTYPE html>', '<html>', '<head>', '</head>', '<body>', '<pre>',
			JSON.stringify(data, null, 4),
			'</pre>', '</body>'
		].join(""));

		document.title = `Log ${log.id}`;
		document.close();
	}
}

