<h2>{{'sim.sim_usage_management' | translate}}</h2>
<div class="d-flex justify-content-end my-3" *ngIf="shouldShowSimActions">
	<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedICCIDs.length"
		(click)="showUpdateConfigPopup('enable')">
		{{'g.enable' | translate}} {{'sim_management.selected_sims' | translate}}
	</button>
	<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedICCIDs.length"
		(click)="showUpdateConfigPopup('disable')">
		{{'g.disable' | translate}} {{'sim_management.selected_sims' | translate}}
	</button>
	<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedICCIDs.length"
		(click)="showUpdateConfigPopup('lock')">
		{{'g.lock' | translate}} {{'sim_management.selected_sims' | translate}}
	</button>
	<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedICCIDs.length"
		(click)="showUpdateConfigPopup('unlock')">
		{{'g.unlock' | translate}} {{'sim_management.selected_sims' | translate}}
	</button>
	<button class="btn btn-primary btn-sm mx-2" (click)="showDisabledSims()">
		{{'sim_management.disabled_sims' | translate}}
	</button>
	<button class="btn btn-primary btn-sm mx-2" (click)="showBlockedSims()">
		{{'sim_management.blocked_sims' | translate}}
	</button>
	<button class="btn btn-primary btn-sm mx-2" [routerLink]="['/admin-panel/missing_from_1nce']">
		{{'sim_management.missing_from_1nce' | translate}}
	</button>
</div>
<div class="header-container d-flex">
	<div class="p-3">
		<div>
			<p>{{'sim.sim_management_note_page_1' | translate}}</p>
			<p>{{'sim.sim_management_note_page_2' | translate}}</p>
			<ul>
				<li>{{'sim.sim_management_note_page_2_point_1' | translate}}</li>
				<li>{{'sim.sim_management_note_page_2_point_2' | translate}}</li>
				<li>{{'sim.sim_management_note_page_2_point_3' | translate}}</li>
				<li>{{'sim.sim_management_note_page_2_point_4' | translate}}</li>
			</ul>
		</div>
	</div>
	<div class="color-mapper">
		<div *ngFor="let u of [5, 3, 1, 140]" class="color-box">
			<div class="color color-{{u}} c-b"></div>
			<p>{{'sim.sim_over_data_usage_'+u | translate}}</p>
		</div>
	</div>
</div>
<div class="table-control-container d-flex justify-content-end">
	<fa-icon class="setting_icon" icon="upload" size="lg" title="{{'g.export_to_csv' | translate}}" (click)="generateMainTableCsvFile()"></fa-icon>
	<fa-icon class="setting_icon" icon="table" size="lg" (click)="toggleHidePopup()"></fa-icon>
	<div class="hide-columns-popup" *ngIf="isHidePopupShown">
		<ul>
			<li *ngFor="let column of allColumns" (click)="toggleColumnVisibility(column)">
				<fa-icon [icon]="['far', hiddenColumns.includes(column.sortHeader) ? 'square' :'check-square']" size="sm" class="mr-2"></fa-icon>{{column.translationKey | translate}}
			</li>
		</ul>
	</div>
</div>
<div class="hide-columns-popup-shadow" *ngIf="isHidePopupShown" (click)="toggleHidePopup()"></div>
<div class="table-container">
	<table class="table table-striped"
		*ngIf="devices.length; else noData"
		matSort (matSortChange)="sortData($event)"
		matSortActive="mac_address, serial_number, customer_name, site_name, cellular_iccid, imei, iccid_status, imei_lock, data_usage, remaining_usage, updating_fw, fw_version, debug_enabled, metered_device, last_connect_time, usage_date, expiry_date, reconnect_time, lastWeekUsage, lastMonthUsage, installation_date"
		matSortDirection="asc"
		matSortDisableClear
	>
		<thead>
			<tr>
				<th></th>
				<th *ngFor="let column of columns"
					class="wide"
					mat-sort-header="{{ column.sortHeader }}"
					draggable="true"
					(dragstart)="drag($event)"
					(dragenter)="drop($event)"
					(dragover)="allowDrop($event)">
					{{ column.translationKey | translate }}
				</th>
				<th>{{'sim_management.usage_history' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of toShowDevices">
				<td>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" [checked]="selectedICCIDs.includes(row.cellular_iccid)" (click)="selectICCID(row)">
					</div>
				</td>
				<td *ngFor="let column of columns" [ngClass]="getClass(row, column)">
					<div [ngSwitch]="column.sortHeader">
						<div *ngSwitchCase="'mac_address'">{{row.mac_address}}</div>
						<div *ngSwitchCase="'serial_number'"><a [routerLink]="['/',row.customer_id, row.site_id, row.mac_address,'performance']">{{row.serial_number}}</a></div>
						<div *ngSwitchCase="'customer_name'"><a [routerLink]="['/', row.customer_id]">{{row.customer_name}}</a></div>
						<div *ngSwitchCase="'site_name'"><a [routerLink]="['/', row.customer_id, row.site_id]">{{row.site_name}}</a></div>
						<div *ngSwitchCase="'cellular_iccid'"><a [routerLink]="['/',row.customer_id, row.site_id, row.mac_address,'cellular-settings']">{{row.cellular_iccid}}</a></div>
						<div *ngSwitchCase="'data_usage'">{{(row.data_usage / 1024).toFixed(2)}}</div>
						<div *ngSwitchCase="'remaining_usage'">{{(row.remaining_usage / 1024).toFixed(2)}}</div>
						<div *ngSwitchCase="'weeklyUsage'">{{row.weeklyUsage}}</div>
						<div *ngSwitchCase="'monthlyUsage'">{{row.monthlyUsage}}</div>
						<div *ngSwitchCase="'updating_fw'">{{(row.updating_fw ? 'g.yes' : 'g.no') | translate}}</div>
						<div *ngSwitchCase="'imei'">{{row.imei}}</div>
						<div *ngSwitchCase="'iccid_status'">{{row.iccid_status}}</div>
						<div *ngSwitchCase="'imei_lock'">{{'g.' + (row.imei_lock ? 'locked' : 'unlocked') | translate}}</div>
						<div *ngSwitchCase="'fw_version'">{{row.fw_version || '-'}}</div>
						<div *ngSwitchCase="'debug_enabled'">{{(row.debug_enabled ? 'g.yes' : 'g.no') | translate}}</div>
						<div *ngSwitchCase="'metered_device'">{{(row.metered_device ? 'g.yes' : 'g.no') | translate}}</div>
						<div *ngSwitchCase="'last_connect_time'">{{formateDate(row.last_connect_time)}}</div>
						<div *ngSwitchCase="'usage_date'">{{row.usage_date}}</div>
						<div *ngSwitchCase="'expiry_date'">{{formateDate(row.expiry_date, 'MM/DD/YYYY')}}</div>
						<div *ngSwitchCase="'reconnect_time'">{{row.reconnect_time || '-'}}</div>
						<div *ngSwitchCase="'installation_date'">{{formateDate(row.installation_date, 'MM/DD/YYYY')}}</div>
						<div *ngSwitchDefault>{{row[column]}}</div>
					</div>
				</td>
				<td>
					<div class="d-flex align-items-center justify-content-center">
						<fa-icon class="history_icon" icon="history" size="lg" (click)="showUsageHistory(row)"></fa-icon>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<mat-paginator
	*ngIf="devices.length"
	[length]="devices.length"
	[pageIndex]="currentPage - 1"
	[pageSize]="pageSize"
	[pageSizeOptions]="pageSizeOptions"
	[showFirstLastButtons]="true"
	(page)="onPageChange($event)">
</mat-paginator>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal [hasConfirmButton]="false" [closeBtnText]="'g.close' | translate" #tableDataPopup title="{{'sim_management.usage_history' | translate}}" size="xl">
	<div *ngIf="selectedDeviceData">
		<p><span><strong>{{'devices.serial_number' | translate}}: </strong></span>{{selectedDeviceData.serial_number}}<span></span></p>
		<p><span><strong>{{'qv.cellular_iccid' | translate}}: </strong></span>{{selectedDeviceData.cellular_iccid}}<span></span></p>
		<p><span><strong>{{'g.customer_name' | translate}}: </strong></span><span><a [routerLink]="['/', selectedDeviceData.customer_id]">{{selectedDeviceData.customer_name}}</a></span></p>
		<p><span><strong>{{'g.site_name' | translate}}: </strong></span><span><a [routerLink]="['/', selectedDeviceData.customer_id, selectedDeviceData.site_id]">{{selectedDeviceData.site_name}}</a></span></p>
	</div>
	<div class="row col-md-12 mb-5">
		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp1" [(ngModel)]="popupFromData" bsDatepicker #d1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" (ngModelChange)="showUsageHistory(selectedDeviceData, false)" [ngClass]="{'not_valid': notValidPopupDate}">
				<div class="input-group-append pointer" (click)="d1.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>

		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp2" [(ngModel)]="popupToData" bsDatepicker #d2="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" (ngModelChange)="showUsageHistory(selectedDeviceData, false)" [ngClass]="{'not_valid': notValidPopupDate}">
				<div class="input-group-append pointer" (click)="d2.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
		<p *ngIf="notValidPopupDate" class="text-error-popup">{{'g.start_date_before_end_date' | translate}}</p>
	</div>
	<div *ngIf="chartObject.data?.length">
		<sct-charts
			[data]="chartObject.data"
			[columnNames]="chartObject.columnNames"
			[options]="chartObject.config"
			[formatter]="chartObject.formatter"
			[formatterType]="chartObject.formatterType"
			[title]="'sim_management.data_usage' | translate"
			[type]="'LineChart'"
			[height]="500"
		></sct-charts>
	</div>
	<div class="d-flex justify-content-end">
		<fa-icon class="setting_icon" icon="upload" title="{{'g.export_to_csv' | translate}}" size="lg" (click)="generateDeviceHistoryCsvFile()"></fa-icon>
	</div>
	<table class="table table-striped"
		*ngIf="toShowUsageHistory.length; else noData"
			matSort (matSortChange)="sortDeviceData($event)"
			matSortActive="data_usage, usage_date, updating_fw"
			matSortDirection="asc"
			matSortDisableClear
	>
		<thead>
			<tr>
				<th mat-sort-header="data_usage" class="nowrap_name">{{'sim_management.data_usage' | translate}}</th>
				<th mat-sort-header="usage_date">{{'sim_management.usage_date' | translate}}</th>
				<th mat-sort-header="updating_fw">{{'sim_management.updating_fw' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of toShowUsageHistory">
				<td class="color-{{row.log_level}}">{{(row.data_usage / 1024).toFixed(2)}}</td>
				<td>{{formateUsageDate(row.usage_date)}}</td>
				<td>{{(row.updating_fw ? 'g.yes' : 'g.no') | translate}}</td>
			</tr>
		</tbody>
	</table>
	<ng-template #noData>
		<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
	</ng-template>
</app-modal>

<app-modal [hasConfirmButton]="false" [closeBtnText]="'g.close' | translate" #disabledSimsPopup title="{{'sim_management.disabled_sims' | translate}}" size="xl">
	<div class="d-flex justify-content-end my-3">
		<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedDisabledICCIDs.length" (click)="showUpdateConfigPopup('enable', true)">
			{{'g.enable' | translate}}
		</button>
	</div>
	<table class="table table-striped"
		*ngIf="disabledSims?.length; else noData"
	>
		<thead>
			<tr>
				<th></th>
				<th>{{'g.mac_address' | translate}}</th>
				<th>{{'devices.serial_number' | translate}}</th>
				<th>{{'g.customer_name' | translate}}</th>
				<th>{{'g.site_name' | translate}}</th>
				<th>{{'qv.cellular_iccid' | translate}}</th>
				<th>{{'sim.last_disabled_time' | translate}} ({{showUserTimeZoneReference()}})</th>
				<th>{{'sim.disabled_source' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of disabledSims">
				<td>
					<div class="form-check" (click)="selectDisabledICCID(row.iccid)">
						<fa-icon *ngIf="selectedDisabledICCIDs.includes(row.iccid)" [icon]="['far', 'check-square']" size="sm" class="mr-2"></fa-icon>
						<fa-icon *ngIf="!selectedDisabledICCIDs.includes(row.iccid)" [icon]="['far', 'square']" size="sm" class="mr-2"></fa-icon>
					</div>
				</td>
				<td>
					<a [routerLink]="['/',row.customer_id, row.site_id, row.mac_address, 'performance']">{{row.mac_address}}</a>
				</td>
				<td>{{row.serial_number}}</td>
				<td>
					<a [routerLink]="['/', row.customer_id]">{{row.customer_name}}</a>
				</td>
				<td>
					<a [routerLink]="['/', row.customer_id, row.site_id]">{{row.site_name}}</a>
				</td>
				<td>
					<a [routerLink]="['/',row.customer_id, row.site_id, row.mac_address,'cellular-settings']">{{row.iccid}}</a>
				</td>
				<td>{{(row.last_disabled_time) | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
				<td>{{(disabledSources[row.disabled_source] || '---') | translate}}</td>
			</tr>
		</tbody>
	</table>
	<ng-template #noData>
		<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
	</ng-template>
</app-modal>

<app-modal [hasConfirmButton]="false" [closeBtnText]="'g.close' | translate" #blockedSimsPopup title="{{'sim_management.blocked_sims' | translate}}" size="xl">
	<div class="alert alert-info" role="alert">
		{{'sim_management.blocked_sims_note' | translate}}
	</div>
	<div class="d-flex justify-content-end my-3">
		<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedBlockedICCIDs.length" (click)="showBlockedSimsConfirmationPopup()">
			{{'g.unblock' | translate}}
		</button>
	</div>
	<table class="table table-striped"
		*ngIf="blockedSims?.length; else noData"
	>
		<thead>
			<tr>
				<th></th>
				<th>{{'devices.serial_number' | translate}}</th>
				<th>{{'g.mac_address' | translate}}</th>
				<th>{{'g.customer_name' | translate}}</th>
				<th>{{'g.site_name' | translate}}</th>
				<th>{{'qv.cellular_iccid' | translate}}</th>
				<th>{{'sim_management.blocked_since' | translate}}({{showUserTimeZoneReference()}})</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of blockedSims">
				<td>
					<div class="form-check" (click)="selectBlockedICCID(row.iccid)">
						<fa-icon *ngIf="selectedBlockedICCIDs.includes(row.iccid)" [icon]="['far', 'check-square']" size="sm" class="mr-2"></fa-icon>
						<fa-icon *ngIf="!selectedBlockedICCIDs.includes(row.iccid)" [icon]="['far', 'square']" size="sm" class="mr-2"></fa-icon>
					</div>
				</td>
				<td>
					<a [routerLink]="['/',row.customer_id, row.site_id, row.mac_address,'performance']">{{row.serial_number}}</a>
				</td>
				<td>{{row.mac_address}}</td>
				<td>
					<a [routerLink]="['/', row.customer_id]">{{row.customer_name}}</a>
				</td>
				<td>
					<a [routerLink]="['/', row.customer_id, row.site_id]">{{row.site_name}}</a>
				</td>
				<td>
					<a [routerLink]="['/',row.customer_id, row.site_id, row.mac_address,'cellular-settings']">{{row.iccid}}</a>
				</td>
				<td>{{row.last_block_time !== '---' ? (row.last_block_time | date:'MM/dd/yyyy hh:mm:ss') : row.last_block_time}}</td>
			</tr>
		</tbody>
	</table>
	<ng-template #noData>
		<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
	</ng-template>
</app-modal>

<app-modal [hasCloseButton]="false" [hasConfirmButton]="false" #blockedSimsConfirmationPopup title="{{'sim_management.blocked_sims' | translate}}" size="xl">
	<h5>{{'sim_management.unblock_sims_confirmation' | translate}}</h5>
	<table class="table table-striped">
		<thead>
			<th>{{'qv.cellular_iccid' | translate}}</th>
			<th>{{'g.unblock_status' | translate}}</th>
		</thead>
		<tbody>
			<tr *ngFor="let iccid of selectedBlockedICCIDs">
				<td>{{iccid}}</td>
				<td>
					<div *ngIf="blockActionDone && doneBlockICCIDs.includes(iccid)" class="done"><fa-icon icon="check-circle" size="lg"></fa-icon></div>
					<div *ngIf="blockActionDone && !doneBlockICCIDs.includes(iccid)" class="failed"><fa-icon icon="times-circle" size="lg"></fa-icon></div>
					<div *ngIf="!blockActionDone">-</div>
				</td>
			</tr>
		</tbody>
	</table>
	<div class="text-area">
		<label for="reason">{{'g.reason' | translate}}*</label>
		<textarea name="reason" id="reason" rows="10" [(ngModel)]="unblockReason"></textarea>
	</div>
	<div dialog-content>
		<div class="mt-5 d-flex flex-row-reverse">
			<button class="btn btn-light ml-3" (click)="blockedSimsConfirmationPopup.onHide()">{{'g.close' | translate}}</button>
			<button [disabled]="unblockReason == '' || blockActionDone" class="btn btn-primary me-2" (click)="confirmUnblock()">{{'g.confirm' | translate}}</button>
		</div>
	</div>
</app-modal>

<app-modal
	[hasCloseButton]="false"
	[hasConfirmButton]="false"
	#exportFilePopup title="{{'g.export_to_csv' | translate}}"
	size="lg"
>
	<div class="row col-md-12 mb-5">
		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp3" [(ngModel)]="fromDate" bsDatepicker #d3="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" (ngModelChange)="checkDateValidation()" [ngClass]="{'not_valid': notValidDate}">
				<div class="input-group-append pointer" (click)="d3.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>

		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp4" [(ngModel)]="toDate" bsDatepicker #d4="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}" (ngModelChange)="checkDateValidation()" [ngClass]="{'not_valid': notValidDate}">
				<div class="input-group-append pointer" (click)="d4.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
	</div>
	<p *ngIf="notValidDate" class="text-error">{{'g.start_date_before_end_date' | translate}}</p>
	<div dialog-content>
		<div class="mt-5 d-flex flex-row-reverse">
			<button class="btn btn-light" (click)="exportFilePopup.hide()">{{'g.close' | translate}}</button>
			<button [disabled]="!fromDate || !toDate || notValidDate" class="btn btn-primary me-2" (click)="generateMainTableCsvFile()">{{'g.export_to_csv' | translate}}</button>
		</div>
	</div>
</app-modal>

<app-modal
	[hasCloseButton]="false"
	[hasConfirmButton]="false"
	#actionsConfirmationPopup title="{{'g.confirmation' | translate}}"
	size="xl"
>
	<p>{{('sim.confirmation_massage_1' | translate)+ " " + operation + " " + ('sim.confirmation_massage_2' | translate)}}</p>
	<ul>
		<li *ngFor="let iccid of ICCIDs">{{iccid}}</li>
	</ul>
	<div class="text-area">
		<label for="reason">{{'g.reason' | translate}}*</label>
		<textarea name="reason" id="reason" rows="10" [(ngModel)]="actionReason"></textarea>
	</div>
	<div dialog-content>
		<div class="mt-5 d-flex flex-row-reverse">
			<button class="btn btn-light ml-3" (click)="actionsConfirmationPopup.onHide()">{{'g.close' | translate}}</button>
			<button [disabled]="actionReason == ''" class="btn btn-primary me-2" (click)="actionsConfirmationPopup.onConfirm()">{{'g.confirm' | translate}}</button>
		</div>
	</div>
</app-modal>
