import { Component, OnInit, OnDestroy } from '@angular/core';
import { SitesService } from 'src/app/sites/sites.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { CommonService } from 'src/app/shared/services/common.service';
import * as lo from 'lodash-es';
import * as moment from 'moment';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { SiteDashboardService } from '../site-dashboard.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { Router } from '@angular/router';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { ChartDataService } from 'src/app/shared/chart-data-widget/chart-data.service';

@Component({
  selector: 'app-site-performance',
  templateUrl: './site-performance.component.html',
  styleUrls: ['./site-performance.component.css']
})
export class SitePerformanceComponent implements OnInit, OnDestroy {

	dataSub: any = {};
	permissionsSub: any = {};
	voltageLevels: any ;

	siteId: number = 0;
	customerId: number = 0;
	siteInfo: any;

	data: any = {};
	devices: any = {};

	listsOptions: {
		truckTypes,
		truckYears,
		batteryCapacities,
		batteryVoltages,
		batteryTypes,
	} = {
		truckTypes: [],
		truckYears: [],
		batteryCapacities: [],
		batteryVoltages: [],
		batteryTypes: [],
	};

	tags: any;
	deviceTags: any;

	columnNames: any = {};
	tableColumns: any = {};

	widgetsIds: string[] = [
		'truck_usage_duration',
		'truck_usage_as',
		'truck_usage_ws',
		'idle_duration',
		'relative_truck_usage_kws',
		'end_of_voltages_vpc',
		'regen_report',
	];

	usageAmpData: any;
	usageKWData: any;
	usageChartDataAmp: any = [];
	usageChartDataWatt: any = [];
	usageAmpColumnNames: any = [this.translate('rt.sequence_id'), this.translate('perf_analytic.total_amp_usage')];
	usageKwColumnNames: any = [this.translate('rt.sequence_id'), this.translate('perf_analytic.total_kw_usage')];
	usageAmpOptions:any = {
		legend: {position: 'none'},
		explorer: {
			"actions": [
				"dragToZoom",
				"rightClickToReset"
			],
			maxZoomIn: 50.0,
			keepInBounds: true
		},
	};
	usageKwOptions:any = {
		legend: {position: 'none'},
		explorer: {
			"actions": [
				"dragToZoom",
				"rightClickToReset"
			],
			maxZoomIn: 50.0,
			keepInBounds: true
		},
	};
	usageChartReady: boolean = false;

	usageTooltip = '';
	usageFromDate: Date = new Date(new Date().setDate(new Date().getDate() - 7));
	usageToDate: Date = new Date();
	usageInvalidDateRange: boolean = false;
	minStartUsageDate: Date = new Date(new Date().setDate(new Date().getDate() - 91));
	isCollapsed: boolean = false;
	usageTruckType: string = 'all';


	reportFromDate: Date = new Date(new Date().setDate(new Date().getDate() - 7));
	reportToDate: Date = new Date();
	reportInvalidDateRange: boolean = false;
	minStartReportDate: Date = new Date(new Date().setDate(new Date().getDate() - 91));

	dailyGridCols: any[] = [];
	aggGridCols: any[] = [];

	siteReport: any;
	mac_address:any[] = [];
	dailyDetails:any[] = [];
	aggDetails:any[] = [];
	selectView:boolean= true;
	isChargersPerformance: boolean = false;
	chargLinkTranslationPath: string = "";



	constructor(
		private sitesService: SitesService,
		private sideMenuService: SideMenuService,
		private commonService: CommonService,
		private translateService: TranslateService,
		private userService: UsersService,
		private siteDashboardService: SiteDashboardService,
		private deviceService: DeviceService,
		private router: Router,
		public commonData: CommonDataService,
		private chartDataService: ChartDataService
	) {
		this.isChargersPerformance = this.router.url.includes('chargers-performance');
		this.chargLinkTranslationPath = this.isChargersPerformance ? this.commonData.chargLinkTranslationPath : "";
		for(let id of this.widgetsIds) {
			this.tableColumns[id] = [];
		}
		this.tableColumns.default = [
			{ headerName: this.translate(this.chargLinkTranslationPath+'devices.truck_sn'), field: "appearName", width: 400},
			{ headerName: this.translate('g.total_value'), field: "totalValue"},
			{ headerName: this.translate('g.daily_avg_value'), field: "avgValue"},
		];
	}

	ngOnInit(): void {
		this.dataSub = this.sideMenuService.currentSite.subscribe((currentSite:any) => {
			if(!currentSite || !currentSite.id)
				return;

			this.voltageLevels = currentSite.voltage_levels ? currentSite.voltage_levels : this.siteDashboardService.voltageLevels;
			this.chartDataService.setVoltageLevels(this.voltageLevels);

			this.siteInfo = currentSite;
			this.customerId = currentSite.customer_id;
			this.siteId = currentSite.id;
			this.sitesService.getReportsData(this.siteId, this.isChargersPerformance).subscribe((reportData: any) => {
				this.siteReport = reportData;
				this.prepareSiteReportData(reportData.data);
				this.prepareDevices(reportData.devices);
				this.prepareTags(reportData.tags);
				this.prepareTableColumns();
				this.prepareTruckTypesOptions();
				this.prepareBatteryCapacityOptions();
				this.prepareBatteryVoltageOptions();
				this.prepareTruckYearOptions();
				this.prepareBatteryTypeOptions();
				// this.buildGrid();

				this.usageAmpData = (this.isChargersPerformance ? this.data.amp_charger_usage_summary : this.data.amp_usage_summary) || {};
				this.usageKWData = (this.isChargersPerformance ? this.data.kw_charger_usage_summary : this.data.kw_usage_summary) || {};
				this.prepareUsageData();

				this.setColumnNames();
			});
		});

		this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data=> {
			this.usageTooltip = this.commonService.getTooltipKey(this.chargLinkTranslationPath+'total_trucks_usage', this.userService.userHasNOCAccess(data));
		});
	}


	onViewSelectionChange(entry:any): void {
		this.selectView = entry;
	}
	

	buildGrid() {
		for (const item in this.devices) {

			this.aggDetails = [];
			let zoneDiff		= new Date().getTimezoneOffset() * -1;
			let fromDate:any	= new Date(new Date(this.reportFromDate).getTime() + (zoneDiff * 60 * 1000));
			let toDate:any		= new Date(new Date(this.reportToDate).getTime() + (zoneDiff * 60 * 1000));
			fromDate			= moment(fromDate).utc().startOf('day').unix();
			toDate				= moment(toDate).utc().endOf('day').unix();
			
			this.deviceService.getDailyDetails(item, fromDate, toDate).subscribe((res: any) => {
				
			for (const k in res) {
				if (res[k].charge_events_as !== undefined && res[k].inuse_events_as !== undefined) {
					this.dailyDetails.push({
						mac_address: item,
						charge_events: res[k].charge_events_as || 0,
						min_charge_events: (res[k].charge_events_as || 0) * (res[k].charge_events_as || 0),
						max_charge_events: (res[k].charge_events_as || 0) * (res[k].charge_events_as || 0),
						inuse_events: res[k].inuse_events_as || 0,
						min_inuse_events: (res[k].inuse_events_as || 0) * (res[k].inuse_events_as || 0),
						max_inuse_events: (res[k].inuse_events_as || 0) * (res[k].inuse_events_as || 0),
						});
					this.aggDetails.push({ id: [moment.unix(Number(k)).format('MM/DD/YYYY')],
						charge_events: res[k].charge_events_as,
						inuse_events: res[k].inuse_events_as });
				}
			}
			this.dailyDetails = this.dailyDetails.reduce((acc, { mac_address, charge_events, min_charge_events, max_charge_events, inuse_events, min_inuse_events, max_inuse_events }) => {
				acc[mac_address] = acc[mac_address] || { mac_address, charge_events: [], min_charge_events: [], max_charge_events: [], inuse_events: [], min_inuse_events: [], max_inuse_events: [] };
				if (Array.isArray(charge_events) && Array.isArray(min_charge_events) && Array.isArray(max_charge_events) && Array.isArray(inuse_events) && Array.isArray(min_inuse_events) && Array.isArray(max_inuse_events)) {
				acc[mac_address].charge_events.push(...charge_events);
				acc[mac_address].min_charge_events.push(...min_charge_events);
				acc[mac_address].max_charge_events.push(...max_charge_events);
				acc[mac_address].inuse_events.push(...inuse_events);
				acc[mac_address].min_inuse_events.push(...min_inuse_events);
				acc[mac_address].max_inuse_events.push(...max_inuse_events);
				} else {
				acc[mac_address].charge_events.push(charge_events);
				acc[mac_address].min_charge_events.push(min_charge_events);
				acc[mac_address].max_charge_events.push(max_charge_events);
				acc[mac_address].inuse_events.push(inuse_events);
				acc[mac_address].min_inuse_events.push(min_inuse_events);
				acc[mac_address].max_inuse_events.push(max_inuse_events);
				}
				return acc;
			}, {});
			this.dailyDetails = Object.values(this.dailyDetails).map((item) => {
				item.charge_events = item.charge_events.reduce((sum :any, value: any) => sum + value, 0);
				item.min_charge_events = item.min_charge_events.reduce((sum: any, value: any) => sum + value, 0);
				item.max_charge_events = item.max_charge_events.reduce((sum: any, value: any) => sum + value, 0);
				item.inuse_events = item.inuse_events.reduce((sum :any, value: any) => sum + value, 0);
				item.min_inuse_events = item.min_inuse_events.reduce((sum: any, value: any) => sum + value, 0);
				item.max_inuse_events = item.max_inuse_events.reduce((sum: any, value: any) => sum + value, 0);
				return item;
			});
			this.aggDetails = this.aggDetails.reduce((acc, { id, charge_events, inuse_events }) => {
				acc[id] = acc[id] || { id, charge_events: [], inuse_events: [] };
				acc[id].charge_events.push(...(Array.isArray(charge_events) ? charge_events : [charge_events]));
				acc[id].inuse_events.push(...(Array.isArray(inuse_events) ? inuse_events : [inuse_events]));
				return acc;
			}, {});
			this.aggDetails = Object.values(this.aggDetails).map((item) => {
				item.charge_events = item.charge_events.reduce((sum: any, value: any) => sum + value, 0);
				item.inuse_events = item.inuse_events.reduce((sum: any, value: any) => sum + value, 0);
				return item;
			});
			});
		};

		const dailyGridCols: any[] = [
			{ headerName: this.translateService.instant('g.mac_address'), field: 'mac_address', width: 200 },
			{ headerName: this.translateService.instant('daily_details.charge_events_ahr'), field: 'charge_events', width: 200, type: 'number', filterParams: { format: '1.2-2' } },			
			{ headerName: this.translateService.instant('daily_details.min_charge_events_ahr'), field: 'min_charge_events', width: 200, type: 'number', filterParams: { format: '1.2-2' }},
			{ headerName: this.translateService.instant('daily_details.max_charge_events_ahr'), field: 'max_charge_events', width: 200, type: 'number', filterParams: { format: '1.2-2' } },
			{ headerName: this.translateService.instant('daily_details.inuse_events_ahr'), field: 'inuse_events', width: 200, type: 'number', filterParams: { format: '1.2-2' } },			
			{ headerName: this.translateService.instant('daily_details.min_inuse_events_ahr'), field: 'min_inuse_events', width: 200, type: 'number', filterParams: { format: '1.2-2' }},
			{ headerName: this.translateService.instant('daily_details.max_inuse_events_ahr'), field: 'max_inuse_events', width: 200, type: 'number', filterParams: { format: '1.2-2' } }
		];
		this.dailyGridCols = dailyGridCols;

			
		const aggGridCols: any[] = [
			{ headerName: this.translateService.instant('g.date'), field: 'id', type: 'date', width: 425 },
			{ headerName: this.translateService.instant('daily_details.charge_events_ahr'), field: 'charge_events', width: 425, type: 'number', filterParams: { format: '1.2-2' } },
			{ headerName: this.translateService.instant('daily_details.inuse_events_ahr'), field: 'inuse_events', width: 425, type: 'number', filterParams: { format: '1.2-2' } },
		];
		this.aggGridCols = aggGridCols;
	}


	ngOnDestroy(): void {
		if(this.dataSub)
			this.dataSub.unsubscribe();

		if(this.permissionsSub)
			this.permissionsSub.unsubscribe();
	}

	private prepareSiteReportData(siteData) {
		let data = this.commonService.decompress(siteData, 'siteReportData');
		let result = {};
		for(let section in data) {
			result[section] = this.commonService.decompress(data[section], 'siteReportDataPeriods');
		}
		this.data = result;
	}

	private prepareDevices(devices) {
		const devicesObj = {};
		devices.forEach((device) => {
			device = this.commonService.decompress(device, 'siteDevice');
			devicesObj[device.mac_address] = device;
		});
		this.devices = devicesObj;
	}

	private prepareTags(tagsData) {
		const siteTags = [];
		const deviceTags = {};
		Object.values(tagsData.siteTags).forEach((tag) => {
			siteTags.push(this.commonService.decompress(tag, 'tag'));
		});
		for(let macAddress in tagsData.deviceTags) {
			deviceTags[macAddress] = deviceTags[macAddress] || [];
			Object.values(tagsData.deviceTags[macAddress]).forEach((tag) => {
				deviceTags[macAddress].push(this.commonService.decompress(tag, 'deviceTag'));
			});
		}
		this.tags = siteTags;
		this.deviceTags = deviceTags;
	}

	private prepareTableColumns() {
		this.tableColumns.default[0].cellRendererSelector = (params) => {
			params.label = params.data.appearName;
			params.link = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, params.data.mac_address, "performance"].join('/');
			return {
				component: 'linkCellRenderer',
				params: params
			};
		};
		this.tableColumns.default[0].cellRendererParams = {customerId: this.customerId, siteId: this.siteId};
		for(let id of this.widgetsIds) {
			this.tableColumns[id] = JSON.parse(JSON.stringify(this.tableColumns.default));
			this.tableColumns[id][0].cellRendererSelector = this.tableColumns.default[0].cellRendererSelector;
		}

		this.tableColumns.truck_usage_duration[1].headerName	= this.translate('site_performance.truck_hours');
		this.tableColumns.truck_usage_duration[2].headerName	= this.translate('site_performance.avg_truck_hours');
		this.tableColumns.truck_usage_as[1].headerName			= this.translate('site_performance.total_ebus');
		this.tableColumns.truck_usage_as[2].headerName			= this.translate('site_performance.avg_ebus');
		this.tableColumns.truck_usage_ws[1].headerName			= this.translate('device_performance.total');
		this.tableColumns.truck_usage_ws[2].headerName			= this.translate('device_performance.avg');
		this.tableColumns.regen_report[1].headerName			= this.translate('device_performance.total');
		this.tableColumns.regen_report[2].headerName			= this.translate('device_performance.avg');
		this.tableColumns.relative_truck_usage_kws[1]			= {headerName: this.translate('site_performance.utilization_percentage'), field:'value'};
		this.tableColumns.idle_duration[1]						= {headerName: this.translate('site_performance.avg_truck_hours'), field:'value'};

		delete this.tableColumns.idle_duration[2];
		delete this.tableColumns.relative_truck_usage_kws[2];

		this.tableColumns.end_of_voltages_vpc[1] = {headerName: this.translate('events.sequence_id'), field: "eventId"};
		this.tableColumns.end_of_voltages_vpc[2] = {headerName: this.translate('event.vpc'), field: "value"};
	}

	private setColumnNames() {
		let columnNames: any = {};
		columnNames.truck_usage_duration = [
			this.translate('g.type')
		];
		columnNames.truck_usage_as = [
			this.translate('g.type')
		];
		columnNames.truck_usage_ws = [
			this.translate('g.type')
		];

		for(let truckType of this.listsOptions.truckTypes) {
			if(truckType.value != 'all') {
				columnNames.truck_usage_duration.push(truckType.label);
				columnNames.truck_usage_as.push(truckType.label);
				columnNames.truck_usage_ws.push(truckType.label);
			}
		}

		this.columnNames = columnNames;
	}

	private prepareTruckTypesOptions() {
		let truckTypesOptions = [];
		let counter = 1;
		for(let type of lo.sortBy(lo.uniq(lo.map(this.devices, 'truck_type')))) {
			// if(!type)
			// 	continue;
			truckTypesOptions.push({
				value: type,
				label: type,
				idx: counter
			});
			counter++;
		}

		if(truckTypesOptions.length)
			truckTypesOptions.push({
				value: 'all',
				label: this.translate('site_performance.all_truck_types'),
				idx: 0
			});
		this.listsOptions.truckTypes = truckTypesOptions;
	}

	private prepareBatteryCapacityOptions() {
		let batteryCapacityOptions = [];
		for(let type of lo.sortBy(lo.uniq(lo.map(this.devices, 'battery_capacity')))) {
			batteryCapacityOptions.push({
				value: type,
				label: type
			});
		}
	
		if(batteryCapacityOptions.length)
			batteryCapacityOptions.push({
				value: 'all',
				label: this.translate('site_performance.all_battery_capacities')
			});
		this.listsOptions.batteryCapacities = batteryCapacityOptions;
	}

	private prepareBatteryVoltageOptions() {
		let batteryVoltageOptions = [];
		for(let type of lo.sortBy(lo.uniq(lo.map(this.devices, 'battery_voltage')))) {
			batteryVoltageOptions.push({
				value: type,
				label: type
			});
		}
	
		if(batteryVoltageOptions.length)
			batteryVoltageOptions.push({
				value: 'all',
				label: this.translate('site_performance.all_battery_voltages')
			});
		this.listsOptions.batteryVoltages = batteryVoltageOptions;
	}

	private prepareTruckYearOptions() {
		let truckYearOptions = [];
		for(let item of ['all', 'new', 'old']) {
			truckYearOptions.push({
				value: item,
				label: this.translate('site_performance.truck_year_'+item)
			});
		}

		this.listsOptions.truckYears = truckYearOptions;
	}

	private prepareBatteryTypeOptions() {
		let batteryTypeOptions = [];
		for(let type of lo.sortBy(lo.uniq(lo.map(this.devices,  'battery_type')))) {
			let textKey = 'site_performance.battery_type_'+type;
			let label = this.translate(textKey);
			if(textKey != label)
				batteryTypeOptions.push({value: type, label});
		}
	
		if(batteryTypeOptions.length > 1) {
			batteryTypeOptions.push({
				value: 'all',
				label: this.translate('site_performance.all_battery_types')
			});
			this.listsOptions.batteryTypes = batteryTypeOptions;
		}
	}

	private translate(key) {
		return this.translateService.instant(key);
	}

	usageDatesChanged() {
		this.usageInvalidDateRange = false;
		if(moment(this.usageFromDate).unix() > moment(this.usageToDate).unix())
			this.usageInvalidDateRange = true;

		this.prepareUsageData();
	}

	reportDatesChanged() {
		this.reportInvalidDateRange = false;
		if(moment(this.reportFromDate).unix() > moment(this.reportToDate).unix())
			this.reportInvalidDateRange = true;

		this.buildGrid();
	}

	getTruckTypeName(value) {
		let item = lo.find(this.listsOptions.truckTypes, {value})
		return item?.label;
	}

	private prepareUsageData() {
		if(!Object.keys(this.usageAmpData).length)
			return;

		let filteredAmpData = this.filterUsageData(this.usageAmpData);
		let filteredWattData = this.filterUsageData(this.usageKWData);
		let usageChartData = this.getUsageChartData(filteredAmpData, filteredWattData);
		this.usageChartDataAmp = usageChartData.amp;
		this.usageChartDataWatt = usageChartData.watt;
	}

	private filterUsageData(data) {
		let zoneDiff = new Date().getTimezoneOffset() * -1 * 60 * 1000;
		let fromDate:any	= new Date(new Date(this.usageFromDate).getTime() + zoneDiff);
		let toDate:any		= new Date(new Date(this.usageToDate).getTime() + zoneDiff);
		fromDate	= moment(fromDate).utc().startOf('day').unix();
		toDate		= moment(toDate).utc().endOf('day').unix();

		let dataAfterTruckTypeFilter = [];
		if(this.usageTruckType != 'all')
			dataAfterTruckTypeFilter.push(data[this.usageTruckType]);
		else
			dataAfterTruckTypeFilter = Object.values(data);

		let filteredData = {};
		for(let truckData of dataAfterTruckTypeFilter) {
			for(let day in truckData) {
				if((+day) >= fromDate && (+day) <= toDate) {
					for(let i = 0; i < 96; i++) {
						let time = (i * 15 * 60) + (+day);
						if(!filteredData[time]) {
							filteredData[time] = 0;
						}
						filteredData[time] += (truckData[day][i] || 0);
					}
				}
			}
		}
		return filteredData;
	}

	private getUsageChartData(dataAmp, dataWatt) {
		let chartData = [];
		let chartDataWatt = [];

		let zoneDiff = new Date().getTimezoneOffset() * -1 * 60 * 1000;
		
		for (let time in dataAmp) {
			chartData.push([new Date(((+time)*1000) - zoneDiff), dataAmp[time]]);
		}
		for (let time in dataWatt) {
			chartDataWatt.push([new Date(((+time)*1000) - zoneDiff), dataWatt[time]]);
		}
		this.usageChartReady = true;
		return {amp: chartData, watt: chartDataWatt};
	}

	exportTotalUsage() {
		this.commonService.exportTotalUsageWidget({
			siteName: this.siteInfo.name,
			startDate: this.usageFromDate,
			endDate: this.usageToDate,
			usageAmpData: this.usageChartDataAmp,
			usageWattData: this.usageChartDataWatt
		})
	}
}