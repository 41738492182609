<div>
    <div class="d-flex justify-content-between mb-2 px-2">
	    <h2>{{'connectivity_verification.connectivity_verification_header' | translate}}</h2>
	</div>

    <table class="table table-striped"*ngIf="connectivityLogStatistics.length > 0; else noData" >
        <thead>
			<tr>
				<th>{{'connectivity_verification.month' | translate}}</th>
				<th>{{'connectivity_verification.total_check' | translate}}</th>
				<th>{{'connectivity_verification.total_number_unique_devices' | translate}}</th>
				<th>{{'connectivity_verification.total_number_unique_sites' | translate}}</th>
			</tr>
		</thead>
        <tbody>
			<tr *ngFor="let record of connectivityLogStatistics">
				<td>
					{{record.month}} {{record.year}}
				</td>
				<td>
					{{record.total_checks}}
				</td>
				<td class="pointer" (click)="showDevicesInfoModal(record.mac_addresses )">
					{{record.mac_addresses .length}}
				</td>
				<td class="pointer" (click)="showSitesInfoModal(record.sites_ids)">
					{{record.sites_ids.length}}
				</td>
			</tr>
		</tbody>
    </table>
</div>

<ng-template #noData>
    <div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal #devicesInfoModel  [hasConfirmButton]="false" [hasCloseButton]="false">
	<table class="table table-striped">
        <thead>
            <tr class="text-center">
                <th>{{'g.devices' | translate}}</th>
            </tr>
        </thead>
        <tbody>
          <tr class="text-center" *ngFor="let macAddress of devicesData">
            <td>
                <a [routerLink]="devicesInfo[macAddress].serial_number == 'device_deleted'? null: ['/', devicesInfo[macAddress].customerId, devicesInfo[macAddress].siteId, macAddress, 'performance']">
                    {{(devicesInfo[macAddress].serial_number == 'device_deleted'? macAddress : devicesInfo[macAddress].serial_number)}}
                </a>
            </td>
          </tr>
        </tbody>
    </table>
</app-modal>

<app-modal #sitesInfoModel  [hasConfirmButton]="false" [hasCloseButton]="false">
    <table class="table table-striped">
        <thead>
            <tr class="text-center">
                <th>{{'site.sites' | translate}}</th>
            </tr>
        </thead>
        <tbody>
            <tr class="text-center" *ngFor="let site of sitesData">
                <td>
                    <a [routerLink]="sitesInfo[site].siteName == 'site_deleted'? null : ['/', sitesInfo[site].customerId, site]">
                    {{(sitesInfo[site].siteName == 'site_deleted'? "connectivity_verification." + sitesInfo[site].siteName  : sitesInfo[site].siteName ) | translate}}
                    </a>
                </td>
            </tr>
        </tbody>
    </table>
</app-modal>
