import { Component, OnInit, Input } from '@angular/core';
import { DeviceManagementService } from '../device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';

@Component({
	selector: 'app-reverse-events',
	templateUrl: './reverse-events.component.html',
})
export class ReverseEventsComponent implements OnInit {

	@Input() device: any = {};
	@Input() study: any = {};

	startId;
	endId;
	
	constructor(
		private deviceManagementService: DeviceManagementService,
		private notificationMessage: NotificationMessageService,
	){}

	ngOnInit() {}

	reverseEvents() {
		if(!this.startId || !this.endId || this.startId > this.endId)
			return this.notificationMessage.setMessage('translate|g.invalid_input', {clearOnXTimeNavigate: 1});
		
		if (Object.keys(this.study).length && !this.studyReversEventsValidation())
			return this.notificationMessage.setMessage('translate|studies.reverse_events_validation', {clearOnXTimeNavigate: 1});

		const studyId = this.study?.id || 0;
		this.deviceManagementService.reverseEvents(this.device.mac_address, this.startId, this.endId, studyId).subscribe(()=>{
			this.startId = this.endId = null;
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	};

	studyReversEventsValidation() {
		if (this.startId < this.study.start_event_id || (this.study.is_done && this.endId > this.study.end_event_id))
			return false;
		return true;
	}
}