import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SiteDashboardService } from '../site-dashboard.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { SitesService } from 'src/app/sites/sites.service';
import { DeviceService } from '../device/device.service';
import * as moment from 'moment';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

@Component({
	selector: 'app-site-info',
	templateUrl: './site-info.component.html',
	styleUrls: ['./site-info.component.css']
})
export class SiteInfoComponent implements OnInit, OnDestroy {
	siteInfo: any = {};
	customerInfo: any = {};
	permissions: any = {};

	devices = [];
	IoTAhDevices = [];
	chargeLinkDevices = [];
	dots = [];

	uniqueIPaddresses = [];
	showOnlyConnected = false;
	liveSiteTimeInterval;
	liveSiteTime;
	zoneName;
	devicesGridCols: any[] = [];
	chargLinkDevicesGridCols: any[] = [];
	currentUser: any = {};
	whrsUnit;
	whrsUsed;
	ahrsUnit;
	ahrsUsed;
	hoursUsed;
	infoBoxSiteAnalytics: any;

	siteDevicesAnalytics: any = {};

	isProduction: boolean = false;

	minDate!: Date;
	minDateChargersDevices!: Date;

	monthsOption: any[] = [];
	chargLinkQuartersOption: any[] = ['All-Year', 'Q1', 'Q2', 'Q3', 'Q4'];

	yearsOption: any[] = [];
	chargLinkYearsOption: any[] = [];

	// Quarterly Charge Usage Report ( IoTAh )
	quarterlyReportCols: any[] = [];
	quarterData: any;
	monthData: any;
	quartersList: any[] = [];
	monthsList: any[] = [];
	truckYearsList: any[] = [];

	selectedMonthData: any[];
	selectedYearQ: any;
	selectedQuarter: any;
	selectedMonth: any;
	selectedTruckYear: any = 'all';

	// Quarterly Charge Usage Report ( ChargLink )
	chargLinkQuarterlyReportCols: any[] = [];
	chargLinkQuarterData: any;
	chargLinkQuartersList: any[] = [];

	charglinkSelectedQuarterData: any[];
	chargLinkSelectedQuarter: any;
	chargLinkSelectedYear: any;

	contactInfo: any[] = [];
	isChargeLink = false;
	tooltips = {
		quarterly_site_report: '',
		chargLink_quarterly_site_report: '',
		site_devices: '',
		chargLink_site_devices: '',
		site_info: '',
		chargLink_site_info: ''
	};

	tags: any[];
	deviceTags: any[];
	infoBox;

	status = {
		failed: this.translateService.instant(`g.failed`),
		passed: this.translateService.instant(`g.passed`),
		not_tested: this.translateService.instant(`g.not_tested`),
	}

	permissionsSub: Subscription = new Subscription();

	selectedDataOption = 'since_installation';

	siteId!: number;
	siteZoneId!: number;
	selectedYearUsageReport!: number;
	chargLinkSelectedYearUsageReport!: number;
	currentSiteName!: string;
	currentSiteAddress!: string;
	siteUTCDifference = '';

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private siteDashboardService: SiteDashboardService,
		private common: CommonService,
		private commonData: CommonDataService,
		public usersService: UsersService,
		public sitesService: SitesService,
		public deviceService: DeviceService,
		private notificationMessage: NotificationMessageService,
		public sideMenuService: SideMenuService
	) { }

	ngOnInit() {
		this.isProduction = this.commonData.productionDomain.includes(window.location.hostname);

		let fieldsAccess: any = {};
		this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data => {
			for (let key in this.tooltips) {
				this.tooltips[key] = this.common.getTooltipKey(key, this.usersService.userHasNOCAccess(data));
			}
			fieldsAccess = {
				truck_manufacturer: this.usersService.hasAccessPermission(data, 'truck_manufacturer'),
				last_pm_date: this.usersService.hasAccessPermission(data, 'last_pm_date'),
				billed_kwhr: this.usersService.hasAccessFunction('billed_kwhrs_settings'),
				charger_max_power: this.usersService.hasAccessPermission(data, 'basic_setup')
			};
		});

		this.usersService.currentUser.subscribe((user: any) => {
			if (user && user.id) {
				this.currentUser = user;
			}
		});

		const siteId = parseInt(this.route.snapshot.params.siteId);
		const customerId = parseInt(this.route.snapshot.params.customerId);

		if (!siteId || isNaN(siteId)) {
			return this.router.navigateByUrl('/404');
		}

		this.fillQuarterlyReportCols(customerId, siteId, fieldsAccess);
		this.fillChargLinkQuarterlyReportCols(customerId, siteId, fieldsAccess);

		this.truckYearsList = [];
		for (let item of ['all', 'new', 'old']) {
			this.truckYearsList.push({
				value: item,
				label: this.translateService.instant('site_performance.truck_year_' + item)
			});
		}

		this.siteDashboardService.getSiteData(siteId).subscribe((data: any) => {
			this.handleIotahUsageReportSelectDate(data.site.devices_min_installation_date || 0);
			this.handleChargersUsageReportSelectDate(data.site.devices_min_installation_date_chargLink || 0);

			this.siteInfo = this.common.decompress(data.site, 'site');
			this.currentSiteName = this.siteInfo.name;
			this.currentSiteAddress = this.siteInfo.address;
			this.siteId = this.siteInfo.id;
			this.siteZoneId = this.siteInfo.zone_id;
			this.sitesService.addSitesConnectivityStatusIcons([this.siteInfo]);
			this.customerInfo = this.common.decompress(data.customer, 'customer');
			this.devices = this.formatDeviceFields(data.device, data.permissions);
			this.siteDevicesAnalytics = data.siteDevicesAnalytics;
			this.siteInfo.devices_count = this.devices.length;
			this.permissions = this.common.revertPermissionValue(data.permissions);
			if (this.siteInfo.contact_name)
				this.contactInfo.push(this.siteInfo.contact_name);
			if (this.siteInfo.email)
				this.contactInfo.push(this.siteInfo.email);
			if (this.siteInfo.phone_num)
				this.contactInfo.push(this.siteInfo.phone_num);

			this.liveSiteTimeInterval = setInterval(() => {
				this.liveSiteTime = this.common.getDateFormattedFromUnixTimeStamp(this.common.getZoneTimestampFromUTC(this.siteInfo['zone_id'], moment().utc().unix()));
			}, 500);

			this.zoneName = this.common.getZoneName(this.siteInfo['zone_id']);
			this.showOnlyConnectedIPs(this.showOnlyConnected);

			// separate IoTAh devices from ChargLink devices
			this.filterDevices();

			this.prepareDevicesGridCols(customerId, siteId);
			this.prepareChargeLinkDevicesGridCols(customerId, siteId, fieldsAccess);

			this.infoBox = data.infoBox;
			this.setInfoBoxSiteAnalyticsData();

			// prepareQuarterReport (IoTAh)
			this.prepareQuarterReport(this.siteDevicesAnalytics.usageData.qReport, false);

			// prepareQuarterReport (ChargLink)
			this.prepareQuarterReport(this.siteDevicesAnalytics.usageData.qReport, true);

			this.prepareTags(data.siteTags);

			this.siteInfo.iotah_devices_count = this.infoBoxSiteAnalytics.ioTAh_devices.count;
			this.siteInfo.chargLink_devices_count = this.infoBoxSiteAnalytics.chargLink_devices.count;
			this.setMapDotes();
		});
	}

	setMapDotes() {
		this.dots.push({...this.siteInfo, type: 'site'});

		for (const device of this.devices) {
			if (device.last_update_location_time) {
				this.dots.push({
					latitude: device.last_latitude,
					longitude: device.last_longitude,
					type: 'device',
					truck_id: device.truck_id,
					truck_sn: device.truck_sn,
					mac_address: device.mac_address,
					site_id: this.siteInfo.id,
					customer_id: this.siteInfo.customer_id,
					serial_number: device.serial_number,
					is_charglink: device.is_charglink,
					color: device.is_charglink ? '#a5d662' : 'orange',
					group: true,
				})
			}
		}
	}

	handleIotahUsageReportSelectDate(minInstallationDate) {
		const currentYear = moment().year();
		this.selectedYearUsageReport = currentYear;
		const minDate = moment((minInstallationDate) * 1000)
		const startYear = minDate.year();
		this.minDate = minDate.toDate();

		const yearsOption = [];
		for (let year = currentYear; year >= startYear; year--) {
			yearsOption.push(year);
		}
		this.yearsOption = yearsOption;
	}

	handleChargersUsageReportSelectDate(minInstallationDate: any) {
		const currentYear = moment().year();
		this.chargLinkSelectedYearUsageReport = currentYear;
		const minDateChargersDevices = moment((minInstallationDate) * 1000)
		const startYear = minDateChargersDevices.year();
		this.minDateChargersDevices = minDateChargersDevices.toDate();

		const chargLinkYearsOption = [];
		for (let year = currentYear; year >= startYear; year--) {
			chargLinkYearsOption.push(year);
		}
		this.chargLinkYearsOption = chargLinkYearsOption;
	}

	getListQuarters(selectedYear: number, isChargeLink = false) {
		let minDate = this.minDate;
		if (isChargeLink)
			minDate = this.minDateChargersDevices;

		if (selectedYear === moment().year() || selectedYear === moment(minDate).year()) {
			const quarters: string[] = ['All-Year'];
			for (let quarter = 1; quarter <= 4; quarter++) {
				if ((selectedYear === moment().year() && quarter > moment().quarter()) || (quarter < moment(minDate).quarter() && selectedYear === moment(minDate).year()))
					continue;
				quarters.push(`Q${quarter}`);
			}
			return quarters;
		}
		return ['All-Year', 'Q1', 'Q2', 'Q3', 'Q4'];
	}

	getListMonths(selectedYear: number) {
		let months: any[] = [{
			value: 'All-Year',
			label: this.translateService.instant('g.all_year')
		}];
		if (+selectedYear === moment().year()) {
			for (let month = 0; month <= moment().month(); month++) {
				months.push({
					value: month + 1,
					label: moment().month(month).format('MMMM')
				});
			}
			return months;
		}
		months.push(moment.months().map((month, idx) => {
			return {
				value: idx + 1,
				label: month
			};
		}));
		return months;
	}

	fillChargLinkQuarterlyReportCols(customerId: number, siteId: number, fieldsAccess: any) {
		this.chargLinkQuarterlyReportCols = [
			{
				headerName: "chargLinkDevice.devices.truck_sn", field: "truck_sn",
				cellRendererSelector: function (params) {
					params['label'] = params.data.truck_sn;
					params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, params.data.mac_address, "performance"].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: { customerId: customerId, siteId: siteId },
			},
			{ headerName: "chargLinkDevice.devices.truck_id", field: "truck_id" },
			{ headerName: "chargLinkDevice.devices.truck_type", field: "truck_type" },
		];

		if (fieldsAccess.charger_max_power)
			this.chargLinkQuarterlyReportCols.push({ headerName: "chargLinkDevice.devices.charger_max_power", type: 'number', field: "max_charger_kw"});

		this.chargLinkQuarterlyReportCols.push(
			{ headerName: "site.total_hours", field: "total_hours" },
			{ headerName: "site.total_ahr", field: "total_ahr", type: 'number', filterParams: { format: '1.0-0' } },
			{ headerName: "chargLinkDevice.site.total_kwhr", field: "total_kwhr", type: 'number', filterParams: { format: '1.1-1' } })

		if (fieldsAccess.truck_manufacturer)
			this.chargLinkQuarterlyReportCols.push({ headerName: "chargLinkDevice.devices.truck_manufacturer", field: "truck_manufacturer" });
	}

	fillQuarterlyReportCols(customerId: number, siteId: number, fieldsAccess: any) {
		this.quarterlyReportCols = [
			{
				headerName: "devices.truck_sn", field: "truck_sn",
				cellRendererSelector: function (params) {
					params['label'] = params.data.truck_sn;
					params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, params.data.mac_address, "performance"].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: { customerId: customerId, siteId: siteId },
			},
			{ headerName: "devices.truck_id", field: "truck_id" },
			{ headerName: "devices.truck_type", field: "truck_type" },
			{ headerName: "site.total_hours", field: "total_hours" },
			{ headerName: "site.total_ahr", field: "total_ahr", type: 'number', filterParams: { format: '1.0-0' } },
			{ headerName: "site.total_kwhr", field: "total_kwhr", type: 'number', filterParams: { format: '1.1-1' } },
			{
				headerName: "site.new_old", field: "truck_year", hide: true, floatingFilterComponent: "DropDownFilter",
				floatingFilterComponentParams: {
					dropDownOptions: [
						{ value: null, label: this.translateService.instant('g.all') },
						{ value: 'new', label: this.translateService.instant('g.new') },
						{ value: 'old', label: this.translateService.instant('g.old') }
					]
				}
			},
		];

		if (fieldsAccess.truck_manufacturer)
			this.quarterlyReportCols.push({ headerName: "devices.truck_manufacturer", field: "truck_manufacturer" });

		this.quarterlyReportCols.push({ headerName: "devices.truck_manufacturing_year", field: "truck_manufacturing_year" });

		if (fieldsAccess.last_pm_date)
			this.quarterlyReportCols.push({ headerName: "site.last_pm_date", field: "last_pm_date" });
		if (fieldsAccess.billed_kwhr)
			this.quarterlyReportCols.push({ headerName: "site.billed_kwhr", field: "billed_kwhr", type: 'number', filterParams: { format: '1.0-2' } });
	}

	prepareDevicesGridCols(customerId, siteId) {
		let devicesGridCols = [
			{ headerName: "g.iotah_sn", field: "serial_number", width: 200 },
			{ headerName: "devices.fw_version", field: "fw_version", width: 150 },
			{
				headerName: "devices.truck_sn", field: "truck_sn", width: 200,
				cellRendererSelector: function (params) {
					params['label'] = params.data.truck_sn;
					params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, params.data.mac_address, "performance"].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: { customerId: customerId, siteId: siteId },
			},
			{ headerName: "devices.truck_type", field: "truck_type", width: 180 },
			{ headerName: "devices.unique_mac_address", field: "mac_address", width: 200, hide: true },
			{ headerName: "devices.hw_version", field: "hw_version", width: 150, hide: true },
			{ headerName: "g.ip_address", field: "ip_address", width: 200 },
			{ headerName: "rt.voltage", field: "_voltage", width: 150 },
			{ headerName: "devices.battery_capacity", field: "battery_capacity", width: 150 },
			{ headerName: "devices.installation_date", field: "_installation_date_ms", type: 'date', width: 180 },
			{ headerName: `${this.translateService.instant("devices.last_connect_time")} (${this.common.showUserTimeZoneReference()})`, field: "last_connect_time_formatted", hide: false, width: 220},
			{ headerName: "connectivity_verification.connectivity_verification_status", field: "connectivity_verfication_status_formatted", hide: false, width: 220,
				cellRendererSelector: function (params) {
					let link = [];

					if (params.data.connectivity_verfication_result && params.data.connectivity_verfication_result.report_id)
						link = ['/#', 'admin-panel', 'connectivity-verification-log', params.data.connectivity_verfication_result?.report_id];

					const label = params.colDef.cellRendererParams.getStatus(params.data, 'connectivity_verfication_result');

					params['label'] = params.colDef.cellRendererParams.status[label];
					params['link'] = link.length ? link.join('/') : null;
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: {status: this.status, getStatus: this.getStatus},
			},
			{ headerName: "devices.shipment_quality_checked", field: "shipment_quality_result_formatted", hide: false, width: 220,
				cellRendererSelector: function (params) {
					let link = [];

					if (params.data.shipment_quality_result && params.data.shipment_quality_result.report_id)
						link = ['/#', 'management', 'shipment-quality-history', params.data.shipment_quality_result?.report_id];

					const label = params.colDef.cellRendererParams.getStatus(params.data, 'shipment_quality_result');

					params['label'] = params.colDef.cellRendererParams.status[label];
					params['link'] = link.length ? link.join('/') : null;
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: {status: this.status, getStatus: this.getStatus},
			}
		];

		let filteredCols = [];
		if (this.IoTAhDevices.length > 0) {
			devicesGridCols.forEach((col) => {
				if ((col.field in this.devices[0])) {
					filteredCols.push(col);
				}
			});
		}

		this.devicesGridCols = filteredCols;
	}

	prepareChargeLinkDevicesGridCols(customerId, siteId, fieldsAccess) {
		let devicesGridCols = [
			{ headerName: "chargLinkDevice.g.iotah_sn", field: "serial_number", hide: false, width: 200 },
			{ headerName: "devices.fw_version", field: "fw_version", hide: false, width: 150 },
			{
				headerName: "chargLinkDevice.devices.truck_sn", field: "truck_sn",hide: false, width: 200,
				cellRendererSelector: function (params) {
					params['label'] = params.data.truck_sn;
					params['link'] = ["/#", params.colDef.cellRendererParams.customerId, params.colDef.cellRendererParams.siteId, params.data.mac_address, "performance"].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: { customerId: customerId, siteId: siteId, status: this.status, getStatus: this.getStatus},
			},
			{ headerName: "chargLinkDevice.devices.truck_type", field: "truck_type", type: 'text', hide: false, width: 180 },
		];

		if (fieldsAccess.charger_max_power)
			devicesGridCols.push({headerName: "chargLinkDevice.devices.charger_max_power", hide: false, type: 'number', field: "max_charger_kw", width: 200})

		devicesGridCols.push(
			{ headerName: "devices.unique_mac_address", field: "mac_address", type: 'text', width: 200, hide: true },
			{ headerName: "devices.hw_version", field: "hw_version", width: 150, type: 'number', hide: true },
			{ headerName: "g.ip_address", field: "ip_address", width: 200,type: 'text', hide: false },
			{ headerName: "rt.voltage", field: "_voltage", width: 150, type: 'number', hide: false },
			{ headerName: "devices.installation_date", field: "_installation_date_ms", hide: false, type: 'date', width: 180 },
			{ headerName: `${this.translateService.instant("devices.last_connect_time")} (${this.common.showUserTimeZoneReference()})`, field: "last_connect_time_formatted", hide: false, width: 220},
			{ headerName: "connectivity_verification.connectivity_verification_status", field: "connectivity_verfication_status_formatted", hide: false, width: 220,
				cellRendererSelector: function (params) {
					let link = [];

					if (params.data.connectivity_verfication_result && params.data.connectivity_verfication_result.report_id)
						link = ['/#', 'admin-panel', 'connectivity-verification-log', params.data.connectivity_verfication_result?.report_id];

					const label = params.colDef.cellRendererParams.getStatus(params.data, 'connectivity_verfication_result');

					params['label'] = params.colDef.cellRendererParams.status[label];
					params['link'] = link.length ? link.join('/') : null;
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: {customerId: customerId, siteId: siteId, status: this.status, getStatus: this.getStatus},
			},
			{ headerName: "devices.shipment_quality_checked", field: "shipment_quality_result_formatted", hide: false, width: 220,
				cellRendererSelector: function (params) {
					let link = [];

					if (params.data.shipment_quality_result && params.data.shipment_quality_result.report_id)
						link = ['/#', 'management', 'shipment-quality-history', params.data.shipment_quality_result?.report_id];

					const label = params.colDef.cellRendererParams.getStatus(params.data, 'shipment_quality_result');

					params['label'] = params.colDef.cellRendererParams.status[label];
					params['link'] = link.length ? link.join('/') : null;
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				cellRendererParams: {customerId: customerId, siteId: siteId, status: this.status, getStatus: this.getStatus},
			});

		let filteredCols = [];
		if (this.chargeLinkDevices.length > 0) {
			devicesGridCols.forEach((col) => {
				if ((col.field in this.devices[0])) {
					filteredCols.push(col);
				}
			});
		}

		this.chargLinkDevicesGridCols = filteredCols;
	}

	ngOnDestroy() {
		this.permissionsSub.unsubscribe();
	}

	formatDeviceFields(devices, permission) {
		devices.forEach((device, idx) => {
			device = this.common.decompress(device, 'siteDevice');
			devices[idx] = device;
			if (this.usersService.hasAccessPermission({ permission }, 'number_of_cells')) {
				let deviceVPC = (device.vpc !== undefined) ? device.vpc : ((device.battery_type == this.deviceService.batteryTypesByName.lithuim_ion) ? 3 : 2);
				const voltage = (device.number_of_cells * deviceVPC);
				device._voltage = voltage;
			}
			if (this.usersService.hasAccessPermission({ permission }, 'installation_date'))
				device._installation_date_ms = device.installation_date * 1000;

			device.fw_version = this.common.toFixedWithoutRounding(device.fw_version, this.usersService.getCurrentUser().is_admin ? 4 : 2);
			device.last_connect_time_formatted = moment(device.last_connect_time * 1000).format('MM/DD/YYYY HH:mm:ss A');
			device.connectivity_verfication_status_formatted = this.translateService.instant(`g.${this.getStatus(device, 'connectivity_verfication_result')}`);
			device.shipment_quality_result_formatted = this.translateService.instant(`g.${this.getStatus(device, 'shipment_quality_result')}`);
		});
		return devices;
	}

	getStatus (device, field) {
		const shipmentResults = {
			notTested: 0,
			passed: 1,
			failed: 2
		}

		let res = 'not_tested';
		if (device[field] && device[field].report_id){
			if (device[field]?.result == shipmentResults.passed)
				res = `passed`;

			if (device[field]?.result == shipmentResults.failed)
				res = `failed`;
		}

		return res;
	}

	setInfoBoxSiteAnalyticsData() {
		this.infoBoxSiteAnalytics = this.infoBox[this.selectedDataOption];
	}

	showOnlyConnectedIPs(flag) {
		let autoconnectFreq = 1;
		let ips = [];
		let data = this.devices;

		if (data.length == 0) {
			this.uniqueIPaddresses = [];
			return;
		}

		if (flag) {
			let frequency = 900;
			let nowDate = Math.round(new Date().getTime() / 1000);
			data.forEach(device => {
				let disconnectTime = nowDate - device.last_connect_time; //disconnectTime in seconds
				if (device.ip_address && disconnectTime <= (2.5 * frequency)) {
					ips.push(device.ip_address);
				}
			});

		} else {
			data.forEach(device => {
				if (device.ip_address) {
					ips.push(device.ip_address);
				}
			});
		}

		ips = ips.filter(function (elem, pos) {
			return ips.indexOf(elem) == pos;
		});
		this.uniqueIPaddresses = ips;
	}

	prepareQuarterReport(data, is_charglink) {
		const qKey = is_charglink ? 'chargLink' : 'iotah';
		const qData = data[qKey];
		const periods = _.sortBy(Object.keys(qData)).reverse();

		if (periods.length) {
			if (is_charglink) {
				this.chargLinkQuarterData = qData;
				this.chargLinkSelectedYear = periods[0];
				this.chargLinkSelectedQuarter = Object.keys(qData[this.chargLinkSelectedYear])[0];
				this.chargLinkQuartersOption = this.getListQuarters(this.chargLinkSelectedYear, true);
			} else {
				this.monthData = qData;
				this.selectedYearQ = periods[0];
				this.selectedMonth = +Object.keys(qData[this.selectedYearQ])[0];
				this.monthsOption = this.getListMonths(this.selectedYearQ);
			}
			this.loadQuarterData(is_charglink);
		}
	}

	loadQuarterData(is_charglink: boolean) {
		if (is_charglink) {
			this.chargLinkSelectedYearUsageReport = this.chargLinkSelectedYear;
			this.charglinkSelectedQuarterData = this.chargLinkQuarterData[this.chargLinkSelectedYear] ? this.chargLinkQuarterData[this.chargLinkSelectedYear][this.chargLinkSelectedQuarter] ?? [] : [];
		} else {
			this.selectedYearUsageReport = this.selectedYearQ;
			//this.selectedQuarter--;
			this.selectedMonthData = this.monthData[this.selectedYearQ] ? this.monthData[this.selectedYearQ][this.selectedMonth] ?? [] : [];
			if (this.selectedTruckYear == 'new') {
				this.selectedMonthData = _.filter(this.selectedMonthData, (device) => { return this.deviceService.isNewDevice(device.truck_manufacturing_year); });
			} else if (this.selectedTruckYear == 'old') {
				this.selectedMonthData = _.filter(this.selectedMonthData, (device) => { return !this.deviceService.isNewDevice(device.truck_manufacturing_year); });
			}
		}
	}

	prepareTags(tagsData) {
		this.tags = [];
		Object.values(tagsData.siteTags).forEach((tag) => {
			this.tags.push(this.common.decompress(tag, 'tag'));
		});
		this.deviceTags = tagsData.deviceTags;
	}

	exportMonthlyData(allMonths?: boolean) {
		let name = 'Monthly';
		let data = this.monthData || [];
		if (!allMonths) {
			data = { [this.selectedMonth]: this.selectedMonthData };
			name = moment(this.selectedMonth, 'MM').format('MMMM');
		}

		if (allMonths && data) {
			data = data[this.selectedYearQ];
			delete data['All-Year'];
		}
		this.common.exportMonthsData(this.quarterlyReportCols, data, `${name}_${this.siteInfo.name}_report`, false);
	}

	exportChargeLinkQuarterData(allQuraters?: boolean) {
		let name = 'quarterly';
		let data = this.chargLinkQuarterData;
		if (!allQuraters) {
			data = { [this.chargLinkSelectedQuarter]: this.charglinkSelectedQuarterData };
			name = this.chargLinkSelectedQuarter;
		}

		if (allQuraters) {
			const filteredData = {};
			for (const key1 in data) {
				if (data.hasOwnProperty(key1)) {
					for (const key2 in data[key1]) {
						const filteredArray = data[key1][key2];
						if (filteredArray.length > 0) {
							filteredData[`${key1}_${key2}`] = filteredArray;
						}
					}
				}
			}
			data = filteredData;
		}

		this.common.exportQuartersData(this.chargLinkQuarterlyReportCols, data, `${name}_${this.siteInfo.name}_report`);
	}

	private filterDevicesQuarterData(data) {
		const chargLinkData = { quarterData: {} };
		const IoTAhData = { quarterData: {} };
		const quarters = Object.keys(data.quarterData);

		quarters.forEach((quarter) => {
			const quarterDataArray = data.quarterData[quarter];

			quarterDataArray.forEach((qData) => {
				const device = this.common.decompress(qData.device, 'siteDevice');
				if (device.is_charglink == true) {
					if (!chargLinkData.quarterData[quarter])
						chargLinkData.quarterData[quarter] = [];
					chargLinkData.quarterData[quarter].push(qData);
				} else {
					if (!IoTAhData.quarterData[quarter])
						IoTAhData.quarterData[quarter] = [];
					IoTAhData.quarterData[quarter].push(qData);
				}
			});
		})
		return { IoTAhData, chargLinkData }
	}

	filterDevices() {
		this.chargeLinkDevices = [];
		this.IoTAhDevices = [];

		this.devices.forEach(device => {
			if (device.is_charglink == true)
				this.chargeLinkDevices.push(device);
			else
				this.IoTAhDevices.push(device);
		})
	}

	formatQuarterReportData(period) {
		const device = this.common.decompress(period.device, 'siteDevice');
		const qData = this.common.decompress(period.data, 'devicePerformance');
		if (device.last_pm_date && device.last_pm_date > 0)
			this.deviceService.formatDeviceFields(device);

		let quarterObj: any = {}
		quarterObj.mac_address = device.mac_address;
		quarterObj.truck_type = device.truck_type;
		quarterObj.truck_sn = device.truck_sn;
		quarterObj.truck_id = device.truck_id;
		quarterObj.truck_manufacturing_year = device.truck_manufacturing_year;

		quarterObj.total_hours = Math.round(qData.accurate_inuse_time / 3600);
		quarterObj.total_ahr = (qData.total_inuse_as_from_all_events / 3600);
		quarterObj.total_kwhr = (qData.total_inuse_ws_from_all_events / 1000 / 3600);

		quarterObj.truck_year = this.translateService.instant(this.deviceService.isNewDevice(device.truck_manufacturing_year) ? 'g.new' : 'g.old');
		quarterObj.truck_manufacturer = device.truck_manufacturer;
		quarterObj.last_pm_date = device.last_pm_date_fmt || 'N/A';
		quarterObj.billed_kwhr = this.deviceService.calculateBilledKWHr(quarterObj.total_kwhr, device);

		return quarterObj;
	}
}
