<div>
	<h1 class="h2 mb-4">{{'nav.iccid_changes_report' | translate}}</h1>
	<table class="table table-striped" *ngIf="devices.length > 0; else noData">
		<thead>
			<tr>
				<th>{{'devices.unique_mac_address' | translate}}</th>
				<th>{{'g.site_name' | translate}}</th>
				<th>{{'g.customer_name' | translate}}</th>
				<th>{{'devices.oem_iccid' | translate}}</th>
				<th>{{'devices.current_iccid' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let device of devices">
				<td>
					<a [routerLink]="['/', customers[sites[device.site_id]?.customer_id]?.id, sites[device.site_id]?.id, device.mac_address, 'performance']">
						{{device.mac_address}}
					</a>
				</td>
				<td>
					<a [routerLink]="['/', customers[sites[device.site_id]?.customer_id]?.id, sites[device.site_id]?.id]">
						{{sites[device.site_id]?.name}}
					</a>
				</td>
				<td>
					<a [routerLink]="['/', customers[sites[device.site_id]?.customer_id]?.id]">
						{{customers[sites[device.site_id]?.customer_id]?.name}}
					</a>
				</td>
				<td>{{device.oem_cellular_iccid}}</td>
				<td>{{device.cellular_iccid}}</td>
			</tr>
			</tbody>
	</table>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>
