<div class="tm-3">
	<sct-form
		[data]="device.config_info"
		[name]="'warning_setting'"
		[permissions]="enterprisePermissions.permission"
		[fields]="deviceSettingsFormFields"
		[changesStack]="{}"
		[showSubmitButton]="usersService.hasAccessPermission(enterprisePermissions, 'noc', 'write')"
		[highlightEmpty]="false"
		(formSubmitted)="checkChanges($event)"
	></sct-form>
</div>

<app-modal #saveOptionsModal [title]="'settings.save_device_settings' | translate" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		{{'settings.apply_changes' | translate}}
	</div>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="saveDeviceSettings('cancel')">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="saveDeviceSettings('direct')">{{'g.ok' | translate}}</button>
	</div>
</app-modal>
