<div class="row mt-4">
	<div class="row col-md-12">
		<div class="col-4">
			<button class="btn btn-success" (click)="getContactUs()">{{'g.refresh' | translate}}</button>
			<button class="btn btn-danger ml-1" (click)="confirmDelete('deleteLogs')" [disabled]="!selectedIds || selectedIds.length === 0" *ngIf="usersService.hasAccessFunction('admin_contact_us', 'write')">{{'error_log.delete_logs' | translate}}</button>
		</div>
	</div>

</div>
<div class="my-4">
	<app-table #dataTable name="contact-us" [elements]="data" [columnNames]="columnNames" [showExport]="false" [hasCheckboxColumn]="true" (actionEvent)="doAction($event)" (selectionChanged)="selectedIds = $event" [pageSizeOptions]="[20, 50, 100]"></app-table>
</div>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>