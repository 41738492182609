import { AfterViewInit, Component, ElementRef, OnInit, ViewChild  } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { saveAs } from 'file-saver';
import { AttachmentsService } from 'src/app/shared/attachments/attachments.service';
import { Subscription, of, BehaviorSubject, concatMap } from 'rxjs';
import { cloneDeep, keyBy } from 'lodash';
import * as moment from 'moment';
import { OrdersService } from '../orders/orders.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ReceivingShipmentService } from '../receiving-shipment/receiving-shipment.service';

@Component({
	selector: 'app-shipment-history',
	templateUrl: './shipment-history.component.html',
	styleUrls: ['./shipment-history.component.css']
})
export class ShipmentHistoryComponent implements OnInit, AfterViewInit {
	@ViewChild("isShipmentHistoryDialog") isShipmentHistoryDialog: any;
	@ViewChild('downloadFilesDialog') downloadFilesDialog: ModalComponent;
	@ViewChild("addAttachmentsDialog") addAttachmentsDialog: ModalComponent;
	@ViewChild("successAttachmentsDialog") successAttachmentsDialog: ModalComponent;
	@ViewChild('uploadAttachmentsBtn') uploadAttachmentsBtn: HTMLInputElement;
	@ViewChild("deleteConfirmModal") deleteConfirmModal: ModalComponent;
	@ViewChild("exportReceivedShipmentData") exportReceivedShipmentData;

	data: any;
	selectedDevices: any[] = [];
	poNumber: number;
	recordsLimit = 20;
	noResult: boolean = false;
	currentPage: number = 1;
	dataToShow: any[] = [];
	fromDate: Date = new Date();
	toDate: Date = new Date();
	notValidPopupDate = false;

	dateTimeFields = ['shipping_date'];
	clickableFields = ['devices', 'attachments'];
	allColumns: { [key: string]: { width: string } } = {
		'purchase_order': {width: '150px'},
		'shipment_tracking_number': {width: '150px'},
		'shipping_date': {width: '150px'},
		'customer': {width: '200px'},
		'site': {width: '200px'},
		'customer_id': {width: '0'},
		'site_id': {width: '0'},
		'devices': {width: '100px'},
		'user_name': {width: '200px'},
		'attachments': {width: '150px'}
	};

	allColumnsKeys: string[] = [];
	dataTable: any[] = [];
	columnDefs: any[] = [];
	hiddenFields = ['site_id', 'customer_id'];

	selectedFiles: any = [];
	selectedFilesValidations: any = {};
	successAttachments: any = {};
	selectedHistory: any;

	orderAttachedFiles: any[] = [];
	selectedFilesToDownload: any = {};
	noSelectedAttachmentsFilesErrMsg = '';
	deleteAttachmentsSub!: Subscription;
	filesAlreadyUploaded: any = {};

	successCounter = 0;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private deviceManagement: DeviceManagementService,
		private ordersService: OrdersService,
		private translateService: TranslateService,
		public attachmentsService: AttachmentsService,
		private notificationMessage: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private receivingShipmentService: ReceivingShipmentService
	) { }

	ngOnInit(): void {
		if (!this.usersService.hasAccessFunction('shipment_management'))
			this.router.navigate(['unauthorized']);

		this.getShipmentsHistory(false, true);

		this.buildGrid();
		this.sideMenuService.hide();
	}

	ngAfterViewInit() {
		this.downloadFilesDialog.onClose.subscribe((result) => {
			if(!result)
				return this.selectedFilesToDownload = {};
			this.downloadFilesDialog.hide();
			this.downloadFiles(Object.keys(this.selectedFilesToDownload), { id: this.selectedHistory.id, po_number: this.selectedHistory.purchase_order });
			return this.selectedFilesToDownload = {};
		});

		this.addAttachmentsDialog.onClose.subscribe((result) => {
			this.selectedFiles = [];
			this.addAttachmentsDialog.hide();
			this.uploadAttachmentsBtn.files = null;
		});

		this.addAttachmentsDialog.onClose.subscribe((result) => {
			this.selectedFiles = [];
			this.addAttachmentsDialog.hide();
			this.uploadAttachmentsBtn.files = null;
		});

		this.deleteConfirmModal.onClose.subscribe((result) => {
			if(!result)
				return;
			return this.deleteAttachmentFiles(this.selectedHistory.id, Object.keys(this.selectedFilesToDownload));
		});
	}
	
	handleUploadedFiles() {
		const newAddedFiles = this.selectedFiles.filter(file => !(file.name in this.filesAlreadyUploaded)).map(file => file.name);

		if (this.selectedFiles.length && this.successCounter == this.selectedFiles.length) {
			this.selectedFiles = [];
			this.successAttachments = {};
			this.successAttachmentsDialog.hide();
		}

		if (this.successCounter && newAddedFiles.length == this.successCounter) {
			this.ordersService.sentAttachmentEmail(this.selectedHistory.order_id, this.selectedHistory.purchase_order, newAddedFiles, 'shipping', false).subscribe();
			this.getShipmentsHistory(false, true);
		}
	}

	getShipmentsHistory(isBack = false, firstTime = false) {
		this.deviceManagement.getShipmentsHistory().subscribe((res: any) => {
			this.data = res;
			this.data.map((order: any) => order.shipping_date = order.shipping_date ? moment.unix(order.shipping_date).utc().format('MM/DD/YYYY') : '-');
			const tableData: any[] = [];
			this.data.forEach((row: any) => {
				const rowObj = {
					purchase_order: this.capitalizeFirstLetter(row.purchase_order),
					shipment_tracking_number: this.capitalizeFirstLetter(row.shipment_tracking_number),
					shipping_date: row.shipping_date != '-' ? row.shipping_date : null,
					customer: this.capitalizeFirstLetter(row.customer_name),
					customer_id: row.customer_id,
					site: this.capitalizeFirstLetter(row.site_name),
					site_id: row.site_id,
					devices:  {
						onClick: () => this.showShipmentsHistoryDevices(row.devices),
						type: 'icon',
						icon: 'eye',
						color: '#007bff'
					},
					user_name: this.capitalizeFirstLetter(row.user_name),
					attachments: {
						onClick: () => row.attached_files?.length ? this.openDownloadDialog(row) : this.openAddDialog(row),
						type: 'icon',
						icon: row.attached_files?.length ? 'paperclip' : 'plus',
						color: row.attached_files?.length ? '#007bff' : '#28a745',
					}
				}
				tableData.push(rowObj);
			})

			this.dataTable = tableData;
		});
	}

	capitalizeFirstLetter(string) {
		if (!string)
			return string;

		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	buildGrid() {
		const gridColumns = [];

		for (let field of Object.keys(this.allColumns)) {
			let colDef: any = {};

			if ("purchase_order".includes(field))
				colDef = { isString: true };

			if (this.dateTimeFields.includes(field))
				colDef = { type: 'dateTime', floatingFilterComponentParams: { suppressFilterButton: true } };

			if (this.clickableFields.includes(field))
				colDef = {type: 'clickable'}


			if(field == 'site')
				colDef = {
				cellRendererSelector:function (params) {
					params['label'] = params.data.site ? params.data.site :'-';
					if(params.data.customer_id && params.data.site_id)
						params['link'] = ["/#", params.data.customer_id, params.data.site_id].join('/');

					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				params: {},
			}

			if(field == 'customer')
				colDef = {
				cellRendererSelector:function (params) {
					params['label'] = params.data.customer ? params.data.customer :'-';
					if(params.data.customer_id)
						params['link'] = ["/#", params.data.customer_id].join('/');

					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				params: {},
			}

			let headerName = field;
			Object.assign(colDef, {
				headerName: this.translateService.instant('shipments_history.' + headerName),
				field: field,
				colId: field,
				width: this.allColumns[field].width
			});

			if (!this.hiddenFields.includes(field))
				gridColumns.push(colDef);
		}

		this.columnDefs = gridColumns;
	}

	showShipmentsHistoryDevices(devices) {
		this.selectedDevices = devices;
		this.isShipmentHistoryDialog.show();
	}

	openDownloadDialog(order: any) {
		this.selectedHistory = order;
		this.orderAttachedFiles = order.attached_files;
		this.downloadFilesDialog.title = order.purchase_order + ' ' + this.translateService.instant('ready_to_ship.attachments');
		this.downloadFilesDialog.show();
		this.selectedFilesToDownload = {};
		this.selectedFiles = [];
		this.successCounter = 0;
		this.noSelectedAttachmentsFilesErrMsg = '';
	}

	openAddDialog(order: any) {
		this.addAttachmentsDialog.title = order.purchase_order + ' ' + this.translateService.instant('ready_to_ship.attachments');
		this.selectedFiles = [];
		this.successAttachments = {};
		this.selectedFilesToDownload = {};
		this.selectedHistory = order;
		this.addAttachmentsDialog.show();
		this.successCounter = 0;
		this.noSelectedAttachmentsFilesErrMsg = '';
	}

	selectFile(event: any, file: any) {
		const isChecked = event.target.checked;
		if (isChecked) {
			if (this.noSelectedAttachmentsFilesErrMsg)
				this.noSelectedAttachmentsFilesErrMsg = '';

			return this.selectedFilesToDownload[file] = isChecked;
		}
		delete this.selectedFilesToDownload[file];
	}

	getDownloadButtonStatus() {
		return !Object.keys(this.selectedFilesToDownload).length;
	}

	downloadFiles(files: string[], orderInfo: { id: number, po_number: string }) {
		this.deviceManagement.downloadAttachment(files, orderInfo).subscribe((res: any) => {
			if (!res)
				return;
			const arr = new Uint8Array(res.content.data);
			const blob = new Blob([arr]);
			if (blob)
				saveAs(blob, res.name);
		});
	}

	addAttachments() {
		if (!this.selectedFiles.length)
			return this.selectedFilesValidations = { ...this.selectedFilesValidations, min_count: true };

		this.selectedFilesValidations = this.attachmentsService.validate({ files: this.selectedFiles, note: '' });
		if (Object.keys(this.selectedFilesValidations).length)
			return;

		this.filesAlreadyUploaded = {};
		this.addAttachmentsDialog.hide();
		this.successAttachmentsDialog.show();
		
		let counter = 0;
		for (const file of this.selectedFiles) {
			let formData = new FormData();

			formData.append("file", file);
			formData.append('id', this.selectedHistory.id.toString());

			this.deviceManagement.addAttachmentToShipmentHistory(formData).subscribe((attachment: any) => {
				switch (attachment.api_status) {
					case 2:
						this.notificationMessage.setMessage('globalErrMsg', { clearOnXTimeNavigate: 1 });
						break;
					case 3:
						this.filesAlreadyUploaded = cloneDeep({...this.filesAlreadyUploaded, [file.name]: true});
						break;
					default:
						this.successAttachments = cloneDeep({ ...this.successAttachments, [attachment.file_name]: true });
						this.successCounter++;
						break;
				}

				counter++;
				if (counter == this.selectedFiles.length)
					this.handleUploadedFiles();
			});
		}
	}

	selectFiles(events: any) {
		this.selectedFiles = Object.values(events.target.files);
	}

	showValidationErrors() {
		return Object.keys(this.selectedFilesValidations).length;
	}

	confirmDownloadAttachedFiles() {
		if (!Object.values(this.selectedFilesToDownload).length)
			return this.noSelectedAttachmentsFilesErrMsg = this.translateService.instant('ready_to_ship.no_selected_files');

		this.downloadFilesDialog.onConfirm();
	}

	confirmDeleteAttachedFiles() {
		if (!Object.values(this.selectedFilesToDownload).length)
			return this.noSelectedAttachmentsFilesErrMsg = this.translateService.instant('ready_to_ship.no_selected_files');

		this.deleteConfirmModal.message = 'attachments.delete_attachments';
		this.deleteConfirmModal.show();
	}

	deleteAttachmentFiles(id: number, files: string[]) {
		this.deleteAttachmentsSub = this.deviceManagement.deleteAttachments(id, files).subscribe((res: any) => {
			if (!res)
				return this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});

			const index = this.data.map((order: any) => order.id).indexOf(id);
			const attachments = this.data[index].attached_files.filter((file: string) => !files.includes(file));
			this.data[index] = { ...this.data[index], attached_files: attachments };

			this.getShipmentsHistory(false, true);
			return this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});

		this.downloadFilesDialog.hide();
	}

	generateShipmentHistoryReport() {
		let zoneDiff = new Date().getTimezoneOffset() * -1;
		const dateRange = {
			from: moment(this.fromDate).utc().startOf('day').unix() + (zoneDiff*60), 
			to: moment(this.toDate).utc().endOf('day').unix()
		};

		if (this.fromDate && dateRange.from > dateRange.to)
			return this.notValidPopupDate = true;
		this.notValidPopupDate = false;

		const reportData = this.data.filter(record => {
			const shippingDate = moment(record.shipping_date).utc().unix();
			return shippingDate >= dateRange.from && shippingDate <= dateRange.to
		});

		const sortedData = reportData.sort((a, b) => moment(b.shipping_date).utc().unix() - moment(a.shipping_date).utc().unix());
		this.exportReceivedShipmentData.hide();
		if (!sortedData.length)
			return this.notificationMessage.setMessage('translate|g.date_range_has_no_records', { type: 'warning' });
		return this.receivingShipmentService.generateHistoryCsvFile(sortedData, this.translateService.instant('shipment.shipment_history'), 'shipment_history');
	}

	ngOnDestroy() {
		if (this.deleteAttachmentsSub)
			this.deleteAttachmentsSub.unsubscribe();
	}

}
