import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { AdminService } from 'src/app/admin/admin.service';

@Component({
	selector: 'app-user-audit',
	templateUrl: './user-audit.component.html',
	styleUrls: ['./user-audit.component.css']
})
export class UserAuditComponent implements OnInit {

	data: any;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private adminService: AdminService
	) { }

	ngOnInit() {
		let currentUser = this.usersService.getCurrentUser();
		if(!currentUser.is_admin)
			return this.router.navigate(['/unauthorized']);
		this.getUsersForAudit();
	}
	
	getUsersForAudit() {
		this.adminService.getUsersForAudit().subscribe((res)=>{
			this.data = res;
		});
	}
}