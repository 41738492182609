<div class="row col-12 justify-content-md-center">
	<div class="panel panel-default">
		<div class="panel-body">
			<div class="panel-body">
				<table class="table table-striped">
					<tbody>
						<tr>
							<th><span class="green-text">Function Name</span></th>
							<th><span class="green-text">Server</span></th>
							<th><span class="green-text">Function Status</span></th>
							<th><span class="green-text">Last Call Time</span></th>
							<th><span class="green-text">Next Call Time</span></th>
							<th><span class="green-text">Frequency</span></th>
							<th><span class="green-text">Frequency In Seconds</span></th>
							<th><span class="green-text">Schedule Time</span></th>
							<th><span class="green-text">Email Sent Time</span></th>
						</tr>
						<tr *ngFor="let function of schedFunctions">
							<td>{{function.name}}</td>
							<td>{{function.server}}</td>
							<td>{{function.runningStatus}}</td>
							<td>{{!function.lastCallTime ? '-' : (function.lastCallTime * 1000 | date:'MM/dd/yyyy hh:mm:ss a')}}</td>
							<td>{{function.nextCallTime * 1000 | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
							<td>{{function.frequency}}</td>
							<td>{{function.frequencyInSec}}</td>
							<td>{{function.time}}</td>
							<td>{{!function.emailSentTime ? '-' : (function.emailSentTime * 1000 | date:'MM/dd/yyyy hh:mm:ss a')}}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</div>