<form (ngSubmit)="updatePermission()" class="my-5">
	<div class="col-md-6 offset-md-3"  *ngIf="permissionObj">
		<h2 class="text-center">{{'g.edit_permissions' | translate}}</h2>
		<div>
			<div *ngFor="let key of permissionMainKeys" class="mt-4">
				<app-simple-widget [title]="'p.titles.'+key | translate" [isCollapsed]="false">
					<h6 class="text-warning mb-4" *ngIf="modifiedPermissions.length">* {{'permissions.modified_permissions_message' | translate}}</h6>
					<div *ngFor="let fieldKey of getKeys(permissions[key])" class="row mt-2">
						<div class="col-md-6" [ngClass]="{'text-warning': modifiedPermissions.includes(permissions[key][fieldKey])}">
							{{'p.'+key+'.'+permissions[key][fieldKey] | translate}}
						</div>
						<div class="col-md-6">
							<select class="form-control" name="access-{{permissions[key][fieldKey]}}" [(ngModel)]="permissionObj[permissions[key][fieldKey]]">
								<option *ngFor="let opt of readWriteOptions" [value]="opt.v">{{opt.text}}</option>
							</select>
						</div>
					</div>
				</app-simple-widget>
			</div>
		</div>
		<div class="mt-3">
			<button class="btn btn-info mr-3">{{'g.save' | translate}}</button>
			<button class="btn btn-default" type="button" (click)="cancel()">{{'g.cancel' | translate}}</button>
		</div>
	</div>
</form>
