
<div class="card" *ngIf="!fileName">
	<div class="card-header">
		<h4>Choose Log File</h4>
		<form #f="ngForm" class="form-inline" (ngSubmit)="setFileName(f)">
			<input class="form-control mr-2" type="text" ngModel name="fName" required />
			<button class="btn btn-success" type="submit">Submit</button>
		</form>
	</div>
</div>
<div class="card" *ngIf="fileName">
	<div class="card-header">
		<h4>Debug Logs ({{ fileName }})</h4>
		<button class="btn btn-success download-button" (click)="downloadLogFile()">Download Log File</button>
	</div>
	<div #cb class="card-body cb">
		<pre class="mh">{{ lines }}</pre>
	</div>
</div>