import { Component, Input, OnChanges, OnInit } from '@angular/core';

@Component({
	selector: 'password-strength',
	templateUrl: './password-strength.component.html',
	styleUrls: ['./password-strength.component.css']
})
export class PasswordStrengthComponent implements OnInit, OnChanges {

	@Input() password: string;
	strength: number = 0;

	readonly strengthColorMap: string[] = [
		'',
		'#000000', //'#D10000',
		'#000000', //'#FFA500',
		'#000000', //'#FFFF2E',
		'#000000', //'#8AFF8A',
		'#000000', //'#00A300',
	];

	constructor() { }

	ngOnInit(): void {
	}

	ngOnChanges() {
		this.strength = this.checkStrength(this.password);
	}

	/**
	 * Check password strength
	 * 	strength = 1 --> less than 8 characters
	 * 	strength = 2 when password is 8 chars length at least
	 * 	strength = 3 when password is 8 chars length at least, and contains 2 of the following (number, special character, upper case, lower case)
	 * 	strength = 4 when password is 8 chars length at least, and contains 3 of the following (number, special character, upper case, lower case)
	 * 	strength = 5 when password is 8 chars length at least, and contains all of the following (number, special character, upper case, lower case)
	 * @param text 
	 */
	checkStrength(text: string) {
		if(!text)
			return 0;
		let strength = 1,
			strongChars = -1;
		if(text.length >= 8) {
			strongChars +=	+/\d/.test(text) +				// Digit
							+/[^a-zA-Z0-9]/.test(text) +	// Special character
							+/[a-z]/.test(text) +			// Lower case
							+/[A-Z]/.test(text);			// Upper case
			strongChars = strongChars < 0 ? 0 : strongChars;
			strength = 2 + strongChars;
		}
		return strength;
	}
}
