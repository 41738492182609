declare var require: any
import { TranslateLoader } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { catchError } from 'rxjs/operators';
import { CommonDataService } from "./shared/services/common-data.service";

export class TranslateFileLoader implements TranslateLoader {
    _currentLanguage: string;
    _currentData: any;
    constructor(private commonData: CommonDataService) {}

    /**
     * Gets the translations from JSON file
     * @param lang
     * @returns {any}
     */
    public getTranslation(lang: string): Observable<any> {
        return Observable.create(observer => {
            if(this._currentLanguage == lang)
            {
                observer.next(this._currentData);
                observer.complete();
                return observer;
            }
            let isChargeLink = this.commonData.chargLinkDomains.includes(window.location.hostname);
            let data =  require('../../../Common/translation/'+lang+'.json');
            if (isChargeLink){
							let chargeLinkData = data.chargeLink;
							data = this.margeTranslationData(data, chargeLinkData);
						}
						this._currentData = data;
						this._currentLanguage = lang;
						observer.next(this._currentData);
						observer.complete();
				});
		}

    private margeTranslationData ( firstObj, secondObj ) {
      if (typeof firstObj !== "object" || typeof secondObj !== "object" || firstObj === null || secondObj === null)
        return secondObj;
      const merged = { ...firstObj };

      for (const key in secondObj) {
        if (secondObj.hasOwnProperty(key))
          merged[key] = this.margeTranslationData(firstObj[key], secondObj[key]);
      }

      return merged;
    }
}
