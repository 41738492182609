<h2 class="mb-4" >{{'connectivity.disconnected_sites_Report' | translate}}</h2>
<div class="form d-flex justify-content-start align-items-end mb-2">
	<div class="input mr-3 input-field">
		<label for="percentage">{{'connectivity.percentage' | translate}}</label>
		<input class="form-control" type="number" name="percentage" id="percentage" max="100" min="0" value="0" [(ngModel)]="percentage" (ngModelChange)="checkValidation()">
		<p class="invalid-tooltip" *ngIf="!isValidPercent">{{'g.invalid_fields' | translate}}</p>
	</div>
	<div class="input mr-3 input-field">
		<label for="percentage">{{'connectivity.days_of_disconnect' | translate}}</label>
		<input class="form-control" type="number" name="days_count" id="days_count" min="0" value="0" [(ngModel)]="daysCount" (ngModelChange)="checkValidation()">
		<p class="invalid-tooltip" *ngIf="!isValidDaysCount">{{'g.invalid_fields' | translate}}</p>
	</div>
	<div class="input mr-3 input-field">
		<label for="stateName">{{'site.search_state_site'| translate}}</label>
		<ng-select
			[ngStyle]="{'width': '250px'}"
			[items]="stateFilterOptions"
			bindLabel="label"
			bindValue="value"
			[(ngModel)]="selectedState"
			placeholder="{{'site_edit.state' | translate }}"
			name="state"
			[multiple]="true">
		</ng-select>
	</div>
	<div class="input mr-3 input-field">
		<div class="mb-3">
			<label for="isSmartRebatesSites">{{ "site.is_smart_rebates_sites_filter" | translate }}</label>
		</div>
		<div class="d-flex justify-content-center mb-2">
			<input class="checkBoxsmartebates" type="checkbox" [(ngModel)]="isSmartRebatesSitesFilter" name="isSmartRebatesSites">
		</div>
	</div>
	<button class="btn btn-primary run ml-4"  type="button" (click)="getDisconnectedSiteData()" [disabled]="!isValidDaysCount || !isValidPercent">{{"g.run" | translate}}</button>
</div>

<div class="d-flex justify-content-end mt-4 ml-2" *ngIf="sitesData.length">
	<div class="mr-2">
		<fa-icon class="pointer text-dark" [icon]="['far', 'file-pdf']" [size]="['2x']" [title]="'g.export_to_pdf' | translate" (click)="exportToPDF()">{{'g.export_to_pdf' | translate}}</fa-icon>
	</div>
	<div>
		<fa-icon class="pointer text-dark" [icon]="['far', 'file-excel']" [size]="['2x']" [title]="'g.export_to_csv' | translate" (click)="exportToCsvFile()">{{'g.export_to_csv' | translate}}</fa-icon>
	</div>
</div>

<div *ngIf="sitesData.length; else noData">
	<sct-custom-table
		[columnsConfig]="columnConfig"
		[data]="sitesData"
		[config]="tableConfig"
		(sortedDataToShow)="getTableSortedData($event)"
	></sct-custom-table>
</div>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal [hasConfirmButton]="false" [hasCloseButton]="false" #popupModal title="{{'connectivity.disconnected_devices' | translate}}" size="lg">
	<ul>
		<li *ngFor="let row of selectedDisconnectedDevices">
			<a [routerLink]="['/',row.customer_id, row.site_id, row.mac_address,'performance']">{{row.serial_number}}</a>
		</li>
	</ul>
</app-modal>
