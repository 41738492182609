import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { UsersService } from 'src/app/users/users.service';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { ReceivingShipmentService } from '../receiving-shipment/receiving-shipment.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { ValidationService } from 'src/app/shared/services/validation.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';

@Component({
	selector: 'app-receiving-shipment-history',
	templateUrl: './receiving-shipment-history.component.html',
	styleUrls: ['./receiving-shipment-history.component.css']
})
export class ReceivingShipmentHistoryComponent implements OnInit {
	@ViewChild("editCmPoNumberPopup") editCmPoNumberPopup: ModalComponent;
	@ViewChild("exportReceivedShipmentData") exportReceivedShipmentData;

	reportsHistory: any = [];
	source: number = this.deviceManagementService.shipmentSource.receive;
	fromDate: Date = new Date();
	toDate: Date = new Date();
	notValidPopupDate = false;
	deviceId: string = '';
	searchBy: string = 'serialNumber';
	invalidDevice: boolean = false;
	searchApplied: boolean = false;
	filteredData: any = [];
	reportHistoryData: any = [];

	// CmPoNumber
	newCmPoNumberValue = '';
	toEditReport = null;

	constructor(
		private router: Router,
		public userService: UsersService,
		public deviceManagementService: DeviceManagementService,
		private receivingShipmentService: ReceivingShipmentService,
		private translateService: TranslateService,
		private commonService: CommonService,
		private validationService: ValidationService,
		private notificationService: NotificationMessageService,
	) { }

	ngOnInit(): void {
		if(!this.userService.hasAccessFunction('shipment_management') && !this.userService.hasAccessFunction('cm_check_warnings'))
			this.router.navigate(['unauthorized']);

		if(this.router.url.includes('shipment-quality-history'))
			this.source = this.deviceManagementService.shipmentSource.quality;

		this.getReceiveShipmentReports();
	}

	ngAfterViewInit() {
		this.editCmPoNumberPopup.onClose.subscribe((ok) => {
			if(ok) {
				this.editCmPoNumber()
				return;
			}

			this.toggleEditCmPoNumberPopup(false, null);
		});
	}

	editCmPoNumber() {
		this.deviceManagementService.updateCmPoNumber(this.toEditReport.id, this.newCmPoNumberValue).subscribe(res => {
			for (const report of this.reportsHistory) {
				if (report.id == this.toEditReport.id)
					report.cm_po_number = this.newCmPoNumberValue;
			}
			this.notificationService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		})
	}

	getReceiveShipmentReports() {
		this.deviceManagementService.getReceiveShipmentHistory(this.source).subscribe((reports: any) => {
			this.reportsHistory = reports;
			this.reportHistoryData = [...reports];
		})
	}

	generateReceivedShipmentHistoryReport() {
		const zoneDiff = new Date().getTimezoneOffset() * -1;
		const from: any = new Date(new Date(this.fromDate).getTime() + (zoneDiff * 60 * 1000));
		const to: any = new Date(new Date(this.toDate).getTime() + (zoneDiff * 60 * 1000));
		const dateRange = {from: moment(from).utc().startOf('day').unix(), to: moment(to).utc().endOf('day').unix()};

		if (this.fromDate && dateRange.from > dateRange.to)
			return this.notValidPopupDate = true;
		this.notValidPopupDate = false;

		const reportsIds = this.reportsHistory.map(report => report.id);
		this.deviceManagementService.generateReceivedShipmentHistoryReport(dateRange, this.source, reportsIds).subscribe((response: any) => {
			this.exportReceivedShipmentData.hide();
			switch (response?.length) {
				case 0:
					return this.notificationService.setMessage('translate|g.date_range_has_no_records', { type: 'warning' });
				default:
					response.map(record => {
						record.checked_quality = record.checked_quality
							? this.translateService.instant('g.yes')
							: this.translateService.instant('g.no');
					});
				return this.receivingShipmentService.generateHistoryCsvFile(response, this.translateService.instant('nav.receiving_shipment_history'), 'receiving_history');
			}
		});
	}

	filterReceivingHistoryDevices() {
		this.invalidDevice = false;
		this.deviceId = this.deviceId.trim();
		const validationRules = this.validationService.getFormValidationRules('editDevice');
		let rule = validationRules['serial_number'];
		this.searchBy = 'serialNumber';
		const isMac = this.commonService.adjustMacSearchString(this.deviceId);
		if (isMac.mac_address) {
			this.searchBy = 'hex';
			rule = validationRules['mac_address'];
		}

		const isValid = this.validationService.dataValidator(this.searchBy, rule, this.deviceId);
		if (!isValid) {
			this.invalidDevice = true;
			return;
		}

		this.searchApplied = true;
		this.deviceManagementService.searchReceiveShipmentHistoryDevices(this.deviceId, this.searchBy).subscribe((response: any) => {
			if (response.invalid_fields) {
				this.searchApplied = false;
				this.invalidDevice = true;
				return;
			}

			if (response.length == 0) {
				this.searchApplied = false;
				this.reportsHistory = [];
				return;
			}

			this.filteredDevicesHistoryData(response);
		});
	}

	filteredDevicesHistoryData(data) {
		this.filteredData = data;
		const filteredReportsIds = data.map(record => record.report_id);
		this.reportsHistory = this.reportHistoryData.filter(report => filteredReportsIds.includes(report.id));
	}

	getDeviceHistoryStatus(reportId) {
		const deviceHistory = this.filteredData.find(item => item.report_id == reportId);
		const status = this.receivingShipmentService.getReceivingShipmentDeviceStatus(deviceHistory?.status);
		switch(this.searchBy) {
			case 'serialNumber':
				if (deviceHistory?.serial_number == this.deviceId && status)
					return 'g.pass';
			case 'hex':
				if (deviceHistory?.mac_address == this.deviceId && status)
					return 'g.pass'
			default:
				return 'g.fail'
		}
	}

	resetSearchFilter() {
		this.deviceId = '';
		this.searchApplied = false;
		this.invalidDevice = false;
		this.getReceiveShipmentReports();
	}

	toggleEditCmPoNumberPopup(value: boolean, toEditReport = null) {
		this.toEditReport = toEditReport;
		this.newCmPoNumberValue = this.toEditReport ? this.toEditReport.cm_po_number : "";

		if(value)
			return this.editCmPoNumberPopup.show();

		this.editCmPoNumberPopup.hide();
	}
}
