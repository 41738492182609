<div>
	<h2>{{(source == deviceManagementService.shipmentSource.quality ? 'shipment.shipment_quality_result' : 'shipment.receiving_shipment_result') | translate}}</h2>
	<div class="d-flex justify-content-between">
		<div class="ml-auto">
			<div class="row">
				<h4>{{'g.date' | translate}}: {{devices[0]?.created_at |date:'MM/dd/yyyy' }}</h4>
			</div>
		</div>
	</div>
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.mac_address' | translate}}</th>
				<th>{{'g.serial_number' | translate}}</th>
				<th>{{(source == deviceManagementService.shipmentSource.quality ? 'shipment.checked_successfully' : 'shipment.received_successfully') | translate}}</th>
				<th>{{'g.reason' | translate}}</th>
				<th>{{'g.details' | translate}}</th>
				<th>{{'g.voltage' | translate}}</th>
				<th>{{'g.current' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let device of devices">
				<td>{{device.mac_address}}</td>
				<td>{{device.serial_number}}</td>
				<td>{{ 'g.' + (device.status === DEVICE_STATUS.completed.status || device.status === DEVICE_STATUS.gps_not_ready.status ? 'yes' : 'no') | translate }}</td>
				<td>{{receivingShipmentService.isRecevingProccessSuccess(device.status) ? '---' : ('shipment.' + DEVICE_STATUS[device.status].status | translate)}}</td>
				<td>
					<ng-container [ngTemplateOutlet]="device.status == DEVICE_STATUS.warnings.status ? showWarningsDetails : successReceiveDetails"
                        		  [ngTemplateOutletContext]="{ device: device }">
          			</ng-container>
				</td>
				<td>{{printCurrentAndVoltageValue(device.details?.currentAndVoltage?.voltage)}}</td>
				<td>{{printCurrentAndVoltageValue(device.details?.currentAndVoltage?.current)}}</td>
			</tr>
		</tbody>
	</table>
</div>

<ng-template #successReceiveDetails let-device="device">
  </ng-template>
  
  <ng-template #showWarningsDetails let-device="device">
	<button *ngIf="device.status == DEVICE_STATUS.warnings.status; else noDetails" class="btn btn-warning btn-sm" (click)="showWarnings(device)">
	  {{ 'g.warnings' | translate }}
	</button>
</ng-template>

<ng-template #noDetails>---</ng-template>

<app-modal #confirmModal title="{{'nav.warnings' | translate}}" [hasConfirmButton]="false" [closeBtnText]="'g.cancel' | translate">
    <div class="mb-5">
        <ul>
            <li *ngFor="let warning of warningsData">
                {{warning}}
            </li>
        </ul>
    </div>
</app-modal>