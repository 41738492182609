import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { DeviceService } from '../../device.service';
import { UsersService } from 'src/app/users/users.service';
import * as moment from 'moment';

@Component({
	selector: 'app-device-admin',
	templateUrl: './device-admin.component.html'
})
export class DeviceAdminComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	rtSync: boolean;
	dataUpdateOnDB: any = {};
	meteredTimes: any = {
		rt_metered_time: null,
		qv_metered_time: null,
	};

	maxMeteredTimes: any = {
		rt_metered_time: 24,
		qv_metered_time: 1,
	};

	readTimesUntil: any = {
		rt_metered_time: '',
		qv_metered_time: '',
	};

	debugDataFields: string[] = ['last_event_id', 'last_rt_id', 'last_debug_id'];

	constructor(
		private deviceService: DeviceService,
		private notificationMessageService: NotificationMessageService,
		public usersService: UsersService,
	) { }

	ngOnInit() {
		const { rt_metered_time, qv_metered_time, last_event_id, last_debug_id, last_rt_id } = this.device.config_info;
		this.formatMeteredTimes({rt_metered_time, qv_metered_time, last_event_id, last_debug_id, last_rt_id});
	}

	ngOnChanges() {
		this.rtSync = this.device.config_info.rt_sync;
	}

	updateDevice() {
		this.deviceService.updateRTSync(this.device.mac_address, this.rtSync).subscribe((res: any) => {
			if(res.invalid_fields)
				return this.notificationMessageService.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
			this.device.config_info.rt_sync = this.rtSync;
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}

	updateDeviceData(updateDevice: boolean) {
		if (!updateDevice && (this.meteredTimes.rt_metered_time === null && this.meteredTimes.qv_metered_time === null))
			return;

		const dataToUpdate = updateDevice ? this.dataUpdateOnDB : this.meteredTimes;

		this.deviceService.updateDeviceOnDB(this.device.mac_address, dataToUpdate).subscribe((res: any) => {
			if(res.api_status == 3)
				return this.notificationMessageService.setMessage('translate|rt_requests.force_read_rt_validation_msg', {clearOnXTimeNavigate: 1});

			if(res.invalid_fields)
				return this.notificationMessageService.setMessage('translate|g.invalid_input');

			for (const field in this.meteredTimes)
				this.device.config_info[field] = res[field];

			if (updateDevice) {
				for (const field of this.debugDataFields)
					this.device.config_info[field] = res[field];
			}
			
			this.formatMeteredTimes(res);
			return this.notificationMessageService.setMessage('globalSuccessMsg');
		})
	}

	formatMeteredTimes(data?: any) {
		for (const field in data) {
			data[field] = data[field] || this.device.config_info[field];
			this.readTimesUntil[field] = moment.unix(data[field] || 0).utc().format('MM/DD/YYYY H:mm');

			if (this.debugDataFields.includes(field))
				this.dataUpdateOnDB[field] = data[field];
		}
	}

	flushCache() {
		this.deviceService.flushDeviceCache(this.device.mac_address).subscribe((res: any) => {
			if(res === 1)
				return this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}
}
