import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, PRIMARY_OUTLET } from "@angular/router";
import { Breadcrumb } from './breadcrumb.model';
import { BreadcrumbService } from './breadcrumb.service';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.css']
})
export class BreadcrumbComponent implements OnInit, OnDestroy {

  public breadcrumbs: Breadcrumb[];
  private subscription: Subscription = new Subscription();
  
  constructor(
    private breadcrumbService: BreadcrumbService,
    private translateService: TranslateService,
  ) { 
  	this.breadcrumbs = [];
  }

  ngOnInit() {
    this.subscription = this.breadcrumbService.breadcrumbChange.subscribe((breadcrumbs) => { 
       for (let breadcrumb of breadcrumbs) {
         breadcrumb.label = this.translateService.instant(breadcrumb.label);
       	if (!breadcrumb.params) breadcrumb.params = {}
       }
      this.breadcrumbs = breadcrumbs;
    });
  }
 
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
