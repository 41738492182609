<div class="justify-content-center confirm">
	<div>
		<div class="alert alert-info">
			{{'login_register.login_non_confirmed_email_txt' | translate}}
		</div>
		<app-contact-us
			[userId]="userId"
			[options]="{hideTitle: true, forced: true}"
		></app-contact-us>
	</div>
</div>