<div class="row mt-4">
	<form class="row col-md-12" #rejectionLog="ngForm">
		<div class="col-md-3">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.get_by' | translate}}</span>
				</div>
				<select [(ngModel)]="getBy" class="form-control" (change)="resetSelects()" name="getBy">
					<option *ngFor="let option of filterOptions" [value]="option">{{option}}</option>
				</select>
			</div>
		</div>

		<div *ngIf="getBy != 'Date' && getBy != 'Rejection Reason'" class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.value' | translate}}</span>
				</div>
				<input type="text" class="form-control" [(ngModel)]="value" name="value" *ngIf="getBy != 'User ID'"/>
				<input type="number" min="0" class="form-control" [(ngModel)]="value" name="value" *ngIf="getBy == 'User ID'"/>
			</div>
		</div>

		<div class="col-md-1" *ngIf="getBy != 'Date'">
		</div>
		
		<div class="row col-md-7" *ngIf="getBy == 'Date'">
			<div class="col-md-6">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{'g.start_date' | translate}}</span>
					</div>
					<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp1" [(ngModel)]="fromDate" bsDatepicker #d1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
					<div class="input-group-append pointer" (click)="d1.toggle()">
						<span class="input-group-text"><i class="fa fa-calendar"></i></span>
					</div>
				</div>
			</div>

			<div class="col-md-6">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{'g.end_date' | translate}}</span>
					</div>
					<input type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp2" [(ngModel)]="toDate" bsDatepicker #d2="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
					<div class="input-group-append pointer" (click)="d2.toggle()">
						<span class="input-group-text"><i class="fa fa-calendar"></i></span>
					</div>
				</div>
			</div>
		</div>

		<div class="col-md-2">
			<button class="form-control btn btn-success" (click)="getLoggingInfo()">{{'error_log.get_logs' | translate}}</button>
			<button class="form-control btn btn-danger mt-1" (click)="confirmDeleteLogs('deleteLogs')" [disabled]="!selectedLogsIds || selectedLogsIds.length === 0">{{'error_log.delete_logs' | translate}}</button>
		</div>
	</form>

</div>

<div class="my-4">
	<app-table #dataTable name="error-logs" [elements]="logInfo" [columnNames]="columnNames" [showExport]="false" [hasCheckboxColumn]="true" (actionEvent)="doAction($event)" (selectionChanged)="selectedLogsIds = $event" [pageSizeOptions]="[10, 20, 50, 100]" [pageSize]="10"></app-table>
</div>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>