<ng-template #modalTemplate>
	<div *ngIf="render">
		<div class="modal-header">
			<h4 class="modal-title pull-left">{{title}}</h4>
			<button type="button" class="close pull-right" aria-label="Close" (click)="onHide()">
				<span aria-hidden="true">&times;</span>
			</button>
		</div>
		<div class="modal-body">
			<span *ngIf="message">{{message | translate}}</span>
			<ng-content>
			</ng-content>
		</div>
		<div class="modal-footer" *ngIf="hasConfirmButton || hasCloseButton">
			<button *ngIf="hasConfirmButton" type="button" class="btn {{confirmBtnClass}}" (click)="onConfirm()" [disabled]="disableConfirm">{{confirmBtnText}}</button>
			<button *ngIf="hasCloseButton" type="button" class="btn btn-light" (click)="onHide()">{{closeBtnText}}</button>
		</div>
	</div>
</ng-template>