<div class="row justify-content-md-center">
	<div class="col-md-8 bg-white">
		<div class="border-bottom p-3">
			<h2>{{'Fake Data Generator' | translate}}</h2>
		</div>
		<div class="px-5 py-4">
			<ul class="text-danger">
				<li>Please use only fake devices, as the generated data may corrupt your existing saved data.</li>
				<li>If you observe unusual values in the generated data, kindly notify me so that I can address it.</li>
				<li>If you have any other concerns, please inform me.</li>
			</ul>
			<form #editUserFrm="ngForm" (submit)="onSubmit()">
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'g.mac_address' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<input class="form-control" name="mac_address" [(ngModel)]="macAddress" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'g.from' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<div class="input-group">
							<input class="form-control" placeholder="mm/dd/yyyy" name="installation_date" [(ngModel)]="from" bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" required>
							<span class="input-group-btn">
								<button class="btn btn-default btn-date" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
							</span>
						</div>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'g.to' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<div class="input-group">
							<input class="form-control" placeholder="mm/dd/yyyy" name="installation_date" [(ngModel)]="to" bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" required>
							<span class="input-group-btn">
								<button class="btn btn-default btn-date" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
							</span>
						</div>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'Max Records Count Per Day' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<input type="number" [min]="1" class="form-control" name="maxPerDay" [(ngModel)]="maxPerDay" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'g.type' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<ng-select
							[items]="typeList"
							bindLabel="name"
							bindValue="id"
							name="type"
							placeholder="{{'g.type' | translate}}"
							[(ngModel)]="type"
							[clearable]="false"
							[required]="true"
							(onchange)="withDailyDetails = false; withAnalytics = false;"
						>
						</ng-select>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'With Daily Details' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<div class="form-check form-check-inline slide-toggle-div">
							<mat-slide-toggle [disabled]="type != 'event'" [(ngModel)]="withDailyDetails" name="is_admin"></mat-slide-toggle>
						</div>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'With Analytics' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<div class="form-check form-check-inline slide-toggle-div">
							<mat-slide-toggle [disabled]="!withDailyDetails" [(ngModel)]="withAnalytics" name="email_confirmed"></mat-slide-toggle>
						</div>
					</div>
				</div>
				<div class="row my-4 align-items-center">
					<div class="col-md-4">
					</div>
					<div class="col-md-8">
						<div class="row align-items-center">
							<button type="submit" class="btn text-white main-blue-bg btn-lg col-5 mr-4">{{'g.submit' | translate}}</button>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>