import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class StudiesService {

	route = 'study';

	constructor(
		private httpClient: HttpClient,
	) {}

	getStudies(params: any) {
		return this.httpClient.get(`${this.route}/`, { params });
	}

	checkHasStudies(siteId: number) {
		return this.httpClient.get('study/check-has-studies', {
			observe: "body",
			params: {
				site_id: siteId
			}
		});
	}

	getStudy(studId: number) {
		return this.httpClient.get(`study/${studId}`, {
			observe: "body",
		});
	}

	getAllStudyDevices() {
		return this.httpClient.get('study/get-all-study-devices', {
			observe: "body",
		});
	}

	deletePowerStudies(studyIds: number[] | number) {
		return this.httpClient.delete('study/delete', {
			observe: "body",
			params: {
				studyIds
			}
		});
	}

	requestPowerStudy(body: any) {
		return this.httpClient.post('study/request-study', body);
	}

	editStartAndEndEvent(macAddress: string, studyId: number, startEventId: number, endEventId: number | null) {
		return this.httpClient.post('study/edit-start-end-event', { macAddress, studyId, startEventId, endEventId }, {
			observe: "body",
		});
	}

	getMinMaxAllowedEventsToEditStudy(studyId: number, macAddress: string) {
		return this.httpClient.post('study/edit-study-min-max-allowed-events', { studyId, macAddress }, {
			observe: "body",
		});
	}
}
