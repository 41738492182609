<div class="row m-0">
	<div class="col-md-5 px-5 mb-5 pb-5">
		<div class="h-100 px-5">
			<div class="row h-100 justify-content-center align-items-center">
				<form class="col-12" (ngSubmit)="f.form.valid && onLogin(f)" #f="ngForm">
					<div class="mb-5 pb-5"><img src="/sctportal{{loginLogo}}" alt="IoTAh" width="200px"></div>
					<div class="form-group">
						<h4>{{'login_register.log_in' | translate}}</h4>
					</div>
					<div class="form-group validated-field">
						<input type="email" name="email" ngModel #emailComponent #email="ngModel" required email class="form-control rounded" placeholder="{{'login_register.email' | translate}}" autofocus (blur)="checkEmailValidation()">
						<div class="invalid-tooltip" *ngIf="email.touched && email.invalid">
							{{"g.fill_out_massage" | translate}}
						</div>
						 <div class="invalid-tooltip" *ngIf="email.touched && !isValidEmail">
							{{"g.invalid_email_format" | translate}}
						</div>
					</div>
					<div class="form-group validated-field">
						<input type="password" name="password" ngModel #password="ngModel" required class="form-control rounded" placeholder="{{'g.password' | translate}}" show-password>
						<div class="invalid-tooltip" *ngIf="password.touched && password.invalid">
							{{"g.fill_out_massage" | translate}}
						</div>
					</div>
					<div class="form-group">
						<a class="text-secondary" routerLink="/forgot-password"><small>{{'login_register.forgot_password' | translate}}?</small></a>
					</div>
					<div class="form-group">
						<button type="submit" class="btn btn-primary btn-block rounded">{{'login_register.login' | translate}}</button>
					</div>
					<div *ngIf="showCaptcha">
						<app-captcha #captchaComponent></app-captcha>
					</div>
					<div class="form-group">
						<a routerLink="/register">{{'login_register.register_new_account' | translate}}</a>
					</div>
				</form>
			</div>
		</div>
	</div>
	<div class="col-md-7 d-none d-md-block p-0">
		<div [ngClass]="loginImg"></div>
	</div>
</div>
