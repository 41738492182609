<div class="row mt-4 ml-1">
	<div class="col">
		<h4>
			{{siteName}} {{'site.download_status' | translate}}
		</h4>
	</div>
	<div class="align-self-center left-panel-item mr-5" (click)="goBack()">
		<i class="fa pointer" [ngClass]="{'fa-arrow-left': true}"></i>
	</div>
</div>
<div class="p-4 gray-bg row">
	{{'site.filter_by_status' | translate}}:
	<div class="form-check form-check-inline ml-2" *ngFor="let item of statusList | keys">
		<input class="form-check-input" type="radio" name="statusFilter" [(ngModel)]="statusFilter" id="status_{{item}}" value="{{item}}" (change)="applyFilter()">
		<label class="form-check-label" for="status_{{item}}">{{statusList[item]}}</label>
	</div>
</div>
<div class="p-4 bg-white border-radius-bottom">
	<table class="table" *ngIf="selectedDevices.length; else noDevices">
		<thead>
			<th>
				{{'g.iotah_sn' | translate}}
			</th>
			<th>
				{{'devices.truck_sn' | translate}}
			</th>
			<th>
				{{'devices.truck_id' | translate}}
			</th>
			<th *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
				{{'site.available_events' | translate}}
			</th>
			<th *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
				{{'devices.fw_version' | translate}}
			</th>
			<th *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
				{{'settings.fw_version_to_push' | translate}}
			</th>
			<th *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
				{{'settings.fw_update_block_reason' | translate}}
			</th>
			<th>
				{{'site.download_status' | translate}}
			</th>
		</thead>
		<tbody>
			<tr *ngFor="let device of selectedDevices">
				<td>
					<a [routerLink]="['/', customerId, siteId, device.mac_address, 'performance']">{{device.serial_number}}</a>
				</td>
				<td>
					{{device.truck_sn}}
				</td>
				<td>
					{{device.truck_id}}
				</td>
				<td *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
					{{device.total_events}} {{'site.events' | translate}}
				</td>
				<td *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
					{{common.toFixedWithoutRounding(device.fw, isAdmin ? 4 : 2)}}
				</td>
				<td *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
					{{device.fw_to_push && device.fw_to_push > 0 && !device.testing_fw ? this.common.toFixedWithoutRounding(device.fw_to_push, isAdmin ? 4 : 2) : 'N/A'}}
				</td>
				<td *ngIf="usersService.userHasNOCAccess(enterprisePermissions)">
					{{('blocked_fw_reasons.'+(device.blocked_reason || 'na')) | translate}}
				</td>
				<td>
					<div class="progress" *ngIf="device.connected; else disconnected">
						<div class="progress-bar progress-bar-striped progress-bar-animated bg-success" role="progressbar" aria-valuemin="0" aria-valuemax="100" [ngStyle]="{
							'width': device.status+'%'
						}">
							<span *ngIf="usersService.userHasNOCAccess(enterprisePermissions); else noCount">
								<span *ngIf="device.downloaded > 0">
									{{device.downloaded}} {{'g.out_of' | translate}} {{device.total_events}} ({{device.status | number:'1.0-0'}}%)
								</span>
							</span>
							<ng-template #noCount>
								<span *ngIf="device.downloaded > 0">
									{{device.status | number:'1.0-0'}}%
								</span>
							</ng-template>
						</div>
					</div>
					<ng-template #disconnected>
						<div class="progress">
							<div class="progress-bar progress-bar-striped bg-danger" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100">
								{{'g.disconnected' | translate}}
							</div>
						</div>
					</ng-template>
				</td>
			</tr>
		</tbody>
	</table>
	<ng-template #noDevices>
		{{'g.no_data_to_show' | translate}}
	</ng-template>
</div>