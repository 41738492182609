import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-site-warnings',
	templateUrl: './site-warnings.component.html',
	styleUrls: ['./site-warnings.component.css']
})
export class SiteWarningsComponent implements OnInit {
	siteId: number;
	customerId: number;

	enterprisePermissions: any = {};
	permissions: any = {};
	permissionSub: Subscription = new Subscription();

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService,
	) { }

	ngOnInit() {
		this.siteId = Number(this.route.parent.snapshot.params['siteId']);
		this.customerId = Number(this.route.parent.snapshot.params['customerId']);

		this.permissionSub = this.usersService.getPermissions(this.siteId).subscribe((permissions: any) => {
			this.enterprisePermissions = permissions;
			this.permissions = permissions.permission;

			if(!this.usersService.hasAccessPermission(permissions, 'noc')) {
				return this.router.navigate(['/unauthorized']);
			}
		});
	}
}
