<div class="row justify-content-md-center">
	<div class="col-md-6 bg-white">
		<div class="border-bottom p-3">
			<h2>{{'permissions.edit_permission' | translate}}</h2>
		</div>
		<div class="px-5 py-4">
			<form #updatePermissionForm="ngForm" (ngSubmit)="linkedItemsModal.show()" validate-form="updatePermission">
				<div class="row mt-2 align-items-center border-bottom pb-2">
					<div class="col-md-4">
						<strong>{{'permissions.permission_name' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<input class="form-control" name="name" [(ngModel)]="permission.name" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center" *ngFor="let field of permissionFields">
					<div class="col-md-4">
						<strong>{{'p.permissions.'+field | translate}}</strong>
					</div>
					<div class="col-md-8">
						<ng-select
							[items]="readWriteOptions"
							bindLabel="text"
							bindValue="v"
							name="perm-{{field}}"
							[(ngModel)]="permission.permissions[field]"
							[clearable]="false"
						>
						</ng-select>
					</div>
				</div>
				<div class="row my-4 align-items-center">
					<div class="col-lg-4">
					</div>
					<div class="col-lg-8">
						<button type="submit" class="btn text-white main-blue-bg btn-lg col-lg-4 mb-4 mr-4" [disabled]="!updatePermissionForm.dirty">{{'g.submit' | translate}}</button>
						<button type="button" class="btn text-white main-grey-bg btn-lg col-lg-4 mb-4" (click)="goBack()">{{'g.back' | translate}}</button>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
<app-modal
	#linkedItemsModal
	title="{{'g.confirm_action' | translate}}"
	[message]="(permissionType == 'enterprise') ? 'permissions.linked_enterprise_prompt' : 'permissions.linked_user_prompt'"
></app-modal>