<div class="mt-4" *ngIf="!triesLimitReached">
	<label for="captcha">{{'login_register.captcha_hint' | translate}}</label>
	<div>
		<img [src]="captchaUrl" height="50px" width="150px">
		<fa-icon icon="sync-alt" size="lg" class="text-primary ml-2 pointer" style="vertical-align: top" (click)="getCaptcha(true)" [attr.title]="'login_register.try_another_code' | translate"></fa-icon>
	</div>
</div>
<div [class]="'mt-2 row col-md-'+textFieldCols" *ngIf="!triesLimitReached">
	<input type="text" class="form-control" name="captchaText" [(ngModel)]="captchaText" required/>
</div>
