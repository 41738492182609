<div class="card">
	<div class="card-header">
		<h4>Overall Calculations</h4>
	</div>
	<div class="card-body">
		<ul>
			<li><b>Remaining Devices Count</b>: {{calcs.rmData.devicesCount}}</li>
			<li><b>Remaining Events Count</b>: {{calcs.rmData.eventsCount}}</li>
		</ul>
		<table class="table">
			<thead>
				<tr>
					<th></th>
					<th>Charge kWh (Before)</th>
					<th>Charge kWh (After)</th>
					<th>Duration Seconds (Before)</th>
					<th>Duration Seconds (After)</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>Smart Rebates Sites</td>
					<td>{{ calcs.smart.before.kwh }}</td>
					<td>{{ calcs.smart.after.kwh }}</td>
					<td>{{ calcs.smart.before.duration }}</td>
					<td>{{ calcs.smart.after.duration }}</td>
				</tr>
				<tr>
					<td>Not Smart Rebates Sites</td>
					<td>{{ calcs.nonSmart.before.kwh }}</td>
					<td>{{ calcs.nonSmart.after.kwh }}</td>
					<td>{{ calcs.nonSmart.before.duration }}</td>
					<td>{{ calcs.nonSmart.after.duration }}</td>
				</tr>
				<tr>
					<td>Total</td>
					<td>{{ calcs.smart.before.kwh + calcs.nonSmart.before.kwh }}</td>
					<td>{{ calcs.smart.after.kwh + calcs.nonSmart.after.kwh }}</td>
					<td>{{ calcs.smart.before.duration + calcs.nonSmart.before.duration }}</td>
					<td>{{ calcs.smart.after.duration + calcs.nonSmart.after.duration }}</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<br>
<div class="card">
	<app-debug-logs [fileName]="fileName"></app-debug-logs>
</div>