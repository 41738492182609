import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../users/users.service';
import { Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, OnDestroy {

	currentUser: any = {};
	constructor(
		private usersService: UsersService,
		private router: Router,
		public sideMenuService: SideMenuService,
	) {}

	ngOnInit() {
		this.sideMenuService.show();
		this.currentUser = this.usersService.getCurrentUser();
	}

	ngOnDestroy() {
	}
}
