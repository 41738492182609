import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DeviceService } from '../../device.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-events',
	templateUrl: './events.component.html'
})
export class EventsComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() currentSite: any;
	@Input() dateRange: any = {};
	@Input() invalidDateRange: boolean = false;
	@Input() showCorruptedEvents: boolean = false;
	@Input() showDefectiveEvents: boolean = false;
	@Input() studyId: null | number = null;

	exportFileName: string = "";
	exportDefectiveFileName = "";
	oneDayInSeconds: number = 24* 60 * 60;
	currentUser: any = {};
	events: any[] = [];
	corruptedEvents: any[] = [];
	defectiveEvents: any[] = [];
	hasGrid: boolean = false;

	eventTables = {
		events: {columnDefs: [], hasGrid: false},
		offEvent: {columnDefs: [], hasGrid: false},
		defectiveEvents: {columnDefs: [], hasGrid: false}
	}

	vpcDropDownList: any = [];
	enterprisePermissions: any = {};
	permissionSub: Subscription = new Subscription();
	allColumnsKeys: string[] = [];
	wsToKwhrRatio: any = 3600000;
	eventZoneFields: string[] = [
		"bv_timestamp",
		"max_temperature_recorded_timestamp",
		"timestamp",
		"end_timestamp",
	];
	durationFields: string[] = [
		"duration",
		"accurate_inuse_seconds",
		"accurate_charge_seconds",
		"event_duration",
		"lift_time",
		"travel_time"
	];
	floatFields: string[] = [
		'min_voltage',
		'start_voltage',
		'event_max_current',
		'event_min_current',
		'event_average_current',
		'latitude',
		'longitude',
	];

	offEventLimit: number = 60;
	isAdminUser: Boolean;

	constructor(
		private deviceService: DeviceService,
		private translateService: TranslateService,
		private commonService: CommonService,
		private usersService: UsersService,
	) { }

	ngOnInit() {
		this.allColumnsKeys = this.deviceService.getEventsFields();
		this.permissionSub = this.usersService.getPermissions(this.currentSite.id).subscribe((res: any) => {
			this.enterprisePermissions = res;
		});

		this.currentUser = this.usersService.getCurrentUser();
		this.isAdminUser = this.currentUser.is_admin;
	}

	getEvents() {
		let fromDateRange = this.dateRange.fromDate;
		let toDateRange = this.dateRange.toDate;
		if (!this.device || !this.device.mac_address || !fromDateRange || !toDateRange) return;

		fromDateRange = new Date(new Date(fromDateRange).setHours(0, 0, 0, 0));
		toDateRange = new Date(new Date(toDateRange).setHours(23, 59, 59, 999));

		const formattedFromDateRange = moment(fromDateRange).format('YYYY.MM.DD');
		const formattedToDateRange = moment(toDateRange).format('YYYY.MM.DD');
		this.exportFileName = `${this.device.serial_number}-${formattedFromDateRange}-${formattedToDateRange}.csv`;
		this.exportDefectiveFileName = `${this.device.serial_number}-${formattedFromDateRange}-${formattedToDateRange}-defective-events.csv`;
		let zoneDiff = new Date().getTimezoneOffset() * -1;
		let fromDate: any = new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000));
		let toDate: any = new Date(new Date(toDateRange).getTime() + (zoneDiff * 60 * 1000));
		fromDate = moment(fromDate).utc().startOf('day').unix();
		toDate = moment(toDate).utc().endOf('day').unix();

		this.deviceService.getEvents(this.device.mac_address, fromDate, toDate, this.studyId).subscribe((data: any) => {
			this.events = this.formatData(data.events);
			this.corruptedEvents = this.formatData(data.corrupted, true);
			this.defectiveEvents = this.formatData(data.defective, false, true);
			this.buildGrid();
		});
	}
	// Hide events (idle, charge, Inuse and off) if user type not NOC or Admin and duration less than 1 Mints
	hideEventsType(events: any) {
		if (!(this.usersService.userHasNOCAccess() || this.currentUser.is_admin)) {
			const newEvents = events.filter((item: any) => item.calc_duration >= 60 || item.event_duration >= 60);
			return newEvents;
		}
		return events;
	}

	buildGrid() {
		let booleanFields = [
			'midnight_split',
			'time_change_split',
			'water_level_exists',
			'water_level_high',
			'water_flow_exists',
			'temperature_exists',
			'system_start',
			'first_time_configuration',
			'fw_update',
			'user_restart_request',
			'set_rtc',
			'set_serial_number',
			'update_config',
			'data_from_bv',
			'split_at_start',
			'split_at_end',
			'flag_finish',
			'flag_eq',
			'flag_finish_start',
			'flag_eq_start',
		];

		let integerFields = [
			'sequence_id',
			'bv_id',
			'bv_event_id',
			'bv_time_diff',
			'vpc',
			'max_temperature_recorded',
			'hall_effect_count',
			'number_of_cells',
			'bv_time_zone',
			'plc_detection',
		];

		let floatFields = [
			'charge_kwhr',
			'inuse_kwhr',
			'charge_ahr',
			'inuse_ahr',
			'end_voltage',
			'min_voltage',
			'start_voltage',
			'event_max_current',
			'event_min_current',
			'event_average_current',
			'latitude',
			'longitude',
			'billed_kwh'
		];

		let shownFields = this.deviceService.eventsDefaultFields;
		let justIotahDevicesEventFields = this.deviceService.justIotahDevicesEventFields;

		let dateTimeFields = [
			"bv_timestamp",
			"max_temperature_recorded_timestamp",
			"timestamp",
			"end_timestamp",
		];

		let helpFields = [
			'sequence_id',
			'timestamp',
			'end_timestamp',
			'event_type',
			'event_duration',
			'inuse_ahr',
			'charge_kwhr',
			'inuse_kwhr',
			'charge_ahr',
			'min_voltage',
			'start_voltage',
			'end_voltage',
			'event_max_current',
			'event_average_current',
			'number_of_cells',
			'vpc',
			'accurate_inuse_seconds',
		];

		let adminFields = this.deviceService.eventsAdminFields;
		let NocAccessFields = this.deviceService.eventsNocAccessFields;

		const eventTables = [
			{key: 'events', data: this.events, existsColumns: this.getExistColumns(this.events)},
			{key: 'offEvent', data: this.corruptedEvents, existsColumns: this.getExistColumns(this.corruptedEvents)},
			{key: 'defectiveEvents', data: this.defectiveEvents, existsColumns: this.getExistColumns(this.defectiveEvents)},
		];

		for (let table of eventTables) {
			if (!table.data.length) {
				this.eventTables[table.key].hasGrid = false;
				continue;
			}

			let gridColumns = [];

			for (let field of this.allColumnsKeys) {
				if (justIotahDevicesEventFields.includes(field) && this.device.config_info.is_charglink)
					continue;

				let colDef: any = {};

				if (table.existsColumns.includes(field)) {
					if (booleanFields.includes(field)) {
						colDef = { type: 'boolean' };
					} else if (integerFields.includes(field)) {
						colDef = { type: 'number', filterParams: { format: 'noFormat' } };
					} else if (floatFields.includes(field)) {
						colDef = { type: 'number', filterParams: { format: '1.0-2' } };
					} else if (dateTimeFields.includes(field)) {
						colDef = { type: 'dateTime', floatingFilterComponentParams: { suppressFilterButton: true } };
					} else if (this.durationFields.includes(field)) {
						colDef = {
							filter: 'agTextColumnFilter',
							filterValueGetter: 'data.' + field,
							filterParams: {
								filterOptions: ['equals', 'greaterThan', 'lessThan'],
								defaultOption: 'equals',
								thisObject: this,
								textMatcher: ({ filterOption, value, filterText }: { filterOption: any, value: any, filterText: string }) => {
									return this.commonService.durationFormatter({filter: filterOption, value, filterText});
								}
							}
						};
					} else if (field == 'event_type') {
						colDef = {
							floatingFilterComponent: "DropDownFilter",
							floatingFilterComponentParams: {
								dropDownOptions: [
									{ label: '', value: null },
									{ value: this.translateService.instant('events.event_type_1'), label: this.translateService.instant('events.event_type_1') },
									{ value: this.translateService.instant('events.event_type_2'), label: this.translateService.instant('events.event_type_2') },
									{ value: this.translateService.instant('events.event_type_3'), label: this.translateService.instant('events.event_type_3') },
									{ value: this.translateService.instant('events.event_type_off'), label: this.translateService.instant('events.event_type_off') },
								]
							},
						};
					} else if (field == 'battery_type') {
						colDef = {
							floatingFilterComponent: "DropDownFilter",
							floatingFilterComponentParams: {
								dropDownOptions: [
									{ label: '', value: null },
									{ value: this.translateService.instant('events.battery_type_0'), label: this.translateService.instant('events.battery_type_0') },
									{ value: this.translateService.instant('events.battery_type_2'), label: this.translateService.instant('events.battery_type_2') },
									{ value: this.translateService.instant('events.battery_type_3'), label: this.translateService.instant('events.battery_type_3') },
								]
							},
						};
					}

					if (!shownFields.includes(field))
						colDef.hide = true;

					if (adminFields.includes(field) && !this.isAdminUser)
						continue;

					if (NocAccessFields.includes(field) && !this.usersService.userHasNOCAccess(this.enterprisePermissions))
						continue;

					if (helpFields.includes(field))
						colDef.headerTooltip = this.translateService.instant('grid_help.events.' + field);

					if (field == 'vpc')
						Object.assign(colDef, {
							floatingFilterComponent: "DropDownFilter",
							floatingFilterComponentParams: { dropDownOptions: this.vpcDropDownList }
						});

					if (this.durationFields.includes(field)) {
						if (table.key == 'events' || table.key == 'defectiveEvents')
							colDef = Object.assign({ headerName: 'events.' + field, field: field + "_formatted", colId: field + "_formatted" }, colDef);

						else if (table.key == 'offEvent')
							colDef = Object.assign({ headerName: 'events.' + field, field: field + "_formatted", colId: field + "_formatted_off" }, colDef);
					}
					else {
						if (table.key == 'events' || table.key == 'defectiveEvents')
							Object.assign(colDef, { headerName: 'events.' + field, field: field, colId: field });

						else if (table.key == 'offEvent')
							Object.assign(colDef, { headerName: 'events.' + field, field: field, colId: field + "_off" });
					}

					gridColumns.push(colDef);
				}
			}

			this.eventTables[table.key].hasGrid = true;
			this.eventTables[table.key].columnDefs = gridColumns;
		}
	}

	getExistColumns(source: any[]) {
		if (!source.length)
			return [];

		let cols = {};

		for (const event of source) {
			if (event.sequence_id == 'N/A')
				continue;

			for (const key in event) {
				if (!cols[key]) {
					cols[key] = true;
				}
			}
		}

		const columnsList = Object.keys(cols);
		columnsList.push(
			'event_duration',
			'charge_kwhr',
			'inuse_kwhr',
			'charge_ahr',
			'inuse_ahr',
			'billed_kwh',
			'lift_time',
			'travel_time'
		);

		return columnsList;
	}

	formatData(data, getCorrupted = false, defective = false) {
		let events = [];

		let allVpcs = {};
		let vpcDropDownOptions = [
			{ label: '', value: null },
		];

		let previousEvent;
		let beforeLastEvent;

		const createOffEvents = (startTime, endTime) => {
			while (startTime < endTime) {
				const timeDiff = endTime - startTime;
				if (timeDiff < this.oneDayInSeconds) {
					let offEvent = this.getOffEvent(startTime, endTime);
					this.formatEvent(offEvent);
					events.push(offEvent);
					break;
				} else {
					const endOfDay = moment(startTime*1000).utc().endOf('day').unix();
					let offEvent = this.getOffEvent(startTime, endOfDay + 1);
					this.formatEvent(offEvent);
					events.push(offEvent);
					startTime = endOfDay + 1;
				}
			}
		}

		data.forEach((element) => {
			let event = getCorrupted ? element.event : this.commonService.decompress(element, 'events');
			event.event_duration = event.end_timestamp - event.timestamp;

			if(this.device.config_info.is_charglink && this.currentSite.state_id != 'Oregon')
				event.charge_kwhr = event.billed_kwh;

			if (!getCorrupted && !defective) {
				let hasOffEvent = this.checkOffEvent(previousEvent, event);
				if (hasOffEvent) {
					createOffEvents(previousEvent.end_timestamp, event.timestamp);
				}
			}

			beforeLastEvent = Object.assign({}, previousEvent);
			previousEvent = Object.assign({}, event);
			this.formatEvent(event);

			if (getCorrupted)
				event.insertion_time = moment((new Date(element.insertion_time)).getTime()).utc().format('MM/DD/YYYY HH:mm:ss');

			events.push(event);

			if (!getCorrupted && !defective)
				allVpcs[event.vpc] = true;
		});

		if (!getCorrupted && !defective) {
			for (let vpc of Object.keys(allVpcs)) {
				vpcDropDownOptions.push({ label: '' + vpc, value: vpc },);
			}
			this.vpcDropDownList = vpcDropDownOptions;
		}
		return this.hideEventsType(events);
	}

	ngOnChanges(changes) {
		if ((changes.showCorruptedEvents === undefined || changes.showCorruptedEvents.firstChange) && (changes.showDefectiveEvents === undefined || changes.showDefectiveEvents.firstChange))
			this.getEvents();
	}

	checkOffEvent(previous, current) {
		if (!previous)
			return false;

		// if the current event START TIME -  previous event END TIME  > 1 minute
		// neither of these events have SET RTC Flag
		if (
			current.timestamp - previous.end_timestamp > this.offEventLimit &&
			!current.set_rtc && !previous.set_rtc
		)
			return true;
	}

	getOffEvent(startTime, endTime) {
		// type OFF
		// all fields will be N/A except for start time (EVENT_A END TIME) , end time (EVENT_B start time) and duration
		let tempEvent: any = {};
		tempEvent.event_type = 'off';
		tempEvent.timestamp = startTime;
		tempEvent.end_timestamp = endTime;
		tempEvent.event_duration = endTime - startTime;
		tempEvent.battery_type = 4;
		for (let key of this.allColumnsKeys) {
			if (tempEvent[key])
				continue;

			tempEvent[key] = 'N/A';
		}
		return tempEvent;
	}

	formatEvent(event) {
		let eventKeys = Object.keys(event);
		eventKeys.forEach((field) => {
			if (this.eventZoneFields.includes(field)) {
				event[field] = moment(event[field] * 1000).utc().format('MM/DD/YYYY HH:mm:ss');
			} else if (this.durationFields.includes(field)) {
				if(!event[field])
					event[field] = 0;

				event[field + "_formatted"] = this.commonService.timeFormat(event[field], { limitToOneDay: true });
			} else if (this.floatFields.includes(field)) {
				event[field] = +(event[field] / 1.0).toFixed(2);
				if (Number.isNaN(event[field]))
					event[field] = 'N/A';
			}
		});

		if (Number.isNaN(event.charge_kwhr)) event.charge_kwhr = 'N/A';
		if (Number.isNaN(event.inuse_kwhr)) event.inuse_kwhr = 'N/A';
		if (Number.isNaN(event.charge_ahr)) event.charge_ahr = 'N/A';
		if (Number.isNaN(event.inuse_ahr)) event.inuse_ahr = 'N/A';

		event.event_type = this.translateService.instant('events.event_type_' + event.event_type);

		if (eventKeys.includes('battery_type')) {
			event.battery_type = this.translateService.instant('events.battery_type_' + event.battery_type);
		}
	}
	ngOnDestroy() {
		this.permissionSub.unsubscribe();
	}
}
