import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-terms-and-conditions',
	templateUrl: './terms-and-conditions.component.html',
	styleUrls: ['./terms-and-conditions.component.css']
})
export class TermsAndConditionsComponent implements OnInit {

	constructor(
		private router: Router,
		private navigation: NavigationService,
		private sideMenuService: SideMenuService,
		public usersService: UsersService
	) { }

	ngOnInit() {
		if(!this.usersService.isLoggedIn())
			return this.router.navigate(['/']);

		const user = this.usersService.getCurrentUser();
		if (!user || user.terms_confirmed)
			return this.router.navigate(['/']);

		this.sideMenuService.hide();
		return;
	}

	confirmUserAgreement() {
		this.usersService.confirmUserAgreement().subscribe((res) => {
			this.usersService.updateCurrentUser(res);
			return this.router.navigate(['/']);
		});
	}

	declineUserAgreement() {
		this.usersService.logout().subscribe((done: boolean) => {
			this.navigation.setPreviousUrl(null, true);
			this.usersService.setCurrentUser({});
			this.router.navigate(['/login']);
		});
	}
}
