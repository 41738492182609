import { Component, OnInit, Input, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { ControlContainer, NgForm } from '@angular/forms';
import { UsersService } from 'src/app/users/users.service';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { SitesService } from '../../sites.service';

@Component({
	selector: 'app-site-edit',
	templateUrl: './site-edit.component.html',
	styleUrls: ['./site-edit.component.css'],
	viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class SiteEditComponent implements OnInit {
	@ViewChild('siteNameComponent', { static: false }) siteNameComponent: ElementRef;
	@Input() site: any = {};
	@Input() enterpriseList: Array<any> = [];
	@Input() miniForm: boolean = false;
	@Input() restrictSpecialSite: boolean = false;
	@Input() customerEnterprises: Array<any> = [];
	TimeZonesMenu = <any>[];
	minInstallationDate = new Date(1970, 1, 1);

	constructor(
		private commonData: CommonDataService,
		public usersService: UsersService,
		private siteService: SitesService,
	) {
	}

	ngOnInit() {
		this.TimeZonesMenu = this.commonData['TimeZonesMenu'];
		// set the default value of is_smart_rebates to true
		if (!Object.keys(this.site).includes('is_smart_rebates'))
			this.site.is_smart_rebates = true;
	}

	ngAfterViewInit() {
		this.siteNameComponent.nativeElement.focus();
	}

}
