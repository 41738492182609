<div class="white-box mt-4 p-4 border-radius" *ngIf="currentUser.id">
	<app-grid
		[data]="data" 
		[columns]="columnDefs"
		#agGrid
		id="daily-details-log-v2-{{currentUser.id}}"	
		*ngIf="data.length > 0; else noRes"
	></app-grid>
</div>
<ng-template #noRes>
	<div class="text-danger" *ngIf="invalidDateRange; else noData">
		<b>{{'g.start_lg_end' | translate}}</b>
	</div>
</ng-template>
<ng-template #noData>
	{{'g.no_data_available_for_the_selected_period' | translate}}
</ng-template>