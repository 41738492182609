import { Component, OnInit, OnChanges, Input, ViewChild, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from '../notification-message/notification-message.service';
import { NoteService } from './note.service';
import * as _ from 'underscore';
import * as moment from 'moment';
import { CommonService } from '../services/common.service';

@Component({
	selector: 'app-note',
	templateUrl: './note.component.html',
	styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit, OnChanges, AfterViewInit {

	@Input() siteId: number = 0;
	@Input() modelType: string = null;
	@Input() modelId: any;
	@Input() title: string;
	@Input() limit: number = 999;
	@Input() tooltipKey: string;

	shareTypeList: any = [];
	reminderFreqList: any = [];
	notificationUsers: any = [];
	allNotificationUsers: any[] = [];
	modelNotes: any = [];
	modelNotesExpand: any = [];
	showMore: boolean = false;
	isSCT: boolean = false;
	thisNote: any;

	@ViewChild('addNoteModal') addNoteModal;
	@ViewChild('deleteNoteModal') deleteNoteModal;
	@ViewChild('editReminderFreqModal') editReminderFreqModal;
	@ViewChild('editShareTypeModal') editShareTypeModal;

	constructor(
		public noteService: NoteService,
		private translateService: TranslateService,
		private notificationMessageService: NotificationMessageService,
		public commonService: CommonService,
	) { }

	ngOnInit() {}
	
	ngOnChanges() {
		if(this.modelType == 'site') {
			this.siteId = this.modelId;
		}
		this.getModelNotes();
	}

	ngAfterViewInit() {
		this.addNoteModal.onClose.subscribe((ok) => {
			if(ok) { this.addNote(); }
		});
		this.deleteNoteModal.onClose.subscribe((ok) => {
			if(ok) { this.deleteNote(); }
		});
		this.editReminderFreqModal.onClose.subscribe((ok) => {
			if(ok) { this.editReminderFreq(); }
		});
		this.editShareTypeModal.onClose.subscribe((ok) => {
			if(ok) { this.editShareType(); }
		});
	}

	prepareShareOptions() {
		let shareOptions = this.noteService.shareOptions,
			shareTypeList = [];

		if(!this.isSCT) {
			delete shareOptions.sct;
		}

		for(let option in shareOptions) {
			shareTypeList.push({
				id: shareOptions[option],
				label: this.translateService.instant('note.share_type_'+shareOptions[option])
			});
		}
		this.shareTypeList = shareTypeList;
	}

	prepareReminderOptions() {
		let reminderOptions = [];
		for(let option in this.noteService.reminderFrequencyOptions) {
			reminderOptions.push({
				id: this.noteService.reminderFrequencyOptions[option],
				label: this.translateService.instant('note.reminder_freq_'+this.noteService.reminderFrequencyOptions[option])
			});
		}
		this.reminderFreqList = reminderOptions;
	}

	getModelNotes() {
		if(!this.modelId || !this.modelType) {
			return;
		}

		this.noteService.getModelNotes(
			this.modelId,
			this.modelType,
			{
				getReceivableUsers: true,
				getIsSCT: true,
				siteId: this.siteId
			}
		).subscribe((data: any) => {
			this.modelNotes = data.model_notes;
			this.modelNotesExpand = [];

			this.isSCT = data.isSCT;
			this.allNotificationUsers = data.receivable_users;

			this.allNotificationUsers.forEach(item => {
				item.fullNname = item.first_name + ' ' + item.last_name;
			});
			this.notificationUsers = this.allNotificationUsers;

			for(var i = 0; i < this.modelNotes.length; i++ ) {
				this.modelNotes[i].originalNote = this.modelNotes[i].note;

				let noteText = this.modelNotes[i].note;
				if(this.modelNotes[i].type == this.noteService.noteTypes.system) {
					noteText = JSON.parse(noteText);
					this.modelNotes[i].originalNote = this.translateService.instant('system_notes.'+noteText.action);
				}
				this.modelNotes[i].note = noteText;
				this.modelNotes[i].formattedDate = moment(this.modelNotes[i].date).format("LLLL");
			}

			if(this.modelNotes.length > this.limit) {
				this.splitNotes();
			}

			this.prepareShareOptions();
			this.prepareReminderOptions();
		});
	}

	splitNotes() {
		this.modelNotesExpand = this.modelNotes.slice(this.limit);
		this.modelNotes = this.modelNotes.slice(0, this.limit);
	}

	showActionModal(action, note?) {
		switch(action) {
			case 'add':
				this.notificationUsers = this.allNotificationUsers;
				this.thisNote = {
					newSiteNote : "",
					shareType: this.noteService.shareOptions.public,
					notificationUsers: [],
					reminderFreq: this.noteService.reminderFrequencyOptions.none
				};
				this.addNoteModal.show();
			break;
			case 'delete' :
				this.thisNote = note;
				this.deleteNoteModal.show();
			break;
			case 'reminder' :
				this.thisNote = {};
				this.thisNote = _.extend(this.thisNote, note);
				this.thisNote.oldReminderFreq = this.thisNote.reminder_freq;
				this.editReminderFreqModal.show();
			break;
			case 'share' :
				this.thisNote = {};
				this.thisNote = _.extend(this.thisNote, note);
				this.thisNote.oldShareType = this.thisNote.shareType;
				this.editShareTypeModal.show();
			break;
		}
	}

	onChangeShareType(shareType) {
		if(shareType != 'sct') {
			this.notificationUsers = _.extend([], this.allNotificationUsers);
			return;
		}

		let notificationUsers = [];
		let systemUserId = this.noteService.getSystemNoteUserInfo().id;
		this.allNotificationUsers.forEach(user => {
			if(user.group_type == 'sct' || user.id == systemUserId)
				notificationUsers.push(user);
		});
		this.notificationUsers = notificationUsers;
	}

	addNote() {
		if (!this.thisNote.newSiteNote.trim()) {
			return;
		}
		if (this.thisNote.shareType == this.noteService.shareOptions.private) {
			this.thisNote.notificationUsers = [];
		}

		this.noteService.addModelNotes(this.modelId, this.modelType, {
			noteText: this.thisNote.newSiteNote,
			siteId: this.siteId,
			shareType: this.thisNote.shareType,
			userNotificationList: _.pluck(this.thisNote.notificationUsers, 'id'),
			reminderFreq: this.thisNote.reminderFreq
		}).subscribe((res: any) => {
			if(res.api_status == 3) {
				return this.notificationMessageService.setMessage('translate|g.invalid_input', {clearOnXTimeNavigate: 1});
			}

			if(res.noteId) {
				this.notificationMessageService.setMessage('translate|note.success_note_add',{clearOnXTimeNavigate: 1, type: 'success'});
				this.getModelNotes();
			}
		});
	}

	deleteNote() {
		this.noteService.deleteModelNotes(this.thisNote.noteId, this.modelId, this.modelType, {
			siteId: this.siteId
		}).subscribe((res: any) => {
			if(res.deleted) {
				this.notificationMessageService.setMessage('translate|note.success_note_delete',{clearOnXTimeNavigate: 1, type: 'success'});
				this.getModelNotes();
			}
		});
	}

	editReminderFreq() {
		// No change
		if(this.thisNote.reminder_freq == this.thisNote.oldReminderFreq) {
			return;
		}

		this.noteService.updateReminderFreq(this.thisNote.noteId, this.modelId, this.modelType, this.thisNote.reminder_freq, {
			siteId: this.siteId
		}).subscribe((res: any) => {
			if(res.updated) {
				this.getModelNotes();
				this.notificationMessageService.setMessage('translate|note.success_note_update',{clearOnXTimeNavigate: 1, type: 'success'});
			}
		});
	}

	editShareType() {
		// No change
		if(this.thisNote.shareType == this.thisNote.oldShareType) {
			return;
		}

		this.noteService.updateShareType(this.thisNote.noteId, this.modelId, this.modelType, this.thisNote.shareType, {
			siteId: this.siteId
		}).subscribe((res: any) => {
			if(res.updated) {
				this.getModelNotes();
				this.notificationMessageService.setMessage('translate|note.success_note_update',{clearOnXTimeNavigate: 1, type: 'success'});
			}
		});
	}

	getShareTypeIcon(type) {
		let icons = {
			private 	: "fa fa-user fa-lg",
			public 		: "fa fa-globe fa-lg",
			sct 		: "fa fa-users fa-lg"
		};
		return icons[type];
	}

}
