import { Directive, ElementRef, Renderer2, OnInit, HostListener, EventEmitter, Input } from '@angular/core';
import { ValidationService } from '../services/validation.service';
import { NotificationMessageService } from '../notification-message/notification-message.service';
import { NgForm } from '@angular/forms';

@Directive({
	selector: '[validate-form]',
})
export class ValidateFormDirective implements OnInit {

	@Input('validate-form') formName;
	@Input('innerModelGroups') innerModelGroups = [];
	submitFunction: EventEmitter<any>;
	constructor(
		private el: ElementRef,
		private renderer: Renderer2,
		private validationService: ValidationService,
		private notificationMessage: NotificationMessageService,
		private form: NgForm
	) {
	}

	ngOnInit(){
		this.submitFunction	= this.form.ngSubmit;
		this.form.ngSubmit	= new EventEmitter;
	}

	@HostListener('submit', ['$event'])
	onSubmit(event: Event) {
		if(this.validateForm())
			this.submitFunction.emit();
	}

	validateForm() {
		this.notificationMessage.closeNotification();
		this.resetForm();
		let formValues = this.getFormValues();

		console.log(formValues, this.formName); // left intentionally
		let invalidFields = this.validationService.validateForm(formValues, this.formName);
		if(invalidFields.length > 0){
			console.log(invalidFields);
			this.highlightInvalidFields(invalidFields);
			return false;
		}
		return true;
	}

	getFormValues(){
		let formValues = {};
		for (let formControlName in this.form.value) {
			if(this.innerModelGroups.includes(formControlName)) // merging parent form with inner form values
				formValues = {...formValues, ...this.form.value[formControlName]};
			else
				formValues[formControlName] = this.form.value[formControlName];
		}
		return formValues;
	}

	highlightInvalidFields(invalidFields) {
		let form = this.el.nativeElement;
		for(let invalidField of invalidFields){
			let ele = form.querySelectorAll('[name="'+invalidField+'"]');
			if(ele && ele[0])
				this.renderer.addClass(ele[0], 'invalid-input');
		}
		this.notificationMessage.setMessage('translate|g.invalid_input', {timeout: 20000, numberOfNavigate: 1});
	}

	resetForm(){
		let invalidInputs = this.el.nativeElement.querySelectorAll('.invalid-input');
		invalidInputs.forEach((item) => {
			item.classList.remove('invalid-input');
		});
	}
}