<div class="row justify-content-md-center">
	<div class="col-md-8 bg-white">
		<div class="border-bottom p-3" *ngIf="!options.hideTitle">
			<h2>{{'contact_us.title' | translate}}</h2>
		</div>
		<div class="px-5 py-4">
			<form #contactUs="ngForm" (ngSubmit)="logContactUs()">
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'contact_us.subject' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<input class="form-control" name="subject" [(ngModel)]="form.subject"  placeholder="{{'contact_us.subject' | translate}}" required/>
					</div>
				</div>
				<div class="row mt-2">
					<div class="col-md-4">
						<strong>{{'contact_us.your_enquiry' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<textarea required rows="10" class="form-control" [(ngModel)]="form.body" name="body" placeholder="{{'contact_us.your_enquiry' | translate}}"></textarea>
					</div>
				</div>
				<div class="row mt-2 align-items-center" *ngIf="captchaEnabled">
					<div class="col-md-4">
					</div>
					<div class="col-md-8">
						<app-captcha #captchaComponent [textFieldCols]="5"></app-captcha>
					</div>
				</div>
				<div class="row my-4 align-items-center">
					<div class="col-md-4">
					</div>
					<div class="col-md-8">
						<div class="row align-items-center">
							<div class="col-md-8">
								<button
									type="submit"
									class="btn text-white main-blue-bg btn-lg col-5 mr-4"
									[disabled]="contactUs.invalid || captchaEnabled && (!captchaComponent || !captchaComponent.captchaText || captchaComponent.triesLimitReached)"
								>
									{{'g.submit' | translate}}
								</button>
								<button type="button" class="btn btn-light btn-lg col-5 mr-4" (click)="goBack()">{{'g.back' | translate}}</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>