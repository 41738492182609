import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { UsersService } from 'src/app/users/users.service';
import * as moment from 'moment';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
	selector: 'app-device-location-mismatch-report',
	templateUrl: './device-location-mismatch-report.component.html',
	styleUrls: ['./device-location-mismatch-report.component.css']
})
export class DeviceLocationMismatchReportComponent implements OnInit {
	locationReport: any[] = [];
	lastUpdateTime: any;
	columnDefs: any[] = [];
	allColumnsKeys: string[] = [];
	pageSize: number = 20
	fileName: string = '';

	constructor(
		private usersService: UsersService,
		private deviceService: DeviceService,
		private router: Router,
		private translate: TranslateService,
		private commonData: CommonDataService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessFunction('device_management', 'read'))
			this.router.navigate(['/unauthorized']);

		const isDev = window.location.hostname.includes(this.commonData.developmentDomain);
		if(isDev)
			this.pageSize = 5;
		this.getDevicesLocationMismatchReport();
	}

	getDevicesLocationMismatchReport() {
		this.deviceService.getDevicesLocationMismatchReport().subscribe((response: any) => {
			const tableData: any[] = [];
			response.forEach((row: any) => {
				const rowObj = {
					mac_address: row.mac_address,
					serial_number: row.serial_number,
					site_name: this.capitalizeFirstLetter(row.site_name),
					customer_name: this.capitalizeFirstLetter(row.customer_name),
					siteId: row.site_id,
					customerId: row.customer_id,
					last_connect_time: this.formatTime(row.last_connect_time),
					site_longitude: row.site_longitude,
					site_latitude: row.site_latitude,
					device_longitude: row.device_longitude,
					device_latitude: row.device_latitude,
					distance_m: row.distance_m.toFixed(0)
				}
				tableData.push(rowObj);
			});
			tableData.sort((A, B) => {
				return A.customer_name.localeCompare(B.customer_name);
			});
			this.locationReport = tableData;
			this.buildGrid();
			this.lastUpdateTime = this.formatTime(response?.lastUpdateTime);
		})
	}

	capitalizeFirstLetter(string) {
		if (!string)
			return '';

		if (string.length === 0)
			return string;

		return string.charAt(0).toUpperCase() + string.slice(1);
	}

	buildGrid() {
		const columnDefs: any[] = [
			{
				headerName: 'g.mac_address', field: 'mac_address',
				cellRendererSelector: function (params: any) {
					params['label'] = params.data.mac_address;
					params['link'] = ['/#', params.data.customerId, params.data.siteId, params.data.mac_address, 'performance'].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
			},
			{ headerName: 'g.serial_number', field: 'serial_number' },
			{
				headerName: 'g.customer_name', field: 'customer_name',
				cellRendererSelector: function (params: any) {
					params['label'] = params.data.customer_name	;
					params['link'] = ['/#',params.data.customerId].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				}
			},
			{
				headerName: 'g.site_name', field: 'site_name',
				cellRendererSelector: function (params: any) {
					params['label'] = params.data.site_name	;
					params['link'] = ['/#', params.data.customerId, params.data.siteId].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				}
			},
			{ headerName: 'devices.last_connect_time', field: 'last_connect_time' },
			{ headerName: 'devices.site_longitude', field: 'site_longitude' },
			{ headerName: 'devices.site_latitude', field: 'site_latitude' },
			{ headerName: 'devices.device_longitude', field: 'device_longitude' },
			{ headerName: 'devices.device_latitude', field: 'device_latitude' },
			{ headerName: 'devices.distance_m', field: 'distance_m' },
		];
		this.columnDefs = columnDefs;
		this.fileName = `${this.translate.instant('nav.devices_location_mismatch')} - ${moment().format('YYYY/MM/DD')}.csv`;
	}

	formatTime(last_connect_time: string | number | Date) {
		const fromDate = Math.round(new Date(new Date((+last_connect_time) * 1000).getTime()).getTime());
		return moment(new Date(fromDate)).format('MM/DD/YYYY HH:mm:ss A');
	}
}
