import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import * as lodash from 'lodash-es';
import { DeviceService } from '../../device.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';

@Component({
	selector: 'app-cellular-track',
	templateUrl: './cellular-track.component.html'
})
export class CellularTrackComponent implements OnInit, AfterViewInit {
	recordsLimit = 100;
	@Input() device: any = {};
	@Input() currentSite: any;
	@Input() dateRange: {
		fromDate: Date,
		toDate: Date
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	@Input() invalidDateRange: boolean = false;
	
	configsToCompare: any;
	gridColumns: any;
	trackModalData: any = {};
	compareModalData: any = {};
	gridData: any[] = null;

	cellularRSSIfields = [
		'cellular_rssi'
	];

	@ViewChild("serverPagination") serverPagination;
	@ViewChild("trackModal") trackModal;
	@ViewChild("compareModal") compareModal;
	
	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	constructor(
		private deviceService: DeviceService,
		private commonService: CommonService,
		private translateService: TranslateService
	) { }

	ngOnInit() {
		this.gridColumns = [
			{headerName: 'g.id', field: "id", type:"number", width: 120},
			{
				headerName: 'config.configs', field: "configs", width: 200,
				onCellClicked: ($e) => {
					this.onTrackCellClicked($e);
				},
				cellRendererSelector: (params) => {
					params['label'] = this.translateService.instant('config.show_configs');
					return {
						component: 'linkCellRenderer',
						params: params,
					};
				}
			},
			{headerName: 'g.insertion_time', field: "insertion_time", width: 200, type: 'dateTime'},
		];
	}

	ngAfterViewInit() {
		this.getCellularConfigTrack(false, true);
	}

	ngOnChanges(changes) {
		if (
			(changes.device && changes.device.previousValue && changes.device.previousValue.mac_address != changes.device.currentValue.mac_address) ||
			changes.dateRange
		) {
			this.getCellularConfigTrack(false, true);
		}
	}

	onTrackCellClicked(cycle) {
		this.trackModalData = this.formatChanges(cycle.data.configs);
		this.trackModal.show();
	}

	formatChanges(configs) {
		let zoneID = (this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id);
		return this.deviceService.formatQueuedChanges(this.device, configs, 'plainObject', zoneID);
	}

	getCellularConfigTrack(isBack=false, firstTime=false) {
		if(!this.device.mac_address || this.invalidDateRange || !this.serverPagination)
			return;

		let fromDateRange = this.dateRange.fromDate;
		let toDateRange = this.dateRange.toDate;
		fromDateRange = new Date(new Date(fromDateRange).setHours(0, 0, 0, 0));
		toDateRange = new Date(new Date(toDateRange).setHours(23, 59, 59, 999));

		let zoneDiff = new Date().getTimezoneOffset() * -1;
		let fromDate: any = new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000));
		let toDate: any = new Date(new Date(toDateRange).getTime() + (zoneDiff * 60 * 1000));
		fromDate = moment(fromDate).utc().startOf('day').unix();
		toDate = moment(toDate).utc().endOf('day').unix();

		if(firstTime)
			this.serverPagination.init();

		const lastId = this.serverPagination.lastId;
		const firstId = this.serverPagination.firstId;
		const limit = this.serverPagination.limit;

		this.deviceService.getCellularConfigTrack(this.device.mac_address, {fromDate, toDate, lastId, firstId, limit, isBack}).subscribe((response: any) => {
			response.forEach((record) => {
				record.configs.cellular_connected = this.translateService.instant('g.' + record.cellular_connected ? 'on' : 'off').toUpperCase();
				record.insertion_time = this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id ? this.device.config_info.zone_id : this.currentSite.zone_id), moment(record.insertion_time).utc().unix());
				record.insertion_time = moment(record.insertion_time * 1000).format('MM/DD/YYYY hh:mm:ss A');
				this.formatData(record.configs);
			});
			this.gridData = [...response];
			if (this.gridData.length > this.recordsLimit)
				this.gridData.pop();


			this.serverPagination.updatePagination(response, isBack, firstTime);
		});
	}

	gridEvent(event) {
		if(event.selection.length == 2)
			this.configsToCompare = event.selection;
		else
			this.configsToCompare = null;
	}

	formatData(recordConfigs) {
		for(let field in recordConfigs) {
			// format boolean fields
			if(this.deviceService.deviceBooleanFields.includes(field))
				recordConfigs[field] = !!recordConfigs[field];

			if (this.cellularRSSIfields.includes(field)) {
				recordConfigs[field] = this.commonService.mapCellularRssi(recordConfigs[field]);
			}
		}
	}
	
	compareConfigs() {
		let oldConfig		= {};
		let oldValsIdx		= 1;
		let newConfig		= lodash.cloneDeep(this.configsToCompare[0].configs);
		let ignoreFields	= ['id'];

		if(this.configsToCompare[1].configs.id > this.configsToCompare[0].configs.id) {

			newConfig	= lodash.cloneDeep(this.configsToCompare[1].configs);
			oldValsIdx	= 0;
		}

		for(let key in newConfig) {
			
			if(ignoreFields.includes(key))
				continue;
			
			let tempOld = this.configsToCompare[oldValsIdx].configs[key];
			if(tempOld != newConfig[key])
				oldConfig[key] = tempOld;
		}
		
		this.compareModalData = {
			'old': this.formatChanges(oldConfig),
			'new': this.formatChanges(newConfig)
		};
		
		this.compareModal.show();
	}

	isObject(value) {
		return typeof value == 'object' && value && Object.keys(value).length > 0;
	}
}