import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { Sort } from '@angular/material/sort';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
	selector: 'app-disable-xtra-file',
	templateUrl: './disable-xtra-file.component.html',
	styleUrls: ['./disable-xtra-file.component.css']
})
export class DisableXTRAFileComponent implements AfterViewInit {
	@ViewChild("restartDeviceConfirm") restartDeviceConfirm;

	pageSizeOptions: number[] = [100];
	pageSize: number = this.pageSizeOptions[0];
	currentPage: number = 1;

	toShowDevices: any = [];
	sortResult: any[] = [];
	filteredResult: any[] = [];
	devices: any[] = [];

	selectedDevices: number[] = [];

	sort =  { active: "serial_number", direction: "asc" };
	filter = {
		serial_number: "",
		customer_name: "",
		site_name: "",
		user_name: ""
	};

	timeFields = ['disable_since'];
	stringOrNumberFields = ['serial_number', 'customer_name', 'site_name', 'user_name'];

	constructor(
		public usersService: UsersService,
		private devicesService: DeviceService,
		private router: Router,
		private commonService: CommonService,
		public translate: TranslateService,
		private sideMenuService: SideMenuService,
		private notificationMessage: NotificationMessageService,
		private commonData: CommonDataService,
	) {}

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			this.router.navigate(['/unauthorized']);

		const isDev = window.location.hostname.includes(this.commonData.developmentDomain);

		if (isDev) {
			this.pageSizeOptions = [4];
			this.pageSize = this.pageSizeOptions[0];
		}

		this.sideMenuService.hide();
		this.getDevices();
	}

	ngAfterViewInit() {
		this.restartDeviceConfirm.onClose.subscribe((ok) => {
			if (ok)
				this.enableExtraFile();

			this.restartDeviceConfirm.hide();
		});
	}

	getDevices() {
		this.devicesService.getDisableXTRAFileDevices().subscribe((res: any) => {
			this.devices = res;
			this.sortResult = this.devices;
			this.filterAndSortData();
		})
	}

	showUserTimeZoneReference() {
		const userTimeZone = moment().utcOffset();
		return moment().utcOffset(userTimeZone).format('UTCZ');
	}

	filterAndSortData(sort?: Sort) {
		if (sort)
			this.sort = sort;

		this.filteredResult = this.filterData();
		const data = this.filteredResult.slice();

		if (!this.sort.active || this.sort.direction === "") {
			this.filteredResult = data;
			return;
		}

		this.sortData(this.sort, data);
	}

	sortData(sort: any, toSortData: any[]) {
		const data = toSortData.slice();

		if (!sort.active || sort.direction === '') {
			this.sortResult = data;
			return;
		}

		this.sortResult = data.sort((a, b) => this.sortFunction(sort, a, b));
		this.updateDevices()
	}

	filterData() {
		const data = this.devices.filter((device) => {
			const includeSite = device.site_name.toLowerCase().indexOf(this.filter.site_name.toLowerCase()) !== -1;
			const includeCustomer = device.customer_name.toLowerCase().indexOf(this.filter.customer_name.toLowerCase()) !== -1;
			const includeSerialNumber = device.serial_number.toLowerCase().indexOf(this.filter.serial_number.toLowerCase()) !== -1;
			const includeUser = typeof device?.user_name === 'string' && device.user_name.toLowerCase().indexOf(this.filter.user_name.toLowerCase()) !== -1;

			return (
				(this.filter.site_name == "" || includeSite) &&
				(this.filter.customer_name == "" || includeCustomer) &&
				(this.filter.serial_number == "" || includeSerialNumber) &&
				(this.filter.user_name == "" || includeUser)
			);
		});

		return data;
	}

	sortFunction(sort: any, a:any, b:any) {
		const { compare } = this.commonService;

		const isAsc = sort.direction === 'asc';
		const field = sort.active;

		let aValue = a[field];
		let bValue = b[field];

		if (field == 'disable_since') {
			aValue = aValue || a.queued_since;
			bValue = bValue || b.queued_since;
		}

		if (this.timeFields.includes(field))
			return compare(this.formateDateForCompare(aValue), this.formateDateForCompare(bValue), isAsc);

		if (this.stringOrNumberFields.includes(field))
			return compare(aValue, bValue, isAsc);

		return 0;
	}

	formateDateForCompare(date: moment.MomentInput) {
		return date ? moment(date).utc().format('YYYY/MM/DD HH:mm:ss'): '-';
	}

	updateDevices() {
		const startIndex = (this.currentPage - 1) * this.pageSize;
		const endIndex = startIndex + this.pageSize;
		this.toShowDevices = (this.sortResult || []).slice(startIndex, endIndex);
	}

	onPageChange(event) {
		this.currentPage = event.pageIndex + 1;
		this.updateDevices();
	}

	generateTableCsvFile() {
		const csvRows = [];
		const columnNames = [
			this.translate.instant('devices.serial_number'),
			this.translate.instant('g.customer_name'),
			this.translate.instant('g.site_name'),
			`${this.translate.instant('g.disabled_since')} ${this.showUserTimeZoneReference()}`,
		];

		csvRows.push(columnNames);

		(this.sortResult || []).forEach(row => {
			csvRows.push( [
				row.serial_number,
				row.customer_name,
				row.site_name,
				this.getDisabledSinceText(row),
			]);
		})

		new AngularCsv(csvRows, this.translate.instant('nav.disable_xtra_file'));
	}

	getDisabledSinceText(device) {
		return device.queued_since  ? `${this.translate.instant('g.queued_since')} ${this.formateDate(device.queued_since)}` : this.formateDate(device.disable_since);
	}

	formateDate(date: moment.MomentInput) {
		return date ? moment(date).utc().format('MM/DD/yyyy'): '-';
	}

	selectDevice(selectMacAddress) {
		if (this.selectedDevices.includes(selectMacAddress))
			return this.selectedDevices = this.selectedDevices.filter(macAddress => macAddress != selectMacAddress);

		return this.selectedDevices.push(selectMacAddress);
	}

	enableExtraFile() {
		this.devicesService.enableExtraFile(this.selectedDevices).subscribe((res: any) => {
			return this.notificationMessage.setMessage('translate|g.command_is_queued', {clearOnXTimeNavigate: 1, type: 'success'});
		})
	}
}
