<div class="table-control-container d-flex justify-content-end align-items-center my-2">
	<fa-icon *ngIf="config.hasExport" class="icon mr-2" icon="upload" size="lg" (click)="generateCsvFile()"></fa-icon>
	<fa-icon *ngIf="!config.hideColumnControl" class="icon" icon="table" size="lg" (click)="toggleHidePopup()"></fa-icon>
	<div class="hide-columns-popup" *ngIf="isHidePopupShown">
		<ul>
			<li *ngFor="let column of columnsConfig" (click)="toggleColumnVisibility(column)">
				<fa-icon *ngIf="!hideFromListTypes.includes(column.type)" [icon]="['far', column.hidden ? 'square' :'check-square']" size="sm" class="mr-2"></fa-icon>
				<span *ngIf="!hideFromListTypes.includes(column.type)">{{column.name}}</span>
			</li>
		</ul>
	</div>
</div>
<div class="hide-columns-popup-shadow" *ngIf="isHidePopupShown" (click)="toggleHidePopup()"></div>

<div class="table_container" [class]="{'fit_screen': config.fitScreen}">
	<table class="table table-striped"
		matSort (matSortChange)="sortData($event)"
		[matSortActive]="columnsToSort.join(' ,')"
		matSortDirection="asc"
		matSortDisableClear
	>
		<thead>
			<tr>
				<th *ngFor="let column of shownColumnsConfig"
						[draggable]="config.draggable"
						(dragstart)="config.draggable && drag($event)"
						(dragenter)="config.draggable && drop($event)"
						(dragover)="config.draggable && allowDrop($event)">

					<!-- Sort -->
					<div class="header-container" *ngIf="columnsToSort.includes(column.key); else noSort" [mat-sort-header]=" column.key">
						<ng-container *ngTemplateOutlet="columnTemplate; context: { column: column }"></ng-container>
					</div>

					<ng-template #noSort class="header-container">
						<ng-container *ngTemplateOutlet="columnTemplate; context: { column: column }"></ng-container>
					</ng-template>
				</th>
			</tr>
			<tr *ngIf="columnsToFilter.length">
				<th *ngFor="let column of shownColumnsConfig">
					<div class="filter-container" *ngIf="hasFilterTypes.includes(column.type) && columnsToFilter.includes(column.key); else noFilter">
						<div [ngSwitch]="column.type">
							<!-- Date Filter -->
							<div *ngSwitchCase="'date_with_ref'">
								<ng-container *ngTemplateOutlet="timeRangeTemplate;
									context: {fromName: 'from_' + column.key, toName: 'to' + column.key, fromId: 'from_' + column.key, toId: 'to' + column.key, range: timeFilter[column.key], column: column}"
								></ng-container>
							</div>

							<div *ngSwitchCase="'date'">
								<ng-container *ngTemplateOutlet="timeRangeTemplate;
									context: {fromName: 'from_' + column.key, toName: 'to' + column.key, fromId: 'from_' + column.key, toId: 'to' + column.key, range: timeFilter[column.key], column: column}"
								></ng-container>
							</div>

							<div *ngSwitchCase="'timestamp'">
								<ng-container *ngTemplateOutlet="timeRangeTemplate;
									context: {fromName: 'from_' + column.key, toName: 'to' + column.key, fromId: 'from_' + column.key, toId: 'to' + column.key, range: timeFilter[column.key], column: column}"
								></ng-container>
							</div>

							<!-- Boolean Filter -->
							<div *ngSwitchCase="'boolean'">
								<ng-select
									[items]="[{value: true, result: column.filterTrueText || ('g.yes' | translate)}, {value: false, result: column.filterFalseText || ('g.no' | translate)}]"
									bindLabel="result"
									bindValue="value"
									[name]="column.key"
									[(ngModel)]="booleanFilter[column.key]"
									multiple="true"
									(change)="filter(column, $event)"
								>
								</ng-select>
							</div>

							<!-- Select -->
							<div *ngSwitchCase="'select'" class="form-check pointer">
								<input class="form-check-input" type="checkbox" [checked]="selectAll" (click)="toggleSelectAll()">
							</div>

							<!-- Text Filter -->
							<div *ngSwitchDefault>
								<input type="text" [name]="column.key" [id]="column.key"
									(input)="filter(column, $event)" class="filter_field"
									[placeholder]="'g.filter' | translate"
								>
							</div>
						</div>

					</div>
					<ng-template #noFilter><div class="filter_shadow"></div></ng-template>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let rowData of toShowData">
				<td *ngFor="let column of shownColumnsConfig" [class]="{'hover-effect': column.type == 'hover' }">
					<div [ngSwitch]="column.type" class="data-cell" [style.--color]="rowData[column.key]?.backGroundColor || 'transparent'">
						<!-- Icon -->
						<div *ngSwitchCase="'icon'">
							<fa-icon class="icon"
								[style.--color]="rowData[column.key]?.iconColor || '#007bff'"
								[icon]="rowData[column.key].icon" size="lg"
								(click)="rowData[column.key].action?.(rowData)"
							></fa-icon>
						</div>

						 <!-- Links -->
						<ng-container *ngSwitchCase="'link'">
							<span *ngIf="!rowData[column.key].link">
								{{ format(rowData[column.key].value, column) }}
							</span>
							<a *ngIf="rowData[column.key].link" [routerLink]="rowData[column.key].link">
								{{ format(rowData[column.key].value, column) }}
							</a>
						</ng-container>

						<ng-container *ngSwitchCase="'pointer'">
							<div class="pointer" *ngIf="rowData[column.key].value" (click)="rowData[column.key].action?.(rowData)">
								{{ format(rowData[column.key].value, column) }}
							</div>
						</ng-container>

						<!-- DropDown -->
						<div *ngSwitchCase="'dropdown'">
							<div class="btn-group mx-3" dropdown placement="left" *ngIf="rowData[column.key]?.options.length">
								<fa-icon icon="cog" size="lg" dropdownToggle class="dropdown-toggle"></fa-icon>
								<ul *dropdownMenu class="dropdown-menu" role="menu">
									<li role="menuitem">
										<a
											*ngFor="let option of rowData[column.key]?.options"
											class="dropdown-item pointer"
											(click)="option.action?.(rowData)"
										>
											{{option.text}}
										</a>
									</li>
								</ul>
							</div>
						</div>

						<!-- Boolean -->
						<div *ngSwitchCase="'boolean'">{{rowData[column.key].value ? rowData[column.key].trueText || ('g.yes' | translate) : rowData[column.key].falseText || ('g.no' | translate)}}</div>
						<div *ngSwitchCase="'list'">
							<ul>
								<li *ngFor="let value of rowData[column.key].value">{{value}}</li>
							</ul>
						</div>

						<div *ngSwitchCase="'hover'" class="pointer" (click)="rowData[column.key].action?.(rowData)">{{rowData[column.key].value}}</div>

						<!-- Select Field -->
						<div *ngSwitchCase="'select'" class="form-check pointer">
							<input class="form-check-input" type="checkbox" [checked]="rowData[column.key].selected" (click)="toggleSelectedRow(rowData.id.value)">
						</div>

						<!-- Another -->
						<div *ngSwitchDefault>{{format(rowData[column.key].value, column)}}</div>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>

<mat-paginator
	*ngIf="data.length && config.hasPagination"
	[length]="filteredData.length"
	[pageSize]="config.pageSize"
	[pageSizeOptions]="config.pageSizeOptions || [config.pageSize || 20]"
	[showFirstLastButtons]="true"
	(page)="onPageChange($event)">
</mat-paginator>

<!-- column name -->
<ng-template #columnTemplate let-column="column">
	<div [ngSwitch]="column.type">
		<div *ngSwitchCase="'date_with_ref'" [class]="{'wide': config.draggable}">
			{{ column.name }} ({{commonService.showUserTimeZoneReference()}})
		</div>
		<div *ngSwitchCase="'select'" class="select">
			{{ column.name }}
		</div>
		<div *ngSwitchDefault [class]="{'wide': config.draggable}">{{ column.name }}</div>
	</div>
</ng-template>

<!-- Time Filter -->
<ng-template #timeRangeTemplate let-fromName="fromName" let-toName="toName" let-fromId="fromId" let-toId="toId" let-range="range" let-column="column">
	<div>
		<input class="form-control"
			type="date"
			[name]="fromName"
			[id]="fromId"
			placeholder="from"
			[(ngModel)]="range.from"
			(input)="filter(column, $event)">
		<input class="form-control"
			type="date"
			[name]="toName"
			[id]="toId"
			placeholder="to"
			[(ngModel)]="range.to"
			(input)="filter(column, $event)">
	</div>
</ng-template>
