<div ngModelGroup="site" class="col-sm-12 card-body">
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<strong>{{'site_edit.name' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<input #siteNameComponent [disabled]="site.is_special" required pattern="[^\s].*" type="text" class="form-control" [(ngModel)]="site.name" placeholder="Name" name="name" autofocus/>
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<strong>{{'site_edit.country' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<input required type="text" class="form-control" [readonly]="true" [(ngModel)]="site.country_id" placeholder="Country" name="country_id" />
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<strong>{{'site_edit.state' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<input required type="text" class="form-control" [readonly]="true" [(ngModel)]="site.state_id" placeholder="State" name="state_id" />
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<strong>{{'site_edit.address' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<input required type="text" name="address" class="form-control" [readonly]="true" [(ngModel)]="site.address" placeholder="Address" />
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<strong>{{'site_edit.address_line2' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<input type="text" name="address_line2" class="form-control" [(ngModel)]="site.address_line2" placeholder="Apartment, building, floor" />
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<strong>{{'site_edit.latitude' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<input required type="text" name="latitude" class="form-control" [readonly]="true" [(ngModel)]="site.latitude" placeholder="Latitude" />
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<strong>{{'site_edit.longitude' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<input required type="text" name="longitude" class="form-control" [readonly]="true" [(ngModel)]="site.longitude" placeholder="Longitude" />
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<strong>{{'site_edit.time_zone' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<ng-select [items]="TimeZonesMenu" bindLabel="display_name" bindValue="id" placeholder="Select Time Zone" name="zone_id" [(ngModel)]="site.zone_id" [clearable]="false" required>
			</ng-select>
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<strong>{{'site_edit.installation_date' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<div class="input-group">
				<input class="form-control" placeholder="mm/dd/yyyy" name="installation_date" [minDate]="minInstallationDate" [(ngModel)]="site.installation_date" bsDatepicker #d="bsDatepicker" [bsConfig]="{ containerClass: 'theme-dark-blue' }" required>
				<span class="input-group-btn">
					<button class="btn btn-default btn-date" (click)="d.toggle()" type="button"><i class="fa fa-calendar"></i></button>
				</span>
			</div>
		</div>
	</div>
	<div class="row mt-2 align-items-center" *ngIf="enterpriseList.length > 0">
		<div class="col-md-3">
			<strong>{{'site_edit.enterprise_group' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<ng-select [multiple]="true" id="enterprise_ids" [items]="enterpriseList" bindLabel="name" bindValue="id" placeholder="{{'site_edit.select_enterprise_group' | translate}}" name="enterprise_ids" [(ngModel)]="site.enterprise_ids" [clearable]="false" required>
			</ng-select>
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-3">
			<strong>{{'customer.customer_enterprises' |translate}}</strong>
		</div>
		<div class="col-md-9">
			<ng-select 
				[items]="customerEnterprises" 
				bindLabel="name"
				bindValue="id"
				id="customer_enterprises"
				name="customer_enterprises"
				[clearable]="false"
				placeholder="{{'customer.customer_enterprises' | translate}}"
				[(ngModel)]="site.customer_enterprises"
				multiple="true"
				[disabled]="true"
			></ng-select>
		</div>
	</div>
	<div class="row mt-2 align-items-center" *ngIf="enterpriseList.length > 0">
		<div class="col-md-3">
			<strong>{{'site_edit.is_verified' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<div class="form-check form-check-inline slide-toggle-div">
				<mat-slide-toggle [(ngModel)]="site.is_verified" name="is_verified"></mat-slide-toggle>
			</div>
		</div>
	</div>
	<div class="row mt-2 align-items-center" *ngIf="enterpriseList.length > 0">
		<div class="col-md-3">
			<strong>{{'site_edit.is_test_site' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<div class="form-check form-check-inline slide-toggle-div">
				<mat-slide-toggle [(ngModel)]="site.test_site" name="test_site"></mat-slide-toggle>
			</div>
		</div>
	</div>
	<div class="row mt-2 align-items-center" *ngIf="enterpriseList.length > 0">
		<div class="col-md-3">
			<strong>{{'site_edit.is_special_site' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<div class="form-check form-check-inline slide-toggle-div">
				<mat-slide-toggle [disabled]="restrictSpecialSite" [(ngModel)]="site.is_special" name="is_special"></mat-slide-toggle>
			</div>
		</div>
	</div>
	<div class="row mt-2 align-items-center" *ngIf="usersService.hasAccessFunction('official_reporting') || usersService.hasAccessPermission(null, 'noc','read')">
		<div class="col-md-3">
			<strong>{{'site_edit.is_smart_rebates_site' | translate}}</strong>
		</div>
		<div class="col-md-9">
			<div class="form-check form-check-inline slide-toggle-div">
				<mat-slide-toggle [disabled]=" !usersService.hasAccessFunction('official_reporting') && !usersService.hasAccessPermission(null, 'noc', 'write')" [(ngModel)]="site.is_smart_rebates" name="is_smart_rebates"></mat-slide-toggle>
			</div>
		</div>
	</div>
	<fieldset class="mt-3" *ngIf="!miniForm">
		<legend>{{'site_edit.site_contact_info' | translate}}</legend>
		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong>{{'site_edit.name' | translate}}</strong>
			</div>
			<div class="col-md-9">
				<input type="text" class="form-control" name="contact_name" [(ngModel)]="site.contact_name" placeholder="Person Name" />
			</div>
		</div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong>{{'site_edit.phone' | translate}}</strong>
			</div>
			<div class="col-md-9">
				<input type="text" class="form-control" name="phone_num" [(ngModel)]="site.phone_num" placeholder="Phone No." />
			</div>
		</div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong>{{'site_edit.email' | translate}}</strong>
			</div>
			<div class="col-md-9">
				<input type="email" class="form-control" name="email" [(ngModel)]="site.email" placeholder="Email" />
			</div>
		</div>
	</fieldset>
	<fieldset class="mt-3">
		<legend>{{'site.electrician_contact_info' |translate}}</legend>
		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong>{{'site_edit.name' | translate}}</strong>
			</div>
			<div class="col-md-9">
				<input type="text" class="form-control" name="electrician_contact_name" [(ngModel)]="site.electrician_contact_name" placeholder="Name" />
			</div>
		</div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong>{{'site_edit.phone' | translate}}</strong>
			</div>
			<div class="col-md-9">
				<input type="text" class="form-control" name="electrician_phone_num" [(ngModel)]="site.electrician_phone_num" placeholder="Phone No." />
			</div>
		</div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong>{{'site_edit.email' | translate}}</strong>
			</div>
			<div class="col-md-9">
				<input type="email" class="form-control" name="electrician_email" [(ngModel)]="site.electrician_email" placeholder="Email" />
			</div>
		</div>
	</fieldset>
	<fieldset class="mt-3">
		<legend>{{'site.sales_person_info' | translate}}</legend>
		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong>{{'site_edit.name' | translate}}</strong>
			</div>
			<div class="col-md-9">
				<input type="text" class="form-control" name="sales_person_name" [(ngModel)]="site.sales_person_name" placeholder="Name" />
			</div>
		</div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong>{{'site_edit.phone' | translate}}</strong>
			</div>
			<div class="col-md-9">
				<input type="text" class="form-control" name="sales_person_phone_num" [(ngModel)]="site.sales_person_phone_num" placeholder="Phone No." />
			</div>
		</div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-3">
				<strong>{{'site_edit.email' | translate}}</strong>
			</div>
			<div class="col-md-9">
				<input type="text" class="form-control" name="sales_person_email" [(ngModel)]="site.sales_person_email" placeholder="Email" />
			</div>
		</div>
	</fieldset>
</div>
