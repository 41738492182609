import { Component, OnInit } from '@angular/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

@Component({
	selector: 'app-page-not-found',
	templateUrl: './page-not-found.component.html',
	styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

	constructor(
		private sideMenuService: SideMenuService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
	}
}