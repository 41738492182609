import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { UsersService } from '../../users/users.service';
import { DeviceManagementService } from '../device-management.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { SitesService } from 'src/app/sites/sites.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { HeaderService } from 'src/app/shared/header/header.service';
import {Sort} from '@angular/material/sort';
import { CommonService } from 'src/app/shared/services/common.service';
@Component({
	selector: 'app-search-devices',
	templateUrl: './search-devices.component.html',
	styleUrls: ['./search-devices.component.css']
})
export class SearchDevicesComponent implements OnInit, OnDestroy, AfterViewInit {
	connectivityTooltipMessages: string;
	currentUser:any = {};
	searchString: string = '';
	searchBy: string = 'sn'; // sn: serial number, mac: mac address
	searchDeleted: string = ''; // sn: serial number, mac: mac address
	searchType: string = 'normal-search';
	isPartial: boolean = false;
	searchResult: any[] = null;
	filteredResult: any[] = null;
	sites: any = [];
	hasNOC = false;
	showConnectivityOptions = false;
	showDeviceTypeOptions = false;
	showFilters = false;
	connectivityStatus: { link: string; selected: boolean; wifi: boolean }[] = [
		{ link: "/images/lte/lte_BAD.png", selected: true, wifi: false },
		{
			link: "/images/lte/lte_disconnected_30d.png",
			selected: true,
			wifi: false,
		},
		{
			link: "/images/lte/lte_disconnected_7d.png",
			selected: true,
			wifi: false,
		},
		{
			link: "/images/lte/lte_disconnected_3d.png",
			selected: true,
			wifi: false,
		},
		{ link: "/images/lte/lte_connected.png", selected: true, wifi: false },
		{ link: "/images/wifi_connected.png", selected: true, wifi: true },
		{ link: "/images/wifi_not_connected_1d.png", selected: true, wifi: true },
		{
			link: "/images/wifi_not_connected_7d_orange.png",
			selected: true,
			wifi: true,
		},
		{ link: "/images/wifi_not_connected_red.png", selected: true, wifi: true },
	];
	devicesTypes: { ischargeLink: boolean; value: string; selected: boolean }[] = [
		{ ischargeLink: false, value: "g.iotah", selected: true },
		{ ischargeLink: true, value: "g.charglink", selected: true },
	];
	activeConnectivityFilter = false;
	activeDeviceFilter = false;
	filter = {
		customer_name: "",
		site_name: "",
	};
	searchFilterLocalStorageKey = "searchFilter";
	sort =  { active: "serial_number", direction: "asc" };

	@ViewChild("confirmModal") confirmModal;

	constructor(
		public usersService: UsersService,
		private deviceManagementService: DeviceManagementService,
		private router: Router,
		private route: ActivatedRoute,
		private sideMenuService: SideMenuService ,
		public deviceService: DeviceService,
		public sitesService: SitesService,
		private notificationMessage: NotificationMessageService,
		private header: HeaderService,
		private commonService:CommonService,
	) {
		this.header.toggleSearchBox(false);
	}

	ngOnInit() {
		this.sideMenuService.hide();
		this.currentUser = this.usersService.getCurrentUser();
		this.hasNOC = this.usersService.hasAccessPermission(null, "noc");
		this.getFilterOptions();
		this.route.queryParams.subscribe(params => {
			if(params.searchString) {
				this.searchString = params.searchString;
				this.searchBy = params.searchBy;
				this.isPartial = true;
				this.getDevices();
			}
		});
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			if(result[1][0] == 'deleteDailyDetails') {
				return this.deleteDailyDetails(result[1][1], result[1][2]);
			}

			if(result[1][0] == 'regenerateDailyDetails') {
				return this.regenerateDailyDetails(result[1][1], result[1][2]);
			}
			if(result[1][0] == 'regenerateDevicePerformance') {
				return this.regenerateDevicePerformance(result[1][1]);
			}
			if(result[1][0] == 'resetDevice') {
				return this.resetDevice(result[1][1]);
			}
			if (result[1][0] == 'regenerateDeviceAnalyticsReport') {
				return this.regenerateDeviceAnalyticsReport(result[1][1], result[1][2])
			}
		});
	}

	confirmAction(action, data1, data2?) {
		this.confirmModal.passData = [action, data1, data2];
		switch(action) {
			case 'deleteDailyDetails':
				this.confirmModal.message = 'devices.delete_daily_details_prompt';
				break;
			case 'regenerateDailyDetails':
				this.confirmModal.message = 'devices.regenerate_daily_details_prompt';
				break;
			case 'regenerateDevicePerformance':
				this.confirmModal.message = 'devices.regenerate_performance_prompt';
				break;
			case 'resetDevice':
				this.confirmModal.message = 'g.reset_device_after_hard_delete_prompt';
				break;
			break;
			case 'regenerateDeviceAnalyticsReport':
				this.confirmModal.message = 'devices.regenerate_device_analttics_report_prompt';
			break;
		}
		this.confirmModal.show();
	}

	filterAndSortData(sort?: Sort) {
		if (sort)
			this.sort = sort;

		this.filteredResult = this.filterData();

		const data = this.filteredResult.slice();
		const { compare } = this.commonService;
		if (!this.sort.active || this.sort.direction === "") {
			this.filteredResult = data;
			return;
		}

		this.filteredResult = data.sort((a, b) => {
			const isAsc = this.sort.direction === "asc";
			switch (this.sort.active) {
				case "serial_number":
					return compare(a.serial_number, b.serial_number, isAsc);
				default:
					return 0;
			}
		});
	}

	filterData() {
		const selectedConnectivity = this.connectivityStatus
			.filter((status) => status.selected)
			.map((status) => status.link);

		const selectedDeviceTypes = this.devicesTypes
			.filter((type) => type.selected)
			.map((type) => type.ischargeLink);

		this.updateFilterOptions();
		const data = this.searchResult.filter((device) => {
			const siteName = this.sites[device.site_id]?.name;
			const customerName = this.sites[device.site_id]?.customer_name;

			const filterSiteName = this.filter.site_name.toLowerCase().trim();
			const filterCustomerName = this.filter.customer_name.toLowerCase().trim();

			const includeSite = siteName ? siteName.toLowerCase().indexOf(filterSiteName) !== -1 : false;
			const includeCustomer = customerName ? customerName.toLowerCase().indexOf(filterCustomerName) !== -1: false;

			return (
				(this.filter.site_name == "" || includeSite) &&
				(this.filter.customer_name == "" || includeCustomer) &&
				(selectedConnectivity.includes(device.wifiConnectivityStatus) || selectedConnectivity.includes(device.connectivityStatus)) &&
				selectedDeviceTypes.includes(!!device.is_charglink)
			);
		});

		return data;
	}

	clearSearch() {
		localStorage.removeItem(this.searchFilterLocalStorageKey);

		this.filter = {customer_name: '', site_name: ''};
		this.devicesTypes.forEach(type => type.selected = true);
		this.connectivityStatus.forEach(status => status.selected = true) ;

		this.activeDeviceFilter = false;
		this.activeConnectivityFilter = false;

		this.filterAndSortData();
	}

	getDevices(updateRoute?) {
		this.searchString = this.searchString.trim();
		if(!this.searchString)
			return;

		this.deviceManagementService.searchDevices(this.searchString, this.searchBy, this.isPartial, this.searchDeleted, this.searchType).subscribe((data: any) => {
				this.searchResult = this.deviceService.addConnectivityClasses(data.nowTime, data.devices);
				this.searchResult.forEach(res => res.connectivityTooltipMessages = this.deviceService.getLastConnectionTimeMassage(res))
				this.sites = data.sites;
				this.filterAndSortData();
				if (updateRoute) {
					this.router.navigate([], {
						relativeTo: this.route,
						queryParams: {searchString: this.searchString, searchBy: this.searchBy},
						queryParamsHandling: 'merge'
					});
				}
			});
	}

	getFilterOptions() {
		const filterOptions = localStorage.getItem(
			this.searchFilterLocalStorageKey
		);

		if (filterOptions) {
			const filterOptionsObject = JSON.parse(filterOptions);
			this.connectivityStatus.forEach(status => status.selected = filterOptionsObject.connectivityStatus.includes(status.link)) ;
			this.devicesTypes.forEach(type => type.selected = filterOptionsObject.devicesTypes.includes(type.ischargeLink));
			this.filter = filterOptionsObject.filter;
			this.sort = filterOptionsObject.sort || { active: "serial_number", direction: "asc" };
		}

		this.activeConnectivityFilter = this.connectivityStatus.some(
			(option) => option.selected == false
		);

		this.activeDeviceFilter = this.devicesTypes.some(
			(option) => option.selected == false
		);
	}

	updateFilterOptions() {
		localStorage.setItem(
			this.searchFilterLocalStorageKey,
			JSON.stringify({
				connectivityStatus: this.connectivityStatus.filter((status) => status.selected).map((status) => status.link),
				devicesTypes: this.devicesTypes.filter((type) => type.selected).map((type) => type.ischargeLink),
				filter: this.filter,
				sort: this.sort
			})
		);
	}

	deleteDailyDetails(macAddress, siteId) {
		this.deviceManagementService.deleteDailyDetails(macAddress, siteId).subscribe((data: any) => {
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			});
	}

	regenerateDailyDetails(macAddress, siteId) {
		this.deviceManagementService.regenerateDailyDetails(macAddress, siteId).subscribe(() => {
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			});
	}

	regenerateDeviceAnalyticsReport(macAddress, siteId) {
		this.deviceManagementService.regenerateDeviceAnalyticsReport(macAddress, siteId).subscribe(() => {
			this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		});
	}

	regenerateDevicePerformance(siteId) {
		this.deviceManagementService.regenerateDevicePerformance(siteId).subscribe(() => {
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			});
	}

	resetDevice(mac_address: string) {
		this.deviceManagementService.cmResetDeviceAfterDelete(mac_address).subscribe(() => {
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			});
	}

	toggleConnectivityStatusList() {
		this.showConnectivityOptions = !this.showConnectivityOptions;
	}

	toggleDeviceType() {
		this.showDeviceTypeOptions = !this.showDeviceTypeOptions;
	}

	toggleShowFilters() {
		this.showFilters = !this.showFilters;
	}

	toggleConnectivityStatus(status: {
		link: string;
		selected: boolean;
		wifi: boolean;
	}) {
		status.selected = !status.selected;
		this.activeConnectivityFilter = this.connectivityStatus.some(
			(option) => option.selected == false
		);
		this.filterAndSortData();
	}

	toggleDeviceTypeOptions(type: { ischargeLink: boolean; value: string; selected: boolean;}) {
		type.selected = !type.selected;
		this.activeDeviceFilter = this.devicesTypes.some((option) => option.selected == false);
		this.filterAndSortData();
	}

	clearConnectivityStatusFilter() {
		if (this.activeConnectivityFilter)
			this.connectivityStatus.forEach((status) => (status.selected = true));
		else this.connectivityStatus.forEach((status) => (status.selected = false));

		this.activeConnectivityFilter = !this.activeConnectivityFilter;
		this.filterAndSortData();
	}

	clearDeviceTypeFiler() {
		if (this.activeDeviceFilter)
			this.devicesTypes.forEach((status) => (status.selected = true));
		else this.devicesTypes.forEach((status) => (status.selected = false));

		this.activeDeviceFilter = !this.activeDeviceFilter;
		this.filterAndSortData();
	}

	ngOnDestroy() {
		this.header.toggleSearchBox(true);
		localStorage.removeItem(this.searchFilterLocalStorageKey);
	}
}
