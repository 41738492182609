<div class="row justify-content-md-center">
	<div class="col-md-8 bg-white">
		<div class="border-bottom p-3">
			<h2>{{'orders.order' | translate}}</h2>
		</div>
		<div class="px-5 py-4">
			<form #editOrderForm="ngForm" (ngSubmit)="saveOrder()" [validate-form]="'orders'" (keydown.enter)="$event.preventDefault()">
				<div *ngIf="action !== 'add'" class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'sim.status' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<ng-select
							[items]="ordersService.getOrdersStatusesOptions()"
							bindLabel="label"
							bindValue="value"
							name="status"
							placeholder="{{'sim.status' | translate}}"
							[(ngModel)]="order.status"
							[clearable]="false"
							[disabled]="true"
							required
						>
						</ng-select>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'g.devices_count' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<input type="number" placeholder="{{'g.devices_count' | translate}}" class="form-control" name="devices_count" min="0" [disabled]="isFormDisabled()" [(ngModel)]="order.devices_count" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'g.purchase_order' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'g.purchase_order' | translate}}" [class]="poFieldClasses" [disabled]="isPoNumberDisabled(order.status)" name="po_number" [(ngModel)]="order.po_number" required/>
					</div>
				</div>
				<div *ngIf="shipmentTrackingFieldsVisibility(order.status || 0)" class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'orders.shipment_tracking_number' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'orders.shipment_tracking_number' | translate}}" class="form-control" [disabled]="true" name="shipment_tracking_number" [(ngModel)]="order.shipment_tracking_number"/>
					</div>
				</div>
				<div *ngIf="shipmentTrackingFieldsVisibility(order.status || 0)" class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'orders.shipping_company' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="-" class="form-control" [disabled]="true" name="shipping_company" [(ngModel)]="order.shipping_company"/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'orders.price' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<ng-select
							[items]="pricesOptions"
							bindLabel="label"
							bindValue="value"
							name="price"
							placeholder="{{'orders.price' | translate}}"
							[(ngModel)]="order.price"
							[clearable]="false"
							[disabled]="isFormDisabled()"
							required
						>
						</ng-select>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'orders.sales_channel' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<ng-select
							[items]="salesChannelOptions"
							bindLabel="label"
							bindValue="value"
							name="sales_channel"
							placeholder="{{'orders.sales_channel' | translate}}"
							[(ngModel)]="order.sales_channel"
							[clearable]="false"
							[disabled]="isFormDisabled()"
							required
						>
						</ng-select>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'orders.subscription_terms' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<ng-select
							[items]="subscriptionTermsOptions"
							bindLabel="label"
							bindValue="value"
							name="subscription_terms"
							placeholder="{{'orders.subscription_terms' | translate}}"
							[(ngModel)]="order.subscription_terms"
							[clearable]="false"
							[disabled]="isFormDisabled()"
							required
						>
						</ng-select>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'orders.warranty_period' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<ng-select
							[items]="warrantyPeriodOptions"
							bindLabel="label"
							bindValue="value"
							name="warranty_years"
							placeholder="{{'orders.warranty_period' | translate}}"
							[(ngModel)]="order.warranty_years"
							[clearable]="false"
							[disabled]="isFormDisabled()"
							required
						>
						</ng-select>
					</div>
				</div>
				<div class="row mt-3 align-items-center">
					<div class="col-md-4">
						<h3>{{'orders.receiver_address' | translate}}</h3>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'g.name' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'g.name' | translate}}" class="form-control" name="receiver_name" [disabled]="isFormDisabled()" [(ngModel)]="order.receiver_name" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'login_register.company_name' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'login_register.company_name' | translate}}" class="form-control" name="receiver_company_name" [disabled]="isFormDisabled()" [(ngModel)]="order.receiver_company_name" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'orders.receiver_company_email' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'orders.receiver_company_email' | translate}}" class="form-control" name="receiver_company_email" [disabled]="isFormDisabled()" [(ngModel)]="order.receiver_company_email"/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'g.address_line' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'g.address_line' | translate}}" class="form-control" name="receiver_first_address_line" [disabled]="isFormDisabled()" [(ngModel)]="order.receiver_first_address_line" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'site_edit.address_optional' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'site_edit.address_optional' | translate}}" class="form-control" name="receiver_second_address_line" [disabled]="isFormDisabled()" [(ngModel)]="order.receiver_second_address_line"/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'orders.receiver_postal_code' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'orders.receiver_postal_code' | translate}}" class="form-control" name="receiver_postal_code" [disabled]="isFormDisabled()" min="0" [(ngModel)]="order.receiver_postal_code" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'orders.receiver_city' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'orders.receiver_city' | translate}}" class="form-control" name="receiver_city" [disabled]="isFormDisabled()" [(ngModel)]="order.receiver_city" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'site_edit.country' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'site_edit.country' | translate}}" class="form-control" name="receiver_country" [disabled]="isFormDisabled()" [(ngModel)]="order.receiver_country" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'login_register.phone_no' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<input class="form-control" placeholder="{{'login_register.phone_no' | translate}}" name="receiver_phone_number" [disabled]="isFormDisabled()" [(ngModel)]="order.receiver_phone_number"/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'login_register.email' | translate}}</strong>
					</div>
					<div class="col-md-8">
						<input placeholder="{{'login_register.email' | translate}}" class="form-control" name="receiver_email" [disabled]="isFormDisabled()" [(ngModel)]="order.receiver_email" email/>
					</div>
				</div>
				<div class="row mt-3 align-items-center">
					<div class="col-md-4">
						<h3>{{'orders.notifications_emails' | translate}}</h3>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'orders.email_addresses' | translate}} *</strong>
					</div>
					<div class="col-md-8">
						<ng-select
							name="notifications_email_list"
							[multiple]="true"
							[addTag]="true"
							[items]="notificationEmails"
							bindLabel="email"
							[clearable]="false"
							placeholder="{{'orders.email_addresses' | translate}}"
							[disabled]="isFormDisabled()"
							[(ngModel)]="notificationEmails"
							required
						>
							<ng-template ng-label-tmp let-item="item" let-clear="clear">
								<span>
									<span class="ng-value-icon left" (click)="clear(item)" aria-hidden="true">×</span>
									<span class="ng-value-label">{{item.email}}</span>
								</span>
							</ng-template>
						</ng-select>
					</div>
				</div>
				<div class="row my-4 align-items-center">
					<div class="col-md-4">
					</div>
					<div class="col-md-8">
						<div class="row align-items-center">
							<div class="col-md-8">
								<button *ngIf="!isFormDisabled()" type="submit" class="btn text-white main-blue-bg btn-lg col-5 mr-4">{{'g.submit' | translate}}</button>
								<button type="button" class="btn text-white main-grey-bg btn-lg px-5" (click)="goBack()">{{'g.back' | translate}}</button>
							</div>
						</div>
						<div *ngIf="action == 'add'" class="row px-3 pt-2 text-danger">
							<p>*{{ 'orders.order_attachment_form_msg' | translate }}</p>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
