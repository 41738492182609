import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AdminService } from '../../admin.service';
import { NotificationMessageService } from '../../../shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-error-log',
	templateUrl: './error-log.component.html',
	styleUrls: ['./error-log.component.css']
})
export class ErrorLogComponent implements OnInit, AfterViewInit {

	filterOptions = ['Date', 'User ID', 'Server IP', 'Function Name'];
	getBy: string = 'Date';
	value: string;
	type: string = 'errors_log';
	fromDate: Date;
	toDate: Date;
	selectedErrors: Array<number> = [];
	logInfo: any = [];
	selectAll: Boolean;
	columnNames: any[] = [
		{name: 'ID', key: 'id', isId: true},
		{name: 'Time', key: 'error_time', type: 'dateTime'},
		{name: 'Function Name', key: 'function_name'},
		{name: 'Main Caller', key: 'main_caller'},
		{name: 'User ID', key: 'user_id'},
		{name: 'Server IP', key: 'server_ip'},
		{name: 'Is Sched?', key: 'is_sched'},
		{name: 'Error', key: 'error', type: 'truncate', maxLength: 50},
		{name: 'Options', key: 'options', type: 'options', optionsList: [{'action': 'view', 'text':'View'},{'action': 'delete', 'text':'Delete'}]},
	];
	
	selectedLogsIds: any;

	@ViewChild('dataTable') dataTable;
	@ViewChild("confirmModal") confirmModal;

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private router: Router,
		private usersService: UsersService,
		private translate: TranslateService
	) {
	}

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('access_error_logs'))
			return this.router.navigate(['/unauthorized']);
		this.getLoggingInfo();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			if(result[1][0] == 'delete') {
				return this.delete(result[1][1], result[1][2]);
			}

			if(result[1][0] == 'deleteLogs') {
				return this.deleteLogs();
			}
		});
	}

	confirmDeleteLogs(action, data1?, data2?) {
		this.confirmModal.passData = [action, data1, data2];
		this.confirmModal.message = 'g.confirm_delete_message';
		this.confirmModal.show();
	}

	setTab(tab){
		switch (tab) {
			case 'errors':
				this.filterOptions = ['Date', 'User ID', 'Server IP', 'Function Name'];
				this.type = 'errors_log';
				break;
			case 'sendPushNotification':
				this.filterOptions = ['Date', 'User ID', 'Server IP'];
				this.type = 'push_notification_error_log';
				break;
		}
		this.getBy = 'Date';
		this.resetSelects();
		this.getLoggingInfo();
	}

	getLoggingInfo() {
		let options = {
			fromDate: "",
			toDate: "",
			value: "",
			type: this.type,
			getBy: this.getBy
		};
		if(this.getBy == 'Date') {
			
			if(!this.fromDate || !this.toDate) {
				this.fromDate = new Date(new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0));
				this.toDate = new Date(new Date().setHours(23,59,59,999));
			}

			options.fromDate  = Math.floor(this.fromDate.getTime() / 1000)+'';
			options.toDate    = Math.floor(this.toDate.getTime() / 1000)+'';
		} else if (this.getBy == 'User ID') {
			options.value = (parseInt(this.value) || 0) + "";
		} else {
			options.value = this.value;
		}
		
		this.adminService.getLoggingInfo(options).subscribe(
			(data: any) => {
				this.logInfo = data.slice();
			}
		);
	}

	delete(type, id) {
		this.adminService.deleteLog(id, type).subscribe(
			(isDeleted: any) => {
				if(isDeleted.result) {
					let logInfo = this.logInfo.slice();
					for(let i in logInfo) {
						if (logInfo[i].id == id) {
							logInfo.splice(+i, 1);
							this.logInfo = logInfo.slice();
							break;
						}
					}
					this.notificationMessage.setMessage(this.translate.instant('g.deleted_successfully'),{clearOnXTimeNavigate: 1, type: 'success'});
				}
			}
		);
	}

	deleteLogs() {
		this.adminService.deleteLog(this.selectedLogsIds, this.type).subscribe(
			(isDeleted: any) => {
				if(isDeleted.result) {
					this.notificationMessage.setMessage(this.translate.instant('g.deleted_successfully'),{clearOnXTimeNavigate: 1, type: 'success'});
					this.dataTable.clearSelection();
					this.getLoggingInfo();
				}
			}
		);
	}

	doAction(event) {
		if(event.action == 'view') {
			let url = this.router.createUrlTree([event.element.id], {relativeTo: this.route});
			return window.open('#'+url.toString(), "_blank");
		}
		if(event.action == 'delete') {
			return this.confirmDeleteLogs('delete', this.type, event.element.id);
		}
	}

	resetSelects(){
		this.value = '';
	}
}