<div class="main-black border-radius-bottom white-box mt-1" *ngIf="requestsList">
	<div class="card-body" *ngIf="requestsList.length > 0">
		<h6 class="mb-3">{{requestsList.length}} {{'nav.manage_device_requests' | translate}}</h6>
		<table class="table mb-0">
			<thead>
				<tr>
					<th>{{'g.from' | translate}}</th>
					<th>{{'g.to' | translate}}</th>
					<th>{{'device_management.request_by' | translate}}</th>
					<th>{{'g.enterprise_name' | translate}}</th>
					<th>{{'device_management.is_reset_data' | translate}}</th>
					<th>{{'g.actions' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let request of requestsList">
					<td>
                        <a [routerLink]="['/', request._current_customer, request._current_site, request.mac_address]" target="_blank">{{request.serial_number}}</a>
                    </td>
					<td>
                        <a [routerLink]="['/', request._new_customer, request._new_site, request.new_mac_address]" target="_blank">{{request.new_serial_number}}</a>
                    </td>
					<td>
						<a [routerLink]="['/user', 'edit', request.user_id]" target="_blank" *ngIf="usersService.hasAccessFunction('edit_user_admin') && usersInfo[request.user_id]; else userNoLink">
							{{usersInfo[request.user_id].first_name}} {{usersInfo[request.user_id].last_name}}
						</a>
						<ng-template #userNoLink>{{request.user_id}}<span *ngIf="!usersInfo[request.user_id]"> ({{'g.deleted' | translate}})</span></ng-template>
					</td>
					<td>
						<ng-container *ngIf="enterprisesInfo[request.enterprise_id]; else entDel">{{enterprisesInfo[request.enterprise_id].name}}</ng-container>
						<ng-template #entDel>{{request.enterprise_id}}<span> ({{'g.deleted' | translate}})</span></ng-template>
					</td>
					<td>{{request.is_reset_data}}</td>
					<td>
						<mat-icon class="pointer blue-hover" [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{request: request}">
							settings
						</mat-icon>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="card-body" *ngIf="requestsList.length == 0">
		<h3>{{'device_management.no_new_move' | translate}}</h3>
	</div>
</div>

<mat-menu #actionsMenu="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent let-request="request">
		<span mat-menu-item class="text-success" (click)="confirmAction('approveRequest', request.id)">
			{{'device_management.approve' | translate}}
		</span>
		<span mat-menu-item class="text-danger" (click)="confirmAction('rejectRequest', request.id)">
			{{'g.reject' | translate}}
		</span>
	</ng-template>
</mat-menu>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>