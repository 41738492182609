<div class="row">
	<div class="col-lg-6 col-md-6 col-sm-12">
		<h2>{{'nav.receiving_shipment' | translate}}</h2>
		<div class="col-md-12">
			<div class="form-group row">
				<label for="cmPoNumber" class="col-2 col-form-label"><b>{{'shipment.cm_po_number' | translate}}</b></label>
				<div class="col-4">
					<input class="form-control" name="cmPoNumber" [ngClass]="{'border border-danger': cmPoNumberError}" [disabled]="shipmentOptions.historyReportId" [(ngModel)]="shipmentOptions.cm_po_number" trim-text />
					<p class="text-danger" *ngIf="cmPoNumberError" (ngModelChange)="cmPoNumberError=''"><small>{{cmPoNumberError}}</small></p>
				</div>
			</div>
		</div>
		<form #searchDeviceForm="ngForm" (ngSubmit)="getDevices()">
			<div class="mt-4">
				<label>
					<input class="mr-2" type="checkbox" name="skipShipmentQuality" [(ngModel)]="shipmentOptions.skipShipmentQuality" [disabled]="enableDisableSkipShipmentQuality() || checkDisabledInputs()">{{'shipment.skip_shipment_quality' | translate}}
				</label>
				<div *ngIf="shipmentOptions.skipShipmentQuality">
					<small class="text-danger">{{'shipment.csv_file_rules_msg' | translate}}</small>
					<div class="custom-file my-2">
						<input class="custom-file-input" [disabled]="checkDisabledInputs()" type="file" id="shipmentBulkIds" accept=".csv" (change)="handleUploadCSVFile($event)">
						<label class="custom-file-label" #labelImport for="shipmentBulkIds">{{'shipment.import_devices_csv' | translate}}</label>
					</div>
				</div>
				<span *ngIf="shipmentOptions.skipShipmentQuality">{{ 'g.or_capital' | translate }}</span>
				<div class="my-2">
					<input [disabled]="checkDisabledInputs()" name="searchString" [(ngModel)]="searchString" trim-text placeholder="{{'g.serial_number' | translate}}/{{'g.mac_address' | translate}}" class="form-control" required/>
				</div>
				<div class="mt-3 input-group">
					<div class="form-check form-check-inline">
						<input [disabled]="checkDisabledInputs()" class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio1" value="sn">
						<label class="form-check-label" for="inlineRadio1">{{'g.serial_number' | translate}}</label>
					</div>
					<div class="form-check form-check-inline">
						<input [disabled]="checkDisabledInputs()" class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio2" value="mac">
						<label class="form-check-label" for="inlineRadio2">{{'g.mac_address' | translate}}</label>
					</div>
				</div>
				<div class="mt-3">
					<button type="submit" class="btn btn-primary pointer" [disabled]="searchDeviceForm.invalid || checkDisabledInputs()">{{'g.search' | translate}}</button>
				</div>
			</div>
		</form>
		<div class="col-md-6 my-5" *ngIf="searchResult?.length == 0 && !notFoundDevices?.length">
			<b>{{'g.no_results_found' | translate}}</b>
		</div>
		<div class="col-md-12 my-5" *ngIf="!searchResult?.length && notFoundDevices?.length">
			<b>{{'shipment.field_import_csv_devices_not_found' | translate}}</b>
			<ul>
				<li *ngFor="let notFoundDevice of notFoundDevices">{{notFoundDevice}}</li>
			</ul>
		</div>
		<div class="col-md-12 my-5" *ngIf="searchResult?.length > 0">
			<h3>{{'g.results' | translate}}:</h3>
			<div class="row py-2">
				<div class="col-md-2"><strong>{{'g.mac_address' | translate}}</strong></div>
				<div class="col-md-2"><strong>{{'g.serial_number' | translate}}</strong></div>
				<div class="col-md-2" *ngIf="!shipmentOptions.skipShipmentQuality">
					<strong>{{'shipment.fw_update_status' | translate}}</strong>
				</div>
				<div class="col-md-3"><strong>{{'actions.move_to_bulk' | translate}}</strong></div>
				<div class="col-md-3" *ngIf="!shipmentOptions.skipShipmentQuality">
					<strong>{{'actions.indicator_light' | translate}}</strong>
				</div>
			</div>
			<div *ngFor="let device of searchResult" class="row py-2">
				<div class="col-md-2">
					<a
						[routerLink]="['/', sites[device.site_id]?.customer_id, device.site_id, device.mac_address, 'performance']"
						class="blue-hover pointer ml-1"
						target="_blank"
					>
						{{device.mac_address}}
					</a>
				</div>
				<div class="col-md-2">{{device.serial_number}}</div>
				<div class="col-md-2" *ngIf="!shipmentOptions.skipShipmentQuality">
					<div *ngIf="fwDevicesStatus[device.mac_address]?.step">
						{{'shipment.fw_in_progress' | translate}} : {{fwDevicesStatus[device.mac_address].step}}%
					</div>

					<div *ngIf="fwDevicesStatus[device.mac_address] && fwDevicesStatus[device.mac_address]?.status == DEVICE_STATUS.fw_update_prevented.status">
						{{'shipment.fw_update_prevented' | translate}}
					</div>

					<div *ngIf="fwDevicesStatus[device.mac_address] && !fwDevicesStatus[device.mac_address]?.step && fwDevicesStatus[device.mac_address]?.status != DEVICE_STATUS.fw_update_prevented.status">
						{{'g.'+fwDevicesStatus[device.mac_address].status | translate }}
					</div>
				</div>
				<div class="col-md-3">
					<div *ngIf="moveToBulk(device)">
						<fa-icon icon="arrow-right" size="lg" class="pointer" (click)="checkVoltageCurrentRanges(device)"></fa-icon>
					</div>
					<div *ngIf="!moveToBulk(device) && device.is_device_in_cm_devices && shipmentOptions.skipShipmentQuality && !fwDevicesStatus[device.mac_address]?.step && !device.time_lost && checkDeviceShipmetQuality(device.shipment_quality_passed)">
						<span class="text-danger">{{'shipment.device_already_in_bulk' | translate}}</span>
					</div>
					<div *ngIf="!device.is_device_in_cm_devices">
						<span class="text-danger">{{'common.device_not_cm_devices' | translate}}</span>
					</div>
					<div *ngIf="!shipmentOptions.skipShipmentQuality && fwDevicesStatus[device.mac_address]?.step">
						<span class="text-danger">{{'ready_to_ship.push_fw_update' |translate}}</span>
					</div>
					<div *ngIf="device.time_lost && (!fwDevicesStatus[device.mac_address]?.step || !device.is_device_in_cm_devices ) && !shipmentOptions.skipShipmentQuality">
						<span class="text-danger">{{'common.time_lost' | translate}}</span>
						<span class="link-text"
							(click)="showResetRTCPopup(device.mac_address)"
							*ngIf="userService.hasAccessFunction('reset_rtc_lost_detection') && device.lost_rtc_time && device.last_cm_date_tested < device.lost_rtc_time"
						>
							{{" (" + ('g.reset' | translate) + ")"}}
						</span>
					</div>
					<div *ngIf="device.client_socket_type != commonDataService.CLIENT_SOCKET_TYPE.WIFI && !this.shipmentOptions.skipShipmentQuality">
						<span class="text-danger"> {{'shipment.device_wifi_connected_promt' |translate}}</span>
					</div>
					<div *ngIf="!device.shipment_quality_passed && shipmentOptions.skipShipmentQuality">
						<span class="text-danger">{{'shipment.not_passed_shipment_quality' |translate}}</span>
					</div>
				</div>
				<div class="col-md-3"  *ngIf="!shipmentOptions.skipShipmentQuality">
					<a class="btn btn-primary text-white" (click)="sendCommand()">{{'actions.indicator_light' | translate}}</a>
				</div>
			</div>
		</div>
	</div>
	<div class="col-lg-6 col-md-6 col-sm-12">
		<div class="d-flex justify-content-between mb-3">
			<h2></h2>
			<div class="ml-auto">
				<div class="row">
					<button class="btn btn-primary pointer" routerLink="/management/receiving-shipment-history">{{'shipment.shipment_history_page' | translate}}</button>
				</div>
			</div>
		</div>
		<div *ngIf="shouldShowShipmentDevices()">
			<div class="col-md-12">
				<div class="row pr-2">
					<div class="col-md-1"><strong>{{'g.number' | translate}}</strong></div>
					<div class="col-md-3"><strong>{{'g.mac_address' | translate}}</strong></div>
					<div class="col-md-3"><strong>{{'g.serial_number' | translate}}</strong></div>
					<div *ngIf="!shipmentOptions.skipShipmentQuality" class="col-md-2"><strong>{{'g.action' | translate}}</strong></div>
					<div class="col-md-2"><strong>{{'sim.status' | translate}}</strong></div>
					<div class="col-md-1"><strong>{{'g.remove' | translate}}</strong></div>
				</div>
				<div *ngFor="let mac_address of devices | keys; let i = index" class="row py-2">
					<div class="col-md-1">
						{{i + 1}}
					</div>
					<div class="col-md-3">
						<a
							[routerLink]="['/', devices[mac_address].customer_id, devices[mac_address].site_id, devices[mac_address].mac_address, 'performance']"
							class="blue-hover pointer ml-1" target="_blank">{{devices[mac_address].mac_address}}
						</a>
					</div>
					<div class="col-md-3">{{devices[mac_address].serial_number}}</div>
					<!-- action -->
					<div *ngIf="!shipmentOptions.skipShipmentQuality" class="col-md-2">
						<button class="btn btn-primary btn-sm"
							(click)="createInitialHistory(devices[mac_address])"
							[disabled]="receiveButtonDisabled(mac_address)">
							{{'g.receive' | translate}}
						</button>
					</div>

					<!-- status -->
					<div class="col-md-2">
						<div *ngIf="devices[mac_address].status == DEVICE_STATUS.in_progress.status" class="text-primary">
							{{'shipment.in_progress' | translate}}
						</div>

						<button *ngIf="devices[mac_address].status == DEVICE_STATUS.warnings.status" class="btn btn-warning btn-sm" (click)="showWarnings(mac_address)">
							{{'g.warnings' | translate}}
						</button>

						<div *ngIf="!shipmentOptions.skipShipmentQuality && fwDevicesStatus[mac_address]?.step; else deviceStatus">
							{{'shipment.fw_in_progress' | translate}} : {{fwDevicesStatus[mac_address].step}}%
						</div>

						<ng-template #deviceStatus>
							<div
								*ngIf="devices[mac_address].status != DEVICE_STATUS.not_start.status &&
										devices[mac_address].status != DEVICE_STATUS.in_progress.status &&
										devices[mac_address].status != DEVICE_STATUS.warnings.status"
								[ngClass]="{'text-success': receivingShipmentService.isRecevingProccessSuccess(devices[mac_address].status),
											'text-danger': !receivingShipmentService.isRecevingProccessSuccess(devices[mac_address].status)}">
								{{'shipment.'+devices[mac_address].status | translate}}
							</div>
						</ng-template>
					</div>

					<div class="col-md-1" *ngIf="devices[mac_address].status == DEVICE_STATUS.not_start.status">
						<fa-icon icon="window-close" size="2x" class="pointer text-danger"
							(click)="removeFromShipment(mac_address)"></fa-icon>
					</div>
				</div>
			</div>
			<div class="row">
				<div class="col-6">
					<button *ngIf="shipmentOptions.skipShipmentQuality" class="btn btn-primary mr-1" [disabled]="isButtonDisabled()" (click)="!toggleButtons() ? submitShipment(true) : createInitialHistory()">{{(!toggleButtons() ? 'g.finish' : 'shipment.receive_all') | translate}}</button>
					<button *ngIf="!shipmentOptions.skipShipmentQuality" class="btn btn-primary mr-1" [disabled]="isButtonDisabled()" (click)="submitShipment(true)">{{'g.finish' | translate}}</button>
					<button *ngIf="hasFailures" class="btn btn-danger"
						(click)="reCheckFailures()">{{'g.rerecive_shipment' | translate}}
					</button>
				</div>
			</div>
		</div>
	</div>
</div>

<app-modal
	#warningDialog
	title="{{'nav.warnings' | translate}}"
	[hasCloseButton]= "false"
	[hasConfirmButton]= "false"
>
<div class="mb-5">
			<ul *ngIf="selectedDevice?.warnings?.length">
				<li *ngFor="let warning of selectedDevice.warnings">
					{{warning | translate}}
				</li>
			</ul>
		</div>
</app-modal>

<app-modal #resetRTCLostDetectionModal title="{{'devices.confirm_reset_rtc_lost_detection' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'settings.reset_rtc_lost_detection_prompt' | translate}}
</app-modal>
