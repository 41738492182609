import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-users-alert-settings',
	templateUrl: './users-alert-settings.component.html',
	styleUrls: ['./users-alert-settings.component.css']
})

export class UsersAlertSettingsComponent implements OnInit {

	usersList: any = [];
	selectedUser: number = 0;
	type: string;
	typesValue: {0: any, 1: any, 2: any};
	rangeValue: any = 0;
	siteID: number;
	usersAlertSettings: any [] = [];

	constructor (
		private route: ActivatedRoute,
		private usersService: UsersService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService,
		private commonService: CommonService
	) {
		this.typesValue = {
			0: this.translateService.instant('alert_frequency.none'),
			1: this.translateService.instant('alert_frequency.daily'),
			2: this.translateService.instant('alert_frequency.weekly')
		}
	}

	ngOnInit(): void {
		this.siteID = this.route.parent.snapshot.params.siteId || 0;
		this.getUsersAndAlertSeetings();
	}
	
	getUsersAndAlertSeetings() {
		this.usersService.getUsersAlertSettings(+this.siteID).subscribe((res: any) => {
			res.users.map((item) => this.usersList.push({id: item.id, name: item.first_name +' '+ item.last_name}));
			this.usersList = this.commonService.sortDataAlphabetically(this.usersList, 'name');
			this.usersAlertSettings = res.usersAlertSettings;
		});
	}

	selectOption(id: number) {
		this.selectedUser = id;
		let userSettings = this.usersAlertSettings.filter(item => item.user_id == this.selectedUser);

		if (userSettings.length && userSettings[0].user_id == this.selectedUser) {
			this.type = userSettings[0].maintenance_reports;
			this.rangeValue = Object.keys(this.typesValue).find(key => this.typesValue[key] === this.type);
		} else {
			this.type = 'None';
			this.rangeValue = 0;
		}
		
	}

	onChange(e) {
		this.type = this.typesValue[e.target.value];
	}
 
	saveUserAlertSettings(selectedUser,type){
		this.usersService.saveUserAlertSettings(selectedUser, type, +this.route.parent.snapshot.params.siteId).subscribe((res: any) => {
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getUsersAndAlertSeetings();
		});
	}
}