<div class="d-flex justify-content white-box p-4 border-radius-bottom">
	<div *ngIf="cellularData" class="col">
		<div class="preperty-values">
			<div class="row mb-2">
				<span class="h3 col">{{'admin.cellular_settings' | translate}}</span>
				<button *ngIf="device.config_info.metered" class="btn btn-secondary me-3 col-2" (click)="sendCellularInfoCommand()">{{'g.refresh' | translate}}</button>
			</div>
			
			<div class="centered-values mt-2">
				<div *ngFor="let field of cellularData | keys">
					<div class="p-4">{{'qv.'+field | translate}}</div>
					<div class="p-4">
						<ng-container *ngIf="field == 'cellular_rssi'; else notCellularRssi">
							<div [tooltip]="('g.original_value' | translate) + ': ' + cellularRssiTooltip">
								<span>{{'qv.live' | translate}}</span>: {{cellularData[field]}}
							</div>
							<div class="mt-1 border-top" [tooltip]="('g.original_value' | translate) + ': ' + cellularOemRssiTooltip">
								<span>{{'qv.oem' | translate}}</span>: {{cellularOemData[field]}}
							</div>
						</ng-container>
						<ng-template #notCellularRssi>
							<div><span>{{'qv.live' | translate}}</span>: {{cellularData[field]}}</div>
							<div class="mt-1 border-top"><span>{{'qv.oem' | translate}}</span>: {{cellularOemData[field]}}</div>
						</ng-template>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col">
		<div class="preperty-values">
			<div class="row mb-2">
				<span class="h3 col">{{'sim.sim_info' | translate}}</span>
				<button *ngIf="device.config_info.cellular_iccid" class="btn btn-secondary me-3 col-2" (click)="this.getSimInfo(true)">{{'g.refresh' | translate}}</button>
			</div>
			
			<h4 *ngIf="noSimInfo; else simInfoData">{{noSimInfo}}</h4>
			<ng-template #simInfoData>
				<div class="centered-values mt-2">
					<div *ngFor="let field of simInfo | keys">
						<div class="p-4">{{'sim.'+field | translate}}</div>
						<div class="p-4">{{ simInfo[field]}}</div>
					</div>
				</div>
			</ng-template>
		</div>
	</div>
</div>