<div class="row p-1">
	<div class="col-sm-6 col-md-4">
		<h4>{{title}}</h4>
	</div>
	<div class="ml-auto mb-2">
		<button class="btn margin-bottom btn-primary" (click)="showActionModal('add')">
			{{'attachments.add' | translate}}
		</button>
	</div>
</div>

<div *ngIf="showAttachments(); else noAttachments">
	<div class="table-responsive">
		<table class="table table-striped">
			<thead>
				<tr>
					<th>{{'g.file_name' | translate}}</th>
					<th>{{'g.time' | translate}}</th>
					<th>{{'login_register.user_name' | translate}}</th>
					<th>{{'attachments.note' | translate}}</th>
					<th>{{'g.actions' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let id of attachments | keys" class="margin-bottom-sm">
					<td class="w-25"><a href="javascript:;" (click)="downloadAttachment(id)">{{attachments[id].file_name}}</a></td>
					<td>{{attachments[id].created_at | date:'MM/dd/yyyy hh:mm:ss' }}</td>
					<td>{{attachments[id].user_name}}</td>
					<td class="w-50">{{attachments[id].note}}</td>
					<td>
						<div class="d-flex">
							<button title="{{'attachments.edit_note' | translate}}" class="btn btn-primary" (click)="showActionModal('edit', id)"><i class="fa fa-pencil"></i></button>
							<button *ngIf="attachmentsService.hasDeleteAccess" title="{{'g.delete' | translate}}" class="btn btn-danger ml-1" (click)="showActionModal('delete', id)"><i class="fa fa-trash"></i></button>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<ng-template #noAttachments>
	<div class="text-center margin-bottom-lg text-muted">
		<h5>{{'attachments.no_attachments' | translate}}</h5>
	</div>
</ng-template>

<app-modal #addAttachmentsModal title="{{'attachments.attachments' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false" [size]="'lg'">
	<form #modelAttachment="ngForm" class="container" validate-form="modelAttachment">
		<div *ngIf="showValidationErrors()" class="alert alert-danger" role="alert">
			<h6 class="alert-heading">{{'g.errors' | translate}}</h6>
			<ul>
				<li *ngIf="selectedFilesValidations.min_count">{{'attachments.files_min_count_err' | translate}}</li>
				<li *ngIf="selectedFilesValidations.max_count">{{'attachments.files_max_count_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxFilesCount}})</li>
				<li *ngIf="selectedFilesValidations.extension">{{'attachments.extension_err' | translate}}: ({{attachmentsService.allowedUploadFilesExt.join(', ')}})</li>
				<li *ngIf="selectedFilesValidations.size">{{'attachments.size_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxFileSize / attachmentsService.mbBytes}} MB)</li>
				<li *ngIf="selectedFilesValidations.note">{{'attachments.note_err' | translate}}: ({{'g.max' | translate}} {{attachmentsService.maxNoteCharacters}})</li>
			</ul>
		</div>
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'attachments.choose_files' | translate}}
			</label>
			<div class="col-md-6">
				<div class="custom-file">
					<input #uploadAttachmentsBtn class="custom-file-input" id="customFile" type="file" [accept]="attachmentsService.allowedUploadFilesExt.join(', ')" id="actual-btn" name="files" multiple (change)="selectFiles($event)">
  					<label class="custom-file-label" for="customFile">{{'attachments.choose_file' | translate}}</label>
				</div>
			</div>
		</div>
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">{{'ready_to_ship.selected_files' | translate}}</label>
			<div class="col-md-6">
				<div *ngFor="let file of selectedFiles" class="d-flex justify-content-between mt-1">
					<p>{{file.name}}</p>
					<button title="{{'g.delete' | translate}}" class="btn btn-danger ml-1" (click)="removeSelectedFile(file)"><i class="fa fa-trash"></i></button>
				</div>
			</div>
		</div>
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">{{'attachments.note' | translate}}</label>
			<div class="col-md-6">
				<textarea required rows="5" [maxlength]="attachmentsService.maxNoteCharacters" class="form-control" [(ngModel)]="note" name="note" placeholder="{{'form.max_limit' | translate:{'max': attachmentsService.maxNoteCharacters} }}"></textarea>
			</div>
		</div>
	
		<div class="row mb-2 mt-2 d-flex justify-content-end">
			<button class="btn btn-primary" (click)="addAttachments()" [disabled]="!selectedFiles.length">{{'g.submit' | translate}}</button>	
			<button class="btn btn-light ml-2" (click)="addAttachmentsModal.hide()">{{'g.close' | translate}}</button>	
		</div>
	</form>
</app-modal>

<app-modal #deleteAttachmentsModal title="{{'g.delete' | translate}} {{'attachments.attachments' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false" [size]="'lg'">
	<form #modelAttachment="ngForm" class="container" validate-form="modelAttachment">
		<p>{{'attachments.delete_attachment' | translate}}</p>
	</form>
	<div class="mt-2 d-flex justify-content-end">
		<button class="btn btn-danger" (click)="deleteAttachment()">{{'g.delete' | translate}}</button>	
		<button class="btn btn-light ml-2" (click)="deleteAttachmentsModal.hide()">{{'g.close' | translate}}</button>	
	</div>
</app-modal>

<app-modal #editNoteModal title="{{'g.edit' | translate}} {{'attachments.attachments' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false" [size]="'lg'">
	<form #modelAttachment="ngForm" class="container" validate-form="modelAttachment">
		<div class="row mb-2" *ngIf="editedNoteAttachment">
			<label class="col-md-4 col-form-label">
				{{'attachments.note' | translate}}
			</label>
			<div class="col-md-6">
				<textarea required rows="5" class="form-control" [(ngModel)]="editedNoteAttachment.note" name="note"></textarea>
			</div>
		</div>
	</form>
	<div class="mt-2 d-flex justify-content-end">
		<button class="btn btn-primary" (click)="editAttachmentNote()">{{'g.edit' | translate}}</button>	
		<button class="btn btn-light ml-2" (click)="editNoteModal.hide()">{{'g.close' | translate}}</button>	
	</div>
</app-modal>

<app-modal #successAttachmentsModal title="{{'attachments.success_attachments' | translate}}" [hasConfirmButton]="false" [size]="'lg'">
	<table class="table table-striped">
		<thead>
			<th>{{'g.file_name' | translate}}</th>
			<th>{{'g.status' | translate}}</th>
		</thead>
		<tbody>
			<tr *ngFor="let file of selectedFiles">
				<td>{{file.name}}</td>
				<td *ngIf="!successAttachments[file.name] && !filesAlreadyUploaded[file.name]">
					<div class="spinner-border spinner-border-sm text-primary" role="status">
						<span class="sr-only">Loading...</span>
					</div>
				</td>
				<td *ngIf="successAttachments[file.name]" class="text-success"><i class="fa fa-check"></i></td>
				<td *ngIf="filesAlreadyUploaded[file.name]" class="text-danger">{{'attachments.file_already_attached' | translate}}</td>
			</tr>
		</tbody>
	</table>
</app-modal>