<div id="footer-wrapper">
	<div class="px-3 mx-1 d-flex justify-content-between align-items-center">
		<div>
			<b>{{'login_register.copyright' | translate:{"year": year} }}. </b> {{'g.all_rights_reserved' | translate}}
			<a class="contact-us" routerLink="/contact-us">
				<b>{{'contact_us.title' | translate}}</b>
			</a>
		</div>
		<div class="d-flex gap-3 align-items-center">
			<div class="p-2">
				<a href="{{appStoreAppLink}}" target="_blank">
					<img src="/sctportal/images/stores/app-store.png" height="30">
				</a>
			</div>
			<div class="p-2">
				<a href="{{googlePlayAppLink}}" target="_blank">
					<img src="/sctportal/images/stores/google-play.png" height="30">
				</a>
			</div>
		</div>
	</div>
</div>
