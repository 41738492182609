import { Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { ColumnsConfig, TableConfig, TableData, DeviceCommand } from '../../shared/custom-table/custom-table-interface';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { DeviceCommonService } from 'src/app/home/site-dashboard/device/device-common.service';

@Component({
	selector: 'app-device-queue-changes',
	templateUrl: './device-queue-changes.component.html',
	styleUrls: ['./device-queue-changes.component.css']
})

export class DeviceQueueChangesComponent {
	queueChanges: any[] = [];
	removedChangesStackInfo: any;
	confirmDeleteStack: boolean = false;
	currentUser: any;
	sortResult: any[] = [];
	queueFields: DeviceCommand;
	changesToSow: any[] = [];

	columnConfig: ColumnsConfig[] = [
		{ key: 'serial_number', name: this.translateService.instant('devices.power_view_sn'), type: "link" },
		{ key: 'mac_address', name: this.translateService.instant('g.mac_address'), type: 'link' },
		{ key: 'customer_name', name: this.translateService.instant('g.customer_name'), type: 'link' },
		{ key: 'site_name', name: this.translateService.instant('g.site_name'), type: "link" },
		{ key: 'user_name', name: this.translateService.instant('devices.queued_by'), type: 'link' },
		{ key: 'expiry_date', name: this.translateService.instant('devices.queue_expiry_date'), type: "date" },
		{ key: 'last_connect_time', name: this.translateService.instant('devices.last_connect_time'), type: "date" },
		{ key: 'queue_fields', name: this.translateService.instant('devices.device_queued_fields'), type: 'icon'}
	];

	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 50,
	};

	@ViewChild("deleteChangeStackModal") deleteChangeStackModal;
	@ViewChild("devicesQueueFieldsModal") devicesQueueFieldsModal;

	constructor(
        public usersService: UsersService,
        private router: Router,
        private deviceService: DeviceService,
		private sctToastService: NotificationMessageService,
		private translateService: TranslateService,
		private deviceCommonService: DeviceCommonService
    ) {}

    ngOnInit() {
        if (!this.usersService.hasAccessPermission(null, 'noc'))
            this.router.navigate(['/unauthorized']);

		this.currentUser = this.usersService.getCurrentUser();
		this.getAllQueueChanges();
    }

	getAllQueueChanges() {
		this.queueChanges = [];
		this.deviceService.getAllQueueChanges().subscribe((res: any) => {
			[...res].forEach(row => {
				let rewData: TableData  = {
					row,
					serial_number: {value: row.serial_number == 'device_deleted'?  this.translateService.instant("g." + row.serial_number) : row.serial_number, link: row.serial_number == 'device_deleted'? null : ['/', row.customerId, row.siteId, row.mac_address, 'performance']},
					mac_address: {value: row.mac_address, link: row.serial_number == 'device_deleted'? null : ['/', row.customerId, row.siteId, row.mac_address, 'performance']},
					customer_name: {value: row.customer_name == 'customer_deleted'? this.translateService.instant("g." + row.customer_name) : row.customer_name, link: row.customer_name == 'customer_deleted'? null : ['/', row.customerId]},
					site_name: {value: row.site_name == 'site_deleted'? this.translateService.instant("g." + row.site_name) : row.site_name, link: row.site_name == 'site_deleted'? null : ['/', row.customerId, row.siteId]},
					user_name: {value: row.user_name, link: this.usersService.hasAccessFunction('edit_user_admin')? ['/user', 'edit', row.user_id] : null},
					expiry_date: {value: moment(row.expiry_date*1000).utc().format('MM/DD/YYYY hh:mm:ss A')},
					last_connect_time: {value: moment(row.last_connect_time*1000).utc().format('MM/DD/YYYY hh:mm:ss A')},
					queue_fields: { icon: 'eye', action: () => {
						this.openQueuedFieldsDialog(row);
					}},
				};

				this.queueChanges.push(rewData);
			});
		});
	}

	ngAfterViewInit() {
		this.deleteChangeStackModal.onClose.subscribe((ok) => {
			if (ok) {
				this.deleteChangeStack(this.removedChangesStackInfo, false);
				this.confirmDeleteStack = false;
			} else {
				this.deleteChangeStackModal.hide();
				this.confirmDeleteStack = false;
			}
		});
	}

	deleteChangeStack(record, confirm=true) {
		if (confirm) {
			this.removedChangesStackInfo = record;
			if (record.user_id != this.currentUser.id)
				this.confirmDeleteStack = true;
			return this.deleteChangeStackModal.show();
		}
		const changeToDelete = Array.isArray(record.changes)? record.changes.flatMap(item => [item.split(/_(?=[^_]+$)/)[0], item.split(/_(?=[^_]+$)/)[1]]) : record.changes;
		this.deviceService.removeChangeStack(changeToDelete, record.id).subscribe((response: any) => {
			switch(response) {
				case 1:
					this.sctToastService.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
					this.devicesQueueFieldsModal.hide();
					return this.getAllQueueChanges();
					break;
				case 2:
					this.sctToastService.setMessage('translate|settings.queue_change_executed', {clearOnXTimeNavigate: 1, type: 'danger'});
					break;
				default:
					this.sctToastService.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
					break;
			}
		});
	}

	openQueuedFieldsDialog(data) {
		this.changesToSow = [];
		this.devicesQueueFieldsModal.show();
		this.queueFields = data;
		this.changesToSow = this.deviceCommonService.formatQueueFields([data.changes]);
	}

}