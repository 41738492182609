<div class="d-flex justify-content-between mb-2">
	<h2>{{'sim_management.missing_from_1nce' | translate}}</h2>
	<div class="refresh-container d-flex align-items-center">
		<div class="d-flex flex-column">
			<p *ngIf="lastUpdateSimsTime">{{'g.last_updated_at' | translate}}{{" " + formateDate(lastUpdateSimsTime * 1000)}} ({{commonService.showUserTimeZoneReference()}})</p>
			<p *ngIf="isUpdatingSims">{{'g.updating_the_list' | translate}}</p>
		</div>
		<button class="btn btn-primary" (click)="showRefreshPopup()">{{'g.update_list' | translate}}</button>
	</div>
</div>

<div class="table-container">
	<table class="table table-striped"
		*ngIf="sims.length; else noData"
		matSort (matSortChange)="sortData($event)"
		matSortActive="mac_address, serial_number, customer_name, site_name, iccid"
		matSortDirection="asc"
		matSortDisableClear
	>
		<thead>
			<tr>
				<th mat-sort-header="serial_number">{{'devices.serial_number' | translate}}</th>
				<th mat-sort-header="customer_name">{{'g.customer_name' | translate}}</th>
				<th mat-sort-header="site_name">{{'g.site_name' | translate}}</th>
				<th mat-sort-header="iccid">{{'qv.cellular_iccid' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let row of toShowSims; index as index">
				<td>
					<a [routerLink]="['/',row.customer_id, row.site_id, row.mac_address,'performance']">{{row.serial_number}}</a>
				</td>
				<td><a [routerLink]="['/', row.customer_id]">{{row.customer_name}}</a></td>
				<td><a [routerLink]="['/', row.customer_id, row.site_id]">{{row.site_name}}</a></td>
				<td><a [routerLink]="['/', row.customer_id, row.site_id, row.mac_address,'cellular-settings']">{{row.iccid}}</a></td>
			</tr>
		</tbody>
	</table>
</div>

<mat-paginator
	*ngIf="sims.length"
	[length]="sims.length"
	[pageSize]="pageSize"
	[pageSizeOptions]="pageSizeOptions"
	[showFirstLastButtons]="true"
	(page)="onPageChange($event)">
</mat-paginator>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal
	[hasCloseButton]="true"
	[hasConfirmButton]="true"
	#actionsConfirmationPopup title="{{'g.confirmation' | translate}}"
	size="md"
>
	<p>{{'sim.update_sims_confirm' | translate}}</p>
</app-modal>
