import { Component, OnInit, AfterViewInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';
import { SiteDashboardService } from '../site-dashboard.service';
import { TagsService } from '../tags.service';

@Component({
	selector: 'app-tags-management',
	templateUrl: './tags-management.component.html',
	styleUrls: ['./tags-management.component.css']
})
export class TagsManagementComponent implements OnInit, AfterViewInit, OnDestroy {

	currentUser: any = {};
	currentSite: any = {};
	siteId: number;
	siteTags: {public, private, group} = {
		public: [],
		private: [],
		group: []
	};
	siteSubscription: any;
	availableShareOptions: any[] = [];
	newTagName: any = "";
	newTagShareOption: any = this.tagsService.shareOptions.public;

	tag: any;
	tooltip: any;
	
	permissionsSub: Subscription = new Subscription();

	@ViewChild("removeTagModal") removeTagModal;

	constructor(
		private route: ActivatedRoute,
		private userService: UsersService,
		private sideMenu: SideMenuService,
		public tagsService: TagsService,
		private notificationMessage: NotificationMessageService,
		private siteDashboardService: SiteDashboardService,
		private commonService: CommonService,
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		this.siteId = +(this.route.parent.snapshot.params.siteId);

		this.siteSubscription = this.sideMenu.currentSite.subscribe(siteInfo => {
			this.currentSite = siteInfo;
		});

		this.getSiteTags();

		let availableShareOptions = [this.tagsService.shareOptions.public, this.tagsService.shareOptions.private];
		if(this.currentUser.isSCT)
			availableShareOptions.push(this.tagsService.shareOptions.group);

		this.availableShareOptions = availableShareOptions;

		this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data=> {
			this.tooltip = this.commonService.getTooltipKey('manage_site_tags', this.userService.userHasNOCAccess(data));
		});
	}

	ngAfterViewInit() {
		this.removeTagModal.onClose.subscribe((ok) => {
			if(!ok)
				return;

			this.tagsService.removeTagFromSite(this.siteId, this.tag).subscribe(() => {
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.updateTagsList(this.tag, 'delete');
			});
		});
	}
	
	ngOnDestroy() {
		if(this.siteSubscription)
			this.siteSubscription.unsubscribe();
		this.permissionsSub.unsubscribe();
	}

	getSiteTags() {
		if(!this.siteId)
			return;
		
		this.tagsService.getSiteTags(this.siteId).subscribe((response: any) => {
			this.siteTags.public	= response.public;
			this.siteTags.private	= response.private;
			this.siteTags.group		= response.group;
		});
	}

	editTagSharingOption(tag, newShareOption) {
		
		if(!this.siteId)
			return;

		if(newShareOption == this.tagsService.shareOptions.private) {
			if(tag.owner_id != this.currentUser.id) {
				this.notificationMessage.setMessage('translate|g.change_tag_not_allowed', {clearOnXTimeNavigate: 1});
				return;
			}
		}
		this.tagsService.updateTagShareOption(this.siteId, tag, newShareOption).subscribe(() => {
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.updateTagsList(tag, 'delete');
			tag.sharing_option = newShareOption;
			this.updateTagsList(tag, 'insert');
		});
	}

	updateTagsList(tag, operation) {
		var shareOption = tag.sharing_option;
		switch (operation) {
			case 'insert':
				switch(shareOption) {
					case this.tagsService.shareOptions.private:
						this.siteTags.private.push(tag);
					break;
					case this.tagsService.shareOptions.group:
						this.siteTags.group.push(tag);
					break;
					default:
						this.siteTags.public.push(tag);
					break;
				}
			break;
			case 'delete':
				var group;
				switch(shareOption) {
					case this.tagsService.shareOptions.private:
						group = this.siteTags.private;
					break;
					case this.tagsService.shareOptions.group:
						group = this.siteTags.group;
					break;
					default:
						group = this.siteTags.public;
					break;
				}

				var newList = [];
				group.forEach((currentTag) => {
					if (currentTag.id != tag.id) {
						newList.push(currentTag);
					}
				});

				switch(shareOption) {
					case this.tagsService.shareOptions.private:
						this.siteTags.private = newList;
					break;
					case this.tagsService.shareOptions.group:
						this.siteTags.group = newList;
					break;
					default:
						this.siteTags.public = newList;
					break;
				}
			break;
		}
	}

	siteTagAdded() {
		let tag = {
			tag_name: this.newTagName.trim(),
			site_id: this.siteId,
			sharing_option: this.newTagShareOption
		};

		this.tagsService.addTags(this.siteId, tag).subscribe((data: any) => {
			if(data.api_status) {
				let errMsg = 'globalErrMsg';
				switch(data.api_status) {
					case 2:
						this.newTagName = "";
						errMsg = 'translate|tags.duplicate_tag';
					break;
				}
				return this.notificationMessage.setMessage(errMsg, {clearOnXTimeNavigate: 1});
			}
			this.newTagName = "";
			this.newTagShareOption = this.tagsService.shareOptions.public;
			this.updateTagsList(data, 'insert');
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}

	tagRemoved(tag) {
		
		if(!this.siteId)
			return;

		this.tag = tag;
		this.removeTagModal.show();
	}
}