import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AdminService } from '../admin.service';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
	selector: 'app-wrong-events-logs',
	templateUrl: './wrong-events-logs.component.html',
	styleUrls: ['./wrong-events-logs.component.css']
})
export class WrongEventsLogsComponent implements OnInit, OnDestroy {

	calcs: any = {};

	fileName = 'wrong-events';

	intervalId: number = 0;

	@ViewChild('cb', { static: false }) cb!: ElementRef;

	constructor(
		private adminService: AdminService,
		private usersServices: UsersService,
		private router: Router
	) { }

	ngOnInit() {
		if(!this.usersServices.getCurrentUser().is_admin)
			this.router.navigate(['/unauthorized']);

		this.getCalculations();

		this.intervalId = setInterval(() => {
			this.getCalculations();
		}, 3000); // 1 second
	}

	getCalculations() {
		this.adminService.getWrongEventsCalculations().subscribe((data: any) => {
			this.calcs = Object.keys(data).length ? data : this.getDefaultCalcs();
		});
	}

	getDefaultCalcs() {
		return {
			smart: {
				before: {
					kwh: 0,
					duration: 0
				},
				after: {
					kwh: 0,
					duration: 0
				}
			},
			nonSmart: {
				before: {
					kwh: 0,
					duration: 0
				},
				after: {
					kwh: 0,
					duration: 0
				}
			},
			rmData: {
				devicesCount: 0,
				eventsCount: 0
			}
		};
	}

	ngOnDestroy(): void {
		clearInterval(this.intervalId);
	}
}
