<div class="main-black border-radius-bottom white-box">
	<div class="m-0 p-4">
		<div class="row p-1">
			<div class="col-sm-6 col-md-2">
				<h4>
					{{'nav.site_settings' | translate}}
				</h4>
			</div>
		</div>
		<div class="card">
			<div class="card-header"><strong>{{'working_days.set_working_days' | translate}}</strong></div>
			<div class="panel-body p-3">
				<div class="d-flex flex-row">
					<select [(ngModel)]="selectedWorkingDays" class="form-control inline-form-control mx-3" name="selectedWorkingDays">
						<option *ngFor="let option of workingDaysOptions" [value]="option">{{'working_days.options.'+option | translate}}</option>
					</select>
					<button
						class="btn btn-primary"
						[disabled]="!selectedWorkingDays"
						(click)="setSiteWorkingDays()"
					>{{'g.set' | translate}}</button>
				</div>
			</div>
		</div>

		<app-users-alert-settings></app-users-alert-settings>

		<div class="card mt-3">
			<div class="card-header"><strong>{{'site_settings.truck_end_voltages_chart_colors' | translate}}</strong></div>
			<div class="panel-body p-3">
				<div class="table-responsive">
					<div class="text-danger mt-2" *ngIf="!isValidVoltageLevel">{{ errorMassages | translate}}!</div>
					<table class="table">
						<thead>
							<tr>
								<th>{{ 'site_settings.level' | translate }}</th>
								<th>{{ 'site_settings.color' | translate }}</th>
								<th>{{ 'site_settings.min' | translate }}</th>
								<th>{{ 'site_settings.max' | translate }}</th>
							</tr>
						</thead>
						<tbody>
						<tr *ngFor="let level of voltageLevels; let i = index">
							<td>{{ i + 1 }}</td>
							<td><input type="color" [(ngModel)]="level.color" class="form-control"></td>
							<td><input type="number" [(ngModel)]="level.min" class="form-control"></td>
							<td><input type="number" [(ngModel)]="level.max" class="form-control"></td>
						</tr>
						</tbody>
					</table>
				</div>
				<div class="d-flex flex-row justify-content-end">
					<button
						class="btn btn-primary"
						[disabled]="!selectedWorkingDays"
						(click)="setVoltageLevelColors()"
					>{{'g.set' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>
