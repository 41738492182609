<div class="main-black border-radius-bottom white-box">
	<div class="m-0 p-4">
		<app-note *ngIf="siteId"
			[title]="'note.site_notes' | translate"
			[modelType]="'site'"
			[modelId]="siteId"
			[tooltipKey]="tooltip"
		></app-note>
	</div>
</div>
