import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { TagsService } from '../tags.service';
import { Tag } from './tag.model';

@Component({
	selector: 'app-tag-input',
	templateUrl: './tag-input.component.html',
	styleUrls: ['./tag-input.component.css']
})
export class TagInputComponent implements OnInit {
	@Input() addTag: boolean = true;
	@Input() isMultiple: boolean = true;
	@Input() addToSite: boolean = false;
	@Input() hideSelected: boolean = false;
	@Input() isSCTuser: boolean = false;
	@Input() items: any[] = [];
	@Input() deviceId: any = 0;
	@Input() siteId: number = 0;
	@Input() tagInput: any;
	@Input() placeholder: string = this.translateService.instant('tags.add_a_tag');
	@ViewChild("modal") modal;

	@Output() onUpdateTags = new EventEmitter<Object>(true);
	@Output() onAddTagFault = new EventEmitter<Object>(true);

	tag: Tag[] = null;
	tagText: string = '';
	tagSharingOptions: {id: string, text: string}[];
	tempTag: Tag = {tag_name: null};
	deviceTagsInfo: any = {};
	title: String;
	showSharingOption: boolean = true;
  	keepInReplacement: boolean = true;
  	maxLimitMsgTimer: number = 0;
	constants: any = {
		maxTermLength: 30,
		maxLimitMessageTimeout: 5
	};

	constructor(
		private notificationMessage: NotificationMessageService,
		public tagsService: TagsService,
		private translateService: TranslateService
	) {
		this.title = translateService.instant('g.settings');
	}

	ngOnInit() {
		if(!this.isMultiple)
			this.tag = null;

		this.getTagSharingOptions();

		if(this.addToSite)
			this.title = this.translateService.instant('tags.sharing_options');
	}

	ngAfterViewInit() {
		this.modal.onClose.subscribe((ok) => {
			if(ok && this.tempTag.sharing_option)
				return this.addTagFunction();

			if(this.isMultiple && this.tag)
				this.tag = this.tag.slice(0, this.tag.length-1); // remove only last selected tag
			else
				this.tag = null;
		});
	}

	ngOnChanges(changes) {
		if(changes.tagInput && changes.tagInput.currentValue && Array.isArray(changes.tagInput.currentValue)) {
			let newTags = changes.tagInput.currentValue;
			let deviceTagsInfo = {};
			newTags.forEach((item) => {
				deviceTagsInfo[item.tag_id] = item;
			});
			this.deviceTagsInfo = deviceTagsInfo;

			if(this.items.length)
				this.updateTagsObj(newTags);
		}
	}

	private updateTagsObj(newTags) {
		let items = {};
		this.items.forEach((item) => {
			items[item.id] = item;
		});

		let tags = [];
		newTags.forEach((item) => {
			tags.push(Object.assign(items[item.tag_id], item));
		});
		this.tag = tags;
	}

	change(tag) {
		if(this.isMultiple)
			return;

		this.add(tag);
	}

	add(tag) {
		if(typeof tag == 'string') {
			this.tempTag = {tag_name: tag}
			for(let item of this.items) {
				if(item.tag_name == tag) {
					this.tempTag = item;
					break;
				}
			}
		} else
			this.tempTag = tag;

		this.showSharingOption = this.addTag && (!this.tempTag || !this.tempTag.sharing_option);

		this.tempTag.tag_name = this.tempTag.tag_name.trim();
		if(this.tempTag.tag_name.length < 3) {
			this.tag = this.tag.slice(0, this.tag.length-1);
			this.notificationMessage.setMessage('translate|tags.invalid_tag_name', {clearOnXTimeNavigate: 1});
			this.onAddTagFault.emit({tag: tag});
			return;
		}
		var showKeepInReplacement = this.isSCTuser && !this.addToSite;
		if(this.showSharingOption || showKeepInReplacement) {
      		this.keepInReplacement= false;
			this.modal.show();
		} else {
			this.addTagFunction();
    	}
	}

	addTagFunction() {
	  this.tempTag.keep_in_replacement = this.keepInReplacement;
		if(this.addToSite) {
			this.tagsService.addTags(this.siteId, this.tempTag).subscribe((data: any) => {
				if(data.api_status) {
					let errMsg = 'globalErrMsg';
					switch(data.api_status) {
						case 2:
							errMsg = 'translate|tags.duplicate_tag';
						break;
					}
					return this.notificationMessage.setMessage(errMsg, {clearOnXTimeNavigate: 1});
				}
				this.tag = null;
				this.onUpdateTags.emit({type: 'add', tag: data});
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			});
		} else {
			this.tagsService.addDeviceTag(this.siteId, this.deviceId, this.tempTag).subscribe(
				(res: any) => {
					if(res.httpStatus == 'error') {
						this.notificationMessage.setMessage(res.msg, {clearOnXTimeNavigate: 1});
						this.tag = this.tag.slice(0, this.tag.length-1);
						this.items = this.items.slice(0, this.items.length-1);
					} else {
						res.id = +res.id;
						let newTagIdx = this.tag ? this.tag.length - 1 : 0;
						this.tag = this.tag || [];

						this.tag[newTagIdx] = this.tempTag;
						this.tag[newTagIdx].id = res.id;
						if (res.isNewTag)
							this.items.push(this.tag[newTagIdx]);
						this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
						this.onUpdateTags.emit({type: 'add', tag: res});
					}
				},
				() => {
					this.onAddTagFault.emit({tag: this.tempTag});
				}
			);
		}
	}

	checkReplacementTag(tag) {
		if(!this.isSCTuser)
			return false;
		
		if(tag.keep_in_replacement || (this.deviceTagsInfo[tag.id] && this.deviceTagsInfo[tag.id].keep_in_replacement))
			return true;
		
		return false;
	}

	removeTag(event) {
		this.tagsService.removeDeviceTag(this.siteId, this.deviceId, this.items[event.index]).subscribe(
			() => {
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.onUpdateTags.emit({type: 'remove', tag: this.items[event.index]});
			}
		);
	}

	checkAddTag = (term) => {
		return new Promise((resolve) => {
			if(!this.addTag)
				return resolve(null);

			if (term.length <= this.tagsService.constants.maxTermLength) {
				this.maxLimitMsgTimer = 0;
				return resolve({tag_name: term});
			}
			this.maxLimitMsgTimer = this.tagsService.constants.maxLimitMessageTimeout;
			let interval = setInterval(() => {
				if(this.maxLimitMsgTimer > 0)
					this.maxLimitMsgTimer--;
				else
					clearInterval(interval);
			}, 1000);

			return resolve(null);
		});
	}
	private getTagSharingOptions() {
		this.tagSharingOptions = [
			{id:this.tagsService.shareOptions.public, text: this.translateService.instant('tags.public')},
			{id:this.tagsService.shareOptions.private, text: this.translateService.instant('tags.private')}
		];
		if (this.isSCTuser)
			this.tagSharingOptions.push({id:this.tagsService.shareOptions.group, text: this.translateService.instant('tags.group')});
	};
}