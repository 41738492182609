import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { DeviceManagementService } from '../device-management.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/shared/services/navigation.service';
import { UsersService } from 'src/app/users/users.service';
import { SitesService } from 'src/app/sites/sites.service';
import * as _ from 'lodash';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-manage-device-page',
	templateUrl: './manage-device-page.component.html',
})
export class ManageDevicePageComponent implements OnInit {
	@Input() devicesIds: any = '';
	@Input() render: string = "full";
	@Input() fromAdminPanel: boolean = false;
	@Output() deviceOwnerSaved = new EventEmitter<void>();

	processSteps = {
		selectDevicesOwner: 1,
		confirmation: 2
	}
	stepsCount = 1;
	selectedDevices: any = [];
	confirmedDevicesSerials = []
	deviceInfo: any = {};
	customers: any = [];
	sites: any = [];
	enterpriseList: any = [];
	customer: any = {};
	site: any = {};
	serialNumbers = [];
	originalOwners: {site_id, customer_id};
	sitesById: any = {};
	tmpDevicesIds: string = '';
	customerEnterprises: any = [];
	newCustomer	= {id: 0, customer_name: this.translateService.instant('site.new_customer'), sites: [{'id': 0, 'name': this.translateService.instant('site.new_site')}]};
	newSite		= {id: 0, name: this.translateService.instant('site.new_site')};
	siteEnterprises: any = [];

	originalDeviceSiteId: number;
	powerStudySiteObj: any = {};
	@ViewChild("updateSetupDone") updateSetupDone;

	constructor(
		private deviceManagementService: DeviceManagementService,
		private route: ActivatedRoute,
		private router: Router,
		private notificationMessage: NotificationMessageService,
		private navigation: NavigationService,
		private sideMenuService: SideMenuService,
		private enterpriseService: EnterpriseService,
		private chRef: ChangeDetectorRef,
		private translateService: TranslateService,
		private deviceService: DeviceService,
		public usersService: UsersService,
		public sitesService: SitesService,
		private el: ElementRef,
		private renderer: Renderer2,
		private commonService: CommonService
	){}

	ngOnInit() {
		if(this.render == "full") {
			this.sideMenuService.hide();
		}
		this.route.params.subscribe(param => {
			this.devicesIds = param.deviceId || '';
			this.devicesIds = this.devicesIds.split(',');
			this.deviceManagementService.getDeviceOwners(this.devicesIds, { allowEmpty: this.fromAdminPanel }).subscribe(
				data => this.init(data)
			);
		});
		this.getEnterpriseList();
		this.site.installation_date = new Date(new Date().setHours(0, 0, 0, 0));
	}

	init(response) {
		this.tmpDevicesIds = this.devicesIds.join(',');
		this.serialNumbers = response.devices.map(device => device.serial_number);
		this.deviceInfo = response.devices[0] || {customer_id: 0, site_id: 0};
		this.powerStudySiteObj = response.power_studies_site;
		if(!this.fromAdminPanel) {
			this.originalDeviceSiteId = this.deviceInfo.site_id;
		}

		this.customers  = response.customersAndSites.sort((item1, item2) => item1.customer_name.toLowerCase() > item2.customer_name.toLowerCase() ? 1 : -1);
		this.customers = (this.hasAccessOnAddNewCustomer() ? [this.newCustomer] : []).concat(this.customers);
		let deviceSiteCustomerId = 0;

		for(let customer of this.customers){
			customer.sites.unshift(this.newSite);
			for(let site of customer.sites){
				if(site.id == this.deviceInfo.site_id){
					deviceSiteCustomerId = customer.id;

					this.sites = customer.sites.filter((site) => site.is_special !== true).sort((item1, item2) => item1.name.toLowerCase() > item2.name.toLowerCase() ? 1 : -1);
					this.deviceInfo.customer_id = customer.id;
					this.deviceInfo.oldCustomerId = customer.id;

					this.originalOwners = {
						site_id: this.deviceInfo.site_id,
						customer_id: this.deviceInfo.customer_id
					};
				}
				// exclude newSite site
				if(site.id)
					this.sitesById[site.id] = site;
			}
		}

		this.getCustomerSites(deviceSiteCustomerId);
	}

	handlePowerStudySites(customer) {
		const customerStudySite = customer.sites.find(site => site.name === this.commonService.powerStudiesDevicesSiteName) || this.powerStudySiteObj;

		this.sites = [customerStudySite];
		this.site = customerStudySite;
		this.deviceInfo.oldSiteId = this.deviceInfo.site_id;
		this.deviceInfo.site_id = this.site.id;
	}

	hasAccessOnAddNewCustomer() {
		return this.usersService.hasAccessFunction('customer_management') && this.usersService.hasAccessFunction('site_management');
	}

	getCustomerSites(customerId) {
		this.sites = [];
		this.site.customer_enterprises = [];
		this.customerEnterprises = [];
		this.deviceInfo.site_id = this.deviceInfo.is_study_device? this.deviceInfo.site_id : 0;
		if(!customerId && this.usersService.hasAccessFunction('site_management')){
			this.sites = [this.newSite];
			return;
		}

		for(let customer of this.customers){
			if(customer.id == customerId){
				if (this.deviceInfo.is_study_device)
					return this.handlePowerStudySites(customer);

				if (!this.usersService.hasAccessFunction('super_admin', 'write'))
					customer.sites = customer.sites.filter((site) =>
						(!site.is_special &&
							(this.usersService.hasAccessFunction('device_management') ||
								!this.usersService.hasAccessFunction('sct_inventory_device_management') ||
								(this.originalDeviceSiteId === this.sitesService.sctInventorySiteId)
							)) ||
						(site.id === this.sitesService.sctInventorySiteId && this.usersService.hasAccessFunction('sct_inventory_device_management'))
					);

				this.sites = customer.sites.sort((item1, item2) => item1.name.toLowerCase() > item2.name.toLowerCase() ? 1 : -1);
				this.handleCustomerEnterprises(customer);
				break;
			}
		}
	}

	isSelectedSiteIsSpecial(siteId) {
		if(this.sitesById[siteId])
			return this.sitesById[siteId].is_special;

		if(this.site)
			return this.site.is_special;

		return false;
	}

	onBeforeSaveDeviceOwners() {
		if(this.fromAdminPanel) {
			return this.getDevicesInfoBySerialNumber();
		}

		if (this.shouldShowSetupPopup()) {
			return this.updateSetupDone.show();
		}
		return this.saveDeviceOwners(false);
	}

	shouldShowSetupPopup() {
		if(this.selectedDevices.length)
			return this.selectedDevices.some(device => !device.setup_done);

		return !this.deviceInfo.setup_done && !this.isSelectedSiteIsSpecial(this.deviceInfo.site_id) &&
			(this.originalOwners.customer_id != this.deviceInfo.customer_id || this.originalOwners.site_id != this.deviceInfo.site_id);
	}

	showSetupPopup() {
		if(this.shouldShowSetupPopup())
			return this.updateSetupDone.show();

		this.updateSetupDone.hide();
		this.saveDeviceOwners(false);
	}

	getDevicesInfoBySerialNumber() {
		this.devicesIds = this.tmpDevicesIds.replace(/ /g,'').replace(/\n/g,'').split(',');
		this.deviceService.getDevicesInfoBySerialNumber(this.devicesIds).subscribe((data: any) => {
			if (!data.length)
				return this.notificationMessage.setMessage('translate|g.invalid_serial_numbers',{clearOnXTimeNavigate: 1, type: 'warning'});

			this.stepsCount = this.processSteps.confirmation;
			this.selectedDevices = data;
			this.confirmedDevicesSerials = this.selectedDevices.filter(device => device.site_id != this.deviceInfo.site_id).map(device => device.serial_number);
		})
	}

	confirmAll() {
		if(this.confirmedDevicesSerials.length)
			this.confirmedDevicesSerials = [];

		else this.confirmedDevicesSerials = this.selectedDevices.filter(device => device.site_id != this.deviceInfo.site_id).map(device => device.serial_number);
	}

	toggleConfirmedDevice(device: any) {
		if (this.confirmedDevicesSerials.includes(device.serial_number))
			this.confirmedDevicesSerials = this.confirmedDevicesSerials.filter(SN => SN != device.serial_number);

		else if (device.site_id != this.deviceInfo.site_id)
			this.confirmedDevicesSerials.push(device.serial_number);
	}

	inTheSameSite(device: any) {
		return device.site_id == this.deviceInfo.site_id;
	}

	getSelectedCustomerName() {
		if (this.deviceInfo.customer_id) {
			const customer = this.customers.filter(customer => customer.id == this.deviceInfo.customer_id).map(customer => customer.customer_name);
			return customer[0];
		}

		return this.customer.customer_name;
	}

	saveDeviceOwners(changeSetupDone = false) {
		let tempSite = Object.assign({}, this.site);
		let insDate = new Date(tempSite.installation_date);

		if(this.fromAdminPanel)
			this.devicesIds = this.confirmedDevicesSerials;

		if(this.devicesIds.length > 1)
			delete this.deviceInfo.oldCustomerId;

		tempSite.installation_date = Math.floor((-insDate.getTimezoneOffset() * 60000 + insDate.getTime()) / 1000);
		this.deviceManagementService.saveDeviceOwners(this.deviceInfo, this.customer, tempSite, this.devicesIds, changeSetupDone, this.fromAdminPanel).subscribe((res: any)=> {
			this.confirmedDevicesSerials = [];

			switch (res.api_status) {
				case 2:
					const form = this.el.nativeElement;
					const ele = form.querySelectorAll('[name=name]');
					if(ele && ele[0])
						this.renderer.addClass(ele[0], 'invalid-input');

					return this.notificationMessage.setMessage('translate|site.reserved_site_name', { clearOnXTimeNavigate: 1 });
				case 3:
					return this.notificationMessage.setMessage('translate|site.site_not_found', { clearOnXTimeNavigate: 1, type: 'danger' });
				case 4:
					return this.notificationMessage.setMessage('translate|customer.customer_name_already_exist', { clearOnXTimeNavigate: 1, type: 'danger' });
				case 5:
					return this.notificationMessage.setMessage('translate|site.site_name_already_exist', { clearOnXTimeNavigate: 1, type: 'danger' });
				default:
					break;
			}

			if(this.render == 'full') {
				this.notificationMessage.setMessage('globalSuccessMsg');
				if(!this.fromAdminPanel)
					return this.goBack(this.devicesIds, res.customer_id, res.site_id);

				window.location.reload();
			} else {
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			}
			this.deviceOwnerSaved.emit();
		});
	};

	updateSiteCoords(coords) {
		this.site.latitude = coords.lat;
		this.site.longitude = coords.lng;
		this.site.address = coords.address;
		this.site.country_id = coords.country;
		this.site.state_id = coords.state;
		this.chRef.detectChanges();
	}

	getEnterpriseList() {
		this.enterpriseService.getEnterprises({site_edit: true, source: 'manage_device'}).subscribe(enterpriseData=> {
			this.enterpriseList = enterpriseData;
		});
	}

	handleCustomerEnterprises(customer) {
		this.site.customer_enterprises = customer.customer_enterprises || [];
		this.customerEnterprises = Array.isArray(customer.customer_enterprises) 
			? this.enterpriseList.filter((item) => customer.customer_enterprises.includes(item.id))
			: [];

		this.siteEnterprises = this.enterpriseList.filter((item) => !this.site.customer_enterprises.includes(item.id));
	}

	goBack(devicesId?, customerId?, siteId?){
		if (this.stepsCount == this.processSteps.confirmation)
			return this.stepsCount = this.processSteps.selectDevicesOwner;

		if(!this.usersService.hasAccessFunction('device_management')) {
			return this.router.navigate(['/sites-map']);
		}
		if(!devicesId || devicesId.length > 1)
			return this.navigation.goBack(['/device/search']);

		this.router.navigate(['/', customerId, siteId, devicesId[0]]);
	}
}
