import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { StudiesService } from '../studies.service';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { SitesService } from 'src/app/sites/sites.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';

@Component({
	selector: 'app-new-study',
	templateUrl: './new-study.component.html',
	styleUrls: []
})
export class NewStudyComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
	@Input() studies = [];
	@Input() parent: 'device'|'admin'|'customer'|'site'|'study' = 'admin';
	@Input() customerId = 0;
	@Input() siteId = 0;
	@Input() deviceId = '';
	@Input() studyInfo;
	@Input() device;

	@Output() onClose = new EventEmitter<number>();

	generalError = this.translateService.instant('g.field_is_required');
	customers: any[] = [];
	sites: any[] = [];
	devices: any[] = [];
	devicesOngoingStudies: any = {};

	newStudyFormData = {
		studyName: '',
		customerId: '',
		siteId: '',
		serialNumber: ''
	};

	newStudyFormErrorsFields = {
		studyName: { hasErr: false, msg: this.generalError },
		customerId: { hasErr: false, msg: this.generalError },
		siteId: { hasErr: false, msg: this.generalError },
		serialNumber: { hasErr: false, msg: this.generalError }
	};

	resultCode = {
		not_found: 2,
		study_already_exists: 3,
		not_iotah_device: 4,
		site_not_found: 5,
		has_queued_study: 6,
	};

	formError = null;


	@ViewChild('newStudyForm') newStudyForm: ModalComponent;

	constructor(
		private studiesService: StudiesService,
		private translateService: TranslateService,
		public usersService: UsersService,
		public sitesService: SitesService
	) {}

	ngOnInit() {
		this.getData();
	}

	ngOnChanges(changes: SimpleChanges): void {
		this.openNewStudyForm();
	}

	ngAfterViewInit(): void {
		this.newStudyForm.show();
		this.newStudyForm.onClose.subscribe((ok) => {
			if(ok) {
				this.newStudyForm.closeOnConfirm = false;
				if(!this.newStudyFormValidate()) {
					return;
				}
				this.newStudyFormSubmit();
			} else {
				this.onClose.emit(0);
				this.newStudyForm.hide();
				this.newStudyFormReset();
			}
		});
	}

	openNewStudyForm() {
		if(['customer', 'site'].includes(this.parent)) {
			this.newStudyFormData.customerId = +this.customerId as any;
			this.getCustomerSites(+this.customerId)
		}

		if(['site'].includes(this.parent))
			this.newStudyFormData.siteId = +this.siteId as any;

		if(['study', 'device'].includes(this.parent))
			this.newStudyFormData.serialNumber = this.device.mac_address;

		if(this.newStudyForm)
			this.newStudyForm.show();
	}

	getData() {
		this.sitesService.getUserSitesInfo({get_customers: true}).subscribe(
			(data: any) => {
				this.customers  = data.customers.sort((item1, item2) => item1.customer_name.toLowerCase() > item2.customer_name.toLowerCase() ? 1 : -1);

				this.studiesService.getAllStudyDevices().subscribe((res: any) => {
					this.devices = res.devices;
					this.devicesOngoingStudies = res.ongoingStudies;
					this.checkOngoingStudies();
					if(+this.customerId)
						this.getCustomerSites(+this.customerId);
				});
			}
		);
	}

	checkOngoingStudies() {
		[...this.devices].forEach(device => {
			device.label = device.serial_number;
			const ongoingStudyName = this.devicesOngoingStudies[device.mac_address];

			if (ongoingStudyName)
				device.label += ` (Ongoing Study: ${ongoingStudyName})`;
		});
	}

	getCustomerSites(customerId) {
		this.sites = [];
		for(let customer of this.customers) {
			if(customer.id == customerId) {
				this.sites = customer.sites.sort((item1, item2) => item1.name.toLowerCase() > item2.name.toLowerCase() ? 1 : -1);
				break;
			}
		}
	}

	newStudyFormReset() {
		this.newStudyFormData = {
			studyName: '',
			customerId: '',
			siteId: '',
			serialNumber: ''
		};
		this.newStudyFormErrorsFields = {
			studyName: { hasErr: false, msg: this.generalError },
			customerId: { hasErr: false, msg: this.generalError },
			siteId: { hasErr: false, msg: this.generalError },
			serialNumber: { hasErr: false, msg: this.generalError }
		};
	}

	newStudyFormValidate() {
		let flag = true;
		this.newStudyFormData.studyName = this.newStudyFormData.studyName.trim();
		this.newStudyFormErrorsFields = {
			studyName: { hasErr: false, msg: this.generalError },
			customerId: { hasErr: false, msg: this.generalError },
			siteId: { hasErr: false, msg: this.generalError },
			serialNumber: { hasErr: false, msg: this.generalError }
		};
		for(const field of Object.keys(this.newStudyFormData))
			if(!this.newStudyFormData[field] || this.newStudyFormData[field] == '') {
				this.newStudyFormErrorsFields[field].hasErr = true;
				flag = false;
			}
		return flag;
	}

	newStudyFormSubmit() {
		const formData = {
			name: this.newStudyFormData.studyName,
			customer_id: this.newStudyFormData.customerId,
			site_id: this.newStudyFormData.siteId,
			mac_address: this.newStudyFormData.serialNumber,
			studyId: this.studyInfo?.id || 0,
		};
		this.formError = null;
		this.studiesService.requestPowerStudy(formData).subscribe((res: any) => {
			if(res.api_status > 1) {
				switch(res.api_status) {
					case this.resultCode.not_found:
						this.newStudyFormErrorsFields.serialNumber.hasErr = true;
						this.newStudyFormErrorsFields.serialNumber.msg = this.translateService.instant('rejection_log.device_not_found');
						break;
					case this.resultCode.study_already_exists:
						this.newStudyFormErrorsFields.studyName.hasErr = true;
						this.newStudyFormErrorsFields.studyName.msg = this.translateService.instant('studies.study_already_exists');
						break;
					case this.resultCode.has_queued_study:
						this.formError = this.translateService.instant('studies.has_queued_study');
						break;
					case this.resultCode.not_iotah_device:
						this.formError = this.translateService.instant('studies.not_iotah_device');
						break;
					case this.resultCode.site_not_found:
						this.formError = this.translateService.instant('site.site_not_found');
						break;
				}
				return;
			}
			this.newStudyForm.hide();
			this.newStudyFormReset();
			this.onClose.emit(res[0]?.id);
		});
	}

	ngOnDestroy() {
		this.newStudyForm.hide();
	}
}
