<div>
	<h4>{{'note.noc_status_note' | translate}}</h4>
	<table class="table">
		<tr>
			<td *ngIf="this.usersService.hasAccessPermission(null, 'noc', 'write')">
				<button type="button" class="btn btn-primary edit" (click)="openNoteModal()">
					<i [ngClass]="'fa fa-edit fa-lg'" class="fa-fw" data-toggle="tooltip" data-placement="top" title="{{'g.edit' | translate}}"></i>
				</button>
			</td>
			<td >{{formattedDate}}</td>
			<td class="w-50">{{formattedNote || 'note.no_note' | translate}}</td>
		</tr>
	</table>
</div>

<app-modal #noteModal title="{{'action_log.update_noc_status_note' | translate}}">
	<div dialog-content class="w-100 form-group">
		<textarea class="form-control" name="note" id="note" cols="30" rows="10" [(ngModel)]="newNote"></textarea>
	</div>
</app-modal>
