import { Component, Input, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
	selector: 'app-device-attachments',
	templateUrl: './device-attachments.component.html',
	styleUrls: ['./device-attachments.component.css']
})
export class DeviceAttachmentsComponent implements OnInit {

	@Input() device: any;

	macAddress: string = '';
	source: string = 'device';

	constructor(
	) { }

	ngOnInit() {}
	
	ngOnChanges() {
		this.macAddress = this.device.mac_address;
	}
}
