import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class SiteDashboardService {

	activePage = 'site-info';

	route = '/site/';
	public readonly voltageLevels: any = [
		{
			min: 1.90,
			max: 1.99,
			color: '#dc3912'
		},{
			min: 1.99,
			max: 2.01,
			color: '#ff9900'
		},{
			min: 2.01,
			max: 2.10,
			color: '#109618'
		}
	];

	private permissionsOfCurrentSiteSbj = new BehaviorSubject<Object>({});
	permissionsOfCurrentSite = this.permissionsOfCurrentSiteSbj.asObservable();

	constructor(
		private httpClient: HttpClient
	) { }
	
	setActiveTab(tab: string) {
		this.activePage = tab;
	}

	getSiteData(siteId) {
		return this.httpClient.post(this.route + 'getSiteData', {siteId}, {
			observe: "body"
		});
	}

	getTruckUsageData(siteId, type, {truckYear, truckType, truckTag, parentPage}) {
		return this.httpClient.post(this.route + 'getTruckUsageData', {siteId, type, truckYear, truckType, truckTag, parentPage}, {
			observe: "body"
		});
	}

	setPermissionsOfCurrentSite(data) {
		this.permissionsOfCurrentSiteSbj.next(data);
	}
	
	siteHasIotahOrChargeLinkDevices(siteId) {
		return this.httpClient.get(this.route + 'siteHasIotahOrChargeLinkDevices/' + siteId);
	}
}