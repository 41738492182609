import { Component, OnInit, Renderer2, ViewChild, ElementRef } from '@angular/core';
import { Router } from "@angular/router";
import { UsersService } from '../users.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { NgForm } from '@angular/forms';
import { CaptchaComponent } from '../captcha/captcha.component';
import { LoaderInterceptorService } from '../../shared/loader/loader-interceptor.service';
import { ValidationService } from 'src/app/shared/services/validation.service';

@Component({
	selector: 'app-register',
	templateUrl: './register.component.html'
})
export class RegisterComponent implements OnInit {
	@ViewChild('captchaComponent') captchaComponent: CaptchaComponent;
	@ViewChild("registerRequestForm") registerRequestForm:NgForm;
	@ViewChild('nameComponent', { static: false }) nameComponent: ElementRef;
	@ViewChild('emailComponent', { static: false }) emailComponent: ElementRef;

	user: any = {};
	isValidEmail = false;

	constructor(
		private router: Router,
		private loader: LoaderInterceptorService,
		private usersService: UsersService,
		private notificationMessage: NotificationMessageService,
		private validationService: ValidationService,
		private renderer: Renderer2,
		private elementRef: ElementRef,
	) {
		this.notificationMessage.closeNotification();
	}

	ngOnInit() {
		if(this.usersService.isLoggedIn())
			this.router.navigate(['/']);
	}

	ngAfterViewInit() {
		this.nameComponent.nativeElement.focus();
	}

	goBack() {
		this.router.navigate(['/login']);
	}

	registerRequest() {
		if(this.validationService.isPublicMail(this.registerRequestForm.value.email)) {
			this.highlightInvalidFields(['email']);
			return this.notificationMessage.setMessage('translate|g.public_mails', {clearOnXTimeNavigate: 1});
		}

		this.usersService.registerRequest(this.registerRequestForm.value, this.captchaComponent.captchaKey, this.captchaComponent.captchaText).subscribe((data: any) => {
			if(data.response_status == this.loader.resStatus.invalid_captcha) {

				this.captchaComponent.getCaptcha();
			} else {
				switch (data) {
					case 1:
						this.router.navigate(['/landing'],  { state: { isLandingSuccessfully: true } })
						break;
					case 2:
						this.notificationMessage.setMessage('translate|g.invalid_input', {clearOnXTimeNavigate: 1});
						break;
					case 3:
						this.notificationMessage.setMessage('translate|users.email_used', {clearOnXTimeNavigate: 1});
						this.highlightInvalidFields(['email']);
						break;
					case 4:
						this.notificationMessage.setMessage('translate|users.phone_used', {clearOnXTimeNavigate: 1});
						break;
					case 5:
						this.notificationMessage.setMessage('translate|g.public_mails', {clearOnXTimeNavigate: 1});
						this.highlightInvalidFields(['email']);
					break;
				}
			}
		});
	}

	highlightInvalidFields(invalidFields) {
		let forms = this.elementRef.nativeElement.querySelectorAll('form');

		forms.forEach((form) => {

			for(let idx in form) {
				let ele = form[idx];
				if(Number.isInteger(Number(idx)))
					if(invalidFields.indexOf(ele.getAttribute('name')) != -1)
						this.renderer.addClass(ele, 'invalid-input');
			}
		});
	}

	checkEmailValidation() {
		const email = this.emailComponent.nativeElement.value;
		this.isValidEmail = this.validationService.dataValidator('email', {allowEmpty: true}, email);
	}
}
