import { Component, OnInit, OnDestroy } from '@angular/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { SiteDashboardService } from './site-dashboard.service';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';
import { StudiesService } from 'src/app/study/studies.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-site-dashboard',
	templateUrl: './site-dashboard.component.html',
	styleUrls: ['./site-dashboard.component.css']
})
export class SiteDashboardComponent implements OnInit, OnDestroy {

	customerId = 0;
	siteId = 0;
	customer: any = {};
	site: any = {};
	enterprisePermissions: any = {};
	activeTab: string;
	currentUser: any = {};

	dataSub: any;
	data = {};
	permissionSub: Subscription = new Subscription();
	isChargLinkDomain: boolean = false;
	sitePages: any[] = [
		'site-info',
		'devices-listing',
		'site-performance',
		'site-chargers-performance',
		'site-notes',
		'site-warnings',
		'tags-management',
		'maintenance-report',
		'site-warranty',
		'working-days',
		'site-attachments',
		'site-studies',
	];

	site_has_iotah_devices: boolean = false;
	site_has_chargLink_devices: boolean = false;
	hasStudies: boolean = false;

	constructor(
		private route: ActivatedRoute,
		public usersService: UsersService,
		private sideMenuService: SideMenuService,
		public siteDashboardService: SiteDashboardService,
		private router: Router,
		private studiesService: StudiesService,
		private commonService: CommonService
	) { }

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();
		this.isChargLinkDomain = this.commonService.isChargeLink();
		this.siteDashboardService.activePage = (this.sitePages.includes(this.route.snapshot.firstChild.data.pageName) ? this.route.snapshot.firstChild.data.pageName : 'device-dash');
		this.siteId = parseInt(this.route.snapshot.params['siteId']);

		this.dataSub = this.sideMenuService.data.subscribe((data:any) => {
			if (data && data.length > 0) {
				this.customerId = parseInt(this.route.snapshot.params['customerId']);
				if (!_.isEqual(this.data, data)) {
					this.data = data;
					this.setGlobalSite(data);
					this.getEnterprisePermission();
				}
			}
		});
		this.siteHasIotahOrChargeLinkDevices();

		if (this.currentUser.has_access_to_any_study)
			this.checkHasStudies();
	}

	onActivate(activeComponent) {
		let tab = '';
		let componentName = activeComponent.constructor.name;

		switch(componentName) {
			case 'SiteInfoComponent':
				tab = 'site-info';
				break;
			case 'DevicesListingComponent':
				tab = 'devices-listing';
				break;
			case 'DeviceDashboardComponent':
				tab = 'analytics-settings';
				break;
			case 'SiteNotesComponent':
				tab = 'site-notes';
				break;
			case 'TagsManagementComponent':
				tab = 'tags-management';
				break;
			case 'MaintenanceReportComponent':
				tab = 'maintenance-report';
				break;
			case 'SiteWarrantyComponent':
				tab = 'site-warranty';
				break;
			case 'WorkingDaysComponent':
				tab = 'working-days';
				break;
			case 'SiteAttachmentsComponent':
				tab = 'site-attachments';
				break;
			case 'SiteStudiesComponent':
				tab = 'site-studies';
				break;
			default:
				break;
		}

		this.activeTab = tab;
	}

	getEnterprisePermission() {
		this.permissionSub = this.usersService.getPermissions(this.siteId).subscribe((permissions: any) => {
			this.enterprisePermissions = permissions;
			this.siteDashboardService.setPermissionsOfCurrentSite(permissions);
		});
	}

	setGlobalSite(data) {
		const customers = _.indexBy(data, "id");
		this.customer = customers[this.customerId];

		if (!this.customer)
			return this.redirectToRightCustomerId(data);

		this.sideMenuService.setCurrentCustomer(customers[this.customerId]);
		let sites = {};
		if(this.customer) {
			sites = _.indexBy(this.customer.sites, "id");
			this.site = sites[this.siteId]
			if (!this.site)
				return this.redirectToRightCustomerId(data);
		}
		this.sideMenuService.setCurrentSite(sites[this.siteId] || {});
	}

	redirectToRightCustomerId(data) {
		let customerId, siteId, child, queryParams;

		for (let customer of data) {
			for (let site of customer.sites) {
				if (site.id == this.siteId) {
					customerId = customer.id;
					siteId = site.id;
					child = this.route.firstChild.routeConfig.path;
					if (child) {
						queryParams = this.route.firstChild.snapshot.queryParams;
						let deviceId = this.route.firstChild.snapshot.params['deviceId'];
						if (deviceId)
							child = child.replace(":deviceId", deviceId);
					}
					break;
				}
			}
		}
		if (!customerId || !siteId)
			return;

		if (!child)
			return this.router.navigate(['/', customerId , siteId]);

		let qp = '';
		if (queryParams && Object.keys(queryParams).length > 0) {
			qp += '?';
			for (let p in queryParams) {
				qp = qp + p + '='+ queryParams[p];
			}
		}
		return this.router.navigateByUrl('/'+ customerId + '/' + this.siteId + '/'  + child + qp);
	}

	ngOnDestroy() {
		this.permissionSub.unsubscribe();
		if(this.dataSub)
			this.dataSub.unsubscribe();
	}

	siteHasIotahOrChargeLinkDevices() {
		this.siteDashboardService.siteHasIotahOrChargeLinkDevices(this.siteId).subscribe((res: any) => {
			this.site_has_iotah_devices = !!res.iotah_devices;
			this.site_has_chargLink_devices = !!res.chargLink_devices;

			if (this.siteDashboardService.activePage == 'site-performance' && !res.iotah_devices)
				this.router.navigateByUrl('/404');
			if (this.siteDashboardService.activePage == 'site-chargers-performance' && !res.chargLink_devices)
				this.router.navigateByUrl('/404');
		});
	}

	checkHasStudies() {
		this.studiesService.checkHasStudies(this.siteId).subscribe((res: any) => {
			this.hasStudies = res;
		});
	}
}
