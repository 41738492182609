<nav class="navbar sticky-top navbar-expand-xl header-style navbar-light" *ngIf="header.visible">
	<div class="navbar-brand" [ngClass]="{'bg-white side-menu-shadow': visibleSideMenu, 'main-bg no-box-shadow': !visibleSideMenu}">
		<div [ngClass]="headerLogo" routerLink="/"></div>
	</div>

	<button class="navbar-toggler navbar-toggler-right ml-auto" type="button" (click)="toggleHeader()">
		<span class="navbar-toggler-icon"></span>
	</button>

	<div class="collapse navbar-collapse pl-4" [ngClass]="{'show': showHeader}" *ngIf="usersService.isLoggedIn()">

		<div class="navbar-nav mr-auto" *ngIf="!user.force_password_change">
			<form (ngSubmit)="searchDevices()" *ngIf="header.SearchBoxVisible && !hideHeader">
				<div class="search-box">
					<div class="search-input">
						<input #searchInput type="text" class="form-control" name="searchString" [(ngModel)]="searchString" placeholder="&#xF002; {{'g.mac_or_serial' | translate}}">
					</div>
					<div class="search-filter" *ngIf="searchString">
						<fa-icon icon="sliders-h" size="md" class="pointer" (click)="searchDevices()"></fa-icon>
					</div>
				</div>
			</form>
		</div>

		<ul class="navbar-nav ml-auto">
			<li class="nav-item dropdown header-user-options" appDropdown>
				<a class="nav-link pr-0">
					<span class="mr-1">{{ (user.full_name?.length > 14) ? (user.full_name | slice:0:14)+'.' : (user.full_name) }}</span>
					<mat-icon class="user-arrow">
						keyboard_arrow_down
					</mat-icon>
				</a>
				<ul class="dropdown-menu dropdown-menu-right main-bg">
					<ng-container *ngIf="!user.force_password_change">
						<li class="nav-item p-0" *ngIf="!hideHeader">
							<a class="nav-link pl-1" routerLink="/user/edit-settings">{{'login_register.user_settings' | translate}}</a>
						</li>
						<li class="nav-item p-0" *ngIf="!hideHeader">
							<a class="nav-link pl-1" routerLink="/change-user-password">{{'login_register.change_password' | translate}}</a>
						</li>
						<li class="nav-item p-0" *ngIf="!isProduction && usersService.hasAccessFunction('dev_team') && !hideHeader">
							<a class="nav-link pl-1" (click)="enableFullAccessMode()">{{'nav.enable_full_access_mode' | translate}}</a>
						</li>
					</ng-container>
					<li class="nav-item p-0">
						<a class="nav-link pl-1" (click)="logout()">{{'login_register.logout' | translate}}</a>
					</li>
				</ul>
			</li>
		</ul>

	</div>

	<div class="navbar-nav ml-auto mr-3" *ngIf="!usersService.isLoggedIn()">
		<a class="nav-link pl-1" routerLink="/login">{{'login_register.log_in' | translate}}</a>
	</div>
</nav>