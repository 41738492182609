import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { NgForm, ControlContainer } from '@angular/forms';

@Component({
  selector: 'app-customer-edit',
  templateUrl: './customer-edit.component.html',
  styleUrls: ['./customer-edit.component.css'],
  viewProviders: [ { provide: ControlContainer, useExisting: NgForm } ]
})
export class CustomerEditComponent implements OnInit {

	@Input() customer: any;
	@Input() miniForm: boolean = false;
	@Input() enterpriseList: any[] = [];
	constructor() { }

	ngOnInit() {
	}
}
