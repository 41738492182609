<div class="row">
	<div class="col-lg-6 col-md-6 col-sm-12">
		<h2>{{'shipment.check_shipment' | translate}}</h2>
		<form #searchDeviceForm="ngForm" (ngSubmit)="getDevices()">
			<small class="text-danger">{{'shipment.csv_file_rules_shipment_quality_msg' | translate}}</small>
			<div class="custom-file my-2">
				<input class="custom-file-input" type="file" id="shipmentBulkIds" accept=".csv" (change)="handleUploadCSVFile($event)">
				<label class="custom-file-label" #labelImport for="shipmentBulkIds">{{'shipment.import_shipment_quality_devices_csv' | translate}}</label>
			</div>
			<div class="mt-4">
				<div class="my-2">
					<input [disabled]="disableSearch" name="searchString" [(ngModel)]="searchString" trim-text placeholder="{{'g.serial_number' | translate}}/{{'g.mac_address' | translate}}" class="form-control" required/>
				</div>
				<div class="mt-3 input-group">
					<div class="form-check form-check-inline">
						<input [disabled]="disableSearch" class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio1" value="sn">
						<label class="form-check-label" for="inlineRadio1">{{'g.serial_number' | translate}}</label>
					</div>
					<div class="form-check form-check-inline">
						<input [disabled]="disableSearch" class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio2" value="mac">
						<label class="form-check-label" for="inlineRadio2">{{'g.mac_address' | translate}}</label>
					</div>
				</div>
				<div class="mt-3">
					<button type="submit" class="btn btn-primary pointer" [disabled]="searchDeviceForm.invalid || disableSearch">{{'g.search' | translate}}</button>
				</div>
			</div>
		</form>
		<div class="col-md-6 my-5" *ngIf="searchResult?.length == 0">
			<b>{{'g.no_results_found' | translate}}</b>
		</div>
		<div class="col-md-12 my-5" *ngIf="searchResult?.length > 0">
			<h3>{{'g.results' | translate}}:</h3>
			<div class="row py-2">
				<div class="col-md-3"><strong>{{'g.mac_address' | translate}}</strong></div>
				<div class="col-md-3"><strong>{{'g.serial_number' | translate}}</strong></div>
				<div class="col-md-2"><strong>{{'shipment.fw_update_status' | translate}}</strong></div>
				<div class="col-md-3"><strong>{{'actions.move_to_bulk' | translate}}</strong></div>
			</div>
			<div *ngFor="let device of searchResult" class="row py-2">
				<div class="col-md-3">{{device.mac_address}}</div>
				<div class="col-md-3">{{device.serial_number}}</div>
				<div class="col-md-2">
					<div *ngIf="fwDevicesStatus[macAddress]?.step">
						{{'shipment.fw_in_progress' | translate}} : {{fwDevicesStatus[macAddress].step}}%
					</div>
					<div *ngIf="fwDevicesStatus[macAddress] && !fwDevicesStatus[macAddress]?.step">
					<ng-container [ngTemplateOutlet]="fwDevicesStatus[macAddress] && fwDevicesStatus[macAddress]?.status == DEVICE_STATUS.fw_update_prevented.status ? fwUpdatePreventedMsg : SuccessFwUpdate">
								</ng-container>
					</div>
				</div>
				<div class="col-md-3">
					<div *ngIf="canMoveToBulk(device)">
						<fa-icon icon="arrow-right" size="lg" class="pointer" (click)="checkVoltageCurrentRanges(device)"></fa-icon>
					</div>
					<div *ngIf="!device.is_device_in_cm_devices">
						<span class="text-danger">{{'common.device_not_cm_devices' | translate}}</span>
					</div>
					<div *ngIf="fwDevicesStatus[device.mac_address]?.step">
						<span class="text-danger">{{'ready_to_ship.push_fw_update' |translate}}</span>
					</div>
					<div *ngIf="device.time_lost">
						<span class="text-danger">{{'common.time_lost' | translate}}</span>
						<span class="link-text"
							(click)="showResetRTCPopup(device.mac_address)"
							*ngIf="userService.hasAccessFunction('reset_rtc_lost_detection') && device.lost_rtc_time && device.last_cm_date_tested < device.lost_rtc_time"
						>
							{{" (" + ('g.reset' | translate) + ")"}}
						</span>
					</div>
					<div *ngIf="device.client_socket_type != commonDataService.CLIENT_SOCKET_TYPE.WIFI">
						<span class="text-danger">{{'shipment.device_wifi_connected_promt' |translate}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="col-lg-6 col-md-6 col-sm-12">
		<div class="d-flex justify-content-between mb-3">
			<h2></h2>
			<div class="ml-auto">
				<div class="row">
					<button class="btn btn-primary pointer" routerLink="/management/shipment-quality-history">{{'shipment.shipment_quality_history_page' | translate}}</button>
				</div>
			</div>
		</div>
		<div *ngIf="shouldShowShipmentDevices()">
			<div class="col-md-12">
				<div class="row pr-2">
					<div class="col-md-1"><strong>{{'g.number' | translate}}</strong></div>
					<div class="col-md-3"><strong>{{'g.mac_address' | translate}}</strong></div>
					<div class="col-md-3"><strong>{{'g.serial_number' | translate}}</strong></div>
					<div class="col-md-2"><strong>{{'g.action' | translate}}</strong></div>
					<div class="col-md-2"><strong>{{'g.status' | translate}}</strong></div>
					<div class="col-md-1"><strong>{{'g.remove' | translate}}</strong></div>
				</div>
				<div *ngFor="let mac_address of devices | keys; let i = index" class="row py-2">
					<div class="col-md-1">
						{{i +1}}
					</div>
					<div class="col-md-3">
						<a [routerLink]="['/', devices[mac_address].customer_id, devices[mac_address].site_id, devices[mac_address].mac_address, 'performance']"
							class="blue-hover pointer ml-1" target="_blank">
							{{devices[mac_address].mac_address}}
						</a>
					</div>
					<div class="col-md-3">{{devices[mac_address].serial_number}}</div>
					<!-- status -->
					<div class="col-md-2">
						<div *ngIf="devices[mac_address].status == DEVICE_STATUS.in_progress.status" class="text-primary">
							{{'shipment.in_progress' | translate}}
						</div>
						<button *ngIf="devices[mac_address].status == DEVICE_STATUS.warnings.status"
							class="btn btn-warning btn-sm" (click)="showWarnings(mac_address)">
							{{'g.warnings' | translate}}
						</button>

						<div *ngIf="fwDevicesStatus[mac_address]?.step; else deviceStatus">
							{{'shipment.fw_in_progress' | translate}} : {{fwDevicesStatus[mac_address].step}}%
						</div>

						<ng-template #deviceStatus>
							<div *ngIf="devices[mac_address].status != DEVICE_STATUS.not_start.status
									&& devices[mac_address].status != DEVICE_STATUS.in_progress.status
									&& devices[mac_address].status != DEVICE_STATUS.warnings.status" [ngClass]="{'text-success': receivingShipmentService.isRecevingProccessSuccess(devices[mac_address].status),
								'text-danger': !receivingShipmentService.isRecevingProccessSuccess(devices[mac_address].status)}">
								{{'shipment.'+devices[mac_address].status | translate}}
							</div>
						</ng-template>
					</div>

					<div class="col-md-1" *ngIf="devices[mac_address].status == DEVICE_STATUS.not_start.status">
						<fa-icon icon="window-close" size="2x" class="pointer text-danger"
							(click)="removeFromShipment(mac_address)"></fa-icon>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center">
				<button *ngIf="toggleButtons()" class="btn btn-primary mr-1" [disabled]="isButtonDisabled()" (click)="createInitialHistory()">{{'shipment.check_all_shipment' | translate}}</button>
				<button *ngIf="!toggleButtons()" class="btn btn-primary mr-1" [disabled]="isButtonDisabled()" (click)="submitShipment(true)">{{'g.finish' | translate}}</button>
				<button *ngIf="hasFailures" class="btn btn-danger"
					(click)="reCheckFailures()">{{'g.recheck_shipment' | translate}}
				</button>
			</div>
		</div>
	</div>
</div>

<app-modal #warningDialog title="{{'nav.warnings' | translate}}" [hasCloseButton]="false" [hasConfirmButton]="false">
	<div class="mb-5">
		<ul *ngIf="selectedDevice?.warnings?.length">
			<li *ngFor="let warning of selectedDevice.warnings">
				{{warning | translate}}
			</li>
		</ul>
	</div>
</app-modal>

<app-modal #indicatorLightModal title="{{'actions.indicator_light' | translate}}" confirmBtnText="g.yes" closeBtnText="g.no">
	<div class="mb-5">
		<p>{{'shipment.indicator_light_blinking' | translate}}</p>
	</div>
</app-modal>

<ng-template #fwUpdatePreventedMsg>
	{{'shipment.fw_update_prevented' | translate}}
</ng-template>

<ng-template #SuccessFwUpdate>
	{{'g.'+fwDevicesStatus[macAddress].status | translate }}
</ng-template>

<app-modal #resetRTCLostDetectionModal title="{{'devices.confirm_reset_rtc_lost_detection' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'settings.reset_rtc_lost_detection_prompt' | translate}}
</app-modal>
