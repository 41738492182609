import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { UsersService } from "src/app/users/users.service";
import { DeviceService } from "src/app/home/site-dashboard/device/device.service";
import { indexBy } from "underscore";

@Component({
	selector: "app-monitoring-devices-page",
	templateUrl: "./monitoring-devices-page.component.html",
	styleUrls: ["./monitoring-devices-page.component.css"],
})
export class MonitoringDevicesPageComponent implements OnInit {
	devices: Array<{mac_address: string, cellular_reconnect_time: number}> = [];
	sites: {
		customer_id?: number
		customer_name?: string
		id?: number
		name?: string
	  } = {};

	constructor(
		private usersService: UsersService,
		private router: Router,
		private devicesService: DeviceService
	) {}

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, "noc"))
			return this.router.navigate(["/unauthorized"]);

		this.getNocMonitoringDevices();
	}

	getNocMonitoringDevices() {
		this.devicesService.getNocMonitoringDevices().subscribe((res: any) => {
			this.devices = res.devices;
			this.sites = indexBy(res.sites, 'id');
		});
	}
}
