import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { UsersService } from '../../users/users.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { AdminService } from '../admin.service';
import { ActivatedRoute, Router } from "@angular/router";

@Component({
	selector: 'app-contact-us-admin',
	templateUrl: './contact-us-admin.component.html',
	styleUrls: ['./contact-us-admin.component.css']
})
export class ContactUsAdminComponent implements OnInit, AfterViewInit {

	data: any = [];
	columnNames: any[] = [];
	
	selectedIds: any;

	@ViewChild('dataTable') dataTable;
	@ViewChild("confirmModal") confirmModal;

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		public usersService: UsersService,
		private adminService: AdminService,
		private translateService: TranslateService,
		private notificationMessage: NotificationMessageService
	) { }

	ngOnInit() {
		this.columnNames = [
			{name: 'g.id', key: 'id', isId: true},
			{name: 'g.time', key: 'insertion_time', type: 'dateTime'},
			{name: 'contact_us.subject', key: 'subject'},
			{name: 'contact_us.body', key: 'body', type: 'truncate', maxLength: 60},
			{name: 'users.user_id', key: 'user_id'},
			{name: 'login_register.user_name', key: 'username'},
			{name: 'error_log.user_ip', key: 'ip_address'},
			{name: 'error_log.user_agent', key: 'browser'},
		];

		let optionsGear = {name: 'g.options', key: 'options', type: 'options', optionsList: [{'action': 'view', 'text': this.translateService.instant('g.view')}]};
		if(this.usersService.hasAccessFunction('admin_contact_us', 'write'))
			optionsGear.optionsList.push({'action': 'delete', 'text': this.translateService.instant('g.delete')});
		this.columnNames.push(optionsGear);
		this.getContactUs();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			if(result[1][0] == 'deleteLogs') {
				return this.deleteLogs();
			}
			if(result[1][0] == 'delete') {
				return this.delete(result[1][1]);
			}
		});
	}

	confirmDelete(action, data?) {
		this.confirmModal.passData = [action, data];
		this.confirmModal.message = 'g.confirm_delete_message';
		this.confirmModal.show();
	}

	getContactUs() {
		this.adminService.getContactUs().subscribe(
			(data: any) => {
				this.data = data.slice();
			}
		);
	}

	delete(id) {
		this.adminService.deleteContactUs(id).subscribe(
			(isDeleted: any) => {
				if(isDeleted.result) {
					let data = this.data.slice();
					for(let i in data) {
						if (data[i].id == id) {
							data.splice(+i, 1);
							this.data = data.slice();
							break;
						}
					}
					this.notificationMessage.setMessage('translate|g.deleted_successfully',{clearOnXTimeNavigate: 1, type: 'success'});
				}
			}
		);
	}

	deleteLogs() {
		this.adminService.deleteContactUs(this.selectedIds).subscribe(
			(isDeleted: any) => {
				if(isDeleted.result) {
					this.notificationMessage.setMessage('translate|g.deleted_successfully',{clearOnXTimeNavigate: 1, type: 'success'});
					this.dataTable.clearSelection();
					this.getContactUs();
				}
			}
		);
	}

	doAction(event) {
		if(event.action == 'view') {
			let url = this.router.createUrlTree([event.element.id], {relativeTo: this.route});
			return window.open('#'+url.toString(), "_blank");
		}
		if(event.action == 'delete')
			return this.confirmDelete('delete', event.element.id);
	}
}