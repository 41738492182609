import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { CommonDataService } from '../../../shared/services/common-data.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';


@Component({
  selector: 'app-sched-run',
  templateUrl: './sched-run.component.html',
})
export class SchedRunComponent implements OnInit {

  schedFunctions = [];
  selectedSched = '';

  schedSkip: string[] = ['ALL', 'MonitVar', 'runSchedOnDemand',];

  constructor(
    private router: Router,
    private usersService: UsersService,
    private adminService: AdminService,
    private commonData: CommonDataService,
		private notificationMessageService: NotificationMessageService,
  ) { }

  ngOnInit() {
		const isProduction = this.commonData.productionDomain.includes(window.location.hostname);

    if (!this.usersService.hasAccessFunction('sched_functions_control') || isProduction)
      return this.router.navigate(['/unauthorized']);

    return this.getAllSchedFunctions();
  }

  getAllSchedFunctions() {
    this.adminService.getCronJobsStatus().subscribe((data: any) => {
      data = data.filter((sched: any) => !this.schedSkip.includes(sched.name) && !sched.name.includes(this.schedSkip[1]));
      this.schedFunctions = data || [];
    });
  }

  runSched() {
    let schedFunction = this.schedFunctions.find((schedFunction: any) => schedFunction.name == this.selectedSched);

    if (schedFunction) {
      this.adminService.addSchedToRun(schedFunction).subscribe((data: any) => {
        if (data) {
          this.selectedSched = '';
          return this.notificationMessageService.setMessage('globalSuccessMsg');
        }
        return this.notificationMessageService.setMessage('globalErrMsg');
      });
    }
  }
}
