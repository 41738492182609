import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from '../device-management.service';
import * as moment from 'moment';

@Component({
	selector: 'app-force-read-debug',
	templateUrl: './force-read-debug.component.html',
	styleUrls: ['./force-read-debug.component.css']
})
export class ForceReadDebugComponent implements OnInit {

	@Input() device: any = {};

	force_read_debug_time: number = 0;

	currentUser: any = {};

	maxAllowedDebugHours: number = 48;

	forceReadDebugUntil: string = '';

	constructor(
		private deviceManagementService: DeviceManagementService,
		private notificationMessage: NotificationMessageService,
		private usersService: UsersService,
		private router: Router,
	){}

	ngOnInit() {
		if(!this.usersService.hasAccessPermission(null, 'read_debug_records'))
			return this.router.navigate(['/unauthorized']);
			
		if(this.device.config_info && this.device.config_info.force_read_debug_time)
			this.getDebugDate();
	}

	getDebugDate(force_read_debug_time?: number) {
		force_read_debug_time = force_read_debug_time || this.device.config_info.force_read_debug_time;
		if(force_read_debug_time && typeof(force_read_debug_time) == 'number')
			this.forceReadDebugUntil = moment.unix(force_read_debug_time || 0).utc().format('MM/DD/YYYY H:mm');
	}

	saveDebugDate() {
		if (this.force_read_debug_time > this.maxAllowedDebugHours || this.force_read_debug_time < 0)
			return this.notificationMessage.setMessage('translate|force_read_debug.select_time_more_max_time');

		this.deviceManagementService.setForceReadDebugDate(this.device.mac_address, this.force_read_debug_time).subscribe((res: any) => {
			this.getDebugDate(res.force_read_debug_time);
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}
}