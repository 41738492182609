<div>
	<h3 class="mb-4 font-weight-bold">
		<span class="light-grey"> {{'nav.admin.sched_run' | translate}}</span>
	</h3>
    <div>
        <div class="col-md-4">
            <ng-select
                [items]="schedFunctions"
                bindLabel="name"
                bindValue="name"
                name="sched_run"
                [(ngModel)]="selectedSched"
                [clearable]="false"
            >
            </ng-select>
        </div>
        <div class="col-md-4 mt-3">
            <button [disabled]="!selectedSched" class="btn btn-success" type="button" (click)="runSched()">{{'g.run' | translate}}</button>
        </div>
    </div>
</div>
