import { Component, OnInit } from '@angular/core';
import { StudiesService } from './studies.service';
import { Router } from '@angular/router';
import { UsersService } from '../users/users.service';
import { SideMenuService } from '../shared/side-menu/side-menu.service';

@Component({
	selector: 'app-study',
	templateUrl: './study.component.html',
	styleUrls: ['./study.component.css']
})
export class StudyComponent implements OnInit {

	currentUser: any = {};

	constructor(
		private router: Router,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
	) {}

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();
		if (!this.currentUser.has_access_to_any_study)
			return this.router.navigate(['/unauthorized']);

		this.sideMenuService.hide();
	}
}
