import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[calender]'
})
export class CalenderDirective implements OnInit {

	calenderIcon;

	constructor(
		private el: ElementRef,
		private renderer2: Renderer2,
	) {}

	ngOnInit() {
		this.calenderIcon = this.renderer2.createElement('span');

		this.renderer2.setAttribute(this.calenderIcon, 'class', 'fa fa-calendar input-icon');
		this.renderer2.insertBefore(this.el.nativeElement.parentNode, this.calenderIcon, this.renderer2.nextSibling(this.el.nativeElement));
		
		this.calenderIcon.addEventListener('click', () => {
			this.el.nativeElement.click();
		});
	}
}