import { Component, OnInit, ViewChild } from '@angular/core';
import { UsersService } from '../users.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { CaptchaComponent } from '../captcha/captcha.component';
import { LoaderInterceptorService } from '../../shared/loader/loader-interceptor.service';
import { Router } from '@angular/router';

@Component({
	selector: 'app-forgot-password',
	templateUrl: './forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {

	@ViewChild('captchaComponent') captchaComponent: CaptchaComponent;
	
	forgot = {
		email: '',
		captcha_key: '',
		captcha_text: ''
	};

	constructor(
		private router: Router,
		private usersService: UsersService,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService,
		private loader: LoaderInterceptorService
	) {}

	ngOnInit() {}

	forgotPassword() {
		this.notificationMessage.closeNotification();
		let forgot = {...this.forgot};
		forgot.captcha_key = this.captchaComponent.captchaKey;
		forgot.captcha_text = this.captchaComponent.captchaText;

		this.usersService.forgotPassword(forgot).subscribe(
			(data: any) => {
				
				this.captchaComponent.getCaptcha();
				
				if(data.response_status == this.loader.resStatus.invalid_captcha)
					return;
				
				switch (data.status) {
					case 1:
						this.notificationMessage.setMessage(this.translateService.instant('g.reset_password_email_sent'), {type: 'success'});
						return this.router.navigate(['/login']);
					break;
					case 2:
						this.notificationMessage.setMessage(this.translateService.instant('login_register.invalid_email_address'), {clearOnXTimeNavigate: 1});
					break;
					case 3:
						this.notificationMessage.setMessage(this.translateService.instant('g.email_not_confirmed'), {clearOnXTimeNavigate: 1});
					break;
					default:
						this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
					break;
				}
			}
		);
	}

	goBack() {
		this.router.navigate(['/']);
	}
}