<div class="card mt-3">
	<div class="card-header"><strong>{{'working_days.alert_settings' | translate}}</strong></div>

	<div class="col-sm-10 col-md-10 ml-3">
		<div class="col-sm-6 col-md-6 p-3 mb-3">
			<select (change)="selectOption($event.target.value)" class="form-control">
				<option value="" disabled selected>{{'g.select_user' | translate}}</option>
				<option *ngFor="let user of usersList" [value]="user.id">{{user.name}}</option>
			</select>
		</div>
		
		<div class="ml-3" *ngIf="selectedUser">
			<h4 style="font-weight: bold;">{{'working_days.maintenance_reports' | translate}}</h4>

			<div class="ml-2 d-flex justify-content-between">
				<h5>{{'note.reminder_freq_none' | translate}}</h5>
				<h5>{{'note.reminder_freq_daily' | translate}}</h5>
				<h5>{{'note.reminder_freq_weekly' | translate}}</h5>
			</div>
			<div class="row ml-2">
				<input type="range" min="0" max="2" [value]="rangeValue" class="slider" (change)="onChange($event)" id="myRange">
			</div>
		</div>
	</div>
	<div class="col-md-12">
		<button [disabled]="!type" (click)="saveUserAlertSettings(selectedUser,type)" class="btn btn-primary mb-3" style="float: right;">{{'g.save' | translate}}</button>
	</div>
</div>