<div class="search-heading">
	<h3>{{'devices.search_for_devices' | translate}}:</h3>
</div>
<div class="card row m-1 mb-3 search-bar">
	<div class="p-2">
		<form #searchDeviceForm="ngForm" (ngSubmit)="getDevices(true)">
			<div class="form-row align-items-center">
				<div class="col-2 search-box">
					<div class="search-input">
						<input type="text" class="form-control" name="searchString" [(ngModel)]="searchString" placeholder="{{'g.serial_number' | translate}}/{{'g.mac_address' | translate}}" required>
					</div>
				</div>
				<span class="search-separator"></span>
				<div class="col-auto">
					<div class="input-group">
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio1" value="sn">
							<label class="form-check-label" for="inlineRadio1">{{'g.serial_number' | translate}}</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio2" value="mac">
							<label class="form-check-label" for="inlineRadio2">{{'g.mac_address' | translate}}</label>
						</div>
						<div class="form-check form-check-inline" *ngIf="currentUser.is_admin">
							<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio3" value="ip">
							<label class="form-check-label" for="inlineRadio3">{{'g.ip_address' | translate}}</label>
						</div>
						<div class="form-check form-check-inline" *ngIf="usersService.hasAccessPermission(null, 'basic_setup')">
							<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio4" value="truckSn">
							<label class="form-check-label" for="inlineRadio4">{{'devices.truck_sn_short' | translate}}</label>
						</div>
						<div class="form-check form-check-inline" *ngIf="hasNOC">
							<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio5" value="iccid">
							<label class="form-check-label" for="inlineRadio5">{{'g.iccid' | translate}}</label>
						</div>
						<div class="form-check form-check-inline" *ngIf="hasNOC">
							<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio6" value="imei">
							<label class="form-check-label" for="inlineRadio6">{{'g.imei' | translate}}</label>
						</div>
						<div class="form-check form-check-inline" *ngIf="usersService.hasAccessFunction('shipment_management')">
							<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio7" value="po_number">
							<label class="form-check-label" for="inlineRadio7">{{'ready_to_ship.po_number' | translate}}</label>
						</div>
					</div>
				</div>
				<span class="search-separator"></span>
				<div class="col-auto" *ngIf="usersService.hasAccessFunction('search_deleted_devices')">
					<div class="input-group">
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="searchDeleted" [(ngModel)]="searchDeleted" id="radio1" value="yes">
							<label class="form-check-label" for="radio1">{{'g.deleted' | translate}}</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="searchDeleted" [(ngModel)]="searchDeleted" id="radio2" value="no">
							<label class="form-check-label" for="radio2">{{'g.not_deleted' | translate}}</label>
						</div>
						<div class="form-check form-check-inline">
							<input class="form-check-input" type="radio" name="searchDeleted" [(ngModel)]="searchDeleted" id="radio3" value="">
							<label class="form-check-label" for="radio3">{{'g.not_selected' | translate}}</label>
						</div>
					</div>
				</div>
				<span class="search-separator" *ngIf="usersService.hasAccessFunction('search_deleted_devices')"></span>
				<div class="col-lg-1 col-md-auto">
					<div class="form-check">
						<input class="form-check-input" type="checkbox" name="isPartial" [(ngModel)]="isPartial" id="isPartialCheck">
						<label class="form-check-label" for="isPartialCheck">
							{{'g.partial_search' | translate}}
						</label>
					</div>
				</div>
				<span class="search-separator"></span>
				<div class="col-auto">
					<button type="submit" class="btn btn-primary btn-rounded px-4" [disabled]="searchDeviceForm.invalid">{{'g.search' | translate}}</button>
				</div>
			</div>
		</form>
	</div>
</div>
<div class="card mx-1 result-list" *ngIf="searchResult">
	<div class="card-body" *ngIf="searchResult.length > 0">
		<div class="d-flex align-items-center mb-3">
			<h6>{{searchResult.length}} {{'g.results' | translate}}</h6>
			<button class="btn btn-primary ml-3"  type="button" (click)="clearSearch()" *ngIf="activeConnectivityFilter || activeDeviceFilter || filter.site_name != '' || filter.customer_name != ''">clear filter</button>
		</div>
		<table class="table mb-0" matSort (matSortChange)="filterAndSortData($event)" matSortActive="serial_number" [matSortDirection]="sort.direction" matSortDisableClear>
			<thead>
				<tr>
					<th>
						<div class="connectivity-column d-flex align-items-center">
							{{'g.connectivity' | translate}}
							<span class="ml-1 filter-icon" [ngClass]="{'active': activeConnectivityFilter}" (click)="toggleConnectivityStatusList()"><fa-icon icon="filter" size="sm"></fa-icon></span>
							<div class="connectivity-options" [ngClass]="{'shown': showConnectivityOptions}" #connectivityOptions>
								<div class="option" (click)="clearConnectivityStatusFilter()">{{'g.select_all' | translate}}</div>
								<div class="option" *ngFor="let status of connectivityStatus" (click)="toggleConnectivityStatus(status)">
									<fa-icon class="mr-2"  [icon]="['far', status.selected ? 'check-square': 'square']" size="sm"></fa-icon>
									<img [src]="status.wifi ? '/sctportal' + status.link : status.link" class="size-35x35">
								</div>
							</div>
							<div class="overlay" *ngIf="showConnectivityOptions" (click)="toggleConnectivityStatusList()"></div>
						</div>
					</th>
					<th>{{'g.mac_address' | translate}}</th>
					<th mat-sort-header="serial_number">{{'g.serial_number' | translate}}</th>
					<th>
						<div class="connectivity-column d-flex align-items-center">
							{{'devices.device_type' | translate}}
								<span class="ml-1 filter-icon" [ngClass]="{'active': activeDeviceFilter}" (click)="toggleDeviceType()"><fa-icon icon="filter" size="sm"></fa-icon></span>
								<div class="device-types-options" [ngClass]="{'shown': showDeviceTypeOptions}" #connectivityOptions>
									<div class="option" (click)="clearDeviceTypeFiler()">{{'g.select_all' | translate}}</div>
									<div class="option" *ngFor="let type of devicesTypes" (click)="toggleDeviceTypeOptions(type)">
										<fa-icon class="mr-2"  [icon]="['far', type.selected ? 'check-square': 'square']" size="sm"></fa-icon>
										<p>{{type.value | translate}}</p>
									</div>
								</div>
								<div class="overlay" *ngIf="showDeviceTypeOptions" (click)="toggleDeviceType()"></div>
						</div>
					</th>
					<th>
						{{'g.customer_name' | translate}}
						<span class="ml-1 filter-icon" (click)="toggleShowFilters()" [ngClass]="{'active': filter.customer_name}"><fa-icon icon="filter" size="sm"></fa-icon></span>
						<input class="form-control ng-tns-c18-2 ng-pristine ng-valid ng-touched"
							type="search"
							name="customer_name_filter"
							id="customer_name_filter"
							[(ngModel)]="filter.customer_name"
							[ngClass]="{'active': showFilters}"
							(input)="filterAndSortData()"
						>
					</th>
					<th>
						{{'g.site_name' | translate}}
						<span class="ml-1 filter-icon" (click)="toggleShowFilters()" [ngClass]="{'active': filter.site_name}"><fa-icon icon="filter" size="sm"></fa-icon></span>
						<input class="form-control ng-tns-c18-2 ng-pristine ng-valid ng-touched"
							type="search"
							name="site_name_filter"
							id="site_name_filter"
							[(ngModel)]="filter.site_name"
							[ngClass]="{'active': showFilters}"
							(input)="filterAndSortData()"
						>
					</th>
					<th *ngIf="usersService.hasAccessFunction('shipment_management')">{{'ready_to_ship.po_number' | translate}}</th>
					<th>{{'g.actions' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let device of filteredResult; let idx = index">
					<td>
						<div class="btn-group" *ngIf="device.connectivityStatus" tooltip="{{device.connectivityTooltipMessages}}" [ngClass]="{ pointer: device.connectivityTooltipMessages != ''}">
							<a [routerLink]="deviceService.getImageRouterLink('cellular', sites[device.site_id]?.customer_id, device)">
								<img src="/sctportal{{device.connectivityStatus}}" class="size-35x35">
							</a>
						</div>
			
						<div class="btn-group m-2" *ngIf="device.wifiConnectivityStatus" tooltip="{{device.connectivityTooltipMessages}}" [ngClass]="{ pointer: device.connectivityTooltipMessages != ''}">
							<a [routerLink]="deviceService.getImageRouterLink('wifi', sites[device.site_id]?.customer_id, device)" [queryParams]="{tab: 'networking'}">
								<img src="{{device.wifiConnectivityStatus}}" class="size-35x35">
							</a>
						</div>
					</td>
					<td>
						<a
							*ngIf="!device.is_deleted && sites[device.site_id];else deviceText"
							[routerLink]="['/', sites[device.site_id]?.customer_id, device.site_id, device.mac_address, 'performance']" class="blue-hover pointer ml-1">
							{{device.mac_address}}
						</a>
						<ng-template #deviceText>{{(device.is_deleted ? deviceService.formatDeletedMACandSN(device.mac_address) : device.mac_address)}}</ng-template>
						<div class="row">
							<div class="col-auto split-right" [ngClass]="{'split-right': device.ip_address}">
								<small class="text-secondary">{{device.truck_sn}}</small>
							</div>
							<div class="col-auto">
								<small class="text-secondary">{{device.ip_address}}</small>
							</div>
						</div>
					</td>
					<td>{{(device.is_deleted ? deviceService.formatDeletedMACandSN(device.serial_number) : device.serial_number)}}</td>
					<td>{{(device.is_charglink ? "g.charglink"  : "g.iotah") | translate}}</td>
					<td><a
						*ngIf="!device.is_deleted && sites[device.site_id];else customerText"
						[routerLink]="['/', sites[device.site_id]?.customer_id]">{{sites[device.site_id]?.customer_name}}</a>
						<ng-template #customerText>{{sites[device.site_id]?.customer_name}}</ng-template>
					</td>
					<td><a
						*ngIf="!device.is_deleted && sites[device.site_id];else siteText"
						[routerLink]="['/', sites[device.site_id]?.customer_id, device.site_id]">{{sites[device.site_id]?.name}}</a>
						<ng-template #siteText>{{sites[device.site_id]?.name}}</ng-template>
						<span *ngIf="sites[device.site_id]?.is_special && usersService.hasAccessFunction('site_management')"> ({{'g.special' | translate}})</span>
					</td>
					<td *ngIf="usersService.hasAccessFunction('shipment_management')">{{device.po_number}}</td>
					<ng-container *ngIf="!device.is_deleted; else emptyCell">
						<ng-container *ngTemplateOutlet="singleOption; context:{device: device}"></ng-container>
						<ng-container *ngTemplateOutlet="multiOptions; context:{device: device}"></ng-container>
					</ng-container>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="card-body" *ngIf="searchResult.length == 0">
		<h3>{{'g.no_results_found' | translate}}</h3>
	</div>
</div>

<ng-template #singleOption let-device="device">
	<td *ngIf="!usersService.hasAccessFunction('reset_device_data')">
		<span *ngIf="!device.is_deleted">
			<a
				*ngIf="deviceService.showManageDeviceOption(device, {is_special: sites[device.site_id]?.is_special}); else requestManage"
				class="pointer"
				[routerLink]="['/device/management', device.mac_address]"
			>
				{{'devices.manage_device' | translate}}
			</a>
			<ng-template #requestManage>
				<a class="pointer" *ngIf="!sites[device.site_id]?.is_special" [routerLink]="['/device', 'management-request', device.mac_address]">
					{{'devices.request_manage_device' | translate}}
				</a>
			</ng-template>
		</span>
	</td>
</ng-template>
<ng-template #multiOptions let-device="device">
	<td *ngIf="usersService.hasAccessFunction('reset_device_data')">
		<mat-icon class="pointer blue-hover" [matMenuTriggerFor]="deviceMenu" [matMenuTriggerData]="{device: device}">
			settings
		</mat-icon>
	</td>
</ng-template>

<!-- Multiple options (Gear) -->
<mat-menu #deviceMenu="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent let-device="device">
		<span *ngIf="!device.is_deleted">
			<span
				mat-menu-item
				[routerLink]="['/device/management', device.mac_address]"
				*ngIf="deviceService.showManageDeviceOption(device, {is_special: sites[device.site_id]?.is_special}); else requestManageOpt"
				>
				{{'devices.manage_device' | translate}}
			</span>
			<ng-template #requestManageOpt>
				<span mat-menu-item [routerLink]="['/device', 'management-request', device.mac_address]" *ngIf="!sites[device.site_id]?.is_special">
					{{'devices.request_manage_device' | translate}}
				</span>
			</ng-template>
		</span>
		<span mat-menu-item class="text-danger" (click)="confirmAction('deleteDailyDetails', device.mac_address, device.site_id)" >{{'devices.delete_daily_details' | translate}}</span>
		<span mat-menu-item (click)="confirmAction('regenerateDailyDetails', device.mac_address, device.site_id)">{{'devices.regenerate_daily_details' | translate}}</span>
		<span mat-menu-item (click)="confirmAction('regenerateDevicePerformance', device.site_id)">{{'devices.regenerate_device_performance' | translate}}</span>
		<span mat-menu-item (click)="confirmAction('regenerateDeviceAnalyticsReport', device.mac_address, device.site_id)">{{'devices.regenerate_device_analytics_report' |translate}}</span>
		<span mat-menu-item class="text-danger" *ngIf="usersService.hasAccessFunction('super_admin', 'write')" (click)="confirmAction('resetDevice', device.mac_address)">{{'g.reset_device_after_hard_delete' | translate}}</span>
	</ng-template>
</mat-menu>

<ng-template #emptyCell><td>N/A</td></ng-template>
<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>
