<div class="white-box mt-4 p-4 border-radius">
	<app-grid
		[data]="events"
		[columns]="eventTables.events.columnDefs"
		[autoSizeColumns]="true"
		#agGrid
		[id]="exportFileName"
		*ngIf="eventTables.events.hasGrid && events.length > 0"
	></app-grid>
	<div class="text-danger" *ngIf="invalidDateRange; else noData"><b>{{'g.start_lg_end' | translate}}</b></div>
	<ng-template #noData>
		<div *ngIf="events.length == 0">{{'g.no_data_available_for_the_selected_period' | translate}}</div>
	</ng-template>
</div>

<div *ngIf="eventTables.offEvent.hasGrid && showCorruptedEvents" class="white-box mt-4 p-4 border-radius">
	<div class="mb-3">
		<h4>{{'g.offline_records' | translate}}</h4>
	</div>
	<app-grid
		[data]="corruptedEvents"
		[columns]="eventTables.offEvent.columnDefs"
		[autoSizeColumns]="true"
		[colId]="true"
		#agGrid
		id="{{exportFileName + '-corrupted-events'}}"
		*ngIf="corruptedEvents.length"
	></app-grid>
	<div class="text-danger" *ngIf="invalidDateRange; else noDataCurr"><b>{{'g.start_lg_end' | translate}}</b></div>
	<ng-template #noDataCurr>
		<div *ngIf="!corruptedEvents.length">{{'g.no_data_available_for_the_selected_period' | translate}}</div>
	</ng-template>
</div>

<div *ngIf="eventTables.defectiveEvents.hasGrid && showDefectiveEvents" class="white-box mt-4 p-4 border-radius">
	<div class="mb-3">
		<h4>{{'g.defective_records' | translate}}</h4>
	</div>
	<app-grid
		[data]="defectiveEvents"
		[columns]="eventTables.defectiveEvents.columnDefs"
		[autoSizeColumns]="true"
		[colId]="true"
		#agGrid
		[id]="exportDefectiveFileName"
		*ngIf="defectiveEvents.length"
	></app-grid>
	<div class="text-danger" *ngIf="invalidDateRange; else noDataCurr"><b>{{'g.start_lg_end' | translate}}</b></div>
	<ng-template #noDataCurr>
		<div *ngIf="!defectiveEvents.length">{{'g.no_data_available_for_the_selected_period' | translate}}</div>
	</ng-template>
</div>
