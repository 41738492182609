<div class="row justify-content-md-center">
	<div class="col-md-12">
		<a class="btn btn-primary btn-lg pointer pull-right text-white" [routerLink]="['/user', 'edit', 0]" *ngIf="usersService.hasAccessFunction('add_user_admin')">{{'g.add' | translate}} {{'g.user' | translate}}</a>
		<form #searchUserForm="ngForm" (ngSubmit)="getUsersList()">
			<legend>
				{{'g.search' | translate}} {{'g.user' | translate}}
			</legend>
			<div class="mt-3">
				<div>
					<input placeholder="{{'users.user_id' | translate}}" type="number" class="form-control" name="id" [(ngModel)]="criteria.id"/>
				</div>
				<div class="mt-2">
					<input placeholder="{{'login_register.first_name' | translate}}" type="text" class="form-control" name="first_name" [(ngModel)]="criteria.first_name"/>
				</div>
				<div class="mt-2">
					<input placeholder="{{'login_register.last_name' | translate}}" type="text" class="form-control" name="last_name" [(ngModel)]="criteria.last_name"/>
				</div>
				<div class="mt-2">
					<input placeholder="{{'login_register.email' | translate}}" type='email' class="form-control" name="email" [(ngModel)]="criteria.email"/>
				</div>
				<div class="mt-2" *ngIf="currentUser.is_admin">
					<input placeholder="{{'login_register.domain' |translate}}" type="text" class="form-control" name="domain" [(ngModel)]="criteria.domain">
				</div>
				<div class="mt-2">
					<ng-select [items]="enterpriseList" bindLabel="name" bindValue="id" placeholder="{{'ent.select_enterprise' | translate}}" name="enterprise_id" [(ngModel)]="criteria.enterprise_id"></ng-select>
				</div>
				<div class="mt-2">
					<ng-select [multiple]="true" [items]="email_subscription_list" bindLabel="name" bindValue="id" placeholder="{{'users.email_subscription_list' | translate}}" name="email_subscription_list" [(ngModel)]="criteria.selected_subscriptions"></ng-select>
				</div>
				<div class="mt-2">
					<button type="submit" class="btn btn-primary pointer" [disabled]="isEmptyCriteria()">{{'g.search' | translate}}</button>
				</div>
			</div>
		</form>
	</div>
</div>

<div class="mt-5">
	<table *ngIf="!noResult && users.length" class="table table-stripped mt-3">
		<thead>
			<tr>
				<th>{{'login_register.first_name' | translate}}</th>
				<th>{{'login_register.last_name' | translate}}</th>
				<th>{{'users.email_address' | translate}}</th>
				<th>{{'g.enterprise_id' | translate}} / {{'g.enterprise_name' | translate}}</th>
				<th>{{'users.create_date' | translate}}</th>
				<th>{{'users.last_access_time' | translate}} ({{commonService.showUserTimeZoneReference()}})</th>
				<th>{{'g.status' | translate}}</th>
				<th>{{'g.actions' | translate}}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let user of usersToShow">
				<td class="text-truncate" [ngStyle]="{'max-width': '200px'}">{{user.first_name}}</td>
				<td class="text-truncate" [ngStyle]="{'max-width': '200px'}">{{user.last_name}}</td>
				<td>{{user.email}}</td>
				<td>{{user.enterprise_id}} / {{enterprises[user.enterprise_id]?.name}}</td>
				<td>{{user.create_date | date:'MM/dd/yyyy'}}</td>
				<td>{{(user.last_access_time? (user.last_access_time * 1000 | date:'MM/dd/yyyy hh:mm:ss a') : 'N/A')}}</td>
				<td>{{(user.account_deactivate? ('g.deactivated' | translate) : ('g.activated' | translate))}}</td>
				<td>
					<mat-icon class="pointer blue-hover" [matMenuTriggerFor]="userMenu"
						[matMenuTriggerData]="{user: user}">
						settings
					</mat-icon>
				</td>
			</tr>
		</tbody>
	</table>

	<mat-paginator
		*ngIf="!noResult && users.length"
		[length]="users.length"
		[pageSize]="pageSize"
		[pageSizeOptions]="pageSizeOptions"
		[showFirstLastButtons]="true"
		(page)="onPageChange($event)">
	</mat-paginator>

	<div *ngIf="noResult && searchUserForm.submitted">{{'g.no_results_found' | translate}}</div>
</div>

<mat-menu #userMenu="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent let-user="user">
		<span mat-menu-item [routerLink]="['/user', 'edit', user.id]" *ngIf="usersService.hasAccessFunction('edit_user_admin')">{{'users.edit_user' | translate}}</span>
		<span mat-menu-item [routerLink]="['/admin-panel/users', 'edit-access-functions', user.id]" *ngIf="currentUser.can_grant_access_functions">{{'users.edit_access_functions' | translate}}</span>
		<span mat-menu-item [routerLink]="['/admin-panel', 'permission-edit', user.permission_id]" *ngIf="user.permission_id > 0 && usersService.hasAccessFunction('edit_user_admin')">{{'g.edit_permissions' | translate}}</span>
		<span mat-menu-item *ngIf="!user.is_admin" (click)="getBecomeUserUrl(user.id)">{{'action_log.become_user' | translate}}</span>
		<span mat-menu-item class="text-danger" (click)="deleteUserModal.passData = user.id; deleteUserModal.show()" *ngIf="usersService.hasAccessFunction('delete_user') && !user.special_user">{{'g.delete' | translate}}</span>
	</ng-template>
</mat-menu>

<app-modal #deleteUserModal title="{{'users.delete_user' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'g.del_user_prompt' | translate}}
</app-modal>
