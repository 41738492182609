import { Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';


@Component({
	selector: 'app-update-device-fw',
	templateUrl: './update-device-fw.component.html',
	styleUrls: ['./update-device-fw.component.css']
})
export class UpdateDeviceFwComponent implements OnInit {
	macAddress!: string;
	fileName: string = ''
	deviceFWtoPush: number;
	fwToPush: number;
	siteSubscription:any;
	currentSite:any;
	fwList: Array<Object> = [];
	
	@Input() device: any = {};

	constructor(
		private deviceManagementService: DeviceManagementService,
		private usersService: UsersService,
		private router: Router,
		private sctToastService: NotificationMessageService,
		private sideMenuService: SideMenuService,
	) { }

	ngOnInit(): void {
		if (!this.usersService.hasAccessFunction('test_fw')) {
			this.router.navigate(['/unauthorized']);
			return;
		}
		this.macAddress = this.device.mac_address;
		this.deviceFWtoPush = this.device.config_info.testing_fw ? this.device.config_info.firmware_version_to_push || 0 : 0;
		this.siteSubscription = this.sideMenuService.currentSite.subscribe(siteInfo => {
			if(!siteInfo['id'])
				return;
			
			this.currentSite = siteInfo;
		})
		
		this.getTestingFWFilesList();
	}
	
	getTestingFWFilesList() {
		this.deviceManagementService.getTestingFWFilesList().subscribe((res: []) => {
			const fwList = [];
			res.map(fwVersion => fwList.push({label: fwVersion, value: fwVersion}));
			this.fwList = fwList;
		})
	}
	
	pushFW(){
		this.deviceManagementService.pushDeviceFW(this.macAddress, this.fwToPush).subscribe((res) => {
			if (res === 2)
				return this.sctToastService.setMessage('translate|site.fw_already_pushed_device');

			if (res === 3)
				return this.sctToastService.setMessage('translate|site.fw_already_pushed');

			if (!res)
				return this.sctToastService.setMessage('globalErrMsg');

			this.sctToastService.setMessage('globalSuccessMsg');
		});
	}
	
	ngOnDestroy() {
		if(this.siteSubscription)
			this.siteSubscription.unsubscribe();
	}
}
