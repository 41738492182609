import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as _ from 'underscore';
import { UsersService } from 'src/app/users/users.service';
import { Subscription } from 'rxjs';
import { SiteDashboardService } from '../site-dashboard/site-dashboard.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { cloneDeep } from 'lodash-es';

@Component({
	selector: 'app-quarterly-usage-data',
	templateUrl: './quarterly-usage-data.component.html'
})
export class QuarterlyUsageDataComponent implements OnInit, OnChanges, OnDestroy {
	chargLinkTranslationPath: string = "";

	@Input() isChargeLinkDevices?: boolean = false;
	@Input() data: any = {};
	@Input() devices: any[];
	@Input() parentPage: string = 'device'; // options are: customer, site, device
	@Input() options: {hasTruckTypeFilter, hasYearFilter, hasStackedColumn, hasSiteFilter} = {hasTruckTypeFilter: false, hasYearFilter: false, hasStackedColumn: false, hasSiteFilter: false};
	@Input() tags: any[] = [];
	@Input() sites: any[] = [];
	@Input() deviceTags: any[] = [];
	@Input() siteInfo: any = {};
	@Input() truckTypes: any = {};

	selectedYear = moment().utc().get('year').toString();
	selectedTruckType = '';
	selectedTruckYear = '';
	selectedSites = [];
	selectedTags = [];

	trucksColors = [];

	selectedTagNames = [];

	ddList = {
		quarter: [],
		truckTypes: [],
		truckYearsList: [],
	};

	chartConfig = {};
	timeChartConfig = {};

	quarterChartObject = {
		data_total_ahr: [],
		data_avg_ahr: [],
		data_total_kwhr: [],
		data_avg_kwhr: [],
		data_total_lift_time: [],
		data_avg_lift_time: [],
		data_total_travel_time: [],
		data_avg_travel_time: [],
		allColumnNames: [],
		columnNames: [],
	};

	quarterChartObjectDefault = {
		data_total_ahr: [],
		data_avg_ahr: [],
		data_total_kwhr: [],
		data_avg_kwhr: [],
		data_total_lift_time: [],
		data_avg_lift_time: [],
		data_total_travel_time: [],
		data_avg_travel_time: [],
		allColumnNames: [],
		columnNames: [],
	};

	timeCharts = [
		'data_total_lift_time',
		'data_avg_lift_time',
		'data_total_travel_time',
		'data_avg_travel_time'
	]

	showChart = null;

	hasData = null;

	types = {
		quarter: 'quarter',
	};

	permissionsSub: Subscription = new Subscription();

	tooltips = '';

	colorsArr = [
		'#3366cc', '#dc3912', '#ff9900', '#109618', '#990099', '#0099c6', '#dd4477', '#66aa00', '#b82e2e', '#316395'
	];

	truckTypesMapper = {};

	constructor(
		private translate: TranslateService,
		public common: CommonService,
		public commonData: CommonDataService,
		private userService: UsersService,
		private siteDashboardService: SiteDashboardService,
	){}

	ngOnInit() {
		this.initData();

		this.ddList.truckYearsList = [];
		for(let item of ['all', 'new', 'old']) {
			this.ddList.truckYearsList.push({
				value: item == 'all' ? '' : item,
				label: this.translate.instant('site_performance.truck_year_'+item)
			});
		}
	}

	ngOnChanges(changes) {
		this.quarterChartObject = cloneDeep(this.quarterChartObjectDefault);
		this.initData();
		this.chargLinkTranslationPath = this.isChargeLinkDevices ? this.commonData.chargLinkTranslationPath : "";
		this.prepareChartHeaders(true);
	}

	ngOnDestroy() {
		this.permissionsSub.unsubscribe();
	}

	initData() {
		this.quarterChartObject = { ...this.quarterChartObject, ...this.data };
		this.hasData = this.quarterChartObject.data_total_ahr || this.quarterChartObject.data_total_kwhr;
		this.showChart = (this.quarterChartObject.data_total_ahr[this.selectedYear]?.length > 0 || this.quarterChartObject.data_total_kwhr[this.selectedYear]?.length > 0);

		this.chargLinkTranslationPath = this.isChargeLinkDevices ? this.commonData.chargLinkTranslationPath : "";

		this.tooltips = this.common.getTooltipKey(`performance_${this.isChargeLinkDevices ? 'quarter' : 'month'}_`+this.parentPage, this.userService.userHasNOCAccess());
		this.tooltips = this.tooltips ? this.chargLinkTranslationPath + this.tooltips : this.tooltips;

		this.chartConfig = {
			legend: 'none',
			bar: {
				groupWidth: 30
			},
			isStacked: this.options.hasStackedColumn,
			tooltip: {isHtml: true},
		};

		this.timeChartConfig = {...this.chartConfig, vAxis: this.calculateTicksForTimeCharts()};

		this.ddList.quarter = Object.keys(this.quarterChartObject.data_total_ahr).sort((a, b) => +b - +a).map((item) => { return { id: item, year: item.replace('_', ' ') } });
		if(this.ddList.quarter[0] && this.selectedYear != this.ddList.quarter[0].id)
			this.selectedYear = this.ddList.quarter[0].id;
	}

	calculateTicksForTimeCharts() {
		const keys = ['data_total_lift_time', 'data_avg_lift_time', 'data_total_travel_time', 'data_avg_travel_time'];
		const data = this.quarterChartObject;
		let max = 0;
		for(const key of keys) {
			if(data[key][this.selectedYear]) {
				for(const item of data[key][this.selectedYear]) {
					for(let i = 1; i < item.length; i += 2)
						if(item[i] > max)
							max = item[i];
				}
			}
		}
		let ticks = [];
		let interval = 300;
		let maxInterval = (Math.ceil(max / interval) * interval) * 1.1;
		if(maxInterval)
			interval = maxInterval / 5; // 5 steps only

		for(let i = 0; i <= maxInterval; i += interval) {
			let hours = Math.floor(i / 3600);
			let minutes = Math.floor((i % 3600) / 60);
			let seconds = i % 60;
			let time = (maxInterval > 3600 ? hours + ':' : '') + (minutes < 10 ? '0' : '') + minutes + ':' + (seconds < 10 ? '0' : '') + seconds.toFixed(0);
			ticks.push({v: i, f: time});
		}
		return {ticks, title: `(${maxInterval > 3600 ? 'H:' : ''}M:S)`};
	}

	
	prepareChartHeaders(firstTime=false) {
		this.quarterChartObject.allColumnNames = [];
		let truckTypesOptions: any = [
			{id: '', text: this.translate.instant(this.chargLinkTranslationPath + 'site_performance.all_truck_types')}
		];
		
		truckTypesOptions.color = '';
		for(const truckType in this.truckTypes) {
			const truckTypeKey = truckType.replace(/[^\w]/gi, '_');
			this.truckTypesMapper[truckTypeKey] = truckType;
			const idx = this.truckTypes[truckType] - 1;
			let color = this.colorsArr[idx%this.colorsArr.length]
			if (this.isChargeLinkDevices)
				color = this.colorsArr[0];
			truckTypesOptions.push({id: truckTypeKey == '' ? '%' : truckTypeKey, text: truckType, color: color});
			if(this.options.hasTruckTypeFilter || this.parentPage == 'device') {
				this.quarterChartObject.allColumnNames.push(truckType, {role: 'style'});
			}
			for(const chartKey in this.data) {
				for(const year in this.data[chartKey]) {
					for(const q in this.data[chartKey][year]) {
						this.data[chartKey][year][q][this.truckTypes[truckType] + 1] = color;
					}
				}
			}
		}

		this.quarterChartObject.columnNames = ['g', ...this.quarterChartObject.allColumnNames];
		this.trucksColors = truckTypesOptions;
		if(firstTime)
			this.ddList.truckTypes = truckTypesOptions;
	}

	onSelectedTagsChanges(event, type) {
		this.selectedTags[type] = event;

		this.selectedTagNames = [];
		for(let tagId of event) {
			let tag = _.findWhere(this.tags, {id: tagId})
			if(tag)
				this.selectedTagNames[type].push(tag.tag_name)
		}
	}

	buildSiteCharts(type: 'quarter', options: {getYearsList?: boolean} = {}) {
		this.showChart = false;
		const ids = this.getUsageIds();
		this.siteDashboardService.getTruckUsageData(ids, type, {truckYear: this.selectedTruckYear, truckType: this.truckTypesMapper[this.selectedTruckType], truckTag: this.selectedTags, parentPage: this.parentPage}).subscribe((res: any)=>{
			this.handleSiteQuarterDataResponse(res, options.getYearsList);
			this.buildGrid();
		});
	}

	getUsageIds() {
		let ids;
		switch (this.parentPage) {
			case 'customer':
				ids = (this.selectedSites.length ? this.selectedSites : this.sites.map((item) => item.id));
			break;
			case 'site':
				ids = this.siteInfo.id;
			break;
			case 'device':
				ids = this.devices[0].mac_address;
			break;
		}
		return ids;
	}

	handleSiteQuarterDataResponse(res, getYearsList?) {
		const deviceType = this.isChargeLinkDevices ? 'chargLink' : 'iotah';
		this.truckTypes = res.trucksIndexes[deviceType];
		const quarterData = res[deviceType] || {};
		this.quarterChartObject = {
			data_total_ahr: [],
			data_avg_ahr: [],
			data_total_kwhr: [],
			data_avg_kwhr: [],
			data_total_lift_time: [],
			data_avg_lift_time: [],
			data_total_travel_time: [],
			data_avg_travel_time: [],
			allColumnNames: this.quarterChartObject.allColumnNames,
			columnNames: this.quarterChartObject.columnNames,
		};
		if(getYearsList && res.years)
			this.ddList.quarter = res.years;
		if(Object.keys(quarterData).length) {
			this.data = res[deviceType];
			this.quarterChartObject = { ...this.quarterChartObject, ...res[deviceType] };
			this.prepareChartHeaders();
			quarterData?.allColumnNames?.unshift(this.translate.instant('site.quarter'));
			quarterData?.columnNames?.unshift(this.translate.instant('site.quarter'));
		}
	}

	rebuildPerformanceCharts(type?) {
		return this.buildSiteCharts(type);
	}

	exportQuarterlyUsageData() {
		let alphabits = this.common.alphabetCharacters;

		let columnNames = [
			this.translate.instant('site.quarter'), // Add the quarter column
			this.translate.instant(this.chargLinkTranslationPath + 'perf_analytic.total_inuse_as_from_all_events_raw'),
			this.translate.instant(this.chargLinkTranslationPath + 'perf_analytic.avg_total_inuse_as_from_all_events_raw'),
			this.translate.instant(this.chargLinkTranslationPath + 'perf_analytic.total_inuse_ws_from_all_events_raw'),
			this.translate.instant(this.chargLinkTranslationPath + 'perf_analytic.avg_total_inuse_ws_from_all_events_raw')
		];

		if(!this.isChargeLinkDevices)
			columnNames.push(
				this.translate.instant('perf_analytic.total_lift_time_raw'),
				this.translate.instant('perf_analytic.avg_total_lift_time_raw'),
				this.translate.instant('perf_analytic.total_travel_time_raw'),
				this.translate.instant('perf_analytic.avg_total_travel_time_raw')
			);

		let headerObj = {};
		columnNames.forEach((column, colIdx) => {
			let colId = alphabits[colIdx];
			headerObj[colId] = column;
		});
		let header = [headerObj];

		let fileName = this.common.getCleanFileName(this.siteInfo.name) +
			'-' + this.selectedYear + '-'
			+ this.translate.instant(this.chargLinkTranslationPath + (this.isChargeLinkDevices ? 'perf_analytic.quarters_data' : 'perf_analytic.monthly_data'));

		let dataObj = header.slice();
		const chartData = this.quarterChartObject;
		const loops = this.isChargeLinkDevices ? [0, 1, 2, 3] : [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11];
		for (const i of loops) {
			// summation the data for all truck type
			const totalAhr = chartData.data_total_ahr[this.selectedYear][i];
			const avgAhr = chartData.data_avg_ahr[this.selectedYear][i];
			const totalKwhr = chartData.data_total_kwhr[this.selectedYear][i];
			const avgKwhr = chartData.data_avg_kwhr[this.selectedYear][i];
			
			const totalAhrSum = this.sumTruckTypeDataPerQuarter(totalAhr);
			const totalAvgAhrSum = this.sumTruckTypeDataPerQuarter(avgAhr);
			const totalKwhrsSum = this.sumTruckTypeDataPerQuarter(totalKwhr);
			const totalAvgKwhrSum = this.sumTruckTypeDataPerQuarter(avgKwhr);

			// we need to add months instead of quarters if not charge link devices

			const energyData = [
				this.isChargeLinkDevices ? `Q${i + 1}` : moment(i + 1, 'MM').format('MMM'),
				totalAhrSum,
				totalAvgAhrSum,
				totalKwhrsSum,
				totalAvgKwhrSum,
			];

			if(!this.isChargeLinkDevices) {

				const totalLiftTime = chartData.data_total_lift_time[this.selectedYear][i];
				const avgLiftTime = chartData.data_avg_lift_time[this.selectedYear][i];
				const totalTravelTime = chartData.data_total_travel_time[this.selectedYear][i];
				const avgTravelTime = chartData.data_avg_travel_time[this.selectedYear][i];

				const totalLiftTimeSum = this.sumTruckTypeDataPerQuarter(totalLiftTime);
				const totalAvgLiftTimeSum = this.sumTruckTypeDataPerQuarter(avgLiftTime);
				const totalTravelTimeSum = this.sumTruckTypeDataPerQuarter(totalTravelTime);
				const totalAvgTravelTimeSum = this.sumTruckTypeDataPerQuarter(avgTravelTime);

				energyData.push(totalLiftTimeSum, totalAvgLiftTimeSum, totalTravelTimeSum, totalAvgTravelTimeSum);
			}

			let vals = {};
			energyData.forEach((col, idx) => {
				let colId = alphabits[idx];
				vals[colId] = col;
			});

			dataObj.push(vals);
		}
		new AngularCsv(dataObj, fileName);
	}

	sumTruckTypeDataPerQuarter(arr) {
		return _.reduce(arr.filter((ele, i) => i % 2 !== 0), (sum, num) => sum + num, 0);
	}

	private buildGrid() {
		this.showChart	= (
			this.quarterChartObject.data_total_ahr[this.selectedYear].length > 0 || 
			this.quarterChartObject.data_total_kwhr[this.selectedYear].length > 0 || 
			this.quarterChartObject.data_total_lift_time[this.selectedYear].length > 0 || 
			this.quarterChartObject.data_total_travel_time[this.selectedYear].length > 0
		);
		this.hasData = true;
	}
}
