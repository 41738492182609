import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { NavigationService } from '../shared/services/navigation.service';
import { UsersService } from '../users/users.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {
	constructor(
		private navigation: NavigationService,
		private router: Router,
		private usersService: UsersService
	) {}
	canActivate(
		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot
	): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
		return this.authHandler(next, next.data.noAuth);
	}

	/**
	 * Auth Handler
	 * @param route 
	 * @param noAuth for logged-out users only components (login, registration, ..etc.)
	 */
	private authHandler(route: ActivatedRouteSnapshot, noAuth: boolean) {
		let userAuth = this.usersService.getUserAuthData(),
			isLoggedIn = userAuth.logged_in,
			passwordExpired = userAuth.password_expired,
			termsAgreement = userAuth.terms_agreement;

		if(noAuth) {
			if(isLoggedIn)
				this.router.navigate(['/']);
			return !isLoggedIn;
		}
		
		if(!isLoggedIn) {
			if(route['_routerSate']?.url)
				this.navigation.setPreviousUrl(route['_routerSate'].url);
			this.router.navigate(['/login']);
		} else if(passwordExpired) {
			this.router.navigate(['/change-expired-password']);
		} else if(termsAgreement) {
			this.router.navigate(['/terms-and-conditions']);
		}
		return isLoggedIn && !passwordExpired && !termsAgreement;
	}
}
