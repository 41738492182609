import { Component, OnInit, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { UsersService } from 'src/app/users/users.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { ReceivingShipmentService } from 'src/app/management/receiving-shipment/receiving-shipment.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

@Component({
	selector: 'app-receiving-shipment-history-result',
	templateUrl: './receiving-shipment-history-result.component.html',
	styleUrls: ['./receiving-shipment-history-result.component.css']
})
export class ReceivingShipmentHistoryResultComponent implements OnInit {

	reportId: number = 0;
	DEVICE_STATUS = this.commonDataService.RECEIVING_SHIPMENT_DEVICES_STATUS;
	devices: any = [];
	warningsData: any;
	routeSubscription: any;

	source: number = this.deviceManagementService.shipmentSource.receive;

	@ViewChild("confirmModal") confirmModal;

	constructor(
		private router: Router,
		public userService: UsersService,
		private route: ActivatedRoute,
		private commonDataService: CommonDataService,
		public deviceManagementService: DeviceManagementService,
		public receivingShipmentService: ReceivingShipmentService,
		private sideMenuService: SideMenuService
	) { }

	ngOnInit(): void {
		this.sideMenuService.hide();
		if (!this.userService.hasAccessFunction('shipment_management') && !this.userService.hasAccessFunction('cm_check_warnings'))
			this.router.navigate(['unauthorized']);

		if(this.router.url.includes('shipment-quality-history'))
			this.source = this.deviceManagementService.shipmentSource.quality;

		this.routeSubscription = this.route.params.subscribe(params => {
			this.reportId = params.reportId;
			this.getReceiveShipmentResult();
		});
	}

	getReceiveShipmentResult() {
		this.deviceManagementService.getReceiveShipmentResult(this.reportId).subscribe(devices => {
			this.devices = devices;
		})
	}

	showWarnings(device: any) {
		if (!device.details?.warnings)
			return;

		this.warningsData = device.details.warnings;
		this.confirmModal.show();
	}

	printCurrentAndVoltageValue(value) {
		if(value === 0)
			return value;
		if(value) {
			if(Number.isInteger(+value))
				return value;
			value = value.toFixed(1);
			return Math.abs(value % 1) > 0 ? value : Math.trunc(value);
		}
		return '-';
	}

}
