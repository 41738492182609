<div>
	<h3 class="mb-4 font-weight-bold">
		<span class="light-grey"> {{'nav.admin.audit' | translate}}</span>
	</h3>
	<div>
		<nav class="navbar navbar-expand-lg border-radius-top navbar-light bg-white topnav pt-2 pb-0 px-4 nav-border main-black">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': activePage == 'user'}" [routerLink]="['/', 'admin-panel', 'audit']" (click)="activePage = 'user'">
						<b>{{'audit.user_audit' | translate}}</b>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': activePage == 'permission'}" [routerLink]="['/', 'admin-panel', 'audit', 'permission']" (click)="activePage = 'permission'">
						<b>{{'audit.permission_audit' | translate}}</b>
					</a>
				</li>
			</ul>
		</nav>
		<router-outlet></router-outlet>
	</div>
</div>