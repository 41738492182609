<div>
	<h1 class="h2 mb-4">{{'nav.noc_monitoring' | translate}}</h1>
	<table class="table table-striped" *ngIf="devices.length > 0 else noData">
		<thead>
			<tr>
				<th>{{'g.customer_name' | translate}}</th>
				<th>{{'g.site_name' | translate}}</th>
				<th>{{'devices.power_view_sn' | translate}}</th>
				<th>{{'g.mac_address' | translate}}</th>
				<th>{{'devices.cellular_reconnect_time' | translate}}</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let device of devices">
			<td><a [routerLink]="['/', sites[device.site_id].customer_id]">{{sites[device.site_id].customer_name}}</a></td>
			<td><a [routerLink]="['/', sites[device.site_id].customer_id, device.site_id]">{{sites[device.site_id].name}}</a></td>
			<td><a [routerLink]="['/', sites[device.site_id].customer_id, device.site_id, device.mac_address, 'performance']">{{device.serial_number}}</a></td>
			<td><a [routerLink]="['/', sites[device.site_id].customer_id, device.site_id, device.mac_address, 'performance']">{{device.mac_address}}</a></td>
			<td>{{device.cellular_reconnect_time}}</td>
		</tr>
		</tbody>
	</table>
</div>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

