import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { EnterpriseService } from './enterprise.service';
import { Router } from "@angular/router";
import { NotificationMessageService } from '../shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore'; 
import { UsersService } from '../users/users.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

@Component({
	selector: 'app-enterprise',
	templateUrl: './enterprise.component.html',
	styleUrls: ['./enterprise.component.css']
})
export class EnterpriseComponent implements OnInit, AfterViewInit {

	enterprise: any[] = [];
	admins = {};
	filters = {
		searchString: '',
		isPartial: false,
		groupType: []
	};
	groupTypes;
	currentUser: any;
	@ViewChild("confirmModal") confirmModal;
	@ViewChild("linkedDevicesModal") linkedDevicesModal;
	@ViewChild("deleteAutoCustomerProcess") deleteAutoCustomerProcess;

	constructor(
		public enterpriseService: EnterpriseService,
		private router: Router,
		private notificationMessage: NotificationMessageService,
		public translate: TranslateService,
		private usersService: UsersService,
		private sideMenuService: SideMenuService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		this.currentUser = this.usersService.getCurrentUser();
		if(!this.currentUser.is_admin || !this.usersService.hasAccessFunction('enterprise_management'))
			return this.router.navigate(['/unauthorized']);
		this.groupTypes = this.enterpriseService.getGroupTypes();
		this.getEnterprises();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			if(result[1][0] == 'deleteEnterprise') {
				return this.deleteEnterprise(result[1][1], false, false);
			}
		});
	}

	getEnterprises() {
		this.enterpriseService.getEnterprises().subscribe(
			(data: any) => {
				this.enterprise = data.sort((item1, item2) => item1.name.toLowerCase() > item2.name.toLowerCase() ? 1 : -1);;
				let adminIds = _.pluck(data, "admins");
				this.getAdminNames([].concat.apply([], adminIds));
			}
		);
	}

	getAdminNames(adminIds) {
		this.enterpriseService.getUsersInfo(adminIds).subscribe(data=> {
			this.admins = _.indexBy(data, "id");
		});
	}

	goToEditEnterprise(id) {
		this.router.navigate(['/enterprise', 'edit', id]);
	}

	deleteEnterprise(id, forceDelete, deleteAutoGeneCustomer) {
		const options = {
			forceDelete: forceDelete,
			deleteAutoGeneCustomer: deleteAutoGeneCustomer
		}
		this.enterpriseService.deleteEnterprise(id, options).subscribe((data: any) => {
			this.handleDeleteResponse(id, data);
		});
	}

	deleteEnterpriseCustomer(id, deleteAutoGeneCustomer) {
		const options = {
			forceDelete: true,
			deleteAutoGeneCustomer: deleteAutoGeneCustomer
		}
		this.enterpriseService.deleteEnterprise(id, options).subscribe((data: any) => {
			this.handleDeleteResponse(id, data);
		});
	}

	handleDeleteResponse(id, res) {
		switch(res?.api_status) {
			case 1:
				this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.getEnterprises();
				break;
			case 2:
				this.deleteAutoCustomerProcess.message = 'ent.enterprise_has_customers';
				this.deleteAutoCustomerProcess.passData = {enteroriseId: id};
				this.deleteAutoCustomerProcess.show();
				break;
			case 3:
				this.notificationMessage.setMessage('translate|ent.enterprise_has_users', {clearOnXTimeNavigate: 1});
				break;
			case 4:
				this.notificationMessage.setMessage('translate|ent.enterprise_has_other_sites', {clearOnXTimeNavigate: 1});
				break;
			case 5:
				this.notificationMessage.setMessage('translate|ent.auto_generated_has_devices_sites', {clearOnXTimeNavigate: 1});
				break;
			default:
				this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
		}
	}

	search() {
		 this.enterpriseService.getEnterprises(this.filters).subscribe(
			(data: any) => {
				this.enterprise = data;
			}
		);

	}

}
