import { Directive, ElementRef, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
	selector: '[show-password]'
})
export class ShowPasswordDirective implements OnInit {

	@Input('show-password') params;
	showIconClass = 'fa fa-fw password-eye fa-eye-slash';
	hideIconClass = 'fa fa-fw password-eye fa-eye';
	eyeIcon;

	isShown = false;
	constructor(
		private el: ElementRef,
		private renderer2: Renderer2,
	) {}

	ngOnInit() {
		if(this.el.nativeElement.hasAttribute('disabled'))
			return;
		this.eyeIcon = this.renderer2.createElement('span');

		this.renderer2.setAttribute(this.eyeIcon, 'class', this.showIconClass);
		this.renderer2.insertBefore(this.el.nativeElement.parentNode, this.eyeIcon, this.renderer2.nextSibling(this.el.nativeElement));

		this.eyeIcon.addEventListener('click', () => {
			this.toggle();
		});
	}

	toggle() {
		this.isShown = !this.isShown;
		if (this.isShown) {
			this.el.nativeElement.setAttribute('type', 'text');
			this.renderer2.setAttribute(this.eyeIcon, 'class', this.hideIconClass);
		} else {
			this.el.nativeElement.setAttribute('type', 'password');
			this.renderer2.setAttribute(this.eyeIcon, 'class', this.showIconClass);
		}
	}
}