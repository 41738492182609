<div>
	<h6 class="alert-danger p-3" *ngIf="successFile === false">
		{{'error_msgs.invalid_file_extention' | translate}}
	</h6>
	<h6 class="alert-danger p-3" *ngIf="successSize === false">
		{{'error_msgs.invalid_file_size' | translate}}
	</h6>
</div>

<div class="row col-md-8">
	<input #uploadFile hidden="true" type="file" onclick="this.value = null" (change)="checkFile($event)" accept=".bin"/>
	<button mat-flat-button color="primary" (click)="uploadFile.click()">{{'g.select_file' | translate}}</button>
	
	<input class="ml-2 mt-1 form-control col-md-1" [(ngModel)]="fwVersion" />
	
	<span class="ml-2 mt-2 col-md-5" *ngIf="fileName"> {{'g.file_name' | translate}}: <b>{{fileName}}</b></span>
	
	<button mat-flat-button color="primary" class="ml-2" [disabled]="!file || !fwVersion || !successSize || !successFile" (click)="upload()">{{'g.upload' | translate}}</button>
</div>
<table class="table table-striped mt-4">
	<thead>
		<tr>
			<th>{{'devices.fw_files_list' |translate}}</th>
			<th>{{'g.action' |translate}}</th>
		</tr>
	</thead>
	<tbody>
		<tr *ngFor="let file of fwList">
			<td>
				{{file}}
			</td>
			<td>
				<button class="btn btn-primary"  type="button" (click)="deleteFWFile(file)">{{'g.delete' | translate}}</button>
			</td>
		</tr>
	</tbody>
</table>