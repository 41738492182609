import { Component, ChangeDetectorRef, OnInit } from '@angular/core';
import { SideMenuService } from '../shared/side-menu/side-menu.service';
import { LeftPanelService } from '../shared/left-panel/left-panel.service';
import { Subscription } from 'rxjs';
import { CommonService } from '../shared/services/common.service';
import {CommonDataService} from '../shared/services/common-data.service'

@Component({
	selector: 'app-main',
	templateUrl: './main-layout.component.html',
	styleUrls:['./main-layout.component.css']
})

export class MainLayoutComponent implements OnInit {

	visibleSideMenu:boolean = false;
	hideMenuView: boolean = false;
	hasLeftPanel:boolean = false;
	visiblitySub: Subscription = new Subscription();
	leftMenuSub: Subscription = new Subscription();
	hideMenuSub: Subscription = new Subscription();

	constructor(
		public sideMenuService: SideMenuService,
		public leftPanelService: LeftPanelService,
		private cdref: ChangeDetectorRef,
		public commonService: CommonService,
		public commonDataService: CommonDataService,
	) {}

	ngOnInit() {
		this.visiblitySub = this.sideMenuService.visibility.subscribe(v=> {
			this.visibleSideMenu = v;
			this.cdref.detectChanges();
		});
		this.leftMenuSub = this.leftPanelService.leftPanelVisibility.subscribe(v=> {
			this.hasLeftPanel = v;
		});
		this.hideMenuSub = this.sideMenuService.hideMenuView.subscribe(v=> {
			this.hideMenuView = v;
		});

	}

	ngOnDestroy() {
		this.cdref.detach();
		this.visiblitySub.unsubscribe();
		this.leftMenuSub.unsubscribe();
		this.hideMenuSub.unsubscribe();
	}
	reloadPage() {
		location.reload();
	}
}