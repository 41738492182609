import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fake-data-generator',
  templateUrl: './fake-data-generator.component.html',
  styleUrls: ['./fake-data-generator.component.css']
})
export class FakeDataGeneratorComponent implements OnInit {

	typeList = [
		{name: 'Events', id: 'event'},
		{name: 'Rts', id: 'rt'},
		{name: 'Quick View', id: 'quick'}
	];

	macAddress: string;
	from: Date;
	to: Date;
	maxPerDay: number;
	type: string;
	withDailyDetails: boolean;
	withAnalytics: boolean;

	constructor(
		private adminService: AdminService,
		private commonData: CommonDataService,
		private router: Router
	) {}

	ngOnInit(): void {
		const isProduction = window.location.hostname == this.commonData.productionDomain;
		const isStaging = window.location.hostname == this.commonData.stagingDomain;
		if(isProduction || isStaging) {
			this.router.navigate(['/unauthorized']);
			return;
		}
	}

	onSubmit() {
		this.adminService.generateData(this.macAddress, this.from, this.to, this.type, this.maxPerDay, this.withDailyDetails, this.withAnalytics).subscribe();
	}
}
