<div class="my-3" *ngIf="usersService.hasAccessFunction('customer_management')">
	<button type="button" [routerLink]="['edit', 0]" class="btn btn-primary mb20">{{'g.add_new' | translate}} <fa-icon icon="plus"></fa-icon></button>
</div>
<div class="mt-2 mb-2 ml-2 mr-2">
	<div class="card">
		<div class="card-body">
			<div class="row">
				<label class="col-1" for="search_enterprise"><small><strong>{{'g.search' | translate}}</strong></small></label>
				<div class="col-4">
					<input type="text" class="form-control" name="search_enterprise" [(ngModel)]="filters.searchString"/>
				</div>
				<div class="col-4 mt-2">
					<input class="form-check-input" type="checkbox" name="isPartial" [(ngModel)]="filters.isPartial" id="defaultCheck1">
					<label class="form-check-label" for="defaultCheck1">
						<small><strong>{{'g.partial_search' | translate}}</strong></small>
					</label>
				</div>
			</div>
			<div class="mt-1">
				<button class="btn btn-primary" (click)="getCustomers()">{{'g.search'| translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="mt-2 mb-2 ml-2 mr-2">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.name' | translate}}</th>
				<th>{{'g.actions' | translate}}</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let customer of customers">
			<td>{{customer.customer_name}}</td>
						
			<td>
				<div class="btn-group mx-3" dropdown placement="left">
					<fa-icon icon="cog" size="lg" dropdownToggle class="dropdown-toggle"></fa-icon>
					<ul *dropdownMenu class="dropdown-menu" role="menu">
						<li role="menuitem" *ngIf="usersService.hasAccessFunction('customer_management')">
							<a class="dropdown-item pointer" (click)="goToEditCustomer(customer.id)">{{'g.edit' | translate}}</a>
						</li>
						<li role="menuitem" *ngIf="usersService.hasAccessFunction('customer_management')">
							<a class="dropdown-item pointer" (click)="confirmDeleteCustomer(customer.id)">{{'g.delete' | translate}}</a>
						</li>
						<li role="menuitem" *ngIf="currentUser.is_admin">
							<a class="dropdown-item pointer" [routerLink]="['/sites',customer.id]">{{'site.sites' | translate}}</a>
						</li>
						<li role="menuitem">
							<a class="dropdown-item pointer" [routerLink]="['/zombie-sites', customer.id]">{{'site.zombie_sites' | translate}}</a>
						</li>
					</ul>
				</div>
			</td>
		</tr>
		</tbody>
	</table>
</div>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>