import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from '../../device-management.service';
import * as _ from 'underscore';

@Component({
	selector: 'app-customer-requests',
	templateUrl: './customer-requests.component.html',
	styleUrls: ['./customer-requests.component.css']
})
export class CustomerRequestsComponent implements OnInit, AfterViewInit {

	customersList: any;
	usersInfo: any = {};
	enterprisesInfo: any = {};
	approvedCustomerInfo: any = {};
	linkedDevices: any[] = [];

	@ViewChild("confirmModal") confirmModal;
	@ViewChild("identicalCustomerModal") identicalCustomerModal;
	@ViewChild("linkedDevicesModal") linkedDevicesModal;

	constructor(
		private notificationMessageService: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		public usersService: UsersService,
		private enterpriseService: EnterpriseService
	) { }

	ngOnInit() {
		this.getPendingCustomers();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			return this.rejectRequest(result[1]);
		});
		this.identicalCustomerModal.onClose.subscribe((confirm) => {
			if(confirm)
				this.mapToApprovedCustomer();
		});
	}

	confirmRejectRequest(data) {
		this.confirmModal.passData = data;
		this.confirmModal.message = 'device_management.reject_customer_prompt';
		this.confirmModal.show();
	}
	
	getPendingCustomers() {
		this.deviceManagementService.getPendingCustomers({getReqUser: true}).subscribe((res) => {
			this.customersList = res;
			let userIds			= _.chain(res).pluck('user_id').uniq().value();
			let enterpriseIds	= _.chain(res).pluck('enterprise_id').uniq().value();
			this.usersService.getUsersInfo(userIds).subscribe((res) => {
				this.usersInfo = _.indexBy(res, 'id');
			});
			this.enterpriseService.getEnterprise(enterpriseIds, {source: 'manage_device', multi: true}).subscribe((res) => {
				this.enterprisesInfo = _.indexBy(res, 'id');
			});
		});
	}

	rejectRequest(customerId) {
		this.deviceManagementService.rejectCustomerRequest(customerId).subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getPendingCustomers();
		});
	}

	viewDevices(customerId) {
		this.linkedDevices = [];
		this.deviceManagementService.getCustomerLinkedDevices(customerId).subscribe((devices: any) => {
			if(devices.length) {
				this.deviceManagementService.getDeviceOwners(devices, {allowEmpty: true}).subscribe((owners: any) => {
					let linkedDevicesOwners = _.chain(...(_.pluck(owners.customersAndSites, 'sites'))).union().indexBy('id').value();
					let linkedDevices = [];
					for(let device of owners.devices) {
						if(linkedDevicesOwners[device.site_id])
							linkedDevices.push({
								site_id: device.site_id,
								customer_id: linkedDevicesOwners[device.site_id].customer_id,
								mac_address: device.mac_address,
								latitude: device.latitude,
								longitude: device.longitude
							});
					}
					this.linkedDevices = _.uniq(linkedDevices, d => d.mac_address);
				});
			}
			this.linkedDevicesModal.show();
		});
	}

	showIdenticalCustomerModal(customer) {
		this.approvedCustomerInfo = Object.assign({}, customer);
		this.identicalCustomerModal.show();
	}

	mapToApprovedCustomer() {
		this.deviceManagementService.mapToApprovedCustomer(this.approvedCustomerInfo.customer_id, this.approvedCustomerInfo._approved_customer_id).subscribe((res) => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getPendingCustomers();
		});
	}
}
