import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from '../../device-management.service';
import * as _ from 'underscore';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';

@Component({
	selector: 'app-device-replacement-requests',
	templateUrl: './device-replacement-requests.component.html',
	styleUrls: ['./device-replacement-requests.component.css']
})
export class DeviceReplacementRequestsComponent implements OnInit, AfterViewInit {

	requestsList: any;
	usersInfo: any = {};
	enterprisesInfo: any = {};

	@ViewChild("confirmModal") confirmModal;

	constructor(
		private translateService: TranslateService,
		private notificationMessageService: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		public usersService: UsersService,
		public enterpriseService: EnterpriseService,
		private router: Router
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('replace_device'))
			return this.router.navigate(['/unauthorized']);
		this.getReplacementRequests();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			if(result[1][0] == 'approveRequest') {
				return this.approveRequest(result[1][1]);
			}

			if(result[1][0] == 'rejectRequest') {
				return this.rejectRequest(result[1][1]);
			}
		});
	}

	confirmAction(action, data) {
		this.confirmModal.passData = [action, data];
		switch(action) {
			case 'approveRequest':
				this.confirmModal.message = 'device_management.approve_replacement_prompt';
			break;
			case 'rejectRequest':
				this.confirmModal.message = 'device_management.reject_replacement_prompt';
			break;
		}
		this.confirmModal.show();
	}
	
	getReplacementRequests() {
		this.deviceManagementService.getReplacementRequests({get_sn: true}).subscribe((res) => {
			this.requestsList = res;
			let userIds			= _.chain(res).pluck('user_id').uniq().value();
			let enterpriseIds	= _.chain(res).pluck('enterprise_id').uniq().value();
			this.usersService.getUsersInfo(userIds).subscribe((res) => {
				this.usersInfo = _.indexBy(res, 'id');
			});
			this.enterpriseService.getEnterprise(enterpriseIds, {source: 'manage_device', multi: true}).subscribe((res) => {
				this.enterprisesInfo = _.indexBy(res, 'id');
			});
		});
	}

	approveRequest(id) {
		this.deviceManagementService.approveReplacementRequest(id).subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getReplacementRequests();
		});
	}
	
	rejectRequest(id) {
		this.deviceManagementService.rejectReplacementRequest(id).subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getReplacementRequests();
		});
	}
}
