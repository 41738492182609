import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../../admin.service';

@Component({
	selector: 'app-view-error',
	templateUrl: './view-error.component.html',
	styleUrls: ['./view-error.component.css']
})
export class ViewErrorComponent implements OnInit {

	log: any;
	id: number;
	errorType: string;
	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute
	) {
		this.id = this.route.snapshot.params.id;
		this.errorType = this.route.snapshot.data['pageName'];
	}

	ngOnInit() {
		this.adminService.getErrorLog(this.id, this.errorType).subscribe(
			data => {
				this.log = data;
			}
		);
	}
}