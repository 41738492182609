import { Component, OnInit } from '@angular/core';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'disconnected_devices',
	templateUrl: './disconnected-device.component.html',
	styleUrls: ['./disconnected-device.component.css']
})
export class DisconnectedDevicesComponent implements OnInit {
	disconnectedDevices: any[] = [];
	lastUpdateTime: any;
	columnDefs: any[] = [];
	allColumnsKeys: string[] = [];
	pageSize: number = 20
	fileName: string = '';

	constructor(
		private usersService: UsersService,
		private deviceService: DeviceService,
		private router: Router,
		private translate: TranslateService,
		private commonService: CommonService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			this.router.navigate(['/unauthorized']);
		this.getDisconnectedDevicesReport();
	}

	getDisconnectedDevicesReport() {
		this.deviceService.getDisconnectedDevicesReport().subscribe((response: any) => {
			const tableData: any[] = [];
			response.devicesList.forEach((row: any) => {
				const rowObj = {
					mac_address: row.mac_address,
					serial_number: row.serial_number,
					site_name: row.site_name.toUpperCase(),
					customer_name: row.customer_name.toUpperCase(),
					siteId: row.site_id,
					customerId: row.customer_id,
					last_connected_in: this.commonService.nowTime() - row.last_connect_time,
					last_connected_in_formatted: this.getLastConnectedIn(row.last_connect_time),
					last_connect_time: moment(row.last_connect_time*1000).toISOString(),
					connection_type: this.getConnectionType(row.metered, row.client_socket_type),
					ip_address: row.ip_address
				}
				tableData.push(rowObj);
			});
			this.disconnectedDevices = tableData;
			this.buildGrid();
			this.lastUpdateTime = this.formatTime(response?.lastUpdateTime);
		})
	}

	buildGrid() {
		const columnDefs: any[] = [
			{
				headerName: 'g.mac_address', field: 'mac_address',
				cellRendererSelector: function (params: any) {
					params['label'] = params.data.mac_address;
					params['link'] = ['/#', params.data.customerId, params.data.siteId, params.data.mac_address, 'performance'].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
			},
			{ headerName: 'g.serial_number', field: 'serial_number' },
			{
				headerName: 'g.customer_name', field: 'customer_name',
				cellRendererSelector: function (params: any) {
					params['label'] = params.data.customer_name	;
					params['link'] = ['/#',params.data.customerId].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				}
			},
			{
				headerName: 'g.site_name', field: 'site_name',
				cellRendererSelector: function (params: any) {
					params['label'] = params.data.site_name	;
					params['link'] = ['/#', params.data.customerId, params.data.siteId].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				}
			},
			{ headerName: 'devices.last_connect_time', field: 'last_connect_time', type: 'dateTime' },
			{
				filter: 'agTextColumnFilter',
				headerName: 'devices.last_connected_in',
				filterValueGetter: 'data.last_connected_in',
				field: 'last_connected_in_formatted',
				colId: 'last_connected_in_formatted',
				comparator: this.lastConnectedInComparator,
				filterParams: {
					filterOptions: ['equals', 'greaterThan', 'lessThan'],
					defaultOption: 'equals',
					thisObject: this,
					textMatcher: ({ filterOption, value, filterText }: { filterOption: any, value: any, filterText: string }) => {
						return this.commonService.durationFormatter({filter: filterOption, value, filterText});
					}
				}
			},
			{ headerName: 'devices.connection_type', field: 'connection_type' },
			{ headerName: 'g.ip_address', field: 'ip_address' },
		];
		this.columnDefs = columnDefs;
		this.fileName = `${this.translate.instant('nav.disconnected_devices')} - ${moment().format('YYYY/MM/DD')}.csv`;
	}

	lastConnectedInComparator(valueCompareA: string, valueCompareB: string, dataCompareA: any, dataCompareB: any) {
		const numValueA = dataCompareA.data.last_connected_in;
		const numValueB = dataCompareB.data.last_connected_in;
		return numValueA - numValueB;
	}

	getConnectionType(metered: boolean, client_socket_type: number) {
		let connectionType = '';
		if (metered)
			connectionType = `${this.translate.instant('client_socket_type.' + client_socket_type)} (${this.translate.instant('g.metered')})`;
		else
			connectionType = `${this.translate.instant('client_socket_type.' + client_socket_type)} (${this.translate.instant('g.non_metered')})`;
		return connectionType;
	}

	formatTime(last_connect_time: string | number | Date) {
		const fromDate = Math.round(new Date(new Date((+last_connect_time) * 1000).getTime()).getTime());
		return moment(new Date(fromDate)).format('MM/DD/YYYY HH:mm:ss A');
	}

	getLastConnectedIn(last_connect_time: any) {
		const formattedLastConnectTime = this.formatTime(last_connect_time);
		const currentTime = moment();
		const lastConnectTime = moment(formattedLastConnectTime, 'MM/DD/YYYY HH:mm:ss A');
		const duration = moment.duration(currentTime.diff(lastConnectTime));
		const days = Math.floor(duration.asDays()) ? `${Math.floor(duration.asDays())} Days` : "";
		const hours = duration.hours() ? `${duration.hours()} Hours` : "";
		const minute = duration.minutes() ? `${duration.minutes()} Minutes` : "" ;
		const result = days != "" ? `${days} ${hours}` : `${hours} ${minute}`;
		return result;
	}
}
