<div class="mt-5">
	<h2>{{'nav.registration_reports' | translate}}</h2>
</div>

<div class="mt-3 mb-2 ml-2 mr-2">
	<table  class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.customer_name' | translate}}</th>
				<th>{{'g.site_name' | translate}}</th>
				<th>
					<div class="d-flex justify-content-center">
						{{'registration_purposes.generate_report' | translate}}
					</div>
				</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let site of sites">
				<td><a target="_blank" [routerLink]="['/', site.customer_id]">{{site.customer_name}}</a></td>
				<td><a target="_blank" class="blue-hover pointer ml-1" [routerLink]="['/', site.customer_id, site.site_id]" target="_blank">{{site.site_name}}</a></td>
				<td>
					<div class="d-flex justify-content-center">
						<fa-icon role="button" icon="download" size="lg" (click)="downloadRegistrationPurposes(site.site_id)" title="{{'g.download' | translate}}"></fa-icon>
					</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
