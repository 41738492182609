<form #editDevice="ngForm" (ngSubmit)="saveDevice()" validate-form="editDevice">
	<div class="col-md-8 col-md-offset-2">
		<div class="boxs3">
			<h2>{{(isNew ? 'g.add_device' : 'g.update_device') | translate}}</h2>
			<div class="padding-8" *ngIf="isNew">
				<label for="mac_address">{{'g.mac_address' | translate}}</label>
				<input type="text" class="form-control" name="mac_address" [(ngModel)]="device.mac_address" required/>
			</div>
			<div class="padding-8">
				<label for="serial_number">{{'devices.serial_number' | translate}}</label>
				<input type="text" class="form-control" name="serial_number" [(ngModel)]="device.serial_number" required/>
			</div>
			<div class="padding-8">
				<label for="hw_version">{{'devices.hw_version' | translate}}</label>
				<input type="text" class="form-control" name="hw_version" [(ngModel)]="device.hw_version" required/>
			</div>
			<br />
			<div>
				<button type="submit" class="btn btn-primary">{{'g.submit' | translate}}</button>
				<button type="button" class="btn btn-default" (click)="goBack()">{{'g.cancel' | translate}}</button>
			</div>
		</div>
	</div>
</form>