<div>
	<div *ngIf="gridData.length > 0">
		<app-grid [data]="gridData" [columns]="gridColumns" [sizeColumnsToFit]="true" #agGrid id="actions-hitory" *ngIf="gridData.length > 0">
		</app-grid>
	</div>
	<div class="text-danger" *ngIf="invalidDateRange; else noData"><b>{{'g.start_lg_end' | translate}}</b></div>
	<ng-template #noData>
		<div *ngIf="emptyResponse">
			<strong>{{'g.no_results_found' | translate}}</strong>
		</div>
	</ng-template>
</div>