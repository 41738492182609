import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { CustomersService } from "../customers.service";
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';

@Component({
	selector: 'app-customer-edit-page',
	templateUrl: './customer-edit-page.component.html',
	styleUrls: ['./customer-edit-page.component.css']
})
export class CustomerEditPageComponent implements OnInit {

	customer: Object = {
		id: 0,
		name: '',
		phoneno: '',
		contactname: '',
		customer_enterprises: null
	};

	enterprisesList: any[] = [];

	constructor(
		private router: Router,
		private route: ActivatedRoute,
		private customersService: CustomersService,
		private notificationMessage: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private enterprisesService: EnterpriseService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		this.route.params.subscribe(
			params => {
				this.customer['id'] = Number(params['customerId']);
			
				if(this.customer['id'])
					this.getCustomer(this.customer['id']);

				this.getEnterprises();
			}
		);
	}

	getCustomer(id) {
		this.customersService.getCustomer(id).subscribe(
			(data: Object) => {
				this.customer = data;
			}
		);
	}

	saveCustomer() {
		this.customersService.saveCustomer(this.customer).subscribe((data: any) => {
			if(data) {
				let msg = 'globalSuccessMsg';
				if (data?.api_status) {
					switch(data?.api_status) {
						case 2:
							return this.notificationMessage.setMessage('translate|customer.customer_name_already_exist');
							break;
						default:
							msg = 'globalErrMsg';
							break;
					}
				}
				this.notificationMessage.setMessage(msg);
				this.goBack();
			} else {
				this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
			}
		});
	}

	getEnterprises() {
		this.enterprisesService.getEnterprises({source: 'customer_edit', customer_edit: true}).subscribe((res: any) => {
			this.enterprisesList = res;
		});
	}

	goBack() {
		this.router.navigate(['/customers']);
	}
}