import { Component, OnInit } from '@angular/core';
import { AdminService } from '../admin.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-sim-management',
	templateUrl: './sim-management.component.html',
})


export class SimManagementComponent implements OnInit {
	sims: string[] = [];
	devices: any = {};
	sites: any = {};

	constructor(
		private adminServices: AdminService,
		private usersService: UsersService,
		private router: Router,
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('super_admin', 'write'))
			return this.router.navigate(['/unauthorized']);

		this.getAllSims();
	}

	getAllSims() {
		this.adminServices.getAllSims().subscribe((res: any) => {
			this.sims = res.sims;
			this.devices = res.devices;
			this.sites = res.sites;
		});
	}
}