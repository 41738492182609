<div>
    <div class="d-flex justify-content-between mb-2 px-2">
		<h2>{{'nav.qv_logs' | translate}}</h2>
	</div>

	<div class="row col-md-12 p-3">
		<div class="col-md-3">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input [ngClass]="{'invalid-input': invalidStartDate}" type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp1" [(ngModel)]="fromDate" bsDatepicker #d1="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
				<div class="input-group-append pointer" (click)="d1.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>

		<div class="col-md-3">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input [ngClass]="{'invalid-input': invalidEndDate}" type="text" class="form-control" placeholder="mm/dd/yyyy" name="dp2" [(ngModel)]="toDate" bsDatepicker #d2="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}">
				<div class="input-group-append pointer" (click)="d2.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
		<button class="btn btn-primary run ml-4"  type="button" (click)="getQuickViewHistory()">{{"g.run" | translate}}</button>
	</div>

	<div *ngIf="quickViewHistoryLogs.length; else noData">
		<sct-custom-table
			[columnsConfig]="columnConfig"
			[data]="quickViewHistoryLogs"
			[config]="tableConfig"
		>
		</sct-custom-table>
	</div>

	<ng-template #noData>
		<div class="alert alert-info" *ngIf="quickViewHistoryLogs.length == 0">{{'g.no_data_available' | translate}}</div>
	</ng-template>

</div>

<app-modal #liveRtDetailsModal [title]="'qv.live_rt' | translate" [hasCloseButton]="false" [hasConfirmButton]="false" [size]="'l'">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{ 'g.field_name' | translate }}</th>
				<th>{{ 'g.value' | translate }}</th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let item of liveRtModalData | keyvalue">
				<td>{{ 'rt.' + item.key | translate }}</td>
				<td>{{ item.value }}</td>
			</tr>
		</tbody>
	</table>
</app-modal>