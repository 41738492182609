<div class="row vh-50 custom-row">
	<div class="col-md-8 d-flex align-items-center">
		<label class="form-label no-wrap" for="revert_time">{{"connectivity_frequency.revert_time" | translate}}</label>
		<input class="form-control" pInputText type="text" name="Revert Time" id="revert_time" [(ngModel)]="revertTime" disabled>
	</div>
	<div class="col-md-4 text-end d-flex align-items-center justify-content-end">
		<button *ngIf="showExtend" class="btn btn-info btn-sm" (click)="showPopup('extend')">{{'connectivity_frequency.extend' | translate}}</button>
		<button *ngIf="showCancel" class="btn btn-danger btn-sm" (click)="showPopup('cancel')">{{'connectivity_frequency.cancel' | translate}}</button>
		<button *ngIf="showRenew" class="btn btn-success btn-sm" (click)="showPopup('renew')">{{'connectivity_frequency.renew' | translate}}</button>
	</div>
</div>

<app-modal [confirmBtnText]="'g.yes' | translate" [closeBtnText]="'g.no' | translate" #popupModal title="{{'perf_analytic.quarter_not_finished' | translate}}" size="lg">
	{{popupData | translate}}
</app-modal>