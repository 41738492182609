import { Component, OnInit, Input } from '@angular/core';
import { DeviceManagementService } from '../device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';

@Component({
	selector: 'app-reverse-calibration',
	templateUrl: './reverse-calibration.component.html',
})
export class ReverseCalibrationComponent implements OnInit {

	@Input() device: any = {};

	constructor(
		private deviceManagementService: DeviceManagementService,
		private notificationMessage: NotificationMessageService,
	){}

	ngOnInit() {}

	reverseCalibration(type = 'cancel') {
		this.deviceManagementService.reverseCalibration(this.device.mac_address, type).subscribe((data: any)=>{
			this.device.config_info = {...this.device.config_info, ...data};
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	};
}