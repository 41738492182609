<div class="row justify-content-md-center">
	<div class="col-md-6">
		<form #registerRequestForm="ngForm" (ngSubmit)="submitRegistrationRequest()" validate-form="validateRegistrationRequest">
			<h2>{{'login_register.validate_registration_request' | translate}}</h2>
			<div class="mt-4">
				<div>
					<label for="first_name">{{'login_register.first_name' | translate}}</label>
					<input type="text" class="form-control" name="first_name" [(ngModel)]="registrationRequest.first_name" required/>
				</div>
				<div class="mt-3">
					<label for="last_name">{{'login_register.last_name' | translate}}</label>
					<input type="text" class="form-control" name="last_name" [(ngModel)]="registrationRequest.last_name" required/>
				</div>
				<div class="mt-3">
					<label for="email">{{'login_register.email' | translate}}</label>
					<input email type='email' class="form-control" name="email" [(ngModel)]="registrationRequest.email" required/>
				</div>
				<div class="mt-3">
					<label for="phone_number">{{'login_register.phone_no' | translate}}</label>
					<input type="text" class="form-control" name="phone_number" [(ngModel)]="registrationRequest.phone_number"/>
				</div>
				<div class="mt-3">
					<label for="company_name">{{'login_register.company_name' | translate}}</label>
					<input type="text" class="form-control" name="company_name" [(ngModel)]="registrationRequest.company_name" required/>
				</div>
				<div class="mt-3">
					<label for="job_role">{{'login_register.job_role' | translate}}</label>
					<input type="text" class="form-control" name="job_role" [(ngModel)]="registrationRequest.job_role" required/>
				</div>
				<div class="mt-3">
					<label for="enterprise_id">{{'ent.enterprise' | translate}}</label>
					<ng-select required [items]="enterpriseList" bindLabel="name" bindValue="id" placeholder="{{'ent.select_enterprise' | translate}}" name="enterprise_id" [(ngModel)]="registrationRequest.enterprise_id" [clearable]="false">
					</ng-select>
					<a href="javascript:;" (click)="showEnterpriseModal();" *ngIf="usersService.hasAccessFunction('enterprise_management') && !enterpriseAdded">
						{{'ent.add_new_enterprise' | translate}}
					</a>
				</div>
				<div class="mt-3">
					<label for="enterprise_id">{{'permissions.permission' | translate}}</label>
					<ng-select
						[items]="permissionList"
						bindLabel="name"
						bindValue="id"
						placeholder="{{'permissions.permission' | translate}}"
						name="default_permission_id"
						[(ngModel)]="registrationRequest.default_permission_id"
						[clearable]="false"
						required
					></ng-select>
				</div>
				<div class="mt-5">
					<button type="submit" class="btn btn-primary">{{'g.validate' | translate}}</button>
					<button type="button" class="btn btn-default" (click)="goBack()">{{'g.cancel' | translate}}</button>
					<button type="button" class="btn btn-danger pull-right" (click)="rejectRequestModal.show()">{{'g.reject' | translate}}</button>
				</div>
			</div>
		</form>
	</div>
</div>
<app-modal #rejectRequestModal title="{{'action_log.reject_registration_request' | translate}}" message="g.del_user_prompt"></app-modal>
<app-modal #enterpriseModal title="{{'ent.add_new_enterprise' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false" size="xl">
	<app-enterprise-edit
		*ngIf="enterpriseInfo"
		[enterprise]="enterpriseInfo"
		[isModal]="true"
		(formSubmitted)="onEnterpriseAdded($event)"
		(formCancelled)="enterpriseModal.hide()"
	></app-enterprise-edit>
</app-modal>
