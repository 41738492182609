import { CommonService } from './../../shared/services/common.service';
import { UsersService } from './../../users/users.service';
import { Component, OnInit } from '@angular/core';

import * as _ from 'underscore';

import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

@Component({
	selector: 'app-blocked-fw-reports',
	templateUrl: './blocked-fw-reports.component.html',
	styleUrls: ['./blocked-fw-reports.component.css']
})
export class BlockedFwReportsComponent implements OnInit {

	reportData: any = {};
	isAdmin = false;

	constructor(
		private deviceService: DeviceService,
		private notificationMessageService: NotificationMessageService,
		private sideMenuService: SideMenuService,
		private userService: UsersService,
		public common: CommonService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		this.isAdmin = this.userService.getCurrentUser().is_admin;

		this.deviceService.getBlockedFWReport().subscribe(
			(data: any) => {
				let filteredDevices = {};
				data.devices.forEach((device) => {
					if(!device.is_deleted) {
						filteredDevices[device.mac_address] = device;
					}
				});
				this.reportData.devices = this.filterData(data, filteredDevices);
			}
		);
	}
	
	filterData(data, devices) {
		let devicesIDs = _.pluck(devices, 'mac_address');

		let result = [];
		data.data.forEach((item) => {
			if(devicesIDs.includes(item.id)) {
				let device = Object.assign({}, devices[item.id]);
				device.fw_version = item.version;
				device.blocked_until = item.value;
				device.reason = item.reason;
				result.push(device);
			}
		});
		return result;
	}

	unBlock(id, idx, version) {
		this.deviceService.unBlockFWReport(id, version).subscribe(
			() => {
				// success message then reload
				this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
				this.reportData.devices.splice(idx, 1);
			}
		);
	}
}