<div>
	<h2>{{'nav.get_incorrect_events' | translate}}</h2>
	<div class="mt-3 row col-md-12">
		<div class="col-md-4">
			<label for="device-type">{{ 'devices.device_type' | translate }}</label>
			<ng-select
				[items]="deviceTypeOptions"
				bindLabel="name"
				bindValue="id"
				placeholder="{{ 'devices.device_type' | translate }}"
				name="device-type"
				[(ngModel)]="selectedDeviceType"
				[clearable]="false"
				id="device-type"
			></ng-select>
		</div>
		<div class="col-md-4">
			<label for="year">{{'g.select_year' | translate}}</label>
			<ng-select
				class="mr-auto w-100 option"
				[items]="years"
				bindLabel="label"
				bindValue="id"
				placeholder="{{'g.select_year' | translate}}"
				name="year"
				[(ngModel)]="selectedYear"
				[clearable]="false"
				id="year"
			></ng-select>
		</div>
		<div class="col-md-4">
			<label for="quarter">{{'g.select_quarter' | translate}}</label>
			<ng-select
				class="mr-auto w-100 option"
				[items]="quarters"
				bindLabel="value"
				bindValue="id"
				placeholder="{{'g.select_quarter' | translate}}"
				name="quarter"
				[(ngModel)]="selectedQuarter"
				[clearable]="false"
				id="quarter"
			></ng-select>
		</div>
	</div>
	<div class="col-md-3 my-4">
		<button class="btn btn-primary" (click)="getIncorrectEvent()">{{'nav.get_incorrect_events' | translate}}</button>
	</div>
	<div class="col-md-12">
		<app-grid
			*ngIf="data.length; else noData"
			[data]="data"
			[columns]="columnDefs"
			[sizeColumnsToFit]="true"
			[settings]="{
				hasExport: true,
				hasReset: false,
				pagination: true,
				paginationPageSize: pageSize
			}"
			#agGrid
			id="incorrect_events"
			[fileName]="fileName"
		></app-grid>
	</div>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>