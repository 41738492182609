import { Component, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from '../users/users.service';
import { Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

@Component({
	selector: 'app-admin',
	template: '<router-outlet></router-outlet>'
})
export class AdminComponent implements OnInit, OnDestroy {

	currentUser: any = {};
	constructor(
		private usersService: UsersService,
		private router: Router,
		private sideMenuService: SideMenuService
	) {}

	ngOnInit() {
		this.sideMenuService.hide();
		this.currentUser = this.usersService.getCurrentUser();
	}

	ngOnDestroy() {
	}
}
