<div class="date-range white-box main-black p-4 border-radius-bottom">
	<div class="border-bottom p-3">
		<h2>{{'admin.device_admin' | translate}}</h2>
	</div>
	<div class="px-5 py-4">
		<form (ngSubmit)="updateDevice()" validate-form="deviceAdmin">
			<div class="row mt-2 align-items-center">
				<div class="col-md-4">
					<strong>{{'devices.rt_sync' | translate}}</strong>
				</div>
				<div class="col-md-8">
					<div class="form-check form-check-inline slide-toggle-div">
						<mat-slide-toggle [(ngModel)]="rtSync" name="rt_sync"></mat-slide-toggle>
					</div>
				</div>
			</div>
			<div class="row my-4 align-items-center">
				<div class="col-md-4">
				</div>
				<div class="col-md-8">
					<div class="row align-items-center">
						<div class="col-12">
							<button type="submit" class="btn text-white main-blue-bg btn-lg col-md-5 mr-4" [disabled]="device.config_info.rt_sync == rtSync">{{'g.save' | translate}}</button>
							<button type="button" class="btn text-white main-blue-bg btn-lg col-md-5 px-5" (click)="flushCache()">{{'devices.flush_device_cache' | translate}}</button>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
	<div class="border-bottom p-3"></div>

	<div class="border-bottom p-3">
		<h2>{{'device_admin.read_metered_fields_time' | translate}}</h2>
	</div>
	<div class="px-5 py-4">
		<form (ngSubmit)="updateDeviceData(false)">

			<div class="mt-4 ml-5">
				<label for="rt_metered_time" >
					<strong>{{'device_admin.rt_metered_time' | translate}}</strong>
					<h6>{{'device_admin.rt_reading_time_until' | translate}}: <b>{{readTimesUntil.rt_metered_time}} ({{'g.utc' | translate}})</b></h6>
				</label>
				<div class="form-field mb-2 w-50">
					<input class="form-control" type="number" name="rt_metered_time" [min]="0" [max]="maxMeteredTimes.rt_metered_time" [(ngModel)]="meteredTimes.rt_metered_time"/>
				</div>
			</div>

			<div class="mt-4 ml-5">
				<label for="qv_metered_time" >
					<strong>{{'device_admin.qv_metered_time' | translate}}</strong>
					<h6>{{'device_admin.qv_reading_time_until' | translate}}: <b>{{readTimesUntil.qv_metered_time}} ({{'g.utc' | translate}})</b></h6>
				</label>
				<div class="form-field mb-2 w-50">
					<input class="form-control" type="number" name="qv_metered_time" [min]="0" [max]="maxMeteredTimes.qv_metered_time" [(ngModel)]="meteredTimes.qv_metered_time"/>
				</div>
			</div>
			<div class="mt-3 ml-5">
				<button [disabled]="meteredTimes.rt_metered_time === null && meteredTimes.qv_metered_time === null" type="submit" class="btn btn-primary">{{'g.submit' | translate}}</button>
			</div>
		</form>
	</div>
	<div class="py-4" *ngIf="usersService.hasAccessPermission(null, 'noc', 'write')">
		<div class="border-bottom p-3"></div>

		<div class="border-bottom p-3">
			<h2>{{'action_log.update_device' | translate}}</h2>
		</div>
		<div class="px-5 py-4">
			<form (ngSubmit)="updateDeviceData(true)">
				<div class="row mt-2 px-5 align-items-center">
					<div class="col-md-4">
						<strong>{{'device_admin.last_event_id' | translate}}</strong>
					</div>
					<div class="col-md-4">
						<input class="form-control" type="text" name="last_event_id" [(ngModel)]="dataUpdateOnDB.last_event_id"/>
					</div>
				</div>
				<div class="row mt-2 px-5 align-items-center">
					<div class="col-md-4">
						<strong>{{'device_admin.last_rt_id' | translate}}</strong>
					</div>
					<div class="col-md-4">
						<input class="form-control" type="text" name="last_rt_id"  [(ngModel)]="dataUpdateOnDB.last_rt_id"/>
					</div>
				</div>
				<div class="row mt-2 px-5 align-items-center">
					<div class="col-md-4">
						<strong>{{'device_admin.last_debug_id' | translate}}</strong>
					</div>
					<div class="col-md-4">
						<input class="form-control" type="text" name="last_debug_id"  [(ngModel)]="dataUpdateOnDB.last_debug_id"/>
					</div>
				</div>
				<div class="mt-3 px-5">
					<button type="submit" class="btn btn-primary">{{'g.submit' | translate}}</button>
				</div>
			</form>
		</div>
	</div>
</div>