<app-modal #newStudyForm
	[size]="'lg'"
	[hasCloseButton]="false"
	[hasConfirmButton]="false"
	[title]="'Create New Study'"
>
	<alert *ngIf="formError != null" [type]="'danger'">
		<div [innerHTML]="formError"></div>
	</alert>
	<p>
		<b>{{'note.note' | translate}}</b>{{'studies.new_study_form_note' | translate}}
	</p>
	<form>
		<div class="form-group row">
			<label for="studyName" class="col-sm-4 col-form-label">{{'studies.name' | translate}}</label>
			<div class="col-sm-8">
				<input type="text" class="form-control" [ngClass]="{'is-invalid': newStudyFormErrorsFields.studyName.hasErr}" name="studyName" id="studyName" [(ngModel)]="newStudyFormData.studyName" required>
				<div *ngIf="newStudyFormErrorsFields.studyName.hasErr" class="invalid-feedback">{{newStudyFormErrorsFields.studyName.msg}}</div>
			</div>
		</div>
		<div class="form-group row">
			<label for="customerId" class="col-sm-4 col-form-label">{{'studies.customer_name' | translate}}</label>
			<div class="col-sm-8">
				<ng-select
					[items]="customers"
					bindLabel="customer_name"
					bindValue="id"
					placeholder="{{'customer.select_customer' | translate}}"
					name="customerId"
					[(ngModel)]="newStudyFormData.customerId"
					[clearable]="false"
					(ngModelChange)="getCustomerSites($event)"
					required
					[ngClass]="{'border border-danger is-invalid': newStudyFormErrorsFields.customerId.hasErr}"
					[disabled]="['customer', 'site'].includes(parent)"
				></ng-select>
				<div *ngIf="newStudyFormErrorsFields.customerId.hasErr" class="invalid-feedback">{{newStudyFormErrorsFields.customerId.msg}}</div>
			</div>
		</div>
		<div class="form-group row">
			<label for="siteId" class="col-sm-4 col-form-label">{{'studies.site_name' | translate}}</label>
			<div class="col-sm-8">
				<ng-select
					[items]="sites"
					bindLabel="name"
					bindValue="id"
					placeholder="{{'site.select_site' | translate}}"
					name="siteId"
					[(ngModel)]="newStudyFormData.siteId"
					[clearable]="false"
					required
					[ngClass]="{'border border-danger is-invalid': newStudyFormErrorsFields.siteId.hasErr}"
					[disabled]="['site'].includes(parent)"
				></ng-select>
				<div *ngIf="newStudyFormErrorsFields.siteId.hasErr" class="invalid-feedback">{{newStudyFormErrorsFields.siteId.msg}}</div>
			</div>
		</div>
		<div class="form-group row">
			<label for="serialNumber" class="col-sm-4 col-form-label">{{'g.serial_number' | translate}}</label>
			<div class="col-sm-8">
				<ng-select
					[items]="devices"
					bindLabel="label"
					bindValue="mac_address"
					name="serialNumber"
					[(ngModel)]="newStudyFormData.serialNumber"
					[clearable]="false"
					required
					[ngClass]="{'border border-danger is-invalid': newStudyFormErrorsFields.serialNumber.hasErr}"
					[disabled]="['study', 'device'].includes(parent)"
				></ng-select>
				<div *ngIf="newStudyFormErrorsFields.serialNumber.hasErr" class="invalid-feedback">{{newStudyFormErrorsFields.serialNumber.msg}}</div>
			</div>
		</div>
		<div class="form-group row">
			<div class="col-sm-4"></div>
			<div class="col-sm-8">
				<button class="btn btn-primary mr-2" (click)="newStudyForm.onConfirm()">{{'g.submit' | translate}}</button>
				<button class="btn btn-light" (click)="newStudyForm.onHide()">{{'g.cancel' | translate}}</button>
			</div>
		</div>
	</form>
</app-modal>
