<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<h3>{{'suggested_installation_date_report.title' | translate}}</h3>
	<hr/>
	
	<b class="text-warning">* {{'suggested_installation_date_report.note' | translate}}</b>

	<div class="row mb-3 mt-4">
		<div class="col">
			<h6>{{ 'g.customer_name' | translate }}</h6>
			<ng-select
				[items]="customersList"
				bindLabel="customer_name"
				bindValue="id"
				placeholder="{{'customer.select_customer' | translate}}"
				name="customer_id"
				[clearable]="false"
				[(ngModel)]="selectedCustomerId"
				(ngModelChange)="getCustomerSites($event)"
				required
			></ng-select>
		</div>
		<div class="col">
			<h6>{{ 'g.site_name' | translate }}</h6>
			<ng-select
				[items]="sitesList"
				bindLabel="name"
				bindValue="id"
				placeholder="{{'site.select_site' | translate}}"
				name="site_id"
				[clearable]="false"
				[(ngModel)]="selectedSiteId"
				required
			></ng-select>
		</div>
		<div class="col">
			<h6>{{ 'suggested_installation_date_report.installation_date_provided_by_the_installer' | translate }}</h6>
			<input type="date" name="date" [(ngModel)]="selectedDate" placeholder="{{'g.date' | translate}}" class="form-control" required />
		</div>
		<div class="col">
			<h6>{{ 'suggested_installation_date_report.gap_period' | translate }} ({{ 'time.days' | translate }})</h6>
			<input type="number" name="gap_period" min="0" [(ngModel)]="selectedGapPeriod" placeholder="{{'suggested_installation_date_report.gap_period' | translate}}" class="form-control" required />
			<small class="text-danger">{{ 'suggested_installation_date_report.gap_period_restrictions' | translate }}</small>
		</div>
	</div>
	<div class="row">
		<div class="col-2">
			<button class="btn btn-primary w-75" type="button" (click)="generateReport()" [disabled]="disableEnableGenerateButton()">{{'registration_purposes.generate_report' | translate}}</button>
		</div>
	</div>

	<div *ngIf="reportData.length" class="d-flex justify-content-end mb-3">
		<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedDevicesLength()" (click)="setInstallationDate()">
			{{'suggested_installation_date_report.use_suggested_installation_date' | translate}}
		</button>

		<fa-icon class="setting_icon pointer" icon="upload" size="lg" (click)="export()" title="Export"></fa-icon>
	</div>

	<div class="table-container mt-3">
		<table class="table table-striped"
			*ngIf="reportData.length; else noData"
			matSort (matSortChange)="sortData($event)"
			matSortActive="mac_address, serial_number, suggested_installation_date, actual_installation_date"
			matSortDirection="asc"
			matSortDisableClear
		>
			<thead>
				<tr>
					<th><input type="checkbox" [checked]="isAllDevicesSelected()" [disabled]="disableCheckAll()" (click)="selectDevice('', $event)"></th>
					<th mat-sort-header="serial_number">{{'devices.serial_number' | translate}}</th>
					<th mat-sort-header="mac_address">{{'g.mac_address' | translate}}</th>
					<th mat-sort-header="suggested_installation_date">{{'suggested_installation_date_report.suggested_installation_date' | translate}}</th>
					<th mat-sort-header="actual_installation_date">{{'suggested_installation_date_report.actual_installation_date' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let row of reportData">
					<td>
						<div class="form-check">
							<input class="form-check-input" type="checkbox" [disabled]="!row.suggested_installation_date" [checked]="isDeviceSelected(row.mac_address)" (click)="selectDevice(row)">
						</div>
					</td>
					<td><a [routerLink]="['/', selectedCustomerId, selectedSiteId, row.mac_address,'performance']">{{ row.serial_number }}</a></td>
					<td>{{ row.mac_address }}</td>
					<td>{{ formateInstallationDate(row.suggested_installation_date) }}</td>
					<td>{{ formateInstallationDate(row.actual_installation_date) }}</td>
				</tr>
			</tbody>
		</table>
	</div>
	<ng-template #noData>
		<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
	</ng-template>	
</div>