import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-audit',
	templateUrl: './audit.component.html',
	styleUrls: ['./audit.component.css']
})
export class AuditComponent implements OnInit {

	activePage = 'permission';

	constructor(
		private route: ActivatedRoute,
		public usersService: UsersService,
		private router: Router,
		private sideMenuService: SideMenuService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		let currentUser = this.usersService.getCurrentUser();
		if(!currentUser.is_admin)
			return this.router.navigate(['/unauthorized']);

		this.activePage = (['user', 'permission'].includes(this.route.snapshot.firstChild.data.pageName) ? this.route.snapshot.firstChild.data.pageName : 'user');
	}
}