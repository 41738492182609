import { Component, OnInit, Input } from '@angular/core';

@Component({
	selector: 'app-simple-widget',
	templateUrl: './simple-widget.component.html',
	styleUrls: ['./simple-widget.component.css']
})
export class SimpleWidgetComponent implements OnInit {

 	@Input() title;
	@Input() widgetId: string = '';
	@Input() tooltipKey: string;
	@Input() isCollapsed: boolean = false;

	constructor() { }

	ngOnInit() { }

	toggleCollapse() {
		this.isCollapsed = !this.isCollapsed;
	}
}