import { CommonService } from './../../../shared/services/common.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import { UsersService } from 'src/app/users/users.service';
import * as _ from 'underscore';

@Component({
	selector: 'app-download-status',
	templateUrl: './download-status.component.html',
	styleUrls: ['./download-status.component.css']
})
export class DownloadStatusComponent implements OnInit, OnDestroy {

	customerId: number;
	siteId: number;
	subSite: Subscription = new Subscription();
	subPermission: Subscription = new Subscription();
	enterprisePermissions: any = {};
	devices: any[] = [];
	selectedDevices: any[] = [];
	statusFilter: string = 'all';
	statusList: any;
	intervalID: any;
	isAdmin = false;
	siteName: "";

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private sideMenuService: SideMenuService,
		public usersService: UsersService,
		private sitesService: SitesService,
		public common: CommonService
	) { }

	ngOnInit() {
		this.isAdmin = this.usersService.getCurrentUser().is_admin;

		this.statusList = {
			all: this.translateService.instant('g.all'),
			yes: this.translateService.instant('g.completed'),
			no: this.translateService.instant('g.not_completed'),
		};

		this.subSite = this.sideMenuService.currentSite.subscribe((data: any) => {
			if (!data || !data['id'])
				return;
			this.customerId = Number(this.route.parent.snapshot.params['customerId']);
			this.siteId = Number(this.route.parent.snapshot.params['siteId']);
			if (data.id != this.siteId || data['customer_id'] != this.customerId)
				return;
			this.siteName = data.name;
			this.getSiteDownloadStatus();
			this.subPermission = this.usersService.getPermissions(this.siteId).subscribe((permissions: any) => {
				this.enterprisePermissions = permissions;
			});
		});
		this.intervalID = setInterval(() => {
			this.getSiteDownloadStatus();
		}, 15000); //every 15 seconds
	}

	getSiteDownloadStatus() {
		if(this.siteId) {
			this.sitesService.getSiteDownloadStatus(this.siteId).subscribe((res: any) => {
				this.devices = res;
				this.applyFilter();
			});
		}
	}

	applyFilter() {
		if(this.statusFilter == 'all')
			return this.selectedDevices = this.devices.slice();
		if(this.statusFilter == 'yes') {
			return this.selectedDevices = _.filter(this.devices, function(device) {
				return device.downloaded > 0 && device.connected && device.fw >= device.fw_to_push && device.total_events - device.downloaded <= 2;
			});
		}
		this.selectedDevices = _.filter(this.devices, function(device) {
			return !(device.downloaded > 0 && device.connected && device.fw >= device.fw_to_push && device.total_events - device.downloaded <= 2);
		});
	}

	ngOnDestroy() {
		this.subSite.unsubscribe();
		this.subPermission.unsubscribe();
		clearInterval(this.intervalID);
	}

	goBack() {
		this.router.navigate(['/', this.customerId, this.siteId]);
	}
}
