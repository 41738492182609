<table class="table table-stripped boxs" *ngIf="data">
	<tbody>
		<tr></tr>
		<tr>
			<td>{{'g.id' | translate}}</td>
			<td>{{data.id}}</td>
		</tr>
		<tr>
			<td>{{'users.user_id' | translate}} / {{'login_register.user_name' | translate}}</td>
			<td>{{data.user_id}} / {{data.username}}</td>
		</tr>
		<tr>
			<td>{{'contact_us.subject' | translate}}</td>
			<td>{{data.subject}}</td>
		</tr>
		<tr>
			<td>{{'contact_us.body' | translate}}</td>
			<td>{{data.body}}</td>
		</tr>
		<tr>
			<td>{{'error_log.user_ip' | translate}}</td>
			<td>{{data.ip_address}}</td>
		</tr>
		<tr>
			<td>{{'error_log.browser' | translate}}</td>
			<td>{{data.browser}}</td>
		</tr>
		<tr>
			<td>{{'g.time' | translate}}</td>
			<td>{{data.insertion_time | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
		</tr>
	</tbody>
</table>