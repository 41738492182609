import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { SiteDashboardService } from '../../../site-dashboard.service';
import * as moment from 'moment';
import { DeviceService } from '../../device.service';
import { pick } from 'lodash-es';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';

@Component({
  selector: 'app-cellular-settings',
  templateUrl: './cellular-settings.component.html',
})
export class CellularSettingsComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() simInfo: any = {};

	siteId!: number;
	customerId!: number;
	macAddress!: string;
	permissionsSub!: Subscription;
	enterprisePermissions: any = {};
	cellularRssiTooltip = '';
	cellularOemRssiTooltip = '';
	refreshRetryCount = 2; // 3 times
	refreshRetryInterval = null;

	cellularData: any = {};
	cellularOemData: any = {};

	cellularFields = [
		'cellular_rssi',
		'cellular_connected',
		'cellular_longitude',
		'cellular_latitude',
		'cellular_imsi',
		'cellular_imei',
		'cellular_iccid',
		'cellular_cereg_val',
		'cellular_failure_type',
		'cellular_access_technology_selected',
		'operator_in_numeric_format',
		'cellular_band',
		'cellular_channel',
		'cellular_ip',
		'cellular_gateway',
		'cellular_netmask',
		'cell_module_id',
		'cell_fw_version',
		'metered_wifi_last_connect_time',
		'metered_cellular_last_connect_time',
		'non_metered_wifi_last_connect_time',
		'cellular_detailed_failure_flags',
		'client_socket_type',
		'metered'
	];

	lastConnectTimeFields = [
		'metered_wifi_last_connect_time',
		'metered_cellular_last_connect_time',
		'non_metered_wifi_last_connect_time',
	];

	booleanFields = ['cellular_connected', 'metered'];
	ipFields = [];

	FAILURE_FLAG_MASKS = {
		sync: 0x0001,
		baud_rate: 0x0002,
		baud_rate_115200: 0x0004,
		module_name: 0x0008,
		manufacturer_id: 0x0010,
		firmware_id: 0x0020,
		imsi: 0x0040,
		imei: 0x0080,
		iccid: 0x0100,
		scan_bands: 0x0200,
		iotopmode: 0x0400,
		sim_pin: 0x0800,
		enable_gps: 0x1000,
		wwan_priority: 0x2000
	}

	noSimInfo: any = null;
	simActivatedStatus: string = 'Enabled';

	oem_cellular_info: any = null;

	constructor(
		private siteDashboardService: SiteDashboardService,
		public usersService: UsersService,
		private router: Router,
		private translate: TranslateService,
		private commonService: CommonService,
		private deviceService: DeviceService,
		private route: ActivatedRoute,
		private notificationMessageService: NotificationMessageService,
	) { }

	ngOnInit(): void {
		this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data => {
			const enterprisePermissions = data;

			if(!this.usersService.hasAccessPermission(enterprisePermissions, 'debug_data'))
				return this.router.navigate(['/unauthorized']);
		});

		this.route.queryParams.subscribe(params => {
			if (params.liveInfo)
				this.getCellularInfo();
		});

		this.prepareFields({});
		this.prepareSimFields(this.simInfo);
	}

	ngOnChanges() {
		this.prepareFields({});
		this.getSimInfo(false);
	}

	prepareFields(deviceInfo?: any) {
		deviceInfo = {...this.device.config_info, ...deviceInfo};

		this.checkLastConnectTimeFields(deviceInfo);

		const oem_cellular_info = this.oem_cellular_info || this.device.config_info.oem_cellular_info || {};

		for (let field of this.cellularFields) {
			if (field in deviceInfo) {
				let value = deviceInfo[field];
				let oemValue = oem_cellular_info[field];

				if (this.booleanFields.includes(field)) {
					value = this.translate.instant('g.' + (value ? 'on' : 'off'));
					oemValue = this.translate.instant('g.' + (oemValue ? 'on' : 'off'));
				}

				if (this.lastConnectTimeFields.includes(field)) {
					value = this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id), deviceInfo[field]);
					value = moment.unix(value).utc().format('MM/DD/YYYY hh:mm:ss A');
				}

				if (field == 'cellular_failure_type') {
					value = this.translate.instant('failure_type.'+ value);
					oemValue = oemValue? this.translate.instant('failure_type.'+ oemValue) : oemValue;
				}

				if (field === 'cellular_detailed_failure_flags') {
					value += (' ' + this.getParsedFlagList(+value).join(', '));
					oemValue += (' ' + this.getParsedFlagList(+oemValue).join(', '));
				}

				if (field == 'client_socket_type') {
					value = this.translate.instant('client_socket_type.' + value);
					oemValue = (oemValue || oemValue == 0) ? this.translate.instant('client_socket_type.' + oemValue) : oemValue;
				}

				if (field === 'cellular_rssi') {
					this.cellularRssiTooltip = value;
					this.cellularOemRssiTooltip = oemValue;
					const oemCellularRssiVal = Math.abs(oemValue);
					value = this.commonService.mapCellularRssi(Math.abs(value));
					oemValue = oemCellularRssiVal >= 0 ? this.commonService.mapCellularRssi(oemCellularRssiVal) : oemValue;
				}

				this.cellularData[field] = value;
				this.cellularOemData[field] = oemValue || '-----';
			}
		}
	}

	sendCellularInfoCommand() {
		if(!this.refreshRetryInterval) {
			this.deviceService.sendCommand(this.device.config_info.mac_address, 'queryCellularInfo', { wait: 0 }).subscribe((deviceInfo: any) => {
				this.refreshRetryCount = 2;
				this.refreshRetryInterval = setInterval(() => {
					if(this.refreshRetryCount == 0) {
						clearInterval(this.refreshRetryInterval);
						this.refreshRetryInterval = null;
					}
					this.getCellularInfo();
					this.refreshRetryCount--;
				}, 10000);
			});
		}
	}

	getCellularInfo() {
		this.deviceService.getCellularInfo(this.device.config_info.mac_address).subscribe((res: any) => {
			if(res) {
				clearInterval(this.refreshRetryInterval);
				this.refreshRetryInterval = null;
				this.oem_cellular_info = res.oem_cellular_info;
				res = pick(res, this.cellularFields);
				this.cellularData = res;
				this.prepareFields(res || {});
				this.getSimInfo(true);
			}
		});
	}

	checkLastConnectTimeFields(deviceInfo: any) {
		if (deviceInfo.non_metered_cellular_last_connect_time)
			delete deviceInfo.non_metered_cellular_last_connect_time
	}

	getParsedFlagList(intvalue: number) {
		const parsedFlagList: string[] = [];

		Object.entries(this.FAILURE_FLAG_MASKS).forEach(([key, bitmask]) => {
			if (intvalue & bitmask)
				parsedFlagList.push(this.translate.instant('cellular_detailed_flags.' + key));
		});

		return parsedFlagList;
	}

	getSimInfo(isLive: boolean = false) {
		this.deviceService.getSimInfo(this.cellularData.cellular_iccid || this.device.config_info.cellular_iccid, isLive).subscribe((data:any) => {
			if (data.api_status == 2) {
				if (isLive)
					this.noSimInfo = this.translate.instant('sim.sim_not_found_in_service');
				else
					this.noSimInfo = this.translate.instant('sim.sim_not_found');
			} else {
				this.simInfo = data;
				this.prepareSimFields(data);
			}
		});
	}

	prepareSimFields(sim: any) {
		if (!sim || !sim.iccid)
			return this.noSimInfo = this.translate.instant('sim.device_does_not_have_sim');

		this.noSimInfo = false;
		delete sim.data;

		sim.last_read = moment.utc(sim.last_read).format('MM/DD/YYYY HH:mm:ss A');
		sim.last_connect_time = moment.utc(sim.last_connect_time).format('MM/DD/YYYY HH:mm:ss A');
		sim.last_block_time = sim.last_block_time ? moment.utc(sim.last_block_time).format('MM/DD/YYYY HH:mm:ss A') : '--/--/--';
		sim.imei_lock = this.translate.instant('g.'+ (sim.imei_lock ? "locked" : "unlocked"));
		sim.status = this.translate.instant('sim.' + (sim.status == this.simActivatedStatus ? 'enable' : 'disable'));
		delete sim.connectivity;
		delete sim.has_sent_remaining_email;
		this.simInfo = sim;
	}

	ngOnDestroy(): void {
		if (this.permissionsSub)
			this.permissionsSub.unsubscribe();
	}

}
