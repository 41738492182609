import {Component} from "@angular/core";

import {IFloatingFilter, IFloatingFilterParams, TextFilterModel, TextFilter} from "ag-grid-community";
import {AgFrameworkComponent} from "ag-grid-angular";

export interface DropDownFilterParams extends IFloatingFilterParams {
	value;
	dropDownOptions: any[];
	type: string;
	filterOptionType: string;
}

@Component({
	template: `
		<select [(ngModel)]="currentValue" (ngModelChange)="valueChanged()" data-show-value="true" data-popup-enabled="true" style="min-width: 100%;">
			<option *ngFor="let item of dropDownOptions" [value]="item.value">{{item.label}}</option>
		</select>`
})
export class DropDownFilter implements IFloatingFilter, AgFrameworkComponent<DropDownFilterParams> {

	private params: DropDownFilterParams;

	public currentValue;
	public dropDownOptions: any[];
	public filterOptionType;

	agInit(params: DropDownFilterParams): void {
		this.params = params;
		this.dropDownOptions = this.params.dropDownOptions;
		this.filterOptionType = this.params.filterOptionType || 'equals'; // greaterThan
		this.currentValue = null;
	}

	valueChanged() {
		let valueToUse = this.getFilterValue();
		this.params.parentFilterInstance( (instance) => {
			(<TextFilter>instance).onFloatingFilterChanged(this.filterOptionType, valueToUse);
		});
	}

	onParentModelChanged(parentModel: TextFilterModel): void {
		if (!parentModel) {
			this.currentValue = null;
			return;
		}
		
		// note that the filter could be anything here, but our purposes we're assuming a greater than filter only,
		// so just read off the value and use that
		this.currentValue = parentModel.filter
		if(this.params.type == 'nullFilter') {
			this.currentValue = parentModel.filter || null;
		} else {
			this.currentValue = parentModel.filter;
		}
	}
	
	getFilterValue() {
		if (this.currentValue === 0 || this.currentValue === null || this.currentValue == 'null') {
			return null;
		}

		return this.currentValue;
	}
}