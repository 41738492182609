import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from '../device.service';

@Component({
	selector: 'app-device-select-criteria',
	templateUrl: './device-select-criteria.component.html',
	styleUrls: ['./device-select-criteria.component.css']
})
export class DeviceSelectCriteriaComponent implements OnInit {

	@Input() device: any = {};
	@Input() changesStack: any = {};
	@Input() simInfo: any = {};
	@Input() enterprisePermissions: any = {};
	@Input() siteID = 0;
	@Input() customerID = 0;
	@Input() activePage: string;
	@Input() currentSite: any;
	@Input() deviceID: string ='';
	@Input() siteDevices: any = [];
	@Input() deviceLastConnectTime = 0;
	@Input() isStudyDevice: boolean = false;
	@Input() studyId = 0;

	multiDevices: boolean = false;
	currentUser: any = {};
	@Output() onDeviceSelect = new EventEmitter<Object>();
	@Output() changePage = new EventEmitter<{pageName: string}>();
	@Output() onGetSimInfo = new EventEmitter<Object>();
	
	constructor(
		private usersService: UsersService,
		private route: ActivatedRoute,
		private router: Router,
		private deviceService: DeviceService
	) { }

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();
		this.route.params.subscribe(params => {
			if(params.deviceId && params.deviceId != '0') {
				let deviceID = params.deviceId || '';
				this.deviceID = deviceID.toLowerCase();
				this.getCachedDevices();
			} else if (this.siteDevices.length > 0 && params.deviceId == '0') {
				this.deviceID =  this.siteDevices[0].mac_address;
				this.deviceChanged();
			}
		});
	}

	getCachedDevices() {
		if(this.deviceID == "0")
			return;

		let multiDevicesIds = this.deviceID.split(',');
		this.multiDevices = (multiDevicesIds.length > 1);
		if(this.multiDevices) {
			this.deviceID = '';
			this.deviceService.getMultiDevicesInfo(multiDevicesIds).subscribe((res: any) => {
				this.deviceLastConnectTime = null;
				this.onDeviceSelect.emit({device: res, multiDevices: this.multiDevices});
			});
		} else {
			this.deviceService.getDeviceInfo(this.deviceID, { get_changes_stack: true, studyId: this.studyId }).subscribe(
				(device: any) => {
					this.deviceSelected(device);
				}
			);
		}
	}

	deviceSelected(device) {
		this.device = device;
		let zoneId = device.config_info.zone_id;
		if(!zoneId)
			zoneId = this.currentSite['zone_id'];

		this.getSimInfo(zoneId);
	}

	get getDeviceLastConnectTime() {
		return moment(this.deviceLastConnectTime * 1000).format('MM/DD/YYYY hh:mm:ss A');
	}

	getSimInfo(zoneId) {
		if (!this.device.config_info.enable_cellular || !(this.usersService.hasAccessPermission(null, 'debug_data') || this.usersService.hasAccessPermission(null, 'noc')))
			return this.onDeviceSelect.emit({device: this.device, zoneId});
	
		this.deviceService.getSimInfo(this.device.config_info.cellular_iccid).subscribe((data:any) => {
			if (data.api_status == 2)
				return this.onDeviceSelect.emit({device: this.device, zoneId});

			this.simInfo = data;
			this.onGetSimInfo.emit({simInfo: this.simInfo});
			this.onDeviceSelect.emit({device: this.device, zoneId});
		});
	}

	deviceChanged() {
		let url: any[] = [this.deviceID, this.activePage];

		this.router.navigate(url, {'relativeTo': this.route.parent});
	}

	refreshDevice() {
		this.getCachedDevices();
	}

	goToPage(page) {
		this.changePage.emit(page);
	}

	showUserTimeZoneReference() {
        const userTimeZone = moment().utcOffset();
        return moment().utcOffset(userTimeZone).format('UTCZ');
	}
}