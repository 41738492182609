<div class="row">
	<div class="col-lg-6 col-md-6 col-sm-12">
		<h2>{{'nav.shipping_out' | translate}}</h2>
		<form #searchDeviceForm="ngForm" (ngSubmit)="getDevices()">
			<div class="mt-4">
				<div class="my-2">
					<ng-select
						name="po_number"
						[(ngModel)]="po_number"
						[items]="poNumbersDropdown"
						bindValue="value"
						bindLabel="label"
						placeholder="{{'ready_to_ship.po_number' | translate}}"
						[clearable]="false"
					></ng-select>
				</div>
				<small class="text-danger">{{'shipment.csv_file_rules_msg' | translate}}</small>
				<div class="custom-file my-2">
					<input type="file" class="custom-file-input" id="importFile" (change)="prepareMultiDevicesForShipment($event)" [disabled]="!showUploadFileEle">
					<label class="custom-file-label" #labelImport for="importFile">{{'shipment.import_devices_csv' | translate}}</label>
				</div>
				{{ 'g.or_capital' | translate }}
				<div class="my-2">
					<input name="searchString" [(ngModel)]="searchString" trim-text placeholder="{{'g.serial_number' | translate}}/{{'g.mac_address' | translate}}" class="form-control" required/>
				</div>
				<div class="mt-3 input-group">
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio1" value="sn">
						<label class="form-check-label" for="inlineRadio1">{{'g.serial_number' | translate}}</label>
					</div>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio2" value="mac">
						<label class="form-check-label" for="inlineRadio2">{{'g.mac_address' | translate}}</label>
					</div>
				</div>
				<div class="mt-3">
					<button type="submit" class="btn btn-primary pointer" [disabled]="searchDeviceForm.invalid">{{'g.search' | translate}}</button>
				</div>
			</div>
		</form>
		<div class="col-md-6 my-5" *ngIf="searchResult?.length == 0 && !bulkSearchNotFoundDevices?.length">
			<b>{{'g.no_results_found' | translate}}</b>
		</div>
		<div class="col-md-12 my-5" *ngIf="!searchResult?.length && bulkSearchNotFoundDevices?.length">
			<b>{{'shipment.field_import_csv_devices_not_found' | translate}}</b>
			<ul>
				<li *ngFor="let device of bulkSearchNotFoundDevices">{{ device }}</li>
			</ul>
		</div>
		<div class="col-md-12 my-5" *ngIf="searchResult?.length > 0">
			<h3>{{'g.results' | translate}}:</h3>
			<div class="row py-2">
				<div class="col-md-3"><strong>{{'g.mac_address' | translate}}</strong></div>
				<div class="col-md-3"><strong>{{'g.serial_number' | translate}}</strong></div>
				<div class="col-md-6"><strong>{{'ready_to_ship.move_to_bulk' | translate}}</strong></div>
			</div>
			<div *ngFor="let device of searchResult" class="row py-2">
				<div class="col-md-3">
					<a
						[routerLink]="['/', sites[device.site_id]?.customer_id, device.site_id, device.mac_address, 'performance']"
						class="blue-hover pointer ml-1"
						target="_blank"
					>
						{{device.mac_address}}
					</a>
				</div>
				<div class="col-md-3">{{device.serial_number}}</div>
				<div class="col-md-6">
					<div *ngIf="device.is_sct_inventory && po_number">
						<fa-icon icon="arrow-right" size="lg" class="pointer" (click)="prepareForShipment(device, sites[device.site_id]?.customer_id)"></fa-icon>
					</div>
					<div *ngIf="!device.is_sct_inventory">
						<span class="text-danger">{{'common.device_not_sct_inventory' | translate}}</span>
					</div>
					<div *ngIf="!po_number">
						<span class="text-danger">{{'orders.select_po_number' | translate}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div class="col-lg-6 col-md-6 col-sm-12">
		<h2>{{'ready_to_ship.to_be_updated' | translate}}</h2>
		<br/>
		<div class="d-flex justify-content-between">
			<div>
				<input
					class="mr-2"
					type="checkbox"
					name="isPowerStudiesDevices"
					[(ngModel)]="isPowerStudiesDevices"
					(ngModelChange)="updateSitesList($event)"
				>
				{{ 'studies.power_studies_devices' | translate }}
			</div>
			<div><button class="btn btn-primary btn-sm shipment-btn" [routerLink]="['/management/shipment-history']">{{'shipment.shipment_history' | translate}}</button></div>
		</div>
		<div *ngIf="isPowerStudiesDevices">
			<small class="text-danger">{{'studies.shipping_out_studies_devices_msg' | translate}}</small>
		</div>
		<div class="mt-3 input-group">
			<div class="form-check form-check-inline" *ngFor="let group of enterpriseGroups">
				<input class="form-check-input" type="radio" name="group" [(ngModel)]="selectedGroup" id="ent_{{group}}" value="{{group}}" (change)="onGroupChanged()">
				<label class="form-check-label" for="ent_{{group}}">{{enterpriseService.getGroupTypeText(group)}}</label>
			</div>
			<div class="ml-4" [tooltip]="smartRebatesSelectedErr">
				<input class="form-check-input" type="radio" name="smart_rebates" id="smart_rebates" value="smart_rebates" [disabled]="disableSmartRebateOption" [(ngModel)]="selectedGroup" (change)="handleSmartRebatesGroup()">
				<label class="form-check-label" for="smart_rebates">{{'ready_to_ship.smart_rebates_option' |translate}}</label>
			</div>
		</div>
		<div class="col-md-6 my-5" *ngIf="selectedEnterpriseList?.length == 0">
			<b>{{'g.no_results_found' | translate}}</b>
		</div>

		<form (ngSubmit)="shipOutDevices()" validate-form="editSite" [innerModelGroups]="['site']">
			<div class="col-md-12 my-5" *ngIf="selectedEnterpriseList?.length > 0">
				<h3>{{'ready_to_ship.ship_to' | translate}}:</h3>
				<div class="row pt-2">
					<div class="col-md-6 pb-2">
						<ng-select
							name="enterprise_id"
							[(ngModel)]="shipOutToEnterprise"
							[items]="selectedEnterpriseList"
							bindValue="id"
							bindLabel="name"
							placeholder="{{'ent.select_enterprise' | translate}}"
							[clearable]="false"
							[disabled]= "smartRebatesGroup"
							(change)="onEnterpriseChanged()"
						></ng-select>
					</div>
					<div class="col-md-6 pb-2" *ngIf="selectedCustomerList?.length > 0">
						<ng-select
							name="customer_id"
							[(ngModel)]="shipOutToCustomer"
							[items]="selectedCustomerList"
							bindValue="id"
							bindLabel="name"
							placeholder="{{'customer.select_customer' | translate}}"
							[disabled]= "smartRebatesGroup"
							(change)="onCustomerChanged()"
						></ng-select>
					</div>
					<div class="col-md-6 pb-2" *ngIf="selectedSiteList?.length > 0">
						<ng-select
							name="site_id"
							[(ngModel)]="shipOutToSite"
							[items]="selectedSiteList"
							bindValue="id"
							bindLabel="name"
							[disabled]= "smartRebatesGroup || isPowerStudiesDevices"
							placeholder="{{'site.select_site' | translate}}"
						></ng-select>
					</div>
				</div>

				<!-- New Site -->
				<div class="my-2 align-items-center">
					<div class="col-md-2"></div>
					<div class="col-md-10">
						<fieldset *ngIf="shipOutToSite === 0">
							<legend><b>{{'g.site' | translate}}</b></legend>
							<app-site-edit [site]="site" [miniForm]="true" [customerEnterprises]="customerEnterprises" [enterpriseList]="selectedEnterpriseList"></app-site-edit>
							<app-mapbox
								class="border-radius main-blue-border"
								[latitude]="site.latitude||sitesService.defaultMapLocation.latitude"
								[longitude]="site.longitude||sitesService.defaultMapLocation.longitude"
								[zoom]="[3]"
								[zoomIn]="true"
								[showPopup]="false"
								[dots]="[{
									latitude: site.latitude||sitesService.defaultMapLocation.latitude,
									longitude: site.longitude||sitesService.defaultMapLocation.longitude
								}]"
								[clickEnabled]="true"
								(onAddressChanged)="updateSiteCoords($event)"
							></app-mapbox>
						</fieldset>
					</div>
				</div>
				<!-- New Site -->
				<div class="row">
					<div class="col-md-6 pb-2">
						<div class="row">
							<div class="col-md-12 d-flex">
								<div class="mr-2">
									<div class="w-100">
										<input type="file" [accept]="allowedUploadFilesExt.join(',')" id="actual-btn" name="files" multiple onclick="this.value=null;" (change)="selectFiles($event)" hidden>
										<label class="mb-0 btn btn-info d-flex justify-content-center" for="actual-btn">
											{{'ready_to_ship.attach_files' | translate}}
										</label>
									</div>
								</div>
								<button type="submit" class="btn btn-info" [disabled]="!shipOutToEnterprise || !shipOutToCustomer || (!shipOutToSite && shipOutToSite != 0) || selectedDevices.length == 0 || !po_number">
									{{(shipOutToSite === 0?'ready_to_ship.action_new_site':'ready_to_ship.action') | translate}}
								</button>
							</div>
						</div>
						<div class="row">
							<a *ngIf="selectedFiles.length" class="link-primary pointer" (click)="selectedFilesDialog.show();">
								<span class="d-flex justify-content-center">
									{{ "ready_to_ship.view_attached_files" | translate}}
								</span>
							</a>
						</div>
					</div>
				</div>
			</div>
		</form>
		<div class="col-md-12 my-5" *ngIf="selectedDevices.length > 0">
			<div class="row py-2">
				<div class="col-md-1"><strong>{{'g.number' | translate}}</strong></div>
				<div class="col-md-3"><strong>{{'g.mac_address' | translate}}</strong></div>
				<div class="col-md-3"><strong>{{'g.serial_number' | translate}}</strong></div>
				<div class="col-md-3"><strong>{{'g.remove' | translate}}</strong></div>
			</div>
			<div *ngFor="let device of selectedDevices; let i = index" class="row py-2">
				<div class="col-md-1">{{i +1}}</div>
				<div class="col-md-3"><a [routerLink]="['/', device._customer_id, device.site_id, device.mac_address, 'performance']" class="blue-hover pointer ml-1" target="_blank">{{device.mac_address}}</a></div>
				<div class="col-md-3">{{device.serial_number}}</div>
				<div class="col-md-3">
					<fa-icon icon="window-close" size="2x" class="pointer text-danger" (click)="removeFromShipment(device.mac_address)"></fa-icon>
				</div>
			</div>
		</div>
	</div>
</div>
<app-modal #selectedFilesDialog title="{{'ready_to_ship.selected_files' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false" [size]="'lg'">
	<div dialog-content>
		<div *ngIf="!selectedFiles else listOfFiles">
			{{'ready_to_ship.no_selected_files' | translate}}
		</div>
		<ng-template #listOfFiles>
			<table class="table table-striped">
				<thead>
					<tr>
						<th>{{'ready_to_ship.file_name' | translate}}</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr *ngFor="let file of selectedFiles">
						<td>{{file.name}}</td>
						<td>
							<fa-icon icon="window-close" size="1x" class="pointer text-danger" title="Delete"
								(click)="removeSelectedFile(file.name)"
							></fa-icon>
						</td>
					</tr>
				</tbody>
			</table>
		</ng-template>
	</div>
</app-modal>
