<app-left-panel ></app-left-panel>
<app-header ></app-header>
<div class="row justify-content-md-center ml-5">
	<div class="col-md-8 bg-white mt-2">
		<div class="border-bottom p-3">
			<h2>{{'login_register.forgot_your_password' | translate}}</h2>
		</div>
		<div class="px-5 py-4">
			<form #forgotPasswordFrm="ngForm" (ngSubmit)="forgotPassword()" validate-form="forgotPassword">
				<div class="row mt-2 align-items-center">
					<div class="col">
						<p>{{'login_register.forgot_header' | translate}}</p>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col">
						<input type="email" class="form-control" name="email" [(ngModel)]="forgot.email" placeholder="{{'users.email_address' | translate}}" required email/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-10">
						<app-captcha #captchaComponent></app-captcha>
					</div>
				</div>
				<div class="row my-4 align-items-center">
					<div class="col-md-8">
						<div class="row align-items-center">
							<div class="col-md-8">
								<button
									type="submit"
									class="btn text-white main-blue-bg btn-lg col-lg-5 col-md-12 mb-2 mr-2"
									[disabled]="!captchaComponent.captchaText || captchaComponent.triesLimitReached"
								>
									{{'g.submit' | translate}}
								</button>
								<button type="button" class="btn btn-light btn-lg col-lg-5 col-md-12 mb-2" (click)="goBack()">{{'g.cancel' | translate}}</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>