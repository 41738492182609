<div>
	<form #editSite="ngForm" (ngSubmit)="saveSite()" validate-form="editSite" [innerModelGroups]="['site']">
		<div class="row">
			<div class="col-6">
				<app-mapbox *ngIf="isSiteLoaded"
					class="border-radius main-blue-border"
					[latitude]="map.latitude"
					[longitude]="map.longitude"
					[zoom]="mapZoom"
					[showPopup]="false"
					[dots]="[map]"
					[clickEnabled]="true"
					(onAddressChanged)="updateSiteCoords($event)"
				></app-mapbox>
			</div>
			<div class="col-6">
				<div>
					<h4>{{'site_edit.add_edit_site_info' | translate}}</h4>
					<div class="card">
						<div class="card-header">{{'g.site_info' | translate}}</div>
						<app-site-edit #editTemplate [site]="site" [enterpriseList]="enterpriseList" [customerEnterprises]="customerEnterprises"></app-site-edit>
					</div>
					<button type="submit" class="btn btn-primary">{{'g.submit' | translate}}</button>
					<button type="button" class="btn btn-default" (click)="goBack()">{{'g.cancel' | translate}}</button>
				</div>
			</div>
		</div>
	</form>
</div>