<div>
	<div class="d-flex justify-content-between mb-2 px-2">
		<h2>{{'connectivity_verification.title' | translate}}</h2>
	</div>
	<div class="table-control-container d-flex justify-content-end align-items-center mb-2">
    <button class="btn btn-primary pointer mr-4" routerLink="/admin-panel/connectivity-verification-statistics">{{'connectivity_verification.statistics' | translate}}</button>
		<fa-icon class="setting_icon" icon="upload" size="lg" title="{{'g.export_to_csv' | translate}}" (click)="generateMainTableCsvFile()"></fa-icon>
		<fa-icon class="setting_icon" icon="table" size="lg" (click)="toggleHidePopup()"></fa-icon>
		<div class="hide-columns-popup" *ngIf="isHidePopupShown">
			<ul>
				<li *ngFor="let column of allColumns" (click)="toggleColumnVisibility(column)">
					<fa-icon [icon]="['far', hiddenColumns.includes(column.sortHeader) ? 'square' :'check-square']" size="sm" class="mr-2"></fa-icon>{{column.translationKey | translate}}
				</li>
			</ul>
		</div>
	</div>
	<div class="hide-columns-popup-shadow" *ngIf="isHidePopupShown" (click)="toggleHidePopup()"></div>
	<div class="table-container">
		<table class="table table-striped"
			*ngIf="connectivityLog.length > 0; else noData"
			matSort (matSortChange)="sortAndFilterData($event)"
			matSortActive="current_customer_name, current_site_name, customer_name, site_name, serial_number, mac_address, user, action_time, result, last_connect_time, connectivity_status, longitude, latitude, app_version"
			matSortDirection="asc"
			matSortDisableClear
		>
			<thead>

				<!-- columns -->
				<tr>
					<th *ngFor="let column of columns"
						class="wide"
						mat-sort-header="{{ column.sortHeader }}"
						draggable="true"
						(dragstart)="drag($event)"
						(dragenter)="drop($event)"
						(dragover)="allowDrop($event)"
					>
						<div [ngSwitch]="column.sortHeader">
							<div *ngSwitchCase="'action_time'">
								{{'connectivity_verification.check_time' | translate}} ({{commonService.showUserTimeZoneReference()}})
							</div>
							<div *ngSwitchCase="'last_connect_time'">
								{{'devices.last_connect_time' | translate}} ({{commonService.showUserTimeZoneReference()}})
							</div>
							<div *ngSwitchDefault>{{column.translationKey | translate}}</div>
						</div>
					</th>
				</tr>

				<!-- filters -->
				<tr>
					<th *ngFor="let column of columns" class="wide">
						<div [ngSwitch]="column.sortHeader" class="filter">

							<!-- time fields -->
							<div *ngSwitchCase="'action_time'">
								<ng-container *ngTemplateOutlet="timeRangeTemplate; context: {fromName: 'from-action_time', toName: 'to-action_time', fromId: 'from-action_time', toId: 'to-action_time', range: timeFilter['action_time']}"></ng-container>
							</div>
							<div *ngSwitchCase="'last_connect_time'">
								<ng-container *ngTemplateOutlet="timeRangeTemplate; context: {fromName: 'from-last_connect_time', toName: 'to-last_connect_time', fromId: 'from-last_connect_time', toId: 'to-last_connect_time', range: timeFilter['last_connect_time']}"></ng-container>
							</div>

							<!-- drop down options -->
							<div *ngSwitchCase="'result'">
								<ng-select
									[items]="results"
									bindLabel="value"
									bindValue="result"
									name="result"
									[(ngModel)]="selectedResults"
									multiple="true"
									(change)="sortAndFilterData()"
								>
								</ng-select>
							</div>

							<div *ngSwitchCase="'connectivity_status'">
								<ng-select
									[items]="connectivityStatuses"
									bindLabel="value"
									bindValue="connectivity_status"
									name="connectivity_status"
									[(ngModel)]="selectedConnectivity"
									multiple="true"
									(change)="sortAndFilterData()"
								>
								</ng-select>
							</div>

							<!-- number range filter -->
							<div *ngSwitchCase="'longitude'">
								<ng-container *ngTemplateOutlet="numberRangeTemplate; context: {fromName: 'from-longitude', toName: 'to-longitude', fromId: 'from-longitude', toId: 'to-longitude', range: numbersFilter['longitude']}"></ng-container>
							</div>
							<div *ngSwitchCase="'latitude'">
								<ng-container *ngTemplateOutlet="numberRangeTemplate; context: {fromName: 'from-latitude', toName: 'to-latitude', fromId: 'from-latitude', toId: 'to-latitude', range: numbersFilter['latitude']}"></ng-container>
							</div>
							<div *ngSwitchCase="'current'">
								<ng-container *ngTemplateOutlet="numberRangeTemplate; context: {fromName: 'from-current', toName: 'to-current', fromId: 'from-current', toId: 'to-current', range: numbersFilter['current']}"></ng-container>
							</div>
							<div *ngSwitchCase="'voltage'">
								<ng-container *ngTemplateOutlet="numberRangeTemplate; context: {fromName: 'from-voltage', toName: 'to-voltage', fromId: 'from-voltage', toId: 'to-voltage', range: numbersFilter['voltage']}"></ng-container>
							</div>

							<!-- string filter -->
							<div *ngSwitchDefault>
								<input class="form-control ng-tns-c18-2 ng-pristine ng-valid ng-touched"
									type="search"
									name="site_name_filter"
									id="site_name_filter"
									placeholder="Filter"
									[(ngModel)]="filter[column.sortHeader]"
									(input)="sortAndFilterData()"
								>
							</div>
						</div>
					</th>
				</tr>

			</thead>
			<tbody>
				<tr *ngFor="let record of toShowConnectivityLog">
					<td *ngFor="let column of columns">
						<div [ngSwitch]="column.sortHeader">
							<div *ngSwitchCase="'current_customer_name'">
								<a [routerLink]="['/', record.device_customer_id]">
									{{record.current_customer_name}}
								</a>
							</div>
							<div *ngSwitchCase="'current_site_name'">
								<a [routerLink]="['/', record.device_customer_id, record.device_site_id]">
									{{record.current_site_name}}
								</a>
							</div>

							<div *ngSwitchCase="'customer_name'">
								<a [routerLink]="record.customer_name == 'customer_deleted'? null : ['/', record.customer_id]">
									{{(record.customer_name == 'customer_deleted'? "connectivity_verification." + record.customer_name  : record.customer_name) | translate}}
								</a>
							</div>

							<div *ngSwitchCase="'site_name'">
								<a [routerLink]="record.site_name == 'site_deleted'? null : ['/', record.customer_id, record.site_id]">
									{{(record.site_name == 'site_deleted'? "connectivity_verification." + record.site_name : record.site_name) | translate}}
								</a>
							</div>

							<div *ngSwitchCase="'serial_number'">
								<a [routerLink]="record.serial_number == 'device_deleted'? null : ['/', record.device_customer_id, record.device_site_id, record.mac_address, 'performance']">
									{{(record.serial_number == 'device_deleted'? "connectivity_verification." + record.serial_number : record.serial_number ) | translate}}
								</a>
							</div>

							<div *ngSwitchCase="'mac_address'">
								<a [routerLink]="record.serial_number == 'device_deleted'? null : ['/', record.device_customer_id, record.device_site_id, record.mac_address, 'performance']">
									{{record.mac_address}}
								</a>
							</div>

							<div *ngSwitchCase="'user'">
								<div *ngIf="record.user else noWarning">
									<a [routerLink]="record.user_id? ['/user', 'edit', record.user_id] : null">
										{{record.user}}
									</a>
								</div>
							</div>

							<div *ngSwitchCase="'action_time'">
								{{record.action_time*1000 |date:'MM/dd/yyyy hh:mm:ss a'}}
							</div>

							<div *ngSwitchCase="'result'">
								{{(checkProcessStatus(record)? 'connectivity_verification.pass': 'connectivity_verification.fail') | translate }}
							</div>

							<div *ngSwitchCase="'last_connect_time'">
								{{record.last_connect_time*1000 |date:'MM/dd/yyyy hh:mm:ss a'}}
							</div>

							<div *ngSwitchCase="'connectivity_status'">
								{{(record.connectivity_status ? 'g.connected': 'g.disconnected') | translate }}
							</div>

							<div *ngSwitchCase="'longitude'">
								{{record.longitude}}
							</div>

							<div *ngSwitchCase="'latitude'">
								{{record.latitude}}
							</div>

							<div *ngSwitchCase="'current'">
								{{record.current != null? record.current : '---'}}
							</div>

							<div *ngSwitchCase="'voltage'">
								{{record.voltage != null? record.voltage : '---'}}
							</div>

							<div *ngSwitchCase="'app_version'">
								{{record.app_version}}
							</div>

							<div *ngSwitchCase="'warning_ids'">
								<div *ngIf="record.warning_ids?.length; else noWarning">
									<ng-container *ngFor="let warning of prepareWarnings(record.warning_ids)">
										<ul>
											<span><li>{{("warnings." + warning) | translate }}</li></span>
										</ul>
									</ng-container>
								</div>
							</div>

						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>

<mat-paginator
	*ngIf="sortResult.length"
	[length]="sortResult.length"
	[pageIndex]="currentPage - 1"
	[pageSize]="pageSize"
	[pageSizeOptions]="pageSizeOptions"
	[showFirstLastButtons]="true"
	(page)="onPageChange($event)">
</mat-paginator>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<ng-template #noWarning>
	{{'---'}}
</ng-template>

<ng-template #numberRangeTemplate let-fromName="fromName" let-toName="toName" let-fromId="fromId" let-toId="toId" let-range="range">
	<div>
		<input class="form-control"
			type="number"
			[name]="fromName"
			[id]="fromId"
			placeholder="from"
			[(ngModel)]="range.from"
			(input)="sortAndFilterData()">
		<input class="form-control"
			type="number"
			[name]="toName"
			[id]="toId"
			placeholder="to"
			[(ngModel)]="range.to"
			(input)="sortAndFilterData()">
	</div>
</ng-template>

<ng-template #timeRangeTemplate let-fromName="fromName" let-toName="toName" let-fromId="fromId" let-toId="toId" let-range="range">
	<div>
		<input class="form-control"
			type="date"
			[name]="fromName"
			[id]="fromId"
			placeholder="from"
			[(ngModel)]="range.from"
			(input)="sortAndFilterData()">
		<input class="form-control"
			type="date"
			[name]="toName"
			[id]="toId"
			placeholder="to"
			[(ngModel)]="range.to"
			(input)="sortAndFilterData()">
	</div>
</ng-template>
