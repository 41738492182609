import { Component, OnInit, Input, AfterViewInit, ViewChild } from '@angular/core';
import { NoteService } from '../note/note.service';
import { UsersService } from 'src/app/users/users.service';
import { NotificationMessageService } from '../notification-message/notification-message.service';
import { Router } from '@angular/router';
import * as moment from 'moment';

@Component({
	selector: 'app-noc-note',
	templateUrl: './noc-note.component.html',
	styleUrls: ['./noc-note.component.css']
})

export class NocNoteComponent implements OnInit, AfterViewInit {
	@Input() device: any;
	@ViewChild("noteModal") noteModal;

	newNote: string = '';
	note: {
		insertion_date: any
		last_connection_time_note: string,
		user_note: string,
		system_notes: any
	} = {
		insertion_date: null,
		last_connection_time_note: '',
		user_note: '',
		system_notes: {}
	};
	formattedNote = '';
	formattedDate: string = '';

	constructor(
		public notificationMessage: NotificationMessageService,
		public noteService: NoteService,
		public usersService: UsersService,
		private router: Router,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			return this.router.navigate(['/unauthorized']);
		this.getNOCNote();
	}

	ngOnChanges(changes) {
		if (changes.device && changes.device.currentValue?.mac_address != changes.device.previousValue?.mac_address)
			this.getNOCNote();
	}

	getNOCNote() {
		this.noteService.getNOCNote(this.device.mac_address, this.device.config_info.site_id).subscribe((data: any) => {
			if(data) {
				const { user_note, last_connection_time_note, insertion_date, system_notes } = data;
				this.note = { ...this.note, user_note, last_connection_time_note, insertion_date, system_notes};
				this.newNote = user_note;
				this.updateFormattedNote();
				this.updateFormattedDate();
			}
		})
	}

	updateFormattedNote() {
		const noteList = []
		if (this.note.user_note != '')
			noteList.push(this.note.user_note)

		if (this.note.last_connection_time_note != '')
			noteList.push(this.note.last_connection_time_note)

		if (Object.keys(this.note.system_notes || {}).length) {
			for (const key in this.note.system_notes) {
				if (this.note.system_notes[key])
					noteList.push(this.note.system_notes[key]);
			}
		}

		this.formattedNote = noteList.join(' / ');
	}

	updateFormattedDate(timeNow = false) {
		if (timeNow)
			return this.formattedDate = moment(new Date().getTime()).format("LLLL");

		if (!this.note.insertion_date)
			return this.formattedDate = '--/--/--';

		this.formattedDate = moment((new Date(this.note.insertion_date).getTime())).format("LLLL");
	}

	ngAfterViewInit() {
		this.noteModal.onClose.subscribe((ok) => {
			if (ok)
				return this.updateNOCNote();

			return;
		});
	}

	openNoteModal() {
		this.newNote = this.note.user_note;
		this.noteModal.show();
	}

	updateNOCNote() {
		this.noteService.updateNOCNote(this.device.mac_address, this.device.config_info.site_id, this.newNote).subscribe((data: any) => {
			if (data.err)
				return this.notificationMessage.setMessage('translate|g.global_err');

			this.note.user_note = this.newNote;
			this.updateFormattedNote();
			this.updateFormattedDate(true);
			return this.notificationMessage.setMessage('globalSuccessMsg');
		})
	}
}
