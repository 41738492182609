import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from '../users/users.service';
import { DeviceService } from '../home/site-dashboard/device/device.service';

@Component({
  selector: 'app-iccid-changes-report',
  templateUrl: './iccid-changes-report.component.html',
  styleUrls: ['./iccid-changes-report.component.css']
})
export class IccidChangesReportComponent implements OnInit {

  devices: any = [];
  sites: any;
  customers: any;

  constructor(
    private router: Router,
    private usersService: UsersService,
    private devicesService: DeviceService
  ) { }

  ngOnInit() {
    let currentUser = this.usersService.getCurrentUser();
		if(!currentUser.is_admin)
			return this.router.navigate(['/unauthorized']);
    return this.getChangesReportDevices();

  }

  getChangesReportDevices() {
    this.devicesService.getChangesReportDevices().subscribe((data: any) => {
      this.devices = data.devices;
      this.sites = data.sites;
      this.customers = data.customers;
    })
  }
}
