<app-modal #updateSetupDone [title]="'devices.change_is_done' | translate" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		{{'devices.change_is_done_msg' | translate}}
	</div>
	<div class="modal-footer">
		<button class="btn text-white main-blue-bg btn-lg mr-4 px-4" (click)="updateSetupDone.hide();approveRequest(selectedRequest, true)">{{'g.yes' | translate}}</button>
		<button class="btn text-white main-grey-bg btn-lg mr-4 px-4" (click)="updateSetupDone.hide();approveRequest(selectedRequest, false)">{{'g.no' | translate}}</button>
		<button class="btn text-white main-green-bg btn-lg" (click)="updateSetupDone.hide()">{{'g.cancel' | translate}}</button>
	</div>
</app-modal>
<app-modal #updateSetupDoneMulti [title]="'devices.change_is_done' | translate" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="mb-5">
		{{'devices.multi_change_is_done_msg' | translate}}
		<ul class="mt-2">
			<li *ngFor="let req of selectedRequests">
				{{req.mac_address}}
			</li>
		</ul>
	</div>
	<div class="modal-footer">
		<button class="btn text-white main-blue-bg btn-lg mr-4 px-4" (click)="updateSetupDoneMulti.hide();approveRequestMulti(true)">{{'g.yes' | translate}}</button>
		<button class="btn text-white main-grey-bg btn-lg mr-4 px-4" (click)="updateSetupDoneMulti.hide();approveRequestMulti(false)">{{'g.no' | translate}}</button>
		<button class="btn text-white main-green-bg btn-lg" (click)="updateSetupDoneMulti.hide()">{{'g.cancel' | translate}}</button>
	</div>
</app-modal>

<div class="main-black border-radius-bottom white-box mt-1" *ngIf="deviceOwners; else noRequests">
	<div class="card-body" *ngIf="requestsList.length > 0; else noRequests">
		<span *ngIf="selectedCount > 1; else noSelect">
			<h6 class="mb-3">
				{{'g.selected' | translate}}: {{selectedCount}}
				<button class="btn btn-outline-success ml-2" (click)="onBeforeApproveRequestMulti()">
					{{'device_management.approve' | translate}}
				</button>
				<button class="btn btn-outline-danger ml-2" (click)="confirmAction('rejectRequestMulti')">
					{{'g.reject' | translate}}
				</button>
			</h6>
		</span>
		<ng-template #noSelect>
			<h6 class="mb-3">{{requestsList.length}} {{'nav.manage_device_requests' | translate}}</h6>
		</ng-template>
		<table class="table mb-0">
			<thead>
				<tr>
					<th><input type="checkbox" name="request_all" [(ngModel)]="selectedAll" (ngModelChange)="selectAll($event)"></th>
					<th>{{'g.mac_address' | translate}}</th>
					<th>{{'devices.latitude' | translate}}</th>
					<th>{{'devices.longitude' | translate}}</th>
					<th>{{'devices.serial_number' | translate}}</th>
					<th>{{'device_management.request_by' | translate}}</th>
					<th>{{'g.enterprise_name' | translate}}</th>
					<th>{{'g.site' | translate}}</th>
					<th>{{'device_management.is_reset_data' | translate}}</th>
					<th>{{'device_management.source' | translate}}</th>
					<th>{{'g.actions' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let request of requestsList">
					<th><input type="checkbox" name="request_all" [(ngModel)]="request.selected" (ngModelChange)="selectRow($event)"></th>
					<td>
						<a
							class="blue-hover pointer ml-1"
							[routerLink]="['/', this.deviceOwners[request._current_site].customer_id, request._current_site, request.mac_address, 'performance']"
							*ngIf="this.deviceOwners[request._current_site]; else deviceNoLink"
						>
							{{request.mac_address}}
						</a>
						<ng-template #deviceNoLink>{{request.mac_address}}</ng-template>
					</td>
					<td>{{devicesInfo[request.mac_address]?.latitude}}</td>
					<td>{{devicesInfo[request.mac_address]?.longitude}}</td>
					<td>{{request.serial_number}}</td>
					<td>
						<a [routerLink]="['/user', 'edit', request.user_id]" target="_blank" *ngIf="usersService.hasAccessFunction('edit_user_admin') && usersInfo[request.user_id]; else userNoLink">
							{{usersInfo[request.user_id].first_name}} {{usersInfo[request.user_id].last_name}}
						</a>
						<ng-template #userNoLink>{{request.user_id}}<span *ngIf="!usersInfo[request.user_id]"> ({{'g.deleted' | translate}})</span></ng-template>
					</td>
					<td>
						<ng-container *ngIf="enterprisesInfo[request.enterprise_id]; else entDel">{{enterprisesInfo[request.enterprise_id].name}}</ng-container>
						<ng-template #entDel>{{request.enterprise_id}}<span> ({{'g.deleted' | translate}})</span></ng-template>
					</td>
					<td>
						{{deviceOwners[request._current_site] ? deviceOwners[request._current_site].name : request._current_site}}
						<i class="fa fa-long-arrow-right" aria-hidden="true"></i>
						<a [routerLink]="['/', request._new_customer, request._new_site]" target="_blank">
							{{deviceOwners[request._new_site] ? deviceOwners[request._new_site].name : request._new_site}}
						</a>
					</td>
					<td>{{request.is_reset_data}}</td>
					<td>{{(request.source ? 'device_management.source_options.'+[request.source] : 'g.n/a') | translate}}</td>
					<td>
						<mat-icon class="pointer blue-hover" [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{request: request}">
							settings
						</mat-icon>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</div>
<ng-template #noRequests>
	<div class="main-black border-radius-bottom white-box mt-1">
		<div class="card-body" *ngIf="!requestsList || !requestsList.length">
			<h3>{{'device_management.no_new_move' | translate}}</h3>
		</div>
	</div>
</ng-template>

<mat-menu #actionsMenu="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent let-request="request">
		<span mat-menu-item class="text-success" (click)="onBeforeApproveRequest(request)">
			{{'device_management.approve' | translate}}
		</span>
		<span mat-menu-item class="text-danger" (click)="confirmAction('rejectRequest', request.id)">
			{{'g.reject' | translate}}
		</span>
		<span
			mat-menu-item
			(click)="showDeviceLocation(request)"
			*ngIf="request.source == 'device' && devicesInfo[request.mac_address]?.latitude && devicesInfo[request.mac_address]?.longitude"
		>
			{{'device_management.view_location' | translate}}
		</span>
	</ng-template>
</mat-menu>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>
<app-modal #locationModal title="{{'g.locations' | translate}}" size="lg" [hasConfirmButton]="false">
	<app-mapbox *ngIf="locationsArr.length > 0"
		class="border-radius main-blue-border"
		[latitude]="sitesService.defaultMapLocation.latitude"
		[longitude]="sitesService.defaultMapLocation.longitude"
		zoom="3.5"
		[showPopup]="false"
		[dots]="locationsArr"
	></app-mapbox>
</app-modal>