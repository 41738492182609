import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { CustomersService } from './customers.service';
import { Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from '../shared/notification-message/notification-message.service';
import { UsersService } from '../users/users.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

@Component({
	selector: 'app-customers',
	templateUrl: './customers.component.html',
	styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit, AfterViewInit {

	customers: any[] = [];
	currentUser: any = {};
	filters = {
		searchString: '',
		isPartial: false,
	};

	@ViewChild("confirmModal") confirmModal;

	constructor(
		private customersService: CustomersService,
		private router: Router,
		private notificationMessage: NotificationMessageService,
		public translate: TranslateService,
		public usersService: UsersService,
		private sideMenuService: SideMenuService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		this.currentUser = this.usersService.getCurrentUser();
		this.getCustomers();
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			return this.deleteCustomer(result[1]);
		});
	}

	confirmDeleteCustomer(data) {
		this.confirmModal.passData = data;
		this.confirmModal.message = 'g.del_customer_prompt';
		this.confirmModal.show();
	}

	getCustomers() {
		this.customersService.getCustomers(this.filters).subscribe(
			(data: any) => {
				// sort data by customer_name
				this.customers = data.sort((item1, item2) => item1.customer_name.toLowerCase() > item2.customer_name.toLowerCase() ? 1 : -1);
			}
		);
	}

	goToEditCustomer(id) {
		this.router.navigate(['/customers', 'edit', id]);
	}

	deleteCustomer(id) {
		this.customersService.deleteCustomer(id).subscribe(
			(data: any) => {
				switch(data) {
					case 1:
						this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
						this.getCustomers();
					break;
					case 2:
						this.notificationMessage.setMessage('translate|customer.delete_failed_has_sites', {clearOnXTimeNavigate: 1});
					break;
					default:
						this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
					break;
				}
			}
		);
	}
}