<app-loader></app-loader>
<app-left-panel *ngIf="hasLeftPanel" [visibleSideMenu]="visibleSideMenu"></app-left-panel>
<app-header [visibleSideMenu]="!hideMenuView && visibleSideMenu"></app-header>
<div id="wrapper" [ngClass]="{'toggled': !hideMenuView && visibleSideMenu, 'has-left-panel': hasLeftPanel}">
	<div>
		<p class="refresh-browser-banner" *ngIf="commonDataService.showRefreshMessage">
			{{ "admin.user_force_website_refresh.part_1" | translate}}
			<a class="link-primary pointer" [routerLink]="[]" (click)="reloadPage()">    
				{{ "admin.user_force_website_refresh.part_2" | translate}}
			</a>
			{{ "admin.user_force_website_refresh.part_3" | translate}}
		</p>
	</div>
	
	<div *ngIf="hasLeftPanel" class="left-menu-extend"></div>
	<app-side-menu *ngIf="visibleSideMenu"></app-side-menu>
	<div [ngClass]="{'no-gutter-left-phone': !visibleSideMenu, 'p-4': !commonService.isMobileScreen, 'p-1': commonService.isMobileScreen}">
		<app-notification-message></app-notification-message>
		<router-outlet></router-outlet>
	</div>
</div>
<app-footer></app-footer>