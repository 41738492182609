<div id="{{widgetId}}" class="card">
	<div class="card-header">
    <a href="javascript:">
      <i (click)="toggleCollapse()" class="fa mr-1" [ngClass]="{'fa-minus': !isCollapsed, 'fa-plus': isCollapsed}"></i>
    </a><strong>{{title}} <app-help-tooltip *ngIf="tooltipKey" [isSmall]="true" [field]="tooltipKey"></app-help-tooltip></strong>
  </div>
	<div *ngIf="!isCollapsed" class="card-body">
		<ng-content></ng-content>
  </div>
</div>
