<div ngModelGroup="customerInputs" class="col-sm-12 card-body">
	<div class="border-bottom mb-4">
		<h2> {{ (customer.id == 0 ? 'customer.new_customer' : 'customer.edit_customer') | translate }} </h2>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-2">
			<strong>{{'g.customer_name' | translate}}</strong>
		</div>
		<div class="col-md-6">
			<input class="form-control" name="customer_name" [(ngModel)]="customer.customer_name" required/>
		</div>
	</div>
	<div class="row mt-2 align-items-center">
		<div class="col-md-2">
			<strong> {{'customer.customer_enterprises' |translate}} </strong>
		</div>
		<div class="col-md-6">
			<ng-select [multiple]="true" id="customer_enterprises" [items]="enterpriseList" bindLabel="name" bindValue="id" placeholder="{{'site_edit.select_enterprise_group' | translate}}" name="customer_enterprises" [(ngModel)]="customer.customer_enterprises" [clearable]="false">
			</ng-select>
		</div>
	</div>
	<fieldset class="mt-4" *ngIf="!miniForm">
		<legend>{{'g.contact_info' | translate}}</legend>
		<div class="row mt-2 align-items-center">
			<div class="col-md-2">
				<strong>{{'g.name' | translate}}</strong>
			</div>
			<div class="col-md-6">
				<input class="form-control" name="customer_contact_name" [(ngModel)]="customer.customer_contact_name"/>
			</div>
		</div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-2">
				<strong>{{'g.phone_no' | translate}}</strong>
			</div>
			<div class="col-md-6">
				<input class="form-control" name="customer_phone_num" [(ngModel)]="customer.customer_phone_num"/>
			</div>
		</div>
	</fieldset>
</div>
