import { CommonService } from './../../shared/services/common.service';
import { Component, OnInit, ViewChild } from '@angular/core';
import { SitesService } from '../../sites/sites.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-firmware-update',
	templateUrl: './firmware-update.component.html',
	styleUrls: ['./firmware-update.component.css']
})
export class FirmwareUpdateComponent implements OnInit {
	@ViewChild("requestsLogsModal") requestsLogsModal;
	@ViewChild("lastUpdateRequestModal") lastUpdateRequestModal;

	firmwareData: any;
	customers: any[];
	searchText: string;
	firmwareVersions: any = [];
	usersInfo = {};
	requestLogs: any = {};
	allDevicesFirmwareVersion: number = 0;
	isAdmin = false;

	selectedSite = null;
	lastUpdateRequest = {
		siteName: "",
		updated: 0,
		total: 0
	};

	firmwareStatusTextMapper = {
		0: 'on_hold',
		1: 'in_progress',
		2: 'completed',
		default: 'n/a'
	};

	constructor(
		private sitesService: SitesService,
		private notificationMessage: NotificationMessageService,
		private usersService: UsersService,
		private router: Router,
		private sideMenuService: SideMenuService,
		public common: CommonService,
		private translateService: TranslateService,
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		this.isAdmin = this.usersService.getCurrentUser().is_admin;

		if(!this.usersService.hasAccessFunction('site_management'))
			return this.router.navigate(['/unauthorized']);

		this.getSitesFirmwareData();
	}

	getSitesFirmwareData() {
		this.sitesService.getSitesFirmwareData().subscribe((data: any) => {
			this.sortFirmwareDataByCustomerNameAndSiteName(data.fwUpdateData, data.firmwareVersions);
			this.firmwareData = data.fwUpdateData;
			this.customers = data.fwUpdateData;
			this.firmwareVersions = data.firmwareVersions;
		});
	}

	applySearchFilter(filterValue) {
		this.customers = this.firmwareData.filter((customer) => {
			const exists = customer.customer_name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1;
			if (exists)
				return true;

			customer.sites.forEach(site => {
				if (site.name.toLowerCase().indexOf(filterValue.toLowerCase()) > -1)
					return true;
			});
			return false;
		});
	}

	getUpToDateSiteFw(site) {
		this.sitesService.getUpToDateSiteFw(site.id).subscribe((data: any) => {
			this.lastUpdateRequest = {
				siteName: site.name,
				total: data.total,
				updated: data.updated
			};

			this.lastUpdateRequestModal.show();
		});
	}

	filterVersions(currentVersion) {
	 	return this.firmwareVersions.filter(function(ver) {return ver != currentVersion;});
	}

	showFirmwareRequestsLog(site) {
		this.selectedSite = site;

		this.sitesService.getSiteFwUpdateRequests('all', site.id).subscribe((data: any) => {
			this.usersInfo = data.usersInfo;
			this.requestLogs = data.requests;

			if(this.requestLogs.device[0])
				this.requestLogs.device[0].status = this.requestLogs.status;

			this.requestLogs.device.map((device) => { device.status = this.firmwareStatusTextMapper[device.status] || this.firmwareStatusTextMapper.default });
			this.requestsLogsModal.show();
		});
	}

	pushFirmwareUpdate(type, siteId, version) {
		this.sitesService.pushFirmwareUpdate(siteId, type, version).subscribe((data: any) => {
			switch (data) {
				case 1:
					this.notificationMessage.setMessage('translate|site.invalid_firmware_version', {clearOnXTimeNavigate: 1});
				break;
				case 2:
					this.notificationMessage.setMessage('translate|site.invalid_site', {clearOnXTimeNavigate: 1});
				break;
				case 3:
					this.notificationMessage.setMessage('translate|site.fw_already_pushed', {clearOnXTimeNavigate: 1});
				break;
				default:
					this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			}
		});
	};

	abortFirmwareUpdate(siteId) {
		this.sitesService.abortFirmwareUpdate(siteId).subscribe((data: any) => {
			switch (data) {
				case 1:
					this.notificationMessage.setMessage('translate|site.invalid_site', {clearOnXTimeNavigate: 1});
				break;
				case 2:
					this.notificationMessage.setMessage('translate|site.no_firmware_version_pushed', {clearOnXTimeNavigate: 1});
				break;
				case 4:
					this.notificationMessage.setMessage('translate|site.firmware_update_completed', {clearOnXTimeNavigate: 1});
				break;
				default:
					this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			}
		});
	};

	toggleExpand(model) {
		model.expanded = !model.expanded;
	}

	sortFirmwareDataByCustomerNameAndSiteName(fwUpdateData, firmwareVersions) {
		// Sort customer_name alphabetically
		fwUpdateData.sort((customerA, customerB) => {
			return customerA.customer_name.localeCompare(customerB.customer_name);
		});

		// Sort sites array alphabetically by name
		fwUpdateData.forEach(customer => {
			customer.sites.sort((siteA, siteB) => {
				return siteA.name.localeCompare(siteB.name);
			});
		});

		// Sort firmware versions in descending order (newest first)
		firmwareVersions.sort((versionA, versionB) => versionB - versionA);
	}
}
