<h3>{{ title }}</h3>
<div class="my-4">
	<app-table name="unused-logs" [elements]="logs" [columnNames]="columnNames" (actionEvent)="doAction($event)"></app-table>
</div>

<app-modal #confirmModal title="" [hasCloseButton]="false" [size]="'xl'">
	<div class="row white-box p-4 border-radius-bottom">
		<div *ngIf="confirmModal.passData" class="col">
			<div class="preperty-values">
				
				<div class="centered-values mt-2">
					<div *ngFor="let mac of confirmModal.passData | keys">
						<div class="p-2">{{ mac }}</div>
						<div class="p-2">{{ confirmModal.passData[mac] * 1000 | date:'long' }}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</app-modal>