import { AfterViewInit, Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import * as _ from 'underscore';
import { cloneDeep, pick, map } from 'lodash';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { ReceivingShipmentService } from '../receiving-shipment/receiving-shipment.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { LoaderInterceptorService } from 'src/app/shared/loader/loader-interceptor.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { BehaviorSubject } from 'rxjs';
import { SitesService } from 'src/app/sites/sites.service';

@Component({
	selector: 'app-shipment-quality',
	templateUrl: './shipment-quality.component.html',
})
export class ShipmentQualityComponent implements OnInit, AfterViewInit {

	currentUser:any = [];
	searchString: string = '';
	searchBy: string = 'mac'; // sn: serial number, mac: mac address
	searchResult: any = null;
	sites: any = [];
	devices: any = {};
	selectedDevice: any = {};
	DEVICE_STATUS = this.commonDataService.RECEIVING_SHIPMENT_DEVICES_STATUS;
	isGpsNotReady: any = {};
	intervalId: any;
	macAddress: string;
	fwDevicesStatus: any = {};
	hasFailures: boolean = false;
	shipmentCounter = 0;
	disableSubmitButton = false;
	disableSearch = false;
	csvDataLimit = 1500;
	searchedDevices: string[];
	resetRTCDevice = '';

	@ViewChild("warningDialog") warningDialog: any;
	@ViewChild("indicatorLightModal") indicatorLightModal: any;
	@ViewChild('labelImport') labelImport: ElementRef;
	@ViewChild("resetRTCLostDetectionModal") resetRTCLostDetectionModal;

	shipmentOptions = {
		historyReportId: null,
		source: this.deviceManagementService.shipmentSource.quality,
		time_zone_diff: -new Date().getTimezoneOffset(),
	};
	private shipmentCounterSubject= new BehaviorSubject<number>(0);
	processCounter = this.shipmentCounterSubject.asObservable();

	constructor(
		private router: Router,
		public userService: UsersService,
		private deviceManagementService: DeviceManagementService,
		public notificationMessage: NotificationMessageService,
		private translate : TranslateService,
		private sideMenuService: SideMenuService,
		public commonDataService: CommonDataService,
		public receivingShipmentService: ReceivingShipmentService,
		private deviceService: DeviceService,
		private loader: LoaderInterceptorService,
		private commonService: CommonService,
		private siteService: SitesService
	) { }

	ngOnInit() {
		this.currentUser = this.userService.getCurrentUser();
		if(!this.userService.hasAccessFunction('cm_check_warnings') && !this.userService.hasAccessFunction('shipment_management'))
			this.router.navigate(['unauthorized']);

		this.intervalId = setInterval(() => {
			this.getFWUpdateStatus();
		}, 30000); //every 30 seconds

		this.sideMenuService.hide();

		this.processCounter.subscribe((counter: number) => {
			if(Object.values(this.devices).length > 0 && (this.devicesHaveFWUpdate() || this.shouldAllowSubmitShipment()) && counter == Object.values(this.devices).length) {
				this.submitShipment();
				this.shipmentCounter = Object.values(this.devices).filter((item: any) => this.receivingShipmentService.isRecevingProccessSuccess(item.status)).length;
				this.disableSubmitButton = !this.shouldAllowSubmitShipment();
			}
		});
	}

	ngAfterViewInit() {
		this.indicatorLightModal.onClose.subscribe((ok) => {
			if (ok)
				return this.addToShipment();

			return this.notificationMessage.setMessage('translate|shipment.incorrect_device');
		});

		this.resetRTCLostDetectionModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			this.resetRTCLostDetection();
		});
	}

	showResetRTCPopup(mac_address) {
		this.resetRTCLostDetectionModal.passData = [{'reset': true}];
		this.resetRTCLostDetectionModal.show();
		this.resetRTCDevice = mac_address;
	}

	getFWUpdateStatus() {
		const deviceIds = (this.macAddress ? [this.macAddress] : []).concat(Object.keys(this.devices));
		if (!deviceIds.length)
			return;

		this.deviceManagementService.checkDeviceFWStatus(deviceIds, { receivingShipmentStarted: false, ...this.shipmentOptions }).subscribe((res: any) => {
			this.fwDevicesStatus = res.status;
		});
	}

	getDevices(){
		this.notificationMessage.closeNotification();
		this.deviceManagementService.searchDevices(this.searchString, this.searchBy, false, false, 'cm-check-warnings').subscribe((data: any) => {
			this.searchResult = data.devices || [];
			this.sites = _.indexBy(data.sites || [], 'id');

			this.macAddress = this.searchResult[0]?.mac_address;
			this.getFWUpdateStatus();
		});
	}

	checkFWUpdateStatus(device) {
		if(!this.shipmentOptions.historyReportId)
			return;

		this.deviceManagementService.checkDeviceFWStatus([device.mac_address], { receivingShipmentStarted: true, ...this.shipmentOptions }).subscribe((res: any) => {
			this.fwDevicesStatus[device.mac_address] = res.status[device.mac_address];

			if (!this.fwDevicesStatus[device.mac_address].step) {
				this.hideReCheckFailureButton();
				this.checkShipmentQuality(device);
			} else if(!this.hasFailures)
				this.hasFailures = true;
		});
	}

	checkShipmentQuality(device) {
		this.devices[device.mac_address].status = this.DEVICE_STATUS.in_progress.status;

		this.deviceManagementService.checkShipmentQuality(device.mac_address, false, this.shipmentOptions).subscribe((data: any) => {
			if (this.manageResponseMessage(device.mac_address, data))
				this.checkCellularTestMode(device);
			else
				this.shipmentCounterSubject.next(++this.shipmentCounter);
		});
	}

	checkCellularTestMode(device)  {
		this.devices[device.mac_address].status = this.DEVICE_STATUS.in_progress.status;

		this.deviceManagementService.checkCellularTestMode(device.mac_address, this.shipmentOptions).subscribe((data: any) => {
			if (this.manageResponseMessage(device.mac_address, data))
				this.checkCellularGpsStatus(device);
			else
				this.shipmentCounterSubject.next(++this.shipmentCounter);
		});
	}

	checkCellularGpsStatus(device) {
		this.devices[device.mac_address].status = this.DEVICE_STATUS.in_progress.status;

		this.deviceManagementService.checkCellularGpsStatus(device.mac_address, this.shipmentOptions).subscribe((data: any) => {
			this.manageResponseMessage(device.mac_address, data, true);
			this.shipmentCounterSubject.next(++this.shipmentCounter);
		});
	}

	manageResponseMessage(mac_address: string, response: any, isFinalStep = false) {
		this.devices[mac_address].status = this.DEVICE_STATUS.in_progress.status;
		this.devices[mac_address].warnings = [];
		const currentVoltageCheck = response.api_status && response.api_status != 1 ? response?.response?.currentVoltageCheck : response?.currentVoltageCheck;
		if(currentVoltageCheck) {
			delete currentVoltageCheck.err;
			this.devices[mac_address].currentVoltageCheck = currentVoltageCheck;
		}

		switch(response.api_status) {
			case 2:
				this.devices[mac_address].status = this.DEVICE_STATUS.not_connected.status;
			break;
			case 3:
				let warnings = response.response.warnings;
				for (let warning of warnings)
					this.devices[mac_address].warnings.push(this.translate.instant('warnings.' + warning));
			break;
			case 4:
				this.devices[mac_address].warnings.push(this.translate.instant('ready_to_ship.cmd_cell_test_mode_failed'));
			break;
			case 5:
				this.devices[mac_address].warnings.push(this.translate.instant('ready_to_ship.cellular_and_gps_not_ready'));
			break;
			case 6:
				this.devices[mac_address].status = this.DEVICE_STATUS.cellular_not_connected.status;
			break;
			case 7:
				//this.devices[mac_address].status = this.DEVICE_STATUS.gps_not_ready.status;
				this.isGpsNotReady[mac_address] = true;
				if(!isFinalStep)
					return true;
			break;
			case 8:
				this.devices[mac_address].warnings.push(this.translate.instant('ready_to_ship.factory_reset_fails'));
			break;
			case 9:
				this.devices[mac_address].warnings.push(this.translate.instant('shipment.new_site_fw_upgrade'));
			break;
			case 10:
				this.devices[mac_address].status = this.DEVICE_STATUS.no_cellular_iccid.status;
			break;
			case 11:
				this.devices[mac_address].warnings.push(this.translate.instant('ready_to_ship.cellular_iccid_does_not_exist'));
			break;
			case 12:
				this.devices[mac_address].warnings.push(this.translate.instant('ready_to_ship.cellular_not_ready'));
			break;
			case 13:
				this.devices[mac_address].status = this.DEVICE_STATUS.device_not_cm_devices.status;
			break;
			case 14:
				this.devices[mac_address].status = this.DEVICE_STATUS.not_reachable.status;
			break;
			case 15:
				this.devices[mac_address].status = this.DEVICE_STATUS.not_authorized.status;
			break;
			case 16:
				this.devices[mac_address].status = this.DEVICE_STATUS.voltage_error.status;
			break;
			case 17:
				this.devices[mac_address].status = this.DEVICE_STATUS.current_error.status;
			break;
			case 18:
				return this.notificationMessage.setMessage('translate|shipment.device_wifi_connected_promt', {type: 'danger', timeout: 10000, scrollToTop: true, clearOnXTimeNavigate: 1});
			break;
			default:
				if(!isFinalStep)
					return true;
			break;
		}

		if (isFinalStep) {
			if ([1, 7].includes(response.api_status))
				this.devices[mac_address].status = this.isGpsNotReady[mac_address] ? this.DEVICE_STATUS.completed_gps_not_ready.status : this.DEVICE_STATUS.completed.status;

			this.searchResult = null;
			this.searchString = '';
		}

		if (this.devices[mac_address].warnings.length)
			this.devices[mac_address].status = this.DEVICE_STATUS.warnings.status;

			this.hideReCheckFailureButton();

		if(![1, 7].includes(response.api_status))
			this.hasFailures = true;

		return false;
	}

	checkVoltageCurrentRanges(device) {
		this.deviceManagementService.getLiveQuickView(device.mac_address).subscribe((res: any) => {
			let msg: string;
			if(res.api_status && res.api_status != 1) {
				msg = 'g.global_err';
				switch (res.api_status) {
					case 2:
						msg = 'shipment.not_connected'
						break;
					case 14:
					msg = 'shipment.not_reachable'
						break;
				}
			} else {
				const qv = this.commonService.decompress(res || {}, 'quick_view');

				const voltage = +qv.voltage.toFixed(1);
				const current = +qv.current.toFixed(1);
				if(voltage < this.deviceManagementService.currentAndVoltageRanges.lowVoltageRange || voltage > this.deviceManagementService.currentAndVoltageRanges.highVoltageRange)
					msg = 'shipment.voltage_error';
				else if((current < this.deviceManagementService.currentAndVoltageRanges.lowCurrentRange || current > this.deviceManagementService.currentAndVoltageRanges.highCurrentRange))
					msg = 'shipment.current_error';
			}
			if(msg) {
				this.notificationMessage.setMessage(`translate|${msg}`, { clearOnXTimeNavigate: 1, timeout: 20000 });
				return;
			}
			this.checkDeviceConnectivity(device);
		});
	}

	checkDeviceConnectivity(device) {
		this.deviceManagementService.getDeviceInfo(device.mac_address).subscribe((res: any) => {
			const deviceInfo = res.config_info;
			if (!deviceInfo.connected)
				return this.notificationMessage.setMessage('translate|warnings.disconnected_device', { clearOnXTimeNavigate: 1, timeout: 20000 });

			return this.sendIndicatorLightCommand();
		});
	}

	sendIndicatorLightCommand() {
		this.deviceService.sendCommand(this.macAddress, 'indicatorLight', {}).subscribe((data: any) => {
			// if the api timed-out OR if wait sent and the device is disconnected.
			if (data.response_status == this.loader.resStatus.api_timeout || data.api_status == this.deviceService.deviceCommandsResponse)
				this.notificationMessage.setMessage('translate|warnings.disconnected_device', { type: 'danger', timeout: 10000, scrollToTop: true, clearOnXTimeNavigate: 1 });
			else
				this.indicatorLightModal.show();
		});
	}

	addToShipment() {
		let device = this.searchResult[0];

		if (this.devices[device.mac_address])
			return;

		device = {
			mac_address: device.mac_address,
			serial_number: device.serial_number,
			site_id: device.site_id,
			customer_id: this.sites[device.site_id].customer_id,
			status: this.DEVICE_STATUS.not_start.status,
			time_lost: device.time_lost,
			is_device_in_cm_devices: device.is_device_in_cm_devices,
		};

		this.devices = cloneDeep({ ...this.devices, [device.mac_address]: device });
		this.searchResult = [];
		this.searchString = '';
	}

	createInitialHistory() {
		let devices = Object.values(this.devices);
		if (!devices.length)
			return;

		if(this.shipmentOptions.historyReportId)
			return this.bulkCheck();


		this.deviceManagementService.createInitialHistory(this.shipmentOptions).subscribe((res: any) => {
			const reportId = res;
			this.shipmentOptions.historyReportId = reportId;

			this.bulkCheck();
		});
	}

	submitShipment(forceSubmit=false) {
		let devices = Object.values(cloneDeep(this.devices));
		if (!devices.length)
			return;

		if(this.hasFailures && !forceSubmit)
			return;

		this.notificationMessage.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
		return this.router.navigate(['/management/shipment-quality-history/', this.shipmentOptions.historyReportId]);
	}

	canMoveToBulk(device: { is_device_in_cm_devices: any; time_lost: any; mac_address: string, client_socket_type: number }) {
		return (
			device.is_device_in_cm_devices
			&& !device.time_lost
			&& (!this.fwDevicesStatus[device.mac_address]?.step)
			&& !this.devices[device.mac_address]
			&& device.client_socket_type == this.commonDataService.CLIENT_SOCKET_TYPE.WIFI
			&& !this.checkDeviceFwIsPrevented(device.mac_address)
		)
	}

	shouldShowShipmentDevices() {
		return Object.keys(this.devices).length;
	}

	receiveBtnDisabled(macAddress: string) {
		return [this.DEVICE_STATUS.in_progress.status, this.DEVICE_STATUS.completed.status].includes(this.devices[macAddress].status);
	}

	removeFromShipment(mac_address: string) {
		delete this.devices[mac_address];
		this.devices = cloneDeep(this.devices);
		this.hideReCheckFailureButton();
		if(this.shouldAllowSubmitShipment() || this.devicesHaveFWUpdate())
			this.disableSubmitButton = false;
	}

	showWarnings(mac_address: any) {
		if (!this.devices[mac_address].warnings)
			return;

		this.selectedDevice = this.devices[mac_address];
		this.warningDialog.show();
	}

	shouldAllowSubmitShipment() {
		const notAllowedSubmitStatus = [this.DEVICE_STATUS.not_start.status, this.DEVICE_STATUS.in_progress.status];
		return !Object.values(this.devices).some((device: any) => notAllowedSubmitStatus.includes(device.status));
	}

	devicesHaveFWUpdate() {
		return Object.values(this.fwDevicesStatus).some((item: any) => item?.step);
	}

	reCheckFailures() {
		for(const macAddress in this.devices) {
			if(this.checkFailuresConditions(macAddress))
				this.checkFWUpdateStatus(this.devices[macAddress]);
		}
		this.hasFailures = false;
	}

	hideReCheckFailureButton() {
		let hide = true;
		for(const macAddress in this.devices) {
			if(this.checkFailuresConditions(macAddress)) {
				hide = false;
				break;
			}
		}
		if(hide)
			this.hasFailures = false;
	}

	checkDeviceFwIsPrevented(macAddress) {
		return this.fwDevicesStatus[macAddress]?.status == this.DEVICE_STATUS.fw_update_prevented.status;
	}

	checkFailuresConditions(macAddress: string) {
		return (this.devices[macAddress].status != this.DEVICE_STATUS.not_start.status &&
				!this.receiveBtnDisabled(macAddress) &&
				this.devices[macAddress].status != this.DEVICE_STATUS.completed_gps_not_ready.status) ||
				this.fwDevicesStatus[macAddress]?.step;
	}

	bulkCheck(onlyRedirect=false) {
		if(onlyRedirect)
			return this.router.navigate(['/management/shipment-quality-history/', this.shipmentOptions.historyReportId]);
		this.disableSubmitButton = true;
		this.disableSearch = true;
		for(const macAddress in this.devices)
			if(this.devices[macAddress].status == this.DEVICE_STATUS.not_start.status)
				this.checkFWUpdateStatus(this.devices[macAddress]);
	}

	getStatuses() {
		let hasInprogress = false;
		let hasNotStart = false;
		let hasFWUpdate = false;
		let hasOtherStatus = false;
		for(const deviceId in this.devices) {
			const device = this.devices[deviceId];
			if(!hasInprogress && device.status == this.DEVICE_STATUS.in_progress.status)
				hasInprogress = true;
			if(!hasNotStart && device.status == this.DEVICE_STATUS.not_start.status)
				hasNotStart = true;
			if(!hasFWUpdate && this.fwDevicesStatus[deviceId]?.step)
				hasFWUpdate = true;
			if(!hasOtherStatus && ![this.DEVICE_STATUS.in_progress.status, this.DEVICE_STATUS.not_start.status].includes(device.status) && !this.fwDevicesStatus[deviceId]?.step)
				hasOtherStatus = true;
		}
		return { hasInprogress, hasNotStart, hasFWUpdate, hasOtherStatus };
	}

	isButtonDisabled() {
		const { hasInprogress, hasNotStart, hasFWUpdate, hasOtherStatus } = this.getStatuses();
		let flag = true;
		switch (true) {
			case (!hasInprogress && hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && !hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && !hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && !hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && !hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && !hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && !hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && !hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && !hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
		}
		return flag;
	}

	toggleButtons() {
		const { hasInprogress, hasNotStart, hasFWUpdate, hasOtherStatus } = this.getStatuses();
		let flag = false;
		switch (true) {
			case (!hasInprogress && hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && !hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && !hasNotStart && hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && !hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && !hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (hasInprogress && hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && !hasNotStart && !hasFWUpdate && hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (hasInprogress && !hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = false;
				break;
			case (!hasInprogress && !hasNotStart && hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
			case (!hasInprogress && !hasNotStart && !hasFWUpdate && !hasOtherStatus):
				flag = true;
				break;
		}
		return flag;
	}

	async handleUploadCSVFile(event) {
		const file = event.target.files[0];
		if (!file)
			return this.notificationMessage.setMessage('translate|ready_to_ship.no_selected_files', { clearOnXTimeNavigate: 1 });

		if(file.type  != 'text/csv') {
			event.target.value = '';
			this.labelImport.nativeElement.innerText = this.translate.instant('shipment.import_devices_csv');
			return this.notificationMessage.setMessage('translate|shipment.invalid_file_extension', {clearOnXTimeNavigate: 1});
		}

		this.labelImport.nativeElement.innerText = file.name;

		const text = (await file.text()).trim();
		let csvData: string[] = text.split("\n").map(item => item.replace('\r', '').trim()).filter(item => item && item != '');
		csvData = [...new Set(csvData)];

		if(!csvData.length) {
			event.target.value = '';
			this.labelImport.nativeElement.innerText = this.translate.instant('shipment.import_devices_csv');
			return this.notificationMessage.setMessage('translate|shipment.invalid_file_length', {clearOnXTimeNavigate: 1});
		}

		if(csvData.length > this.csvDataLimit) {
			event.target.value = '';
			this.labelImport.nativeElement.innerText = this.translate.instant('shipment.import_devices_csv');
			return this.notificationMessage.setMessage('translate|shipment.shpment_quality_csv_file_more_1500_records', {clearOnXTimeNavigate: 1});
		}

		const deviceIds = [];
		for (const row of csvData) {
			const macSerialObj = this.commonService.adjustMacSearchString(row);
			if (macSerialObj.mac_address)
				deviceIds.push(macSerialObj.mac_address);
			else
				deviceIds.push(macSerialObj);
		}

		this.deviceManagementService.searchDevices(deviceIds, this.searchBy, false, false, 'cm-check-warnings', true).subscribe((data: any) => {
			const fileName = this.translate.instant('shipment.import_shipment_quality_devices_csv');
			let dataToReturn = [];
			const response = map(data.devices, item => pick(item, ['mac_address', 'serial_number', 'shipment_quality_passed', 'site_id']));

			csvData.forEach((data) => {
				const status = this.getShipmentQualityStatusForCsv(data, response);
				dataToReturn.push({ device: data, status: this.translate.instant(status) });
			});

			new AngularCsv(dataToReturn, fileName);
		});
	}

	getShipmentQualityStatusForCsv(data: any, response: any) {
		let checkedDevices;

		if (data.length > 12) {
			const { mac_address, serial_number } = this.commonService.adjustMacSearchString(data);
			checkedDevices = response.find(item => item.serial_number == serial_number && item.mac_address == mac_address);
			return this.getStatus(checkedDevices, data);
		} else {
			checkedDevices = response.find(item => item.serial_number == data || item.mac_address == data);
			return this.getStatus(checkedDevices, data);
		}
	}

	getStatus(checkedDevices: any, data: any): string {
		if (checkedDevices) {
			if (checkedDevices.site_id != this.siteService.specialFolderId)
				return 'g.not_in_new_devices_site';

			if (checkedDevices.shipment_quality_passed == true)
				return 'shipment.passed_shipment_quality';

			return 'shipment.not_passed_shipment_quality';
		} else {
			return data.length > 12 ? 'g.device_sn_mac_mismatc' : 'g.no_data_found';
		}
	}

	resetRTCLostDetection() {
		this.deviceService.resetRTCLostDetection(this.resetRTCDevice).subscribe((response: any) => {
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.getDevices();
		});
	}
}
