import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-change-lang',
	templateUrl: './change-lang.component.html',
	styleUrls: ['./change-lang.component.css']
})
export class ChangeLangComponent implements OnInit {

	langList = {
		en: "English"
	};

	constructor(
		public translate: TranslateService
	) { }

	ngOnInit() {
	}

}