<div class="row col-12 justify-content-md-center">
	<div class="col-md-6 col-sm-12">
		<h2>{{'action_log.reverse_calibration' | translate}}</h2>
		<p *ngIf="device.config_info.calibration_reversed_at">
			<b>{{'settings.last_calibration_reverse' | translate}}</b>: {{device.config_info.calibration_reversed_at | date:'MM/dd/yyyy hh:mm:ss a'}}
		</p>
		<div class="my-4" *ngIf="device.config_info.reverse_calibration_type == 0 || device.config_info.reverse_calibration_type == null">
			<button (click)="reverseCalibration('without_data')" class="btn btn-primary col-6">{{'reverse_cal.reverse_only' | translate}}</button>
		</div>
		<div class="my-4" *ngIf="device.config_info.reverse_calibration_type == 0 || device.config_info.reverse_calibration_type == null">
			<button (click)="reverseCalibration('with_data')" class="btn btn-primary col-6">{{'reverse_cal.reverse_cal_and_data' | translate}}</button>
		</div>
		<div class="my-4" *ngIf="device.config_info.reverse_calibration_type != 0 && device.config_info.reverse_calibration_type != null">
			<button (click)="reverseCalibration()" class="btn btn-primary col-6">{{'g.cancel' | translate}}</button>
		</div>
	</div>
</div>