<app-left-panel ></app-left-panel>
<app-header ></app-header>
<div class="row justify-content-md-center ml-5">
	<div class="col-md-8 bg-white mt-2">
		<div class="border-bottom p-3">
			<h2>{{'login_register.register' | translate}}</h2>
		</div>
		<div class="px-5 py-4">
			<form #registerRequestForm="ngForm" (ngSubmit)="registerRequest()" validate-form="registerRequest">
				<div class="alert alert-warning">{{'login_register.required_fields_desc' | translate}}</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'login_register.first_name' | translate}}<b class="text-danger">*</b></strong>
					</div>
					<div class="col-md-8">
						<input #nameComponent class="form-control" name="first_name" [(ngModel)]="user.first_name" required autofocus/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'login_register.last_name' | translate}}<b class="text-danger">*</b></strong>
					</div>
					<div class="col-md-8">
						<input class="form-control" name="last_name" [(ngModel)]="user.last_name" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'login_register.email' | translate}}<b class="text-danger">*</b></strong>
					</div>
					<div class="col-md-8 validated-field">
						<input type="email" #email="ngModel" #emailComponent class="form-control" name="email" [(ngModel)]="user.email" email required (blur)="checkEmailValidation()"/>
						<div class="invalid-tooltip" *ngIf="email.touched && email.invalid">
							{{"g.fill_out_massage" | translate}}
						</div>
						 <div class="invalid-tooltip" *ngIf="email.touched && !isValidEmail">
							{{"g.invalid_email_format" | translate}}
						</div>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'login_register.phone_no' | translate}}<b class="text-danger">*</b></strong>
					</div>
					<div class="col-md-8">
						<input class="form-control" name="phone_number" [(ngModel)]="user.phone_number" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'login_register.company_name' | translate}}<b class="text-danger">*</b></strong>
					</div>
					<div class="col-md-8">
						<input class="form-control" name="company_name" [(ngModel)]="user.company_name" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
						<strong>{{'login_register.job_role' | translate}}<b class="text-danger">*</b></strong>
					</div>
					<div class="col-md-8">
						<input class="form-control" name="job_role" [(ngModel)]="user.job_role" required/>
					</div>
				</div>
				<div class="row mt-2 align-items-center">
					<div class="col-md-4">
					</div>
					<div class="col-md-8">
						<app-captcha #captchaComponent [textFieldCols]="5"></app-captcha>
					</div>
				</div>
				<div class="row my-4 align-items-center">
					<div class="col-md-4">
					</div>
					<div class="col-md-8">
						<div class="row align-items-center">
							<div class="col-md-8">
								<button
									type="submit"
									class="btn text-white main-blue-bg btn-lg col-5 mr-4"
									[disabled]="!captchaComponent.captchaText || captchaComponent.triesLimitReached"
								>
									{{'g.submit' | translate}}
								</button>
								<button type="button" class="btn text-white main-grey-bg btn-lg px-5" (click)="goBack()">{{'g.cancel' | translate}}</button>
							</div>
						</div>
					</div>
				</div>
			</form>
		</div>
	</div>
</div>
