import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from '../../shared/services/common-data.service';
import { AdminService } from '../admin.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import * as _ from 'lodash-es';

@Component({
	selector: 'app-permission-edit',
	templateUrl: './permission-edit.component.html',
	styleUrls: ['./permission-edit.component.css']
})
export class PermissionEditComponent implements OnInit {

	siteId: any;
	permissions: any = [];
	permissionId: number = 0;
	permissionMainKeys = [];
	permissionObj = null;
	readWriteOptions: any[] = [];
	modifiedPermissions: any[] = [];
	defaultPermissions: any = {};

	constructor(
		private route: ActivatedRoute,
		private commonData: CommonDataService,
		private router: Router,
		private adminService: AdminService,
		private translate: TranslateService,
		private notificationMessage: NotificationMessageService
	) {
		this.readWriteOptions = [
			{v: 'noAccess',	text: this.translate.instant('g.no_access')},
			{v: 'read', text: this.translate.instant('g.read_only')},
			{v: 'write', text: this.translate.instant('g.write')}
		];
		this.permissions = this.commonData.permissionFields;
		this.permissionMainKeys = _.keys(this.permissions);
		this.permissionId = Number(this.route.snapshot.params.permissionId);
		this.siteId = this.route.snapshot.queryParams['customerId'];
		this.getPermission();
	}

	ngOnInit() {
	}

	isObject(data) {
		if(data && typeof data == 'object')
			return true;
		
		return false;
	}

	updatePermission(){
		this.adminService.updatePermission(this.permissionId, this.permissionObj).subscribe(
			response => {
				this.setModifiedPermissions(this.permissionObj);
				this.notificationMessage.setMessage('globalSuccessMsg');
			}
		);
	}
		
	getPermission() {
		let type = (this.siteId ? 'enterprise' : 'user');
		this.adminService.getPermission(this.permissionId, {getDefaultPermission: true, type}).subscribe(
			(data: any) => {
				if(!data.permission || !data.id) {
					this.permissionObj = null;
					this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
					return;
				}
				this.defaultPermissions = data.defaultPermission;
				this.permissionObj	= data.permission;
				this.setModifiedPermissions(data.permission);

				for(let type in this.permissions) {
					let typePermissions = this.permissions[type];
					for(let typePermission in typePermissions) {
						if(this.isObject(typePermissions[typePermission])) {
							let typeKey = typePermissions[typePermission].key;
							let typeFields = typePermissions[typePermission].fields;
							if (!this.permissionObj.hasOwnProperty(typeKey)) {
								this.permissionObj[typeKey] = {};
							}
							for(let typeField of typeFields) {
								if (!this.permissionObj[typeKey][typeField]) {
									this.permissionObj[typeKey][typeField] = this.readWriteOptions[0].v;
								}
							}
							// if(Object.keys(typeFields).length == 0)
							// 	delete typePermissions[typePermission];
						} else {
							let typeField = typePermissions[typePermission];
							if (!this.permissionObj.hasOwnProperty(typeField)) {
								this.permissionObj[typeField] = this.readWriteOptions[0].v;
							}
						}
					}
				}
		});
	}

	setModifiedPermissions(permissions) {
		this.modifiedPermissions = [];
		for(let field in this.defaultPermissions) {
			if(this.defaultPermissions[field] != permissions[field]) {
				this.modifiedPermissions.push(field);
			}
		}
	}

	getKeys(obj) {
		return _.keys(obj); 
	}

	cancel() {
		if (this.siteId) {
			this.router.navigate(['/sites', this.siteId]);
			return;
		}

		this.router.navigate(['/admin-panel', 'users']);
	}
}