<table class="table table-stripped">
	<thead>
		<tr>
			<th class="red-text">{{'g.mac_address' | translate}}</th>
			<th>{{'devices.serial_number' | translate}}</th>
			<th>{{'devices.fw_version' | translate}}</th>
			<th>{{'devices.blocked_until' | translate}}</th>
			<th>{{'g.reason' | translate}}</th>
			<th>{{'g.action' | translate}}</th>
		</tr>
	</thead>
	<tbody *ngIf="reportData?.devices">
		<tr *ngFor="let device of reportData.devices; let idx = index">
			<td>
				<a [routerLink]="['/', device.customer_id, device.site_id, device.mac_address, 'settings']" [queryParams]="{tab: 'info'}" class="blue-hover pointer ml-1">{{device.mac_address}}</a>
			</td>
			<td>{{device.serial_number}}</td>
			<td>{{common.toFixedWithoutRounding(device.fw_version || 0, isAdmin ? 4 : 2)}}</td>
			<td>{{(+device.blocked_until * 1000) | date:'MM/dd/yyyy hh:mm:ss a':'UTC/GMT'}}</td>
			<td>{{('blocked_fw_reasons.'+(device.reason || 'na')) | translate}}</td>
			<td>
				<a (click)="unBlock(device.mac_address, idx, device.fw_version)" class="btn btn-warning">{{'g.unblock' | translate}}</a>
			</td>
		</tr>	
	</tbody>
</table>