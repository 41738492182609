<div class="d-flex mt-5 text-center flex-column">
	<div>
		<fa-icon class="fa-5x red-color" icon="ban" size="lg"></fa-icon>
	</div>
	<div>
		<h1>{{'unauth_page.unauthorized' | translate}}</h1>
		<h2>{{'unauth_page.msg1' | translate}}.</h2>
		<p>{{'unauth_page.msg2' | translate}}.</p>
		<a href="/">{{'g.back_to_homepage' | translate}}</a>
	</div>
</div>