<div class="main-black border-radius-bottom white-box mt-1" *ngIf="customer">
	<div class="card-body">
		<form #customerForm="ngForm" (ngSubmit)="approveCustomer()" validate-form="editCustomer" [innerModelGroups]="['customerInputs']">
			<div class="col-sm-12 card-body" *ngIf="customersList">
				<div class="row mt-2 align-items-center">
					<div class="col-md-3">
						<strong>{{'device_management.map_existing_customer' | translate}}</strong>
					</div>
					<div class="col-md-9">
						<ng-select
							[items]="customersList"
							bindLabel="customer_name"
							bindValue="id"
							placeholder="{{'device_management.Use New' | translate}}"
							name="customer_id"
							[(ngModel)]="customer.id"
							[clearable]="false"
							required
						></ng-select>
					</div>
				</div>
			</div>
			<app-customer-edit [customer]="customer" *ngIf="customer.id == customerId"></app-customer-edit>
			<div class="col-sm-12 card-body">
				<div class="row mt-2 align-items-center">
					<div class="col-md">
						<button type="submit" class="btn btn-primary mr-2">{{'g.submit' | translate}}</button>
						<button type="button" class="btn btn-default" (click)="goBack()">{{'g.cancel' | translate}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
