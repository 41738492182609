<div>
	<div class="d-flex align-items-center justify-content-between">
		<button class="btn btn-primary mb-3" [routerLink]="['/admin-panel/disconnected-site']" type="button">{{'connectivity.disconnected_sites_Report' | translate}}</button>
		<h2>{{'nav.disconnected_devices' | translate}}</h2>
		<div class="d-flex align-items-center gap-2">
			<p class="m-0">
				<span>{{'g.last_data_update_time' | translate}}</span>
				<span class="mx-2">{{lastUpdateTime}}</span>
			</p>
			<button class="btn btn-primary"  type="button" (click)="getDisconnectedDevicesReport()">{{'g.refresh' | translate}}</button>
		</div>
	</div>

	<div class="white-box p-4" *ngIf="disconnectedDevices && disconnectedDevices.length > 0; else noData">
		<app-grid
			*ngIf="disconnectedDevices.length !== 0"
			[data]="disconnectedDevices"
			[columns]="columnDefs"
			[sizeColumnsToFit]="true"
			[settings]="{hasExport: true, hasReset: false, pagination: true, paginationPageSize: pageSize}"
			#agGrid id="disconnected_devices_report"
			[fileName]="fileName"
		></app-grid>

	</div>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>
