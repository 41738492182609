import { Injectable } from '@angular/core';

export type GACommands = 'config' | 'get' | 'set' | 'event' | 'consent';
@Injectable()
export class GoogleAnalytics {

	constructor() {
	}
	sendEvent(command: GACommands = 'event', eventCategory, params) {
		(<any>window).gtag(command, eventCategory, params);
	}
}