import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MapsAPILoader } from '@agm/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class GoogleMapService {

	private mapBoxUrl = 'https://api.mapbox.com/search/geocode/v6/reverse';

	constructor(
		//private mapsAPILoader: MapsAPILoader,
		private httpClient: HttpClient
	) {
		//mapsAPILoader.load();
	}

	getAddressName(coords: marker) {
		// let latlng = new google.maps.LatLng(coords.lat, coords.lng);
		// let geocoder = new google.maps.Geocoder();
		// return Observable.create(observer => {
		// 	geocoder.geocode({'location': latlng}, (results, status) => {
		// 		if (status === google.maps.GeocoderStatus.OK && results[1]) {
		// 			let addressObj = this.parseAddressComponentObject(results[1].address_components);
		// 			let siteInfo = {};
		// 			if(addressObj['country']) {
		// 				siteInfo['country'] = addressObj['country'];
		// 			}
		// 			if(addressObj['state']) {
		// 				siteInfo['state'] = addressObj['state'];
		// 			}
		// 			siteInfo['address'] = results[0].formatted_address;

		// 			observer.next(siteInfo);
		// 			observer.complete();
		// 		} else {
		// 			observer.error();
		// 			observer.complete();
		// 		}
		// 	});
		// });
		return this.httpClient.get(this.mapBoxUrl, {
			params: {
				longitude: coords.lng,
				latitude: coords.lat,
				access_token: environment.mapbox.accessToken
			}
		});
	}

	parseAddressComponentObject(addressComponentArr) {
		let addressObj = {};
		addressComponentArr.forEach(function (address_component) {
			switch(address_component.types[0]) {
				case 'country':
					addressObj['country'] = address_component['long_name'];
				break;
				case 'administrative_area_level_1':
					addressObj['state'] = address_component['long_name'];
				break;
			}
		});
		return addressObj;
	}
}

interface marker {
	lat: number;
	lng: number;
}
