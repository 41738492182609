import { AfterViewInit, Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { AttachmentsService } from './attachments.service';
import { cloneDeep } from 'lodash-es';
import { NotificationMessageService } from '../notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { ModalComponent } from '../modal/modal.component';
import { BehaviorSubject } from 'rxjs';

@Component({
	selector: 'app-attachments',
	templateUrl: './attachments.component.html',
	styleUrls: ['./attachments.component.css']
})
export class AttachmentsComponent implements OnInit {

	@Input() source: string = null;
	@Input() sourceId: any;
	@Input() title: string;

	@ViewChild('addAttachmentsModal') addAttachmentsModal: ModalComponent;
	@ViewChild('deleteAttachmentsModal') deleteAttachmentsModal: ModalComponent;
	@ViewChild('editNoteModal') editNoteModal: ModalComponent;
	@ViewChild('successAttachmentsModal') successAttachmentsModal: ModalComponent;
	@ViewChild('uploadAttachmentsBtn') uploadAttachmentsBtn: ElementRef;

	attachments: any = {};
	deletedAttachmentId: number = null;
	editedNoteAttachment: any = {};
	note: string;
	selectedFiles: any = [];
	selectedFilesValidations: any = {};
	successAttachments: any = {};
	filesAlreadyUploaded: any = {};

	private successCounterSubject= new BehaviorSubject<number>(0);
	successCounter = this.successCounterSubject.asObservable();

	constructor(
		public attachmentsService: AttachmentsService,
		private notificationMessage: NotificationMessageService,
	) { }

	ngOnInit(): void {
		this.getAttachments();

		this.successCounter.subscribe((counter: number) => {
			if(this.selectedFiles.length && counter == this.selectedFiles.length) {
				this.note = '';
				this.selectedFiles = [];
				this.successAttachments = {};
				this.successAttachmentsModal.hide();
				return this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
			}
		});
	}

	showActionModal(action: string, attachmentId?: any) {
		switch(action) {
			case 'add':
				this.note = '';
				this.uploadAttachmentsBtn.nativeElement.value = "";
				this.selectedFiles = [];
				this.selectedFilesValidations = {};
				this.addAttachmentsModal.show();
				break;
			case 'edit':
				this.editedNoteAttachment = cloneDeep(this.attachments[attachmentId]);
				this.editNoteModal.show();
				break;
			case 'delete':
				this.deletedAttachmentId = attachmentId;
				this.deleteAttachmentsModal.show();
				break;
			default:
				break;
		}
	}

	selectFiles(events: any) {
		const oldFileNames = this.selectedFiles.map(file => file.name);
		const newFiles: any = Object.values(events.target.files)

		for (const file of newFiles) {
			if (!oldFileNames.includes(file.name))
				this.selectedFiles.push(file);
		}

		this.uploadAttachmentsBtn.nativeElement.value = '';
	}

	removeSelectedFile(removedFile: any) {
		this.selectedFiles = this.selectedFiles.filter(file => file.name != removedFile.name);
	}

	addAttachments() {
		if(!this.selectedFiles.length)
			return this.selectedFilesValidations = {...this.selectedFilesValidations, min_count: true};

		this.selectedFilesValidations = this.attachmentsService.validate({files: this.selectedFiles, note: this.note});
		if(Object.keys(this.selectedFilesValidations).length)
			return

		this.filesAlreadyUploaded = {};
		this.addAttachmentsModal.hide();
		this.successAttachmentsModal.show();

		let successCounter = 0;
		for (const file of this.selectedFiles) {
			const formData = new FormData();
			
			formData.append("file", file);
			formData.append('note', this.note);
			formData.append('source', this.source);
			formData.append('sourceId', this.sourceId);

			this.attachmentsService.addAttachments(formData).subscribe((attachment: any) => {
				switch (attachment.api_status) {
					case 3:
						this.filesAlreadyUploaded = cloneDeep({...this.filesAlreadyUploaded, [file.name]: true});
						break;
					default:
						this.attachments = cloneDeep({...this.attachments, [attachment.id]: attachment});
						this.successAttachments = cloneDeep({...this.successAttachments, [attachment.file_name]: true});
						this.successCounterSubject.next(++successCounter);
						break;
				}
			});
		}
	}

	getAttachments() {
		if(!this.sourceId || !this.source)
			return;
		
		this.attachmentsService.getAttachments(this.source, this.sourceId).subscribe((attachments: any) => {
			this.attachments = attachments;
		});
	}

	editAttachmentNote() {
		const {id, note} = this.editedNoteAttachment;
		this.attachmentsService.editAttachmentNote(this.source, this.sourceId, {id, note}).subscribe((data: any) => {
			this.attachments[id].note = note;
			this.editedNoteAttachment = {};
			this.editNoteModal.hide();
			return this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		});
	}

	deleteAttachment() {
		if(!this.deletedAttachmentId)
			return;

		this.attachmentsService.deleteAttachment(this.source, this.sourceId, this.deletedAttachmentId).subscribe((data: any) => {
			delete this.attachments[this.deletedAttachmentId];
			this.deletedAttachmentId = null;
			this.deleteAttachmentsModal.hide();
			this.attachments = cloneDeep(this.attachments);
			return this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		});
	}

	downloadAttachment(attachmentId: number) {
		if(!attachmentId)
			return;

		return this.attachmentsService.downloadAttachment(this.source, this.sourceId, this.attachments[attachmentId].file_name);
	}

	showAttachments() {
		return Object.keys(this.attachments).length;
	}

	showValidationErrors() {
		return Object.keys(this.selectedFilesValidations).length;
	}
}
