import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsersService } from '../../users/users.service';
import { CommonDataService } from '../services/common-data.service';
import { SideMenuService } from '../side-menu/side-menu.service';
import { LeftPanelService } from './left-panel.service';
import { CommonService } from '../services/common.service';

@Component({
	selector: 'app-left-panel',
	templateUrl: './left-panel.component.html',
	styleUrls: ['./left-panel.component.css']
})
export class LeftPanelComponent implements OnInit {

	@Input() visibleSideMenu: boolean;

	user: any = {};
	isProduction: boolean = false;
	isStaging: boolean = false;
	sideMenuHidden: boolean = false;
	hideHomeIcon: boolean = false;
	isChargLinkDomain: boolean = false;

	constructor(
		public usersService: UsersService,
		private commonData: CommonDataService,
		private sideMenuService: SideMenuService,
		public leftPanelService: LeftPanelService,
		private route: ActivatedRoute,
		private commonService: CommonService
	) { }

	ngOnInit(): void {
		this.isProduction = window.location.hostname == this.commonData.productionDomain;
		this.isStaging = window.location.hostname == this.commonData.stagingDomain;
		this.isChargLinkDomain = this.commonService.isChargeLink()
		this.usersService.currentUser.subscribe((user: any) => {
			if(user && Object.keys(user).length > 0) {
				this.user = user;
			}
		});

		this.sideMenuService.hideMenuView.subscribe(v=> {
			this.sideMenuHidden = v;
		});
		this.hideHomeIcon  = this.route.snapshot.firstChild?.data.hideItems;
	}

	userHasAdminLogsTab() {
		this.user = this.usersService.getCurrentUser(true);
		if (!this.user || !this.user.id)
			return false;

		return (
			this.usersService.hasAccessFunction('search_users') ||
			this.usersService.hasAccessFunction('registration_requests') ||
			this.usersService.hasAccessFunction('admin_contact_us') ||
			this.usersService.hasAccessFunction('access_error_logs') ||
			this.usersService.hasAccessFunction('access_action_logs') ||
			this.usersService.hasAccessFunction('access_rejection_logs') ||
			this.usersService.hasAccessFunction('sched_functions_control') ||
			this.usersService.hasAccessFunction('add_device') ||
			this.usersService.hasAccessFunction('dev_team') ||
			this.usersService.hasAccessFunction('site_management') ||
			this.usersService.hasAccessFunction('device_management') ||
			this.user.is_admin ||
			this.usersService.hasAccessFunction('show_warnings') ||
			this.usersService.hasAccessFunction('cm_check_warnings') ||
			this.usersService.hasAccessFunction('registration_reports') ||
			this.usersService.hasAccessFunction('shipment_management') ||
			this.usersService.hasAccessFunction('noc') ||
			this.user.has_access_to_any_study
		);
	}

	userHasAdminTab() {
		return (
			this.usersService.hasAccessFunction('search_users') ||
			this.user.is_admin ||
			this.usersService.hasAccessFunction('enterprise_management') ||
			this.usersService.hasAccessFunction('registration_requests') ||
			this.usersService.hasAccessFunction('show_warnings')
		);
	}

	userHasDeviceTab() {
		return (
			this.usersService.hasAccessFunction('add_device') ||
			this.usersService.hasAccessFunction('device_management') ||
			this.usersService.hasAccessFunction('dev_team') ||
			this.usersService.hasAccessPermission(null, 'noc') ||
			this.usersService.hasAccessFunction('site_management')
		);
	}

	userHasLogsTab() {
		return (
			this.usersService.hasAccessFunction('access_error_logs') ||
			this.usersService.hasAccessFunction('access_action_logs') ||
			this.usersService.hasAccessFunction('access_rejection_logs') ||
			this.usersService.hasAccessFunction('admin_contact_us') ||
			this.usersService.hasAccessPermission(null, 'noc')
		);
	}

	toggleSideMenu() {
		let menuView = this.sideMenuService.getHideMenuView();
		this.sideMenuService.setHideMenuView(!menuView);
	}
}
