import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { saveAs } from 'file-saver';
import { BehaviorSubject } from 'rxjs';
import { UsersService } from 'src/app/users/users.service';

@Injectable({
	providedIn: 'root'
})
export class AttachmentsService {

	route = '/attachments';
	readonly allowedUploadFilesExt: string[] = [
		'docx', 'doc',
		'xls', 'xlsx',
		'ppt', 'pptx',
		'txt', 'csv',
		'jpeg', 'png',
		'pdf',
	];

	hasDeleteAccess = this.usersService.hasAccessFunction('remove_attachments');

	readonly maxFilesCount: number = 10;
	readonly mbBytes: number = 1048576; // 1 MB in bytes
	readonly maxFileSize: number = 20 * this.mbBytes;
	readonly maxNoteCharacters: number = 200;

	public successCounterSubject = new BehaviorSubject<number>(0);
	successCounter = this.successCounterSubject.asObservable();

	constructor(
		private httpClient: HttpClient,
		private usersService: UsersService,
	) { }


	validate(data: {files:any[], note:string}) {
		let selectedFilesValidations = {};
		
		const { files, note } = data;

		if(files.length > this.maxFilesCount)
			selectedFilesValidations = {...selectedFilesValidations, max_count: true};

		if(note.length > this.maxNoteCharacters)
			selectedFilesValidations = {...selectedFilesValidations, note: true};
		
		for(const file of files) {
			const fileExtension = file.name.split('.').pop();
			
			if(!this.allowedUploadFilesExt.includes(fileExtension.toLowerCase()))
				selectedFilesValidations = {...selectedFilesValidations, extension: true};
			
			if(file.size > this.maxFileSize)
				selectedFilesValidations = {...selectedFilesValidations, size: true};
		}

		return selectedFilesValidations;
	}

	addAttachments(formData: FormData) {
		return this.httpClient.post(this.route + '/addAttachments', formData, {
			observe: "body"
		});
	}

	getAttachments(source: string, sourceId: any) {
		return this.httpClient.get(this.route + '/getAttachments', {
			observe: "body",
			params: {
				source,
				sourceId
			}
		});
	}

	editAttachmentNote(source: string, sourceId: any, attachment: any) {
		return this.httpClient.put(this.route + '/editAttachmentNote', {source, sourceId, attachment}, {
			observe: "body"
		});
	}

	deleteAttachment(source: string, sourceId: any, attachmentId: any) {
		return this.httpClient.delete(this.route + '/deleteAttachment', {
			observe: "body",
			params: {
				source,
				sourceId,
				attachmentId,
			}
		});
	}

	downloadAttachment(source: string, sourceId: any, attachmentName: string) {
		return this.httpClient.post(this.route + '/downloadAttachment', { source, sourceId, attachmentName }, {
			observe: "body",
		}).toPromise().then((res: any) => {
			if (!res)
				return;
			const arr = new Uint8Array(res.content.data);
			const blob = new Blob([arr]);
			if (blob)
				saveAs(blob, res.name);
		}).catch(err => console.error("download error = ", err))
	}

	downloadAttachments(source: string, sourceId: any, attachmentNames: string[], hasMultiSource=false) {
		return this.httpClient.post(this.route + '/downloadAttachments', { source, sourceId, attachmentNames, hasMultiSource }, {
			observe: "body",
		}).toPromise().then((res: any) => {
			if (!res)
				return;
			const arr = new Uint8Array(res.content.data);
			const blob = new Blob([arr]);
			if (blob)
				saveAs(blob, res.name);
		}).catch(err => console.error("download error = ", err))
	}
}
