<div>
	<div class="white-box p-4" *ngIf="locationReport && locationReport.length > 0; else noData">
		<h2>{{'nav.devices_location_mismatch' | translate}}</h2>
		<app-grid
			*ngIf="locationReport.length !== 0"
			[data]="locationReport"
			[columns]="columnDefs"
			[autoSizeColumns]="true"
			[settings]="{hasExport: true, hasReset: false, pagination: true, paginationPageSize: pageSize}"
			#agGrid id="location_report"
			[fileName]="fileName"
		></app-grid>

	</div>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>
