import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class NoteService {

	route = '/note';

	shareOptions = {
		public: 'public',
		private: 'private',
		sct: 'sct'
	};

	reminderFrequencyOptions = {
		none: 'none',
		daily: 'daily',
		weekly: 'weekly'
	};

	noteTypes = {
		user: 'user_note',
		system: 'system_note'
	};

	constructor(
		private httpClient: HttpClient
	) { }

	getModelNotes(modelId, modelType, options?) {
		return this.httpClient.post(this.route + '/getModelNotes', {modelId, modelType, options}, {
			observe: "body"
		});
	}

	addModelNotes(modelId, modelType, options) {
		return this.httpClient.post(this.route + '/addModelNotes', {modelId, modelType, options}, {
			observe: "body"
		});
	}

	deleteModelNotes(noteId, modelId, modelType, options?) {
		return this.httpClient.post(this.route + '/deleteModelNotes', {noteId, modelId, modelType, options}, {
			observe: "body"
		});
	}

	updateNOCNote(mac_address: string, site_id: number, note: string) {
		return this.httpClient.post(this.route + '/update-noc-status-note', { mac_address, site_id, note }, {
			observe: "body"
		});
	}

	getNOCNote(mac_address: string, site_id: number) {
		return this.httpClient.post(this.route + '/get-noc-status-note', { mac_address, site_id }, {
			observe: "body"
		});
	}

	updateReminderFreq(noteId, modelId, modelType, reminderFreq, options?) {
		return this.httpClient.post(this.route + '/updateReminderFreq', {noteId, modelId, modelType, reminderFreq, options}, {
			observe: "body"
		});
	}

	updateShareType(noteId, modelId, modelType, shareType, options?) {
		return this.httpClient.post(this.route + '/updateShareType', {noteId, modelId, modelType, shareType, options}, {
			observe: "body"
		});
	}

	getSystemNoteUserInfo() {
		return {
			id: 2147483647,
			first_name: 'System',
			last_name: 'User'
		};
	}
}
