import { Component, OnInit, OnDestroy } from '@angular/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from '../users.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
  selector: 'app-become-user',
  template: '',
})
export class BecomeUserComponent implements OnInit, OnDestroy {

	token: string;
	userSubscription: any;
	becomeUserSubscription: any;
	routeSubscription: any;
  	
	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private notificationMessage: NotificationMessageService,
		private usersService: UsersService,
		private commonService : CommonService
	) {
		this.routeSubscription = this.route.params.subscribe( params => {
			this.token = params.token;
		});
	}

	ngOnInit() {
		this.notificationMessage.closeNotification();

		let params = {
			params: this.token
		};
		this.becomeUserSubscription = this.usersService.becomeUser(params).subscribe(
			(res: any) => {
				if (res['err'] && res['err'] == 'logout_noaccess') {
					// go to login + show unauth
					this.notificationMessage.setMessage('globalAuthMsg');
					this.router.navigate(['/login']);
				} else {
					let user = res['res'];
					user.access_functions		= this.commonService.revertPermissionValue(user.access_functions);
					user.permissions.permission	= this.commonService.revertPermissionValue(user.permissions.permission);
					this.usersService.setCurrentUser(res['res']);
				}
				
			}
		);

		this.userSubscription = this.usersService.currentUser.subscribe(user=> {
			if (user && user.id) {
				this.router.navigate(['/']);
			}
		})

	}

	ngOnDestroy() {
		this.userSubscription.unsubscribe();
		this.routeSubscription.unsubscribe();
		this.becomeUserSubscription.unsubscribe();
	}

}
