import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { LeftPanelService } from 'src/app/shared/left-panel/left-panel.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { UsersService } from '../../users/users.service';

@Component({
  selector: 'app-login-non-confirmed-email',
  templateUrl: './login-non-confirmed-email.component.html',
  styleUrls: ['./login-non-confirmed-email.component.css']
})
export class LoginNonConfirmedEmailComponent implements OnInit, OnDestroy {

	userId: number = 0;

  	constructor(
  		private notificationMessage: NotificationMessageService,
  		private leftPanelService: LeftPanelService,
		private route: ActivatedRoute,
		private router: Router,
		private usersService : UsersService,
  	) {
  		leftPanelService.hide();
  	}

	ngOnInit() {
		this.route.params.subscribe(
			params => {
				this.userId = params['userId'] || 0;
			}
		);
		let user = this.usersService.getCurrentUser();
		if(user && user.id)
			this.goBack();
	}

	sendConfirmationEmail() {
		this.usersService.sendConfirmationEmail(this.userId).subscribe(
			(response) => {
				switch (response) {
					case 1:
						//Success
						this.notificationMessage.setMessage('translate|login_register.confirmation_email_sent', {type: 'success'});
					break;
					case 2:
						//Already confirmed
						this.notificationMessage.setMessage('translate|login_register.email_already_confirmed');
					break;
					case 3:
						this.notificationMessage.setMessage('translate|login_register.max_confirm_tries');
					break;
				}
				this.goBack();
			}
		);
	}

	goBack(){
		this.router.navigate(['/']);
	}

	ngOnDestroy(){
		this.leftPanelService.show();
	}
}
