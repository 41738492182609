import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { ColumnTypes, ColumnsConfig, TableConfig, TableData } from './custom-table-interface';
import * as moment from 'moment';
import { CommonService } from '../services/common.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'sct-custom-table',
	templateUrl: './custom-table.component.html',
	styleUrls: ['./custom-table.component.scss'],
})
export class SCTCustomTable implements OnInit, OnChanges {
	@Input() columnsConfig: ColumnsConfig[] = [];
	@Input() data: TableData[] = [];
	@Input() config: TableConfig = {
		hasPagination: false,
		pageSize: 20,
		hasExport: false,
		hasSelectionColumn: false,
		fitScreen: false,
		fileName: 'Exported Data'
	};
	@Output() onDatesChanged = new EventEmitter<Object>();
	@Output() updateSelectedRows = new EventEmitter<Object>();
	@Output() sortedDataToShow = new EventEmitter<any>();

	shownColumnsConfig: ColumnsConfig[] = [];
	draggedColumn = "";
	isHidePopupShown = false;
	selectAll = false;
	selectedRows = [];
	currentPage: number = 1;
	sort: any = { active: null, direction: '' };
	timeFilter: {[key: string]: {from: number | null, to: number | null}} = {};
	filters: {[key: string]: string} = {};
	booleanFilter: {[key: string]: any} = {};

	toShowData: any[] = [];
	sortResult: any[] = [];
	filteredData: TableData[] = [];

	columnsToSort: string[] = [];
	columnsToFilter: string[] = [];

	boolFields: string[] = [];
	timeFields: string[] = [];
	hasExportField: ColumnsConfig[] = [];
	stringOrNumberFields: string[] = [];
	hasFilterTypes: ColumnTypes[] = ['number', 'string', 'link', 'date', 'timestamp', 'date_with_ref', 'boolean', 'list', 'select', 'hover'];
	hideFromListTypes: ColumnTypes[] = ['id', 'select', 'icon', 'dropdown'];

	constructor(
		public commonService: CommonService,
		public translate: TranslateService,
	) {}

	ngOnInit(): void {
		this.sortResult = this.filteredData = this.data;
		this.prepareFieldLists();
		this.updatePageData();
	}

	ngOnChanges(changes) {
		if (changes.data) {
			this.sortResult = this.filteredData = changes.data.currentValue;
			this.updatePageData();
		}
	}

	prepareFieldLists() {
		if (this.config.hasSelectionColumn) {
			this.columnsConfig.unshift({key: "select", name: this.translate.instant("g.select"), type: "select", hasFilter: true});
			this.data.map(row => {
				row.select = {};
				row.select.selected = false
			});
		}

		for (let column of this.columnsConfig) {
			if (column.hasSort != false && !['icon' , 'dropdown', 'select'].includes(column.type))
				this.columnsToSort.push(column.key);

			if (column.hasFilter)
				this.columnsToFilter.push(column.key);

			if (column.type == 'boolean') {
				this.boolFields.push(column.key);
				this.booleanFilter[column.key] = [true, false];
			}

			if (['string', 'number', 'timestamp', 'link', 'hover'].includes(column.type))
				this.stringOrNumberFields.push(column.key);

			if (['date', 'date_with_ref'].includes(column.type))
				this.timeFields.push(column.key);

			if (['date', 'timestamp', 'date_with_ref'].includes(column.type))
				this.timeFilter[column.key] = { from: null, to: null };

			if (!('hidden' in column))
				column.hidden = false;

			if (!column.hidden && column.type != 'id')
				this.shownColumnsConfig.push(column);
		}
	}

	format(data: any, columnConfig: ColumnsConfig) {
		if (!data && (columnConfig.type == 'timestamp' || columnConfig.type == 'date'))
			return '--/--/--';

		if (!data && data == 0 && columnConfig.type == 'number')
			return data;

		if (columnConfig.type == 'boolean')
			return data ? columnConfig.filterTrueText || this.translate.instant('g.yes') : columnConfig.filterFalseText || this.translate.instant('g.no');

		if (!data)
			return '-';

		if (columnConfig.type == 'timestamp')
			return moment(data).utc().format(columnConfig.dateFormat || 'MM/DD/YYYY H:mm');

		return data;
	}

	onPageChange(event: { pageIndex: number; }) {
		this.currentPage = event.pageIndex + 1;
		this.updatePageData();
	}

	filter(column: ColumnsConfig, event: any) {
		const value = event.target ? String(event.target.value) : event.map(val => val.value);

		if (value)
			this.filters[column.key] = value;
		else
			delete this.filters[column.key];

		if (!Object.keys(this.filters).length) {
			this.filteredData = this.data;
			return this.sortData();
		}

		this.filteredData = this.data;

		for(const filter in this.filters)
			this.filteredData = this.filteredData.filter(row => this.rowFilter(row, filter));

		this.sortData();
	}

	rowFilter(row: any, field: string) {
		const rowValue = row[field].value;
		const filterValue = this.filters[field];
		const filterColumn = this.columnsConfig.filter(column => column.key == field)[0];

		if (!filterColumn)
			return true;

		// string filter
		if (['number', 'string', 'link', 'hover'].includes(filterColumn.type)) {
			if (!String(rowValue).toLowerCase().length)
				return false

			return String(rowValue).toLowerCase().includes(filterValue.toLowerCase());
		}

		// date filter
		if (['date', 'timestamp', 'date_with_ref'].includes(filterColumn.type)) {
			const rowTime = moment(rowValue).unix();
			const from = this.timeFilter[field].from;
			const to = this.timeFilter[field].to;

			if (!from && !to)
				return true;

			if (!to && from && moment(this.timeFilter[field].from).unix() <= rowTime)
				return true;

			if (!from && to && moment(this.timeFilter[field].to).endOf('day').unix() >= rowTime)
				return true;

			if (to && moment(this.timeFilter[field].to).endOf('day').unix() >= rowTime && from && moment(this.timeFilter[field].from).unix() <= rowTime)
				return true;

			return false;
		}

		if (filterColumn.type == 'boolean')
			return filterValue.length && filterValue.includes(rowValue) || !filterValue.length;

		if (filterColumn.type == 'list') {
			if (!rowValue.length && filterValue.trim().length)
				return false;

			return rowValue.filter(item => item && item.trim().toLowerCase().includes(filterValue.trim().toLowerCase())).length > 0;
		}

		return true;
	}

	sortData(sort: any = {active: null, direction: null}) {
		const data = this.filteredData.slice();

		// no sort params and no last sort params
		if ((!sort.active || sort.direction === '') && (!this.sort.active || this.sort.direction == '')) {
			this.sortResult = data;
			this.updatePageData();
			return;
		}

		if (sort.active && sort.direction != '')
			this.sort = sort;

		this.sortResult = data.sort((a, b) => this.sortFunction(this.sort, a, b));
		this.updatePageData();
	}

	updatePageData() {
		const { hasPagination, pageSize } = this.config;
		let dataToShow = this.sortResult;

		if (hasPagination) {
			const startIndex = (this.currentPage - 1) * pageSize;
			const endIndex = startIndex + pageSize;
			dataToShow = dataToShow.slice(startIndex, endIndex);
		}

		this.toShowData = dataToShow;
		this.sortedDataToShow.emit(this.toShowData);
	}

	sortFunction(sort: any, a: any, b: any) {
		const { compare } = this.commonService;
		const isAsc = sort.direction === 'asc';
		const field = sort.active;

		if (this.boolFields.includes(field))
			return compare(a[field].value ? 1 : 2, b[field].value ? 1 : 2, isAsc);

		if (this.timeFields.includes(field))
			return compare(this.formateDateForCompare(a[field].value), this.formateDateForCompare(b[field].value), isAsc);

		if (this.stringOrNumberFields.includes(field)) {
			const aValue = a[field]?.formattedValue || a[field].value;
			const bValue = b[field]?.formattedValue || b[field].value;
			return compare(aValue, bValue, isAsc);
		}

		return 0;
	}

	formateDateForCompare(date: moment.MomentInput) {
		return date ? moment(date).utc().format('YYYY/MM/DD hh:mm:ss a'): '-';
	}

	generateCsvFile() {
		const csvRows = [];
		const hasExportColumn = this.shownColumnsConfig.filter(column => column.hasExport !== false && !['dropdown', 'icon'].includes(column.type))
		const columnNames = hasExportColumn.map(column => column.name);
		csvRows.push(columnNames);

		(this.sortResult || []).forEach(row => {
			const rowArray: any[] = [];

			hasExportColumn.forEach(field => {
				rowArray.push(this.format(row[field.key].value, field));
			})

			csvRows.push(rowArray);
		});

		new AngularCsv(csvRows, this.config.fileName || 'Exported Data');
	}

	drag(event: any) {
		this.draggedColumn = event.currentTarget.textContent;
		event.dataTransfer.setData('text/plain', event.currentTarget.textContent);
		event.dataTransfer.effectAllowed = 'move';
	}

	drop(event: any) {
		event.preventDefault();
		let target = event.currentTarget;

		if (!target || target.tagName != 'TH')
			return;

		const data = this.draggedColumn;
		const thElements = Array.from(target.parentElement.children);
		const currentIndex = thElements.findIndex((th: any) => th.textContent === data);
		const dropIndex = thElements.findIndex(th => th === target);

		// update columns list
		if (currentIndex > -1 && dropIndex > -1 && currentIndex != dropIndex) {
			const toMoveColumn = this.shownColumnsConfig[currentIndex];
			const columnsList = [...this.shownColumnsConfig];
			columnsList.splice(currentIndex, 1);
			columnsList.splice(dropIndex, 0, toMoveColumn);
			this.shownColumnsConfig = [...columnsList];
		}
	}

	allowDrop(event: any) {
		event.preventDefault();
	}

	toggleHidePopup() {
		this.isHidePopupShown = !this.isHidePopupShown;
	}

	toggleColumnVisibility(column) {
		column.hidden = !column.hidden;

		const shownColumnsKeys = this.shownColumnsConfig.map(column => column.key);

		if (!column.hidden)
			return this.shownColumnsConfig = this.columnsConfig.filter(columnsConfig => columnsConfig.key == column.key || shownColumnsKeys.includes(columnsConfig.key));

		this.shownColumnsConfig = this.shownColumnsConfig.filter(columnsConfig => columnsConfig.key != column.key);
	}

	toggleSelectAll() {
		this.selectAll = !this.selectAll;

		if (this.selectAll)
			this.selectedRows = this.data;
		else
			this.selectedRows = [];

		this.data.map(row => row.select.selected = this.selectAll);
		this.updateSelectedRows.emit(this.selectedRows);
	}

	toggleSelectedRow(id) {
		const rows = this.data.filter(data => data.id.value == id);
		rows.map(row => row.select.selected = !row.select.selected);
		this.selectedRows = this.data.filter(data => data.select.selected == true);
		this.updateSelectedRows.emit(this.selectedRows);
	}
}
