
<div class="border-radius-bottom m-2 main-black p-4 white-box">
	<h3>{{'devices_without_location.title' | translate}}</h3>
	<hr/>

	<details open>
		<summary>{{'g.filter_box' | translate}}</summary>

		<div class="filter-box white-field col-12">
			<div class="custom-row">
				<!-- Customer Name -->
				<div class="filter">
					<label for="warning">{{'g.customers' | translate}}</label>
					<ng-select
						[(ngModel)]="filter.customers"
						[items]="customerList"
						name="tags_filter"
						bindValue="key"
						bindLabel="value"
						[clearable]="false"
						[multiple]="true"
						[ngStyle]="{'width': '300px'}"
						(ngModelChange)="selectCustomer()"
					></ng-select>
				</div>
			</div>
		
			<div class="custom-row align-items-end">
				<!-- Device Serial Number -->
				<div class="filter">
					<label for="warning">{{'g.devices' | translate}}</label>
					<ng-select
						*ngIf="filter.customers"
						[(ngModel)]="searchedDevices"
						[items]="customerDevicesList"
						name="tags_filter"
						bindValue="key"
						bindLabel="value"
						[clearable]="false"
						[multiple]="true"
						[ngStyle]="{'width': '300px'}"
						(change)="addDevice()"
					></ng-select>
					<input class="form-control" *ngIf="!filter.customers" type="text" [(ngModel)]="searchedDevices" >
				</div>
		
				<div class="filter" *ngIf="!filter.customers">
					<button class="btn btn-primary" (click)="addDevice()">{{"g.add" | translate}}</button>
				</div>
			</div>
			<label class="mt-3" for="warning">{{'g.devices_list' | translate}}</label>
			<ul class="d-flex flex-wrap device-list">
				<li *ngFor="let device of this.filter.devices">{{device.value}}</li>
			</ul>

			<div class="custom-row align-items-end">
				<div class="filter">
					<button class="btn btn-primary" (click)="getDevicesWithoutLocation()">{{"g.search" | translate}}</button>
				</div>
			</div>

		</div>
	</details>

	<div class="d-flex justify-content-between">
		<div>
			<b class="text-warning">* {{'devices_without_location.note1' | translate}}</b><br>
			<b class="text-warning">* {{'devices_without_location.note2' | translate}}</b>
		</div>

		<button *ngIf="devices.length" class="btn btn-primary btn-sm mx-2" [disabled]="!selectedDevices.length" (click)="showConfirmationModal()">
			{{'devices.disable_xtra' | translate}}
		</button>
	</div>

	<div *ngIf="devices.length; else noData">
		<sct-custom-table
			[columnsConfig]="columnConfig"
			[data]="devices"
			[config]="tableConfig"
			(updateSelectedRows)="getSelectedDevices($event)"
		></sct-custom-table>
	</div>
	
	<ng-template #noData>
		<div class="alert alert-info mt-3">{{'g.no_data_to_show' | translate}}</div>
	</ng-template>	
</div>


<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
	<p>{{'devices_without_location.confirm_action'| translate: { "devicesCount": selectedDevices.length } }}</p>
</app-modal>