<div class="row col-12 justify-content-md-center">
	<div class="col-md-12">
		<div class="row">
			<div *ngFor="let server of schedFunctions | keys" class="col-md-{{12/numberOfServers}}">
				<h2>{{server}}</h2>
				<div class="row">
					<div class="col-md-8">Function Name</div>
					<div class="col-md-4">Status</div>
				</div>
				<div class="row mt-3" *ngFor="let function of schedFunctions[server]">
					<div class="col-md-8">{{function.prettyName}}</div>
					<div class="col-md-4">
						<mat-slide-toggle [(ngModel)]="function.is_active"></mat-slide-toggle>	
					</div>
				</div>
			</div>
		</div>
		<div class="row mt-5">
			<div class="col-md-12">
				<button type="button" class="btn btn-lg btn-primary" (click)="updateSchedFunctionsStatus()">Submit</button>
			</div>
		</div>
	</div>
</div>