<google-chart
	[type]="chartSpecs.chartType"
	[data]="data"
	[columns]="columnNames"
	[title]="title"
	[options]="chartSpecs.options"
	[formatters]="rawFormatter"
	(ready)="onReady($event)"
	(select)="onSelect($event)"
	style="width: 100%;"
></google-chart>