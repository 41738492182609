import { AfterViewInit, Component, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { ServerPaginationComponent } from 'src/app/shared/server-pagination/server-pagination.component';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-update-firmware-logs',
	templateUrl: './update-firmware-logs.component.html',
	styleUrls: ['./update-firmware-logs.component.css']
})
export class UpdateFirmwareLogsComponent implements AfterViewInit {

	logs: any;
	limit = 20;
	filter = {
		macAddress: null
	};
	@ViewChild("serverPagination") serverPagination!: ServerPaginationComponent;

	firmwareUpdateStatuses = {
		1: 'start',
		2: 'ongoing',
		3: 'done'
	}

	constructor(
		private deviceManagementService: DeviceManagementService,
		public usersService: UsersService
	) { }

	ngAfterViewInit() {
		this.getFirmwareUpdateLog(false, true);
	}

	getFirmwareUpdateLog(isBack = false, firstTime = false) {
		let lastId = this.serverPagination.lastId;
		if (isBack)
			lastId = this.serverPagination.firstId;
		if (firstTime) {
			this.serverPagination.init();
			lastId = 0;
		}
		const filter = this.filter;
		this.deviceManagementService.getUpdateFirmwareLog({ lastId, isBack, filter }).subscribe((response: any) => {
			this.logs = [...response];
			if (this.logs.length > this.limit)
				this.logs.pop();

			this.serverPagination.updatePagination(response, isBack, firstTime);
		});
	}
}
