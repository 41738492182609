<div class="mt-2 mb-2 ml-2 mr-2">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'sim.iccid' | translate}}</th>
				<th>{{'g.mac_address' | translate}}</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let iccid of sims">
			<td>{{iccid}}</td>
			<td>
				<a *ngIf="devices[iccid]" [routerLink]="['/', sites[devices[iccid].site_id]?.customer_id, devices[iccid].site_id, devices[iccid].mac_address, 'performance']" class="blue-hover pointer ml-1">
					{{devices[iccid].mac_address}}
				</a>
			</td>
		</tr>
		</tbody>
	</table>
</div>
