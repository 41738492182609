import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import * as moment from 'moment';
import { UsersService } from 'src/app/users/users.service';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
	selector: 'sim-usage-management',
	templateUrl: './sim-usage-management.component.html',
	styleUrls: ['./sim-usage-management.component.css']
})
export class simUsageManagementComponent implements OnInit, OnDestroy {
	@ViewChild("tableDataPopup") tableDataPopup: ModalComponent;
	@ViewChild("exportFilePopup") exportFilePopup: ModalComponent;
	@ViewChild("disabledSimsPopup") disabledSimsPopup: ModalComponent;
	@ViewChild("blockedSimsPopup") blockedSimsPopup: ModalComponent;
	@ViewChild("blockedSimsConfirmationPopup") blockedSimsConfirmationPopup: ModalComponent;
	@ViewChild("actionsConfirmationPopup") actionsConfirmationPopup: ModalComponent;

	allColumns: { sortHeader: string, translationKey: string }[] = [
		{ sortHeader: 'serial_number', translationKey: 'devices.serial_number' },
		{ sortHeader: 'mac_address', translationKey: 'g.mac_address' },
		{ sortHeader: 'customer_name', translationKey: 'g.customer_name' },
		{ sortHeader: 'site_name', translationKey: 'g.site_name' },
		{ sortHeader: 'cellular_iccid', translationKey: 'qv.cellular_iccid' },
		{ sortHeader: 'data_usage', translationKey: 'sim_management.data_usage' },
		{ sortHeader: 'remaining_usage', translationKey: 'sim_management.remaining_usage' },
		{ sortHeader: 'weeklyUsage', translationKey: 'sim_management.week_average' },
		{ sortHeader: 'monthlyUsage', translationKey: 'sim_management.month_average' },
		{ sortHeader: 'updating_fw', translationKey: 'sim_management.updating_fw' },
		{ sortHeader: 'imei', translationKey: 'sim.imei' },
		{ sortHeader: 'iccid_status', translationKey: 'sim.iccid_status' },
		{ sortHeader: 'imei_lock', translationKey: 'sim.imei_status' },
		{ sortHeader: 'fw_version', translationKey: 'g.fw_version' },
		{ sortHeader: 'debug_enabled', translationKey: 'sim_management.debug_enabled' },
		{ sortHeader: 'metered_device', translationKey: 'sim_management.metered_device' },
		{ sortHeader: 'last_connect_time', translationKey: 'devices.last_connect_time' },
		{ sortHeader: 'usage_date', translationKey: 'sim_management.usage_date' },
		{ sortHeader: 'expiry_date', translationKey: 'sim_management.expiry_date' },
		{ sortHeader: 'reconnect_time', translationKey: 'devices.cellular_reconnect_time' },
		{ sortHeader: 'installation_date', translationKey: 'devices.installation_date' },
	];

	hiddenColumns = ['remaining_usage', 'weeklyUsage', 'monthlyUsage', 'imei', 'imei_lock','last_connect_time', 'usage_date', 'expiry_date', 'installation_date'];
	columns: { sortHeader: string, translationKey: string }[] = [];

	isHidePopupShown = false;
	draggedColumn = "";

	selectedDeviceData: any;
	toShowUsageHistory: any = [];
	devices: any = [];
	disabledSims: any = [];
	blockedSims: any = [];
	selectedICCIDs: number[] = [];
	selectedBlockedICCIDs: string[] = [];
	doneBlockICCIDs: string[] = [];
	blockActionDone: boolean = false;
	selectedDisabledICCIDs: string[] = [];
	sortResult: any[] = [];
	weeklyUsage: any[] = [];
	monthlyUsage: any[] = [];
	actionReason: string = '';

	pageSizeOptions: number[] = [100];
	pageSize: number = this.pageSizeOptions[0];
	operation: 'enable' | 'disable' | 'lock' | 'unlock' | undefined;
	ICCIDs = [];

	unblockReason = '';
	currentPage: number = 1;
	toShowDevices: any = [];

	fromDate: Date = null;
	toDate: Date = null;

	popupFromData: Date = new Date();
	popupToData: Date = new Date();

	notValidDate = false;
	notValidPopupDate = false;

	simOverUsageLimit140K = 140 * 1024;
	simOverUsageLimit1M = 1024 * 1024;
	simOverUsageLimit3M = 1024 * 1024 * 3;
	simOverUsageLimit5M = 1024 * 1024 * 5;

	usageConfig = ['5M', '3M', '1M', '140K'];

	disabledSources = {
		1: 'sim.disable_system',
		2: 'sim.disable_1nce'
	};

	chartObject: any = {
	formatterType: 'DateFormat',
	formatter: {pattern: "MM/dd"},
		columnNames: ['Date', 'Data Usage'],
	};

	boolFields = ['imei_lock', 'updating_fw', 'debug_enabled', 'metered_device'];
	timeFields = ['last_connect_time', 'usage_date', 'expiry_date', 'installation_date'];
	stringFields = ['cellular_iccid', 'mac_address', 'serial_number', 'customer_name', 'site_name', 'imei', 'iccid_status', 'fw_version'];
	numberFields = ['weeklyUsage', 'monthlyUsage', 'usage', 'data_usage', 'reconnect_time', 'remaining_usage'];

	constructor(
		private router: Router,
		private userService: UsersService,
		private deviceService: DeviceService,
		private notificationMessage: NotificationMessageService,
		private commonService: CommonService,
		private commonData: CommonDataService,
		public translate: TranslateService,
	) {}

	ngOnDestroy(): void {
		this.disabledSimsPopup.hide();
		this.blockedSimsPopup.hide();
		this.blockedSimsConfirmationPopup.hide();
		this.tableDataPopup.hide();
		this.exportFilePopup.hide();
	}

	ngOnInit() {
		if (!this.userService.hasAccessPermission(null, 'noc', 'write'))
			this.router.navigate(['/unauthorized']);

		this.columns = this.allColumns.filter(column => !this.hiddenColumns.includes(column.sortHeader));
		const isDev = window.location.hostname.includes(this.commonData.developmentDomain);

		if (isDev) {
			this.pageSizeOptions = [4];
			this.pageSize = this.pageSizeOptions[0];
		}

		//one day shift because we show yesterday usage
		this.popupToData.setDate(this.popupToData.getDate() - 1);
		this.popupFromData.setDate(this.popupFromData.getDate() - 31);

		this.getSimDevicesData();
	}

	confirmUnblock() {
		this.unblockSims();
		return ;
	}

	ngAfterViewInit() {
		this.actionsConfirmationPopup.onClose.subscribe((ok) => {
			this.actionsConfirmationPopup.closeOnConfirm = true;
			if(!ok) {
				this.actionReason = '';
				return;
			}

			if(!this.actionReason) {
				this.actionsConfirmationPopup.closeOnConfirm = false;
				return;
			}

			this.updateSimConfig(this.operation);
			this.actionReason = '';
		});

		this.blockedSimsPopup.onClose.subscribe((ok) => {
			this.selectedBlockedICCIDs = [];
		});

		this.disabledSimsPopup.onClose.subscribe((ok) => {
			this.selectedDisabledICCIDs = [];
		});
	}

	getSimDevicesData() {
		this.deviceService.getSimDevicesData().subscribe((response: any) => {
			this.weeklyUsage = response.weeklyUsage;
			this.monthlyUsage = response.monthlyUsage;

			this.devices = this.formateDeviceUsageData(response.sims);
			this.disabledSims = response.disabledSims;
			this.blockedSims = response.blockedSims;
			this.sortResult = this.devices;
			this.updateDevices();
		})
	}

	formateDeviceUsageData(response) {
		const formateDevicesData = {};

		if (!response)
			return formateDevicesData;

		const sortedResponse = this.sortByDataAndUsage(response);

		for(let row in sortedResponse) {
			const rowData : any = response[row];
			rowData.usage_date = this.formateUsageDate(rowData.usage_date);
			rowData.expiry_date = this.formateUsageDate(rowData.expiry_date);

			if (!Object.keys(formateDevicesData).includes(rowData.mac_address)) {
				formateDevicesData[rowData.mac_address] = rowData;
				formateDevicesData[rowData.mac_address]['weeklyUsage'] = ((this.weeklyUsage[rowData.mac_address]?.average || 0) / 1024 ).toFixed(2);
				formateDevicesData[rowData.mac_address]['monthlyUsage'] = ((this.monthlyUsage[rowData.mac_address]?.average || 0) / 1024).toFixed(2);
			}
		}

		return [...Object.values(formateDevicesData)];
	}

	checkUsageLevelForPopupData(usage: number) {
		for(const config of this.usageConfig) {
			if(usage > this[`simOverUsageLimit${config}`])
				return config.slice(0, config.length - 1);
		}
		return 0;
	}

	showExportFilePopup() {
		this.fromDate = null;
		this.toDate = null;
		this.notValidDate = false;
		this.exportFilePopup.show();
	}

	generateMainTableCsvFile() {
		const csvRows = [];
		const columnNames = []
		this.columns.forEach(column => {
			columnNames.push(this.translate.instant(column.translationKey));
		})

		csvRows.push(columnNames);

		(this.sortResult || []).forEach(row => {
			const rowArray = [];

			this.columns.forEach(column => {
				let value = this.getFieldValue(row, column);
				rowArray.push(value)
			});

			csvRows.push(rowArray);
		})

		new AngularCsv(csvRows, this.translate.instant('sim.sim_usage_management'));
		this.exportFilePopup.hide();
		this.fromDate = null;
		this.toDate = null;
	}

	getFieldValue(row, column) {
		let value = row[column.sortHeader];

		switch (column.sortHeader) {
			case 'mac_address':
			case 'serial_number':
			case 'customer_name':
			case 'site_name':
			case 'iccid_status':
			case 'usage_date':
			case 'reconnect_time':
			case 'imei':
			case 'fw_version':
				value = value || '-';
				break;

			case 'cellular_iccid':
				value = value ? `\t${value}` : '-';
			break;

			case 'data_usage':
			case 'remaining_usage':
				value = (value / 1024).toFixed(2);
				break;

			case 'lastWeekUsage':
				value = this.getAverage(value || [], 7);
				break;

			case 'lastMonthUsage':
				value = this.getAverage(value || [], 30);
				break;

			case 'updating_fw':
			case 'debug_enabled':
			case 'metered_device':
				value = this.translate.instant(value ? 'g.yes' : 'g.no');
				break;

			case 'imei_lock':
				value = this.translate.instant('g.' + (row.imei_lock ? 'locked' : 'unlocked'));
				break;

			case 'last_connect_time':
				value = this.formateDate(row.last_connect_time);
				break;

			case 'expiry_date':
			case 'installation_date':
				value = this.formateDate(value, 'MM/DD/YYYY');
				break;

			default:
				value = value || '-';
				break;
		}

		return value;
	}

	generateDeviceHistoryCsvFile() {
		const csvRows = [];
		const columnNames = [
			this.translate.instant('sim_management.data_usage'),
			this.translate.instant('sim_management.usage_date'),
		];

		csvRows.push(columnNames);
		(this.toShowUsageHistory || []).forEach(row => {
			const rowArray = [
				(row.data_usage / 1024).toFixed(2),
				moment(row.usage_date).utc().format('MM/DD/YYYY')
			];

			csvRows.push(rowArray);
		})

		new AngularCsv(csvRows, this.translate.instant('sim.sim_usage_management'));
	}

	sortByDataAndUsage(DataArray) {
		DataArray.sort((a, b) => {
			const aTime = moment(a.usage_date);
			const bTime = moment(b.usage_date);

			if (aTime.isSame(bTime, 'day')) {
				return b.data_usage - a.data_usage;
			}

			return bTime.unix() - aTime.unix();
		});

		return DataArray;
	}

	formateDate(date: moment.MomentInput, format = 'MM/DD/YYYY hh:mm:ss a') {
		return date && date != '---' ? moment(date).utc().format(format || 'MM/DD/YYYY hh:mm:ss a') : '---';
	}

	formateUsageDate(date: moment.MomentInput) {
		return date && date != '---' ? moment(date).utc().format('MM/DD/YYYY') : '---';
	}

	prepareChartData(usageHistory) {
		const data = [];

		if (!usageHistory.length)
			return [];

		usageHistory.forEach(dayData => {
			const usage = +(dayData.data_usage / 1024).toFixed(0);
			const formattedTime = moment(dayData.usage_date).utc().format('D MMM');
			data.push([formattedTime, usage]);
		})

		return data;
	}

	getAverage(numbers, days) {
		if (!numbers.length) return (0).toFixed(2);

		const sum = numbers.reduce((acc, num) => acc + num, 0);
		return ((sum / days) / 1024).toFixed(2);
	}

	updateDevices() {
		const startIndex = (this.currentPage - 1) * this.pageSize;
		const endIndex = startIndex + this.pageSize;
		this.toShowDevices = (this.sortResult || []).slice(startIndex, endIndex);
	}

	onPageChange(event) {
		this.currentPage = event.pageIndex + 1;
		this.updateDevices();
	}

	showUsageHistory(deviceData, openPopup = true) {
		let zoneDiff = new Date().getTimezoneOffset() * -1;

		let from:any = new Date(new Date(this.popupFromData).getTime() + (zoneDiff * 60 * 1000));
		let to:any = new Date(new Date(this.popupToData).getTime() + (zoneDiff * 60 * 1000));

		from = moment(from).utc().startOf('day').unix();
		to = moment(to).utc().endOf('day').unix();

		if (this.popupFromData && from > to)
			return this.notValidPopupDate = true;

		this.notValidPopupDate = false;

		this.deviceService.getSimUsageHistory(deviceData.mac_address, from, to).subscribe((response: any) => {
			this.selectedDeviceData = deviceData;
			this.toShowUsageHistory = this.sortByDataAndUsage(response);
			const data = this.toShowUsageHistory.length ? this.prepareChartData(this.toShowUsageHistory) : [];

			this.chartObject.data = data.reverse();

			if (openPopup)
				this.tableDataPopup.show();
		})
	}

	showUpdateConfigPopup(operation: 'enable' | 'disable' | 'lock' | 'unlock', fromPopup=false) {
		this.operation = operation;
		this.ICCIDs = fromPopup ? this.selectedDisabledICCIDs : this.selectedICCIDs;
		this.actionsConfirmationPopup.show();
	}

	updateSimConfig(operation: 'enable' | 'disable' | 'lock' | 'unlock') {
		this.deviceService.updateSimConfig(this.ICCIDs, operation, this.actionReason).subscribe((res: any) => {
			if (res.api_status == 2)
				return this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});

			return this.notificationMessage.setMessage('translate|sim_management.action_will_applied_tomorrow', {clearOnXTimeNavigate: 1, type: 'success'});
		});
	}

	unblockSims() {
		let ICCIDs = this.selectedBlockedICCIDs;

		if (!ICCIDs.length)
			return;

		this.deviceService.unblockSims(ICCIDs, this.unblockReason).subscribe((res: any) => {
			if (res.api_status == 2)
				return this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});

			this.blockActionDone = true;
			this.doneBlockICCIDs = res;

			return this.notificationMessage.setMessage('translate|sim_management.action_will_applied_tomorrow', {clearOnXTimeNavigate: 1, type: 'success'});
		});
	}

	get shouldShowSimActions() {
		return this.userService.hasAccessPermission(null, 'noc', 'write');
	}

	selectICCID(row) {
		if (this.selectedICCIDs.includes(row.cellular_iccid)) {
			this.selectedICCIDs = this.selectedICCIDs.filter(iccidIndex => iccidIndex != row.cellular_iccid);
			return;
		}

		this.selectedICCIDs.push(row.cellular_iccid);
		return;
	}

	selectBlockedICCID(iccid: string) {
		if (this.selectedBlockedICCIDs.includes(iccid))
			return this.selectedBlockedICCIDs = this.selectedBlockedICCIDs.filter(id => id != iccid);

		return this.selectedBlockedICCIDs.push(iccid);
	}

	selectDisabledICCID(iccid: string) {
		if (this.selectedDisabledICCIDs.includes(iccid))
			return this.selectedDisabledICCIDs = this.selectedDisabledICCIDs.filter(id => id != iccid);

		return this.selectedDisabledICCIDs.push(iccid);
	}

	sortData(sort: any) {
		const data = this.devices.slice();

		if (!sort.active || sort.direction === '') {
			this.sortResult = data;
			return;
		}

		this.sortResult = data.sort((a, b) => this.sortFunction(sort, a, b));
		this.currentPage = 1;
		this.updateDevices()
	}

	sortDeviceData(sort: any) {
		if(!this.toShowUsageHistory.length)
			return;

		if (!sort.active || sort.direction === '')
			return;

		const data = this.toShowUsageHistory.slice();
		this.toShowUsageHistory = data.sort((a, b) => this.sortFunction(sort, a, b));
	}

	sortFunction(sort: any, a:any, b:any) {
		const { compare } = this.commonService;

		const isAsc = sort.direction === 'asc';
		const field = sort.active;

		if (this.boolFields.includes(field))
			return compare(a[field] ? 1 : 2, b[field] ? 1 : 2, isAsc);

		if (this.timeFields.includes(field))
			return compare(this.formateDateForCompare(a[field]), this.formateDateForCompare(b[field]), isAsc);

		if (this.stringFields.includes(field)) {
			const aValue = (String(a[field]) || '').toLowerCase();
			const bValue = (String(b[field]) || '').toLowerCase();

			return compare(aValue, bValue, isAsc);
		}

		if (this.numberFields.includes(field)) {
			const aValue = +a[field] || 0;
			const bValue = +b[field] || 0;

			return compare(aValue, bValue, isAsc);
		}

		return 0
	}

	formateDateForCompare(date: moment.MomentInput) {
		return date ? moment(date).utc().format('YYYY/MM/DD HH:mm:ss'): '-';
	}

	showDisabledSims() {
		this.disabledSimsPopup.show();
	}

	showBlockedSims() {
		this.selectedBlockedICCIDs = [];
		this.blockedSimsPopup.show();
	}

	showBlockedSimsConfirmationPopup() {
		this.unblockReason = '';
		this.blockActionDone = false;
		this.blockedSimsConfirmationPopup.show();
	}

	checkDateValidation() {
		if (this.fromDate && this.toDate && moment(this.fromDate).unix() > moment(this.toDate).unix())
			this.notValidDate = true;
		else
			this.notValidDate = false;
	}

	showUserTimeZoneReference() {
		const userTimeZone = moment().utcOffset();
		return moment().utcOffset(userTimeZone).format('UTCZ');
	}

	drag(event: any) {
		this.draggedColumn = event.target.textContent;
		event.dataTransfer.setData('text/plain', event.target.textContent);
		event.dataTransfer.effectAllowed = 'move';
	}

	drop(event: any) {
		event.preventDefault();
		let target = event.target;

		if (!target || target.tagName != 'TH')
			return;

		const data = this.draggedColumn;
		const thElements = Array.from(target.parentElement.children);
		const currentIndex = thElements.findIndex((th: any) => th.textContent === data);
		const dropIndex = thElements.findIndex(th => th === target);

		// update columns list
		if (currentIndex > -1 && dropIndex > -1 && currentIndex != dropIndex) {
			const toMoveColumn = this.columns[currentIndex - 1];
			const columnsList = [...this.columns];
			columnsList.splice(currentIndex - 1, 1);
			columnsList.splice(dropIndex - 1, 0, toMoveColumn);
			this.columns = [...columnsList];
		}
	}

	allowDrop(event: any) {
		event.preventDefault();
	}

	toggleHidePopup() {
		this.isHidePopupShown = !this.isHidePopupShown;
	}

	toggleColumnVisibility(column) {
		if (this.hiddenColumns.includes(column.sortHeader))
			this.hiddenColumns = this.hiddenColumns.filter(hiddenCol => hiddenCol != column.sortHeader);

		else
			this.hiddenColumns.push(column.sortHeader);

		this.columns = this.allColumns.filter(column => !this.hiddenColumns.includes(column.sortHeader));
	}

	getClass(row, column) {
		return {
			['color-' + row.log_level]: column.sortHeader === 'data_usage'
		};
	}
}
