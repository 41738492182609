import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { DeviceManagementService } from '../device-management.service';

@Component({
	selector: 'app-reverse-rt',
	templateUrl: './reverse-rt.component.html',
})
export class ReverseRtComponent implements OnInit {

	@Input() device: any = {};

	startId;
	endId;

	constructor(
		private deviceManagementService: DeviceManagementService,
		private notificationMessage: NotificationMessageService,
		private usersService: UsersService,
		private router: Router
	){}

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('device_management'))
			return this.router.navigate(['/unauthorized']);
	}

	reverseRT() {
		if(!this.startId || !this.endId || this.startId > this.endId)
			return this.notificationMessage.setMessage('translate|g.invalid_input', {clearOnXTimeNavigate: 1});
		this.deviceManagementService.reverseRT(this.device.mac_address, this.startId, this.endId).subscribe(()=>{
			this.startId = this.endId = null;
			this.notificationMessage.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	};
}
