import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../users/users.service';
import { Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { SitesService } from 'src/app/sites/sites.service';
import * as moment from 'moment';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { keyBy } from 'lodash-es'
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { Subscription } from 'rxjs';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';

@Component({
	selector: 'app-suggested-installation-date-report',
	templateUrl: './suggested-installation-date-report.component.html'
})
export class SuggestedInstallationDateReportComponent implements OnInit {

	customersAndSites: any[] = [];
	customersList: any[] = [];
	sitesList: any[] = [];

	selectedCustomerId: number = null;
	selectedSiteId: number = null;
	selectedDate = 0;
	selectedDevices = {};
	selectedGapPeriod: number = 14;
	reportData = [];

	setInstallationSubscription!: Subscription;
	generateReportSubscription!: Subscription;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		private deviceService: DeviceService,
		private siteService: SitesService,
		private commonService: CommonService,
		public translate: TranslateService,
		private notificationMessage: NotificationMessageService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			return this.router.navigate(['/unauthorized']);

		this.sideMenuService.hide();

		this.getCustomersSiteList();
	}

	getCustomersSiteList() {
		this.siteService.getUserSitesInfo({ get_customers: true }).subscribe((res: any) => {
			this.customersList = res.customers;
			this.customersList = this.commonService.sortDataAlphabetically(this.customersList, 'customer_name');
			this.customersAndSites = keyBy(this.customersList || [], 'id');
		});
	}

	getCustomerSites(customerId: number) {
		this.sitesList = this.customersAndSites[customerId].sites || [];
		this.sitesList = this.commonService.sortDataAlphabetically(this.sitesList, 'name');
		this.selectedSiteId = this.sitesList[0]?.id;
	}

	disableEnableGenerateButton() {
		return  !this.selectedSiteId ||
				!this.selectedDate ||
				!Number.isInteger(this.selectedGapPeriod) ||
				this.selectedGapPeriod < 7 ||
				this.selectedGapPeriod > 60;
	}

	generateReport() {
		if (this.disableEnableGenerateButton())
			return;

		this.generateReportSubscription = this.deviceService.generateSuggestedInstallationReport(this.selectedSiteId, this.selectedDate, this.selectedGapPeriod).subscribe((res: any) => {
			this.reportData = res || [];
			this.selectedDevices = {};
		});
	}

	setInstallationDate() {
		this.setInstallationSubscription = this.deviceService.setDevicesInstallationDate(this.selectedDevices).subscribe((res: any) => {
			switch (res?.api_status) {
				case 2:
					this.notificationMessage.setMessage(this.translate.instant('g.invalid_fields'));
					break;
				default:
					this.notificationMessage.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
					break;
			}
		});
	}

	selectDevice(data: any, event?: any) {
		if (event) {
			this.selectedDevices = {};

			if (event.target.checked) {
				for (const row of this.reportData) {
					if (row.suggested_installation_date)
						this.selectedDevices[row.mac_address] = row.suggested_installation_date;
				}
			}

			return;
		}

		if (!(data.mac_address in this.selectedDevices))
			this.selectedDevices[data.mac_address] = data.suggested_installation_date;
		else
			delete this.selectedDevices[data.mac_address];

		return;
	}

	sortData(sort: any) {
		const data = this.reportData.slice();
		this.reportData = data.sort((a, b) => {
			const { compare } = this.commonService;

			const isAsc = sort.direction === 'asc';
			const field = sort.active;

			return compare(a[field], b[field], isAsc);
		});
	}

	formateInstallationDate(date: number) {
		if (!date)
			return 'N/A';

		return moment(date * 1000).utc().format('MM/DD/YYYY');
	}

	export() {
		const customerInfo = this.customersAndSites[this.selectedCustomerId];
		const customerName = customerInfo.customer_name;
		const sites = keyBy(customerInfo.sites || [], 'id');
		const siteName = sites[this.selectedSiteId].name;

		const csvRows = [
			[`${this.translate.instant('g.customer_name')}:`, customerName, '', ''],
			[`${this.translate.instant('g.site_name')}:`, siteName, '', ''],
			[`${this.translate.instant('suggested_installation_date_report.installation_date_provided_by_the_installer')}:`, moment(this.selectedDate).format('MM/DD/YYYY'), '', ''],
			['', '', '', ''],
			['', '', '', ''],
		];

		const columnNames = [
			this.translate.instant('devices.serial_number'),
			this.translate.instant('g.mac_address'),
			this.translate.instant('suggested_installation_date_report.suggested_installation_date'),
			this.translate.instant('suggested_installation_date_report.actual_installation_date'),
		];

		csvRows.push(columnNames);

		(this.reportData || []).forEach(row => {
			csvRows.push([
				row.serial_number,
				row.mac_address,
				this.formateInstallationDate(row.suggested_installation_date),
				this.formateInstallationDate(row.actual_installation_date),
			]);
		});

		new AngularCsv(csvRows, this.translate.instant('suggested_installation_date_report.title'));
	}

	selectedDevicesLength() {
		return Object.keys(this.selectedDevices).length;
	}

	isDeviceSelected(macAddress: string) {
		return macAddress in this.selectedDevices;
	}

	isAllDevicesSelected() {
		return this.selectedDevicesLength() && this.selectedDevicesLength() == Object.values(this.reportData).filter((row: any) => row.suggested_installation_date).length;
	}

	disableCheckAll() {
		return this.reportData.every(row => !row.suggested_installation_date);
	}

	ngOnDestroy() {
		if (this.setInstallationSubscription)
			this.setInstallationSubscription.unsubscribe();

		if (this.generateReportSubscription)
			this.generateReportSubscription.unsubscribe();
	}
}