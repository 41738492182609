import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { StudiesService } from 'src/app/study/studies.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-site-studies',
	templateUrl: './site-studies.component.html',
})

export class SiteStudiesComponent implements OnInit {

	siteId: number = 0;
	customerId: number = 0;
	hasStudies: boolean = false;
	currentUser: any = {};
	isChargLinkDomain: boolean = false;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private usersService: UsersService,
		private studiesService: StudiesService,
		private commonService: CommonService
	){}

	ngOnInit() {
		this.currentUser = this.usersService.getCurrentUser();
		this.isChargLinkDomain = this.commonService.isChargeLink()

		if(!this.currentUser.has_access_to_any_study || this.isChargLinkDomain)
			return this.router.navigate(['/unauthorized']);

		this.siteId = this.route.parent.snapshot.params.siteId || 0;
		if(!this.siteId || isNaN(this.siteId))
			return this.router.navigateByUrl('/404');

		this.customerId = this.route.parent.snapshot.params.customerId || 0

		if (this.currentUser.has_access_to_any_study)
			this.checkHasStudies();
	}

	checkHasStudies() {
		this.studiesService.checkHasStudies(this.siteId).subscribe((res: any) => {
			this.hasStudies = res;
			if (!res)
				return this.router.navigateByUrl('/404');
		});
	}
}
