<div class="row">
	<div class="col-md-6">
		<form #accessFunctionsForm="ngForm" (ngSubmit)="updateUserAccessFunctions()" class="my-5">
			<h3 class="text-center mb-5">{{'users.edit_user_access_functions' | translate}}</h3>
			<div class="col-md-12" *ngFor="let functionKey of getKeys(userAccessFunctions)" class="row mt-2">
				<div class="col-md-6">
					{{'access_func.'+functionKey | translate}}
				</div>
				<div class="col-md-4">
					<select *ngIf="!accessFunctions[functionKey].hasReadOption" [(ngModel)]="userAccessFunctions[functionKey]" class="form-control" name="access-{{functionKey}}">
						<option *ngFor="let opt of boolAccessOptions" [value]="opt.id">{{opt.text}}</option>
					</select>
					<select *ngIf="accessFunctions[functionKey].hasReadOption" [(ngModel)]="userAccessFunctions[functionKey]" class="form-control" name="access-{{functionKey}}">
						<option *ngFor="let opt of accessOptions" [value]="opt.id">{{opt.text}}</option>
					</select>
				</div>
				<div class="col-md-2">
					<fa-icon icon="times" size="sm" class="pointer red-hover" [title]="'g.remove' | translate" (click)="editAccessFunction('del', functionKey)"></fa-icon>
				</div>
			</div>
			<div class="row mt-2" *ngIf="accessOptionsToAdd.length > 0">
				<div class="col-md-6">
					<select [(ngModel)]="newAccessFunction.key" class="form-control" name="new-access-function">
						<option *ngFor="let newFunc of accessOptionsToAdd" [value]="newFunc.value">{{newFunc.label}}</option>
					</select>
				</div>
				<div class="col-md-4">
					<select *ngIf="newAccessFunction.key && !accessFunctions[newAccessFunction.key].hasReadOption" [(ngModel)]="newAccessFunction.access" class="form-control" name="new-bool-access">
						<option *ngFor="let opt of boolAccessOptions" [value]="opt.id">{{opt.text}}</option>
					</select>
					<select *ngIf="newAccessFunction.key && accessFunctions[newAccessFunction.key].hasReadOption" [(ngModel)]="newAccessFunction.access" class="form-control" name="new-access">
						<option *ngFor="let opt of accessOptions" [value]="opt.id">{{opt.text}}</option>
					</select>
				</div>
				<div class="col-md-2">
					<button type="button" class="btn btn-success" (click)="editAccessFunction('add', newAccessFunction)" [disabled]="!newAccessFunction.key || !newAccessFunction.access">{{'g.add' | translate}}</button>
				</div>
			</div>
			<div class="mt-3">
				<button class="btn btn-info mr-3" [disabled]="accessFunctionsForm.invalid">{{'g.save' | translate}}</button>
				<button class="btn btn-default" (click)="goBack()" type="button">{{'g.cancel' | translate}}</button>
			</div>
		</form>
	</div>
	<div class="col-md-5 offset-md-1 my-5 light-grey-bg p-4">
		<h5 class="text-center mb-4">{{'users.access_functions_documentation' | translate}}</h5>
		<select [(ngModel)]="docAccessFunction" class="form-control my-2" name="doc-access-function">
			<option *ngFor="let functionKey of getKeys(accessFunctions)" [value]="functionKey">{{'access_func.'+functionKey | translate}}</option>
		</select>
		<div *ngIf="docAccessFunction && accessFunctions[docAccessFunction].desc && accessFunctions[docAccessFunction].desc.length > 0">
			<p *ngFor="let item of accessFunctions[docAccessFunction].desc" class="m-2">
				<fa-icon [icon]="['far', 'check-square']" size="sm" class="mr-2"></fa-icon><span [innerHTML]="item | safe: 'html'"></span>
			</p>
		</div>
	</div>
</div>