import { Component, OnInit } from '@angular/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { SiteDashboardService } from '../site-dashboard.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-site-notes',
	templateUrl: './site-notes.component.html',
	styleUrls: ['./site-notes.component.css']
})
export class SiteNotesComponent implements OnInit {

	siteId: number;

	tooltip: string;
	permissionsSub: Subscription = new Subscription();

	constructor(
		private route: ActivatedRoute,
		private sideMenuService: SideMenuService,
		private commonService: CommonService,
		private siteDashboardService: SiteDashboardService,
		private userService: UsersService,
	) { }

	ngOnInit() {
		this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data=> {
			this.tooltip = this.commonService.getTooltipKey('site_notes', this.userService.userHasNOCAccess(data));
		});

		this.sideMenuService.currentSite.subscribe(data => {
			if (!data || !data['id']) {
				return ;
			}

			let siteId = Number(this.route.parent.snapshot.params['siteId']);
			let customerId = Number(this.route.parent.snapshot.params['customerId']);
			
			if (data['id'] == siteId && data['customer_id'] == customerId) {
				this.siteId = data['id'];
			}
		});
	}

	ngOnDestroy() {
		this.permissionsSub.unsubscribe();
	}
}
