import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { ServerPaginationComponent } from 'src/app/shared/server-pagination/server-pagination.component';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';
import { ModalComponent } from '../../shared/modal/modal.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-rma-processing',
  templateUrl: './rma-processing.component.html',
  styleUrls: ['./rma-processing.component.css']
})
export class RmaProcessingComponent implements OnInit {

	rmaDevices: any;
	recordsCount: number = 0;
	selectedMacAddress: string = '';;
	selectedType: string = '';
	selectedIndex: number = -1;
	dialogMsg: string = '';
	pageNum!: number;

	limit = 20;

	statuses: any  = {
		1: this.translate.instant('rma.pending'),
		2: this.translate.instant('rma.fix'),
		3: this.translate.instant('rma.discard'),
	};

	@ViewChild("serverPagination", { static: true }) serverPagination!: ServerPaginationComponent;
	@ViewChild("confirmDialog") confirmDialog: ModalComponent


	constructor(
		public userService: UsersService,
		private router: Router,
		private sideMenuService: SideMenuService,
		private deviceManagementService: DeviceManagementService,
		private sctToastService: NotificationMessageService,
		private translate: TranslateService,
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		if (!this.userService.hasAccessFunction('shipment_management'))
			return this.router.navigate(['/unauthorized']);
		this.resetPageVars();
		this.getRmaDevices(false, true);
		return;
	}

	resetPageVars() {
		this.pageNum = 0;
	}

	getRmaDevices(isBack = false, firstTime=false) {
		if (firstTime)
			this.serverPagination.init();
		
		if (isBack)
			this.pageNum--;
		else
			this.pageNum++;

		this.deviceManagementService.getRmaDevices({pageNo: this.pageNum}).subscribe((res: any) => {
			this.serverPagination.updatePagination(res.devices, isBack, firstTime);
			if(res.length >= (this.limit + 1))
				res.pop();
			this.rmaDevices = res.devices;
		});
	}

	moveFromRma(mac_address: string, type: string, index: number) {
		const data = {
			mac_address,
			discard: (type == 'discard')
		}
		this.deviceManagementService.moveFromRma(data).subscribe(() => {
			this.sctToastService.setMessage('globalSuccessMsg');
			this.confirmDialog.hide();
			this.resetPageVars();
			this.getRmaDevices(false, true);
		});
	}

	confirmMoveFromRma(mac_address: string, type: string, index: number) {
		this.selectedMacAddress = mac_address;
		this.selectedType = type;
		this.selectedIndex = index;
		this.dialogMsg = `rma.${type == 'discard' ? 'discard_body' : 'move_body'}`;
		this.confirmDialog.title = this.translate.instant(type == 'discard' ? 'rma.discard_header' : 'rma.move_header');
		this.confirmDialog.show();
	}

}
