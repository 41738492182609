import { Component, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { AdminService } from "../../admin.service";
import { ActivatedRoute, Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { TranslateService } from '@ngx-translate/core';
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
import { CommonService } from 'src/app/shared/services/common.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';

@Component({
	selector: 'app-connectivity-verification-log',
	templateUrl: './connectivity-verification-log.component.html',
	styleUrls: ['./connectivity-verification-log.component.css']
})

export class ConnectivityVerificationLogComponent {
  connectivityDetails: any[] = [];
  warnings: any = [];
  routeSubscription: any;
	connectivityLog: any[] = [];
	sortResult: any[] = [];
	toShowConnectivityLog: any[] = [];
	filteredResult: any[] = [];

	pageSizeOptions: number[] = [100];
	pageSize: number = this.pageSizeOptions[0];
  reportId = 0;
	currentPage = 1;

	numberFields = ['current', 'voltage', 'longitude', 'latitude'];
	boolFields = ['connectivity_status'];
	timeFields = ['action_time', 'last_connect_time'];
	stringFields = ['current_customer_name', 'current_site_name', 'customer_name', 'site_name', 'serial_number', 'mac_address', 'user', 'result', 'app_version' ];

	isHidePopupShown = false;

	allColumns: { sortHeader: string, translationKey: string }[] = [
		{ sortHeader: 'current_customer_name', translationKey: 'connectivity_verification.current_customer_name'},
		{ sortHeader: 'current_site_name', translationKey: 'connectivity_verification.current_site_name'},
		{ sortHeader: 'customer_name', translationKey: 'connectivity_verification.customer_name' },
		{ sortHeader: 'site_name', translationKey: 'connectivity_verification.site_name' },
		{ sortHeader: 'serial_number', translationKey: 'g.serial_number' },
		{ sortHeader: 'mac_address', translationKey: 'g.mac_address' },
		{ sortHeader: 'user', translationKey: 'g.done_by' },
		{ sortHeader: 'action_time', translationKey: 'connectivity_verification.check_time' },
		{ sortHeader: 'result', translationKey: 'g.result' },
		{ sortHeader: 'last_connect_time', translationKey: 'devices.last_connect_time' },
		{ sortHeader: 'connectivity_status', translationKey: 'devices.connectivity_status' },
		{ sortHeader: 'longitude', translationKey: 'site_edit.longitude' },
		{ sortHeader: 'latitude', translationKey: 'site_edit.latitude' },
		{ sortHeader: 'current', translationKey: 'qv.current' },
		{ sortHeader: 'voltage', translationKey: 'qv.voltage' },
		{ sortHeader: 'app_version', translationKey: 'connectivity_verification.app_version' },
		{ sortHeader: 'warning_ids', translationKey: 'g.warnings' },
	];
	hiddenColumns = ['site_name', 'customer_name', 'last_connect_time', 'connectivity_status', 'longitude', 'latitude', 'current', 'voltage', 'app_version', 'warning_ids'];
	columns: { sortHeader: string, translationKey: string }[] = [];
	draggedColumn = "";

	filter = {
		customer_name: "",
		site_name: "",
		serial_number: "",
		mac_address: "",
		user: "",
		app_version: "",
		warning_ids: "",
	};

	numbersFilter: {[key: string]: {from: number | null, to: number | null}} = {
		longitude: {from: null, to: null},
		latitude: {from: null, to: null},
		current: {from: null, to: null},
		voltage: {from: null, to: null}
	}

	timeFilter: {[key: string]: {from: number | null, to: number | null}} = {
		action_time: {from: null, to: null},
		last_connect_time: {from: null, to: null},
	}

	selectedResults = [true, false];
	results: { result: boolean; value: string }[] = [
		{ result: false, value: this.translate.instant("connectivity_verification.pass") },
		{ result: true, value: this.translate.instant("connectivity_verification.fail") },
	];

	selectedConnectivity = [true, false];
	connectivityStatuses: { connectivity_status: boolean; value: string }[] = [
		{ connectivity_status: true, value: this.translate.instant("g.connected")},
		{ connectivity_status: false, value: this.translate.instant("g.disconnected")},
	];

	sort =  { active: "serial_number", direction: "asc" };

	@ViewChild("connectivitVerificationModel") connectivitVerificationModel;

	constructor(
		private adminService: AdminService,
		private usersService: UsersService,
		private router: Router,
		private deviceService: DeviceService,
		public translate: TranslateService,
		public commonService: CommonService,
		private commonData: CommonDataService,
    private route: ActivatedRoute,
	) {}

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
				this.router.navigate(['/unauthorized']);

		this.columns = this.allColumns.filter(column => !this.hiddenColumns.includes(column.sortHeader));
		const isDev = window.location.hostname.includes(this.commonData.developmentDomain);

		if (isDev) {
				this.pageSizeOptions = [4];
				this.pageSize = this.pageSizeOptions[0];
		}


    this.routeSubscription = this.route.params.subscribe(params => {
      this.reportId = +params.reportId;
      this.getConnectivityVerificationLogs();
    });

	}

	getConnectivityVerificationLogs() {
		this.adminService.getConnectivityVerificationLogs(this.reportId).subscribe((res: any) => {
			this.connectivityLog = res;
			this.sortResult = [...this.connectivityLog];
			this.updateConnectivityLog();
		});
	}

	prepareWarnings(data: any) {
		if (!data || data.length == 0)
			return
		return data.map(item => this.deviceService.getWarningsMabValues(item));
	}

	checkProcessStatus(record) {
		if (record.proccess_result == null)
			return (!record.warning_ids || !record.warning_ids?.length) && record.connectivity_status;

		return record.proccess_result;
	}

	sortAndFilterData(sort?: any) {
		if (sort)
			this.sort = sort;

		this.filteredResult = this.filterData();
		const data = this.filteredResult.slice();

		if (!this.sort.active || this.sort.direction === '') {
			this.sortResult = data;
			return;
		}

		this.sortResult = data.sort((a, b) => this.sortFunction(this.sort, a, b));
		this.currentPage = 1;
		this.updateConnectivityLog()
	}

	getWarningsToFilter(warningsIds) {
		return (this.prepareWarnings(warningsIds) || [])
			.map(warning => this.translate.instant(`warnings.${warning}`));
	}

	filterData() {
		const filterFields = Object.keys(this.filter);
		const filterNumberFields = Object.keys(this.numbersFilter);
		const filterTimeFields = Object.keys(this.timeFilter);

		const data = [...this.connectivityLog].filter((log) => {
			const warning_ids = this.getWarningsToFilter(log.warning_ids);
			for (const field of filterFields) {
				const filterValue = this.filter[field].toLowerCase().trim();
				if (!filterValue)
					continue;
				const value = field != 'warning_ids' && log[field] ? log[field].toLowerCase().trim() : '';

				if (field == 'warning_ids') {
					const filteredWarnings = warning_ids.filter(text => text.toLowerCase().includes(filterValue));
					if (!filteredWarnings.length)
						return false;

				} else if (filterValue && !value.includes(filterValue))
					return false;
			}

			// numbers filter
			for (const field of filterNumberFields) {
				const from = this.numbersFilter[field].from;
				const to = this.numbersFilter[field].to;

				if (from != null && to != null && !(log[field] >= from && log[field] <= to))
					return false;
			}

			// time filter
			for (const field of filterTimeFields) {
				const from = this.timeFilter[field].from ? moment(this.timeFilter[field].from).startOf('day').unix() : null;
				const to = this.timeFilter[field].to ? moment(this.timeFilter[field].to).endOf('day').unix() : null;

				if (from != null && to != null && !(log[field] >= from && log[field] <= to))
					return false;
			}

			// drop down options filter
			if (this.selectedConnectivity.length && !this.selectedConnectivity.includes(log.connectivity_status))
				return false;

			if (!this.selectedConnectivity.length)
				return false;

			if (this.selectedResults.length && !this.selectedResults.includes(!this.checkProcessStatus(log)))
				return false;

			if (!this.selectedResults.length)
				return false;


			return true;
		});

		return data;
	}

	sortFunction(sort: any, a:any, b:any) {
		const { compare } = this.commonService;

		const isAsc = sort.direction === 'asc';
		const field = sort.active;

		if (this.boolFields.includes(field))
			return compare(a[field] ? 1 : 2, b[field] ? 1 : 2, isAsc);

		if (this.timeFields.includes(field))
			return compare(this.formateDateForCompare(a[field]), this.formateDateForCompare(b[field]), isAsc);

		if (field == 'result') {
			const aValue = this.checkProcessStatus(a);
			const bValue = this.checkProcessStatus(b);

			return compare(aValue, bValue, isAsc);
		}

		if (this.stringFields.includes(field)) {
			const aValue = (String(a[field]) || '').toLowerCase();
			const bValue = (String(b[field]) || '').toLowerCase();

			return compare(aValue, bValue, isAsc);
		}

		if (this.numberFields.includes(field)) {
			const aValue = +a[field] || 0;
			const bValue = +b[field] || 0;

			return compare(aValue, bValue, isAsc);
		}

		return 0
	}

	formateDateForCompare(date: moment.MomentInput) {
		return date ? moment(date).utc().format('YYYY/MM/DD HH:mm:ss'): '-';
	}

	updateConnectivityLog() {
		const startIndex = (this.currentPage - 1) * this.pageSize;
		const endIndex = startIndex + this.pageSize;
		this.toShowConnectivityLog = (this.sortResult || []).slice(startIndex, endIndex);
	}

	onPageChange(event) {
		this.currentPage = event.pageIndex + 1;
		this.updateConnectivityLog();
	}

 	toggleHidePopup() {
		this.isHidePopupShown = !this.isHidePopupShown;
	}

	toggleColumnVisibility(column) {
		if (this.hiddenColumns.includes(column.sortHeader))
			this.hiddenColumns = this.hiddenColumns.filter(hiddenCol => hiddenCol != column.sortHeader);

		else
			this.hiddenColumns.push(column.sortHeader);

		this.columns = this.allColumns.filter(column => !this.hiddenColumns.includes(column.sortHeader));
	}

	generateMainTableCsvFile() {
		const fileName = `${this.translate.instant('connectivity_verification.file_name')} ${moment.unix(this.commonService.nowTime()).utc().format('MM/DD/YYYY')}`;

		const csvRows = [];
		const columnNames = []
		this.columns.forEach(column => {
			columnNames.push(this.translate.instant(column.translationKey));
		})

		csvRows.push(columnNames);

		(this.sortResult || []).forEach(row => {
			const rowArray = [];

			this.columns.forEach(column => {
				let value = this.getFieldValue(row, column);
				rowArray.push(value)
			});

			csvRows.push(rowArray);
		})

		new AngularCsv(csvRows, fileName);
	}

	getFieldValue(row, column) {
		let value = row[column.sortHeader];

		switch (column.sortHeader) {
			case 'customer_name':
				value =  this.translate.instant(value == 'customer_deleted' ? "connectivity_verification." + value : value);
				break;

			case 'site_name':
				value =  this.translate.instant(value == 'site_deleted' ? "connectivity_verification." + value : value);
				break;

			case 'serial_number':
				value =  this.translate.instant(value == 'device_deleted' ? "connectivity_verification." + value : value);
				break;

			case 'mac_address':
			case 'user':
			case 'longitude':
			case 'latitude':
			case 'current':
			case 'voltage':
			case 'app_version':
				value =  value || '-';
				break;

			case 'action_time':
			case 'last_connect_time':
				value = this.formateDate(value * 1000);
				break;

			case 'result':
				value = this.translate.instant( this.checkProcessStatus(row) ? 'connectivity_verification.pass': 'connectivity_verification.fail' );
				break;

			case 'connectivity_status':
				value = this.translate.instant( this.checkProcessStatus(row) ? 'g.connected': 'g.disconnected' );
				break;
			case 'warning_ids':
				value = this.prepareWarningsCsv(value);
				break;
			default:
				value = value || '-';
				break;
		}

		return value;
	}

	formateDate(date: moment.MomentInput, format = 'MM/DD/YYYY hh:mm:ss a') {
		return date && date != '---' ? moment(date).utc().format(format || 'MM/DD/YYYY hh:mm:ss a') : '---';
	}

	prepareWarningsCsv(warningIds) {
		let warnings = this.prepareWarnings(warningIds);
		if (!warnings)
			return '---';
		let result = warnings.map((text, index) => `${index + 1}. ${this.translate.instant('warnings.' + text)}`);
		return result.join('\n');
	}

	drag(event: any) {
		this.draggedColumn = event.currentTarget.textContent;
		event.dataTransfer.setData('text/plain', event.currentTarget.textContent);
		event.dataTransfer.effectAllowed = 'move';
	}

	drop(event: any) {
		event.preventDefault();
		let target = event.currentTarget;

		if (!target || target.tagName != 'TH')
			return;

		const data = this.draggedColumn;
		const thElements = Array.from(target.parentElement.children);
		const currentIndex = thElements.findIndex((th: any) => th.textContent === data);
		const dropIndex = thElements.findIndex(th => th === target);

		// update columns list
		if (currentIndex > -1 && dropIndex > -1 && currentIndex != dropIndex) {
			const toMoveColumn = this.columns[currentIndex];
			const columnsList = [...this.columns];
			columnsList.splice(currentIndex, 1);
			columnsList.splice(dropIndex, 0, toMoveColumn);
			this.columns = [...columnsList];
		}
	}

	allowDrop(event: any) {
		event.preventDefault();
	}
}
