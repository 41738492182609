import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CommonDataService } from "src/app/shared/services/common-data.service";
import { AngularCsv } from 'angular-csv-ext/dist/Angular-csv';
@Injectable({
    providedIn: 'root'
})
export class ReceivingShipmentService {

    constructor(
        private commonDataService: CommonDataService,
        private translateService: TranslateService
    ) { }

    isRecevingProccessSuccess(status: string): boolean {
		return (status == this.commonDataService.RECEIVING_SHIPMENT_DEVICES_STATUS.completed.status 
				|| status == this.commonDataService.RECEIVING_SHIPMENT_DEVICES_STATUS.gps_not_ready.status);
    }

    getReceivingShipmentDeviceStatus(status: number): boolean {
       return (status == this.commonDataService.RECEIVING_SHIPMENT_DEVICES_STATUS.completed.key 
                || status == this.commonDataService.RECEIVING_SHIPMENT_DEVICES_STATUS.gps_not_ready.key);
    }

    generateHistoryCsvFile(response, fileName, type) {
        const config = {
            shipment_history: {
                columns: [
                    this.translateService.instant('g.mac_address'),
                    this.translateService.instant('g.serial_number'),
                    this.translateService.instant('g.date'),
                    this.translateService.instant('shipment.user_name'),
                    'SNMAC'
                ],
                proccessFormatData: row => row.devices.map(device => [
                    device.mac_address,
                    device.serial_number,
                    row.shipping_date ? row.shipping_date : '-',
                    row.user_name ? row.user_name : '-',
                    `${device.serial_number}${device.mac_address}`
                ])
            },
            receiving_history: {
                columns: [
                    this.translateService.instant('g.mac_address'),
                    this.translateService.instant('g.serial_number'),
                    this.translateService.instant('g.date'),
                    this.translateService.instant('shipment.user_name'),
                    this.translateService.instant('shipment.checked_quality'),
                    'SNMAC'
                ],
                proccessFormatData: row => [[
                    row.mac_address,
                    row.serial_number,
                    row.action_time,
                    row.user_name,
                    row.checked_quality,
                    row.sn_mac
                ]]
            }
        };
    
        const { columns, proccessFormatData } = config[type];
        const csvRows = [columns];
    
        (response || []).forEach(row => {
            const processedRows = proccessFormatData(row);
            csvRows.push(...processedRows);
        });
    
        new AngularCsv(csvRows, fileName);
    }
}

