<div>
	<div class="d-flex justify-content-between align-items-center">
		<h2>{{'nav.rma-devices' | translate}}</h2>
		<button
			class="btn btn-primary pointer"
			type="button"
			routerLink="/management/rma"
			target="_blank"
			*ngIf="userService.hasAccessFunction('shipment_management')"
		>
			{{'devices.add_rma' | translate}}
		</button>
	</div>
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.mac_address' | translate}}</th>
				<th>{{'devices.serial_number' | translate}}</th>
				<th>{{'g.purchase_order' | translate}}</th>
				<th>{{'devices.rma_no' | translate}}</th>
				<th>{{'users.create_date' | translate}}</th>
				<th>{{'rma.updated_at' | translate}}</th>
				<th>{{'g.status' | translate}}</th>
				<th>{{'g.actions' | translate}}</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let rmaDevice of rmaDevices; let idx = index">
			<td>
				<a [routerLink]="['/', rmaDevice.customer_id, rmaDevice.site_id, rmaDevice.mac_address, 'performance']" class="blue-hover pointer ml-1" target="_blank">
					{{rmaDevice.mac_address}}
				</a>
			</td>
			<td>{{rmaDevice.serial_number}}</td>
			<td>{{rmaDevice.po_number}}</td>
			<td>{{rmaDevice.rma_no}}</td>
			<td>{{rmaDevice.created_at | date:'long'}}</td>
			<td>{{rmaDevice.updated_at != '----' ? (rmaDevice.updated_at | date:'long') : rmaDevice.updated_at}}</td>
			<td>{{statuses[rmaDevice.rma_status]}}</td>
			<td>
				<div class="btn-group mx-3" dropdown placement="left" *ngIf="rmaDevice.rma_status == 1">
					<fa-icon icon="cog" size="lg" dropdownToggle class="dropdown-toggle"></fa-icon>
					<ul *dropdownMenu class="dropdown-menu" role="menu">
						<li role="menuitem">
							<a class="dropdown-item pointer" (click)="confirmMoveFromRma(rmaDevice.mac_address, 'new', idx)">{{'actions.move' | translate}}</a>
						</li>
						<li role="menuitem">
							<a class="dropdown-item pointer" (click)="confirmMoveFromRma(rmaDevice.mac_address, 'discard', idx)">{{'actions.discard' | translate}}</a>
						</li>
					</ul>
				</div>
			</td>
		</tr>	
		</tbody>
	</table>
	<div class="w-100">
		<server-pagination idField="id" [limit]="limit" (paginate)="getRmaDevices($event)" #serverPagination>
		</server-pagination>
	</div>
</div>

<app-modal #confirmDialog  [hasConfirmButton]="false" [hasCloseButton]="false">
    <div>
        {{dialogMsg | translate}}
    </div>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="confirmDialog.hide()">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="moveFromRma(selectedMacAddress, selectedType, selectedIndex)">{{'g.ok' | translate}}</button>
	</div>
</app-modal>