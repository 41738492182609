<div class="white-box p-3">
	<h1 class="mb-4">
		{{'nav.admin_actions' | translate}}
	</h1>
	<hr>
	<h4 class="mb-4">
		{{'admin.force_website_refresh' | translate}}
	</h4>
	<div class="row">
		<div class="col-md-12">
			<button type="button" class="btn btn-lg btn-primary" (click)="forceWebsiteRefresh()">{{'admin.force_website_refresh' | translate}}</button>
		</div>
	</div>
	<div *ngIf="userService.hasAccessFunction('super_admin', 'write')">
		<hr>
		<h4 class="mb-4">
			{{'admin.generate_script' | translate}}
		</h4>

		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{'generate_script.pick_command' | translate}}</label>
			<div class="col-sm-10">
				<ng-select
					[items]="commands"
					bindLabel="text"
					bindValue="key"
					placeholder="{{'generate_script.pick_command' | translate}}"
					name="command"
					[(ngModel)]="command"
				></ng-select>
			</div>
		</div>

		<div class="form-group row" *ngIf="commandHasMacOption()">
			<label class="col-sm-2 col-form-label">{{'generate_script.enterMac' | translate}}</label>
			<div class="col-sm-10">
				<input type="text" class="form-control mt-3" placeholder="{{'generate_script.enterMac' | translate}}" [(ngModel)]="macAddress" (blur)="validateMacAddress()" [ngClass]="{'invalid-input' : !validateMacAddress()}">
				<small class="alert text-danger" *ngIf="!validateMacAddress()">{{'generate_script.invalidMac' | translate}}</small>
			</div>
		</div>

		<div class="form-group row" *ngIf="commandHasSiteOption()">
			<label class="col-sm-2 col-form-label">{{'generate_script.enterSite' | translate}}</label>
			<div class="col-sm-10">
				<input type="text" class="form-control mt-3" placeholder="{{'generate_script.enterSite' | translate}}" [(ngModel)]="siteId" (blur)="validateSiteId()" [ngClass]="{'invalid-input' : !validateSiteId()}">
				<small class="alert text-danger" *ngIf="!validateSiteId()">{{'generate_script.invalidSite' | translate}}</small>
			</div>
		</div>

		<div class="form-group row" *ngIf="commandHasExcludeSitesOption()">
			<label class="col-sm-2 col-form-label">{{'generate_script.exclude_large_sites' | translate}}</label>
			<div class="col-sm-10">
				<mat-slide-toggle [(ngModel)]="excludeLargeSites"></mat-slide-toggle>
			</div>
		</div>

		<div class="form-group row">
			<label class="col-sm-2 col-form-label">{{'g.file_name' | translate}}</label>
			<div class="col-sm-10">
				<input type="text" class="form-control mt-3" placeholder="{{'g.file_name' | translate}}" [(ngModel)]="fileName">
			</div>
		</div>

		<button class="btn btn-lg btn-primary" (click)="sendGenerateCommand()" [disabled]="!command">{{'g.submit' | translate}}</button>
	</div>
</div>
