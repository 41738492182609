<div>
	<h1 class="h2 m-4">{{'audit.permission_audit' | translate}}</h1>
	<div class="mb-4" *ngFor="let perm of this.data | keys">
		<app-simple-widget isCollapsed="true" title="{{perm}} ">
			<div class="mb-2">
				<div *ngFor="let user of this.data[perm]" class="row mt-2">
					<div class="col-md-6">
						{{user}}
					</div>
				</div>
			</div>
		</app-simple-widget>
	</div>
	<div class="card-body" *ngIf="checkData === 0">
		<strong>{{'g.no_data_to_show' | translate}}</strong>
	</div>
</div>