import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SitesService } from 'src/app/sites/sites.service';
import { DeviceManagementService } from '../../device-management.service';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';

@Component({
	selector: 'app-customer-request-edit',
	templateUrl: './customer-request-edit.component.html',
	styleUrls: ['./customer-request-edit.component.css']
})
export class CustomerRequestEditComponent implements OnInit {

	customerId: number;
	customer: any;
	customersList: any;
	enterpriseList: any;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private notificationMessageService: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		private sitesService: SitesService,
		private enterpriseService: EnterpriseService
	) { }

	ngOnInit() {
		this.route.params.subscribe(
			params => {
				this.customerId = Number(params['customerId']);
				if(!this.customerId) {
					return this.router.navigateByUrl('/404');
				}
			}
		);
		this.getCustomer();
		this.prepareCustomersList();
		this.getEnterpriseList();
	}

	getEnterpriseList() {
		this.enterpriseService.getEnterprises({site_edit: true, source: 'manage_device'}).subscribe(enterpriseData=> {
			this.enterpriseList = enterpriseData;
		});
	}

	getCustomer() {
		this.deviceManagementService.getPendingCustomers({customerId: this.customerId}).subscribe((res) => {
			if(res[0]) {
				this.customer = {
					id: res[0].customer_id,
					customer_name: res[0].customer_name
				};
			}
		});
	}

	prepareCustomersList() {
		this.sitesService.getUserSitesInfo({get_customers: true}).subscribe((res: any) => {
			this.customersList = res.customers;
			this.customersList.unshift(
				{id: this.customerId, customer_name: this.translateService.instant('device_management.none_new_customer')}
			);
		});
	}

	approveCustomer() {
		this.deviceManagementService.approveCustomerRequest(this.customerId, this.customer).subscribe((res: any) => {
			if (res?.api_status) {
				switch(res.api_status) {
					case 2:
						return this.notificationMessageService.setMessage('translate|customer.customer_name_already_exist',{clearOnXTimeNavigate: 1});
						break;
					default:
						return this.notificationMessageService.setMessage('globalErrMsg',{clearOnXTimeNavigate: 1});
						break;
				}
			}
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
			this.goBack();
		});
	}

	goBack() {
		this.router.navigate(['/management/device-management-requests/customers']);
	}
}
