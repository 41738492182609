import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class RtRequestHistoryService {

	prefix = 'device/';

	sources = {
		idRequest: 1,
		timeRequest: 2,
		syncRequest: 3,
		forceMeteredRequest: 4,
		forceUnmeteredRequest: 5,
		1: 'idRequest',
		2: 'timeRequest',
		3: 'syncRequest',
		4: 'forceMeteredRequest',
		5: 'forceUnmeteredRequest'
	};

	statuses = {
		queued: 1,
		processing: 2,
		processed: 3,
		failed: 4,
		system: 5,
		cancel: 6,
		time_processing: 7,
		1: 'queued',
		2: 'processing',
		3: 'processed',
		4: 'failed',
		5: 'system',
		6: 'cancel',
		7: 'time_processing'
	};

	searchStatuses = {
		not_start: -1,
		success: 0,
		has_search: 1,
		has_complete: 2,
		'-1': 'not_start',
		0: 'success',
		1: 'has_search',
		2: 'has_complete'
	};

	searchStatusStatuses = {
		not_start: -1,
		incorrect: 0,
		running: 1,
		success: 2,
		end_without_valid_start_id: 3,
		end_without_endpoint_id: 4,
		end_with_close_id: 5,
		end_without_valid_correct_id: 6,
		'-1': 'not_start',
		0: 'incorrect',
		1: 'running',
		2: 'success',
		3: 'end_without_valid_start_id',
		4: 'end_without_valid_endpoint_id',
		5: 'end_with_close_id',
		6: 'end_without_valid_correct_id'
	};

	constructor(
		private http: HttpClient
	) {}

	getRtRequestsHistory(filters={}): Observable<any> {
		return this.http.get(`${this.prefix}rt-requests-history`, {params: filters});
	}

	updateRequestHistory(data): Observable<any> {
		return this.http.post(`${this.prefix}update-rt-request`, data);
	}
}