<div>
	<div *ngIf="gridData.length > 0">
		<app-grid
			[data]="gridData"
			[columns]="gridColumns"
			#agGrid
			id="connectivity-track"
			*ngIf="gridData.length > 0">
		</app-grid>
	</div>
	<div class="text-danger" *ngIf="invalidDateRange; else noData"><b>{{'g.start_lg_end' | translate}}</b></div>
	<ng-template #noData>
		<div *ngIf="emptyResponse">
			<strong>No results found!</strong>
		</div>
	</ng-template>
</div>