import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class ApisLogsService {

	readonly route: string = 'apisLogs';

	readonly integrationApisResponseStatus = {
		"unauthorized": 3,
		"devices_dates": 5,
		"not_exist": 6,
		"invalid_data": 7
	};

	constructor(
		private httpClient: HttpClient,
	) { }

	getLogError(errCode: number) {
		const statuses: any = this.integrationApisResponseStatus;

		for (const key in this.integrationApisResponseStatus) {
			if (statuses[key] === errCode)
				return key;
		}

		return null;
	}

	getLogs(options: any) {
		return this.httpClient.get(`${this.route}/get-logs`, {
			observe: "body",
			params: options
		});
	}
}
