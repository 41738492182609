import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { AdminService } from 'src/app/admin/admin.service';

@Component({
	selector: 'app-permission-audit',
	templateUrl: './permission-audit.component.html',
	styleUrls: ['./permission-audit.component.css']
})
export class PermissionAuditComponent implements OnInit {

	data: any;
	checkData:number;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private adminService: AdminService
	) { }

	ngOnInit() {
		let currentUser = this.usersService.getCurrentUser();
		if(!currentUser.is_admin)
			return this.router.navigate(['/unauthorized']);
		this.getPermissionsForAudit();
	}
	
	getPermissionsForAudit() {
		this.adminService.getPermissionsForAudit().subscribe((res)=>{
			this.data = res;
			this.checkData = Object.keys(this.data).length
		});
	}
}