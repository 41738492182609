import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../shared/breadcrumb/breadcrumb.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

@Component({
	selector: 'app-unauthorized',
	templateUrl: './unauthorized.component.html',
	styleUrls: ['./unauthorized.component.css']
})
export class UnauthorizedComponent implements OnInit {

	constructor(
		private breadcrumb: BreadcrumbService,
		private sideMenuService: SideMenuService
	) {
		this.breadcrumb.hide();
		this.sideMenuService.hide();
	}

	ngOnInit() {
	}
}
