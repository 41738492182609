<div class="row col-12 justify-content-md-center">
	<div class="col-md-6 col-sm-12">
		<h2>{{'admin.replace_device' | translate}}</h2>
		<!-- *if last connect time less than a week - Show warning- -->
		<p *ngIf="nowTime - device.config_info.last_connect_time < disconnectionLimit" class="alert alert-warning">
			{{'replace_device.device_was_connected' | translate}}
		</p>
		<!-- if the device selected has setup_done -Show a warning- -->
		<p *ngIf="getHitted && newDevice?.setup_done" class="alert alert-warning mt-2">
			{{'replace_device.setup_done_msg' | translate}}
		</p>
		<!-- *if the device selected is assigned to a site has is_special as false - SHOW warning- -->
		<p *ngIf="getHitted && newDevice && !newDevice.site?.is_special" class="alert alert-warning mt-2">
			{{'replace_device.site_is_not_special' | translate}}
		</p>

		<div *ngIf="getHitted && !newDevice">
			<p class="alert alert-danger mt-2">
				{{'g.no_results_found' | translate}}
			</p>
		</div>

		<form (ngSubmit)="getDevice()" validate-form="replaceDevice" *ngIf="!getHitted || !hasResult">
			<div class="search-box">
				<div class="search-input">
					<input type="text" class="form-control" name="macAddress" [(ngModel)]="macAddress" placeholder="{{'replace_device.device_mac_address' | translate}}">
				</div>
				<button class="btn btn-secondary" type="submit">{{'g.get_device' | translate}}</button>
			</div>
		</form>
		<div *ngIf="getHitted">
			<div *ngIf="newDevice">
				<div class="p-3 text-center" data-placement="top">
					<div>
						<img class="mt-2 mb-3" src="/sctportal/{{newDeviceImageLink}}" width="158" height="100" [ngClass]="{'charglink-logo': newDevice.is_charglink,'study-logo': newDevice.is_study_device}">
					</div>
					<div class="small">
						<div>
							<b>{{newDevice.serial_number}}</b>
						</div>
						<div>
							<div class="light-grey">{{(newDevice.truck_sn?newDevice.truck_sn:'&nbsp;')}}</div>
						</div>
					</div>
				</div>
				<div class="p-3 text-center">
					<button class="btn btn-primary" (click)="replaceDevice()">{{'admin.replace_device' | translate}}</button>
					<button class="btn btn-secondary ml-2" (click)="getHitted = !getHitted; macAddress = '';">{{'admin.get_another_device' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>
