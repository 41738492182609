<div class="main-black border-radius-bottom white-box mt-1" *ngIf="customersList">
	<div class="card-body" *ngIf="customersList.length > 0">
		<h6 class="mb-3">{{customersList.length}} {{'nav.manage_device_requests' | translate}}</h6>
		<table class="table mb-0">
			<thead>
				<tr>
					<th>{{'g.customer_name' | translate}}</th>
					<th>{{'device_management.request_by_user' | translate}}</th>
					<th>{{'g.enterprise_name' | translate}}</th>
					<th>{{'device_management.source' | translate}}</th>
					<th>{{'g.actions' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let customer of customersList">
					<td>
						{{customer.customer_name}}
						<span *ngIf="customer._approved_customer_id">
							(<a href="javascript:;" (click)="showIdenticalCustomerModal(customer)">{{'device_management.identical_customer_found' | translate}}</a>)
						</span>
					</td>
					<td>
						<a [routerLink]="['/user', 'edit', customer.user_id]" target="_blank" *ngIf="usersService.hasAccessFunction('edit_user_admin') && usersInfo[customer.user_id]; else userNoLink">
							{{usersInfo[customer.user_id].first_name}} {{usersInfo[customer.user_id].last_name}}
						</a>
						<ng-template #userNoLink>{{customer.user_id}}<span *ngIf="!usersInfo[customer.user_id]"> ({{'g.deleted' | translate}})</span></ng-template>
					</td>
					<td>
						<ng-container *ngIf="enterprisesInfo[customer.enterprise_id]; else entDel">{{enterprisesInfo[customer.enterprise_id].name}}</ng-container>
						<ng-template #entDel>{{customer.enterprise_id}}<span> ({{'g.deleted' | translate}})</span></ng-template>
					</td>
					<td>{{(customer.source ? 'device_management.source_options.'+[customer.source] : 'g.n/a') | translate}}</td>
					<td>
						<mat-icon class="pointer blue-hover" [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{customer: customer}">
							settings
						</mat-icon>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="card-body" *ngIf="customersList.length == 0">
		<h3>{{'device_management.no_new_move' | translate}}</h3>
	</div>
</div>

<mat-menu #actionsMenu="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent let-customer="customer">
		<span mat-menu-item [routerLink]="['/management/device-management-requests/customer-edit', customer.customer_id]">
			{{'device_management.edit_approve' | translate}}
		</span>
		<span mat-menu-item (click)="viewDevices(customer.customer_id)">
			{{'device_management.view_devices' | translate}}
		</span>
		<span mat-menu-item class="text-danger" (click)="confirmRejectRequest(customer.customer_id)">
			{{'g.reject' | translate}}
		</span>
	</ng-template>
</mat-menu>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate"></app-modal>

<app-modal #identicalCustomerModal title="{{'device_management.identical_customer_title' | translate}}" [closeBtnText]="'g.cancel' | translate">
	<p>
		{{'device_management.identical_customer_message' | translate}}:
	</p>
	<div class="card p-2">
		<div class="row">
			<div class="col-4">Customer Name</div>
			<div class="col-8">{{approvedCustomerInfo.customer_name}}</div>
		</div>
	</div>
</app-modal>

<app-modal #linkedDevicesModal title="{{'ent.linked_devices_title' | translate}}" [hasConfirmButton]="false" size="lg">
	<p>
		{{'ent.linked_devices_title' | translate}}:
	</p>
	<div class="card p-2">
		<ng-container *ngIf="linkedDevices.length; else noDevices">
			<div class="row my-2">
				<div class="col-4"><b>{{'g.mac_address' | translate}}</b></div>
				<div class="col-4"><b>{{'devices.latitude' | translate}}</b></div>
				<div class="col-4"><b>{{'devices.longitude' | translate}}</b></div>
			</div>
			<div class="row" *ngFor="let device of linkedDevices">
				<div class="col-4">
					<a class="blue-hover pointer ml-1" [routerLink]="['/', device.customer_id, device.site_id, device.mac_address, 'performance']" target="_blank">
						{{device.mac_address}}
					</a>
				</div>
				<div class="col-4">
					{{device.latitude}}
				</div>
				<div class="col-4">
					{{device.longitude}}
				</div>
			</div>
		</ng-container>
		<ng-template #noDevices>
			<div class="row">
				<div class="col">
					{{'device_management.no_devices_found' | translate}}
				</div>
			</div>
		</ng-template>
	</div>
</app-modal>