import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { CommonService } from '../services/common.service';
import * as _ from 'underscore';

@Component({
	selector: 'app-warnings-list',
	templateUrl: './warnings-list.component.html',
	styleUrls: ['./warnings-list.component.css']
})
export class WarningsListComponent implements OnInit, OnChanges {

	@Input() devices: any[] = [];
	@Input() exportName: string = '';
	@Input() siteTags: any = {};
	@Input() siteTagsValues: any = [];
	@Input() isSCT: boolean = false;
	@Input() tagsFilter: any[] = [];
	@Input() showOwnersColumns: boolean = false;
	@Output() tagsUpdated = new EventEmitter<Object>(true);

	columnNames: any[] = [];
	selectedDevices: any[] = [];

	constructor(
		private commonService: CommonService
	) { }

	ngOnInit() {
		this.updateSiteTags();
	}

	updateSiteTags(newTag?) {
		if(newTag)
			this.tagsUpdated.emit({id: newTag.id, data: newTag, action: 'add'});
	}

	exportCsv() {
		let devices = JSON.parse(JSON.stringify(this.selectedDevices));
		for(let device of devices)
			device._device_tags = this.getItem({key: '_device_tags'}, device)
		this.commonService.exportTableToCsv(this.columnNames, devices, this.exportName);
	}

	getItem(item, device) {
		if(item.key == '_device_tags') {
			let deviceTagsInfo = {};
			for(let item in this.siteTags) {
				deviceTagsInfo[item] = this.siteTags[item].tag_name;
			}
			let tagsNames = [];
			if(device._device_tags) {
				device._device_tags.forEach(tag => {
					tagsNames.push(deviceTagsInfo[tag.tag_id])
				});
			}
			return tagsNames.join(", ");
		}
		return device[item.key];
	}

	onTagsUpdated(event, device) {
		let tag = event.tag;
		device._device_tags = device._device_tags || [];
		if(event.type == 'remove') {
			if(true) {
				//workaround
				let updatedTags = [];
				device._device_tags.forEach(item => {
					if(item.tag_id != tag.id)
						updatedTags.push(item)
				});
	
				let len = device._device_tags.length;
				for(let i = 0; i < len; i++)
					device._device_tags.pop();
	
				for(let item of updatedTags)
					device._device_tags.push(item);
			} else {
				//@TODO: Check why this doesn't update device tags in other warnings device listings and remove the above workaround
				device._device_tags = device._device_tags.filter(function(item) { return item.tag_id !== tag.id; });
			}
		} else {
			device._device_tags.push({
				tag_id: tag.id, device_mac_address: device.mac_address, keep_in_replacement: tag.keep_in_replacement
			});
			this.updateSiteTags(tag);
		}
	}

	ngOnChanges(changes) {
		this.columnNames = [
			{name: 'g.iotah_sn', key: 'serial_number', type: 'link', linkId: '_device_link', expandable: true},
			{name: 'tags.tags', key: '_device_tags'},
			{name: 'devices.truck_id', key: 'truck_id'},
			{name: 'devices.truck_sn_short', key: 'truck_sn'},
		];

		if(this.showOwnersColumns) {
			// Add site & customer names columns if "ALL Sites" option is selected
			this.columnNames.push(
				{name: 'g.site_name', key: 'site_name', type: 'link', linkId: '_site_link'},
				{name: 'g.customer_name', key: 'customer_name', type: 'link', linkId: '_customer_link'},
			);
		}

		this.selectedDevices = [...this.devices];
		if(this.tagsFilter.length == 0)
			return;
		this.selectedDevices = [];

		deviceLoop:
		for(let device of this.devices) {
			if(device._device_tags && device._device_tags.length) {
				for(let tag of device._device_tags) {
					if(this.tagsFilter.includes(+tag.tag_id)) {
						this.selectedDevices.push(device);
						continue deviceLoop;
					}
				}
			}
		}
	}
}
