import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../admin.service';

@Component({
	selector: 'app-view-contact-us-admin',
	templateUrl: './view-contact-us-admin.component.html',
	styleUrls: ['./view-contact-us-admin.component.css']
})
export class ViewContactUsAdminComponent implements OnInit {

	data: any;
	id: number;

	constructor(
		private adminService: AdminService,
		private route: ActivatedRoute
	) {
		this.id = this.route.snapshot.params.id;
	}

	ngOnInit() {
		this.adminService.getContactUs({id: this.id}).subscribe(
			(data: any) => {
				this.data = (data && data.length ? data[0] : null);
			}
		);
	}
}