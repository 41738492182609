import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
	providedIn: 'root'
})
export class TagsService {

	route = '/tags';

	shareOptions = {
		public: 'public',
		private: 'private',
		group: 'group'
	};

	constants: any = {
		maxTermLength: 30,
		minTermLength: 3,
		maxLimitMessageTimeout: 5
	};

	constructor(
		private httpClient: HttpClient
	) { }

	getSiteTags(siteId, options?) {
		return this.httpClient.post(this.route + '/getSiteTags', {siteId, options}, {
			observe: "body"
		});
	}

	addTags(siteId, tagObj, options?) {
		return this.httpClient.post(this.route + '/addTags', {siteId, tagObj, options}, {
			observe: "body"
		});
	}

	removeTagFromSite(siteId, tagObj, options?) {
		return this.httpClient.post(this.route + '/removeTagFromSite', {siteId, tagObj, options}, {
			observe: "body"
		});
	}

	updateTagShareOption(siteId, tagObj, newShareOption, options?) {
		return this.httpClient.post(this.route + '/updateTagShareOption', {siteId, tagObj, newShareOption, options}, {
			observe: "body"
		});
	}

	addDeviceTag(siteId, deviceIds, tagObj, options?) {
		return this.httpClient.post(this.route + '/addDeviceTag', {siteId, deviceIds, tagObj, options}, {
			observe: "body"
		});
	}

	removeDeviceTag(siteId, deviceId, tagObj, options?) {
		return this.httpClient.post(this.route + '/removeDeviceTag', {siteId, deviceId, tagObj, options}, {
			observe: "body"
		});
	}
}
