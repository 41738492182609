<app-modal #requestsLogsModal title="{{'site.update_firmware_requests_history' | translate}}" [hasConfirmButton]="false" size="xl">
	<div class='col-md-12' *ngIf="requestLogs.device">
		<table class='table table-striped' *ngIf="requestLogs.device.length > 0; else noData">
			<thead>
				<tr>
					<th>{{'g.version' | translate}}</th>
					<th>{{'g.user' | translate}}</th>
					<th>{{'g.date' | translate}}</th>
					<th>{{'g.status' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let device of requestLogs.device">
					<td>{{common.toFixedWithoutRounding(device.fw_version, isAdmin ? 4 : 2)}}</td>
					<td>{{usersInfo[device.user_id] || '-'}}</td>
					<td>{{device.date_requested | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
					<td>{{('g.'+device.status) | translate}}</td>
				</tr>
			</tbody>
		</table>
		<ng-template #noData>
			<div>
				{{'g.no_data_to_show' | translate}}!
			</div>
		</ng-template>
	</div>
</app-modal>

<app-modal #lastUpdateRequestModal title="{{'site.up_to_date_site_fw' | translate}}" [hasConfirmButton]="false" size="xl">
	<p>
		<strong>{{'g.site_name' | translate}}:</strong><span class="ml-1">{{lastUpdateRequest.siteName}}</span>
	</p>
	<p>
		<strong>{{'site.up_to_date_site_fw' | translate}}:</strong><span class="ml-1">{{lastUpdateRequest.updated}}/{{lastUpdateRequest.total}}</span>
	</p>
</app-modal>

<div class="col-md-12 mb-4">
	<fieldset>
		<legend style="font-size:18px">{{'site.push_firmware_for_all_sites' | translate}}</legend>
		<div class="col-md-3">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.devices' | translate}}:</span>
				</div>
				<select class="form-control" [(ngModel)]="allDevicesFirmwareVersion">
					<option *ngFor="let version of firmwareVersions" [value]="version">{{version}}</option>
				</select>
				<div class="input-group-append">
					<button class="btn btn-primary" (click)="pushFirmwareUpdate('device', 0, allDevicesFirmwareVersion)" [disabled]="!allDevicesFirmwareVersion">{{'devices.push_fw' | translate}}</button>
				</div>
			</div>
		</div>
	</fieldset>
</div>

<div>
	<ul class="insm sidebar-nav">
		<div class="form-group mb-3">
			<input id="search-filter" name="tv-side-search" [ngModel]="searchText" (ngModelChange)="applySearchFilter($event)" offset="15" placeholder="Search/Filter" class="form-control"/>
		</div>
		<li *ngFor="let customer of customers">
			<a class="customer pointer" (click)="toggleExpand(customer)">
				<i class="fa mr-1" [ngClass]="{'fa-plus-circle': !customer.expanded, 'fa-minus-circle': customer.expanded}" aria-hidden="true"></i>
				<span>{{customer.customer_name}}</span>
			</a>
			<ul *ngIf="customer.expanded" class="sidebar-nav">
				<li>
					<table class="table table-striped">
						<tbody>
							<tr>
								<th>{{'g.site' | translate}}</th>
								<th>{{'devices.update_fw' | translate}}</th>
								<th>{{'site.up_to_date_site_fw' | translate}}</th>
								<th>{{'site.show_requests_history' | translate}}</th>
							</tr>
							<tr *ngFor="let site of customer.sites">
								<td>{{site.name}}</td>
								<td>
									<div class="input-group">
										<select class="form-control" [(ngModel)]="site.deviceFirmwareVersion">
											<option *ngFor="let version of filterVersions(site.device_fw_version)" [value]="version">{{version}}</option>
										</select>
										<div class="input-group-append">
											<button class="btn btn-info" (click)="pushFirmwareUpdate('device', site.id, site.deviceFirmwareVersion)" [disabled]="!site.deviceFirmwareVersion">{{'devices.push_fw' | translate}}</button>
											<button class="btn btn-danger" (click)="abortFirmwareUpdate(site.id)" [disabled]="!site.device_fw_version">{{'g.abort' | translate}}</button>
										</div>
									</div>
								</td>
								<td>
									<button class="btn btn-info" (click)="getUpToDateSiteFw(site)">{{'g.view' | translate}}</button>
								</td>
								<td>
									<fa-icon class="pointer text-primary" icon="list-alt" size="2x" (click)="showFirmwareRequestsLog(site)"></fa-icon>
								</td>
							</tr>
						</tbody>
					</table>
				</li>
			</ul>
		</li>
	</ul>
</div>
