<div class="set-adc mt-5">
	<div class="row">
		<div class="col-3 offset-1 offset-1 txt">{{'adc.internal_prob_raw' | translate}}</div>
		<div class="col-3">
			<input type="number" class="form-control" [(ngModel)]="adc.internal_prob_raw" name="internal_prob_raw"/>
		</div>
		<div class="col-3">
			<ng-select 
				bindLabel="text"
				bindValue="val"
				[items]="enableOptions"
				name="internal_prob_raw_enable"
				[(ngModel)]="adc.internal_prob_raw_enable"></ng-select>
		</div>
	</div>
	<div class="row">
		<div class="col-3 offset-1 txt">{{'adc.halleffect_c' | translate}}</div>
		<div class="col-3">
			<input type="number" class="form-control" [(ngModel)]="adc.halleffect_c" name="halleffect_c"/>
		</div>
		<div class="col-3">
			<ng-select 
				bindLabel="text"
				bindValue="val"
				[items]="enableOptions"
				name="halleffect_c_enable"
				[(ngModel)]="adc.halleffect_c_enable"></ng-select>
		</div>
	</div>
	<div class="row">
		<div class="col-3 offset-1 txt">{{'adc.ext_adc_voltage' | translate}}</div>
		<div class="col-3">
			<input type="number" class="form-control" [(ngModel)]="adc.ext_adc_voltage" name="ext_adc_voltage"/>
		</div>
		<div class="col-3">
			<ng-select 
				bindLabel="text"
				bindValue="val"
				[items]="enableOptions"
				name="ext_adc_voltage_enable"
				[(ngModel)]="adc.ext_adc_voltage_enable"></ng-select>
		</div>
	</div>
	<div class="row">
		<div class="col-3 offset-1 txt">{{'adc.ext_adc_current' | translate}}</div>
		<div class="col-3">
			<input type="number" class="form-control" [(ngModel)]="adc.ext_adc_current" name="ext_adc_current"/>
		</div>
		<div class="col-3">
			<ng-select 
				bindLabel="text"
				bindValue="val"
				[items]="enableOptions"
				name="ext_adc_current_enable"
				[(ngModel)]="adc.ext_adc_current_enable"></ng-select>
		</div>
	</div>
	<div class="row">
		<div class="col-3 offset-1 txt">{{'adc.current_low' | translate}}</div>
		<div class="col-3">
			<input type="number" class="form-control" [(ngModel)]="adc.current_low" name="current_low"/>
		</div>
		<div class="col-3">
			<ng-select 
				bindLabel="text"
				bindValue="val"
				[items]="enableOptions"
				name="current_low_enable"
				[(ngModel)]="adc.current_low_enable"></ng-select>
		</div>
	</div>
	<div class="row">
		<div class="col-3 offset-1 txt">{{'adc.temperature_adc' | translate}}</div>
		<div class="col-3">
			<input type="number" class="form-control" [(ngModel)]="adc.temperature_adc" name="temperature_adc"/>
		</div>
		<div class="col-3">
			<ng-select
				bindLabel="text"
				bindValue="val"
				[items]="enableOptions"
				name="temperature_adc_enable"
				[(ngModel)]="adc.temperature_adc_enable"></ng-select>
		</div>
	</div>
	<div class="text-center mt-5">
		<button (click)="readADC()" class="btn btn-info mr-2">{{'g.read' | translate}}</button>
		<button (click)="setADC()" class="btn btn-success">{{'adc.sensing_simulation' | translate}}</button>
	</div>
</div>