import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AdminService } from "../../admin.service";
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';

@Component({
    selector: 'app-connectivity-verification-statistics',
    templateUrl: './connectivity-verification-statistics.component.html',
    styleUrls: ['./connectivity-verification-statistics.component.css']
})

export class ConnectivityVerificationStatisticsComponent implements OnInit, OnDestroy {
    connectivityLogStatistics: any[] = [];
    sitesInfo: any;
    devicesInfo: any;
    devicesData: any[] = [];
    sitesData: any[] = [];

    @ViewChild("devicesInfoModel") devicesInfoModel;
    @ViewChild("sitesInfoModel") sitesInfoModel;

    constructor(
        private adminService: AdminService,
        private usersService: UsersService,
        private router: Router,
    ) {}

    ngOnInit() {
        if (!this.usersService.hasAccessPermission(null, 'noc'))
            this.router.navigate(['/unauthorized']);
        this.getConnectivityVerificationStatistics();
    }

    getConnectivityVerificationStatistics() {
        this.adminService.getConnectivityVerificationStatistics().subscribe((response: any) => {
            if (!Object.keys(response).length)
                return;
            this.connectivityLogStatistics = response.statisticsLogs;
            this.sitesInfo = response.sitesInfo;
            this.devicesInfo = response.devicesInfo;
        });
    }

    showDevicesInfoModal(data) {
        this.devicesData = data;
        this.devicesInfoModel.show();
    }

    showSitesInfoModal(data) {
        this.sitesData = data;
        this.sitesInfoModel.show();
    }

    ngOnDestroy() {
        this.sitesInfoModel.hide();
        this.devicesInfoModel.hide();
    }
}
