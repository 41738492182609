import { ChangeDetectorRef, Component, ElementRef, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EnterpriseService } from 'src/app/enterprise/enterprise.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SitesService } from 'src/app/sites/sites.service';
import { DeviceManagementService } from '../../device-management.service';
import { CustomersService } from 'src/app/customers/customers.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-site-request-edit',
	templateUrl: './site-request-edit.component.html',
	styleUrls: ['./site-request-edit.component.css']
})
export class SiteRequestEditComponent implements OnInit {

	siteId: number;
	site: any;
	sitesList: any;
	enterpriseList: any = [];
	customersList: any = [];
	newCustomerSelected: number = 0;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private chRef: ChangeDetectorRef,
		private translateService: TranslateService,
		private notificationMessageService: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
		private customersService: CustomersService,
		public sitesService: SitesService,
		private enterpriseService: EnterpriseService,
		public usersService: UsersService,
		private el: ElementRef,
		private renderer: Renderer2,
	) { }

	ngOnInit() {
		this.route.params.subscribe(
			params => {
				this.siteId = Number(params['siteId']);
				if(!this.siteId) {
					return this.router.navigateByUrl('/404');
				}
			}
		);
		this.getCustomers();
		this.getSite();
	}
	
	getCustomers() {
		this.customersService.getCustomers({ customer_name: '', partial: false }).subscribe((customers:	any) => {
			if (customers.length) {
				let customersList: any = [{ id: 0, name: this.translateService.instant('customer.original_customer') }];
				
				customers.map((customer: any) => customersList.push({ id: customer.id, name: customer.customer_name}));
				this.customersList = customersList;
			}
		});
	}

	getSite() {
		this.deviceManagementService.getPendingSites({siteId: this.siteId}).subscribe((res) => {
			if(res[0]) {
				let siteInfo = res[0];
				
				this.site = {
					id: siteInfo.site_id,
					name: siteInfo.site_name,
					country_id:  siteInfo.country_id,
					state_id: siteInfo.state_id,
					address: siteInfo.address,
					latitude: siteInfo.latitude,
					longitude: siteInfo.longitude,
					zone_id: siteInfo.zone_id,
					enterprise_ids: [siteInfo.enterprise_id],
					installation_date: new Date(new Date(siteInfo.installation_date).setHours(0, 0, 0, 0)),
					contact_name: '',
					phone_num: '',
					email: '',
				};
				this.prepareSitesList(siteInfo.customer_id);
				this.prepareEnterpriseList(siteInfo.enterprise_id);
			}
		});
	}

	prepareSitesList(customerId) {
		this.sitesService.getCustomerSites(customerId, {include_zombie_sites: true}).subscribe((res: any) => {
			
			if (!this.usersService.hasAccessFunction('super_admin', 'write'))
				res.site = res.site.filter((site: any) => !site.is_special);
			
			this.sitesList = res.site;
			this.sitesList.unshift(
				{id: this.siteId, name: this.translateService.instant('device_management.none_new_site')}
			);
		});
	}

	prepareEnterpriseList(enterpriseId) {
		this.enterpriseService.getEnterprise(enterpriseId, {source: 'manage_device'}).subscribe((res: any) => {
			this.enterpriseList = [res];
		});
	}

	approveSite() {
		let tempSite = Object.assign({}, this.site);
		tempSite.newCustomerId = this.newCustomerSelected;

		let installationDate = new Date(tempSite['installation_date']);
		tempSite['installation_date'] = Math.floor((-installationDate.getTimezoneOffset() * 60000 + installationDate.getTime()) / 1000);

		this.deviceManagementService.approveSiteRequest(this.siteId, tempSite).subscribe((res: any) => {
			if ([2, 3, 4].includes(res.api_status)) { // this API status related error in site name field
				const form = this.el.nativeElement;
				const ele = form.querySelectorAll('[name=name]');
				if(ele && ele[0])
					this.renderer.addClass(ele[0], 'invalid-input');

				const statusMessages: Record<number, string> = {
					2: 'site.reserved_site_name',
					3: 'site.site_name_cannot_changed',
					4: 'site.site_name_already_exist',
				};

				const msg = statusMessages[res.api_status];
				return this.notificationMessageService.setMessage(`translate|${msg}`, { clearOnXTimeNavigate: 1 });
			}

			this.notificationMessageService.setMessage('globalSuccessMsg');
			this.goBack();
		});
	}

	updateSiteCoords(coords) {
		this.site['latitude'] = coords['lat'];
		this.site['longitude'] = coords['lng'];
		this.site['address'] = coords['address'];
		this.site['country_id'] = coords['country'];
		this.site['state_id'] = coords['state'];
		this.chRef.detectChanges();
	}

	goBack() {
		this.router.navigate(['/management/device-management-requests/sites']);
	}
}
