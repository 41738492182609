import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-sched-control',
	templateUrl: './sched-control.component.html',
})

export class SchedControlComponent implements OnInit {

	schedFunctions = <any>[];
	numberOfServers = 1;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private adminService: AdminService,
	) {}

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('sched_functions_control'))
			return this.router.navigate(['/unauthorized']);
		this.adminService.getSchedFunctionsStatus().subscribe(
			data => {
				this.schedFunctions = data;
				this.numberOfServers = (Object.keys(data).length ? Object.keys(data).length : 1);
			}
		);
	}

	updateSchedFunctionsStatus() {
		this.adminService.updateSchedFunctionsStatus(this.schedFunctions).subscribe();
	}
}