import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { SitesService } from 'src/app/sites/sites.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { CustomersService } from 'src/app/customers/customers.service';

import * as _ from 'underscore';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-zombie-sites',
	templateUrl: './zombie-sites.component.html',
	styleUrls: ['./zombie-sites.component.css']
})
export class ZombieSitesComponent implements OnInit, AfterViewInit {

	customerId = 0;
	sites = <any>[];

	@ViewChild("confirmModal") confirmModal;

  	constructor(
		private sitesService: SitesService,
		private customersService : CustomersService,
		private router: Router,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private translate: TranslateService,
		private sideMenuService: SideMenuService,
		public usersService: UsersService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();

		this.route.params.subscribe(
			params => {
				this.customerId = Number(params['customerId']) || 0;
				if (this.customerId) {
					this.getZombieSites();
				}
			}
		);
	}

	ngAfterViewInit() {
		this.confirmModal.onClose.subscribe((result) => {
			if(!result[0])
				return;

			return this.deleteSite(result[1]);
		});
	}

	getZombieSites() {
		this.customersService.getCustomerZombieSites(this.customerId).subscribe(
			(data: any) => {
				this.sites = _.sortBy(_.values(data['site']), 'name');
			}
		);
	}

	goToEditSite(id) {
		if (this.customerId) {
			this.router.navigate(['/sites', this.customerId, 'edit', id], {queryParams: {source: "z"}} );
		}
	}

	confirmDeleteSite(data) {
		this.confirmModal.passData = data;
		this.confirmModal.message = 'g.del_site_prompt';
		this.confirmModal.show();
	}

	deleteSite(id) {
		this.sitesService.deleteSite(id).subscribe(
			(data: any) => {
				if(data) {
					switch (data.api_status) {
						case 2:
							this.notificationMessage.setMessage('translate|site.delete_site_with_devices', { clearOnXTimeNavigate: 1 });
							break;
						case 3:
							this.notificationMessage.setMessage('translate|site.delete_site_with_studies', { clearOnXTimeNavigate: 1 });
							break;
						default:
							this.notificationMessage.setMessage('globalSuccessMsg', { clearOnXTimeNavigate: 1 });
							this.getZombieSites();
							break;
					}
				} else {
					this.notificationMessage.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
				}
			}
		);
	}
}