import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';

@Component({
	selector: 'app-site-attachments',
	templateUrl: './site-attachments.component.html',
	styleUrls: ['./site-attachments.component.css']
})
export class SiteAttachmentsComponent implements OnInit {

	siteId: number;

	permissionsSub: Subscription = new Subscription();
	source: string = 'site';

	constructor(
		private route: ActivatedRoute,
		private sideMenuService: SideMenuService,
	) { }

	ngOnInit() {
		this.sideMenuService.currentSite.subscribe(data => {
			if (!data || !data['id'])
				return ;

			let siteId = Number(this.route.parent.snapshot.params['siteId']);
			let customerId = Number(this.route.parent.snapshot.params['customerId']);
			
			if (data['id'] == siteId && data['customer_id'] == customerId)
				this.siteId = data['id'];
		});
	}

	ngOnDestroy() {
		this.permissionsSub.unsubscribe();
	}

}
