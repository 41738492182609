<div class="row">
	<div class="col-12">
		<h2>{{'nav.rma' | translate}}</h2>
		<div class="alert alert-warning" role="alert">
			{{'rma.note' | translate}}
		</div>
		<form #searchDeviceForm="ngForm" class="row" (ngSubmit)="getDevices()">
			<div class="col-lg-6 col-md-8 col-sm-12 mt-4">
				<div class="my-2">
					<input name="searchString" [(ngModel)]="searchString" trim-text class="form-control"
						placeholder="{{'devices.serial_number' | translate}}/{{'g.mac_address' | translate}}" required />
				</div>
				<div class="mt-3 input-group">
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio1" value="sn">
						<label class="form-check-label" for="inlineRadio1">{{'devices.serial_number' | translate}}</label>
					</div>
					<div class="form-check form-check-inline">
						<input class="form-check-input" type="radio" name="searchBy" [(ngModel)]="searchBy" id="inlineRadio2" value="mac">
						<label class="form-check-label" for="inlineRadio2">{{'g.mac_address' | translate}}</label>
					</div>
				</div>
				<div class="mt-3">
					<button type="submit" class="btn btn-primary pointer" [disabled]="searchDeviceForm.invalid">{{'g.search' | translate}}</button>
				</div>
			</div>
		</form>

		<div class="col-md-6 my-5" *ngIf="searchResult?.length == 0">
			<b>{{'g.no_results_found' | translate}}</b>
		</div>

		<div class="col-md-12 my-5" *ngIf="searchResult?.length">
			<h3>{{'g.results' | translate}}:</h3>

			<div class="row py-2">
				<div class="col-md-2"><strong>{{'g.mac_address' | translate}}</strong></div>
				<div class="col-md-2"><strong>{{'devices.serial_number' | translate}}</strong></div>
				<div class="col-md-2"><strong>{{'g.purchase_order' | translate}}</strong></div>
				<div class="col-md-2"><strong>{{'g.customer_name' | translate}}</strong></div>
				<div class="col-md-2"><strong>{{'g.site_name' | translate}}</strong></div>
				<div class="col-md-2"><strong>{{'actions.move_to_rma' | translate}}</strong></div>
			</div>
			<div *ngFor="let device of searchResult" class="row py-2">
				<div class="col-md-2">
					<a [routerLink]="['/', sites[device.site_id]?.customer_id, device.site_id, device.mac_address, 'performance']" class="blue-hover pointer ml-1" target="_blank">
						{{device.mac_address}}
					</a>
				</div>
				<div class="col-md-2">{{device.serial_number}}</div>
				<div class="col-md-2">{{device.po_number || '-----'}}</div>
				<div class="col-md-2">
					<a [routerLink]="['/', sites[device.site_id]?.customer_id]" class="blue-hover pointer ml-1" target="_blank">
						{{sites[device.site_id]?.customer_name}}
					</a>
				</div>
				<div class="col-md-2">
					<a [routerLink]="['/', sites[device.site_id]?.customer_id, sites[device.site_id]?.id]" class="blue-hover pointer ml-1" target="_blank">
						{{sites[device.site_id]?.name}}
					</a>
				</div>
				<div class="col-md-2">
					<div *ngIf="!sites[device.site_id].is_special">
						<button type="button" class="btn btn-info" (click)="rmaNoModal.hide(); rmaNoModal.show();">{{'actions.move' | translate}}</button>
					</div>
					<div *ngIf="sites[device.site_id].is_special">
						<span class="text-danger">{{'rma.device_in_special_site' | translate}}</span>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>

<app-modal #rmaNoModal title="{{ 'rma.rma_no' | translate }}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div>
		<input name="rma_no" [(ngModel)]="rmaNo" trim-text placeholder="{{'rma.rma_no' | translate}}" class="form-control" required />
		<span *ngIf="errorText && errorText != ''" class="text-danger">{{errorText}}</span>
	</div>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="rmaNoModal.hide(); rmaNo = ''">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="rmaConfirmation.show(); rmaNoModal.hide();">{{'g.ok' | translate}}</button>
	</div>
</app-modal>

<app-modal #rmaConfirmation title="{{ 'rma.confirm_action' | translate }}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div>
		{{'rma.confirmation_popup' | translate}}
	</div>
	<div class="modal-footer">
		<button class="btn btn-default mr-2" (click)="rmaConfirmation.hide(); rmaNo = ''">{{'g.cancel' | translate}}</button>
		<button class="btn btn-primary" (click)="moveDeviceToRMA(); rmaConfirmation.hide();">{{'g.ok' | translate}}</button>
	</div>
</app-modal>
<app-modal #nocEmailRmaModal title="{{'rma.emailing_noc_promt' |translate}}" [hasConfirmButton]="true" [hasCloseButton]="false">
	{{'rma.noc_email_prompt' | translate:{"email": nocEmail} | translate}}
	<a href="mailto:{{nocEmail}}">{{nocEmail}}</a>
	{{'rma.noc_email_msg' |translate}}
</app-modal>
