import { UsersService } from './../../../users/users.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../admin.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-admin-actions',
	templateUrl: './admin-actions.component.html',
	styleUrls: ['./admin-actions.component.css'],
})
export class AdminActionsComponent implements OnInit {
	readonly commandsEnum = {
		generateDailyDetails: 'generateDailyDetails',
		generateDeviceAnalytics: 'generateDeviceAnalytics',
		generateDevicePerformance: 'generateDevicePerformance',
		generateSiteData: 'generateSiteData',
		generateMaintenanceSiteReport: 'generateMaintenanceSiteReport',
	};
	commands: {key: string, text: string}[] = [
		{ key: this.commandsEnum.generateDailyDetails, text: this.translateService.instant('devices.regenerate_daily_details') },
		{ key: this.commandsEnum.generateDeviceAnalytics, text: 'Generate NEW Device Analytics' },
		{ key: this.commandsEnum.generateDevicePerformance, text: 'Generate Device Performance' },
		{ key: this.commandsEnum.generateSiteData, text: 'Generate Site Reports' },
		{ key: this.commandsEnum.generateMaintenanceSiteReport, text: 'Generate Maintenance Site Report' },
	];

	command: string;
	macAddress: string;
	siteId: string;
	fileName: string;
	excludeLargeSites: boolean;

	constructor(
		private router: Router,
		public userService: UsersService,
		private adminService: AdminService,
		private notificationMessageService: NotificationMessageService,
		private translateService: TranslateService
	) {
	}

	ngOnInit() {
		if(!this.userService.hasAccessFunction('sched_functions_control') && !this.userService.hasAccessFunction('super_admin', 'write'))
			this.router.navigate(['/unauthorized']);
	}

	forceWebsiteRefresh() {
		this.adminService.forceWebsiteRefresh().subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}

	commandHasMacOption() {
		return [this.commandsEnum.generateDailyDetails, this.commandsEnum.generateDeviceAnalytics].includes(this.command);
	}

	commandHasSiteOption() {
		return [this.commandsEnum.generateSiteData, this.commandsEnum.generateDevicePerformance, this.commandsEnum.generateMaintenanceSiteReport, this.commandsEnum.generateDailyDetails, this.commandsEnum.generateDeviceAnalytics].includes(this.command);
	}

	commandHasExcludeSitesOption() {
		return [this.commandsEnum.generateDailyDetails, this.commandsEnum.generateDeviceAnalytics].includes(this.command);
	}

	validateMacAddress() {
		let input = this.macAddress;

		//trim input
		input = input ? input.trim() : '';

		if(!input)
			return true;

		input = input.trim().replace(/:/g, "");
		if(!/^[a-fA-F0-9]{12}$/.test(input))
			return false;

		return true;
	}

	validateSiteId() {
		let input = this.siteId;

		//trim input
		input = input ? input.trim() : '';

		if(!input)
			return true;

		if(!Number.isInteger(+input) || +input < 0)
			return false;

		return true;
	}

	sendGenerateCommand() {
		if(this.commandHasMacOption() && !this.validateMacAddress()) {
			this.notificationMessageService.setMessage('translate|generate_script.invalidMac');
			return;
		}

		if(this.commandHasSiteOption() && !this.validateSiteId()) {
			this.notificationMessageService.setMessage('translate|generate_script.invalidSite');
			return;
		}

		this.adminService.generateScript(this.command, this.macAddress, this.siteId, this.fileName, this.excludeLargeSites).subscribe(() => {
			this.notificationMessageService.setMessage('globalSuccessMsg',{clearOnXTimeNavigate: 1});
		});
	}
}