import { Component, OnInit, Input, OnChanges, Output, EventEmitter } from '@angular/core';
import { SideMenuService } from '../side-menu/side-menu.service';
import { GoogleMapService } from '../google-map/google-map.service';
import { MapsAPILoader } from '@agm/core';
import { SitesService } from '../../sites/sites.service'
import * as mapboxgl from 'mapbox-gl';
import { environment } from 'src/environments/environment';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';

declare const google;

@Component({
	selector: 'app-mapbox-search',
	templateUrl: './mapbox-search.component.html',
	styleUrls: ['./mapbox-search.component.css']
})
export class MapboxSearchComponent implements OnInit, OnChanges {

	@Input() latitude: number;
	@Input() longitude: number;
	@Input() zoom: number= 3;
	@Input() zoomIn: boolean = false;
	@Input() showPopup = true;
	@Input() dots;
	@Input() dotsType;
	@Input() clickEnabled:boolean = false;
	@Output() onAddressChanged = new EventEmitter<Object>();

	center;
	map: Object = {};

	constructor(
		public sideMenuService: SideMenuService,
		private googleMapService: GoogleMapService,
		private mapsAPILoader: MapsAPILoader,
		private sitesService: SitesService,
	) {
	}

	ngOnInit() {
		this.center = [this.longitude, this.latitude];

		this.mapsAPILoader.load().then((goog) => {
			this.setCurrentPosition({
				lngLat: {
					lng: this.center[0],
					lat: this.center[1]
				}
			})
			
		});
		// this.mapRef.resize.emit();
		// this.map.mapEvents.resize.emit();
	}

	ngOnChanges(changes){
		if(changes.longitude && changes.latitude){
			this.center = [changes.longitude.currentValue || this.sitesService.defaultMapLocation.longitude, changes.latitude.currentValue || this.sitesService.defaultMapLocation.latitude];
			this.generateMapBox();
		}
		if(this.dots && this.dots.length){
			for (let dot of this.dots) {
				dot.opened = false;
			}
		}
		// if(changes.dots)
		// 	this.sideMenuService.reDrawMap();
	}

	setCurrentPosition(event, updateDots=true) {
		if(!this.clickEnabled) {
			return;
		}
		let pinLocation = event.lngLat||null;
		if(pinLocation) {
			let lat = pinLocation.lat,
				lng = pinLocation.lng;
			this.map['lat'] = lat;
			this.map['lng'] = lng;
			if(updateDots) {
				this.dots[0].latitude = lat;
				this.dots[0].longitude = lng;
			}
			if(this.zoomIn) {
				this.zoom = 50;
			}
			this.googleMapService.getAddressName({lat, lng}).subscribe(
				data => {
					if(!data['features'].length) {
						this.map['address'] = '';
						this.map['country'] = '';
						this.map['state'] = '';
						this.onAddressChanged.emit(this.map);
						return;
					}
					data = data['features'][0]['properties'];
					this.map['address'] = data['full_address'];
					this.map['country'] = data['context']['country']['name'];
					this.map['state'] = data['context']['region']['name'];
					this.onAddressChanged.emit(this.map);
				}
			);
		}
	}

	onGeocoderResult(event) {
		this.zoom=13;
		this.setCurrentPosition({
			lngLat: {
				lng: event.result.center[0],
				lat: event.result.center[1]
			}
		})
	}

	generateMapBox() {
		if(this.latitude !== undefined && this.longitude !== undefined && this.sideMenuService.showMap) {
			(mapboxgl as typeof mapboxgl).accessToken = environment.mapbox.accessToken;
			const map = new mapboxgl.Map({
				container: 'map',
				style: 'mapbox://styles/mapbox/light-v10',
				center: this.center,
				zoom: this.zoom,
			});
			map.on('click', (e) => {
				this.setCurrentPosition(e);
			});
		
			if (this.clickEnabled) {
				const geocoder = new MapboxGeocoder({
					accessToken: environment.mapbox.accessToken,
					mapboxgl: mapboxgl,
				});
				geocoder.on('result', (result)=> {
					this.onGeocoderResult(result);
				})
				map.addControl(geocoder);
			}

			// Set marker options.
			new mapboxgl.Marker({
				color: "#23ABE2",
				draggable: true,
			})
			.setLngLat([this.dots[0].longitude, this.dots[0].latitude])
			.addTo(map);
		}
	}
}