import { Component, OnInit, ElementRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeviceManagementService } from 'src/app/device/device-management.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-device-edit-page',
	templateUrl: './device-edit-page.component.html',
	styleUrls: ['./device-edit-page.component.css']
})
export class DeviceEditPageComponent implements OnInit {

	mac_address: string = '';
	siteId: number = 0;
	customerId: number = 0;
	isNew: boolean = true;
	isCMdevicesMode: boolean = false;

	device: any = {};
	oldDevice: any = {};

	constructor(
		private route: ActivatedRoute,
		private deviceManagementService: DeviceManagementService,
		private elementRef: ElementRef,
		private notificationMessage: NotificationMessageService,
		private router: Router,
		private sideMenuService: SideMenuService,
		private usersService: UsersService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		this.route.params.subscribe( params => {
			this.siteId = +params['siteId'] || 1;
			this.customerId = +params['customerId'] || 1;
			this.mac_address = params['deviceId'] || 0;
			this.isCMdevicesMode = this.route.snapshot.data['cmDevice'];
			this.isNew = +this.mac_address == 0;
			
			if(
				(this.isNew && !this.usersService.hasAccessFunction('add_device')) ||
				(!this.isNew && !this.usersService.hasAccessFunction('device_management'))
			)
				return this.router.navigate(['/unauthorized']);

			if(!this.isNew) {
				this.getDevice();
			}
		});
	}

	getDevice() {
		this.deviceManagementService.getDeviceInfo(this.mac_address).subscribe((data: any) => {
			let device: any = {};
			device.id				= data.config_info.id;
			device.mac_address		= data.config_info.mac_address;
			device.serial_number	= data.config_info.serial_number;
			device.hw_version		= data.config_info.hw_version;

			this.device = device;
			this.oldDevice = Object.assign({}, this.device);
		});
	}

	saveDevice() {
		if(this.isNew) {
			this.deviceManagementService.addDevice(this.device.mac_address, this.device.serial_number, this.device.hw_version, this.siteId).subscribe(
				(data: any) => this.handleResponse(data)
			);
		} else {
			this.deviceManagementService.updateDevice(this.device.mac_address, this.device.serial_number, this.device.hw_version, this.siteId, {cmDevice: this.isCMdevicesMode}).subscribe(
				(data: any) => this.handleResponse(data)
			);
		}
	}

	handleResponse(data) {

		this.notificationMessage.closeNotification();
		let invalidInputs = this.elementRef.nativeElement.querySelectorAll('.invalid-input');
		invalidInputs.forEach((item) => {
			item.classList.remove('invalid-input');
		});

		if(data.api_status) {
			switch (data.api_status) {
				case 2:
					if(this.isCMdevicesMode) {
						this.notificationMessage.setMessage('translate|g.duplicate_mac_for_cm', {clearOnXTimeNavigate: 1});
					} else {
						this.notificationMessage.setMessage('translate|g.duplicate_mac', {clearOnXTimeNavigate: 1});
					}
					break;
				case 4:
					this.notificationMessage.setMessage('translate|g.duplicate_sn', {clearOnXTimeNavigate: 1});
					break;
				case 3:
					for (let field of data.response.invalid_fields) {
						var ele = this.elementRef.nativeElement.querySelector('[name=' + field + ']');
						if(ele)
							ele.classList.add('invalid-input');
					}
					this.notificationMessage.setMessage('translate|g.invalid_input', {clearOnXTimeNavigate: 1});
					break;
			}
			return;
		}
		
		this.notificationMessage.setMessage('globalSuccessMsg');
		this.goBack();
	}

	goBack() {
		let pageURL = ['/devices', this.customerId, this.siteId];
		if(this.isCMdevicesMode) {
			pageURL = ['admin-panel','cm-devices'];
		}
		// this.navigation.goBack(pageURL);
		this.router.navigate(pageURL);
	}
}