<app-modal #trackModal title="{{'config.configurations' | translate}}" [hasConfirmButton]="false" size="lg">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.property' | translate}}</th>
				<th>{{'g.value' | translate}}</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let key of trackModalData | keys">
			<td>{{'qv.'+key | translate}}</td>
			<td>
				<span *ngIf="isObject(trackModalData[key]); else elseBlock">
					<table>
						<thead>
							<tr>
								<th>{{'g.property' | translate}}</th>
								<th>{{'g.value' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let subKey of trackModalData[key] | keys">
								<td>{{'qv.'+subKey | translate}}</td>
								<td>{{trackModalData[key][subKey]}}</td>
							</tr>
						</tbody>
					</table>
				</span>
				<ng-template #elseBlock>
					{{trackModalData[key]}}
				</ng-template>
			</td>
		</tr>
		</tbody>
	</table>
</app-modal>

<app-modal #compareModal title="{{'config.compare_configurations' | translate}}" [hasConfirmButton]="false" size="lg">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.property' | translate}}</th>
				<th>{{'g.old' | translate}}</th>
				<th>{{'g.new' | translate}}</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let key of compareModalData.old | keys">
			<td>{{'qv.'+key | translate}}</td>
			<td>
				<span *ngIf="isObject(compareModalData.old[key]); else compareElseBlock">
					<table>
						<thead>
							<tr>
								<th>{{'g.property' | translate}}</th>
								<th>{{'g.value' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let subKey of compareModalData.old[key] | keys">
								<td>{{'qv.'+subKey | translate}}</td>
								<td>{{compareModalData.old[key][subKey]}}</td>
							</tr>
						</tbody>
					</table>
				</span>
				<ng-template #compareElseBlock>
					{{compareModalData.old[key]}}
				</ng-template>
			</td>
			<td>
				<span *ngIf="isObject(compareModalData.new[key]); else compareElseBlock2">
					<table>
						<thead>
							<tr>
								<th>{{'g.property' | translate}}</th>
								<th>{{'g.value' | translate}}</th>
							</tr>
						</thead>
						<tbody>
							<tr *ngFor="let subKey of compareModalData.new[key] | keys">
								<td>{{'qv.'+subKey | translate}}</td>
								<td>{{compareModalData.new[key][subKey]}}</td>
							</tr>
						</tbody>
					</table>
				</span>
				<ng-template #compareElseBlock2>
					{{compareModalData.new[key]}}
				</ng-template>
			</td>
		</tr>
		</tbody>
	</table>
</app-modal>

<div class="white-box p-4 border-radius mt-2">
	<div class="text-danger" *ngIf="invalidDateRange">
		<b>{{'g.start_lg_end' | translate}}</b>
	</div>
	
	<div class="panel panel-default">
		<div class="panel-body">
			<div class="col-md-12">
				<div *ngIf="gridData && gridData.length > 0">
					<button class="btn btn-info" (click)="compareConfigs()" [disabled]="!configsToCompare" [attr.title]="!configsToCompare ? ('config.select_msg' | translate) : ''">{{'config.compare_configurations' | translate}}</button>
					<app-grid
						[data]="gridData" 
						[columns]="gridColumns"
						[excludeOnExport]="['configs']"
						#agGrid
						id="cellular-track"
						(emitEventToParent)="gridEvent($event)"
					>
					</app-grid>
				</div>
				<server-pagination idField="id" [limit]="recordsLimit" (paginate)="getCellularConfigTrack($event)" #serverPagination></server-pagination>
			</div>
			<div *ngIf="gridData && gridData.length == 0">{{'g.no_data_to_show' | translate}}</div>
		</div>
	</div>
</div>