<div>
	<h1 class="h2 m-4">{{'audit.user_audit' | translate}}</h1>
	<div class="mb-4" *ngFor="let user of this.data">
		<app-simple-widget [isCollapsed]="true" title="{{user.first_name}} {{user.last_name}} - {{user.email}}">
			<p *ngIf="user.isSCT">{{'g.sct_user' | translate}}</p>
			<div class="mb-2">
				<app-simple-widget [title]="('p.titles.permissions' | translate)+' ('+user.permission_name+')'" [isCollapsed]="true" *ngIf="user.permissions">
					<div *ngFor="let field of user.permissions | keys" class="row mt-2">
						<div class="col-md-6">
							{{'p.permissions.'+field | translate}}
						</div>
						<div class="col-md-6">
							<div class="col-md-6">
								{{'g.'+user.permissions[field] | translate}}
							</div>
						</div>
					</div>
				</app-simple-widget>
			</div>
			<div class="mb-2">
				<app-simple-widget [title]="'g.access_functions' | translate" [isCollapsed]="true" *ngIf="user.access_functions">
					<div *ngFor="let field of user.access_functions | keys" class="row mt-2">
						<div class="col-md-6">
							{{'access_func.'+field | translate}}
						</div>
						<div class="col-md-6">
							<div class="col-md-6">
								{{'g.'+user.access_functions[field] | translate}}
							</div>
						</div>
					</div>
				</app-simple-widget>
			</div>
		</app-simple-widget>
	</div>
</div>