import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from '../users.service';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-edit-access-functions',
	templateUrl: './edit-access-functions.component.html',
	styleUrls: ['./edit-access-functions.component.css']
})
export class EditAccessFunctionsComponent implements OnInit {

	userId: number;
	accessOptions: any[];
	boolAccessOptions: any[];
	accessOptionsToAdd: any[] = [];
	newAccessFunction: any = {};
	accessFunctions: any = {};
	userAccessFunctions: any = {};
	docAccessFunction;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private translateService: TranslateService,
		private notificationMessage: NotificationMessageService,
		private usersService: UsersService,
		private sideMenuService: SideMenuService,
		private commonService: CommonService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		this.accessOptions = [
			{'id': 'read', 'text': this.translateService.instant('g.read')},
			{'id': 'write', 'text': this.translateService.instant('g.write')}
		];
		
		this.boolAccessOptions = [
			{'id': 'yes', 'text': this.translateService.instant('g.yes')},
			{'id': 'no', 'text': this.translateService.instant('g.no')}
		];
		
		this.route.params.subscribe( (params: any) => {
			this.userId = Number(params.userId);

			this.getUserAccessFunctions();
		});
	}

	getKeys(obj) {
		return Object.keys(obj);
	}

	goBack() {
		this.router.navigate(['/admin-panel/users']);
	}

	editAccessFunction(mode, data) {
		
		if(mode == 'del') {

			var functionKey = data;
			delete this.userAccessFunctions[functionKey];

			// add removed access function to list of available access functions
			this.accessOptionsToAdd.push(functionKey);
			return;
		}

		//mode == 'add'
		var functionKey = data.key;
		
		// remove added access function from list of available access functions
		let idx = this.accessOptionsToAdd.indexOf(functionKey);

		if(idx != -1)
			this.accessOptionsToAdd.splice(idx, 1)
		
		this.userAccessFunctions[functionKey] = data.access;
		
		this.newAccessFunction = {};
	};

	getUserAccessFunctions() {
		
		if(!this.userId)
			return;
		
		this.usersService.getUserAccessFunctions(this.userId).subscribe((data:any) => {
			this.accessFunctions = data.access_functions_data;
			this.userAccessFunctions = data.access_functions;

			let accessOptionsToAdd = [];

			for(let func in this.accessFunctions) {
				if(!this.userAccessFunctions.hasOwnProperty(func))
					accessOptionsToAdd.push(func)

				if(!this.accessFunctions[func].hasReadOption && this.userAccessFunctions.hasOwnProperty(func)){
					if(this.userAccessFunctions[func] == 'write')
						this.userAccessFunctions[func] = 'yes';
					else
						this.userAccessFunctions[func] = 'no';
				}
			}

			this.accessOptionsToAdd  = accessOptionsToAdd.map((item) => ({'label': this.translateService.instant("access_func."+item), 'value': item}));
			this.accessOptionsToAdd = this.commonService.sortDataAlphabetically(this.accessOptionsToAdd, 'label');
		});
	}

	updateUserAccessFunctions() {
		let tempAccess = {};
		for(let func in this.userAccessFunctions) {
			tempAccess[func] = this.userAccessFunctions[func];
			if(this.userAccessFunctions[func] == 'yes')
				tempAccess[func] = 'write';
			if(this.userAccessFunctions[func] == 'no')
				tempAccess[func] = '';
		}

		this.usersService.updateUserAccessFunctions(this.userId, tempAccess).subscribe((data) => {
			this.notificationMessage.setMessage('globalSuccessMsg');
			this.goBack();
		});
	}
}