import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpInterceptor, HttpErrorResponse, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoaderService } from './loader.service';
import { CustomHttpParams } from './custom-httpParam';
import { NotificationMessageService } from '../notification-message/notification-message.service';
import { NavigationService } from '../services/navigation.service';
import { Location } from '@angular/common';

@Injectable({
	providedIn: 'root'
})
export class LoaderInterceptorService implements HttpInterceptor {
	internetChecked = false;

	private requests: HttpRequest<any>[] = [];

	resStatus = {
		"success":			1,
		"failed":			2,
		"logged_out":		3,
		"not_authorized":	4,
		"app_expired":		5,
		"api_timeout":		6,
		"invalid_captcha":	7,
		"password_expired":	8,
		"terms_agreement":	9,
		"not_found":        404
	};

	resStatusFlipped = {};

	messages: any = {
		"success":			'globalSuccessMsg',
		"failed":			'globalErrMsg',
		"not_authorized":	'globalAuthMsg',
		"api_timeout":		'globalTimeoutMsg'
	};

	constructor(
		private loaderService: LoaderService,
		private router: Router,
		private location: Location,
		private navigation: NavigationService,
		private notificationMessage: NotificationMessageService,
	) {
		let messages = {};

		for(var type in this.resStatus) {
			messages[this.resStatus[type]] = this.messages[type];
		}

		this.messages = messages;

		let resStatusFlipped = {};

		for(var type in this.resStatus) {
			resStatusFlipped[this.resStatus[type]] = type;
		}

		this.resStatusFlipped = resStatusFlipped;
	}

	pushRequest(req: HttpRequest<any>) {

		let noUiBlock = (req.params instanceof CustomHttpParams && req.params.object && req.params.object.noUIBlock);

		if(!noUiBlock) {
			this.requests.push(req);
			this.loaderService.isLoading.next(true);
		}
	}

	removeRequest(req: HttpRequest<any>) {

		let noUiBlock = (req.params instanceof CustomHttpParams && req.params.object && req.params.object.noUIBlock);

		if(!noUiBlock) {
			const i = this.requests.indexOf(req);
			if (i >= 0) {
				this.requests.splice(i, 1);
			}
			this.loaderService.isLoading.next(this.requests.length > 0);
		}

		this.internetChecked = false;
	}

	intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let isExternalUrl = false;
		if (!(req.url.startsWith('https://') || req.url.startsWith('http://'))) {
			if (req.url[0] === '/')
				req = req.clone({ url: '/sctportal' + req.url });
			else
				req = req.clone({ url: '/sctportal/'+ req.url });
		} else {
			isExternalUrl = true;
		}

		this.pushRequest(req);

		return Observable.create(observer => {
			const subscription = next.handle(req).subscribe(
				(event: HttpEvent<any>) => {
					if (event instanceof HttpResponse) {

						this.removeRequest(req);

						let isValid = false;
						if(event.body && isExternalUrl) {
							observer.next(event.clone({body: event.body}));
						} else if(event.body && event.body.response_status) {
							switch(event.body.response_status) {
								case this.resStatus.success:
									isValid = true;
									if(event.body.response && event.body.response.msg)
										this.notificationMessage.setMessage(event.body.response.msg,{clearOnXTimeNavigate: 1, type: 'success'});
								break;
								case this.resStatus.failed:
								case this.resStatus.api_timeout:
								case this.resStatus.not_authorized:
									this.notificationMessage.setMessage((event.body.error && typeof event.body.error == 'string') ? event.body.error : this.messages[event.body.response_status], {clearOnXTimeNavigate: 1});
								break;
								case this.resStatus.invalid_captcha:
									this.notificationMessage.setMessage('globalInvalidCaptcha', {clearOnXTimeNavigate: 1});
								break;
								case this.resStatus.logged_out:
									this.navigation.setPreviousUrl(this.location.path());
									localStorage.setItem('userInfo', '{}');
									this.router.navigate(['/login']);
								break;
								case this.resStatus.password_expired:
									this.router.navigate(['/change-expired-password']);
								break;
								case this.resStatus.terms_agreement:
									this.router.navigate(['/terms-and-conditions']);
								break;
								case this.resStatus.not_found:
									this.router.navigate(['/404']);
								break;
							}

							if(isValid) {
								if(event.body.api_status != 1) {
									observer.next(event.clone({body: {response: event.body.response, api_status: event.body.api_status}}));
								} else {
									observer.next(event.clone({body: event.body.response}));
								}
							}

							let acceptedStatus = (req.params instanceof CustomHttpParams && req.params.object && req.params.object.acceptedStatus);
							if(acceptedStatus && acceptedStatus.includes(this.resStatusFlipped[event.body.response_status]))
								observer.next(event.clone({body: event.body}));
						}
					}
				},
				httpError => {

					this.removeRequest(req);

					var toReturn = httpError;

					if(httpError instanceof HttpErrorResponse) {
						if (httpError.status && !(httpError.status >= 500 && httpError.status <= 599)) {
							this.notificationMessage.setMessage('globalErrMsg');
						} else {
							this.checkHttpError();
						}
						toReturn = httpError.status;
					}

					observer.error(toReturn);
				},
				() => {
					this.removeRequest(req);
					observer.complete();
				}
			);

			return () => {
				this.removeRequest(req);
				subscription.unsubscribe();
			}
		});
	}

	checkHttpError() {
		if (!this.internetChecked)
			this.checkGoogle();
	}

	checkGoogle() {
		this.loaderService.isLoading.next(true);
		fetch('https://www.google.com', { mode: 'no-cors' })
			.then(() => {
				this.internetChecked = true;
				this.notificationMessage.setMessage('translate|g.lost_connect_to_server', { clearOnXTimeNavigate: 1 });
				this.loaderService.isLoading.next(false);
			})
			.catch(() => {
				this.checkMicrosoft();
			});
	}

	checkMicrosoft() {
		fetch('https://www.microsoft.com', { mode: 'no-cors' })
			.then(() => {
				this.notificationMessage.setMessage('translate|g.lost_connect_to_server', { clearOnXTimeNavigate: 1 });
			})
			.catch(() => {
				this.notificationMessage.setMessage('translate|g.lost_internet_connection', { clearOnXTimeNavigate: 1 });
			})
			.finally(() => {
				this.internetChecked = true;
				this.loaderService.isLoading.next(false);
			});
	}
}
