<div class="white-box main-black mt-4 p-4 border-radius" *ngIf="macAddress">
	<app-noc-note *ngIf="userService.hasAccessPermission(null, 'noc')" [device]="device"></app-noc-note>

	<app-note
		[title]="'note.device_notes' | translate"
		[modelType]="'device'"
		[modelId]="macAddress"
		[siteId]="siteId"
		[tooltipKey]="tooltip"
	></app-note>
</div>