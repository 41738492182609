import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import * as moment from 'moment';
import { AdminService } from '../admin.service';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-debug-logs',
  templateUrl: './debug-logs.component.html',
  styleUrls: ['./debug-logs.component.css']
})
export class DebugLogsComponent implements OnInit, OnDestroy {

	@Input() fileName: string = null;

	startLine = 0;
	allLines = '';
	lines = '';

	readonly showLines = 1000;

	intervalID: number = 0;

	constructor(
		private adminService: AdminService,
		private usersServices: UsersService,
		private router: Router
	) { }

	ngOnInit() {
		if(!this.usersServices.getCurrentUser().is_admin)
			this.router.navigate(['/unauthorized']);

		this.initData();
	}

	initData() {
		if(this.fileName) {
			this.getLoggingInfo();
		
			this.intervalID = setInterval(() => {
				this.getLoggingInfo();
			}, 3000); // 1 second
		}
	}

	getLoggingInfo() {
		this.adminService.getDebugLogs(this.startLine, this.fileName).subscribe((data: {log: string}) => {
			if(!data.log.length)
				return;
			this.allLines += (this.lines.endsWith('\n') ? '' : '\n') + data.log;
			this.lines = this.allLines.split('\n').slice(-this.showLines).join('\n');
			this.startLine += data.log.split('\n').length;
		});
	}

	downloadLogFile() {
		const blob = new Blob([this.allLines], { type: 'text/plain' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = `${this.fileName}-${moment().utc().unix()}.log`;
		a.click();
		window.URL.revokeObjectURL(url);
	}

	setFileName(form: NgForm) {
		this.fileName = form.value.fName;
		this.initData();
	}

	ngOnDestroy(): void {
		clearInterval(this.intervalID);
	}
}
