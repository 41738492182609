<div>
	<h3 class="mb-4 font-weight-bold">
		<span class="light-grey"> {{'device_management.header' | translate}}</span>
	</h3>
	<div>
		<nav class="navbar navbar-expand-lg border-radius-top navbar-light bg-white topnav pt-2 pb-0 px-4 nav-border main-black">
			<ul class="navbar-nav mr-auto">
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': activePage == 'devices'}" [routerLink]="['/', 'management', 'device-management-requests']" (click)="activePage = 'devices'">
						<b>{{'device_management.move_requests' | translate}}</b>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': activePage == 'customers'}" [routerLink]="['/', 'management', 'device-management-requests', 'customers']" (click)="activePage = 'customers'">
						<b>{{'device_management.customers_requests' | translate}}</b>
					</a>
				</li>
				<li class="nav-item">
					<a class="nav-link p-3 light-grey small" [ngClass]="{'active': activePage == 'sites'}" [routerLink]="['/', 'management', 'device-management-requests', 'sites']" (click)="activePage = 'sites'">
						<b>{{'device_management.sites_requests' | translate}}</b>
					</a>
				</li>
				<li class="nav-item">
					<a *ngIf="usersService.hasAccessFunction('replace_device')" class="nav-link p-3 light-grey small" [ngClass]="{'active': activePage == 'replacement'}" [routerLink]="['/', 'management', 'device-management-requests', 'replacement']" (click)="activePage = 'replacement'">
						<b>{{'device_management.replacement_requests' | translate}}</b>
					</a>
				</li>
			</ul>
		</nav>
		<router-outlet></router-outlet>
	</div>
</div>