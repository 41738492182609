import { Component, OnInit } from '@angular/core';
import { LoaderService } from './loader.service';
import { CommonDataService } from '../services/common-data.service';

@Component({
	selector: 'app-loader',
	templateUrl: './loader.component.html',
	styleUrls: ['./loader.component.css']
})
export class LoaderComponent implements OnInit {

	show: boolean;
	isChargLink: boolean = false;
	
	constructor(
		private loaderService: LoaderService,
		private commonData: CommonDataService,
	) { }

	ngOnInit() {
		this.isChargLink = this.commonData.chargLinkDomains.includes(window.location.hostname);
		
		this.loaderService.isLoading.subscribe((value) => {
			this.show = value;
		});
	}

}