import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import * as lodash from 'lodash-es';
import { DeviceService } from '../../device.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-config-track',
	templateUrl: './config-track.component.html'
})
export class ConfigTrackComponent implements OnInit, AfterViewInit {
	recordsLimit = 100;
	@Input() device: any = {};
	@Input() currentSite: any;
	@Input() dateRange: {
		fromDate: Date,
		toDate: Date
	} = {
		fromDate: new Date(),
		toDate: new Date()
	};
	@Input() invalidDateRange: boolean = false;
	
	configsToCompare: any;
	gridColumns: any;
	trackModalData: any = {};
	compareModalData: any = {};
	gridData: any[] = null;
	arrayFields: string[] = [];
	objectFields: string[]	= ['mobile_master', 'mobile_network', 'router_network', 'online_network'];
	nestedObjFields: string[] = ['mp_cal_high_range', 'mp_cal_low_range'];

	linksFields: string[] = ['last_saved_user_id'];
	compareSkipFields: string[] = ['last_saved_user_id_link'];

	@ViewChild("serverPagination") serverPagination;
	@ViewChild("trackModal") trackModal;
	@ViewChild("compareModal") compareModal;
	
	@Output() updateAppearanceElementsFlags = new EventEmitter<any>(true);

	constructor(
		private deviceService: DeviceService,
		private commonService: CommonService,
		private translateService: TranslateService,
		private usersService: UsersService,
	) { }

	ngOnInit() {
		this.gridColumns = [
			{headerName: 'g.id', field: "id", type:"number", width: 120},
			{headerName: 'devices.memory_signature', field: "memory_signature", type:"number", width: 200},
			{
				headerName: 'config.configs', field: "configs", width: 200,
				onCellClicked: ($e) => {
					this.onTrackCellClicked($e);
				},
				cellRendererSelector: (params) => {
					params['label'] = this.translateService.instant('config.show_configs');
					return {
						component: 'linkCellRenderer',
						params: params,
					};
				}
			},
			{headerName: 'g.insertion_time', field: "insertion_time", width: 200, type: 'dateTime'},
		];
	}

	ngAfterViewInit() {
		this.getDeviceConfigTrack(false, true);
	}

	ngOnChanges(changes) {
		if (
			(changes.device && changes.device.previousValue && changes.device.previousValue.mac_address != changes.device.currentValue.mac_address) ||
			changes.dateRange
		) {
			this.getDeviceConfigTrack(false, true);
		}
	}

	onTrackCellClicked(cycle) {
		this.trackModalData = this.formatChanges(cycle.data.configs);
		this.trackModal.show();
	}

	formatChanges(configs) {
		let zoneID = (this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id);
		return this.deviceService.formatQueuedChanges(this.device, configs, 'plainObject', zoneID);
	}

	getDeviceConfigTrack(isBack=false, firstTime=false) {
		if(!this.device.mac_address || this.invalidDateRange || !this.serverPagination)
			return;

		if(firstTime)
			this.serverPagination.init();

		const lastId = this.serverPagination.lastId;
		const firstId = this.serverPagination.firstId;
		const limit = this.recordsLimit;

		let fromDateRange = this.dateRange.fromDate;
		let toDateRange = this.dateRange.toDate;
		fromDateRange = new Date(new Date(fromDateRange).setHours(0, 0, 0, 0));
		toDateRange = new Date(new Date(toDateRange).setHours(23, 59, 59, 999));

		let zoneDiff = new Date().getTimezoneOffset() * -1;
		let fromDate: any = new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000));
		let toDate: any = new Date(new Date(toDateRange).getTime() + (zoneDiff * 60 * 1000));
		fromDate = moment(fromDate).utc().startOf('day').unix();
		toDate = moment(toDate).utc().endOf('day').unix();

		this.deviceService.getDeviceConfigTrack(this.device.mac_address, {lastId, firstId, isBack, limit, fromDate, toDate}).subscribe((response: any) => {
			response.forEach((record) => {
				record.insertion_time = this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), record.insertion_time);
				record.insertion_time = moment(record.insertion_time*1000).utc().format('MM/DD/YYYY hh:mm:ss A');

				switch (record.configs.last_saved_user_id) {
					case 0:
					case 0xFFFFFFFF:
						record.configs.last_saved_user_id = this.translateService.instant('g.system');
						break;
					default:
						if (this.usersService.hasAccessFunction('edit_user_admin'))
							record.configs.last_saved_user_id_link = `/user/edit/${record.configs.last_saved_user_id}`;

						record.configs.last_saved_user_id = `${record.configs.last_saved_user_id} - ${record.configs.last_saved_user_name}`;
						delete record.configs.last_saved_user_name;
						break;
				}

				this.formatBooleanFields(record.configs);
			});
			this.gridData = [...response];

			if (this.gridData.length > this.recordsLimit)
				this.gridData.pop();

			this.serverPagination.updatePagination(response, isBack, firstTime);
		});
	}

	gridEvent(event) {
		if(event.selection.length == 2)
			this.configsToCompare = event.selection;
		else
			this.configsToCompare = null;
	}

	formatBooleanFields(recordConfigs) {
		for(let field in recordConfigs) {
			if(this.deviceService.deviceBooleanFields.includes(field))
				recordConfigs[field] = !!recordConfigs[field];
		}
	}
	
	compareConfigs() {
		
		let oldConfig		= {};
		let oldValsIdx		= 1;
		let newConfig		= lodash.cloneDeep(this.configsToCompare[0].configs);
		let ignoreFields	= ['memory_signature'];
		let fieldsToShowBothConfig = ['last_saved_user_id', 'last_saved_user_id_link'];

		if(this.configsToCompare[1].configs.memory_signature > this.configsToCompare[0].configs.memory_signature) {

			newConfig	= lodash.cloneDeep(this.configsToCompare[1].configs);
			oldValsIdx	= 0;
		}

		for(let key in newConfig) {
			if(ignoreFields.includes(key))
				continue;
			
			const handleNestObj = (tempOld, newConfig) => {
				if(tempOld && newConfig) {
					let hasDifference = false;
					let tempObj = {};
					for(let subKey in tempOld) {
						if(tempOld[subKey] != newConfig[subKey]) {
							tempObj[subKey] = tempOld[subKey];
							hasDifference = true;
						} else {
							delete newConfig[subKey];
						}
					}
					if(hasDifference) {
						oldConfig[key] = lodash.cloneDeep(tempObj);
					}
				}
			}
			let tempOld = this.configsToCompare[oldValsIdx].configs[key];
			if(this.objectFields.includes(key)) {
				handleNestObj(tempOld, newConfig[key]);
				
			} else if(this.arrayFields.includes(key)) {
				
				if(tempOld && newConfig[key] && !this.commonService.arrayCompare(tempOld, newConfig[key]))
					oldConfig[key] = tempOld;

			}else if (this.nestedObjFields.includes(key)) {
				handleNestObj(oldConfig[key], newConfig[key]);
			} 
			else if(fieldsToShowBothConfig.includes(key) || tempOld != newConfig[key]) {

				oldConfig[key] = tempOld;
			}
		}
		
		this.compareModalData = {
			'old': this.formatChanges(oldConfig),
			'new': this.formatChanges(newConfig)
		};
		
		this.compareModal.show();
	}

	isObject(value) {
		return typeof value == 'object' && value && Object.keys(value).length > 0;
	}

	hideModals() {
		this.compareModal.hide();
		this.trackModal.hide();
	}
}