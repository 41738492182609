import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-incorrect-events',
	templateUrl: './incorrect-events.component.html',
})

export class IncorrectEventsComponent implements OnInit {
	data: any[] = [];
	columnDefs: any[] = [];
	allColumnsKeys: string[] = [];
	pageSize: number = 50;
	fileName: string = '';
	readonly devicesTypes = { iotah: 0, chargelink: 1 };

	deviceTypeOptions = [
		{id: this.devicesTypes.iotah, name: 'IoTAh'},
		{id: this.devicesTypes.chargelink, name: 'ChargLink'},
	];
	years = [];
	quarters = [
		{id: 0, value: 'All Year'},
		{id: 1, value:'Q1'},
		{id: 2, value:'Q2'},
		{id: 3, value:'Q3'},
		{id: 4, value:'Q4'}
	];

	selectedDeviceType = this.devicesTypes.iotah;
	selectedYear: number = new Date().getFullYear();
	selectedQuarter = this.quarters[0].id;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private devicesService: DeviceService,
		private translate: TranslateService,
	) { }

	ngOnInit() {
		if (!this.usersService.hasAccessPermission(null, 'noc'))
			return this.router.navigate(['/unauthorized']);

		return this.getAllYearsFromDate(new Date(`2015-01-01`));
	}

	getIncorrectEvent() {
		const options = {
			year: this.selectedYear,
			quarter: this.selectedQuarter,
			is_charglink: this.selectedDeviceType == this.devicesTypes.chargelink,
		};

		this.devicesService.getIncorrectEvents(options).subscribe((data: any) => {
			this.data = data;
			this.buildGrid();
		});
	}

	buildGrid() {
		let eventsCountHeaderName = 'incorrect_events.number_of_event.charge';

		if (this.selectedDeviceType == this.devicesTypes.chargelink)
			eventsCountHeaderName = 'incorrect_events.number_of_event.inuse';

		const columnDefs: any[] = [
			{
				headerName: 'incorrect_events.serial_number', field: 'serial_number',
				cellRendererSelector: function (params: any) {
					params['label'] = params.data.serial_number;
					params['link'] = `/#/${params.data.customer_id}/${params.data.site_id}/${params.data.mac_address}/performance`;
					return {
						component: 'linkCellRenderer',
						params: params
					};
				}
			},
			{
				headerName: 'incorrect_events.customer_name', field: 'customer_name',
				cellRendererSelector: function (params: any) {
					params['label'] = params.data.customer_name;
					params['link'] = ['/#', params.data.customer_id].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				comparator: this.sortString,
			},
			{
				headerName: 'incorrect_events.site_name', field: 'site_name',
				cellRendererSelector: function (params: any) {
					params['label'] = params.data.site_name	;
					params['link'] = ['/#', params.data.customer_id, params.data.site_id].join('/');
					return {
						component: 'linkCellRenderer',
						params: params
					};
				},
				comparator: this.sortString,
			},
			{ headerName: eventsCountHeaderName, field: 'events_count' },
		];

		this.columnDefs = columnDefs;
		this.fileName = `${this.translate.instant('incorrect_events.incorrect_events')}-${this.selectedYear}.csv`;

		if (+this.selectedQuarter)
			this.fileName = `${this.translate.instant('incorrect_events.incorrect_events')}-${this.selectedYear}-Q${this.selectedQuarter}.csv`;
	}

	sortString(valueA: string, valueB: string) {
		return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
	}

	getAllYearsFromDate(startDate) {
		const currentDate = new Date();
		const startYear = startDate.getFullYear();
		const currentYear = currentDate.getFullYear();
		const years = [];

		for (let year = startYear; year <= currentYear; year++)
			years.push(year);

		this.selectedYear = years[years.length - 1];
		this.years = years.reverse();
	}
}
