<div class="row p-1" *ngIf="title; else noTitle">
	<div class="col-sm-6 col-md-4">
		<h4>
			{{title}}
			<app-help-tooltip *ngIf="tooltipKey" [field]="tooltipKey"></app-help-tooltip>
		</h4>
	</div>
	<div class="col-sm-1 ml-auto d-inline-flex">
		<button class="btn btn-sm margin-bottom btn-primary" (click)="showActionModal('add')">
			{{'note.add_note' | translate}}
		</button>
	</div>
</div>
<ng-template #noTitle>
	<table class="table">
		<tr class="margin-bottom-sm">
			<td class="text-nowrap">
				<button class="btn btn-sm margin-bottom btn-primary" (click)="showActionModal('add')">
					{{'note.add_note' | translate}}
				</button>
			</td>
		</tr>
	</table>
</ng-template>
<div *ngIf="modelNotes.length; else noNotes">
	<div class="table-responsive">
		<table class="table">
			<tr *ngFor="let note of modelNotes" class="margin-bottom-sm">
				<td *ngIf="note.canDelete">
					<button type="button" class="btn btn-sm btn-danger mr-1" (click)="showActionModal('delete', note)">
						<fa-icon icon="trash"></fa-icon>
					</button>
					<button type="button" class="btn btn-sm light-grey-border mr-1" (click)="showActionModal('reminder', note)" data-toggle="tooltip" data-placement="top" title="{{'note.edit_self_reminder_frequency' | translate}}" *ngIf="note.canEditReminder" [ngClass]="{'mt-2': commonService.isMobileScreen}">
						<fa-icon icon="bell"></fa-icon>
					</button>
					<button type="button" class="btn btn-sm light-grey-border mr-1" [disabled]="!note.canUpdateShareType" (click)="showActionModal('share', note)" data-toggle="tooltip" data-placement="top" title="{{'note.edit_sharing_type' | translate}}" [ngClass]="{'mt-2': commonService.isMobileScreen}">
						<i [ngClass]="getShareTypeIcon(note.shareType)" class="fa-fw" data-toggle="tooltip" data-placement="top" title="{{'note.share_type_'+note.shareType | translate}}">
						</i>
					</button>
				</td>
				<td *ngIf="!note.canDelete">&nbsp;</td>
				<td>{{note.formattedDate}}</td>
				<td class="w-50" *ngIf="note.type == 'user_note'">
					{{note.fname ? (note.fname + ' ' + note.lname) : 'DELETED USER ('+note.noteOwnerId+')'}}: <span [innerHTML]="note.note | safe: 'html'"></span>
				</td>
				<td class="w-50" *ngIf="note.type != 'user_note'">
					{{'system_notes.'+note.note.action | translate}} <span *ngIf="note.noteOwnerId">
						{{'g.by_user' | translate}} {{note.fname ? (note.fname + ' ' + note.lname) : 'DELETED USER ('+note.noteOwnerId+')'}}
					</span><br>
					<span *ngFor="let key of note.note.info | keys">
						{{key}}: {{note.note.info[key]}}<br>
					</span>
				</td>
				<td>
					<p *ngFor="let notifiedUser of note.notifiedList">{{notifiedUser.fname}} {{notifiedUser.lname}}</p>
				</td>
			</tr>
			<ng-container *ngIf="showMore">
				<tr class="margin-bottom-sm" *ngFor="let note of modelNotesExpand">
					<td *ngIf="note.canDelete">
						<button type="button" class="btn btn-sm btn-danger mr-1" (click)="showActionModal('delete', note)">
							<fa-icon icon="trash"></fa-icon>
						</button>
						<button type="button" class="btn btn-sm light-grey-border mr-1" (click)="showActionModal('reminder', note)" data-toggle="tooltip" data-placement="top" title="{{'note.edit_self_reminder_frequency' | translate}}" *ngIf="note.canEditReminder">
							<fa-icon icon="bell"></fa-icon>
						</button>
						<button type="button" class="btn btn-sm light-grey-border mr-1" [disabled]="!note.canUpdateShareType" (click)="showActionModal('share', note)" data-toggle="tooltip" data-placement="top" title="{{'note.edit_sharing_type' | translate}}">
							<i [ngClass]="getShareTypeIcon(note.shareType)" class="fa-fw" data-toggle="tooltip" data-placement="top" title="{{'note.share_type_'+note.shareType | translate}}">
							</i>
						</button>
					</td>
					<td>{{note.formattedDate}}</td>
					<td class="w-50" *ngIf="note.type == 'user_note'">{{note.fname}} {{note.lname}}: <span [innerHTML]="note.note | safe: 'html'"></span></td>
					<td class="w-50" *ngIf="note.type != 'user_note'">
						{{'system_notes.'+note.note.action | translate}}<br>
						<span *ngFor="let key of note.note.info | keys">
							{{key}}: {{note.note.info[key]}}<br>
						</span>
					</td>
					<td>
						<p *ngFor="let notifiedUser of note.notifiedList">{{notifiedUser.fname}} {{notifiedUser.lname}}</p>
					</td>
				</tr>
			</ng-container>
		</table>
	</div>
	<div class="text-center" *ngIf="modelNotesExpand.length && !showMore">
		<button type="button" class="btn btn-xs margin-bottom" (click)="showMore = true">
			<fa-icon icon="caret-square-down"></fa-icon>
		</button>
	</div>
	<div class="text-center" *ngIf="modelNotesExpand.length && showMore">
		<button type="button" class="btn btn-xs margin-bottom" (click)="showMore = false">
			<fa-icon icon="caret-square-up"></fa-icon>
		</button>
	</div>
</div>
<ng-template #noNotes>
	<div class="text-center margin-bottom-lg text-muted">
		<h5>{{'note.no_notes' | translate}}</h5>
	</div>
</ng-template>

<app-modal #addNoteModal title="{{'g.notes' | translate}}" confirmBtnText="{{'g.save' | translate}}" [size]="'lg'">
	<form #modelNote="ngForm" class="container" validate-form="modelNote" *ngIf="thisNote">
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'note.add_new_note' | translate}}
			</label>
			<div class="col-md-6">
				<input name="note_text" [(ngModel)]="thisNote.newSiteNote" placeholder="{{'note.note' | translate}}" class="form-control" required>
			</div>
		</div>
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'note.share_with' | translate}}
			</label>
			<div class="col-md-6">
				<select class="form-control" [(ngModel)]="thisNote.shareType" name="shareType" (change)="onChangeShareType($event.target.value)">
					<option *ngFor="let option of shareTypeList" [value]="option.id">{{option.label}}</option>
				</select>
			</div>
		</div>
		<div class="row mb-2" *ngIf="thisNote.shareType != noteService.shareOptions.private">
			<label class="col-md-4 col-form-label">
				{{'note.notification_users' | translate}}
			</label>
			<div class="col-md-6">
				<ng-select
					[items]="notificationUsers"
					bindLabel="fullNname"
					placeholder="{{'note.select_users' | translate}}"
					name="notification_user"
					[(ngModel)]="thisNote.notificationUsers"
					multiple="true"
				></ng-select>
			</div>
		</div>
		<div class="row">
			<label class="col-md-4 col-form-label">{{'note.self_reminder_frequency' | translate}}</label>
			<div class="col-md-6">
				<ng-select [items]="reminderFreqList" bindLabel="label" bindValue="id" placeholder="{{'note.select_reminder_frequency' | translate}}..." name="reminder" [(ngModel)]="thisNote.reminderFreq" [multiple]="false" [clearable]="false">
				</ng-select>
			</div>
		</div>
	</form>
</app-modal>
<app-modal #deleteNoteModal title="{{'note.delete_note' | translate}}" confirmBtnText="{{'g.delete' | translate}}" [size]="'md'" [confirmBtnClass]="'btn-danger'">
	<div class="container" *ngIf="thisNote">
		<div class="row mb-4">
			<label class="col-md-4 col-form-label">
				{{'note.note' | translate}}
			</label>
			<div class="col-md-6">
				<input type="text" name="new-site-note" [value]="thisNote.originalNote" class="form-control" disabled>
			</div>
		</div>
		<div class="my-2 text-center">
			<strong>{{'note.delete_note_msg' | translate}}</strong>
		</div>
	</div>
</app-modal>
<app-modal #editReminderFreqModal title="{{'note.edit_self_reminder_frequency' | translate}}" confirmBtnText="{{'g.save' | translate}}" [size]="'lg'">
	<form class="container" *ngIf="thisNote">
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'note.note' | translate}}
			</label>
			<div class="col-md-6">
				<input type="text" name="new-site-note" [value]="thisNote.originalNote" class="form-control" disabled>
			</div>
		</div>
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'note.self_reminder_frequency' | translate}}
			</label>
			<div class="col-md-6">
				<ng-select [items]="reminderFreqList" bindLabel="label" bindValue="id" placeholder="{{'note.select_reminder_frequency' | translate}}" name="reminder" [(ngModel)]="thisNote.reminder_freq" [multiple]="false" [clearable]="false">
				</ng-select>
			</div>
		</div>
	</form>
</app-modal>
<app-modal #editShareTypeModal title="{{'note.edit_sharing_type' | translate}}" confirmBtnText="{{'g.save' | translate}}" [size]="'lg'">
	<form class="container" *ngIf="thisNote">
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'note.note' | translate}}
			</label>
			<div class="col-md-6">
				<input type="text" name="new-site-note" [value]="thisNote.originalNote" class="form-control" disabled>
			</div>
		</div>
		<div class="row mb-2">
			<label class="col-md-4 col-form-label">
				{{'note.share_with' | translate}}
			</label>
			<div class="col-md-6">
				<select class="form-control" [(ngModel)]="thisNote.shareType" name="shareType">
					<option *ngFor="let option of shareTypeList" [value]="option.id">{{option.label}}</option>
				</select>
			</div>
		</div>
	</form>
</app-modal>
