<div class="white-box mt-4 p-4 border-radius">
	<div class="d-flex justify-content-between align-items-center mb-3">
		<h1 class="h2 mb-4">{{'rt_history.rt_requests_history' | translate}}</h1>
	</div>
	<sct-custom-table
		*ngIf="tableData.length"
		[columnsConfig]="columnConfig"
		[data]="tableData"
		[config]="tableConfig"
	>
	</sct-custom-table>
	<ng-template #noData>
		<div *ngIf="tableData.length == 0">{{'g.no_data_available' | translate}}</div>
	</ng-template>
</div>

<app-modal #infoModal size="lg" title="{{'g.info' | translate}}" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div>
		<div class="row mt-2 align-items-center">
			<div class="col-md-12" *ngIf="rowType == rtRequestHistoryService.sources.timeRequest">
				<h5>{{'rt_history.time_request_result_hdr' | translate}}</h5>
				<table class="table table-bordered" *ngIf="hasTimeRequest else noDataPeriods">
					<thead>
						<tr>
							<th>{{ 'g.key' | translate }}</th>
							<th>{{ 'g.search_id' | translate }}</th>
							<th>{{ 'rt_history.search_result' | translate }}</th>
							<th>{{ 'rt_history.popup_request_status' | translate }}</th>
							<th>{{ 'rt_history.status_result' | translate }}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let time of ['start', 'end']">
							<td>{{ time }}</td>
							<td>{{ timeRequest[time].id }}</td>
							<td>{{ 'rt_history.'+rtRequestHistoryService.searchStatuses[timeRequest[time].search_result] | translate }}</td>
							<td>{{ 'rt_history.'+rtRequestHistoryService.statuses[timeRequest[time].status] | translate }}</td>
							<td>{{ 'rt_history.'+rtRequestHistoryService.searchStatusStatuses[timeRequest[time].status_result] | translate }}</td>
						</tr>
						<tr *ngIf="reason">
							<td>{{ 'rt_history.failure_reason' | translate }}</td>
							<td colspan="4">{{ 'rt_history.'+reason | translate }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<div class="col-md-12">
				<h5>{{'rt_history.periods' | translate}}</h5>
				<table class="table table-bordered" *ngIf="periods.length > 0 else noDataPeriods">
					<thead>
						<tr>
							<th>{{ 'g.start_id' | translate }}</th>
							<th>{{ 'g.end_id' | translate }}</th>
							<th>{{ 'g.last_id' | translate }}</th>
							<th>{{ 'g.percentage' | translate }}</th>
						</tr>
					</thead>
					<tbody>
						<tr *ngFor="let period of periods">
							<td>{{ period.start }}</td>
							<td>{{ period.end }}</td>
							<td>{{ period.lastId }}</td>
							<td>{{ period.percentage }} %</td>
						</tr>
					</tbody>
				</table>
			</div>
			<ng-template #noDataPeriods>
				<div class="col-md-12" *ngIf="periods.length == 0 || !hasTimeRequest">{{'g.no_data_available' | translate}}</div>
			</ng-template>
		</div>
	</div>
</app-modal>

<app-modal #confirmCancelRequest [title]="'g.confirm_action' | translate">
	<p>{{ 'rt_history.confirm_cancel' | translate }}</p>
</app-modal>
