<div>
	<h2>{{'nav.disable_xtra_file' | translate}}</h2>
	<div class="d-flex justify-content-end my-3" *ngIf="usersService.hasAccessPermission(null, 'noc', 'write')">
		<button class="btn btn-primary btn-sm mx-2" [disabled]="!selectedDevices.length"
			(click)="restartDeviceConfirm.show()">
			{{'devices.enable_xtra_file' | translate}}
		</button>
	</div>
	<div class="d-flex justify-content-end" *ngIf="devices.length > 0">
		<fa-icon class="setting_icon" icon="upload" size="lg" (click)="generateTableCsvFile()"></fa-icon>
	</div>
	<table
		class="table table-striped"
		*ngIf="devices.length > 0; else noData"
		matSort (matSortChange)="filterAndSortData($event)"
		matSortActive="serial_number, customer_name, site_name, user_name, disable_since"
		matSortDirection="asc"
		matSortDisableClear
	>
		<thead>
			<tr>
				<th></th>
				<th mat-sort-header="serial_number">{{'g.serial_number' | translate}}</th>
				<th mat-sort-header="customer_name">{{'g.customer_name' | translate}}</th>
				<th mat-sort-header="site_name">{{'g.site_name' | translate}}</th>
				<th mat-sort-header="user_name">{{'sim.disabled_source' | translate}}</th>
				<th mat-sort-header="disable_since">{{'g.disabled_since' | translate}} ({{showUserTimeZoneReference()}})</th>
			</tr>
			<tr>
				<th></th>
				<th>
					<input class="form-control ng-tns-c18-2 ng-pristine ng-valid ng-touched"
						type="search"
						name="serial_number_filter"
						id="serial_number_filter"
						placeholder="{{'g.filter_by' | translate}} {{'g.serial_number' | translate}}"
						[(ngModel)]="filter.serial_number"
						(input)="filterAndSortData()"
					>
				</th>
				<th>
					<input class="form-control ng-tns-c18-2 ng-pristine ng-valid ng-touched"
						type="search"
						name="customer_name_filter"
						id="customer_name_filter"
						placeholder="{{'g.filter_by' | translate}} {{'g.customer_name' | translate}}"
						[(ngModel)]="filter.customer_name"
						(input)="filterAndSortData()"
					>
				</th>
				<th>
					<input class="form-control ng-tns-c18-2 ng-pristine ng-valid ng-touched"
						type="search"
						name="site_name_filter"
						id="site_name_filter"
						placeholder="{{'g.filter_by' | translate}} {{'g.site_name' | translate}}"
						[(ngModel)]="filter.site_name"
						(input)="filterAndSortData()"
					>
				</th>
				<th>
					<input class="form-control ng-tns-c18-2 ng-pristine ng-valid ng-touched"
						type="search"
						name="user_name_filter"
						id="user_name_filter"
						placeholder="{{'g.filter_by' | translate}} {{'sim.disabled_source' | translate}}"
						[(ngModel)]="filter.user_name"
						(input)="filterAndSortData()"
					>
				</th>
				<th></th>
			</tr>
		</thead>
		<tbody>
			<tr *ngFor="let device of toShowDevices; index as index">
				<td>
					<div class="form-check">
						<input class="form-check-input" type="checkbox" [value]="selectedDevices.includes(index)" (click)="selectDevice(device.mac_address)">
					</div>
				</td>
				<td>
					<a [routerLink]="['/', device.customer_id, device.site_id, device.mac_address, 'performance']">
						{{device.serial_number}}
					</a>
				</td>
				<td>
					<a [routerLink]="device.customer_name == 'customer_deleted'? null : ['/', device.customer_id]">
						{{(device.customer_name == 'customer_deleted'? "connectivity_verification." + device.customer_name  : device.customer_name) | translate}}
					</a>
				</td>
				<td>
					<a [routerLink]="device.site_name == 'site_deleted'? null : ['/', device.customer_id, device.site_id]">
						{{(device.site_name == 'site_deleted'? "connectivity_verification." + device.site_name : device.site_name) | translate}}
					</a>
				</td>
				<td>
					{{( device.user_id ? (device.user_name || '-') : 'g.system') |translate}}
				</td>
				<td>
					<div *ngIf="device.queued_since && !device.disable_since">{{'g.queued_since' | translate}} {{device.queued_since | date:'MM/dd/yyyy hh:mm:ss'}}</div>
					<div *ngIf="device.disable_since && !device.queued_since">{{device.disable_since | date:'MM/dd/yyyy hh:mm:ss'}}</div>
				</td>
			</tr>
		</tbody>
	</table>
</div>
<mat-paginator
	*ngIf="devices.length"
	[length]="devices.length"
	[pageSize]="pageSize"
	[pageSizeOptions]="pageSizeOptions"
	[showFirstLastButtons]="true"
	(page)="onPageChange($event)">
</mat-paginator>

<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>

<app-modal #restartDeviceConfirm [title]="'devices.restart_required' | translate">
	<p>
		{{'devices.restart_device_confirm_message'| translate }}
	</p>
	<ul>
		<li>{{'devices.disable_xtra' | translate}}</li>
	</ul>
</app-modal>
