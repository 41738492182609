import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgModel } from '@angular/forms';
import { AdminService } from '../../admin.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-rejected-device-connection',
	templateUrl: './rejected-device-connection.component.html',
	styleUrls: ['./rejected-device-connection.component.css']
})
export class RejectedDeviceConnectionComponent implements OnInit {

	logOptions: {
		getBy: string,
		value?: string,
		reason?: string,
		execlude?: boolean,
		ipAddress?: string,
	};
	filterOptions: Object[];
	rejectionReasons: any = {
		'deviceNotFound': 'rejection_log.device_not_found',
		'serialNumbersMissMatch': 'rejection_log.serial_number_mismatch',
		'hugeQueueSize': 'rejection_log.huge_queue_size',
		'encCodeMissMatch': 'rejection_log.enc_code_miss_match'
	};
	fromDateFmt: Date;
	toDateFmt: Date;
	logInfo: any[] = [];
	columnNames: any[];
	
	@ViewChild('ipAddress') ipAddress:NgModel;

	constructor(
		private adminService: AdminService,
		private translateService: TranslateService,
	) {
	}

	ngOnInit() {
		this.columnNames = [
			{name: this.translateService.instant('g.time'), key: 'time', type: 'dateTime'},
			{name: this.translateService.instant('g.mac_address'), key: 'mac_address', isId: true},
			{name: this.translateService.instant('g.serial_number'), key: 'serial_number'},
			{name: this.translateService.instant('g.reason'), key: 'reason'},
			{name: this.translateService.instant('g.ip_address'), key: 'ip_address'},
			{name: this.translateService.instant('error_log.extra_info'), key: 'extra_info', type: 'json'},
			{name: this.translateService.instant('g.options'), key: 'options', type: 'options', optionsList: [{'action': 'delete', 'text':'Delete Log'}]},
		];
		this.filterOptions	= [
			{label: 'g.date', value:'date'}, 
			{label: 'g.mac_address', value: 'mac_address'},
			{label: 'g.serial_number', value: 'serial_number'},
			{label: 'g.ip_address', value: 'ip_address'},
			{label: 'rejection_log.rejection_reason', value: 'rejection_reason'}
		];
		this.logOptions = {
			getBy: 'date',
			execlude: false
		};
		this.getRejectedDeviceConnections();
	}

	resetSelects(){
		this.logOptions.value = '';
		this.logOptions.reason = '';
		this.logOptions.execlude = false;
		this.logOptions.ipAddress ='';
	}

	getRejectedDeviceConnections(options?){
		options = options || this.logOptions;

		if(options.getBy == 'date') {
			let fromDate, toDate;

			if(!this.fromDateFmt || !this.toDateFmt) {
				this.fromDateFmt = new Date(new Date(new Date().setDate(new Date().getDate() - 7)).setHours(0, 0, 0, 0));
				fromDate = Math.floor(new Date(this.fromDateFmt).getTime() / 1000);
				
				this.toDateFmt = new Date(new Date().setHours(23,59,59,999));
				toDate = Math.floor(new Date(new Date(this.toDateFmt).setHours(23,59,59,999)).getTime() / 1000);
			} else {
				
				var fromDateFmt	= this.fromDateFmt;
				var toDateFmt	= this.toDateFmt;
				if(options.passedDates) {
					fromDateFmt	= options.fromDate;
					toDateFmt	= options.toDate;
				}

				fromDate	= Math.floor(new Date(fromDateFmt).getTime() / 1000);
				toDate 		= Math.floor(new Date(new Date(toDateFmt).setHours(23,59,59,999)).getTime() / 1000);
			}

			options.fromDate	= fromDate;
			options.toDate		= toDate;
		}

		this.adminService.getRejectedDeviceConnections(options).subscribe(
			(response: any) => {
				response.forEach((item)=> {
					if(this.rejectionReasons[item.reason])
						item.reason = this.translateService.instant(this.rejectionReasons[item.reason]);
				});
				this.logInfo = response;
			}
		);

	}

	getKeys(variable) {
		return Object.keys(variable);
	}

	doAction(event) {
		if(event.action == 'delete') {
			this.adminService.deleteRejectionLog(event.element.id).subscribe(
				response => {
					let logInfo = this.logInfo.slice();

					for(var i in logInfo) {
						if (logInfo[i].id == event.element.id) {
							logInfo.splice(+i, 1);
							break;
						}
					}

					this.logInfo = logInfo;
				}
			);
		}
	}

	checkDisableSubmitButton() {
		return (this.logOptions.getBy != 'date' &&
				!this.logOptions.value &&
				!this.logOptions.reason &&
				!this.logOptions.ipAddress)
			|| (this.logOptions.getBy == 'ip_address' && this.ipAddress.invalid);
	}
}
