import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { CommonService } from 'src/app/shared/services/common.service';
import { DeviceService } from '../../device.service';
import { ModalComponent } from '../../../../../shared/modal/modal.component';

@Component({
	selector: 'app-connectivity-frequency',
	templateUrl: './connectivity-frequency.component.html',
	styleUrls: ['./connectivity-frequency.component.css']
})
export class ConnectivityFrequencyComponent implements OnInit, AfterViewInit {
	
	@Input() macAddress: string = "";

	showExtend: boolean = false;
	showCancel: boolean = false;
	showRenew: boolean = false;
	revertTime: Date | string = '--/--/-- --:--:--';
	action: "extend" | "cancel" | "renew" = "extend";

	hasAccess = this.userService.hasAccessPermission(null, 'noc', 'write');

	@ViewChild("popupModal") popupModal: ModalComponent;
	popupData = "";;

	constructor(
		public deviceService: DeviceService,
		public userService: UsersService,
		public commonService: CommonService,
		private translateService: TranslateService
	) {}

	ngAfterViewInit(): void {
		this.popupModal.onClose.subscribe((result: any) => {
			if(result)
				this.updateActiveTime(this.action);
		});
	}

	ngOnInit(): void {
		if (!this.macAddress)
			return;
		this.deviceService.getShippingConnectivityData(this.macAddress).subscribe((data: any) => {
			if(!data[0])
				return;
			this.revertTime = this.formatDate(data[0].action_time);
			this.checkButtons(this.revertTime);
		});
	}

	showPopup(action: "extend" | "cancel" | "renew") {
		this.action = action;
		switch (action) {
			case "extend":
				this.popupModal.title = this.translateService.instant("connectivity_frequency.extend_popup_header");
				this.popupData = "connectivity_frequency.extend_popup_data";
				break;
			case "cancel":
				this.popupModal.title = this.translateService.instant("connectivity_frequency.cancel_popup_header");
				this.popupData = "connectivity_frequency.cancel_popup_data";
				break;
			case "renew":
				const stringTime = new Date (this.revertTime);
				const nextTime = new Date(stringTime.setMonth(stringTime.getMonth() + 1));
				this.popupModal.title = this.translateService.instant("connectivity_frequency.renew_popup_header");
				this.popupData = this.translateService.instant("connectivity_frequency.renew_popup_data");
				break;
			default:
				break;
		}
		this.popupModal.show();
	}

	updateActiveTime(action: string) {
		this.deviceService.updateShippingConnectivityData(this.macAddress, this.action).subscribe((data: any) => {
			this.revertTime = this.formatDate(data);
			this.checkButtons(this.revertTime);
		})
	}

	checkButtons(date: Date | string) {
		const revertTimeDate = moment(date).utc().toDate();
		const currentTime = new Date();
		if (revertTimeDate > currentTime) {
			this.showExtend = true && this.hasAccess;
			this.showCancel = true && this.hasAccess;
			this.showRenew = false && this.hasAccess;
		} else {
			this.showRenew = true && this.hasAccess;
			this.showExtend = false && this.hasAccess;
			this.showCancel = false && this.hasAccess;
		}
	}

	formatDate(date : string) {
		return date ? moment(date).format('MM/DD/yy hh:mm:ss A Z') : '--/--/-- --:--:--';
	}
}
