<div class="main-black border-radius-bottom white-box p-4">
	<app-grid
		[data]="warrantyData"
		[columns]="columnNames"
		[settings]="{
			hasExport: false,
			hasReset: false,
			showSummary: false,
			height: 'auto',
			enableFloatingFilter: false,
			pagination: false,
			domLayout: 'autoHeight'
		}"
		[sizeColumnsToFit]="true"
		id="site-warranty-{{siteId}}"
	></app-grid>
</div>