<div class="main-black border-radius-bottom white-box" *ngIf="siteId">
	<div class="m-0 p-4">
		<div class="row p-1">
			<div class="col-sm-6 col-md-2">
				<h4>
					{{'tags.site_tags' | translate}}
					<app-help-tooltip *ngIf="tooltip" [field]="tooltip"></app-help-tooltip>
				</h4>
			</div>
		</div>
		<div class="row mb-5">
			<div class="col-md-4" *ngFor="let type of availableShareOptions">
				<div class="card">
					<div class="card-header"><strong>{{'tags.'+type | translate}}</strong></div>
					<div class="panel-body p-3">
						<div *ngFor="let tag of siteTags[type]" class="float-left">
							<div class="btn btn-md btn-outline-secondary pointer p-1 m-1 word-break-btn btn-group">
								<span>{{tag.tag_name}}</span>
								<span *ngIf="currentUser.isSCT || tag.owner_id == currentUser.id" appDropdown>
									<img class="size-15x15 pointer dropdown opacity-50 ml-1" src="/sctportal/images/settings.svg">
									<ul class="dropdown-menu gear-dropdown-menu" role="menu">
										<li><a class="pointer dropdown-item" *ngIf="type != tagsService.shareOptions.public" (click)="editTagSharingOption(tag, tagsService.shareOptions.public)">{{'tags.change_perm_public' | translate}}</a></li>
										<li><a class="pointer dropdown-item" *ngIf="type != tagsService.shareOptions.private && tag.owner_id == currentUser.id" (click)="editTagSharingOption(tag, tagsService.shareOptions.private)">{{'tags.change_perm_private' | translate}}</a></li>
										<li><a class="pointer dropdown-item" *ngIf="type != tagsService.shareOptions.group && currentUser.isSCT" (click)="editTagSharingOption(tag, tagsService.shareOptions.group)">{{'tags.change_perm_group' | translate}}</a></li>
										<li *ngIf="currentUser.id == tag.owner_id || currentUser.isSCT">
											<a class="pointer dropdown-item" (click)="tagRemoved(tag)">{{'tags.delete_tag' | translate}}</a>
										</li>
									</ul>
								</span>
							</div>
						</div>
						<div *ngIf="siteTags[type].length === 0" class="alert alert-info m-0">
							<span>{{'tags.no_tag_msg' | translate}}</span>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="card">
			<div class="card-header"><strong>{{'tags.add_new_tags' | translate}}</strong></div>
			<div class="panel-body p-3">
				<div *ngIf="newTagName && (newTagName.length > tagsService.constants.maxTermLength || newTagName.length < tagsService.constants.minTermLength)" class="d-flex flex-row pb-1">
					<span class="text-danger">{{'form.min_max_limit' | translate:{"max": tagsService.constants.maxTermLength, "min": tagsService.constants.minTermLength} }}</span>
				</div>
				<div class="d-flex flex-row">
					<input type="text" class="form-control" name="newTagName" [(ngModel)]="newTagName" placeholder="{{'tags.add_a_tag' | translate}}">
					<select [(ngModel)]="newTagShareOption" class="form-control inline-form-control mx-3" name="newTagShareOption">
						<option *ngFor="let option of availableShareOptions" [value]="option">{{'tags.'+option | translate}}</option>
					</select>
					<button
						class="btn btn-primary"
						[disabled]="!newTagName || !newTagShareOption || newTagName.length > tagsService.constants.maxTermLength || newTagName.trim().length < tagsService.constants.minTermLength"
						(click)="siteTagAdded()"
					>{{'g.add' | translate}}</button>
				</div>
			</div>
		</div>
	</div>
</div>

<app-modal #removeTagModal title="{{'tags.delete_tags' | translate}}" [closeBtnText]="'g.cancel' | translate">
	{{'tags.remove_global_tags_msg' | translate}}
</app-modal>
