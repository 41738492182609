import { Injectable } from '@angular/core';

@Injectable()
export class HeaderService {
	visible: boolean;
	SearchBoxVisible: boolean;

	constructor() {
		this.visible = true;
		this.SearchBoxVisible = true;
	}

	hide() { this.visible = false; }

	show() { this.visible = true; }

	toggle() { this.visible = !this.visible; }

	toggleSearchBox(visible: boolean) { this.SearchBoxVisible = visible; }
}