import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { UsersService } from '../users.service';

@Component({
  selector: 'app-unblock-login',
  templateUrl: './unblock-login.component.html',
})
export class UnblockLoginComponent implements OnInit {

  constructor(
    private usersService: UsersService,
    private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private translateService: TranslateService,
		private router: Router,
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      if('link_expired' in params) {
        this.router.navigate(['/']);
        this.notificationMessage.setMessage(this.translateService.instant('g.link_expired'));
        return;
      }
  
      if(params.enc)
        return this.usersService.unblockLogin(params.enc).subscribe((res: any) => {
          switch(res.api_status) {
            case 2:
              this.notificationMessage.setMessage(this.translateService.instant('g.link_expired'));
              break;
            default:
              return this.router.navigate(['/']);
          }
        });
        
      return this.notificationMessage.setMessage('globalErrMsg');
    })
  }
}
