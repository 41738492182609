import { Directive, Input, HostListener } from '@angular/core';
import { GACommands, GoogleAnalytics } from '../services/google-analytics.service';


@Directive({
	selector: '[ga-tracker]'
})
export class GaTrackerDirective {
	@Input('ga-params') params: any = {};
	@Input('ga-command') command: GACommands = 'event';
	@Input('ga-category') category: string;

	constructor(
		private googleAnalytics: GoogleAnalytics,
	){}

	@HostListener('click')
	sendEvent() {				
		this.googleAnalytics.sendEvent(this.command, this.category, this.params);
	}
}