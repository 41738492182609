import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from './../admin.service';
import { EnterpriseService } from '../../enterprise/enterprise.service';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { PermissionsService } from 'src/app/permissions/permissions.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-validate-registration-requests',
	templateUrl: './validate-registration-requests.component.html',
})
export class ValidateRegistrationRequestsComponent implements OnInit, AfterViewInit {

	id = 0;
	registrationRequest: any = {};
	enterpriseList: any = [];
	permissionList: any = [];
	modalInit: boolean = false;
	enterpriseAdded: boolean = false;
	enterpriseInfo: any;
	@ViewChild('rejectRequestModal') rejectRequestModal;
	@ViewChild("enterpriseModal") enterpriseModal;

	constructor(
		private router: Router,
		private adminService: AdminService,
		private enterpriseService: EnterpriseService,
		private route: ActivatedRoute,
		private notificationMessage: NotificationMessageService,
		private permissionsService: PermissionsService,
		public usersService: UsersService
	) {
		this.id = this.route.snapshot.params.id;
	}

	ngOnInit() {
		this.getEnterpriseList();
		this.adminService.getRegistrationRequests(this.id).subscribe((data: any) => {
			//Registration request not found (no response)
			if(data.api_status) {
				this.notificationMessage.setMessage('translate|login_register.invalid_registration_request');
				return this.goBack();
			}
			this.registrationRequest = data;
		});
		this.permissionsService.getPermissionsList('user').subscribe((data) => {
			this.permissionList = data;
		});
	}

	ngAfterViewInit() {
		this.rejectRequestModal.onClose.subscribe((ok) => {
			if(ok) {
				this.rejectRegistrationRequest();
			}
		});
	}

	getEnterpriseList(selectId?) {
		this.enterpriseService.getEnterprises({source: 'registration_requests'}).subscribe((data) => {
			this.enterpriseList = data;
			if(selectId)
				this.registrationRequest.enterprise_id = selectId;
		});
	}

	submitRegistrationRequest(){
		this.adminService.submitRegistrationRequest(this.registrationRequest).subscribe((data: any) => {
			switch (data) {
				case 1:
					this.notificationMessage.setMessage('globalSuccessMsg');
					this.goBack();
					break;
				case 2:
					this.notificationMessage.setMessage('translate|g.invalid_input', {clearOnXTimeNavigate: 1});
					break;
				case 3:
					this.notificationMessage.setMessage('translate|users.email_used', {clearOnXTimeNavigate: 1});
					break;
				case 4:
					this.notificationMessage.setMessage('translate|users.phone_used', {clearOnXTimeNavigate: 1});
					break;
			}
		});
	}

	rejectRegistrationRequest() {
		this.registrationRequest.isrejected = true;
		this.submitRegistrationRequest();
	}

	showEnterpriseModal() {
		this.enterpriseInfo = {
			id: 0,
			name: '',
			admins:[],
			group_type: null,
			default_permission_id: null,
			update_linked: false,
			site: {}
		};
		this.enterpriseModal.show();
	}

	onEnterpriseAdded(data) {
		this.enterpriseModal.hide();
		this.getEnterpriseList(data.enterpriseId);
		this.enterpriseAdded = true;
		this.notificationMessage.setMessage('globalSuccessMsg');
	}

	goBack() {
		this.router.navigate(['/admin-panel/validate-registration-request']);
	}
}