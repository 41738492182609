<div>
	<h1 class="h2 mb-4">{{'nav.admin.time_lost_report' | translate}}</h1>
	<table class="table table-striped" *ngIf="devices.length > 0; else noData">
		<thead>
			<tr>
				<th>{{'devices.unique_mac_address' | translate}}</th>
				<th>{{'g.site_name' | translate}}</th>
				<th>{{'g.customer_name' | translate}}</th>
				<th>{{'devices.rtc_lost' | translate}}</th>
				<th>{{'devices.last_cm_date_tested' | translate}} ({{'g.utc' | translate}})</th>
				<th>{{'devices.lost_rtc_time' | translate}} ({{'g.utc' | translate}})</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let device of devices">
			<td>
				<a [routerLink]="['/', customers[sites[device.site_id]?.customer_id]?.id, sites[device.site_id]?.id, device.mac_address, 'performance']">
					{{device.mac_address}}
				</a>
			</td>
			<td>
				<a [routerLink]="['/', customers[sites[device.site_id]?.customer_id]?.id, sites[device.site_id]?.id]">
					{{sites[device.site_id]?.name}}
				</a>
			</td>
			<td>
				<a [routerLink]="['/', customers[sites[device.site_id]?.customer_id]?.id]">
					{{customers[sites[device.site_id]?.customer_id]?.name}}
				</a>
			</td>
			<td>{{(device.lost_rtc == 1 ? 'g.yes' : 'g.no') | translate}}</td>
			<td>{{device.last_cm_date_tested | date:('MM/dd/yyyy hh:mm:ss a'):'UTC/GMT'}}</td>
			<td>{{device.lost_rtc_time | date:('MM/dd/yyyy hh:mm:ss a'):'UTC/GMT'}}</td>
		</tr>
		</tbody>
	</table>
</div>
<ng-template #noData>
	<div class="alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>