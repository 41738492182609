import { Component, OnInit, Input, ViewChild, OnChanges, Output, EventEmitter } from '@angular/core';
import { DeviceService } from '../../device.service';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';
@Component({
  selector: 'app-debug-records',
  templateUrl: './debug-records.component.html',
  styleUrls: ['./debug-records.component.css']
})
export class DebugRecordsComponent implements OnInit, OnChanges {
	recordsLimit = 1000;
	@Input() device: any = {};
	@Output() onDeviceDataChange = new EventEmitter<Object>();
	debugRecords: any = [];

	columnDefs = [
		{headerName: 'events.record_id', field: 'record_id', type: 'number', filterParams: {format: 'noFormat'}, width: 150},
		{headerName: 'g.info', field: 'info', width: 1100}
	];

	maxDebugHours: number = 48;
	force_read_debug_time: number = null;
	forceReadDebugUntil: string = '';

	@ViewChild("serverPagination") serverPagination;

	constructor(
		private deviceService: DeviceService,
		public usersService: UsersService,
		private router: Router,
		private translateService: TranslateService,
		private notificationMessage: NotificationMessageService,
		private deviceManagementService: DeviceManagementService,
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessPermission(null, 'read_debug_records'))
			return this.router.navigate(['/unauthorized']);

		if(this.device.config_info && this.device.config_info.force_read_debug_time)
			this.getDebugDate();
	}

	ngAfterViewInit() {
		this.getDebugRecords(false, true);
	}

	ngOnChanges(changes) {
		if (changes.device.previousValue && changes.device.previousValue.mac_address !=changes.device.currentValue.mac_address && this.serverPagination) {
			this.getDebugRecords(false, true);
		}
	}

	getDebugRecords(isBack=false, firstTime=false) {
		if(!this.device.mac_address)
			return;

		if(firstTime)
			this.serverPagination.init();

		const lastId = this.serverPagination.lastId;
		const firstId = this.serverPagination.firstId;
		const limit = this.recordsLimit;

		this.deviceService.getDebugRecords(this.device.mac_address, {lastId, firstId, isBack, limit}).subscribe((data: any) => {
			this.debugRecords = [...data];
			if (this.debugRecords.length >  this.recordsLimit)
				this.debugRecords.pop();

			this.serverPagination.updatePagination(data, isBack, firstTime);
		});
	}
	
	getDebugDate(force_read_debug_time?) {
		force_read_debug_time = force_read_debug_time || this.device.config_info.force_read_debug_time;
		if(force_read_debug_time && typeof(force_read_debug_time) == 'number')
			this.forceReadDebugUntil = moment.unix(force_read_debug_time || 0).utc().format('MM/DD/YYYY H:mm');
	}

	saveDebugDate() {
		if(this.force_read_debug_time > this.maxDebugHours || this.force_read_debug_time < 0)
			return this.notificationMessage.setMessage(this.translateService.instant('force_read_debug.select_time_more_max_time'));
		this.deviceManagementService.setForceReadDebugDate(this.device.mac_address, this.force_read_debug_time).subscribe((data: any) => {
			this.onDeviceDataChange.emit(data);
			this.getDebugDate(data.force_read_debug_time);
			this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		})
	}

	forceReadDebugNow() {
		this.deviceManagementService.setForceDebugRecordNow(this.device.mac_address).subscribe((data: any) => {
			this.onDeviceDataChange.emit(data);
			this.getDebugDate(data.debug_synch_time);
			this.notificationMessage.setMessage('globalSuccessMsg', {clearOnXTimeNavigate: 1});
		})
	}
}
