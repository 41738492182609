import { Component, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { UsersService } from 'src/app/users/users.service';
import { CommonService } from 'src/app/shared/services/common.service';
import * as moment from 'moment';
import { ModalComponent } from 'src/app/shared/modal/modal.component';
@Component({
	selector: 'app-missing-sims',
	templateUrl: './missing-sims.component.html',
	styleUrls: ['./missing-sims.component.css']
})
export class MissingSimsComponent {
	@ViewChild("actionsConfirmationPopup") actionsConfirmationPopup: ModalComponent;

	confirmationMassage = '';
	sims: any[] = [];
	toShowSims: any[] = [];
	sortResult: any[] = [];
	pageSizeOptions: number[] = [100];

	pageSize: number = this.pageSizeOptions[0];
	currentPage: number = 1;
	isUpdatingSims: boolean = false;
	lastUpdateSimsTime: number;

	constructor(
		private router: Router,
		private userService: UsersService,
		private deviceService: DeviceService,
		public commonService: CommonService,
		private notificationMessage: NotificationMessageService,
		public translate: TranslateService,
	) {}

	ngOnInit() {
		if (!this.userService.hasAccessPermission(null, 'noc', 'write'))
			this.router.navigate(['/unauthorized']);

		this.getMissingSims();
	}

	ngAfterViewInit() {
		this.actionsConfirmationPopup.onClose.subscribe((ok) => {
			if(!ok)
				return;

			this.refreshMissingSims();
		});
	}

	getMissingSims() {
		this.deviceService.getMissingSims().subscribe((response: any) => {
			this.sims = response.data;
			this.isUpdatingSims = response.isUpdatingSims;
			this.lastUpdateSimsTime = response.lastUpdateSimsTime;
			this.sortResult = this.sims;
			this.sortData({active: 'customer_name', direction: 'asc'});
		})
	}

	refreshMissingSims() {
		this.deviceService.refreshMissingSims().subscribe((response: any) => {
			this.lastUpdateSimsTime = response?.response?.lastUpdateSimsTime;

			switch (response.api_status) {
				case 2:
					this.notificationMessage.setMessage('translate|g.in_progress_action', {clearOnXTimeNavigate: 1, type: 'warning'});
					this.isUpdatingSims = true;
					break;
				case 3:
					this.notificationMessage.setMessage('translate|g.done_before_less_than_three_hours', {clearOnXTimeNavigate: 1, type: 'warning'});
					this.getMissingSims();
					break;
				default:
					this.notificationMessage.setMessage('translate|g.action_will_applied_later', {clearOnXTimeNavigate: 1, type: 'success'});
					this.isUpdatingSims = true;
					break;
			}
		})
	}

	sortData(sort: any) {
		const data = this.sims.slice();
		if (!sort.active || sort.direction === '') {
			this.sortResult = data;
			return this.updatePage();
		}

		this.sortResult = data.sort((a, b) => this.sortFunction(sort, a, b));
		this.updatePage()
	}

	onPageChange(event) {
		this.currentPage = event.pageIndex + 1;
		this.updatePage();
	}

	updatePage() {
		const startIndex = (this.currentPage - 1) * this.pageSize;
		const endIndex = startIndex + this.pageSize;
		this.toShowSims = (this.sortResult || []).slice(startIndex, endIndex);
	}

	sortFunction(sort: any, a:any, b:any) {
		const { compare } = this.commonService;
		const isAsc = sort.direction === 'asc';
		const field = sort.active;

		if (["customer_name", "site_name"].includes(field))
			return compare(a[field].toUpperCase(), b[field].toUpperCase(), isAsc);

		return compare(a[field], b[field], isAsc);
	}

	formateDate(date: moment.MomentInput) {
		return date && date != '---' ? moment(date).format('MM/DD/YYYY hh:mm:ss a') : '---';
	}

	showRefreshPopup() {
		if (this.isUpdatingSims)
			return this.refreshMissingSims();

		this.confirmationMassage = this.translate.instant('sim.update_sims_confirm');
		this.actionsConfirmationPopup.show();
	}
}
