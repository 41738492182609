import { Component, OnInit, Input } from '@angular/core';
import { NotificationMessageService } from '../../shared/notification-message/notification-message.service';
import { CommonService } from '../../shared/services/common.service';
import { EventEmitter } from 'events';

@Component({
	selector: 'app-captcha',
	templateUrl: './captcha.component.html',
})
export class CaptchaComponent implements OnInit {

	triesLimitReached: boolean = false;
	triesCount: number = 0;
	triesLimit: number = 3;

	captchaUrl: string = null;
	captchaKey: string = null;
	@Input() captchaText: string;
	@Input() textFieldCols: number = 4;

	constructor(
		private commonService: CommonService,
		private notificationMessage: NotificationMessageService
	){}

	ngOnInit() {
		this.getCaptcha(true);
	}

	getCaptcha(refresh=false) {

		if(!refresh)
			this.triesCount++;

		if(this.triesCount >= this.triesLimit) {
			this.triesLimitReached = true;
			this.notificationMessage.setMessage('translate|login_register.verification_is_expired', {clearOnXTimeNavigate: 1});
			return;
		}

		this.captchaText = null;
		let captcha = this.commonService.getCaptchaUrl();
		this.captchaUrl = captcha.url;
		this.captchaKey = captcha.key;
	}
}
