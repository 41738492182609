import { Component, Input, OnInit, ViewChild } from '@angular/core';
import * as _ from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { CommonService } from '../services/common.service';
import * as moment from 'moment';
import { cloneDeep } from 'lodash';
import { NotificationMessageService } from '../notification-message/notification-message.service';
import { CustomersService } from 'src/app/customers/customers.service';
import { DeviceManagementService } from 'src/app/device/device-management.service';

@Component({
	selector: 'app-warnings-history-table',
	templateUrl: './warnings-history-table.component.html',
	styleUrls: ['./warnings-history-table.component.css']
})
export class warningsHistoryTableComponent implements OnInit {
	@ViewChild("infoDialog") infoDialog: any;
	@Input() siteId?: number;
	@Input() customerId?: number;
	site_name = '';
	customer_name = '';

	// global warning filter
	doneFilter = false;
	warningsList = [];
	selectedWarnings = null;
	customerList = [];
	customerDevicesList = [];
	customersDevicesObject = {};
	selectedDevice: any = null;
	filter = {
		warnings: null,
		fromDate: null,
		toDate: null,
		status: 'current_warnings',
		customers: null,
		devices: []
	}

	infoHeader = "";
	filterOptions: {from: Date | null, to: Date | null} = {from: null, to: null};
	dataTable: any[] = [];
	columnDefs: any[] = [];
	allColumnsKeys: string[] = [];

	fromDate: Date;
	toDate: Date;
	showDateRange: string;
	invalidDateRange: boolean = false;;

	currentUser: any = {};
	enterprisePermissions: any = {};
	dateFields = ['timestamp'];
	dateTimeFields = ['trigger_time', 'clear_time'];
	clickableFields = ['triggered_qv_values', 'cleared_qv_values', 'acknowledge'];
	hiddenFields = ['id', 'site_id', 'customer_id', 'mac_address'];
	floutFields = ['voltage', 'charge_kwhr', 'inuse_kwhr', 'current', 'min_voltage', 'event_max_current'];
	booleanFields = ['low_range_is_inuse'];

	statusMapValue: any = {
		wifi_status: 'wifi_ip',
		eth_status: 'eth_ip'
	};

	triggeredQVValues: {
		customer_name: string,
		site_name: string,
		serial_number: string,
		warning_text: string
	} = {
		customer_name: '',
		site_name: '',
		serial_number: '',
		warning_text: ''
	};
	noQVData = false;

	qvFields: {
		[key: string]: string[];
	} = {
		main: ['hw_status', 'wifi_status', 'voltage', 'current', 'low_range_is_inuse'],
		live_event: ['sequence_id', 'timestamp', 'min_voltage', 'event_max_current', 'charge_kwhr', 'inuse_kwhr'],
		live_rt: ['sequence_id', 'voltage', 'current'],
	}

	warningStatus = {
		open: 1,
		close: 2,
		acknowledged: 3
	}

	allColumns: { key: string }[] = [
		{
			key: 'id',
		},
		{
			key: 'serial_number'
		},
		{
			key: 'customer_name'
		},
		{
			key: 'site_name'
		},
		{
			key: 'warning_text'
		},
		{
			key: 'trigger_time'
		},
		{
			key: 'clear_time'
		},
		{
			key: 'cleared_qv_values'
		},
		{
			key: 'acknowledge'
		},
	];

	tableFilter: 'current_warnings'  | 'period' = 'current_warnings';

	tableFilterList = [
		{id: 'current_warnings', name: this.translateService.instant('warnings.current_warnings')},
		{id: 'period', name: this.translateService.instant('warnings.period')}
	];

	excludePermissionWarnings = ['lost_rtc', 'password', 'disconnected_device', 'invalid_device_zone', 'no_setup'];

	constructor(
		private customersService: CustomersService,
		private deviceService: DeviceService,
		private deviceManagementService: DeviceManagementService,
		private translateService: TranslateService,
		private commonService: CommonService,
		private notificationMessage: NotificationMessageService,
	) {
		this.fromDate =  moment().subtract(6, 'day').toDate();
		this.toDate = new Date();
		this.enterprisePermissions = this.currentUser.permissions;
	}

	ngOnInit() {
		if (this.siteId) {
			this.fillWarningTable();
		} else {
			this.prepareFilters();
		}
	}

	fillWarningTable() {
		this.getWarnings();
		this.buildGrid();
	}

	prepareFilters() {
		this.warningsList = this.deviceService.getWarningsList();
		this.customersService.getCustomers({}).subscribe((data: any) => {
				const customerList = [];

				for(let customer of data) {
					customerList.push({key: customer.id , value: customer.customer_name});
				}
				this.customerList = customerList;
			}
		);
	}

	selectCustomer() {
		const existCustomers = Object.keys(this.customersDevicesObject).map(value => +value);

		if (existCustomers.length > this.filter.customers.length)
			return this.removeCustomer(existCustomers)

		// add new customer
		const newCustomer = this.filter.customers.filter(customer => !existCustomers.includes(customer));
		if (!newCustomer.length)
			return;

		this.customersService.getCustomer(newCustomer, {get_devices_counts: true, without_analytics: true, include_zombie_sites: true}).subscribe((data: any) => {
				let customerDevicesList = [];

				for (let device of data.devices) {
					customerDevicesList.push({key: device.mac_address, value: device.serial_number});
				}

				this.customerDevicesList = [...this.customerDevicesList, ...customerDevicesList];
				this.customersDevicesObject[newCustomer] = customerDevicesList;
			}
		);
	}

	removeCustomer(existCustomers: any[]) {
		const removeCustomer = existCustomers.filter(customer => !this.filter.customers.includes(customer));
		if (!removeCustomer.length)
			return;

		const removeCustomerDevice = this.customersDevicesObject[removeCustomer[0]].map(device => device.key);
		delete this.customersDevicesObject[removeCustomer[0]];
		this.customerDevicesList = this.customerDevicesList.filter(device => !removeCustomerDevice.includes(device.key));
		this.selectedDevice = this.selectedDevice?.length ? this.selectedDevice.filter((device: any) => !removeCustomerDevice.includes(device)) : null;

		if (existCustomers.length == 1)
			this.filter.customers = null;

		return this.filter.devices = this.customerDevicesList.filter(device => this.selectedDevice.includes(device.key));
	}

	addDevice() {
		if (this.filter.customers)
			this.filter.devices = this.customerDevicesList.filter(device => this.selectedDevice.includes(device.key));

		else {
			this.deviceManagementService.searchDevices(this.selectedDevice, 'sn', true, false).subscribe((data: any) => {
				if(data.devices.length == 1)
					return this.filter.devices = [...this.filter.devices, {key: data.devices[0].mac_address, value: data.devices[0].serial_number}];

				this.notificationMessage.setMessage(this.translateService.instant('devices.selected_devices_not_exist') ,{clearOnXTimeNavigate: 1});
			});
		}
	}

	getWarnings() {
		let filter: any = {};
		filter.siteId = this.siteId ? this.siteId : null;
		let from = this.fromDate;
		let to = this.toDate;
		let getCurrentWarning = this.tableFilter == 'current_warnings';

		if (!filter.siteId) {
			filter = {...this.filter};
			filter.devices = filter.devices.map(device => device.key);
			from = this.filter.fromDate;
			to = this.filter.toDate;
			getCurrentWarning = this.filter.status == 'current_warnings';
		}

		let zoneDiff = new Date().getTimezoneOffset() * -1;
		let fromDate: any = new Date(new Date(from).getTime() + (zoneDiff * 60 * 1000));
		let toDate: any = new Date(new Date(to).getTime() + (zoneDiff * 60 * 1000));
		filter.fromDate = moment(fromDate).utc().startOf('day').unix();
		filter.toDate = moment(toDate).utc().endOf('day').unix();
		filter.getCurrentWarning = getCurrentWarning;

		this.deviceService.getWarnings(filter).subscribe((res: any) => {
			const tableData: any[] = [];
			this.site_name = res.site_name;
			this.customer_name = res.customer_name;

			res.warnings.forEach((row: any) => {
				const rowObj = {
					id: row.id,
					site_id: row.site_id || this.siteId,
					customer_id: row.customer_id || this.customerId,
					mac_address: row.mac_address,
					serial_number: row.serial_number,
					customer_name: row.customer_name,
					site_name: row.site_name,
					warning_text: this.translateService.instant('warnings.' + row.warning),
					trigger_time: moment(+(new Date(+row.trigger_time * 1000))).utc().format('MM/DD/YYYY HH:mm:ss'),
					clear_time: row.clear_time ? moment(+(new Date(+row.clear_time * 1000))).utc().format('MM/DD/YYYY HH:mm:ss'): null ,
					cleared_qv_values: {
						onClick: () => this.prepareQVValues(row),
						type: 'icon',
						icon: 'info',
					},
					acknowledge: {
						onClick: () => this.acknowledgeAction(row),
						type: 'icon',
						icon: row.status == this.warningStatus.close ? 'eye-slash': null,
					},
				}

				tableData.push(rowObj);
			})

			this.doneFilter = true;
			this.dataTable = tableData;
		})
	}

	buildGrid() {
		const gridColumns = [];
		this.allColumnsKeys = this.allColumns.map(item => item.key);

		if (this.siteId) { // remove customer name and site name columns
			this.allColumnsKeys.splice(this.allColumnsKeys.indexOf('customer_name'), 1);
			this.allColumnsKeys.splice(this.allColumnsKeys.indexOf('site_name'), 1);
		}

		for (let field of this.allColumnsKeys) {
			let colDef: any = {};

			if (this.dateTimeFields.includes(field))
				colDef = { type: 'dateTime', floatingFilterComponentParams: { suppressFilterButton: true } };

			if (this.clickableFields.includes(field))
				colDef = {type: 'clickable'}

			let headerName = field;

			switch (field) {
				case 'serial_number':
					Object.assign(colDef, { headerName: this.translateService.instant('warnings.' + headerName), field: field, colId: field,
						cellRendererSelector:function (params: any) {
							params['label'] = params.data.serial_number;
							params['link'] = ['/#', params.data.customer_id, params.data.site_id, params.data.mac_address, 'performance'].join('/');
							return { component: 'linkCellRenderer', params: params };
						}
					});
					break;

				case 'customer_name':
					Object.assign(colDef, { headerName: this.translateService.instant('warnings.' + headerName), field: field, colId: field,
						cellRendererSelector: function (params: any) {
							params['label'] = params.data.customer_name	;
							params['link'] = ['/#',params.data.customer_id].join('/');
							return {
								component: 'linkCellRenderer',
								params: params
							};
						}
					});
					break;

				case 'site_name':
					Object.assign(colDef, { headerName: this.translateService.instant('warnings.' + headerName), field: field, colId: field,
						cellRendererSelector: function (params: any) {
							params['label'] = params.data.site_name	;
							params['link'] = ['/#', params.data.customer_id, params.data.site_id].join('/');
							return {
								component: 'linkCellRenderer',
								params: params
							};
						}
					});
					break;

				default:
					Object.assign(colDef, { headerName: this.translateService.instant('warnings.' + headerName), field: field, colId: field });
					break;
			}

			if (!this.hiddenFields.includes(field))
				gridColumns.push(colDef);
		}

		this.columnDefs = gridColumns;
	}

	prepareQVValues(row: any) {
		this.infoDialog.show();
		this.infoHeader = this.translateService.instant('warnings.cleared_qv_values');
		const data = cloneDeep(row);
		this.setDialogData(data);
	}

	setDialogData(data: any) {
		this.triggeredQVValues['serial_number'] = data.serial_number;
		this.triggeredQVValues['customer_name'] = data.customer_name || this.customer_name;
		this.triggeredQVValues['site_name'] = data.site_name || this.site_name;
		this.triggeredQVValues['warning_text'] = this.translateService.instant('warnings.' + data.warning);
		const tempQVFields = cloneDeep(this.qvFields);

		if ( !data.triggered_qv_values && !data.cleared_qv_values ) {
			this.noQVData = true;
			return
		}

		// using charge_kwhr for charger device and inuse_kwhr for iotah device
		if (data.is_charglink)
			tempQVFields['live_event'].splice(tempQVFields['live_event'].indexOf('inuse_kwhr'), 1);
		else
			tempQVFields['live_event'].splice(tempQVFields['live_event'].indexOf('charge_kwhr'), 1);

		const dataObjectArray = [
			{key: 'main', trigger_object: data.triggered_qv_values, clear_object: data.cleared_qv_values},
			{key: 'live_event', trigger_object: data.triggered_qv_values?.live_event, clear_object: data.cleared_qv_values?.live_event},
			{key: 'live_rt', trigger_object: data.triggered_qv_values.live_rt, clear_object: data.cleared_qv_values?.live_rt},
		];

		dataObjectArray.forEach(object => {
			this.triggeredQVValues[object.key] = [];
			tempQVFields[object.key].forEach(field => {
				const fieldData = {
					field_name: field,
					trigger_value: this.formatData(object.trigger_object, field),
					clear_value: this.formatData(object.clear_object, field)
				};
				this.triggeredQVValues[object.key].push(fieldData);
			})
		})
	}

	formatData(object, field) {
		let returnValue = null;
		if (!(object && field in object))
			return returnValue;

		returnValue = object[field];

		if (['wifi_status'].includes(field))
			returnValue = this.getStaStatus(object[field], object[this.statusMapValue[field]]);

		else if (['hw_status'].includes(field))
			returnValue = this.getHwStatus(object[field]);

		else if (this.floutFields.includes(field))
			returnValue = (object[field]).toFixed(2);

		else if (this.booleanFields.includes(field))
			returnValue = !!(object[field]);

		else if (this.dateFields.includes(field))
			returnValue = this.commonService.getDateFormattedFromUnixTimeStamp(object[field]);

		return returnValue;
	}

	acknowledgeAction(row: any) {
		this.deviceService.acknowledgeWarnings(row.warning, row.mac_address).subscribe((data) => {
			if (data) {
				if(this.tableFilter == 'current_warnings') {
					this.dataTable = this.dataTable.filter((data) => data.id !== row.id);
					this.buildGrid();
				} else {
					this.getWarnings();
				}
			}
		})
	}

	onFilterChange() {
		if(this.tableFilter == 'current_warnings')
			this.filterOptions = {from: null, to: null};

		this.getWarnings();
	}

	datesChanged() {
		this.invalidDateRange = false;
		if(moment(this.fromDate).startOf('day').unix() > moment(this.toDate).endOf('day').unix())
			this.invalidDateRange = true;

		if (!this.invalidDateRange && this.fromDate && this.toDate)
			this.getWarnings();
		else
			this.notificationMessage.setMessage(this.translateService.instant('g.start_date_before_end_date') ,{clearOnXTimeNavigate: 1});
	}

	changeFilterDate() {
		if (this.filter.fromDate && this.filter.toDate && moment(this.filter.fromDate).startOf('day').unix() > moment(this.filter.toDate).endOf('day').unix())
			this.notificationMessage.setMessage(this.translateService.instant('g.start_date_before_end_date') ,{clearOnXTimeNavigate: 1});
	}

	getHwStatus(value: number) {
		let statuses = {
			1: 'warnings.plc',
			2: 'warnings.rtc',
			4: 'warnings.adc',
			8: 'warnings.flash_size'
		};

		let status = [];

		for(let id in statuses) {
			if((value & +id) != 0) {
				status.push(this.translateService.instant(statuses[id]))
			}
		}

		return status.join(', ');
	}

	getStaStatus(status: number, ip: string) {

		let STA_STATUS_STARTED = 0,
		STA_STATUS_STOPPED = 1,
		STA_STATUS_CONNECTTING = 2,
		STA_STATUS_CONNECTED = 3,
		STA_STATUS_GOT_IP = 4,
		STA_STATUS_DISOCNNECTED = 5,
		STA_STATUS_AFTER_IP = 6,
		STA_STATUS_DISOCNNECTING = 7,
		STA_STATUS_DIS = 8,
		STA_STATUS_MAX = 9;

		if(status == STA_STATUS_AFTER_IP && ip != "0.0.0.0"){
			return this.translateService.instant('qv.sta_connected')+' '+ip;
		}

		return this.translateService.instant('qv.sta_on');
	}
}
