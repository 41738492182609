<div class="main-black border-radius-bottom white-box mt-1" *ngIf="site">
	<div class="card-body">
		<form (ngSubmit)="approveSite()" validate-form="editSite" [innerModelGroups]="['site']">
			<div class="col-sm-12 card-body" *ngIf="sitesList">
				<div class="row mt-2 align-items-center">
					<div class="col-md-3">
						<strong>{{'device_management.map_existing_site' | translate}}</strong>
					</div>
					<div class="col-md-9">
						<ng-select
							[items]="sitesList"
							bindLabel="name"
							bindValue="id"
							placeholder="{{'device_management.Use New' | translate}}"
							name="site_id"
							[(ngModel)]="site.id"
							[clearable]="false"
							required
						></ng-select>
					</div>
				</div>
			</div>
			<div class="col-sm-12 card-body">
				<div class="row mt-2 align-items-center">
					<div class="col-md-3">
						<strong>{{'g.customer' | translate}}</strong>
					</div>
					<div class="col-md-9">
						<ng-select
							[items]="customersList"
							bindLabel="name"
							bindValue="id"
							name="new_customer_id"
							[(ngModel)]="newCustomerSelected"
							[clearable]="false"
						></ng-select>
					</div>
				</div>
			</div>
			<div class="row" *ngIf="site.id == siteId">
				<div class="col-6">
					<app-mapbox
						class="border-radius main-blue-border"
						[latitude]="site.latitude||sitesService.defaultMapLocation.latitude"
						[longitude]="site.longitude||sitesService.defaultMapLocation.longitude"
						[zoom]="3"
						[showPopup]="false"
						[dots]="[{
							latitude: site.latitude||sitesService.defaultMapLocation.latitude,
							longitude: site.longitude||sitesService.defaultMapLocation.longitude
						}]"
						[clickEnabled]="true"
						(onAddressChanged)="updateSiteCoords($event)"
					></app-mapbox>
				</div>
				<div class="col-6">
					<div class="card">
						<div class="card-header">{{'g.site_info' | translate}}</div>
						<app-site-edit [site]="site" [enterpriseList]="enterpriseList" [restrictSpecialSite]="!usersService.hasAccessFunction('super_admin', 'write')"></app-site-edit>
						<div class="col-sm-12 card-body pt-0" *ngIf="site.id == siteId">
							<div class="row mt-2 align-items-center">
								<div class="col-md">
									<button type="submit" class="btn btn-primary mr-2">{{'g.submit' | translate}}</button>
									<button type="button" class="btn btn-default" (click)="goBack()">{{'g.cancel' | translate}}</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="col-sm-12 card-body" *ngIf="site.id != siteId">
				<div class="row mt-2 align-items-center">
					<div class="col-md">
						<button type="submit" class="btn btn-primary mr-2">{{'g.submit' | translate}}</button>
						<button type="button" class="btn btn-default" (click)="goBack()">{{'g.cancel' | translate}}</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</div>
