<div class="row col-12 justify-content-md-center">
	<form (ngSubmit)="reverseEvents()" class="col-md-6 col-sm-12">
		<h2>{{'action_log.reverse_events' | translate}}</h2>
		<div class="form-field mb-2">
			<label for="start_id">{{'g.start_id' | translate}}</label>
			<input type='number' class='form-control' name="start_id" [(ngModel)]='startId'/>
		</div>
		<div class="form-field mb-2">
			<label for="end_id">{{'g.end_id' | translate}}</label>
			<input type='number' class='form-control' name="end_id" [(ngModel)]='endId'/>
		</div>
		<div class="my-4">
			<button type="submit" class="btn btn-primary">{{'g.reverse' | translate}}</button>
		</div>
	</form>
</div>