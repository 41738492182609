import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-time-lost-report',
	templateUrl: './time-lost-report.component.html',
	styleUrls: ['./time-lost-report.component.css']
})
export class TimeLostReportComponent implements OnInit {

	devices: any[] = [];
	sites: any;
	customers: any;

	constructor(
		private router: Router,
		private usersService: UsersService,
		private deviceService: DeviceService
	) { }

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('show_warnings'))
			return this.router.navigate(['/unauthorized']);
		this.getTimeLostReport();
	}
	
	getTimeLostReport() {
		this.deviceService.getTimeLostReport().subscribe((res: any) => {
			this.devices = res.devices;
			this.sites = res.sites;
			this.customers = res.customers;
		});
	}
}
