<div class="date-range white-box main-black p-4 border-radius-bottom">
	<div class="border-bottom p-3">
		<h2>{{'admin.force_read_debug' | translate}}</h2>
	</div>
	
	<div class="mt-3 ml-5">
		<h6 *ngIf="forceReadDebugUntil" class="mt-2">{{'force_read_debug.reading_time_until' | translate}}: <b>{{forceReadDebugUntil}} ({{'g.utc' | translate}})</b></h6>
	</div>
	
	<div class="mt-4 ml-5">
		<label for="force_read_debug_time">{{'force_read_debug.sub_title' | translate}}</label>
		<div class="form-field mb-2 w-50">
			<input class="form-control" type="number" name="force_read_debug_time" [min]="0" [max]="maxAllowedDebugHours" [(ngModel)]="force_read_debug_time"/>
		</div>
	</div>
	<div class="mt-3 ml-5">
		<button type="submit" class="btn btn-primary" [disabled]="force_read_debug_time == null" (click)="saveDebugDate()">{{'g.submit' | translate}}</button>
	</div>
</div>