import { Component, OnInit } from '@angular/core';
import { Router} from '@angular/router';
import { Location } from '@angular/common';
import { NavigationService } from 'src/app/shared/services/navigation.service';

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html'
})
export class LandingPageComponent implements OnInit {
  
  constructor(
    private router : Router,
    private location: Location,
    private navigation: NavigationService
    ) { }

  ngOnInit(): void {
      const res =  sessionStorage.getItem('previousUrl');
      if(res == '/contact-us') {
        this.router.navigate(['/landing'])
        return;
      }
		if(!(this.location.getState() as {isLandingSuccessfully: boolean}).isLandingSuccessfully) {
      this.router.navigate(['login']);
		}
  }

}
