import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/users/users.service';
import { CommonDataService } from 'src/app/shared/services/common-data.service';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { PermissionsService } from '../permissions.service';

@Component({
	selector: 'app-permissions-edit',
	templateUrl: './permissions-edit.component.html',
	styleUrls: ['./permissions-edit.component.css']
})
export class PermissionsEditComponent implements OnInit, AfterViewInit {

	permissionId: any = 0;
	permissionType: any;
	permissionTypeArr: any = [
		'user',
		'enterprise'
	];

	permission: any = {
		id: 0,
		name: '',
		permissions: {}
	};

	permissionFields = {};
	readWriteOptions: any[] = [
		{v: 'noAccess',	text: this.translateService.instant('g.no_access')},
		{v: 'read', text: this.translateService.instant('g.read_only')},
		{v: 'write', text: this.translateService.instant('g.write')}
	];
	@ViewChild('linkedItemsModal') linkedItemsModal;

	constructor(
		private route: ActivatedRoute,
		public router: Router,
		private translateService: TranslateService,
		private usersService: UsersService,
		private commonDataService: CommonDataService,
		private sideMenuService: SideMenuService,
		private notificationMessageService: NotificationMessageService,
		private permissionsService: PermissionsService
	) {
		this.permissionFields = this.commonDataService.permissionFields['permissions'];
	}

	ngAfterViewInit() {
		this.linkedItemsModal.onClose.subscribe((ok) => {
			this.updatePermission(ok);
		});
	}
	ngOnInit() {
		if(!this.usersService.hasAccessFunction('permissions_management')){
			return this.router.navigate(['/'], {}).then(() => {
				this.notificationMessageService.setMessage('globalAuthMsg', {clearOnXTimeNavigate: 1});
			});
		}
		this.sideMenuService.hide();
		this.route.params.subscribe((params: any) => {
			this.permissionId = +params.permissionId || 0;
			if(!this.permissionTypeArr.includes(params.permissionType) || this.permissionId == 0) {
				return this.router.navigateByUrl('/404');
			}
			this.permissionType = params.permissionType;
			this.getPermissionInfo();
		});
	}

	getPermissionInfo() {
		this.permissionsService.getPermissionInfo(this.permissionId, this.permissionType).subscribe((data:any) => {
			if(!data.length) {
				return this.router.navigateByUrl('/404');
			}
			this.permission = data[0];
		});
	}

	updatePermission(updateLinkedItems) {
		this.permissionsService.updatePermission({
			permission: this.permission,
			type: this.permissionType,
			updateLinkedItems
		}).subscribe((response: any) => {
			if(response != 1) {
				return this.notificationMessageService.setMessage('globalErrMsg', {clearOnXTimeNavigate: 1});
			}
			this.notificationMessageService.setMessage('globalSuccessMsg');
			this.goBack();
		});
	}

	goBack() {
		this.router.navigate(['/permissions', this.permissionType]);
	}
}
