import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';
import { DeviceService } from 'src/app/home/site-dashboard/device/device.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { ColumnsConfig, TableConfig, TableData } from 'src/app/shared/custom-table/custom-table-interface';
import { TranslateService } from '@ngx-translate/core';
import { NotificationMessageService } from 'src/app/shared/notification-message/notification-message.service';
import { DeviceCommonService } from 'src/app/home/site-dashboard/device/device-common.service';
import { CommonService } from 'src/app/shared/services/common.service';

@Component({
	selector: 'app-quick-view-history',
	templateUrl: './quick-view-history.component.html',
	styleUrls: ['./quick-view-history.component.css']
})

export class QuickViewHistoryComponent implements OnInit {
	quickViewHistoryLogs: any[] = [];
	macAddress: string;
	invalidStartDate: boolean = false;
	invalidEndDate: boolean = false;

	fromDate: Date = new Date(new Date().setMonth(new Date().getMonth() - 1));
	toDate: Date = new Date();
	liveRtModalData: any;
	liveEvent: any = {};
	tableData: TableData[] = [];
	tableConfig: TableConfig = {
		hasExport: false,
		hasPagination: true,
		pageSize: 100,
		draggable: true
	};

	columnConfig: ColumnsConfig[] = [
		{ key: 'source', name: this.translateService.instant('g.source'), type: 'string', hasFilter: true},
		{ key: 'time', name: this.translateService.instant('g.time'), type: "date", hasFilter: true },
		{ key: 'live_event', name: this.translateService.instant('qv.live_event'), type: "action_link", hasFilter: true },
		{ key: 'ap_status', name: this.translateService.instant('qv.ap_status'), type: "string", hasFilter: true, hidden: true },
		{ key: 'light_sleep_mode', name: this.translateService.instant('qv.sleep_mode'), type: 'boolean', hasFilter: true, hidden: true },
		{ key: 'hw_status', name: this.translateService.instant('qv.hw_status'), type: 'string', hasFilter: true, hidden: true },
		{ key: 'internal_prob_raw', name: this.translateService.instant('qv.probe'), type: 'number', hasFilter: true, hidden: true},
		{ key: 'halleffect_c', name: this.translateService.instant('qv.hall_effect'), type: 'number', hasFilter: true},
		{ key: 'ext_adc_voltage', name: this.translateService.instant('qv.voltage_adc'), type: 'number', hasFilter: true},
		{ key: 'ext_adc_current', name: this.translateService.instant('qv.current_adc'), type: 'number', hasFilter: true},
		{ key: 'temperature_adc', name: this.translateService.instant('qv.temperature_adc'), type: 'number', hasFilter: true},
		{ key: 'ext_adc_current_low_range', name: this.translateService.instant('qv.low_current_adc'), type: 'number', hasFilter: true},
		{ key: 'live_rt', name: this.translateService.instant('qv.live_rt'), type: 'action_link', hasFilter: true},
		{ key: 'version', name: this.translateService.instant('g.version'), type: 'number', hasFilter: true, hidden: true},
		{ key: 'rssi', name: this.translateService.instant('qv.rssi'), type: 'number', hasFilter: true, hidden: true},
		{ key: 'network_idx', name: this.translateService.instant('qv.network_idx'), type: 'number', hasFilter: true, hidden: true},
		{ key: 'low_range_is_inuse', name: this.translateService.instant('qv.low_range_is_inuse'), type: 'boolean', hasFilter: true},
		{ key: 'current', name: this.translateService.instant('qv.current'), type: 'number', hasFilter: true},
		{ key: 'voltage', name: this.translateService.instant('qv.voltage'), type: 'number', hasFilter: true},
	];

	@ViewChild("liveRtDetailsModal") liveRtDetailsModal;
	@ViewChild("liveEventModal") liveEventModal;

	constructor (
		private route: ActivatedRoute,
		private router: Router,
		private userService: UsersService,
		private devicesService: DeviceService,
		private translateService: TranslateService,
		private sctToastService: NotificationMessageService,
		private deviceCommonService: DeviceCommonService,
		private datePipe: DatePipe,
		public common: CommonService
	) {}

	ngOnInit() {
		if (!this.userService.hasAccessPermission(null, 'noc'))
			return this.router.navigate(['/unauthorized']);

		this.route.params.subscribe(params => {
			this.macAddress = params['deviceId'];
			this.getQuickViewHistory();
		});
	}

	getQuickViewHistory() {
		this.invalidStartDate = false
		let zoneDiff = new Date().getTimezoneOffset() * -1;

		let from:any = new Date(new Date(this.fromDate).getTime() + (zoneDiff * 60 * 1000));
		let to:any = new Date(new Date(this.toDate).getTime() + (zoneDiff * 60 * 1000));
		from = moment(from).utc().startOf('day').unix();
		to = moment(to).utc().endOf('day').unix();

		if(from > to) {
			this.invalidStartDate = true;
			return this.sctToastService.setMessage('translate|g.start_lg_end');
		}

		this.devicesService.getQuickViewHistory(this.macAddress, from, to).subscribe((response: any) => {
			this.quickViewHistoryLogs = [];
			[...response || []].forEach(quick => {
				const quickData = this.common.decompress(quick.data, 'quick_view');
				let row: TableData = {
					quickData,
					source: {value: this.common.QV_SNAPSHOT_SOURCE[quick.source]},
					time: {value: moment(quickData.time*1000).utc().format('MM/DD/YYYY hh:mm:ss A')},
					live_event: {value: quickData.live_event.sequence_id, action: () => { this.showLiveEventModal(quickData.live_event, quickData) }},
					ap_status: {value: this.deviceCommonService.getApStatus(quickData.ap_status, quickData.ap_ip)},
					light_sleep_mode: {value: !!quickData['light_sleep_mode']},
					hw_status: {value: this.getHwStatus(quickData, 'getHwStatus')},
					internal_prob_raw: {value: quickData.internal_prob_raw},
					halleffect_c: {value: quickData.halleffect_c},
					ext_adc_voltage: {value: quickData.ext_adc_voltage},
					ext_adc_current: {value: quickData.ext_adc_current},
					temperature_adc: {value: quickData.temperature_adc},
					ext_adc_current_low_range: {value: quickData.ext_adc_current_low_range},
					live_rt: {value: quickData.live_rt.sequence_id, action: () => {this.showLiveRtModal(quickData.live_rt)}},
					version: {value: quickData.version},
					rssi: {value: quickData.rssi},
					network_idx: {value: quickData.network_idx},
					low_range_is_inuse: {value: !quickData.low_range_is_inuse},
					current: {value: quickData.current},
					voltage: {value: quickData.voltage}

				}
				this.quickViewHistoryLogs.push(row);
			});
		});
	}

	showLiveRtModal(liveRt) {
		delete liveRt.isValidCRC16;
		delete liveRt.crc16;
		liveRt.timestamp = moment(liveRt.timestamp*1000).utc().format('MM/DD/YYYY hh:mm:ss A')
		this.liveRtDetailsModal.show();
		this.liveRtModalData = liveRt;
	}

	showLiveEventModal(liveEvent, quickData) {
		if (liveEvent.timestamp_utc)
			liveEvent._duration = moment(quickData.time * 1000).utc().diff(moment(liveEvent.timestamp_utc * 1000).utc(), 'seconds');
		else
			liveEvent._duration = moment(quickData.time * 1000).diff(moment(liveEvent.timestamp * 1000).utc(), 'seconds');

		this.liveEvent = liveEvent;
		
		this.liveEventModal.show();
	}

	formatTime(val, options:any={}) {
		let zoneId = this.liveEvent.my_zone_id;

		if(options.convertToTimestamp)
			val = moment(val).utc().unix();

		let time = this.common.getZoneTimestampFromUTC(zoneId, val);
		time = this.common.getDateFormattedFromUnixTimeStamp(time);

		if(options.timeOnly)
			return this.datePipe.transform(time, 'hh:mm:ss a');

		if(options.dateOnly){
			return this.datePipe.transform(time, 'MM/dd/yyyy');
		}
		return this.datePipe.transform(time, 'MM/dd/yyyy hh:mm:ss a');
	}

	getHwStatus(quick, key) {
		let statuses = {
			1: 'warnings.plc',
			2: 'warnings.rtc',
			4: 'warnings.adc',
			8: 'warnings.flash_size'
		};

		let status = [];
		let hwValue = quick[key];

		for(let id in statuses) {
			if((hwValue & +id) != 0) {
				status.push(this.translateService.instant(statuses[id]))
			}
		}
		return status.join(', ');
	}

	formatNotes() {
		var flags = [
			'midnight_split',
			'time_change_split',
			'data_from_bv',
			'split_at_start',
			'split_at_end',
			'flag_finish',
			'flag_eq',
			'flag_finish_start',
			'flag_eq_start',
			'water_level_high',
			'temperature_exists'
		];

		var notes = [];

		flags.forEach((key) => {
			if(this.liveEvent[key])
				notes.push(this.translateService.instant('event.'+key));
		});

		return notes.join(', ');
	}

	formatQv(key, type="any", options={}) {
		let booleans = {
			'event': ['isValidCRC16'],
			'any': ['light_sleep_mode', 'low_range_is_inuse']
		};

		switch (type) {
			case "event":
				switch(key) {
					case "event_type":
						if(this.devicesService.eventTypes[this.liveEvent.event_type] !== undefined)
							return this.translateService.instant('event.'+this.devicesService.eventTypes[this.liveEvent.event_type]);
					break;
					case "my_zone_id":
						let zoneId = this.liveEvent[key];
						return this.common.getZoneName(zoneId) + ' ['+zoneId+']';
					break;
					case "water_level_high":
						let waterLevel = this.liveEvent[key] ? 'high' : 'low';
						return this.translateService.instant('g.'+waterLevel);
					break;
					case "timestamp_utc":
					case "end_timestamp_utc":
						return this.formatTime(this.liveEvent[key], options);
					break;
					case "notes":
						return this.formatNotes();
					break;
					default:
						if(booleans['event'].includes(key))
							return !!this.liveEvent[key];
					break;
				}
			break;

			default:
				switch(key) {
					case "time":
						return this.formatTime(this.liveEvent[key]);
					break;
					default:
						if(booleans['any'].includes(key))
							return !!this.liveEvent[key];
					break;
				}
			break;
		}
	}
}