<div class="main-black border-radius-bottom white-box mt-1" *ngIf="sitesList">
	<div class="card-body" *ngIf="sitesList.length > 0">
		<h6 class="mb-3">{{sitesList.length}} {{'nav.manage_device_requests' | translate}}</h6>
		<table class="table mb-0">
			<thead>
				<tr>
					<th>{{'g.site_name' | translate}}</th>
					<th>{{'g.customer' | translate}}</th>
					<th>{{'device_management.request_by_user' | translate}}</th>
					<th>{{'g.enterprise_name' | translate}}</th>
					<th>{{'device_management.source' | translate}}</th>
					<th>{{'g.actions' | translate}}</th>
				</tr>
			</thead>
			<tbody>
				<tr *ngFor="let site of sitesList">
					<td>
						{{site.site_name}}
						<span *ngIf="site._approved_site_id">
							(<a href="javascript:;" (click)="showIdenticalSiteModal(site)">{{'device_management.identical_site_found' | translate}}</a>)
						</span>
					</td>
					<td>
						<a [routerLink]="['/customers', 'edit', site.customer_id]" target="_blank" *ngIf="usersService.hasAccessFunction('customer_management'); else customerNoLink">
							{{site.customer_name}}
						</a>
						<ng-template #customerNoLink>{{site.customer_id}}</ng-template>
					</td>
					<td>
						<a [routerLink]="['/user', 'edit', site.user_id]" target="_blank" *ngIf="usersService.hasAccessFunction('edit_user_admin') && usersInfo[site.user_id]; else userNoLink">
							{{usersInfo[site.user_id].first_name}} {{usersInfo[site.user_id].last_name}}
						</a>
						<ng-template #userNoLink>{{site.user_id}}<span *ngIf="!usersInfo[site.user_id]"> ({{'g.deleted' | translate}})</span></ng-template>
					</td>
					<td>
						<ng-container *ngIf="enterprisesInfo[site.enterprise_id]; else entDel">{{enterprisesInfo[site.enterprise_id].name}}</ng-container>
						<ng-template #entDel>{{site.enterprise_id}}<span> ({{'g.deleted' | translate}})</span></ng-template>
					</td>
					<td>{{(site.source ? 'device_management.source_options.'+[site.source] : 'g.n/a') | translate}}</td>
					<td>
						<mat-icon class="pointer blue-hover" [matMenuTriggerFor]="actionsMenu" [matMenuTriggerData]="{site: site}">
							settings
						</mat-icon>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
	<div class="card-body" *ngIf="sitesList.length == 0">
		<h3>{{'device_management.no_new_move' | translate}}</h3>
	</div>
</div>

<mat-menu #actionsMenu="matMenu" yPosition="below" [overlapTrigger]="false">
	<ng-template matMenuContent let-site="site">
		<span mat-menu-item [routerLink]="['/management/device-management-requests/site-edit', site.site_id]">
			{{'device_management.edit_approve' | translate}}
		</span>
		<span mat-menu-item (click)="getSiteLinkedDevices(site.site_id, 'linkedDevices')">
			{{'device_management.view_devices' | translate}}
		</span>
		<span mat-menu-item (click)="getSiteLinkedDevices(site.site_id, 'location')" *ngIf="site.source == 'device' && !site._approved_site_id">
			{{'device_management.view_location' | translate}}
		</span>
		<span mat-menu-item class="text-danger" (click)="confrimRejectRequest(site.site_id)">
			{{'g.reject' | translate}}
		</span>
	</ng-template>
</mat-menu>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate"></app-modal>
<app-modal #identicalSiteModal title="{{'device_management.identical_site_title' | translate}}" [closeBtnText]="'g.cancel' | translate">
	<p>
		{{'device_management.identical_site_message' | translate}}:
	</p>
	<div class="card p-2">
		<div class="row">
			<div class="col-4">Site Name</div>
			<div class="col-8">{{approvedSiteInfo.site_name}}</div>
		</div>
		<div class="row">
			<div class="col-4">Country</div>
			<div class="col-8">{{approvedSiteInfo.country_id}}</div>
		</div>
		<div class="row">
			<div class="col-4">State</div>
			<div class="col-8">{{approvedSiteInfo.state_id}}</div>
		</div>
		<div class="row">
			<div class="col-4">address</div>
			<div class="col-8">{{approvedSiteInfo.address}}</div>
		</div>
	</div>
</app-modal>
<app-modal #linkedDevicesModal title="{{'ent.linked_devices_title' | translate}}" [hasConfirmButton]="false" size="lg">
	<p>
		{{'ent.linked_devices_title' | translate}}:
	</p>
	<div class="card p-2">
		<ng-container *ngIf="linkedDevices.length; else noDevices">
			<div class="row my-2">
				<div class="col-4"><b>{{'g.mac_address' | translate}}</b></div>
				<div class="col-4"><b>{{'devices.latitude' | translate}}</b></div>
				<div class="col-4"><b>{{'devices.longitude' | translate}}</b></div>
			</div>
			<div class="row" *ngFor="let device of linkedDevices">
				<div class="col-4">
					<a class="blue-hover pointer ml-1" [routerLink]="['/', device.customer_id, device.site_id, device.mac_address, 'performance']" target="_blank">
						{{device.mac_address}}
					</a>
				</div>
				<div class="col-4">
					{{device.latitude}}
				</div>
				<div class="col-4">
					{{device.longitude}}
				</div>
			</div>
		</ng-container>
	</div>
</app-modal>
<app-modal #locationModal title="{{'g.locations' | translate}}" size="lg" [hasConfirmButton]="false">
	<app-mapbox *ngIf="linkedDevices.length > 0; else noDevices"
		class="border-radius main-blue-border"
		[latitude]="sitesService.defaultMapLocation.latitude"
		[longitude]="sitesService.defaultMapLocation.longitude"
		zoom="3.5"
		[showPopup]="false"
		[dots]="linkedDevices"
	></app-mapbox>
</app-modal>
<ng-template #noDevices>
	<div class="row">
		<div class="col">
			{{'device_management.no_devices_found' | translate}}
		</div>
	</div>
</ng-template>