<app-chart-data-widget
	[title]="chargLinkTranslationPath+ 'site_performance.truck_usage_time_report' | translate"
	[devices]="devices"
	[siteName]="siteInfo?.name"
	[data]="data"
	[config]="{
		hAxis: {title: 'site_performance.time_report_title' | translate},
		vAxis: {title: chargLinkTranslationPath+'site.number_of_trucks' | translate, format: '0'},
		legend: 'none'
	}"
	[stackColumn]="'truck_type'"
	[columnNames]="columnNames.truck_usage_duration"
	[widgetId]="'truck_usage_duration'"
	[tableColumns]="tableColumns.truck_usage_duration"
	[tableSortBy]="'avgValue'"
	[listsOptions]="listsOptions"
	[tags]="tags"
	[deviceTags]="deviceTags"
	[options]="{
		hideTruckTypesOptions: isChargersPerformance,
		hideTruckYearsOptions: isChargersPerformance
	}"
	[tooltipKey]="chargLinkTranslationPath+'truck_usage_duration'"
></app-chart-data-widget>
<app-chart-data-widget
	[title]="'site_performance.truck_usage_as_report' | translate"
	[devices]="devices"
	[siteName]="siteInfo?.name"
	[data]="data"
	[config]="{
		hAxis: {title: 'site_performance.as_report_title' | translate},
		vAxis: {title: 'site.number_of_trucks' | translate, format: '0'},
		legend: 'none'
	}"
	[widgetId]="'truck_usage_as'"
	[listsOptions]="listsOptions"
	[stackColumn]="'truck_type'"
	[tableColumns]="tableColumns.truck_usage_as"
	[tableSortBy]="'avgValue'"
	[columnNames]="columnNames.truck_usage_as"
	[tags]="tags"
	[deviceTags]="deviceTags"
	*ngIf="!isChargersPerformance"
></app-chart-data-widget>
<app-chart-data-widget
	[title]="chargLinkTranslationPath + 'site_performance.truck_usage_ws_report' | translate"
	[devices]="devices"
	[siteName]="siteInfo?.name"
	[data]="data"
	[config]="{
		hAxis: {title: 'site_performance.ws_report_title' | translate},
		vAxis: {title: chargLinkTranslationPath+'site.number_of_trucks' | translate, format: '0'},
		legend: 'none'
	}"
	[widgetId]="'truck_usage_ws'"
	[tableColumns]="tableColumns.truck_usage_ws"
	[tableSortBy]="'avgValue'"
	[listsOptions]="listsOptions"
	[stackColumn]="'truck_type'"
	[columnNames]="columnNames.truck_usage_ws"
	[tags]="tags"
	[deviceTags]="deviceTags"
	[options]="{
		hideTruckTypesOptions: isChargersPerformance,
		hideTruckYearsOptions: isChargersPerformance
	}"
	[tooltipKey]="chargLinkTranslationPath+'truck_usage_ws'"
></app-chart-data-widget>
<app-chart-data-widget
	[title]="'site_performance.idle_events_report_site' | translate"
	[devices]="devices"
	[siteName]="siteInfo?.name"
	[data]="data"
	[config]="{
		hAxis: {title: 'site_performance.idle_events_report_title' | translate},
		vAxis: {title: 'site.number_of_trucks' | translate, format: '0'},
		colors: ['#109618', '#3366cc', 'yellow', '#ff9900', '#dc3912']
	}"
	[widgetId]="'idle_duration'"
	[tableColumns]="tableColumns.idle_duration"
	[tableSortBy]="'value'"
	[columnNames]="[
		'g.type' | translate,
		'durations.30_mins' | translate,
		'durations.30_60_mins' | translate,
		'durations.60_120_mins' | translate,
		'durations.120_240_mins' | translate,
		'durations.240_mins' | translate
	]"
	[tags]="tags"
	[deviceTags]="deviceTags"
	[listsOptions]="listsOptions"
	*ngIf="!isChargersPerformance"
></app-chart-data-widget>
<app-chart-data-widget
	[title]="'site_performance.relative_truck_usage' | translate"
	[devices]="devices"
	[siteName]="siteInfo?.name"
	[data]="data"
	[config]="{
		hAxis: {title: 'devices.truck_sn' | translate},
		vAxis: {title: 'site_performance.utilization_percentage' | translate},
		legend: 'none'
	}"
	[widgetId]="'relative_truck_usage_kws'"
	[tableColumns]="tableColumns.relative_truck_usage_kws"
	[listsOptions]="listsOptions"
	[options]="{
		hasBatteryCapacitiesOptions: true,
		hasBatteryVoltagesOptions: true
	}"
	[columnNames]="[
		'g.type' | translate,
		'site_performance.utilization_percentage' | translate
	]"
	[tags]="tags"
	[deviceTags]="deviceTags"
	*ngIf="!isChargersPerformance"
></app-chart-data-widget>

<app-chart-data-widget
	[title]="'site_performance.end_voltage_report' | translate"
	[devices]="devices"
	[siteName]="siteInfo?.name"
	[data]="data"
	[selectedDate]="'yesterday'"
	[config]="{
		hAxis: {title: 'event.vpc' | translate},
		vAxis: {title: 'site.number_of_events' | translate, format: '0'},
		legend: 'none'
	}"
	[widgetId]="'end_of_voltages_vpc'"
	[tableColumns]="tableColumns.end_of_voltages_vpc"
	[tableSortBy]="'value'"
	[listsOptions]="listsOptions"
	[options]="{
		hasBatteryTypesOptions: true
	}"
	[columnNames]="[
		'g.type' | translate,
		'site_performance.events_count' | translate,
		{role: 'style'}
	]"
	[tags]="tags"
	[deviceTags]="deviceTags"
	*ngIf="!isChargersPerformance"
></app-chart-data-widget>

<app-chart-data-widget
	[title]="'site_performance.regen_report' | translate"
	[devices]="devices"
	[siteName]="siteInfo?.name"
	[data]="data"
	[config]="{
		hAxis: {title: 'site_performance.regen_options.ahr' | translate},
		vAxis: {title: 'site.number_of_trucks' | translate, format: '0'},
		legend: 'none'
	}"
	[widgetId]="'regen_report'"
	[listsOptions]="listsOptions"
	[stackColumn]="'truck_type'"
	[tableColumns]="tableColumns.regen_report"
	[tableSortBy]="'avgValue'"
	[columnNames]="columnNames.truck_usage_as"
	[tags]="tags"
	[deviceTags]="deviceTags"
	[options]="{hasRegenOptions: true}"
	*ngIf="!isChargersPerformance"
></app-chart-data-widget>
<app-simple-widget 
	*ngIf="usageChartReady"
	title="{{chargLinkTranslationPath + 'site_performance.total_truck_usage' | translate}}"
	[widgetId]="'total_trucks_usage'"
	[tooltipKey]="usageTooltip"
	id="usage-chart"
>
	<div class="panel-body" *ngIf="!isCollapsed">
		<div class="row noprint mb-4">
			<div class="col-sm-12 col-md-3 pb-1 d-inline-flex">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{'g.start_date' | translate}}</span>
					</div>
					<input class="form-control" [ngClass]="{'text-danger': usageInvalidDateRange}" [minDate]="minStartUsageDate" placeholder="mm/dd/yyyy" name="startUsageDB" [(ngModel)]="usageFromDate" (ngModelChange)="usageDatesChanged()" bsDatepicker #startUsageDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
					<div class="input-group-append pointer" (click)="startUsageDB.toggle()">
						<span class="input-group-text"><i class="fa fa-calendar"></i></span>
					</div>
				</div>
			</div>

			<div class="col-sm-12 col-md-3 pb-1 d-inline-flex">
				<div class="input-group">
					<div class="input-group-prepend">
						<span class="input-group-text">{{'g.end_date' | translate}}</span>
					</div>
					<input class="form-control" placeholder="mm/dd/yyyy" name="endUsageDB" [(ngModel)]="usageToDate" (ngModelChange)="usageDatesChanged()" bsDatepicker #endUsageDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
					<div class="input-group-append pointer" (click)="endUsageDB.toggle()">
						<span class="input-group-text"><i class="fa fa-calendar"></i></span>
					</div>
				</div>
			</div>

			<div class="col-sm-12 col-md-3 pb-1 d-inline-flex" *ngIf="!usageInvalidDateRange && !isChargersPerformance">
				<select
					class="form-control input-sm"
					[(ngModel)]="usageTruckType"
					(change)="usageDatesChanged()"
				>
					<option *ngFor="let option of listsOptions.truckTypes" [value]="option.value">
						{{option.label}}
					</option>
				</select>
			</div>
			<div class="d-inline-flex ml-auto" *ngIf="!usageInvalidDateRange && usageChartDataAmp.length">
				<button class="btn btn-xs" printTitle="{{'g.performance' | translate}}" [useExistingCss]="true" printSectionId="usage-chart" ngxPrint  href="javascript:">
					<i class="fa fa-print"></i>
				</button>
				<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}" (click)="exportTotalUsage()"></button>
			</div>
		</div>

		<div class="margin-top-5 row" *ngIf="usageChartDataAmp.length; else noData">
			<div class="showOnPrint">
				<h3>{{'perf_analytic.period' | translate}}: {{usageFromDate | date:'MM/dd/yyyy'}} - {{usageToDate | date:'MM/dd/yyyy'}}</h3>
				<h3>{{getTruckTypeName(usageTruckType)}}</h3>
			</div>
			<sct-charts
				id="usage_chart_amp"
				[title]="chargLinkTranslationPath + 'site_performance.total_truck_amp_usage' | translate"
				[data]="usageChartDataAmp"
				[columnNames]="usageAmpColumnNames"
				[options]="usageAmpOptions"
				[type]="'LineChart'"
				[height]="500"
				*ngIf="!usageInvalidDateRange"
			></sct-charts>
			<div class="showOnPrint">
				<table class="table">
					<thead class="pb-2">
						<th>{{'g.time' | translate}}</th>
						<th>{{'perf_analytic.value_amps' | translate}}</th>
					</thead>
					<tbody>
						<ng-container *ngFor="let item of usageChartDataAmp">
							<tr *ngIf="item[1] != 0">
								<td>{{item[0] | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
								<td>{{item[1] | number:'1.2-2'}}</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
			
			<sct-charts
				id="usage_chart_kws"
				[title]="chargLinkTranslationPath + 'site_performance.total_truck_kw_usage' | translate"
				[data]="usageChartDataWatt"
				[columnNames]="usageKwColumnNames"
				[options]="usageKwOptions"
				[type]="'LineChart'"
				[height]="500"
				*ngIf="!usageInvalidDateRange"
			></sct-charts>
			<div class="showOnPrint">
				<table class="table">
					<thead class="pb-2">
						<th>{{'g.time' | translate}}</th>
						<th>{{'perf_analytic.value_kws' | translate}}</th>
					</thead>
					<tbody>
						<ng-container *ngFor="let item of usageChartDataWatt">
							<tr *ngIf="item[1] != 0">
								<td>{{item[0] | date:'MM/dd/yyyy hh:mm:ss a'}}</td>
								<td>{{item[1] | number:'1.2-2'}}</td>
							</tr>
						</ng-container>
					</tbody>
				</table>
			</div>
		</div>
	</div>
</app-simple-widget>

<app-simple-widget
	title="{{'g.charge_inuse_report' | translate}}"
	[widgetId]="'total_events_report'"
	id="events-report"
	*ngIf="false"
>
	<div class="row noprint mb-4">
		<div class="col-sm-12 col-md-5 pb-1 d-inline-flex">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control" [ngClass]="{'text-danger': reportInvalidDateRange}" [minDate]="minStartReportDate" placeholder="mm/dd/yyyy" name="startReortDB" [(ngModel)]="reportFromDate" (ngModelChange)="reportDatesChanged()" bsDatepicker #startReportDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="startReportDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>

		<div class="col-sm-12 col-md-5 pb-1 d-inline-flex">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input class="form-control" placeholder="mm/dd/yyyy" name="endReportDB" [(ngModel)]="reportToDate" (ngModelChange)="reportDatesChanged()" bsDatepicker #endReportDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="endReportDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>

		<div class="d-flex mt-3 ml-1 col-sm-12">
			<div class="radio">
				<label>
					<input type="radio"
						   value="true"
						   [checked]="selectView"
						   (change)="onViewSelectionChange(true)"
						   >
				</label>
			</div>
			<small class="mr-3">{{'g.indvisual' | translate}}</small>
			<div class="radio">
				<label>
					<input type="radio"
							value="false"
							[checked]="!selectView"
							(change)="onViewSelectionChange(false)"
							>
				</label>
			</div>
			<small>{{'g.aggregate' | translate}}</small>
		</div>


		<div class="white-box mt-0 p-4 border-radius col-sm-12" *ngIf="selectView">
			<app-grid
				[data]="dailyDetails" 
				[columns]="dailyGridCols"
				#agGrid
				id="daily-details-info"	
				*ngIf="dailyDetails.length > 0; else noRes"
			></app-grid>
		</div>

		<div class="white-box mt-4 p-4 border-radius col-sm-12" *ngIf="!selectView">
			<app-grid
				[data]="aggDetails" 
				[columns]="aggGridCols"
				#agGrid
				id="agg-details-info"	
				*ngIf="aggDetails.length > 0; else noRes"
			></app-grid>
		</div>
		<ng-template #noRes>
			<div class="text-danger" *ngIf="reportInvalidDateRange; else noData">
				<b>{{'g.start_lg_end' | translate}}</b>
			</div>
		</ng-template>
		<ng-template #noData>
			{{'g.no_data_available_for_the_selected_period' | translate}}
		</ng-template>
	</div>
</app-simple-widget>

<ng-template #noData>
	<div class="mt-4 alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>