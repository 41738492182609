<div class="mt-4">
	<div class="row">
		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'action_log.log_month' | translate}}:</span>
				</div>
				<select [(ngModel)]="filter.logMonth" class="form-control inline-form-control"  name="log-month">
					<option *ngFor="let option of logMonths"  [value]="option">{{option}}</option>
				</select>
			</div>
		</div>
	</div>
	<div class="row mt-1">
		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'action_log.action_name' | translate}}:</span>
				</div>
				<ng-select
					[items]="actions"
					placeholder="{{ 'action_log.action_name' | translate }}"
					bindLabel="label"
					bindValue="key"
					name="action-name"
					multiple="false"
					[(ngModel)]="filter.actionName"
					class="select"
				>
				</ng-select>
			</div>
		</div>
	</div>
	<div class="row mt-1">
		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'action_log.related_id' | translate}}:</span>
				</div>
				<input type="text" class="form-control inline-form-control" [(ngModel)]="filter.relatedID"/>
			</div>
		</div>
	</div>
	<div class="row mt-1">
		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'action_log.action_user_id' | translate}}:</span>
				</div>
				<input type="number" class="form-control inline-form-control" [(ngModel)]="filter.actionUserID"/>
			</div>
		</div>
	</div>
	<div class="row mt-1">
		<div class="col-md-6">
			<div class="input-group">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'action_log.generic_id_search' | translate}}:</span>
				</div>
				<input type="text" class="form-control" [(ngModel)]="filter.genericIdSearch"/>
			</div>
		</div>
	</div>
	<div class="row mt-1">
		<div class="col-md-6">
			<button class="form-control btn btn-success" (click)="getActionLogs(false, true)">{{'g.get_logs' | translate}}</button>
		</div>
	</div>
	<div class="my-4">
		<app-table #dataTable name="action-log" [elements]="actionsLog" [columnNames]="columnNames" [showExport]="false" [hasCheckboxColumn]="false" [pagination]="false"></app-table>
	</div>
	<server-pagination idField="id" [limit]="recordsLimit" (paginate)="getActionLogs($event)" #serverPagination></server-pagination>
</div>