<table class="table table-striped boxs" *ngIf="log">
	<tbody>
		<tr></tr>
		<tr>
			<td>{{'g.time' | translate}}</td>
			<td>{{log.error_time}}</td>
		</tr>
		<tr>
			<td>{{'error_log.function_name' | translate}}</td>
			<td>{{log.function_name}}</td>
		</tr>
		<tr>
			<td>{{'error_log.main_caller' | translate}}</td>
			<td>{{log.main_caller}}</td>
		</tr>
		<tr>
			<td>{{'users.user_id' | translate}}</td>
			<td>{{log.user_id}}</td>
		</tr>
		<tr>
			<td>{{'error_log.error' | translate}}</td>
			<td>{{log.error}}</td>
		</tr>
		<tr>
			<td>{{'error_log.server_ip' | translate}}</td>
			<td>{{log.server_ip}}</td>
		</tr>
		<tr>
			<td>{{'error_log.user_ip' | translate}}</td>
			<td>{{log.user_ip}}</td>
		</tr>
		<tr>
			<td>{{'error_log.browser' | translate}}</td>
			<td>{{log.browser}}</td>
		</tr>
		<tr>
			<td>{{'error_log.extra_info' | translate}}</td>
			<td>{{log.extra_field}}</td>
		</tr>
		<tr>
			<td>{{'error_log.trace' | translate}}</td>
			<td>{{log.trace}}</td>
		</tr>
		<tr>
			<td>{{'error_log.is_sched' | translate}}</td>
			<td>{{log.is_sched}}</td>
		</tr>
		<tr>
			<td>{{'g.git_info' | translate}}</td>
			<td>{{log.git_info}}</td>
		</tr>
	</tbody>
</table>