<div class="my-3">
	<button type="button" [routerLink]="['edit', 0]" class="btn btn-primary mb20">{{'g.add_new' | translate}} <fa-icon icon="plus"></fa-icon></button>
</div>
<div class="mt-2 mb-2 ml-2 mr-2">
	<div class="card">
		<div class="card-body">
			<div class="row">
				<label class="col-1" for="search_enterprise"><small><strong>{{'g.search' | translate}}</strong></small></label>
				<div class="col-4">
					<input type="text" class="form-control" name="search_enterprise" [(ngModel)]="filters.searchString"/>
				</div>
				<div class="col-4 mt-2">
					<input class="form-check-input" type="checkbox" name="isPartial" [(ngModel)]="filters.isPartial" id="defaultCheck1">
					<label class="form-check-label" for="defaultCheck1">
						<small><strong>{{'g.partial_search' | translate}}</strong></small>
					</label>
				</div>
			</div>
			<div class="row mt-1">
				<label class="col-1" for="search_enterprise"><small><strong>{{'g.group_type' | translate}}</strong></small></label>
				<div class="col-4">
					<ng-select 
						[items]="groupTypes" 
						bindLabel="text" 
						bindValue="val" 
						name="search_group_type" 
						[(ngModel)]="filters.groupType"
						[multiple]="true">
				</ng-select>
				</div>
			</div>
			<div class="mt-1">
				<button class="btn btn-primary" (click)="search()">{{'g.search'| translate}}</button>
			</div>
		</div>
	</div>
</div>
<div class="mt-2 mb-2 ml-2 mr-2">
	<table class="table table-striped">
		<thead>
			<tr>
				<th>{{'g.name' | translate}}</th>
				<th>{{'g.admins' | translate}}</th>
				<th>{{'g.group_type' | translate}}</th>
				<th>{{'g.actions' | translate}}</th>
			</tr>
		</thead>
		<tbody>
		<tr *ngFor="let item of enterprise">
			<td>{{item.name}}</td>
			<td><div *ngFor="let id of item.admins"><span *ngIf="admins[id]">{{admins[id].first_name}} {{admins[id].last_name}}</span></div></td>
			<td>{{enterpriseService.getGroupTypeText(item.group_type)}}</td>
			<td>
				<div class="btn-group mx-3" dropdown placement="left">
					<fa-icon icon="cog" size="lg" dropdownToggle class="dropdown-toggle"></fa-icon>
					<ul *dropdownMenu class="dropdown-menu" role="menu">
						<li role="menuitem">
							<a class="dropdown-item pointer" (click)="goToEditEnterprise(item.id)">{{'g.edit' | translate}}</a>
						</li>
						<li role="menuitem">
							<a class="dropdown-item pointer" (click)="confirmModal.message = 'g.del_enterprise_prompt'; confirmModal.passData = ['deleteEnterprise', item.id]; confirmModal.show();">{{'g.delete' | translate}}</a>
						</li>
					</ul>
				</div>
			</td>
		</tr>
		</tbody>
	</table>
</div>

<app-modal #confirmModal title="{{'g.confirm_action' | translate}}" [closeBtnText]="'g.cancel' | translate">
</app-modal>
<app-modal #linkedDevicesModal [hasConfirmButton]="false" [title]="'ent.linked_devices_title' | translate">
	<div class="alert alert-danger mb-0" role="alert">
		{{'ent.linked_devices_prompt' | translate}}
	</div>
</app-modal>

<app-modal #deleteAutoCustomerProcess [title]="'g.confirm_action' | translate" [hasConfirmButton]="false" [hasCloseButton]="false">
	<div class="modal-footer">
		<button class="btn text-white main-blue-bg btn-lg mr-4 px-4" (click)="deleteAutoCustomerProcess.hide();deleteEnterpriseCustomer(deleteAutoCustomerProcess.passData.enteroriseId, true)">{{'g.yes' | translate}}</button>
		<button class="btn text-white main-grey-bg btn-lg mr-4 px-4" (click)="deleteAutoCustomerProcess.hide();deleteEnterprise(deleteAutoCustomerProcess.passData.enteroriseId, true, false)">{{'g.no' | translate}}</button>
		<button class="btn text-white main-green-bg btn-lg" (click)="deleteAutoCustomerProcess.hide()">{{'g.cancel' | translate}}</button>
	</div>
</app-modal>