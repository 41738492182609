import { Component, OnInit, OnChanges, Input, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { SiteDashboardService } from '../../../site-dashboard.service';

@Component({
	selector: 'app-device-notes',
	templateUrl: './device-notes.component.html',
	styleUrls: ['./device-notes.component.css']
})
export class DeviceNotesComponent implements OnInit, OnChanges, OnDestroy {

	@Input() device: any;
	@Input() currentSite: any;

	siteId: number = 0;
	macAddress: string = '';
	tooltip: string;
	permissionsSub: Subscription = new Subscription();

	constructor(
		private commonService: CommonService,
		private siteDashboardService: SiteDashboardService,
		public userService: UsersService,
	) { }

	ngOnInit() {}
	
	ngOnChanges() {
		this.siteId = this.device.config_info.site_id;
		this.macAddress = this.device.mac_address;
		this.permissionsSub = this.siteDashboardService.permissionsOfCurrentSite.subscribe(data=> {
			this.tooltip = this.commonService.getTooltipKey('device_notes', this.userService.userHasNOCAccess(data));
		});
	}

	ngOnDestroy() {
		this.permissionsSub.unsubscribe();
	}
}
