import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SideMenuService } from 'src/app/shared/side-menu/side-menu.service';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-management-requests',
	templateUrl: './management-requests.component.html',
	styleUrls: ['./management-requests.component.css']
})
export class ManagementRequestsComponent implements OnInit {

	activeTab: string;
	activePage = 'devices';

	constructor(
		private route: ActivatedRoute,
		public usersService: UsersService,
		private router: Router,
		private sideMenuService: SideMenuService
	) { }

	ngOnInit() {
		this.sideMenuService.hide();
		if(!this.usersService.hasAccessFunction('device_management') && !this.usersService.hasAccessFunction('replace_device'))
			return this.router.navigate(['/unauthorized']);

		this.activePage = (['devices', 'customers', 'sites', 'replacement'].includes(this.route.snapshot.firstChild.data.pageName) ? this.route.snapshot.firstChild.data.pageName : 'devices');
	}
}
