import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { DeviceService } from '../../device.service';
import * as moment from 'moment';
import { ActivatedRoute } from '@angular/router';
import { CommonService } from 'src/app/shared/services/common.service';
import { UsersService } from 'src/app/users/users.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-actions-history',
  templateUrl: './actions-history.component.html',
  
})
export class ActionsHistoryComponent implements OnInit, OnChanges {

	@Input() device: any = {};
	@Input() dateRange: any = {};
	@Input() invalidDateRange: boolean = false;
	@Input() currentSite: any;
	
	gridColumns: any;
	gridData: any[] = [];

	emptyResponse = false;
	constructor(
		private deviceService: DeviceService,
		private usersService: UsersService,
		private commonService: CommonService,
		private router: Router,
	) { }

	ngOnInit() {
		if(!this.usersService.getCurrentUser().is_admin)
			return this.router.navigate(['/unauthorized']);
		this.gridColumns = [
			{headerName: 'action_history.action_name', field: "action_name", type:"string", width: '200'},
			{headerName: 'action_history.username', field: "username", type:"string", width: '250'},
			{headerName: "action_history.action_time", field: "action_time", type: 'dateTime', width: '250'},
		];
	}

	getActionsHistory() {
		let fromDateRange = this.dateRange.fromDate;
		let toDateRange = this.dateRange.toDate;

		if(!this.device.mac_address || !fromDateRange || !toDateRange)
			return;

		let zoneDiff		= new Date().getTimezoneOffset() * -1;
		let fromDate:any	= new Date(new Date(fromDateRange).getTime() + (zoneDiff * 60 * 1000));
		let toDate:any		= new Date(new Date(toDateRange).getTime() + (zoneDiff * 60 * 1000));
		fromDate			= moment(fromDate).utc().startOf('day').unix();
		toDate				= moment(toDate).utc().endOf('day').unix();
		fromDate			= this.commonService.getUTCTimestampFromZone((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), fromDate);
		toDate				= this.commonService.getUTCTimestampFromZone((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), toDate);

		this.deviceService.getDeviceActions(this.device.mac_address, fromDate, toDate).subscribe(
			(response: any) => {
				
				if(response.length)
					this.emptyResponse = false;
				else
					this.emptyResponse = true;
				const tableData = response.map((record) => {
					record.action_time				= this.commonService.getZoneTimestampFromUTC((this.device.config_info.zone_id?this.device.config_info.zone_id:this.currentSite.zone_id), record.action_time);
					record.action_time				= moment(record.action_time * 1000).utc().format('MM/DD/YYYY hh:mm:ss a');
					return {
						action_name: this.deviceService.getCommandNameFromCode(record.action_code),
						username: record.user_id + " - "+ (record.first_name??'') + ' ' + (record.last_name??''),
						action_time: record.action_time,
					}
				});

				this.gridData = tableData;
			}
		);
	};

	ngOnChanges() {
		this.getActionsHistory();
	}
}
