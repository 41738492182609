import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../admin.service';
import { Router } from '@angular/router';
import { UsersService } from 'src/app/users/users.service';

@Component({
	selector: 'app-sched-monitor',
	templateUrl: './sched-monitor.component.html',
})
export class SchedMonitorComponent implements OnInit {

	schedFunctions = <any>[];

	constructor(
		private router: Router,
		private usersService: UsersService,
		private adminService: AdminService,
	) {}

	ngOnInit() {
		if(!this.usersService.hasAccessFunction('sched_functions_control'))
			return this.router.navigate(['/unauthorized']);
		this.adminService.getCronJobsStatus().subscribe(
			data => {
				this.schedFunctions = data;
			}
		);
	}
}
