import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import {distinctUntilChanged} from 'rxjs/operators';

@Injectable()
export class LeftPanelService {

	private panelVisibilitySubject = new BehaviorSubject<boolean>(true);
	leftPanelVisibility = this.panelVisibilitySubject.asObservable();

	constructor() { }

	hide() { this.panelVisibilitySubject.next(false);}

	show() { this.panelVisibilitySubject.next(true);}

	getVisibility() {
		return this.panelVisibilitySubject.value;
	}
}