<div class="white-box main-black mt-4 p-4 border-radius">
	<div class="row">
		<div class="col">
			<h4>
				{{'perf_analytic.charge_opportunity_analysis' | translate}}
			</h4>
		</div>
	</div>
	<div class="row py-4">
		<div class="col-sm-6 col-md-7 d-inline-flex">
			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.start_date' | translate}}</span>
				</div>
				<input class="form-control" [ngClass]="{'text-danger': invalidDate}" [minDate]="deviceInstallationDate" [maxDate]="maxDate" placeholder="mm/dd/yyyy" name="thStartDB" [(ngModel)]="startDate" (ngModelChange)="getDailyDetails()" bsDatepicker #thStartDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="thStartDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>

			<div class="input-group col-6 p-0 mr-3 d-inline-flex">
				<div class="input-group-prepend">
					<span class="input-group-text">{{'g.end_date' | translate}}</span>
				</div>
				<input class="form-control" placeholder="mm/dd/yyyy" name="thEndDB" [(ngModel)]="endDate" [minDate]="deviceInstallationDate" [maxDate]="maxDate" (ngModelChange)="getDailyDetails()" bsDatepicker #thEndDB="bsDatepicker" [bsConfig]="{containerClass: 'theme-dark-blue'}"/>
				<div class="input-group-append pointer" (click)="thEndDB.toggle()">
					<span class="input-group-text"><i class="fa fa-calendar"></i></span>
				</div>
			</div>
		</div>
		<div class="d-inline-flex ml-auto" [ngClass]="{'pt-2': common.isMobileScreen}">
			<button  class="btn fa fa-print fa-lg" printTitle="{{'g.performance' | translate}}" [useExistingCss]="true" printSectionId="charge_opportunity_analysis" ngxPrint title="{{'g.print' | translate}}"></button>
			<button class="fa fa-upload btn" title="{{'g.export_to_csv' | translate}}" (click)="exportDataToCsv()"></button>
		</div>
	</div>
	<div class="row" id="charge_opportunity_analysis" *ngIf="hasData else noData">
		<div *ngIf="data.daily_charging_opportunities.length" class="col-md-6">
			<div class="card">
				<div class="card-header">
					<h5>{{'perf_analytic.total_daily_charge_opportunity' | translate}}</h5>
				</div>
				<div class="card-body">
					<sct-charts 
						id="ube-daily-chart"
						title="{{'perf_analytic.average_daily_charge_opportunities' | translate}}"
						[data]="data.daily_charging_opportunities"
						[columnNames]="columnNames"
						[options]="minutesChartConfig"
						[type]="'ColumnChart'"
						[height]="200"
					></sct-charts>
					<sct-charts 
						id="ube-daily-chart"
						title="{{'perf_analytic.maximum_daily_charge_opportunities' | translate}}"
						[data]="data.max_charging_opportunities"
						[columnNames]="columnNames"
						[options]="minutesChartConfig"
						[type]="'ColumnChart'"
						[height]="200"
					></sct-charts>
					<sct-charts 
						id="ube-daily-chart"
						title="{{'perf_analytic.minimum_daily_charge_opportunities' | translate}}"
						[data]="data.min_charging_opportunities"
						[columnNames]="columnNames"
						[options]="minutesChartConfig"
						[type]="'ColumnChart'"
						[height]="200"
					></sct-charts>
				</div>
			</div>
		</div>
		<div *ngIf="data.daily_ebu_times.length" class="col-md-6">
			<div class="card">
				<div class="card-header">
					<h5>{{'perf_analytic.ebu_daily_usage_summary' | translate}}</h5>
				</div>
				<div class="card-body">
					<sct-charts 
						id="ube-daily-chart"
						title="{{'perf_analytic.average_daily_ebu_usage' | translate}}"
						[data]="data.daily_ebu_times"
						[columnNames]="columnNames"
						[options]="ChartConfig"
						[type]="'ColumnChart'"
						[height]="300"
					></sct-charts>
					<sct-charts 
						id="ube-daily-chart"
						title="{{'perf_analytic.maximum_daily_ebu_usage' | translate}}"
						[data]="data.max_ebu_times"
						[columnNames]="columnNames"
						[options]="ChartConfig"
						[type]="'ColumnChart'"
						[height]="300"
					></sct-charts>
				</div>
			</div>
		</div>
		<div *ngIf="data.idle_times.length" class="col-md-12 pt-30">
			<div class="card">
				<div class="card-header">
					<h5>{{'perf_analytic.idle_times' | translate}}</h5>
				</div>
				<div class="card-body">
					<sct-charts 
						id="ube-daily-chart"
						title="{{'perf_analytic.hourly_idle_times' | translate}}"
						[data]="data.idle_times"
						[columnNames]="columnNames"
						[options]="minutesChartConfig"
						[type]="'ColumnChart'"
						[height]="200"
					></sct-charts>
				</div>
			</div>
		</div>
	</div>
</div>
<ng-template #noData>
	<div class="mt-4 alert alert-info">{{'g.no_data_to_show' | translate}}</div>
</ng-template>